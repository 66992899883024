import moment from 'moment';
import styled, { useTheme } from 'styled-components';
import { FormProvider, useForm } from 'react-hook-form';
import { EventFilters } from '@aclito/shared/redux/types';
import { EventTag } from '@aclito/shared/features/event/types/types';
import { getCurrentTag } from '@aclito/shared/features/event/utils/getEventTag';
import { generateTagMappings } from '@aclito/shared/features/event/utils/mapEventTags';
import { Divider, Space } from '@mantine/core';
import { getCommonInputLabelStyle } from '@aclito/ui-web/utils/styles';

import { useSearchFilters } from '../hooks/useSearchFilters';

import {
  ActionButton,
  HStack,
  Tabs,
  TagButton,
  Text,
  ValidatedDateTimePicker,
  ValidatedMultiSelect,
  ValidatedNumberInput,
  ValidatedRangeSlider,
  ValidatedSelect,
  ValidatedSwitch,
  VStack,
} from '@/components';
import { FilterProps } from '@/features/search/types';

const Filter = (props: FilterProps) => {
  const {
    filterInitialValue,
    orgOptions,
    translatedActivities,
    translatedFilterGender,
    clearFilter,
    handleFilters,
    translatedLevels,
  } = useSearchFilters(props);

  const methods = useForm<EventFilters>({ defaultValues: filterInitialValue });

  const tags = methods.watch('tags');

  const handleTabIndexChange = (val: EventTag) =>
    methods.setValue('tags', generateTagMappings(val));

  const theme = useTheme();

  return (
    <FormProvider {...methods}>
      <VStack spacing={16} data-testid="LargeFilter">
        <ValidatedSelect
          options={translatedActivities}
          name="activityType"
          txLabel="filters.activityType"
          txPlaceholder="filters.activityType"
        />
        <ValidatedSelect
          options={orgOptions}
          name="org"
          txLabel="nav.org"
          txPlaceholder="nav.org"
          data-testid="organisation"
        />
        <ValidatedDateTimePicker
          {...{
            txLabel: 'filters.from',
            minDate: moment().toDate(),
          }}
          clearable={false}
          name="from"
          data-testid="from"
        />
        <ValidatedDateTimePicker
          {...{
            txLabel: 'filters.to',
            minDate: moment().toDate(),
          }}
          clearable={false}
          name="to"
          data-testid="to"
        />
        <ValidatedMultiSelect
          options={translatedLevels}
          name="level"
          txLabel="events.new.level"
          txPlaceholder="events.new.level"
          data-testid="level"
        />
        <ValidatedSelect
          options={translatedFilterGender}
          name="gender"
          txLabel="filters.gender"
          txPlaceholder="filters.gender"
        />
        <ValidatedNumberInput
          txLabel="filters.distance"
          name="distance"
          min={0}
          data-testid="distance"
        />
        <Space />
        <ValidatedSwitch name="multisport" txLabel="events.new.multisport" />
        <Divider />
        <ValidatedSwitch name="freeOnly" txLabel="filters.freeOnly" />
        <ValidatedRangeSlider
          name="price"
          min={0}
          step={10}
          max={1000}
          txLabel="filters.price"
          marks={[
            { value: 0, label: '0' },
            { value: 1000, label: '1000' },
          ]}
        />
        <Space h={16} />
        <VStack spacing={5}>
          <Text
            style={{ ...getCommonInputLabelStyle(theme) }}
            tx="filters.tags"
          />
          <Tabs
            unstyled
            mt={'5px'}
            maw={'max-content'}
            style={{
              flexDirection: 'row',
              flex: 1,
              rowGap: 20,
            }}
            selectedIndex={tags ? getCurrentTag(tags) : EventTag.ALL}
            setSelectedIndex={(tag) => handleTabIndexChange(tag as EventTag)}
          >
            <TagButton value={EventTag.ALL} tx="events.new.tab.all" />
            <TagButton value={EventTag.KIDS} tx="events.new.tab.kids" />
            <TagButton value={EventTag.ADULTS} tx="events.new.tab.adults" />
          </Tabs>
        </VStack>
        <Divider />
        <ButtonSection>
          <ActionButton tx="clear" fullWidth onClick={clearFilter} />
          <ActionButton
            tx="filter"
            primary
            fullWidth
            onClick={methods.handleSubmit(handleFilters)}
          />
        </ButtonSection>
      </VStack>
    </FormProvider>
  );
};

const ButtonSection = styled(HStack)`
  flex-wrap: nowrap;
`;

export default Filter;
