import { LoadingOverlay } from '@mantine/core';
import { useProfileAliasesCreate } from '@aclito/shared/features/profile/hooks/useProfileAliasesCreate';
import type { UserAlias } from '@aclito/entities';
import { useAppSelector } from '@aclito/shared/hooks';
import { profileSelectors } from '@aclito/shared/redux/slices/profileSlices';
import { useForm } from 'react-hook-form';
import { UserAliasForm } from '@aclito/shared/features/profile/hooks/useProfileAliasesEdit';

import AliasForm from '../../components/AliasForm';

import { useNavigate } from '@/hooks/useNavigate';
import { AliasFormScreen } from '@/features/profile/styles/styles';

const ProfileAliasesCreate = () => {
  const nav = useNavigate();

  const isLoadingUpdateMyProfile = useAppSelector(
    profileSelectors.isLoadingUpdateMyProfile,
  );

  const {
    placeholderName,
    placeholderSurname,
    createSecondaryProfile,
    initialValues,
  } = useProfileAliasesCreate();

  const handleSubmit = async (aliasData: Omit<UserAlias, 'id'>) => {
    if (await createSecondaryProfile(aliasData)) {
      nav(-1);
    }
  };

  const methods = useForm<UserAliasForm>({
    defaultValues: initialValues,
  });

  return (
    <AliasFormScreen>
      <LoadingOverlay visible={isLoadingUpdateMyProfile} overlayBlur={2} />
      <AliasForm
        {...{ methods, handleSubmit, placeholderName, placeholderSurname }}
      />
    </AliasFormScreen>
  );
};

export default ProfileAliasesCreate;
