import React from 'react';
import styled from 'styled-components';
import { Organization } from '@aclito/entities';
import { useTranslate } from '@aclito/shared/hooks';
import { TranslateKey } from '@aclito/shared/types';

import InfoBuilder from '../../../../event/screens/EventInfo/components/InfoBuilder';

import { useOrganizationInfoSection } from '@/features/organization/screens/OrganizationInfo/hooks/useOrganizationInfoSection';

type DescriptionProps = {
  displayedOrg: Organization;
};

const Description: React.FC<DescriptionProps> = ({ displayedOrg }) => {
  const { adminNames } = useOrganizationInfoSection(displayedOrg);
  const t = useTranslate();
  return (
    <Content>
      <InfoBuilder
        data={[
          {
            text: { text: adminNames.join(', ') },
            icon: 'person',
            label: 'org.info.admin',
          },
          {
            text: { text: displayedOrg?.telephone || '' },
            icon: 'telephone',
            label: 'org.telephone',
            visible: !!displayedOrg?.telephone,
          },
          {
            text: { text: displayedOrg?.email || '' },
            icon: 'email',
            label: 'org.email',
            visible: !!displayedOrg?.email,
          },
          {
            text: {
              tx: t(displayedOrg?.private ? 'yes' : 'no') as TranslateKey,
            },
            icon: 'lock',
            label: 'org.private',
          },
          {
            text: { text: displayedOrg?.address || '' },
            icon: 'place',
            label: 'org.info.address',
            visible: !!displayedOrg?.address,
          },
          {
            text: {
              tx: t(displayedOrg?.contact ? 'yes' : 'no') as TranslateKey,
            },
            icon: 'note',
            label: 'org.contact',
          },
          {
            text: { text: displayedOrg?.description || '' },
            icon: 'edit',
            label: 'org.info.description',
            visible: !!displayedOrg?.description,
            vertical: true,
          },
        ]}
      />
    </Content>
  );
};

const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

export default Description;
