import moment from 'moment';
import type { Availability } from '@aclito/entities';

import { config } from '../../../config';
import { ActivitiesKey } from '../../../types';
import { useSuggestEventsQuery } from '../../../redux/api/availabilityApi';
import { formatEvents } from '../../event/utils/formatEvents';

import { useAppSelector, useTranslate } from '@aclito/shared/hooks';
import { profileSelectors } from '@aclito/shared/redux/slices/profileSlices';

export const useAvailabilityInfo = (
  displayedAvailability: Availability | undefined,
) => {
  const t = useTranslate();

  const user = useAppSelector(profileSelectors.userInfo);

  const { data, refetch, isSuccess, isUninitialized, isFetching } =
    useSuggestEventsQuery(displayedAvailability?.id ?? '', {
      skip: !displayedAvailability,
    });

  const activities = displayedAvailability?.activities?.length
    ? displayedAvailability?.activities
    : ([config.activities.any.id] as ActivitiesKey[]);

  const date = displayedAvailability
    ? `${moment(displayedAvailability?.from).format(
        'dddd MMMM D, HH:mm',
      )} - ${moment(displayedAvailability.to).format('HH:mm')}`
    : t('error.availabilities.list');

  return {
    activities,
    suggestedEvents: formatEvents(data ?? []),
    user,
    displayedAvailability,
    date,
    refetch,
    isSuccess,
    isUninitialized,
    isFetching,
  };
};
