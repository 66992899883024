import { useRegister } from '@aclito/shared/features/auth/hooks/useRegister';
import { SignUpForm } from '@aclito/shared/types';

import { useNavigate } from '@/hooks/useNavigate';
import { ROUTE_SIGN_IN } from '@/util/constants';

export const useRegisterWeb = () => {
  const navigate = useNavigate();
  const { register } = useRegister();

  const signUp = async (values: SignUpForm) => {
    try {
      await register(values);
      navigate(ROUTE_SIGN_IN);
    } catch (e) {
      return;
    }
  };

  return { signUp };
};
