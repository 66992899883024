import { Auth } from 'aws-amplify';

import { ApiAttributes } from '../../types/authentication';
import { Logger } from '../../../util/logger';
import { errorObject } from '../../utils/errorObject';
/**
 *
 * Updates current users attributes
 */
export const makeUpdateAttributesCurrentUser = async (
  data: ApiAttributes,
): Promise<boolean> => {
  try {
    Logger.debug('Updating Attributes');
    const user = await Auth.currentAuthenticatedUser();
    await Auth.updateUserAttributes(user, data);
    return true;
  } catch (error) {
    //@ts-ignore
    return Promise.reject(errorObject[error.code]);
  }
};
