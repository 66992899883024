import { createIntl, createIntlCache } from 'react-intl';
import dayjs from 'dayjs';

import { messages } from '@aclito/shared/i18n/I18nProvider';
import { TranslateKey, TranslateValues } from '@aclito/shared/types';
import { store } from '@aclito/shared/redux/store';
const cache = createIntlCache();
export const translateDateFormat = (
  date: string,
  id: TranslateKey,
  values?: TranslateValues,
) => {
  const locale = store.getState().locale;
  const intl = createIntl({ locale, messages: messages[locale] }, cache);

  return dayjs(date).format(intl.formatMessage({ id }, values));
};
