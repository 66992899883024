import React from 'react';
import { eventIsRunning } from '@aclito/shared/features/event/utils/eventIsRunning';
import {
  isUserOrganizer,
  isUserTrainer,
} from '@aclito/shared/features/event/utils/getEventRole';
import {
  isForAdults,
  isForKids,
} from '@aclito/shared/features/event/utils/getEventTag';
import {
  isClub,
  isOrganization,
  isTeam,
} from '@aclito/shared/features/organization/utils/getOrgTag';
import { useAppSelector } from '@aclito/shared/hooks';
import { profileSelectors } from '@aclito/shared/redux/slices/profileSlices';
import { Event } from '@aclito/entities';
import { EventProviderType } from '@aclito/enums';

import { GroupedContent } from '@/components';
import {
  CardStatusBannerIcon,
  CardStatusBannerText,
} from '@/common/CardStatusBanner';
import ActivityBannerText from '@/common/CardStatusBanner/ActivityBannerText';
import { providerImage, providerStyle } from '@/util/providerData';
interface EventTagsProps {
  event: Event;
  fullText?: boolean;
}

const EventTags: React.FC<EventTagsProps> = ({ event, fullText }) => {
  const userInfo = useAppSelector(profileSelectors.userInfo);
  return (
    <GroupedContent style={{ justifyContent: 'space-between' }}>
      <GroupedContent spacing={4} style={{ flexWrap: 'nowrap' }}>
        {userInfo &&
          isUserOrganizer(userInfo, event) &&
          (!fullText ? (
            <CardStatusBannerIcon type="eventOrganizer" />
          ) : (
            <CardStatusBannerText type="eventOrganizer" />
          ))}
        {userInfo &&
          isUserTrainer(userInfo, event) &&
          (!fullText ? (
            <CardStatusBannerIcon type="eventTrainer" />
          ) : (
            <CardStatusBannerText type="eventTrainer" />
          ))}
        {eventIsRunning(event) &&
          (!fullText ? (
            <CardStatusBannerIcon type="eventRunning" />
          ) : (
            <CardStatusBannerText type="eventRunning" />
          ))}
        {event.activityType && (
          <ActivityBannerText activityType={event.activityType} />
        )}
        {isForKids(event) && <CardStatusBannerText type="eventForKids" />}
        {isForAdults(event) && <CardStatusBannerText type="eventForAdults" />}
        {event.org && isOrganization(event.org) && (
          <CardStatusBannerText type="orgTypeOrganization" />
        )}
        {event.org && isTeam(event.org) && (
          <CardStatusBannerText type="orgTypeTeam" />
        )}
        {event.org && isClub(event.org) && (
          <CardStatusBannerText type="orgTypeClub" />
        )}
        {event.repetitionId && <CardStatusBannerText type="eventRepeating" />}
        {event.provider !== EventProviderType.ACLITO && (
          <CardStatusBannerText type={event.provider} />
        )}
      </GroupedContent>
      {event.provider !== EventProviderType.ACLITO && (
        <img
          alt={'provider-logo'}
          src={providerImage[event.provider]}
          style={providerStyle[event.provider]}
        />
      )}
    </GroupedContent>
  );
};

export default EventTags;
