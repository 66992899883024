import { useState } from 'react';
import { Organization } from '@aclito/entities';

import { PlayerDataType } from '../types/types';
import { ThemeColorsRecord } from '../../../theme/colors';
import { EventModel } from '../../../classes/EventModel';

import { TranslateKey, UserWithMembershipType } from '@aclito/shared/types';
import { sectionMembers } from '@aclito/shared/util/sectionOrgMembers';
import { useAppSelector } from '@aclito/shared/hooks';
import { profileSelectors } from '@aclito/shared/redux/slices/profileSlices';
import { useFindOrganizationQuery } from '@aclito/shared/redux/api/organizationApi';

export const useEventInfoSelectTrainer = (
  org?: Organization,
  model?: EventModel,
) => {
  const { data } = useFindOrganizationQuery(
    { id: org?.id ?? '' },
    { skip: !org?.id },
  );

  const [selected, setSelected] = useState<PlayerDataType | undefined>();
  let playersToSelect: UserWithMembershipType[] = [];

  const userInfo = useAppSelector(profileSelectors.userInfo);
  const orgMembers = data?.members ?? [];

  if (orgMembers) {
    const { adminInfo, powerUsersInfo } = sectionMembers(
      org,
      orgMembers,
      userInfo,
    );

    playersToSelect = [
      ...adminInfo.filter((a) => a.id !== userInfo.id),
      ...powerUsersInfo.filter((p) => p.id !== userInfo.id),
    ].filter((player) => player.id !== model?.event?.leader);
  }

  const handleOnPlayerPress = (player: PlayerDataType) => {
    setSelected(player);
  };

  const isSelected = (id: string) => selected?.id === id;

  const topText: TranslateKey = 'events.info.alias.join.title';

  const isPlayerPowerUser = (playerId: string) =>
    org?.powerUsers.includes(playerId) ?? false;

  const isPlayerAdmin = (playerId: string) =>
    org?.admins.includes(playerId) ?? false;

  const itemBg = (id: string, theme: ThemeColorsRecord) =>
    isSelected(id) ? `${theme.primary}1A` : theme.white;

  return {
    itemBg,
    topText,
    isSelected,
    selected,
    setSelected,
    handleOnPlayerPress,
    playersToSelect,
    isPlayerPowerUser,
    isPlayerAdmin,
  };
};
