import { useTranslate } from '@aclito/shared/hooks';
import { TranslateKey, TranslateValues } from '@aclito/shared/types';
import {
  ButtonProps as MTButtonProps,
  Button as MTButton,
} from '@mantine/core';
import React, { forwardRef } from 'react';
import styled from 'styled-components';

export interface ButtonProps
  extends MTButtonProps,
    Omit<
      React.DetailedHTMLProps<
        React.ButtonHTMLAttributes<HTMLButtonElement>,
        HTMLButtonElement
      >,
      'color' | 'children' | 'ref'
    > {
  tx?: TranslateKey;
  text?: string;
  txOptions?: TranslateValues;
  ref?: React.ForwardedRef<HTMLButtonElement>;
}

// eslint-disable-next-line react/display-name
const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ tx, txOptions, text, children, ...rest }, ref) => {
    {
      const t = useTranslate();

      const i18nText = tx && t(tx, txOptions);
      const content = i18nText || text || children;

      return (
        <StyledButton ref={ref} {...rest}>
          {content}
        </StyledButton>
      );
    }
  },
);

const StyledButton = styled(MTButton)<ButtonProps>``;

export default Button;
