import { SelectObject, TranslateKey, txFn } from '../types';

export const translate = (arr: SelectObject[], t: txFn) => _getItem(arr, t);

export const getById = (id: string, obj: Record<string, SelectObject>) => {
  if (!id) {
    return { item: '', id: '' };
  }

  return _getItem([obj[id]])[0];
};

export const getByIdTranslate = (
  id: string,
  obj: Record<string, SelectObject>,
  t: txFn,
) => {
  if (!id) {
    return { item: '', id: '' };
  }

  return translate([obj[id]], t)[0];
};

const _getItem = (arr: SelectObject[], t?: txFn) =>
  arr.map((obj) => ({
    item: t ? t(obj?.item as TranslateKey) : obj.item,
    id: obj.id,
  }));
