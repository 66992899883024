import { useTranslate } from '@aclito/shared/hooks';
import { MultiSelect as MantineMultiSelect } from '@mantine/core';
import React, { useRef } from 'react';
import { useTheme } from 'styled-components';

import { Icon } from '../Icons/Icon';
import { getCommonInputLabelStyle } from '../../utils/styles';

import { MultiSelectProps } from './types';

const MultiSelect: React.FC<MultiSelectProps> = ({
  label,
  placeholder,
  txLabel,
  txPlaceholder,
  options,
  ...props
}) => {
  const t = useTranslate();
  const theme = useTheme();
  const i18nLabel = txLabel && t(txLabel);
  const labelContent = i18nLabel || label;

  const i18nPlaceholder = txPlaceholder && t(txPlaceholder);
  const placeholderContent = i18nPlaceholder || placeholder;

  const selectRef = useRef<HTMLInputElement>(null);

  return (
    <MantineMultiSelect
      ref={selectRef}
      variant="filled"
      size="md"
      clearable
      radius={10}
      rightSection={
        <Icon
          onClick={() => selectRef.current && selectRef.current.focus()}
          icon={'expandMore'}
          color="grey30"
        />
      }
      styles={{
        input: { backgroundColor: theme.white },
        value: {
          backgroundColor: theme.primary,
          color: theme.whiteText,
        },
        label: { ...getCommonInputLabelStyle(theme) },
      }}
      data={options?.map((o) => ({ value: o.id, label: o.item }))}
      label={labelContent}
      placeholder={placeholderContent}
      searchable
      {...props}
    />
  );
};

export default MultiSelect;
