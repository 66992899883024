import { FeedbackType } from '@aclito/enums';
import { FeedbackEvent } from '@aclito/entities';

import { EmojisKey, UserInfoWithAlias } from '../../../types';

export const useGetRatings = (
  player: UserInfoWithAlias,
  ratings: FeedbackEvent[],
) => {
  const playerId = player.aliasId ? player.aliasId : player.id;

  const userRatings = ratings.filter((r) => r.toUserId === playerId);

  const emoji = userRatings.find((r) => r.eventType === FeedbackType.EMOJI)
    ?.data as EmojisKey | undefined;

  const comment = userRatings.find(
    (r) => r.eventType === FeedbackType.REVIEW,
  )?.data;

  const karma = userRatings.find((r) => r.eventType === FeedbackType.KARMA);

  return { karma, comment, emoji, playerId };
};
