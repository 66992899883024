import { useTranslate } from '@aclito/shared/hooks';
import { Radio } from '@mantine/core';
import React from 'react';
import { useTheme } from 'styled-components';

import { getCommonInputLabelStyle } from '../../utils/styles';

import { RadioProps } from './types';

const RadioButton: React.FC<RadioProps> = ({ label, txLabel, ...props }) => {
  const t = useTranslate();
  const theme = useTheme();
  const i18nLabel = txLabel && t(txLabel);
  const labelContent = i18nLabel || label;

  return (
    <Radio
      label={labelContent}
      styles={{
        label: { ...getCommonInputLabelStyle(theme) },
      }}
      {...props}
    />
  );
};

export default RadioButton;
