import { EventWithDateObject } from '@aclito/shared/types';
import { useTranslate } from '@aclito/shared/hooks';
import { formatEvent } from '@aclito/shared/features/event/utils/formatEvents';
import moment from 'moment';
import { useMemo, useState } from 'react';
import { debounce } from '@aclito/shared/util/debounce';
import { View, Views } from 'aclito-calendar';
import {
  useSearchCalendarEventsQuery,
  useSearchEmbeddedCalendarEventsQuery,
} from '@aclito/shared/redux/api/calendarEventsApi';
import { Event } from '@aclito/entities';

type Range = Date[] | { start: Date; end: Date };

export const useCalendar = (orgId: string, isPublic = false) => {
  const t = useTranslate();
  const offsetMonthViewDate = (date: Date, end = false) =>
    end
      ? moment(date).endOf('month').add(1, 'week').endOf('day')
      : moment(date).startOf('month').subtract(1, 'week').startOf('day');

  const [viewStartDate, setViewStartDate] = useState<string>(
    offsetMonthViewDate(new Date()).toISOString(),
  );
  const [viewEndDate, setViewEndDate] = useState<string>(
    offsetMonthViewDate(new Date(), true).toISOString(),
  );

  const { data: events, isFetching } = useSearchCalendarEventsQuery(
    {
      viewStartDate,
      viewEndDate,
      orgId,
    },
    { skip: isPublic },
  );

  const { data: publicEvents, isLoading } =
    useSearchEmbeddedCalendarEventsQuery(
      {
        viewStartDate,
        viewEndDate,
        orgId,
      },
      { skip: !isPublic || !orgId },
    );

  const handleNavigate = (newDate: Date, view: View) => {
    let startDate;
    let endDate;
    switch (view) {
      case Views.MONTH:
        startDate = offsetMonthViewDate(newDate);
        endDate = offsetMonthViewDate(newDate, true);
        break;
      case Views.WEEK:
        startDate = moment(newDate).startOf('week');
        endDate = moment(newDate).endOf('week');
        break;
      case Views.DAY:
        startDate = moment(newDate).startOf('day');
        endDate = moment(newDate).endOf('day');
        break;
      default:
        startDate = moment();
        endDate = moment();
        break;
    }

    setViewStartDate(startDate.toISOString());
    setViewEndDate(endDate.toISOString());
  };

  const handleRangeChange = (range: Range, view?: View) => {
    if (!view) {
      return;
    }

    let startDate;
    let endDate;
    switch (view) {
      case Views.MONTH: {
        const { start, end } = range as { start: Date; end: Date };
        startDate = offsetMonthViewDate(start);
        endDate = offsetMonthViewDate(end, true);
        break;
      }

      case Views.WEEK: {
        const weekRange = range as Date[];
        startDate = moment(weekRange[0]).startOf('day');
        endDate = moment(weekRange[weekRange.length - 1]).endOf('day');
        break;
      }
      case Views.DAY: {
        const [dayStart] = range as Date[];
        startDate = moment(dayStart).startOf('day');
        endDate = moment(dayStart).endOf('day');
        break;
      }
      default:
        startDate = moment();
        endDate = moment();
        break;
    }

    setViewStartDate(startDate.toISOString());
    setViewEndDate(endDate.toISOString());
  };

  const debouncedNavigate = useMemo(() => debounce(handleNavigate, 300), []);

  const debouncedRangeChange = useMemo(
    () => debounce(handleRangeChange, 300),
    [],
  );

  const mapEvent = (event: Event): EventWithDateObject => ({
    ...event,
    ...formatEvent(event, t),
    date: new Date(event.date),
    endDate: new Date(event.endDate),
  });

  const mappedEvents: EventWithDateObject[] = (
    isPublic ? publicEvents ?? [] : events ?? []
  )
    .filter((event) => moment(event.endDate) > moment())
    .map((event) => mapEvent(event));

  return {
    mappedEvents,
    debouncedNavigate,
    debouncedRangeChange,
    isFetching: isFetching || isLoading,
  };
};
