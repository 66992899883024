import React from 'react';
import { useController } from 'react-hook-form';

import { RangeSlider, Slider } from './Slider';
import { RangeSliderProps, SliderProps } from './types';

export const ValidatedSlider: React.FC<SliderProps & { name: string }> = ({
  name,
  ...rest
}) => {
  const {
    field: { value, onChange },
  } = useController({ name });
  return <Slider value={value} onChange={onChange} {...rest} />;
};
export const ValidatedRangeSlider: React.FC<
  RangeSliderProps & { name: string }
> = ({ name, ...rest }) => {
  const {
    field: { value, onChange },
  } = useController({ name });
  return <RangeSlider value={value} onChange={onChange} {...rest} />;
};
