import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { FirstLoad, HasSearchedTypes } from '../types';
import {
  FirstLoadInitialState,
  LocaleInitialState,
  InitialHasSearchedState,
} from '../initialStates';
import { REDUX_RESET } from '../../util/constants';
import { LocaleType } from '../../types';
import { AppState } from '../store';

export const REDUX_LOCALE = 'locale';
export const REDUX_CURRENT_SCREEN = 'currentScreen';
export const REDUX_CONNECTED = 'netConnected';
export const REDUX_FIRST_LOAD = 'isFirstLoaded';
export const REDUX_HAS_SEARCHED = 'hasSearched';
export const REDUX_IS_SEARCH_OPEN = 'isSearchOpen';

const SliceLocale = createSlice({
  name: REDUX_LOCALE,
  initialState: LocaleInitialState,
  reducers: {
    updateLocale: (
      _,
      action: PayloadAction<{
        locale: LocaleType;
      }>,
    ) => action.payload.locale || 'cs',
  },
});

const SliceCurrentScreen = createSlice({
  name: REDUX_CURRENT_SCREEN,
  initialState: '',
  reducers: {
    updateCurrentScreen: (_, action: PayloadAction<string>) => action.payload,
    [REDUX_RESET + REDUX_CURRENT_SCREEN]: () => '',
  },
});

const SliceNetConnected = createSlice({
  name: REDUX_CONNECTED,
  initialState: true,
  reducers: {
    updateNetConnected: (_, action: PayloadAction<boolean>) => action.payload,
    [REDUX_RESET + REDUX_CONNECTED]: () => true,
  },
});

const SliceFirstLoad = createSlice({
  name: REDUX_FIRST_LOAD,
  initialState: FirstLoadInitialState,
  reducers: {
    updateFirstLoad: (_, action: PayloadAction<FirstLoad>) => action.payload,
    [REDUX_RESET + REDUX_FIRST_LOAD]: () => FirstLoadInitialState,
  },
});

const SliceIsSearchOpen = createSlice({
  name: REDUX_IS_SEARCH_OPEN,
  initialState: false,
  reducers: {
    setIsSearchOpen: (_, action: PayloadAction<boolean>) => action.payload,
    [REDUX_RESET + REDUX_IS_SEARCH_OPEN]: () => false,
  },
});

const SliceHasSearched = createSlice({
  name: REDUX_HAS_SEARCHED,
  initialState: InitialHasSearchedState,
  reducers: {
    updateHasSearch: (
      state,
      action: PayloadAction<[keyof HasSearchedTypes, boolean]>,
    ) => {
      const [key, value] = action.payload;
      state[key] = value;
    },
    [REDUX_RESET + REDUX_HAS_SEARCHED]: () => InitialHasSearchedState,
  },
});

export const commonActions = {
  ...SliceLocale.actions,
  ...SliceCurrentScreen.actions,
  ...SliceNetConnected.actions,
  ...SliceFirstLoad.actions,
  ...SliceHasSearched.actions,
  ...SliceIsSearchOpen.actions,
};

export const commonReducers = {
  [REDUX_LOCALE]: SliceLocale.reducer,
  [REDUX_CURRENT_SCREEN]: SliceCurrentScreen.reducer,
  [REDUX_IS_SEARCH_OPEN]: SliceIsSearchOpen.reducer,
  [REDUX_CONNECTED]: SliceNetConnected.reducer,
  [REDUX_FIRST_LOAD]: SliceFirstLoad.reducer,
  [REDUX_HAS_SEARCHED]: SliceHasSearched.reducer,
};

export const commonSelectors = {
  locale: (state: AppState) => state.locale as LocaleType,
  currentScreen: (state: AppState) => state.currentScreen,
  isSearchOpen: (state: AppState) => state.isSearchOpen,
  netConnected: (state: AppState) => state.netConnected,
  isFirstLoaded: (state: AppState) => state.isFirstLoaded,
  canLoadAgain: (state: AppState) => state.isFirstLoaded.canLoadAgain,
  hasSearched: (state: AppState) => state.hasSearched as HasSearchedTypes,
};
