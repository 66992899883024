import activities from './json/activityConfig.json';
import age from './json/ageRangesConfig.json';
import allLanguages from './json/allLanguages.json';
import distance from './json/distanceConfig.json';
import gender from './json/genderConfig.json';
import kick from './json/kickReasons.json';
import language from './json/languageConfig.json';
import levels from './json/levelsConfig.json';
import names from './json/namesConfig.json';
import notifications from './json/notificationsConfig.json';
import orgRoles from './json/orgUserRolesConfig.json';
import permissions from './json/permissionsConfig.json';
import places from './json/placesConfig.json';
import quotas from './json/quotasConfig.json';
import repeat from './json/repeatConfig.json';
import URL from './json/URLsConfig.json';
import axiom from './json/axiomConfig.json';
import searchPlaces from './json/searchPlacesConfig.json';
import emojis from './json/emojiConfig.json';
const config = {
  activities,
  age,
  allLanguages,
  distance,
  gender,
  kick,
  language,
  levels,
  names,
  notifications,
  orgRoles,
  permissions,
  places,
  quotas,
  repeat,
  URL,
  axiom,
  emojis,
  searchPlaces,
} as const;
type BaseConfig = typeof config;
export type ConfigType = 'always' | 'dev' | 'prod' | 'never';
export interface ConfigBaseProps extends BaseConfig {
  catchErrors: ConfigType;
}

const BaseConfig: ConfigBaseProps = {
  catchErrors: 'prod',
  ...config,
};

export default BaseConfig;
