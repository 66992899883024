import styled from 'styled-components';
import { TranslateKey } from '@aclito/shared/types';
import { useTranslate } from '@aclito/shared/hooks';

import { Icon } from '../Icons/Icon';
import { Text } from '../Text';

import HStack from './HStack';

interface SectionProps {
  buttonLabelTx?: TranslateKey;
  text?: string;
  onClick?: () => void;
  testID?: string;
}

function SectionTitle({ text, buttonLabelTx, onClick, testID }: SectionProps) {
  const t = useTranslate();

  const textValue = buttonLabelTx ? t(buttonLabelTx) : text;

  return (
    <Title data-testid={testID} onClick={onClick}>
      <HStack spacing={0}>
        <Text>{textValue}</Text>
      </HStack>
      <Icon icon="chevronRight" />
    </Title>
  );
}

const Title = styled.button`
  display: flex;
  width: 100%;
  padding: 8px 16px;
  align-items: center;
  justify-content: space-between;
  background: ${({ theme }) => theme.grey10};
  color: ${({ theme }) => theme.grey80};
  font-weight: bold;
  border: unset;
  cursor: pointer;
`;

export default SectionTitle;
