import { useUpdateEventMutation } from '../../../redux/api/eventApi';

import { EventForm, EventWithPlayers } from '@aclito/shared/types';

export const useEventInfoUpdate = () => {
  const [updateEventTrigger] = useUpdateEventMutation();

  const updateRepeatingEvents = async (
    data: EventForm,
    eventToEdit: EventWithPlayers,
  ) => {
    await updateEventTrigger({
      data,
      id: eventToEdit.id,
      repeatingId: eventToEdit.repetitionId,
    });
  };

  const updateEvent = async (
    data: EventForm,
    eventToEdit: EventWithPlayers,
  ) => {
    await updateEventTrigger({ data, id: eventToEdit.id });
  };

  return { updateEvent, updateRepeatingEvents };
};
