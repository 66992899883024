import { useLocation, Navigate } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { Hub } from '@aclito/shared/classes/Hub';
import { useEffect, useState } from 'react';
import { CognitoData, getUserData } from '@aclito/shared/util/cognitoUser';
import { currentUser } from '@aclito/shared/util/helpersApi';
import { AxiomLogger } from '@aclito/shared/classes/AxiomLogger';
import {
  isLoginDisabled,
  isTACUpdateRequired,
} from '@aclito/shared/util/handleStatus';
import api from '@aclito/shared/api/api';
import { StatusData } from '@aclito/shared/api/requests/custom/status/getStatus';
import {
  FIRST_TIME,
  NOT_FIRST_TIME,
  TERMS_AND_CONDITIONS_SKIP_DATE,
} from '@aclito/shared/util/constants';

import {
  ROUTE_ABOUT_USER,
  ROUTE_SIGN_IN,
  ROUTE_STATUS,
  ROUTE_TAC_UPDATE,
} from '@/util/constants';
import { saveContextToLocalStorage } from '@/util/context';
import { Storage } from '@/util/storage';

type RequireAuthProps = {
  children: JSX.Element;
};

const log = AxiomLogger.getLogger('router');

export const RequireAuth = ({ children }: RequireAuthProps) => {
  const location = useLocation();
  const [status, setStatus] = useState<StatusData | undefined>();
  const [userData, setUserData] = useState<CognitoData | undefined>();
  const [skipDate, setSkipDate] = useState<string | null>(null);
  const { route, user } = useAuthenticator((context) => [
    context.route,
    context.user,
  ]);

  useEffect(() => {
    log.info({ router: location.pathname });
  }, [location]);

  useEffect(() => {
    if (!status) {
      api.custom.status.getStatus().then((data) => setStatus(data));
    }
  }, [status]);

  useEffect(() => {
    const getData = async () => {
      try {
        if (user) {
          const data = await getUserData();
          Hub.user = data;
          Hub.cognitoUser = await currentUser();
          setUserData(data);
          setSkipDate(await Storage.getItem(TERMS_AND_CONDITIONS_SKIP_DATE));
        }
      } catch (e) {
        console.error('No user');
      }
    };
    getData();
  }, [user]);

  const getRoute = () => {
    if (route !== 'authenticated' && route !== 'idle') {
      if (route !== 'signOut') {
        saveContextToLocalStorage(location);
      }
      return <Navigate to={ROUTE_SIGN_IN} state={{ from: location }} replace />;
    }

    if (status && isLoginDisabled(status)) {
      return <Navigate to={ROUTE_STATUS} />;
    }

    if (
      user?.attributes?.['custom:Login'] === FIRST_TIME &&
      location.pathname !== ROUTE_ABOUT_USER
    ) {
      return <Navigate to={ROUTE_ABOUT_USER} />;
    }

    if (
      status &&
      userData &&
      user &&
      user.attributes?.['custom:Login'] === NOT_FIRST_TIME &&
      isTACUpdateRequired(userData.termsAndConditionsVersion, status, skipDate)
    ) {
      return <Navigate to={ROUTE_TAC_UPDATE} />;
    }

    return children;
  };

  return getRoute();
};
