import React from 'react';

import { Button, HStack, Text, VStack, Modal } from '@/components';

interface InitialConfirmModalProps {
  opened: boolean;
  close: () => void;
  onConfirmPress: () => void;
  onLeavePress: () => void;
}

const InitialConfirmModal: React.FC<InitialConfirmModalProps> = ({
  opened,
  close,
  onConfirmPress,
  onLeavePress,
}) => {
  return (
    <Modal
      styles={{
        body: {
          height: '100%',
        },
      }}
      opened={opened}
      onClose={close}
    >
      <VStack justify={'center'}>
        <Text tx="events.info.attendance.confirm.text" />
        <HStack spacing={15} style={{ justifyContent: 'space-between' }}>
          <Button
            w={195}
            color={'yellow'}
            onClick={onConfirmPress}
            tx="events.info.attendance.confirm.button"
          />
          <Button w={195} color={'gray'} onClick={onLeavePress} tx="leave" />
        </HStack>
      </VStack>
    </Modal>
  );
};

export default InitialConfirmModal;
