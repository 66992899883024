import { FieldErrors, FieldValues } from 'react-hook-form';

export const formErrorMapper = <K extends string, T extends FieldValues>(
  errorRecord: FieldErrors<T>,
  tx: Record<K, string>,
) => {
  const errs: Partial<Record<K, string>> = (
    Object.keys(errorRecord) as Array<K>
  ).reduce(
    (obj, key) =>
      Object.assign(obj, {
        [key]: `${tx[key]}: ${errorRecord[key]?.message}`,
      }),
    {},
  );
  return errs;
};
