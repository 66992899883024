import React, { useEffect } from 'react';
import { Center, Loader, Space } from '@mantine/core';
import { usePlayerSuggestions } from '@aclito/shared/features/event/hooks/usePlayerSuggestions';

import { PlayerSuggestionsProps } from './interface';

import { SectionGrid, Text, TitleBar, Title } from '@/components';
import withRouter from '@/navigation/withRouter';
import SuggestionCard from '@/components/Cards/SuggestionCard';

const PlayerSuggestions: React.FC<PlayerSuggestionsProps> = ({ state }) => {
  const event = state?.event;

  const {
    suggestPlayers,
    suggestedPlayers,
    invitePlayerToEvent,
    resetPlayers,
    isLoading,
  } = usePlayerSuggestions(event);

  useEffect(() => {
    suggestPlayers();
    return () => {
      resetPlayers();
    };
  }, []);

  return (
    <SectionGrid $isOpen={false}>
      {isLoading ? (
        <Center style={{ height: '100%' }}>
          <Loader />
        </Center>
      ) : (
        <>
          <TitleBar>
            <Title tx="nav.suggestions" />
          </TitleBar>
          <Text
            align="center"
            style={{
              margin: '1% 24% 2% 24%',
            }}
            tx="suggestions.about"
          />
          <Space h={30} />
          {suggestedPlayers.length > 0 &&
            suggestedPlayers.map((player) => (
              <SuggestionCard
                key={player.id}
                {...{
                  player,
                  invitePlayer: invitePlayerToEvent,
                }}
              />
            ))}
        </>
      )}
    </SectionGrid>
  );
};

export default withRouter(PlayerSuggestions);
