import { createAsyncThunk } from '@reduxjs/toolkit';
import { placeClient } from '@aclito/client';
import {
  CreatePlaceInput,
  ListPlaceInput,
  UpdatePlaceInput,
} from '@aclito/entities';

export const REDUX_CREATE_PLACE = 'createPlace';
export const REDUX_UPDATE_PLACE = 'updatePlace';
export const REDUX_NEARBY_PLACE = 'nearbyPlace';

export const searchNearbyPlacesAsync = createAsyncThunk(
  REDUX_NEARBY_PLACE,
  async (data: { location: ListPlaceInput }, thunkAPI) => {
    try {
      const result = await placeClient.places.list(data.location);

      return result.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ type: 'fail', payload: error });
    }
  },
);

export const createPlaceAsync = createAsyncThunk(
  REDUX_CREATE_PLACE,
  async (data: CreatePlaceInput, thunkAPI) => {
    try {
      return await placeClient.places.create(data).then((d) => d.data);
    } catch (error) {
      return thunkAPI.rejectWithValue({ type: 'fail', payload: error });
    }
  },
);

export const updatePlaceAsync = createAsyncThunk(
  REDUX_UPDATE_PLACE,
  async (data: UpdatePlaceInput & { id: string }, thunkAPI) => {
    try {
      return await placeClient.places.update(data.id, data).then((d) => d.data);
    } catch (error) {
      return thunkAPI.rejectWithValue({ type: 'fail', payload: error });
    }
  },
);
