import React from 'react';
import { Menu } from '@mantine/core';
import { useTheme } from 'styled-components';

import { ActionButton, HStack, Text, Icon } from '@/components';

interface ButtonsProps {
  handleDelete: () => void;
  handleFilter: () => void;
  compact?: boolean;
}

const Buttons: React.FC<ButtonsProps> = ({
  handleDelete,
  handleFilter,
  compact,
}) => {
  const theme = useTheme();

  return (
    <>
      {compact ? (
        <Menu>
          <Menu.Target>
            <ActionButton iconType={'more'} />
          </Menu.Target>
          <Menu.Dropdown
            style={{
              borderRadius: '10px',
              backgroundColor: theme.white,
              color: theme.grey80,
              border: 'none',
            }}
          >
            <Menu.Item
              icon={<Icon icon="filter" size={20} />}
              onClick={handleFilter}
            >
              <Text tx="filter" />
            </Menu.Item>
            <Menu.Item
              icon={<Icon icon="delete" size={20} />}
              onClick={handleDelete}
            >
              <Text tx="tooltip.delete" />
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      ) : (
        <HStack>
          <ActionButton
            onClick={handleFilter}
            tx={'filter'}
            iconType="filter"
          ></ActionButton>
          <ActionButton
            tx={'delete'}
            bg={'red10'}
            textcolor={'red'}
            onClick={handleDelete}
            iconType="delete"
          ></ActionButton>
        </HStack>
      )}
    </>
  );
};

export default Buttons;
