import { useState } from 'react';

import { filterJoinedAliases } from '../utils/filterJoinedAliases';

import { EventModel } from '@aclito/shared/classes/EventModel';

export const useEventInfoAliasesLeave = (model: EventModel) => {
  const { event, userInfo } = model;

  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const handleOnAliasPress = (id: string) =>
    setSelectedIds((prevValues) =>
      prevValues.includes(id)
        ? prevValues.filter((val) => val !== id)
        : [...prevValues, id],
    );

  const isSelected = (id: string) => selectedIds.includes(id);

  return {
    selectedIds,
    isSelected,
    handleOnAliasPress,
    joinedAliases: filterJoinedAliases(event.players, event.leader, userInfo),
  };
};
