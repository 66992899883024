import { FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { eventClient } from '@aclito/client';
import { EventRaw, Event, Place, Chat, UserInfo } from '@aclito/entities';
import { MaybeDrafted } from '@reduxjs/toolkit/dist/query/core/buildThunks';

import { mapEvent } from '../../features/event/utils/mapEvent';
import { EventModel } from '../../classes/EventModel';
import { formatEvent } from '../../features/event/utils/formatEvents';
import {
  ActivitiesKey,
  EventForm,
  EventWithPlayers,
  GenderKey,
  NoUndefinedField,
} from '../../types';
import { useAppSelector } from '../../hooks';
import { profileSelectors } from '../slices/profileSlices';
import { aclitoApi, AppState } from '../store';
import { parseURL } from '../../util/parseURL';
import {
  findAllAndReplace,
  findAllAndReplaceMultiple,
} from '../../util/findAndReplace';
import { unique } from '../../util/unique';
import { EventFilters } from '../types';
import { locationSelectors } from '../slices/locationSlice';

export const CREATE_EVENT = 'createEvent';
export const UPDATE_EVENT = 'updateEvent';
export const DELETE_EVENT = 'deleteEvent';
export const LOAD_MY_EVENTS = 'loadMyEvents';
export const LOAD_MY_ORG_EVENTS = 'loadMyOrgEvents';
export const DISPLAY_EVENT = 'displayEvent';
export const LEAVE_EVENT = 'leaveEvent';
export const JOIN_EVENT = 'joinEvent';
export const CONFIRM_EVENT = 'confirmEvent';
export const KICK_FROM_EVENT = 'kickFromEvent';
export const SEARCH_EVENTS = 'searchEvents';
export const JOIN_PROVIDER_EVENT = 'joinProviderEvent';
export const LEAVE_PROVIDER_EVENT = 'leaveProviderEvent';
export const HISTORY_EVENT = 'eventHistory';

export const eventApi = aclitoApi
  .enhanceEndpoints({
    addTagTypes: [
      'listMyEvents',
      'listMyOrgEvents',
      'displayEvent',
      'searchEvents',
    ],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      [LOAD_MY_EVENTS]: builder.query<
        { result: Event[]; nextToken: string | null },
        { nextToken: string | null; refetch?: boolean }
      >({
        queryFn: async (args) => {
          try {
            const events = await eventClient.events.getMyEvents(
              args.nextToken,
              50,
            );
            return {
              data: {
                result: events.data.data,
                nextToken: events.data.nextToken,
              },
            };
          } catch (error) {
            return { error: { error: 'fail' } as FetchBaseQueryError };
          }
        },
        serializeQueryArgs: ({ queryArgs }) => {
          const { nextToken, refetch, ...rest } = queryArgs;
          return rest;
        },
        merge: (currentData, { result, nextToken }, { arg }) => {
          if (arg.refetch) {
            currentData.result = result;
          } else {
            currentData.result.push(...result);
          }
          currentData.nextToken = nextToken;
        },
        forceRefetch({ currentArg, previousArg }) {
          return !!currentArg?.nextToken && currentArg !== previousArg;
        },
        providesTags: ['listMyEvents'],
      }),
      [LOAD_MY_ORG_EVENTS]: builder.query<
        { result: Event[]; nextToken: string | null },
        { nextToken: string | null; refetch?: boolean; orgId: string }
      >({
        queryFn: async (args) => {
          try {
            const events = await eventClient.events.getMyEvents(
              args.nextToken,
              50,
              args.orgId,
            );
            return {
              data: {
                result: events.data.data,
                nextToken: events.data.nextToken,
              },
            };
          } catch (error) {
            return { error: { error: 'fail' } as FetchBaseQueryError };
          }
        },
        serializeQueryArgs: ({ queryArgs }) => {
          const { nextToken, refetch, ...rest } = queryArgs;
          return rest;
        },
        merge: (currentData, { result, nextToken }, { arg }) => {
          if (arg.refetch) {
            currentData.result = result;
          } else {
            currentData.result.push(...result);
          }
          currentData.nextToken = nextToken;
        },
        forceRefetch({ currentArg, previousArg }) {
          return !!currentArg?.nextToken && currentArg !== previousArg;
        },
        providesTags: ['listMyOrgEvents'],
      }),
      [HISTORY_EVENT]: builder.query<Event[], void>({
        queryFn: async () => {
          try {
            const events = await eventClient.events.eventHistory();
            return { data: events.data };
          } catch (error) {
            return { error: { error: 'fail' } as FetchBaseQueryError };
          }
        },
      }),
      [SEARCH_EVENTS]: builder.query<
        { data: EventRaw[]; total: number; nextToken: string | null },
        EventFilters & { nextToken: string | null; refetch?: boolean }
      >({
        queryFn: async (args, { getState }) => {
          try {
            const searchMapLocation = locationSelectors.searchMapLocation(
              getState() as AppState,
            );
            const events = await eventClient.events.searchEvents({
              limit: 50,
              nextToken: args.nextToken,
              query: args.query || undefined,
              lat: args.distance
                ? args.location?.lat || searchMapLocation.lat
                : undefined,
              lon: args.distance
                ? args.location?.lon || searchMapLocation.lon
                : undefined,
              gender: args.gender?.id || undefined,
              activityType: args.activityType?.id || undefined,
              multisport: args.multisport ? args.multisport : undefined,
              freeOnly: args.freeOnly ? args.freeOnly : undefined,
              distance: args.distance ? Number(args.distance) : undefined,
              price: args.price?.join('|'),
              to: args.to,
              from: args.from,
              tags: args.tags
                ? Object.keys(args.tags)
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    .filter((tag) => args.tags[tag])
                    .join('|')
                : undefined,
              eventOrgId: args.org?.id || undefined,
              level: args.level?.map((l) => parseInt(l.id)).join('|'),
            });
            return { data: { ...events.data } };
          } catch (error) {
            return { error: { error: 'fail' } as FetchBaseQueryError };
          }
        },
        serializeQueryArgs: ({ queryArgs }) => {
          const { nextToken, refetch, ...rest } = queryArgs;
          return rest;
        },
        merge: (currentData, { data, nextToken }, { arg }) => {
          if (arg.refetch) {
            currentData.data = data;
          } else {
            currentData.data.push(...data);
          }
          currentData.nextToken = nextToken;
        },
        forceRefetch({ currentArg, previousArg }) {
          return !!currentArg?.nextToken && currentArg !== previousArg;
        },
        providesTags: ['searchEvents'],
      }),
      [DELETE_EVENT]: builder.mutation<
        { id: string } | { id: string }[],
        { id: string } | { repId: string; id: string }
      >({
        queryFn: async (args) => {
          try {
            if ('repId' in args) {
              const result = await eventClient.events.deleteRepeatingEvent(
                args.repId,
                args.id,
              );
              return { data: result.data };
            } else {
              await eventClient.events.deleteEvent(args.id);
              return { data: { id: args.id } };
            }
          } catch (error) {
            return { error: { error: 'fail' } as FetchBaseQueryError };
          }
        },
        async onQueryStarted(args, { dispatch, queryFulfilled }) {
          const result = await queryFulfilled;
          dispatch(
            eventApi.util.updateQueryData(
              'loadMyEvents',
              { nextToken: null },
              (draft) => {
                if ('repId' in args) {
                  const res = result.data as { id: string }[];
                  const ids = res.map((id) => id.id);
                  draft.result = draft.result.filter(
                    (e) => !ids.includes(e.id),
                  );
                  return draft;
                } else {
                  draft.result = draft.result.filter((e) => e.id !== args.id);
                  return draft;
                }
              },
            ),
          );
        },
      }),
      [CREATE_EVENT]: builder.mutation<
        Array<Omit<Event, 'leaderInfo' | 'playerInfo'>>,
        EventForm
      >({
        queryFn: async (args) => {
          const data = args as NoUndefinedField<EventForm>;
          try {
            const res = await eventClient.events.createEvent({
              eventPlaceId: data.place.id,
              date: data.date,
              maxPlayers: data.unlimited ? -1 : data.players || -1,
              name: data.name,
              canPayWithMultiSport: !!data.multisport,
              price: data.forFree ? 0 : data.price || 0,
              gender: data.gender.id as GenderKey,
              info: data.info,
              minLevel: parseInt(data.level.id),
              tags: data.tags,
              externalLink: parseURL(data.externalLink),
              eventOrgId: data.org?.id ? data.org?.id : undefined,
              enableQueue: data.enableQueue,
              activityType: data.activity.id as ActivitiesKey,
              repeatAfter: data.repeatAfter?.id,
              duration: data.duration,
              numberOfEvents: data.numberOfEvents
                ? Number(data.numberOfEvents)
                : undefined,
              hoursConfirm: data.hoursConfirm
                ? Number(data.hoursConfirm)
                : undefined,
              trainer: data.trainer?.id,
              willParticipate: !data.dontParticipate,
            });
            return { data: res.data };
          } catch (error) {
            return { error: { error: 'fail' } as FetchBaseQueryError };
          }
        },
        async onQueryStarted(_, { dispatch, queryFulfilled }) {
          const result = await queryFulfilled;
          dispatch(
            eventApi.util.updateQueryData(
              'loadMyEvents',
              { nextToken: null },
              (draft) => {
                draft.result = [...draft.result, ...result.data];
                return draft;
              },
            ),
          );
        },
      }),
      [UPDATE_EVENT]: builder.mutation<
        (EventRaw & { place: Place }) | { events: EventRaw[]; place: Place },
        {
          data: EventForm;
          id: string;
          repeatingId?: string;
        }
      >({
        queryFn: async (args) => {
          const data = args.data as NoUndefinedField<EventForm>;
          try {
            const input = {
              eventId: args.id,
              eventPlaceId: data.place.id,
              date: data.date,
              maxPlayers: data.unlimited ? -1 : data.players || -1,
              name: data.name,
              canPayWithMultiSport: !!data.multisport,
              price: data.forFree ? 0 : data.price || 0,
              gender: data.gender.id as GenderKey,
              info: data.info,
              minLevel: parseInt(data.level.id),
              tags: data.tags,
              externalLink: parseURL(data.externalLink),
              enableQueue: data.enableQueue,
              activityType: data.activity.id as ActivitiesKey,
              repeatAfter: data.repeatAfter?.id,
              duration: data.duration,
              hoursConfirm: data.hoursConfirm
                ? Number(data.hoursConfirm)
                : undefined,
              trainer: data.trainer?.id,
              willParticipate: !data.dontParticipate,
            };

            if (args.repeatingId) {
              const updated = await eventClient.events.updateRepeatingEvent(
                args.repeatingId,
                input,
              );
              return { data: updated.data };
            } else {
              const updated = await eventClient.events.updateEvent(
                args.id,
                input,
              );
              return { data: updated.data };
            }
          } catch (error) {
            return { error: { error: 'fail' } as FetchBaseQueryError };
          }
        },
        async onQueryStarted(args, { dispatch, queryFulfilled }) {
          const { data } = await queryFulfilled;

          if ('repeatingId' in args) {
            const result = data as { events: EventRaw[]; place: Place };
            dispatch(
              eventApi.util.updateQueryData(
                'loadMyEvents',
                { nextToken: null },
                (draft) => {
                  const newEvents = result.events
                    .map((event) => {
                      const oldEvent = draft.result.find(
                        (e) => e.id === event.id,
                      );
                      if (oldEvent) {
                        return {
                          ...oldEvent,
                          ...event,
                        };
                      }
                    })
                    .filter(Boolean);
                  draft.result = findAllAndReplaceMultiple(
                    draft.result,
                    newEvents,
                    'id',
                  );
                  return draft;
                },
              ),
            );
          } else {
            const result = data as EventRaw & { place: Place };
            dispatch(
              eventApi.util.updateQueryData(
                'loadMyEvents',
                { nextToken: null },
                (draft) => {
                  const oldEvent = draft.result.find((e) => e.id === result.id);
                  if (oldEvent) {
                    const newEvent = {
                      ...oldEvent,
                      ...result,
                    };
                    draft.result = findAllAndReplace(
                      draft.result,
                      newEvent,
                      (e) => e.id === result.id,
                    );
                    return draft;
                  }
                },
              ),
            );
          }
        },
        invalidatesTags: ['displayEvent'],
      }),
      [DISPLAY_EVENT]: builder.query<EventWithPlayers, { id: string }>({
        queryFn: async ({ id }) => {
          try {
            const event = await eventClient.events.getEvent(id);
            return { data: event.data };
          } catch (error) {
            return { error: { error: 'fail' } as FetchBaseQueryError };
          }
        },
        providesTags: ['displayEvent'],
      }),
      [JOIN_EVENT]: builder.mutation<
        | (EventRaw & { chat: Chat; place: Place })
        | { events: EventRaw[]; place: Place },
        | { id: string; aliasIds?: string[] }
        | { id: string; aliasIds?: string[]; repId: string }
      >({
        queryFn: async (args) => {
          try {
            if ('repId' in args) {
              const event = await eventClient.events.joinEventRepeating(
                args.repId,
                { aliasIds: args?.aliasIds ?? [], eventId: args.id },
              );
              return { data: event.data };
            } else {
              const event = await eventClient.events.joinEvent(args.id, {
                aliasIds: args.aliasIds ?? [],
              });
              return { data: event.data };
            }
          } catch (error) {
            return { error: { error: 'fail' } as FetchBaseQueryError };
          }
        },
        async onQueryStarted(args, { dispatch, queryFulfilled, getState }) {
          const state = getState() as AppState;
          const user = state.userInfo.current;
          const orgId = state.userInfo.orgId;

          try {
            const res = await queryFulfilled;

            if ('repId' in args) {
              const result = res.data as { events: EventRaw[]; place: Place };
              for (const event of result.events) {
                dispatch(
                  eventApi.util.updateQueryData(
                    'displayEvent',
                    { id: event.id },
                    (draft) => {
                      draft.players = event.players;
                      draft.playersInfo.push(user);
                      if (draft.chat) {
                        draft.chat = {
                          ...draft.chat,
                          participants: unique([
                            ...draft.chat.participants,
                            user.id,
                          ]),
                        };
                      }
                      return draft;
                    },
                  ),
                );
              }
              for (const {
                endpointName,
                originalArgs,
              } of eventApi.util.selectInvalidatedBy(getState(), [
                'searchEvents',
              ])) {
                if (endpointName !== SEARCH_EVENTS) continue;
                dispatch(
                  eventApi.util.updateQueryData(
                    endpointName,
                    originalArgs,
                    (draft) => {
                      return {
                        ...draft,
                        data: draft.data.filter(
                          (e) => !result.events.find((r) => r.id === e.id),
                        ),
                      };
                    },
                  ),
                );
              }
              dispatch(
                eventApi.util.updateQueryData(
                  'loadMyEvents',
                  { nextToken: null },
                  updateCacheFromJoinToMyEventsRep(result),
                ),
              );
              dispatch(
                eventApi.util.updateQueryData(
                  'loadMyOrgEvents',
                  { nextToken: null, orgId: orgId ?? '' },
                  updateCacheFromJoinToMyEventsRep(result),
                ),
              );
            } else {
              const result = res.data as EventRaw & {
                chat: Chat;
                place: Place;
              };
              dispatch(
                eventApi.util.updateQueryData(
                  'displayEvent',
                  { id: args.id },
                  (draft) => {
                    draft.players = result.players;
                    draft.playersInfo.push(user);
                    draft.chat = result.chat;
                    return draft;
                  },
                ),
              );
              for (const {
                endpointName,
                originalArgs,
              } of eventApi.util.selectInvalidatedBy(getState(), [
                'searchEvents',
              ])) {
                if (endpointName !== SEARCH_EVENTS) continue;
                dispatch(
                  eventApi.util.updateQueryData(
                    endpointName,
                    originalArgs,
                    (draft) => {
                      return {
                        ...draft,
                        data: draft.data.filter((e) => e.id !== result.id),
                      };
                    },
                  ),
                );
              }
              dispatch(
                eventApi.util.updateQueryData(
                  'loadMyEvents',
                  { nextToken: null },
                  updateCacheFromJoinToMyEventsSingle(result),
                ),
              );
              dispatch(
                eventApi.util.updateQueryData(
                  'loadMyOrgEvents',
                  { nextToken: null, orgId: orgId ?? '' },
                  updateCacheFromJoinToMyEventsSingle(result),
                ),
              );
            }
          } catch (e) {
            console.error(e);
          }
        },
      }),
      [LEAVE_EVENT]: builder.mutation<
        (EventRaw & { chat: Chat }) | EventRaw[],
        | { id: string; aliasIds?: string[]; message: string }
        | { id: string; aliasIds?: string[]; repId: string; message: string }
      >({
        queryFn: async (args) => {
          try {
            if ('repId' in args) {
              const event = await eventClient.events.leaveRepeatingEvent(
                args.repId,
                {
                  aliasIds: args?.aliasIds ?? [],
                  eventId: args.id,
                  message: args.message,
                },
              );
              return { data: event.data };
            } else {
              const event = await eventClient.events.leaveEvent(args.id, {
                aliasIds: args.aliasIds ?? [],
                message: args.message,
              });
              return { data: event.data };
            }
          } catch (error) {
            return { error: { error: 'fail' } as FetchBaseQueryError };
          }
        },
        async onQueryStarted(args, { dispatch, queryFulfilled, getState }) {
          try {
            const res = await queryFulfilled;
            const state = getState() as AppState;
            const user = state.userInfo.current;
            const orgId = state.userInfo.orgId;

            if ('repId' in args) {
              const result = res.data as EventRaw[];
              for (const event of result) {
                dispatch(
                  eventApi.util.updateQueryData(
                    'displayEvent',
                    { id: event.id },
                    (draft) => {
                      draft.players = event.players;
                      if (
                        draft.chat &&
                        !event.players.some((p) => p.id === user.id)
                      ) {
                        draft.chat.participants =
                          draft.chat.participants.filter((p) => p !== user.id);
                      }
                      return draft;
                    },
                  ),
                );
              }
              dispatch(
                eventApi.util.updateQueryData(
                  'loadMyEvents',
                  { nextToken: null },
                  updateCacheFromLeaveToMyEventsRep(result, user),
                ),
              );
              dispatch(
                eventApi.util.updateQueryData(
                  'loadMyOrgEvents',
                  { nextToken: null, orgId: orgId ?? '' },
                  updateCacheFromLeaveToMyEventsRep(result, user),
                ),
              );
            } else {
              const result = res.data as EventRaw & {
                chat: Chat;
                place: Place;
              };
              dispatch(
                eventApi.util.updateQueryData(
                  'displayEvent',
                  { id: args.id },
                  (draft) => {
                    draft.players = result.players;
                    draft.chat = result.chat;
                    return draft;
                  },
                ),
              );
              dispatch(
                eventApi.util.updateQueryData(
                  'loadMyEvents',
                  { nextToken: null },
                  updateCacheFromLeaveToMyEventsSingle(result, user),
                ),
              );
              dispatch(
                eventApi.util.updateQueryData(
                  'loadMyOrgEvents',
                  { nextToken: null, orgId: orgId ?? '' },
                  updateCacheFromLeaveToMyEventsSingle(result, user),
                ),
              );
            }
          } catch (e) {
            console.error(e);
          }
        },
      }),

      [KICK_FROM_EVENT]: builder.mutation<
        EventRaw,
        { eventId: string; idOrAliasId: string; message: string }
      >({
        queryFn: async ({ eventId, idOrAliasId, message }) => {
          try {
            const event = await eventClient.events.kickFromEvent(eventId, {
              idOrAliasId,
              message,
            });

            return { data: event.data };
          } catch (error) {
            return { error: { error: 'fail' } as FetchBaseQueryError };
          }
        },
        async onQueryStarted({ eventId }, { dispatch, queryFulfilled }) {
          try {
            const result = await queryFulfilled;

            dispatch(
              eventApi.util.updateQueryData(
                'displayEvent',
                { id: eventId },
                (draft) => {
                  draft.players = result.data.players;
                  return draft;
                },
              ),
            );

            dispatch(
              eventApi.util.updateQueryData(
                'loadMyEvents',
                { nextToken: null },
                (draft) => {
                  const oldEvent = draft.result.find(
                    (e) => e.id === result.data.id,
                  );
                  if (oldEvent) {
                    const newEvent = {
                      ...oldEvent,
                      ...result,
                    };
                    draft.result = findAllAndReplace(
                      draft.result,
                      newEvent,
                      (e) => e.id === result.data.id,
                    );
                    return draft;
                  }
                  return draft;
                },
              ),
            );
          } catch (e) {
            console.error(e);
          }
        },
      }),

      [CONFIRM_EVENT]: builder.mutation<
        EventRaw,
        { id: string; aliasIds?: string[] }
      >({
        queryFn: async ({ id, aliasIds }) => {
          try {
            const event = await eventClient.events.confirmEvent(
              id,
              aliasIds ?? [],
            );

            return { data: event.data };
          } catch (error) {
            return { error: { error: 'fail' } as FetchBaseQueryError };
          }
        },
        async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
          try {
            const result = await queryFulfilled;

            dispatch(
              eventApi.util.updateQueryData('displayEvent', { id }, (draft) => {
                draft.players = result.data.players;
                return draft;
              }),
            );
          } catch (e) {
            console.error(e);
          }
        },
      }),

      [JOIN_PROVIDER_EVENT]: builder.mutation<EventRaw, { id: string }>({
        queryFn: async ({ id }) => {
          try {
            const event = await eventClient.events.joinProviderEvent(id);

            return { data: event.data };
          } catch (error) {
            return { error: { error: 'fail' } as FetchBaseQueryError };
          }
        },
        async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
          try {
            const result = await queryFulfilled;

            dispatch(
              eventApi.util.updateQueryData('displayEvent', { id }, (draft) => {
                return { ...draft, ...result };
              }),
            );
          } catch (e) {
            console.error(e);
          }
        },
      }),

      [LEAVE_PROVIDER_EVENT]: builder.mutation<EventRaw, { id: string }>({
        queryFn: async ({ id }) => {
          try {
            const event = await eventClient.events.leaveProviderEvent(id);

            return { data: event.data };
          } catch (error) {
            return { error: { error: 'fail' } as FetchBaseQueryError };
          }
        },
        async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
          try {
            const result = await queryFulfilled;

            dispatch(
              eventApi.util.updateQueryData('displayEvent', { id }, (draft) => {
                return { ...draft, ...result };
              }),
            );
          } catch (e) {
            console.error(e);
          }
        },
      }),
    }),
  });

export const {
  useDisplayEventQuery,
  useJoinEventMutation,
  useLeaveEventMutation,
  useConfirmEventMutation,
  useKickFromEventMutation,
  useLoadMyEventsQuery,
  useLoadMyOrgEventsQuery,
  useLazyLoadMyEventsQuery,
  useLazyLoadMyOrgEventsQuery,
  useCreateEventMutation,
  useUpdateEventMutation,
  useDeleteEventMutation,
  useSearchEventsQuery,
  useLazySearchEventsQuery,
  useJoinProviderEventMutation,
  useLeaveProviderEventMutation,
  useEventHistoryQuery,
} = eventApi;

type Draft = MaybeDrafted<{
  result: Event[];
  nextToken: string | null;
}>;

const updateCacheFromLeaveToMyEventsSingle = (
  result: EventRaw & {
    chat: Chat;
    place: Place;
  },
  user: UserInfo,
) => {
  return (draft: Draft) => {
    const oldEvent = draft.result.find((e) => e.id === result.id);
    if (oldEvent) {
      // if no longer in event, remove it
      if (!result.players.some((p) => p.id === user.id)) {
        draft.result = draft.result.filter((e) => e.id !== result.id);
        return draft;
      } else {
        const newEvent = {
          ...oldEvent,
          ...result,
        };
        draft.result = findAllAndReplace(
          draft.result,
          newEvent,
          (e) => e.id === result.id,
        );
        return draft;
      }
    }
    return draft;
  };
};

const updateCacheFromLeaveToMyEventsRep = (
  result: EventRaw[],
  user: UserInfo,
) => {
  return (draft: Draft) => {
    const idsToRemove: string[] = [];
    const newEvents = result
      .map((event) => {
        // if no longer in event, remove it
        if (!event.players.some((p) => p.id === user.id)) {
          idsToRemove.push(event.id);
        }
        const oldEvent = draft.result.find((e) => e.id === event.id);
        if (oldEvent) {
          return {
            ...oldEvent,
            ...event,
          };
        }
      })
      .filter(Boolean);
    const replaced = findAllAndReplaceMultiple(draft.result, newEvents, 'id');
    draft.result = replaced.filter((e) => !idsToRemove.includes(e.id));
    return draft;
  };
};

const updateCacheFromJoinToMyEventsRep = (result: {
  events: EventRaw[];
  place: Place;
}) => {
  return (draft: Draft) => {
    const additionalEvents: Event[] = [];
    const newEvents = result.events
      .map((event) => {
        const oldEvent = draft.result.find((e) => e.id === event.id);
        if (oldEvent) {
          return {
            ...oldEvent,
            ...event,
          };
        } else {
          additionalEvents.push({
            ...event,
            place: result.place,
          });
        }
      })
      .filter(Boolean);
    const replaced = findAllAndReplaceMultiple(draft.result, newEvents, 'id');

    draft.result = [...replaced, ...additionalEvents];
    return draft;
  };
};

const updateCacheFromJoinToMyEventsSingle = (
  result: EventRaw & { chat: Chat; place: Place },
) => {
  return (draft: Draft) => {
    const oldEvent = draft.result.find((e) => e.id === result.id);
    if (oldEvent) {
      const newEvent = {
        ...oldEvent,
        ...result,
      };
      draft.result = findAllAndReplace(
        draft.result,
        newEvent,
        (e) => e.id === result.id,
      );
      return draft;
    } else {
      draft.result = [...draft.result, result];
      return draft;
    }
  };
};

export const useDisplayEvent = <T extends string | undefined>(id: T) => {
  const userInfo = useAppSelector(profileSelectors.userInfo);

  const { data, ...rest } = useDisplayEventQuery(
    { id: id ?? '' },
    { skip: !id },
  );

  if (!data) {
    return {
      model: undefined,
      ...rest,
    };
  }
  const mappedEvent = mapEvent(data, data.playersInfo);

  return {
    model: new EventModel(formatEvent(mappedEvent), userInfo),
    ...rest,
  };
};
