import { OrganizationForm } from '../../../types';

export const composeInitialCreateValues = (): OrganizationForm => ({
  name: '',
  maxMembers: undefined,
  description: '',
  private: false,
  address: '',
  unlimited: true,
  image: 'BASIC',
  telephone: '',
  email: '',
  file: undefined,
  contact: false,
  tags: {
    team: true,
    club: false,
    organization: false,
  },
});
