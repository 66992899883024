import { useAppSelector } from '@aclito/shared/hooks';
import { useFindOrganizationQuery } from '@aclito/shared/redux/api/organizationApi';
import { profileSelectors } from '@aclito/shared/redux/slices/profileSlices';
import { sectionMembers } from '@aclito/shared/util/sectionOrgMembers';
import { Organization } from '@aclito/entities';

export const useOrganizationInfoSection = (org: Organization | undefined) => {
  const userInfo = useAppSelector(profileSelectors.userInfo);

  const { data } = useFindOrganizationQuery(
    { id: org?.id ?? '' },
    { skip: !org },
  );

  const { adminInfo, adminNames, powerUsersInfo, memberInfo, myRole } =
    sectionMembers(org, data?.members ?? [], userInfo);
  return {
    adminInfo,
    adminNames,
    powerUsersInfo,
    memberInfo,
    myRole,
  };
};
