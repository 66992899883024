import { EventPlayer, UserInfo, Event } from '@aclito/entities';

import { getPlayersByFlag, getQueuePlayersByFlag } from './getPlayers';

import { EventWithPlayers, UserInfoWithAlias } from '@aclito/shared/types';

export const mapEvent = (event: Event, pool: UserInfo[]): EventWithPlayers => {
  const _mapAlias = (player: EventPlayer): UserInfoWithAlias => {
    const foundUser = pool.find((user) => user.id === player.id);

    const foundAlias = foundUser?.aliases.find(
      (alias) => alias.id === player.aliasId,
    );

    if (foundAlias && foundUser) {
      return {
        ...foundUser,
        name: foundAlias.name,
        image: foundAlias.image,
        surname: foundAlias.surname,
        aliasId: foundAlias.id,
      };
    }

    return { ...foundUser!, aliasId: foundUser?.id };
  };

  const playersInfo = getPlayersByFlag(event)
    .map((player) => {
      if (player.aliasId) {
        return _mapAlias(player);
      }

      return pool.find((user) => user.id === player.id);
    })
    .filter(Boolean);

  const queuePlayersInfo = getQueuePlayersByFlag(event)
    .map((player) => {
      if (player.aliasId) {
        return _mapAlias(player);
      }

      return pool.find((user) => user.id === player.id);
    })
    .filter(Boolean);

  return {
    ...event,
    leaderInfo: pool.find((user) => user.id === event.leader)!,
    playersInfo,
    queuePlayersInfo,
  };
};
