import React from 'react';
import { getById } from '@aclito/shared/util/selectObject';
import { ActivitiesKey, TranslateKey } from '@aclito/shared/types';
import { config } from '@aclito/shared/config';
import { cardColorPicker } from '@aclito/shared/util/cardColorPicker';
import { useTheme } from 'styled-components';

import Banner from './Banner';

import { IconSelect } from '@/components';
interface ActivityBannerTextProps {
  activityType: ActivitiesKey;
}

const ActivityBannerText: React.FC<ActivityBannerTextProps> = ({
  activityType,
}) => {
  const theme = useTheme();
  return (
    <Banner
      icon={
        activityType !== config.activities.any.id ? (
          <IconSelect
            style={{ display: 'flex', marginBottom: 2 }}
            iconSize={14}
            iconColor={theme.whiteText}
            type={activityType}
          />
        ) : (
          <></>
        )
      }
      color={
        activityType !== config.activities.any.id
          ? cardColorPicker(activityType)
          : theme.primary
      }
      tx={
        activityType !== config.activities.any.id
          ? (getById(activityType, config.activities).item as TranslateKey)
          : 'availability.new.anyactivity'
      }
    />
  );
};

export default ActivityBannerText;
