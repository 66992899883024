import { ImageValueShape, SelectObject } from '@aclito/shared/types';

export enum OrganizationTag {
  TEAM = 'TEAM',
  CLUB = 'CLUB',
  ORGANIZATION = 'ORGANIZATION',
}

export type OrganizationTags = {
  team: boolean;
  club: boolean;
  organization: boolean;
};

export interface FeedForm {
  feedOrg: SelectObject | undefined;
  feedText: string;
  file: string | undefined;
  image?: ImageValueShape;
}
