export const NOTIFICATION_TYPE_GROUP_MESSAGE = 'GroupMessage';
export const NOTIFICATION_TYPE_EVENT = 'Event';
export const NOTIFICATION_TYPE_ORG = 'Org';

export type NotificationDataType = [
  typeof NOTIFICATION_TYPE_EVENT,
  typeof NOTIFICATION_TYPE_ORG,
  typeof NOTIFICATION_TYPE_GROUP_MESSAGE,
][number];

export enum ChatMessageMetaData {
  Left = 'LEFT',
  Joined = 'JOINED',
  Message = 'MESSAGE',
}

export enum EventProviderType {
  ACLITO = 'ACLITO',
  RESERVANTO = 'RESERVANTO',
  CHYTRA_REZERVACE = 'CHYTRA_REZERVACE',
}

export enum EventTag {
  ALL = 'ALL',
  KIDS = 'KIDS',
  ADULTS = 'ADULTS',
}

export enum NotificationType {
  EVENT_LEFT = 'EVENT_LEFT',
  EVENT_LEFT_REPEATING = 'EVENT_LEFT_REPEATING',
  EVENT_DELETE_REPEATING = 'EVENT_DELETE_REPEATING',
  EVENT_CHANGE_REPEATING = 'EVENT_CHANGE_REPEATING',
  EVENT_JOIN_REPEATING = 'EVENT_JOIN_REPEATING',
  EVENT_MULTIPLE_LEFT = 'EVENT_MULTIPLE_LEFT',
  EVENT_QUEUE_LEFT = 'EVENT_QUEUE_LEFT',
  EVENT_MULTIPLE_QUEUE_LEFT = 'EVENT_MULTIPLE_QUEUE_LEFT',
  EVENT_KICK = 'EVENT_KICK',
  EVENT_QUEUE_KICK = 'EVENT_QUEUE_KICK',
  EVENT_INVITE = 'EVENT_INVITE',
  EVENT_JOIN = 'EVENT_JOIN',
  EVENT_MULTIPLE_JOIN = 'EVENT_MULTIPLE_JOIN',
  EVENT_QUEUE_MULTIPLE_JOIN = 'EVENT_QUEUE_MULTIPLE_JOIN',
  EVENT_QUEUE_REPEATING_JOIN = 'EVENT_QUEUE_REPEATING_JOIN',
  EVENT_QUEUE_REPEATING_LEFT = 'EVENT_QUEUE_REPEATING_LEFT',
  EVENT_QUEUE_JOIN = 'EVENT_QUEUE_JOIN',
  EVENT_PLAYER_CONFIRMED = 'EVENT_PLAYER_CONFIRMED',
  EVENT_MULTIPLE_PLAYER_CONFIRMED = 'EVENT_MULTIPLE_PLAYER_CONFIRMED',
  EVENT_CONFIRM = 'EVENT_CONFIRM',
  EVENT_STARTING = 'EVENT_STARTING',
  EVENT_END = 'EVENT_END',
  EVENT_CHANGE = 'EVENT_CHANGE',
  EVENT_DELETE = 'EVENT_DELETE',
  EVENT_QUEUE_SHIFT = 'EVENT_QUEUE_SHIFT',
  ORG_ROLE_CHANGE = 'ORG_ROLE_CHANGE',
  ORG_LEFT = 'ORG_LEFT',
  ORG_JOIN = 'ORG_JOIN',
  ORG_DELETE = 'ORG_DELETE',
  ORG_KICK = 'ORG_KICK',
  FEED_CREATE = 'FEED_CREATE',
  FEED_UPDATE = 'FEED_UPDATE',
}

export enum AchievementEventEnum {
  JOIN_EVENT = 'JOIN_EVENT',
  CREATE_EVENT = 'CREATE_EVENT',
  LEAVE_EVENT = 'LEAVE_EVENT',
  UPDATE_EVENT = 'UPDATE_EVENT',
  DELETE_EVENT = 'DELETE_EVENT',
  JOIN_ORG = 'JOIN_ORG',
  LEAVE_ORG = 'LEAVE_ORG',
  DELETE_ORG = 'DELETE_ORG',
  CREATE_AVAILABILITY = 'CREATE_AVAILABILITY',
  DELETE_AVAILABILITY = 'DELETE_AVAILABILITY',
  UPDATE_AVAILABILITY = 'UPDATE_AVAILABILITY',
}

type PickEnum<T, K extends T> = {
  [P in keyof K]: P extends K ? P : never;
};

export enum FeedbackType {
  REVIEW = 'REVIEW',
  EMOJI = 'EMOJI',
  KARMA = 'KARMA',
}

export type AchievementType = PickEnum<
  AchievementEventEnum,
  | AchievementEventEnum.CREATE_EVENT
  | AchievementEventEnum.CREATE_AVAILABILITY
  | AchievementEventEnum.JOIN_EVENT
  | AchievementEventEnum.JOIN_ORG
>;
