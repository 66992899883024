import { UserInfo, EventRaw } from '@aclito/entities';

import { commonActions } from '../../../redux/slices';
import { useAppDispatch } from '../../../hooks/useDispatch';

import { useSuggestPlayers } from './useSuggestPlayers';

import { REDUX_RESET } from '@aclito/shared/util/constants';

export const usePlayerSuggestions = (event: EventRaw | undefined) => {
  const dispatch = useAppDispatch();

  const { suggestPlayers } = useSuggestPlayers();
  const isLoading = false;
  const suggestedPlayers: UserInfo[] = []; //TODO
  const resetPlayers = () => {
    //@ts-ignore
    dispatch(commonActions[REDUX_RESET + REDUX_SUGGESTED_PLAYERS]());
  };

  const invitePlayerToEvent = async (_: UserInfo) => {
    if (event) {
      //TODO
    }
  };

  return {
    suggestPlayers,
    suggestedPlayers,
    isLoading,
    invitePlayerToEvent,
    resetPlayers,
  };
};
