import React, { MutableRefObject, useState } from 'react';

import { Icon, Input } from '@/components';
import { useSearchBar } from '@/features/search/hooks/useSearchBar';
import { SearchBarProps } from '@/features/search/types';

const SearchBar: React.FC<SearchBarProps> = ({
  onQueryChange,
  value,
  txLabel,
  debounce = 1000,
}) => {
  const { ref, handleDebounceQuery } = useSearchBar(onQueryChange, debounce);

  const inputRef = (ref ||
    React.createRef()) as MutableRefObject<HTMLInputElement>;

  const [v, setV] = useState(value);

  return (
    <Input
      txLabel={txLabel}
      txPlaceholder="search"
      style={{ flexGrow: '1' }}
      ref={inputRef}
      value={v}
      icon={<Icon icon="search" size={24} color={'primary'} />}
      onChange={(text: React.ChangeEvent<HTMLInputElement>) => {
        setV(text.target.value);
        handleDebounceQuery(text.target.value);
      }}
    />
  );
};

export default SearchBar;
