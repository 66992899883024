import type { FormatXMLElementFn, PrimitiveType } from 'intl-messageformat';
import type {
  Message,
  UserInfo,
  Event,
  Location,
  Organization,
  EmojiMap,
} from '@aclito/entities';
import { IconType } from '@aclito/ui-web/components/Icons/Icon';

import en from '../i18n/messages/en.json';
import {
  CALENDAR_EVENT,
  CURRENT_ORGANIZATION,
  LAST_ACTIVE,
  LAST_SEEN_CHAT,
  LOCALE,
  LOCATION_SERVICES,
  PREVIOUS_ORGANIZATIONS,
  REMINDER,
  SEEN_MAP,
  SHOW_TUTORIAL,
  STARTING_LANGUAGE,
  TERMS_AND_CONDITIONS_SKIP_DATE,
  THEME,
  UPDATE_SEEN,
} from '../util/constants';
import { config } from '../config';
import { useTranslate, useTranslateDate } from '../hooks';
import { OrganizationTags } from '../features/organization/types/types';
import { EventTags } from '../features/event/types/types';
import { SeenMap } from '../util/seenMap';

export type UserLocation = Omit<
  InitialLocation,
  'latitudeDelta' | 'longitudeDelta'
>;
export type MemberType = 'admin' | 'powerUser' | 'member';
export interface UserWithMembershipType extends UserInfo {
  type: MemberType;
}
export type txFn = ReturnType<typeof useTranslate>;
export type txDateFn = ReturnType<typeof useTranslateDate>;
export type AlertErrorEventKeys = keyof Omit<
  EventForm,
  | 'switchRepeating'
  | 'multisport'
  | 'org'
  | 'forFree'
  | 'repetitionInterval'
  | 'enableQueue'
  | 'dontParticipate'
  | 'tags'
>;
export type AlertErrorAvailabilityKeys = keyof Pick<
  AvailabilityForm,
  'date' | 'repeatAfter' | 'numberOfAvailabilities' | 'duration'
>;
export type ShareType = 'org' | 'event';

export type LocaleType = keyof typeof config.language;
export type Item =
  | typeof STARTING_LANGUAGE
  | typeof CALENDAR_EVENT
  | typeof UPDATE_SEEN
  | typeof SHOW_TUTORIAL
  | typeof LAST_SEEN_CHAT
  | typeof LAST_ACTIVE
  | typeof SEEN_MAP
  | typeof REMINDER
  | typeof LOCALE
  | typeof THEME
  | typeof LOCATION_SERVICES
  | typeof PREVIOUS_ORGANIZATIONS
  | typeof CURRENT_ORGANIZATION
  | typeof TERMS_AND_CONDITIONS_SKIP_DATE;
export type Theme = 'light' | 'dark';

export type ValueOf<T> = T[keyof T];

export type NoUndefinedField<T> = {
  [P in keyof T]-?: NoUndefinedField<NonNullable<T[P]>>;
};

export type UnionToIntersection<U> = (
  U extends any ? (k: U) => void : never
) extends (k: infer I) => void
  ? I
  : never;
export type PickEnum<T, K extends T> = {
  [P in keyof K]: P extends K ? P : never;
};
export type TranslateKey = keyof typeof en;
export type ActivitiesKey = keyof typeof config.activities;
export type EmojisKey = keyof typeof config.emojis;
export type EmojiObject = Record<EmojisKey, { item: string }>;
export type AllIcons = IconType | ActivitiesKey | GenderKey | RepeatingKey;
export type GenderKey = keyof typeof config.gender.filter;
export type GenderUserKey = keyof typeof config.gender.signup;
export type LanguagesKey = keyof typeof config.allLanguages;
export type AgeKey = keyof typeof config.age;
export type RepeatingKey = 'repeating';

export enum AvailabilityActivities {
  ANY_ACTIVITY = 'anyActivity',
  CHOOSE_ACTIVITY = 'chooseActivity',
  MY_ACTIVITIES = 'myActivities',
}

export type TranslateValues = Record<
  string,
  PrimitiveType | FormatXMLElementFn<string, any>
>;

export type ObjectFromList<T extends ReadonlyArray<string>, V = string> = {
  [K in T extends ReadonlyArray<infer U> ? U : never]: V;
};

export interface SignInForm {
  username: string;
  password: string;
}
export type LeaveForm = { area: string };

export interface InitialLocation {
  latitude: number;
  longitude: number;
  lat: number;
  lng: number;
  lon: number;
  latitudeDelta: number;
  longitudeDelta: number;
}

export type ItemToReturnType = {
  [LAST_SEEN_CHAT]: LastSeenData;
  [PREVIOUS_ORGANIZATIONS]: Record<string, Record<string, string> | undefined>;
  [CURRENT_ORGANIZATION]: Record<string, string | undefined>;
  [SEEN_MAP]: SeenMap;
};

export interface IStorage {
  setItem: (item: Item, value: string) => Promise<void>;
  getItem: (item: Item) => Promise<string | null>;
  getParsedItems: <T extends keyof ItemToReturnType>(
    item: T,
  ) => Promise<ItemToReturnType[T]>;
  removeItem: (item: Item) => Promise<void>;
}

export interface OrgFilter {
  name: string;
}

export interface OrganizationForm {
  name: string;
  maxMembers: number | undefined;
  description: string;
  private: boolean;
  address: string;
  unlimited: boolean;
  file: string | undefined;
  image: ImageValueShape;
  contact: boolean;
  tags: OrganizationTags;
  telephone: string;
  email: string;
}

export interface AvailabilityForm {
  duration: string | null;
  date: string | undefined;
  location: Location | undefined;
  activities: SelectObject[] | undefined;
  repeating: boolean;
  repeatAfter: SelectObject | undefined;
  numberOfAvailabilities: string | undefined;
  repetitionId: string | null;
}

export interface NotificationBody {
  bodyTranslations?: string;
  titleTranslations: string;
  secondaryTitle?: string;
  action?: string;
  message?: string;
}

export interface ProfileInfoForm {
  languages: SelectObject[];
  activities: SelectObject[];
  age: SelectObject;
  gender: SelectObject;
  name: string;
  surname: string;
  description: string;
  image: ImageValueShape;
  file: string | undefined;
}

export type OnRemoveMemberFunction = (id: string) => void;
export type OnEditMemberFunction = (type: MemberType, id: string) => void;

export interface i18<T extends string> {
  translations: Record<T, string>;
}

export interface ForgotPasswordForm {
  username: string;
  code: string;
  newPassword: string;
}

export interface PasswordRequestForm {
  username: string;
}

export interface SignUpForm {
  username: string;
  password: string;
}

export interface IMessage extends Omit<Message, 'createdAt' | 'user'> {
  user: {
    _id: string | number;
    userInfo: UserInfo | undefined;
  };
  text: string;
  _id: string | number;
  sent: boolean;
  createdAt: Date | number;
}

export interface EventForm {
  switchRepeating: boolean;
  repeatAfter: SelectObject | undefined;
  numberOfEvents: string | undefined;
  price: number | '' | undefined;
  forFree: boolean;
  players: number | '' | undefined;
  info: string;
  activity: SelectObject | undefined;
  date: string | null;
  duration: string | null;
  gender: SelectObject;
  place: SelectObject | undefined;
  level: SelectObject;
  name: string;
  multisport: boolean;
  newPlace?: string;
  externalLink: string;
  linkRequired: boolean;
  org: SelectObject | undefined;
  unlimited: boolean;
  requireConfirm: boolean;
  hoursConfirm: string | undefined;
  dontParticipate: boolean;
  trainer?: SelectObject | null;
  enableQueue: boolean;
  tags: EventTags;
}

export interface EventWithPlayers extends Event {
  leaderInfo: UserInfo;
  playersInfo: UserInfoWithAlias[];
  queuePlayersInfo: UserInfoWithAlias[];
}

export interface UserInfoWithAlias extends UserInfo {
  aliasId?: string;
}

export interface FormattedEvent extends Omit<Event, 'activityType'> {
  activityType: ActivitiesKey;
  formattedEndDate: string;
  formattedEndTime: string;
  formattedTime: string;
  formattedDate: string;
  txActivity?: string;
  txLevel?: string;
  txGender?: string;
}

export interface FormattedUserInfo extends UserInfo {
  formattedGender: string;
  formattedLanguages: string[];
  formattedAge: string;
  formattedActivities: string[];
}

export interface FormattedEventWithPlayers
  extends FormattedEvent,
    Omit<EventWithPlayers, 'activityType'> {
  activityType: ActivitiesKey;
}

export interface EventWithDateObject
  extends Omit<FormattedEvent, 'date' | 'endDate'> {
  date: Date;
  endDate: Date;
}

export type SnackBarIconType =
  | 'notificationError'
  | 'notificationWarning'
  | 'notificationSuccess';

export type LocaleIconType = 'localeEN' | 'localeCS' | 'localeSK';

export interface SelectObject {
  item: string;
  id: string;
}

export interface MarkerSimpleMap {
  latitude: number;
  longitude: number;
  name?: string;
  id: string;
  activityType?: ActivitiesKey;
  place?: string;
  placeId?: string;
}

export interface LastSeenData {
  [hash: string]: {
    lastMessage: Message;
    lastSeen: string;
  };
}

export interface MemberCardProps {
  member: UserWithMembershipType;
  withControls?: boolean;
  onRemoveMember?: OnRemoveMemberFunction;
  onEditMember?: OnEditMemberFunction;
  myRole: MemberType;
  myId: string;
  org: Organization;
  emojis: EmojiMap | undefined;
  index: number;
}

export type BannersKey =
  | 'eventRunning'
  | 'eventRepeating'
  | 'orgAdmin'
  | 'orgMember'
  | 'orgPowerUser'
  | 'orgTypeTeam'
  | 'orgTypeClub'
  | 'orgTypeOrganization'
  | 'joinedAlias'
  | 'queuedAlias'
  | 'confirmedAlias'
  | 'eventOrganizer'
  | 'eventTrainer'
  | 'eventForKids'
  | 'eventForAdults'
  | 'alias'
  | 'RESERVANTO'
  | 'CHYTRA_REZERVACE';
export type BannerKeyFeed = 'feedNews' | 'feedAnnouncement' | 'feedInfo';
export type ImageValuePrefix = 'SEED:' | 'FILE:';
export type ImageValueShape = `${ImageValuePrefix}${string}` | 'BASIC';
export type LocationServices = 'unset' | 'manual' | 'live';

export interface AchievementCardProps {
  title: Record<LocaleType, string>;
  description: Record<LocaleType, string>;
  currentProgress: number;
  totalProgress: number | undefined;
  level: number;
  unlocked: boolean;
  classic: boolean;
  icons: string[] | undefined;
}
