import React from 'react';
import styled from 'styled-components';

const Container: React.FC<
  { style?: React.CSSProperties } & { children: React.ReactNode }
> = ({ children, style }) => <StyledDiv style={style}>{children}</StyledDiv>;
const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  background-color: ${({ theme }) => theme.background};
`;
export default Container;
