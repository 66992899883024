import { ApiAuthErrors } from '../types/types';

export const errorObject: Record<ApiAuthErrors, ApiAuthErrors> = {
  UserNotFoundException: 'UserNotFoundException',
  NotAuthorizedException: 'NotAuthorizedException',
  PasswordResetRequiredException: 'PasswordResetRequiredException',
  UserNotConfirmedException: 'UserNotConfirmedException',
  AttributesErrorException: 'AttributesErrorException',
  ExpiredCodeException: 'ExpiredCodeException',
  InvalidPasswordException: 'InvalidParameterException',
  InvalidParameterException: 'InvalidParameterException',
  LimitExceededException: 'LimitExceededException',
  NoUserException: 'NoUserException',
  PasswordErrorException: 'PasswordErrorException',
  UserLambdaValidationException: 'UserLambdaValidationException',
  UsernameExistsException: 'UsernameExistsException',
};
