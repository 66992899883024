export const findAllAndReplace = <T>(
  array: Array<T>,
  replacement: T,
  callback: (item: T) => boolean,
): T[] => array.map((element) => (callback(element) ? replacement : element));

export const findAllAndReplaceMultiple = <T>(
  array: Array<T>,
  replacement: Array<T>,
  key: keyof T,
): T[] =>
  array.map((arr) => {
    const found = replacement.find((r) => r[key] === arr[key]);
    return found || arr;
  });
