import styled from 'styled-components';
import { closeAllModals } from '@mantine/modals';
import { cardColorPicker } from '@aclito/shared/util/cardColorPicker';
import { CloseButton, Loader } from '@mantine/core';
import { config } from '@aclito/shared/config';
import { getById } from '@aclito/shared/util/selectObject';
import { FormattedEventWithPlayers, TranslateKey } from '@aclito/shared/types';
import { useDisplayEvent } from '@aclito/shared/redux/api/eventApi';

import { HStack, VStack, Text, Icon, Title, PlainCard } from '@/components';
import { Info } from '@/features/event/screens/EventInfo/components';
import JoinLeaveButton from '@/features/event/screens/EventInfo/components/JoinLeaveButton';

interface EventModalProps {
  id: string;
  handleRedirectToOrg: (event: FormattedEventWithPlayers) => void;
  handleRedirectToLeader: (event: FormattedEventWithPlayers) => void;
  handleRedirectToMap: (event: FormattedEventWithPlayers) => void;
  navigation: (id: string) => void;
}

const EventModal: React.FC<EventModalProps> = ({
  id,
  navigation,
  handleRedirectToLeader,
  handleRedirectToOrg,
  handleRedirectToMap,
}) => {
  const { model } = useDisplayEvent(id);

  const handleOrg = (event: FormattedEventWithPlayers) => {
    handleRedirectToOrg(event);
    closeAllModals();
  };

  const handleMap = (event: FormattedEventWithPlayers) => {
    handleRedirectToMap(event);
    closeAllModals();
  };

  const handleLeader = (event: FormattedEventWithPlayers) => {
    handleRedirectToLeader(event);
    closeAllModals();
  };

  return (
    <>
      {model?.event ? (
        <PlainCard
          style={{ width: 600 }}
          $borderColor={cardColorPicker(model.event.activityType)}
        >
          <VStack spacing={15}>
            <HStack
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <VStack spacing={0}>
                <EventTitle text={model.event.name} />
                <SportTitle
                  tx={
                    getById(model.event.activityType, config.activities)
                      .item as TranslateKey
                  }
                  color={cardColorPicker(model.event.activityType)}
                />
              </VStack>
              <CloseButton
                style={{
                  position: 'absolute',
                  top: '24px',
                  right: '24px',
                }}
                onClick={() => {
                  closeAllModals();
                }}
              />
            </HStack>
            <Info
              event={model.event}
              handleRedirectToLeader={handleLeader}
              handleRedirectToOrg={handleOrg}
              handleRedirectToMap={handleMap}
            />
            <HStack
              style={{
                justifyContent: 'flex-end',
              }}
            >
              <JoinLeaveButton
                onClick={() => {
                  navigation(model.event.id);
                  closeAllModals();
                }}
                tx="modal.goToEvent"
              >
                <Icon icon="forward" color="whiteText" />
              </JoinLeaveButton>
            </HStack>
          </VStack>
        </PlainCard>
      ) : (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      )}
    </>
  );
};
const LoaderContainer = styled.div`
  width: 600px;
  height: 400px;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 10px;
`;

const SportTitle = styled(Text)<{ color: string }>`
  font-weight: bold;
  display: flex;
  justify-content: center;
  color: ${(props) => props.color};
` as typeof Text;

const EventTitle = styled(Title)`
  font-size: 1rem;
`;

export default EventModal;
