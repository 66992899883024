import {
  CreateMessageInput,
  GetMessagesByEventIdParams,
  Message,
} from '@aclito/entities';

import { ContentType, HttpClient } from '../httpClient';

export class MessageClient extends HttpClient {
  messages = {
    postMessage: (data: CreateMessageInput) =>
      this.request<Message>({
        path: `/messages`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
      }),
    getMessagesByChatId: ({
      id,
      limit,
      nextToken,
    }: GetMessagesByEventIdParams & { id: string }) =>
      this.request<{ data: Message[]; nextToken: string | null }>({
        path: `/messages/list/${id}`,
        method: 'GET',
        query: { limit, nextToken },
        type: ContentType.Json,
      }),
  };
}
