import { FlashMessageInterface } from './flashMessage';

import { colors } from '@aclito/shared/theme/colors';
import { SnackBarIconType } from '@aclito/shared/types';

export const getBackgroundColor = (type: FlashMessageInterface['type']) => {
  const backgroundColors: Record<FlashMessageInterface['type'], string> = {
    danger: colors.red,
    default: colors.red,
    info: colors.notificationInfo,
    none: colors.notificationInfo,
    success: colors.primary,
    warning: colors.orange,
  };

  return backgroundColors[type];
};

export const getIcon = (type: FlashMessageInterface['type']) => {
  const icons: Record<FlashMessageInterface['type'], SnackBarIconType> = {
    danger: 'notificationError',
    default: 'notificationError',
    info: 'notificationWarning',
    none: 'notificationWarning',
    success: 'notificationSuccess',
    warning: 'notificationWarning',
  };

  return icons[type];
};
