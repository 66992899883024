import { useEffect } from 'react';

export function useOnClickOutside<T extends Element | null>(
  refsToIgnore: React.RefObject<T>[],
  handler: (event: MouseEvent | TouchEvent) => void,
) {
  useEffect(
    () => {
      const listener = (event: MouseEvent | TouchEvent) => {
        for (let i = 0; i < refsToIgnore.length; i++) {
          // Do nothing if clicking ref's element or descendent elements
          if (
            refsToIgnore[i].current &&
            refsToIgnore[i].current?.contains(event.target as Node)
          ) {
            return;
          }
        }
        handler(event);
      };
      document.addEventListener('mousedown', listener);
      document.addEventListener('touchstart', listener);

      return () => {
        document.removeEventListener('mousedown', listener);
        document.removeEventListener('touchstart', listener);
      };
    },
    // Add ref and handler to effect dependencies
    // It's worth noting that because the passed-in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
    [refsToIgnore, handler],
  );
}

export default useOnClickOutside;
