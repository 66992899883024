import moment from 'moment';

import { useTranslate } from './useTranslate';

import { TranslateKey } from '@aclito/shared/types';

export const useTranslateDate = () => {
  const t = useTranslate();

  return (date: string, format: TranslateKey) => moment(date).format(t(format));
};
