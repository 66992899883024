import { useAppSelector } from '@aclito/shared/hooks';
import { noteSelectors } from '@aclito/shared/redux/slices/noteSlices';
import { useNotesShared } from '@aclito/shared/features/notes/useNotesShared';

import { useModal } from '@/hooks/useModal';

export const useNotes = () => {
  const isLoading = useAppSelector(noteSelectors.isLoading);

  const { openModal } = useModal();

  const openDialog = () => {
    openModal({
      txTitle: 'admin.notes.info.text',
    });
  };

  const {
    handleSubmit,
    handleAddEmail,
    handleAddTel,
    data,
    operationTable,
    handleClear,
    contactFilled,
  } = useNotesShared();
  return {
    data,
    isLoading,
    contactFilled,
    handleAddEmail,
    handleClear,
    operationTable,
    handleAddTel,
    handleSubmit,
    openDialog,
  };
};
