import { Organization } from '@aclito/entities';
import { LeaveForm } from '@aclito/shared/types';
import { useForm } from 'react-hook-form';
import { closeAllModals } from '@mantine/modals';
import {
  useJoinOrganizationMutation,
  useDeleteOrganizationMutation,
  useLeaveOrganizationMutation,
} from '@aclito/shared/redux/api/organizationApi';

import {
  ROUTE_CALENDAR,
  ROUTE_FEED_EDIT,
  ROUTE_ORGANIZATIONS,
} from '@/util/constants';
import { useNavigate } from '@/hooks/useNavigate';
import { useWebShare } from '@/hooks/useWebShare';
import { useModal } from '@/hooks/useModal';

export const useOrganizationInfoHandlers = (org: Organization) => {
  const nav = useNavigate();
  const { handleShare } = useWebShare(org, true);
  const { openConfirmModal, openModal } = useModal();
  const [deleteOrganization] = useDeleteOrganizationMutation();
  const [joinOrganization] = useJoinOrganizationMutation();
  const [leaveOrganization] = useLeaveOrganizationMutation();
  const events = [];

  const methods = useForm<LeaveForm>({
    defaultValues: { area: '' },
  });

  const handleJoin = async (cb?: () => void) => {
    if (org.contact) {
      openModal({
        withCloseButton: true,
        size: 'auto',
        padding: 10,
        type: 'grantNotes',
        values: { handleGrant: join, orgName: org.name },
      });

      cb?.();
    } else {
      openConfirmModal({
        txTitle: 'modal.confirmation',
        confirmProps: { color: 'primary' },
        txLabels: { cancel: 'close', confirm: 'org.join' },
        onConfirm: async () => {
          await join();
          cb?.();
        },
      });
    }
  };

  const join = async () => {
    closeAllModals();
    await joinOrganization({ orgId: org.id }).unwrap();
  };

  const handleLeave = () => {
    openConfirmModal({
      txTitle: 'org.info.leave.header',
      txLabels: { cancel: 'close', confirm: 'leave' },
      type: 'leaveOrg',
      values: { methods },
      onConfirm: async () => {
        await leaveOrganization({
          orgId: org.id,
          message: methods.getValues().area,
        });

        nav(-1);
      },
    });
  };

  const handleAddPost = () => nav(ROUTE_FEED_EDIT);

  const handleDelete = () => {
    //TODO: move to BE
    if (events.length) {
      openConfirmModal({
        txTitle: 'org.info.delete.permissions.title',
        type: 'deleteOrgPermission',
        txLabels: { cancel: 'close', confirm: 'cancel' },
      });
    } else {
      openConfirmModal({
        txTitle: 'delete',
        txLabels: { cancel: 'close', confirm: 'delete' },
        type: 'deleteOrgConfirm',
        onConfirm: async () => {
          nav(ROUTE_ORGANIZATIONS);
          await deleteOrganization({ orgId: org.id });
        },
      });
    }
  };

  const handleCalendar = () => {
    nav(ROUTE_CALENDAR, { state: { org } });
  };

  return {
    handleJoin,
    handleLeave,
    handleDelete,
    handleCalendar,
    handleShare,
    handleAddPost,
  };
};
