const sizeMin = {
  mobileS: 320,
  mobile: 481,
  tablet: 769,
  laptop: 1025,
  desktop: 1201,
  desktopL: 1470,
};

export const sizeMax = {
  mobileS: 319,
  mobile: 480,
  tabletS: 650,
  tablet: 768,
  laptop: 1024,
  desktop: 1200,
  desktopL: 1470,
  desktopXL: 1680,
  desktopMax: 2200,
};

export const deviceMin = {
  mobileS: `(min-width: ${sizeMin.mobileS}px)`,
  mobile: `(min-width: ${sizeMin.mobile}px)`,
  tablet: `(min-width: ${sizeMin.tablet}px)`,
  laptop: `(min-width: ${sizeMin.laptop}px)`,
  desktop: `(min-width: ${sizeMin.desktop}px)`,
  desktopL: `(min-width: ${sizeMin.desktopL})`,
  desktopXL: `(min-width: ${sizeMax.desktopXL}px)`,
};

export const deviceMax = {
  mobileS: `(max-width: ${sizeMax.mobileS}px)`,
  mobile: `(max-width: ${sizeMax.mobile}px)`,
  tabletS: `(max-width: ${sizeMax.tabletS}px)`,
  tablet: `(max-width: ${sizeMax.tablet}px)`,
  laptop: `(max-width: ${sizeMax.laptop}px)`,
  desktop: `(max-width: ${sizeMax.desktop}px)`,
  desktopL: `(max-width: ${sizeMax.desktopL}px)`,
  desktopXL: `(max-width: ${sizeMax.desktopXL}px)`,
  desktopMax: `${sizeMax.desktopMax}px`,
};

export const columnsFullWidth = {
  xs: 6,
  sm: 4,
  md: 4,
  lg: 3,
  xl: 2,
};

export const columnsThreeFourths = {
  xs: 6,
  sm: 6,
  md: 4,
  lg: 4,
  xl: 3,
};
