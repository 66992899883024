import styled from 'styled-components';
import { Box, Sx } from '@mantine/core';
import { ThemeColorsRecord } from '@aclito/shared/theme/colors';
import { deviceMax, deviceMin } from '@aclito/ui-web/utils/mediaQueries';

import { HStack, Text } from '@/components';
import AnchorLink from '@/common/Text/AnchorLink';

export const MenuItemContainer = styled(Box)`` as typeof Box;

export const menuStyle = (theme: ThemeColorsRecord): Sx => ({
  ':hover': { backgroundColor: theme.grey10 },
  padding: '0 24px',
  fontSize: 14,
  cursor: 'pointer',
});

export const activeMenuStyle = (theme: ThemeColorsRecord): Sx => ({
  borderLeft: `4px solid`,
  padding: '0 20px',
  borderLeftColor: theme.primary,
  '& svg': {
    fill: theme.primary,
  },
});

export const StyledText = styled(Text)<{
  $active?: boolean;
}>`
  color: ${({ theme, $active }) => ($active ? theme.primary : theme.grey80)};
  @media (${deviceMax.desktop}) and (${deviceMin.tablet}) {
    display: none;
  }
`;

export const StyledHStack = styled(HStack)`
  height: 40px;
  gap: 16px;
  align-items: center;
`;

export const SmallText = styled(Text)`
  color: ${({ theme }) => theme.grey50};
  font-size: 10px;
  @media (${deviceMax.desktop}) and (${deviceMin.tablet}) {
    display: none;
  }
` as typeof Text;

export const SmallAnchorText = styled(AnchorLink)`
  color: ${({ theme }) => theme.grey50};
  font-size: 10px;
  @media (${deviceMax.desktop}) and (${deviceMin.tablet}) {
    display: none;
  }
  text-decoration: underline;
  cursor: pointer;
` as typeof AnchorLink;
