import { ActivitiesKey, ValueOf } from '../types';
import { activityColors, ActivitiesColorsType } from '../theme/colors';

export const cardColorPicker = (
  type: ActivitiesKey,
): ValueOf<ActivitiesColorsType> => {
  const colors: Record<ActivitiesKey, ValueOf<ActivitiesColorsType>> = {
    icehockey: activityColors.icehockey,
    football: activityColors.football,
    volleyball: activityColors.volleyball,
    badminton: activityColors.badminton,
    running: activityColors.running,
    tennis: activityColors.tennis,
    golf: activityColors.golf,
    tabletennis: activityColors.tabletennis,
    handball: activityColors.handball,
    box: activityColors.box,
    swimming: activityColors.swimming,
    iceskating: activityColors.iceskating,
    archery: activityColors.archery,
    baseball: activityColors.baseball,
    bowling: activityColors.bowling,
    cricket: activityColors.cricket,
    curling: activityColors.curling,
    cycling: activityColors.cycling,
    darts: activityColors.darts,
    billiard: activityColors.billiard,
    wallclimbing: activityColors.wallclimbing,
    rugby: activityColors.rugby,
    skateboarding: activityColors.skateboarding,
    skiing: activityColors.skiing,
    snowboarding: activityColors.snowboarding,
    squash: activityColors.squash,
    fencing: activityColors.fencing,
    karate: activityColors.karate,
    taekwondo: activityColors.taekwondo,
    beachvolleyball: activityColors.beachvolleyball,
    chess: activityColors.chess,
    wrestling: activityColors.wrestling,
    horseriding: activityColors.horseriding,
    fitness: activityColors.fitness,
    pilates: activityColors.pilates,
    gym: activityColors.gym,
    yoga: activityColors.yoga,
    hiking: activityColors.hiking,
    basketball: activityColors.basketball,
    circletraining: activityColors.circletraining,
    personaltraining: activityColors.personaltraining,
    aerobic: activityColors.aerobic,
    spinning: activityColors.spinning,
    tabata: activityColors.tabata,
    trx: activityColors.trx,
    bosu: activityColors.bosu,
    heat: activityColors.heat,
    hiit: activityColors.hiit,
    zumba: activityColors.zumba,
    trampoline: activityColors.trampoline,
    ballet: activityColors.ballet,
    poledance: activityColors.poledance,
    hathayoga: activityColors.hathayoga,
    poweryoga: activityColors.poweryoga,
    dance: activityColors.dance,
    bachata: activityColors.bachata,
    crossfit: activityColors.crossfit,
    bodyform: activityColors.bodyform,
    any: activityColors.any,
  };
  return colors[type];
};
