import { EventFilters } from '@aclito/shared/redux/types';
import { useSelectObject } from '@aclito/shared/hooks/useSelectObject';
import moment from 'moment';
import { useSearchParams } from 'react-router-dom';

import { FilterProps } from '@/features/search/types';
import { applyQueryParams } from '@/features/search/utils/queryParams';

export const useSearchFilters = ({
  filters: filterInitialValue,
  orgOptions,
  ...props
}: FilterProps) => {
  const [, setQueryParams] = useSearchParams();
  const { translatedFilterGender, translatedActivities, translatedLevels } =
    useSelectObject();

  const clearFilter = () => {
    setQueryParams({});
    props.onClearFilter?.();
  };

  const handleFilters = (filters: EventFilters) => {
    filters.to = moment(filters.to).toISOString();
    filters.from = moment(filters.from).toISOString();
    props.onFilterChange?.(filters);
    setQueryParams((prev) => applyQueryParams(prev, filters));
  };

  return {
    filterInitialValue,
    handleFilters,
    clearFilter,
    orgOptions,
    translatedActivities,
    translatedLevels,
    translatedFilterGender,
    applyQueryParams,
  };
};
