import React from 'react';
import styled from 'styled-components';

import { Avatar } from '../Avatar';
import Image from '../Image';

interface ImagePreviewProps {
  preview: string | Blob;
  circular: boolean;
}

const ImagePreview: React.FC<ImagePreviewProps> = ({ preview, circular }) => {
  return typeof preview === 'string' ? (
    circular ? (
      <Avatar image={`SEED:${preview}`} size={180} />
    ) : (
      <Image image={`SEED:${preview}`} height={200} width={200} />
    )
  ) : (
    <PreviewImage circular={circular} src={URL.createObjectURL(preview)} />
  );
};

const PreviewImage = styled.img<{ circular?: boolean }>`
  max-width: 200px;
  max-height: 200px;
  border-radius: ${({ circular }) => (circular ? '50%' : 0)};
  overflow: hidden;
  border: 1px solid ${({ theme }) => theme.grey20};
`;

export default ImagePreview;
