import { GoogleMap } from '@react-google-maps/api';
import { Event } from '@aclito/entities';
import { MutableRefObject } from 'react';

export const handleClusterClick = <T extends Event[]>(
  location: { lat: string; lon: string },
  data: T,
  mapRef: MutableRefObject<GoogleMap>,
) => {
  const eventsAtMarker = data.filter(
    (e) =>
      e.place?.location.lat.toFixed(4) === location.lat &&
      e.place?.location.lon.toFixed(4) === location.lon,
  );

  mapRef.current?.state.map?.panTo({
    lng: parseFloat(location.lon),
    lat: parseFloat(location.lat),
  });
  return eventsAtMarker;
};
