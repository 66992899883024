import { useEffect, useState } from 'react';
import { Notification } from '@aclito/entities';
import { NotificationType } from '@aclito/enums';

import { TranslateKey } from '../../../types';

import { useAppSelector } from '@aclito/shared/hooks';
import { notificationSelectors } from '@aclito/shared/redux/slices/notificationsSlices';

export type BinType = 'org' | 'event' | 'other';

type NotificationBins = Record<BinType, Partial<NotificationType>[]>;

export const useFilterNotifications = () => {
  const notifications = useAppSelector(notificationSelectors.getNotification);
  const [chipValue, setChipValue] = useState<Array<BinType>>([]);
  const [filter, setFilter] = useState(false);
  const [filteredNotifications, setFilteredNotifications] =
    useState(notifications);

  useEffect(() => {
    setFilteredNotifications(notifications);
  }, [notifications]);

  const handleChipFilter = (values: BinType[]) => {
    setChipValue(values);
    const result = _whichNotificationsToDisplay(values);
    setFilteredNotifications(result);
  };
  const handleFilter = () => setFilter((prev) => !prev);

  const _whichNotificationsToDisplay = (values: BinType[]) => {
    // iterate over selected types and add notification with the matching type to shared array
    const filtered: Notification[][] = [[]];
    for (let index = 0; index < values.length; index++) {
      const bin = _bins[values[index]];
      const temp = notifications.filter((n) => bin.includes(n.type));
      filtered.push(temp);
    }
    return values.length ? filtered.flat() : notifications;
  };

  const _makeNotificationBins = () => {
    const options = Object.entries(NotificationType);
    const bins: NotificationBins = {
      event: [],
      org: [],
      other: [],
    };
    for (let index = 0; index < options.length; index++) {
      const [, type] = options[index];
      if (type.startsWith('EVENT')) {
        bins.event.push(type);
      } else if (type.startsWith('ORG')) {
        bins.org.push(type);
      } else {
        bins.other.push(type);
      }
    }
    return bins;
  };

  const _bins = _makeNotificationBins();
  const options = Object.keys(_bins) as Array<keyof typeof _bins>;
  const translations: Record<BinType, TranslateKey> = {
    event: 'notification.filter.event',
    org: 'notification.filter.org',
    other: 'notification.filter.other',
  };

  return {
    filteredNotifications,
    filter,
    handleFilter,
    handleChipFilter,
    chipValue,
    options,
    translations,
  };
};
