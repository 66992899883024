import { useTranslate } from '@aclito/shared/hooks';
import { SubmitHandler } from 'react-hook-form';
import {
  AlertErrorAvailabilityKeys,
  AvailabilityForm,
} from '@aclito/shared/types';
import { useFormErrorMap } from '@aclito/shared/hooks/useFormErrorMap';
import { showNotification } from '@aclito/ui-web/utils/showNotification';

import { useShowTranslatedNotification } from '@/hooks';
import { useNavigate } from '@/hooks/useNavigate';

export const useAvailabilityCreateEdit = (
  handleSubmit: (formValues: AvailabilityForm) => Promise<void>,
  alreadyHasEvent: boolean,
  alreadyHasAvailability: boolean,
) => {
  const t = useTranslate();

  const showMessage = useShowTranslatedNotification();

  const nav = useNavigate();
  const navBack = () => {
    nav(-1);
  };

  const onSubmit: SubmitHandler<AvailabilityForm> = async (values) => {
    if (alreadyHasEvent) {
      showMessage('availabilityEditHasEvent');
      return;
    }
    if (alreadyHasAvailability) {
      showMessage('availabilityEditHasAvailability');
      return;
    }

    await handleSubmit(values);
  };

  const tx: Record<AlertErrorAvailabilityKeys, string> = {
    date: t('events.new.date'),
    repeatAfter: t('availability.new.repetitionInterval'),
    numberOfAvailabilities: t('availability.new.numberOfAvailabilities'),
    duration: t('events.new.duration'),
  };
  const { onError } = useFormErrorMap(tx, showNotification);

  return { onSubmit, onError, navBack };
};
