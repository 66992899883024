import React from 'react';
import { useTranslate } from '@aclito/shared/hooks/useTranslate';
import { Divider, LoadingOverlay } from '@mantine/core';
import { useAppSelector } from '@aclito/shared/hooks';
import { profileSelectors } from '@aclito/shared/redux/slices/profileSlices';
import { EventForm } from '@aclito/shared/types';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTheme } from 'styled-components';
import { StyledTitle } from '@aclito/ui-web/utils/styles';
import { sizeMax } from '@aclito/ui-web/utils/mediaQueries';

import { useEventCreateEdit } from '../../hooks/useEventCreateEdit';

import { useNewEvent } from './hooks/useNewEvent';

import { TypedLocation } from '@/hooks/useLocation';
import {
  SectionGrid,
  useStepper,
  Stepper,
  VStack,
  ActionButton,
  TitleBar,
  StepperButtons,
} from '@/components';
import Description from '@/common/EventForm/Description';
import Place from '@/common/EventForm/Place';
import DateForm from '@/common/EventForm/DateForm';
import { QueryNewEvent } from '@/util/types';
import withRouter from '@/navigation/withRouter';

type EventNewProps = TypedLocation<void, QueryNewEvent>;

const EventNew: React.FC = (props: EventNewProps) => {
  const t = useTranslate();
  const userInfo = useAppSelector(profileSelectors.userInfo);
  const {
    initialValues,
    newEventValidationSchema,
    allActivities,
    possibleGenders,
    levelOptions,
    createNewEvent,
    isLoading,
    orgOptions,
  } = useNewEvent(userInfo, props.query?.org);

  const { onSubmit, onError, navBack } = useEventCreateEdit(createNewEvent);

  const methods = useForm<EventForm>({
    defaultValues: initialValues,
    //@ts-ignore
    resolver: yupResolver(newEventValidationSchema),
  });

  const { active, setActive, nextStep, prevStep } = useStepper();

  const steps = [
    {
      component: (
        <Description
          {...{ allActivities, possibleGenders, levelOptions, orgOptions }}
          orgId={props.query?.org}
        />
      ),
      label: t('org.info.description'),
    },
    {
      component: <Place />,
      label: t('events.new.place'),
    },
    {
      component: <DateForm showRepeating={true} />,
      label: t('events.new.date'),
    },
  ];

  const theme = useTheme();

  return (
    <SectionGrid
      style={{ alignSelf: 'center', maxWidth: sizeMax.tablet }}
      $isOpen={false}
    >
      <TitleBar>
        <StyledTitle tx="nav.eventInfo" />
        <ActionButton
          onClick={navBack}
          bg={'red10'}
          textcolor={'red'}
          iconType={'close'}
        />
      </TitleBar>

      <FormProvider {...methods}>
        <VStack>
          <LoadingOverlay visible={isLoading} />
          <Stepper
            {...{
              active,
              steps,
              setActive,
              nextStep,
              prevStep,
            }}
          />
          <Divider color={theme.grey10} />
          <StepperButtons
            {...{
              active,
              steps: steps.length,
              nextStep,
              onSubmit,
              onError,
              prevStep,
            }}
          />
        </VStack>
      </FormProvider>
    </SectionGrid>
  );
};
export default withRouter(EventNew);
