import React from 'react';
import { TranslateKey } from '@aclito/shared/types';
import { useTheme } from 'styled-components';

import { Icon, IconTypes, HStack, VStack, Text } from '@/components';

export type BuilderData = {
  icon?: IconTypes;
  label: TranslateKey;
  onPress?: () => void;
  text: ({ tx: TranslateKey } | { text: string }) & {
    style?: React.CSSProperties;
  };
  testID?: string;
  vertical?: boolean;
  visible?: boolean;
};

type InfoBuilderProps = {
  data: BuilderData[];
  hideCollapsible?: boolean;
  sectionTx?: TranslateKey;
};

/**
 * InfoBuilder builds a list of information items based on the provided data.
 * @param {Object} props - The props object containing the data array.
 * @param {Array<InfoBuilderProps>} props.data - An array of information items.
 * @returns {JSX.Element} - The JSX element representing the list of information items.
 */
const InfoBuilder: React.FC<InfoBuilderProps> = ({ data }) => {
  data.forEach((item) => {
    if (item.visible === undefined || item.visible === null) {
      item.visible = true;
    }
  });
  const theme = useTheme();

  return (
    <>
      {data.map((item, i) => {
        const Stack = item.vertical ? VStack : HStack;
        return (
          item.visible && (
            <div key={i}>
              <div onClick={item.onPress}>
                <HStack
                  style={{
                    alignItems: 'center',
                    padding: 8,
                    borderBottom: 'solid 1px',
                    borderBottomColor: theme.grey10,
                  }}
                >
                  <Stack
                    style={{
                      flex: 1,
                    }}
                    spacing={8}
                  >
                    <HStack spacing={8} style={{ flex: 1 }}>
                      {item.icon && (
                        <Icon icon={item.icon} size={20} color={'grey60'} />
                      )}
                      <Text
                        tx={item.label}
                        size={'xs'}
                        style={{
                          color: theme.grey50,
                          flex: 1,
                        }}
                      />
                    </HStack>
                    <HStack
                      spacing={8}
                      style={{
                        flex: 1,
                        cursor: item.onPress ? 'pointer' : 'default',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Text
                        {...item.text}
                        size={'sm'}
                        style={{ color: theme.grey80, ...item.text.style }}
                      />
                      {item.onPress && (
                        <Icon
                          icon="rightArrow"
                          data-testid={item.testID}
                          color={'grey40'}
                          size="20"
                        />
                      )}
                    </HStack>
                  </Stack>
                </HStack>
              </div>
            </div>
          )
        );
      })}
    </>
  );
};

export default InfoBuilder;
