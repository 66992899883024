import { ROUTE_EMBEDDED_CALENDAR } from '@/util/constants';
import { useModal } from '@/hooks/useModal';

export const useEmbed = () => {
  const { openModal } = useModal();

  const makeUrl = (orgId: string) =>
    `${window.location.protocol}//${window.location.host}${ROUTE_EMBEDDED_CALENDAR}?orgId=${orgId}`;

  const embedModal = async (orgId: string) => {
    const url = makeUrl(orgId);
    openModal({
      txTitle: 'calendar.embed',
      type: 'embedCal',
      values: { url },
    });
  };

  return { embedModal };
};
