import styled from 'styled-components';
import { ThemeColorsRecord } from '@aclito/shared/theme/colors';

import { HStack } from '../components/Layout';
import { Title } from '../components/Title';

export const StyledTitle = styled(Title)`
  color: ${({ theme }) => theme.grey80};
  font-size: 25px;
`;

export const HStackButtons = styled(HStack)`
  display: flex;
  justify-content: space-between;
  .next {
    margin-left: auto;
  }
  .back {
    margin-right: auto;
  }
`;

export const getCommonInputLabelStyle = (theme: ThemeColorsRecord) => ({
  color: theme.grey50,
  fontFamily: 'Roboto',
  fontSize: ' 0.8rem',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
});
