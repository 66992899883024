import {
  CreateOrganizationInput,
  DeleteOrganizationInput,
  GetAllOrganizationsParams,
  GetMyOrganizationsParams,
  GetOrganizationParams,
  Organization,
  UpdateOrganizationInput,
  UserInfo,
  UpdateOrganizationMemberParams,
} from '@aclito/entities';

import { ContentType, HttpClient } from '../httpClient';

export class OrganizationClient extends HttpClient {
  organizations = {
    createOrganization: (data: CreateOrganizationInput) =>
      this.request<Organization>({
        path: '/organization',
        method: 'POST',
        body: data,
        type: ContentType.Json,
      }),

    updateOrganization: (data: UpdateOrganizationInput) =>
      this.request<Organization>({
        path: '/organization',
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
      }),

    joinOrganization: (orgId: string) =>
      this.request<Organization>({
        path: `/organization/join/${orgId}`,
        method: 'PATCH',
        type: ContentType.Json,
      }),

    leaveOrganization: (orgId: string, data: { message: string }) =>
      this.request<Organization>({
        path: `/organization/leave/${orgId}`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
      }),

    updateOrganizationMember: (
      orgId: string,
      data: UpdateOrganizationMemberParams,
    ) =>
      this.request<Organization>({
        path: `/organization/role/${orgId}`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
      }),
    deleteOrganization: (data: DeleteOrganizationInput) =>
      this.request<Organization>({
        path: `/organization/single/${data.orgId}`,
        method: 'DELETE',
        type: ContentType.Json,
      }),
    getOrganization: (query: GetOrganizationParams) =>
      this.request<{
        org: Organization;
        members: UserInfo[];
      }>({
        path: `/organization/single/${query.orgId}`,
        method: 'GET',
        type: ContentType.Json,
      }),
    getMyOrganizations: (query: GetMyOrganizationsParams) =>
      this.request<{ data: Organization[]; nextToken: string }>({
        path: '/organization/my-orgs',
        method: 'GET',
        query,
        type: ContentType.Json,
      }),
    getAllOrganizations: (query: GetAllOrganizationsParams) =>
      this.request<{ data: Organization[]; nextToken: string | null }>({
        path: '/organization/all-orgs',
        method: 'GET',
        query,
        type: ContentType.Json,
      }),
  };
}
