import { useAppSelector } from '@aclito/shared/hooks';
import { notificationSelectors } from '@aclito/shared/redux/slices/notificationsSlices';
import { Variants } from 'framer-motion';
import useMeasure from 'react-use-measure';

export const useNotificationAnimation = (filter: boolean) => {
  const notifications = useAppSelector(notificationSelectors.getNotification);

  const [ref, bounds] = useMeasure();

  const height = notifications.length ? bounds.height + 20 : 0.1;
  const containerVariants: Variants = {
    init: { y: 0 },
    enter: { y: filter ? height : 0, transition: { duration: 0.25 } },
  };

  const filterVariants: Variants = {
    init: { opacity: 0, y: 0, zIndex: -1 },
    enter: {
      opacity: 1,
      y: filter ? -height : 0,
      transition: { delay: 0.3 },
    },
    exit: {
      opacity: 0,
      y: height,
      transition: { duration: 0.5 },
    },
  };

  const cardVariant: Variants = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0, transition: { duration: 0.1 } },
  };
  return { containerVariants, filterVariants, cardVariant, ref, bounds };
};
