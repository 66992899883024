import React from 'react';
import styled from 'styled-components';

import { VStack, SmallButton, Text } from '@/components';
export interface ErrorDetailsProps {
  error: Error;
  errorInfo: string;
  resetError(): void;
}
const Fallback: React.FC<ErrorDetailsProps> = (props) => {
  return (
    <VStack
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 64,
      }}
    >
      <Heading tx="errorScreen.title" />
      <Text style={{ textAlign: 'center' }} tx="errorScreen.friendlySubtitle" />

      <ErrorSection>
        <ErrorContent weight="bold" text={`${props.error}`.trim()} />
        <ErrorBacktrace text={`${props.errorInfo}`.trim()} />
      </ErrorSection>
      <SmallButton
        bg="primary"
        tx={'errorScreen.reset'}
        onClick={window.location.reload}
      />
    </VStack>
  );
};

const Heading = styled(Text)`
  margin-bottom: 16px;
  color: ${({ theme }) => theme.error};
`;

const ErrorContent = styled(Text)`
  color: ${({ theme }) => theme.error};
`;

const ErrorBacktrace = styled(Text)`
  margin-top: 16px;
  color: ${({ theme }) => theme.error};
`;

const ErrorSection = styled.div`
  margin: 32px;
  background-color: ${({ theme }) => theme.grey20};
  padding: 16px;
  border-radius: 10px;
  max-height: 60vh;
  overflow: auto;
`;

export default Fallback;
