import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';

import { HStack } from '../components/Layout';
import { SubmitButton } from '../components/Button';
import { SmallButtonProps } from '../components/Button/SmallButton';

const Button: React.FC<PropsWithChildren<Omit<SmallButtonProps, 'bg'>>> = ({
  children,
  ...rest
}) => {
  return (
    // @ts-ignore
    <StyledButton bg={'primary'} {...rest}>
      <ButtonContent spacing={8}>{children}</ButtonContent>
    </StyledButton>
  );
};
const StyledButton = styled(SubmitButton)`
  width: 100%;
  height: auto;
  background-color: ${({ theme }) => theme.primary};
  margin-top: 24px;
  padding: 12px 24px;
  border-radius: 10px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
  color: white;
  &:hover {
    filter: brightness(95%);
  }
  cursor: pointer;
` as typeof SubmitButton;

const ButtonContent = styled(HStack)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 16px;
`;

export default Button;
