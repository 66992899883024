import { useState, useEffect } from 'react';
import type { UserInfo } from '@aclito/entities';

import { useSelectObject } from '../../../hooks';
import { config } from '../../../config';
import { SelectObject } from '../../../types';

export const useGetActivityAndGenders = (userInfo: UserInfo) => {
  const { translate, translatedActivities, translatedFilterGender } =
    useSelectObject();
  const [allActivities, setAllActivities] =
    useState<SelectObject[]>(translatedActivities);

  useEffect(() => {
    if (userInfo) {
      const userActivity = translate(
        userInfo.activities.map((id) => config.activities[id]),
      );
      setAllActivities(
        [...userActivity, ...translatedActivities].filter(
          (value, index, self) =>
            index === self.findIndex((t) => t.id === value.id),
        ),
      );
    }
  }, [userInfo]);
  return { allActivities, possibleGenders: translatedFilterGender };
};
