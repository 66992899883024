import { Auth, CognitoUser } from '@aws-amplify/auth';

import { ApiSignIn } from '../../types/authentication';
import { errorObject } from '../../utils/errorObject';
/**
 *
 * @param body User credentials
 * @returns A promise of User if success, appropriate error message otherwise
 */
export const makeSignIn = async (body: ApiSignIn): Promise<CognitoUser> => {
  try {
    return await Auth.signIn({
      username: body.username,
      password: body.password,
    });
  } catch (error) {
    //@ts-ignore
    return Promise.reject(errorObject[error.code]);
  }
};
