import { AnyAction, compose, Middleware } from '@reduxjs/toolkit';
import { ToolkitStore } from '@reduxjs/toolkit/dist/configureStore';
import { Dispatch } from 'redux';
//https://github.com/pofigizm/redux-dynamic-middlewares/blob/master/src/index.js
const createDynamicMiddlewares = () => {
  let allDynamicMiddlewares: Middleware[] = [];
  let allAppliedDynamicMiddlewares: ((
    next: Dispatch,
  ) => (action: AnyAction) => unknown)[] = [];
  let store: ToolkitStore;

  const enhancer = (_store: ToolkitStore) => {
    store = _store;
    return (next: Dispatch<AnyAction>) => (action: AnyAction) => {
      // eslint-disable-next-line @typescript-eslint/ban-types
      return compose<Function>(...allAppliedDynamicMiddlewares)(next)(action);
    };
  };

  const addMiddleware = (...middlewares: Middleware[]) => {
    allAppliedDynamicMiddlewares.push(
      ...middlewares.map((middleware) => middleware(store)),
    );
    allDynamicMiddlewares.push(...middlewares);
  };

  const removeMiddleware = (middleware: Middleware) => {
    const index = allDynamicMiddlewares.findIndex((d) => d === middleware);

    if (index === -1) {
      console.warn('Middleware does not exist!', middleware.name);

      return;
    }

    allDynamicMiddlewares = allDynamicMiddlewares.filter(
      (_, mdwIndex) => mdwIndex !== index,
    );
    allAppliedDynamicMiddlewares = allAppliedDynamicMiddlewares.filter(
      (_, mdwIndex) => mdwIndex !== index,
    );
  };

  const resetMiddlewares = () => {
    allAppliedDynamicMiddlewares = [];
    allDynamicMiddlewares = [];
  };

  return {
    enhancer,
    addMiddleware,
    removeMiddleware,
    resetMiddlewares,
  };
};

const dynamicMiddlewaresInstance = createDynamicMiddlewares();

export default dynamicMiddlewaresInstance.enhancer;

export const { addMiddleware, removeMiddleware, resetMiddlewares } =
  dynamicMiddlewaresInstance;

export { createDynamicMiddlewares };
