import { debounce } from '@aclito/shared/util/debounce';
import { useRef, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

export const useSearchBar = (
  onQueryChange: (query: string) => void,
  deb: number,
) => {
  const ref = useRef<HTMLInputElement>();
  const [, setQueryParams] = useSearchParams();
  const handleDebounceQuery = useMemo(
    () =>
      debounce((value: string) => {
        setQueryParams((prev) => {
          prev.set('q', value);
          return prev.toString();
        });
        onQueryChange?.(value);
      }, deb),
    [],
  );

  return { ref, handleDebounceQuery };
};
