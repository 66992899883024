/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: 'eu-central-1',
  aws_appsync_region: 'eu-central-1',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  aws_cognito_identity_pool_id:
    'eu-central-1:d8496cf6-b2ae-4941-a881-e563e7a6dd2d',
  aws_cognito_region: 'eu-central-1',
  aws_user_pools_id: 'eu-central-1_H9yFDn3sL',
  aws_user_pools_web_client_id: '52e926jsdl7lf2os1asildu5he',
  oauth: {
    domain: 'ubs-fed.auth.eu-central-1.amazoncognito.com',
    scope: [
      'aws.cognito.signin.user.admin',
      'email',
      'openid',
      'phone',
      'profile',
    ],
    redirectSignIn:
      'aclito://,aclitolite://,exp://127.0.0.1:19000/--/,http://localhost:3000/sign-in,https://app.aclito.com/sign-in,https://release-aerope.drg2myj4sum0e.amplifyapp.com/sign-in',
    redirectSignOut:
      'aclito://,aclitolite://,exp://127.0.0.1:19000/--/,http://localhost:3000/sign-in,https://app.aclito.com/sign-in,https://release-aerope.drg2myj4sum0e.amplifyapp.com/sign-in',
    responseType: 'code',
  },
  federationTarget: 'COGNITO_USER_POOLS',
  aws_cognito_username_attributes: ['EMAIL'],
  aws_cognito_social_providers: ['FACEBOOK', 'GOOGLE', 'APPLE'],
  aws_cognito_signup_attributes: [],
  aws_cognito_mfa_configuration: 'OFF',
  aws_cognito_mfa_types: [],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: ['REQUIRES_LOWERCASE', 'REQUIRES_NUMBERS'],
  },
  aws_cognito_verification_mechanisms: ['EMAIL'],
};

export default awsmobile;
