import React from 'react';
import { Organization } from '@aclito/entities';
import styled from 'styled-components';

import OrgMemberCards from '@/components/Cards/OrgMemberCards';
import { useOrgMembers } from '@/features/organization/screens/OrganizationInfo/hooks/useOrgMembers';
import { useOrganizationInfoSection } from '@/features/organization/screens/OrganizationInfo/hooks/useOrganizationInfoSection';

type MembersProps = {
  displayedOrg: Organization;
};

const Members: React.FC<MembersProps> = ({ displayedOrg }) => {
  const { myRole, adminInfo, memberInfo, powerUsersInfo } =
    useOrganizationInfoSection(displayedOrg);
  const { onEditMember, onRemoveMember } = useOrgMembers(displayedOrg, myRole);

  const members = [...adminInfo, ...powerUsersInfo, ...memberInfo];

  return (
    <Content>
      <OrgMemberCards
        withControls
        {...{
          members,
          myRole,
          onEditMember,
          onRemoveMember,
          org: displayedOrg,
        }}
      />
    </Content>
  );
};

const Content = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  & > :first-child {
    margin-top: 1rem;
  }
`;

export default Members;
