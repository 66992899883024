import { EventFilters } from '@aclito/shared/redux/types';
import { getById } from '@aclito/shared/util/selectObject';
import { config } from '@aclito/shared/config';
import moment from 'moment/moment';
import { SelectObject } from '@aclito/shared/types';
import { FiltersInitialState } from '@aclito/shared/redux/initialStates';
import { EventTags } from '@aclito/shared/features/event/types/types';

import { QuerySearch } from '@/util/types';

export const applyQueryParams = (
  prev: URLSearchParams,
  filter: EventFilters,
) => {
  const { level, price, multisport, freeOnly, distance, ...rest } = filter;
  const params = {
    ...rest,
    multisport: multisport?.toString(),
    freeOnly: freeOnly?.toString(),
    gender: filter.gender?.id,
    activityType: filter.activityType?.id,
    org: filter.org?.id,
    tags: JSON.stringify(filter.tags),
    level: JSON.stringify(filter.level),
    price: JSON.stringify(filter.price),
  };

  const queryParams = new URLSearchParams();

  if (params.multisport !== undefined)
    queryParams.append('multisport', params.multisport);
  if (params.freeOnly !== undefined)
    queryParams.append('freeOnly', params.freeOnly);
  if (params.gender !== undefined) queryParams.append('gender', params.gender);
  if (params.level !== undefined) queryParams.append('level', params.level);
  if (params.price !== undefined) queryParams.append('price', params.price);
  if (params.activityType !== undefined)
    queryParams.append('activityType', params.activityType);
  if (params.org !== undefined) queryParams.append('org', params.org);
  if (params.tags !== undefined) queryParams.append('tags', params.tags);
  if (params.to !== undefined) queryParams.append('to', params.to);
  if (params.from !== undefined) queryParams.append('from', params.from);
  if (distance !== undefined) queryParams.append('distance', distance);
  if (params.location !== undefined)
    queryParams.append('lat', params.location.lat.toString());
  if (params.location !== undefined)
    queryParams.append('lon', params.location.lon.toString());

  const query = prev.get('q') || params.query;
  if (query) {
    queryParams.append('q', query);
  }
  return queryParams.toString();
};

export const objectifyQuerySearch = (
  query: QuerySearch,
  orgOptions: SelectObject[],
): EventFilters => {
  const q = query.q || undefined;
  const org = query.org
    ? orgOptions.some((o) => o.id === query.org)
      ? orgOptions.find((o) => o.id === query.org)
      : FiltersInitialState.org
    : FiltersInitialState.org;

  const activityType = query.activityType
    ? getById(query.activityType, config.activities)
    : getById(FiltersInitialState.activityType?.id ?? 'any', config.activities);

  const from = query.from ? _getDate(query.from) : FiltersInitialState.from;

  const to = query.to ? _getDate(query.to) : FiltersInitialState.to;

  const gender = query.gender
    ? getById(query.gender, config.gender.filter)
    : getById(FiltersInitialState.gender?.id ?? 'any', config.gender.filter);

  const level = query.level?.length
    ? (JSON.parse(query.level) as SelectObject[])
    : FiltersInitialState.level;

  const price = query.price?.length
    ? (JSON.parse(query.price) as number[])
    : FiltersInitialState.price;

  const multisport = query.multisport
    ? _getBoolean(query.multisport as unknown as string)
    : FiltersInitialState.multisport;

  const distance = query.distance || FiltersInitialState.distance;

  const freeOnly = query.freeOnly
    ? _getBoolean(query.freeOnly as unknown as string)
    : FiltersInitialState.freeOnly;

  const tags = query.tags
    ? (JSON.parse(query.tags) as EventTags)
    : FiltersInitialState.tags;

  const location =
    query.lat && query.lon
      ? { lat: Number(query.lat), lon: Number(query.lon) }
      : undefined;

  return {
    org,
    activityType,
    from,
    to,
    gender,
    level,
    price,
    distance,
    multisport,
    location,
    tags,
    freeOnly,
    query: q,
    isDirty: true,
  };
};

const _getBoolean = (value: string) => value === 'true';

const _getDate = (isoString: string) => {
  return moment(isoString).startOf('minutes').toISOString();
};
