import React, { useRef } from 'react';

import { Icon } from '../Icons/Icon';

import Input from './Input';
import { InputProps } from './types';

interface SearchBarProps extends InputProps {
  onClear: () => void;
}

const SearchBar: React.FC<SearchBarProps> = ({ onClear, ...rest }) => {
  const ref = useRef<HTMLInputElement>(null);
  const handleClear = () => {
    onClear?.();
    if (ref?.current?.value) ref.current.value = '';
  };
  return (
    <Input
      // @ts-ignore
      ref={ref}
      txPlaceholder="search"
      icon={<Icon icon="search" color="primary" size={20} />}
      rightSection={
        <Icon icon="close" onClick={handleClear} color="primary" size={20} />
      }
      {...rest}
    />
  );
};

export default SearchBar;
