import { Center, Divider, Loader, Space } from '@mantine/core';

import NoteItem from '@/features/notes/screens/Notes/components/NoteItem';
import { useNotes } from '@/features/notes/screens/Notes/hooks/useNotes';

const NotesListing = () => {
  const { data, isLoading, operationTable } = useNotes();

  const handleConfirmClick = (text: string, index: number) => {
    operationTable[index as 0 | 1](text);
  };

  if (isLoading) {
    return (
      <Center>
        <Loader />
      </Center>
    );
  }
  return (
    <>
      {data.map((item, index) => (
        <>
          <NoteItem
            item={item}
            index={index}
            error={item.error}
            handleConfirmClick={handleConfirmClick}
          />

          {index < data.length - 1 && (
            <>
              <Space h={8} />
              <Divider />
              <Space h={16} />
            </>
          )}
        </>
      ))}
    </>
  );
};

export default NotesListing;
