import { Organization } from '@aclito/entities';

import { UseNavigateType } from '@/hooks/useNavigate';

interface OrganizationListingProps {
  selected: string | undefined;
  setSelected: (val: Organization) => void;
  navigation: UseNavigateType;
  search: string;
  tabIndex: OrgsTabType;
  getSelectedOrgs: Organization[];
  setTabIndex: React.Dispatch<React.SetStateAction<OrgsTabType>>;
  handleSearch: (val: string) => void;
  resetSearch: () => void;
  loading: boolean;
  open: boolean;
  toggle: () => void;
  nextToken: string | undefined | null;
  handleShowMore: () => void;
}

interface OrganizationInfoProps {
  selected: string;
  toggle: () => void;
}

export enum OrgsTabType {
  MYORGS = 'myOrgs',
  ALLORGS = 'allOrgs',
}

export type { OrganizationListingProps, OrganizationInfoProps };
