import moment from 'moment';
import { useState } from 'react';
import { ToolbarProps, View, Navigate } from 'aclito-calendar';
import styled from 'styled-components';
import { useTranslate } from '@aclito/shared/hooks';

import { ActionButton, HStack, Button, Select, Title } from '@/components';
import { formattedWeekDate } from '@/util/formattedWeekDate';

const CustomToolbar = ({ onView, date, onNavigate }: ToolbarProps) => {
  const [tabIndex, setTabIndex] = useState('0');
  const goToView = (timeFormat: View) => {
    onView(timeFormat);
  };
  const goToBack = () => {
    onNavigate(Navigate.PREVIOUS);
  };

  const goToNext = () => {
    onNavigate(Navigate.NEXT);
  };

  const goToToday = () => {
    onNavigate(Navigate.TODAY);
  };

  const handleTabIndexChange = (index: string) => {
    setTabIndex(index);
    switch (index) {
      case '0':
        goToView('month');
        break;
      case '1':
        goToView('week');
        break;

      case '2':
        goToView('day');
        break;
      default:
        return null;
    }
    return null;
  };

  const dateFormat = (index: string) => {
    switch (index) {
      case '0':
        return moment(date).format('MMM yyyy');
      case '1':
        return formattedWeekDate(date);
      case '2':
        return moment(date).format('ddd, D. MMMM yyyy');
      default:
        return null;
    }
  };

  const t = useTranslate();
  return (
    <ToolBar>
      <Select
        clearable={false}
        value={tabIndex}
        onChange={handleTabIndexChange}
        options={[
          { id: '0', item: t('calendar.month') },
          { id: '1', item: t('calendar.week') },
          { id: '2', item: t('calendar.day') },
        ]}
      />

      <Title text={dateFormat(tabIndex) || undefined} />
      <StyledButtonBox>
        <HStack spacing={8}>
          <ActionButton
            iconType="leftArrow"
            data-testid="leftArrow"
            onClick={goToBack}
          />
          <ActionButton
            iconType="rightArrow"
            data-testid="rightArrow"
            onClick={goToNext}
          />
          <TodayButton tx="calendar.today" onClick={goToToday} />
        </HStack>
      </StyledButtonBox>
    </ToolBar>
  );
};

const TodayButton = styled(Button)`
  font-size: 16px;
  border-radius: 10px;
  height: 100%;
  :hover {
    opacity: 0.95;
  }
` as typeof Button;

const ToolBar = styled(HStack)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`;

const StyledButtonBox = styled.div`
  display: flex;
`;

export default CustomToolbar;
