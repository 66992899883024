import * as React from 'react';
import { useController } from 'react-hook-form';

import Switch from './Switch';
import { SwitchProps } from './types';

const ValidatedSwitch: React.FC<SwitchProps & { name: string }> = ({
  name,
  ...props
}) => {
  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController({ name });
  return (
    <Switch
      value={value}
      checked={value}
      error={error?.message}
      {...props}
      onChange={(val) => {
        onChange(val.target.checked);
        if (props.onChange) {
          props.onChange(val);
        }
      }}
    />
  );
};

export default ValidatedSwitch;
