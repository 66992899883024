import { DateTimePicker as MDateTimePicker } from '@mantine/dates';
import React from 'react';
import { useTranslate } from '@aclito/shared/hooks';
import { useTheme } from 'styled-components';

import { getCommonInputLabelStyle } from '../../utils/styles';
import { Icon } from '../Icons/Icon';

import { DateTimePickerProps } from './types';

const DateTimePicker: React.FC<DateTimePickerProps> = (props) => {
  const {
    txPlaceholder,
    placeholder,
    txLabel,
    label,
    clearable = true,
    ...rest
  } = props;
  const t = useTranslate();
  const theme = useTheme();
  const i18nDateLabel = txLabel && t(txLabel);
  const dateLabelContent = i18nDateLabel || label;

  const i18nDatePlaceholder = txPlaceholder && t(txPlaceholder);
  const datePlaceholderContent = i18nDatePlaceholder || placeholder;

  return (
    <MDateTimePicker
      onPointerEnterCapture={() => {
        return;
      }}
      onPointerLeaveCapture={() => {
        return;
      }}
      {...rest}
      clearable={clearable}
      radius={10}
      size="md"
      valueFormat={t('date.picker')}
      styles={{
        input: { backgroundColor: theme.white, color: theme.grey80 },
        label: { ...getCommonInputLabelStyle(theme) },
      }}
      variant="filled"
      label={dateLabelContent}
      placeholder={datePlaceholderContent}
      icon={<Icon icon={'datePicker'} color="grey50" />}
    />
  );
};

export default DateTimePicker;
