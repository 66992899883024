import { closeAllModals } from '@mantine/modals';
import type { Event } from '@aclito/entities';
import { FormattedEventWithPlayers } from '@aclito/shared/types';
import { useDeleteEventMutation } from '@aclito/shared/redux/api/eventApi';

import { useNavigate } from '@/hooks/useNavigate';
import { ROUTE_EVENT_EDIT } from '@/util/constants';
import { useModal } from '@/hooks/useModal';

export const useEventInfoEditDelete = () => {
  const [deleteEventTrigger] = useDeleteEventMutation();
  const nav = useNavigate();
  const { openConfirmModal } = useModal();

  const handleEdit = (event: Event) => {
    nav(ROUTE_EVENT_EDIT, { state: { event, orgId: event.eventOrgId } });
  };

  const handleDelete = (event: FormattedEventWithPlayers) => {
    const hasRepetitionId = !!event?.repetitionId;

    const onConfirmRepetition = async () => {
      await deleteEventTrigger({
        repId: event.repetitionId ?? '',
        id: event.id,
      });
      closeAllModals();
      nav(-1);
    };

    const onConfirmSingle = async () => {
      await deleteEventTrigger({ id: event.id });
      closeAllModals();
      nav(-1);
    };

    openConfirmModal({
      confirmProps: { color: 'red' },
      closeOnConfirm: false,
      txTitle: 'modal.confirmation',
      txLabels: { confirm: 'delete', cancel: 'close' },
      type: 'eventDelete',
      onConfirm: async () => {
        if (hasRepetitionId) {
          openConfirmModal({
            confirmProps: { color: 'red', w: 100 },
            txTitle: 'delete.repeating',
            closeOnConfirm: false,
            type: 'eventDeleteConfirm',
            values: { onConfirm: onConfirmRepetition },
            txLabels: { confirm: 'delete', cancel: 'close' },
            onConfirm: onConfirmSingle,
          });
        } else {
          onConfirmSingle();
        }
      },
    });
  };

  return { handleDelete, handleEdit };
};
