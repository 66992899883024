import { Center } from '@mantine/core';
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  FacebookMessengerIcon,
  FacebookMessengerShareButton,
  LinkedinShareButton,
  LinkedinIcon,
  EmailIcon,
  EmailShareButton,
} from 'react-share';
import styled from 'styled-components';
import { config } from '@aclito/shared/config';

import { VStack, Icon, Title } from '@/components';
import {
  CopyLinkButton,
  InviteHStack,
} from '@/common/InviteLayouts/InviteLayouts';
import { FB_APP_ID } from '@/util/constants';
import { useModal } from '@/hooks/useModal';

const Social = () => {
  const { openModal } = useModal();
  const handleInvite = () => {
    openModal({
      txTitle: 'nav.share',
      type: 'shareUrl',
      values: { msg: config.URL.aclitoInviteUrl },
    });
  };
  return (
    <Center>
      <StyledVStack>
        <Title tx="aclito.share.message.web" />
        <InviteHStack>
          <FacebookShareButton url={config.URL.aclitoInviteUrl}>
            <FacebookIcon size={64} round data-testid="facebook" />
          </FacebookShareButton>
          <TwitterShareButton url={config.URL.aclitoInviteUrl}>
            <TwitterIcon size={64} round data-testid="twitter" />
          </TwitterShareButton>
          <WhatsappShareButton url={config.URL.aclitoInviteUrl}>
            <WhatsappIcon size={64} round data-testid="whatsapp" />
          </WhatsappShareButton>
          <FacebookMessengerShareButton
            appId={FB_APP_ID}
            url={config.URL.aclitoInviteUrl}
            data-testid="facebookmessenger"
          >
            <FacebookMessengerIcon size={64} round />
          </FacebookMessengerShareButton>
          <EmailShareButton url={config.URL.aclitoInviteUrl}>
            <EmailIcon size={64} round data-testid="email" />
          </EmailShareButton>
          <LinkedinShareButton url={config.URL.aclitoInviteUrl}>
            <LinkedinIcon size={64} round data-testid="linkedin" />
          </LinkedinShareButton>
          <CopyLinkButton onClick={handleInvite}>
            <Icon icon="share" size={32} color="white" data-testid="copylink" />
          </CopyLinkButton>
        </InviteHStack>
      </StyledVStack>
    </Center>
  );
};
const StyledVStack = styled(VStack)`
  align-items: center;
  margin-top: 32px;
`;

export default Social;
