import * as yup from 'yup';

import { useSchema } from '@aclito/shared/hooks';
import { useAppDispatch } from '@aclito/shared/hooks/useDispatch';
import { SignUpForm } from '@aclito/shared/types';
import { authActions } from '@aclito/shared/redux/slices';

export const useRegister = () => {
  const dispatch = useAppDispatch();
  const initialValues: SignUpForm = {
    username: '',
    password: '',
  };

  const { passwordValidationSchema, userNameValidationSchema } = useSchema();

  const registerValidationSchema = yup
    .object()
    .concat(passwordValidationSchema)
    .concat(userNameValidationSchema)
    .required();

  const register = async (values: SignUpForm) =>
    await dispatch(
      authActions.singUpAsync({
        ...values,
        username: values.username.toLowerCase(),
      }),
    ).unwrap();

  return { register, initialValues, registerValidationSchema };
};
