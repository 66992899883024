import { useCallback, useEffect } from 'react';
import {
  noteActions,
  noteSelectors,
} from '@aclito/shared/redux/slices/noteSlices';
import { useAppSelector } from '@aclito/shared/hooks';
import { useAppDispatch } from '@aclito/shared/hooks/useDispatch';

export const useNoteGrants = () => {
  const dispatch = useAppDispatch();

  const noteGrants = useAppSelector(noteSelectors.myGrants);

  const loadGrantOnFocus = useCallback(() => {
    dispatch(noteActions.getMyNoteGrants());
  }, [dispatch]);

  useEffect(loadGrantOnFocus, [loadGrantOnFocus]);

  return { noteGrants };
};
