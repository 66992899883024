import React, { useState } from 'react';
import { Space } from '@mantine/core';
import { formatEvents } from '@aclito/shared/features/event/utils/formatEvents';
import { FormattedEvent } from '@aclito/shared/types';
import { Event } from '@aclito/entities';
import { EventFilters } from '@aclito/shared/redux/types';

import { useSearchMap } from '../hooks/useSearchMap';

import Map from '@/features/search/components/Map';
import EventsCards from '@/components/Cards/EventsCards';

type SearchMapProps = {
  events: Event[];
  filters: EventFilters;
};

const SearchMap: React.FC<SearchMapProps> = ({ events, filters }) => {
  const { findEventsOnMap, showCircle } = useSearchMap(filters);

  const [selectedEvents, setSelectedEvents] = useState<FormattedEvent[]>([]);

  return (
    <>
      <div style={{ position: 'relative' }}>
        <Map
          filters={filters}
          events={events}
          displayCircle={showCircle}
          onMapClusterClick={(e) => setSelectedEvents(formatEvents(e))}
          panToCenterOnLoad={true}
          onCenterChange={findEventsOnMap}
        />
        <Space h={24} />
        <EventsCards data={selectedEvents} />
      </div>
    </>
  );
};

export default SearchMap;
