import React from 'react';
import { useMediaQuery } from '@mantine/hooks';
import { Loader } from '@mantine/core';
import { deviceMax } from '@aclito/ui-web/utils/mediaQueries';
import { Calendar as BigCalendar } from 'react-big-calendar';
import { Views } from 'aclito-calendar';
import { EventRaw } from '@aclito/entities';

import withRouter from '../../navigation/withRouter';
import { MobileCalendar } from '../MobileCalendar';

import { useEmbed } from './hooks/useEmbed';
import { useCalendar } from './hooks/useCalendar';
import { CalendarProps } from './interface';
import './index.css';
import { CalendarStyleWrapper } from './CalendarStyleWrapper';

import {
  BackButton,
  ActionButton,
  HStack,
  SectionGrid,
  TitleBar,
  Title,
} from '@/components';
import { components } from '@/common/Calendar';
import { useNavigate } from '@/hooks/useNavigate';
import { useModal } from '@/hooks/useModal';
import { useEventInfoHandlers } from '@/features/event/screens/EventInfo/hooks/useEventInfoHandlers';
import { useLocalizer } from '@/components/Calendar/hooks/useLocalizer';

const Calendar: React.FC<CalendarProps> = ({ state }) => {
  const nav = useNavigate();
  const org = state?.org;
  const {
    handleRedirectToOrg,
    handleRedirectToLeader,
    handleRedirectToEventInfo,
    handleRedirectToMap,
  } = useEventInfoHandlers();

  const { embedModal } = useEmbed();
  const { openModal } = useModal();

  const { mappedEvents, isFetching, debouncedNavigate, debouncedRangeChange } =
    useCalendar(org?.id ?? '');
  const { formats, localizer } = useLocalizer();

  const mqTabletS = useMediaQuery(deviceMax.tabletS);

  return org ? (
    <SectionGrid $isOpen={false}>
      <TitleBar>
        <HStack>
          <BackButton onClick={() => nav(-1)} />
          <Title text={org.name} />
        </HStack>
        {isFetching ? <Loader /> : null}
        <ActionButton iconType="share" onClick={() => embedModal(org.id)} />
      </TitleBar>
      {mqTabletS ? (
        <MobileCalendar
          onMonthSelect={(date) => debouncedNavigate(date, Views.MONTH)}
          onNextMonth={(date) => debouncedNavigate(date, Views.MONTH)}
          onPreviousMonth={(date) => debouncedNavigate(date, Views.MONTH)}
          events={mappedEvents}
        />
      ) : (
        <CalendarStyleWrapper>
          {/*@ts-ignore*/}
          <BigCalendar
            formats={formats}
            localizer={localizer}
            {...{
              events: mappedEvents,
              startAccessor: 'date',
              endAccessor: 'endDate',
              components,
              onNavigate: debouncedNavigate,
              onRangeChange: debouncedRangeChange,
              onSelectEvent: (event: EventRaw) => {
                openModal({
                  withCloseButton: false,
                  size: 'auto',
                  padding: 0,
                  type: 'calModal',
                  values: {
                    id: event.id,
                    navigation: handleRedirectToEventInfo,
                    handleRedirectToLeader,
                    handleRedirectToOrg,
                    handleRedirectToMap,
                  },
                });
              },
            }}
          />
        </CalendarStyleWrapper>
      )}
    </SectionGrid>
  ) : (
    <Title tx="error" />
  );
};

export default withRouter(Calendar);
