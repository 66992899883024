import {
  useJoinChatMutation,
  useLeaveChatMutation,
} from '../../../redux/api/messageApi';

import { EventModel } from '@aclito/shared/classes/EventModel';
import { TranslateKey } from '@aclito/shared/types';

export const useChatHandlers = (model: EventModel) => {
  const [leave] = useLeaveChatMutation();
  const [join] = useJoinChatMutation();

  const leaveChat = async () => {
    if (model.event.eventChatId) {
      await leave({ id: model.event.eventChatId, eventId: model.event.id });
    }
  };

  const joinChat = async () => {
    if (model.event.eventChatId) {
      await join({ id: model.event.eventChatId, eventId: model.event.id });
    }
  };

  const txChatPlaceholderText: TranslateKey =
    !model.isJoined && model.canJoin
      ? 'event.chat.join.event'
      : model.canJoinQueue
      ? 'event.chat.join.full'
      : 'event.chat.join.chat';

  return { leaveChat, joinChat, txChatPlaceholderText };
};
