import { AvailabilityForm } from '@aclito/shared/types';
import { composeInitialCreateValues } from '@aclito/shared/features/availability/utils/composeInitialCreateValues';
import { useAvailabilityCreate } from '@aclito/shared/features/availability/hooks/useAvailabilityCreate';
import { useTranslate } from '@aclito/shared/hooks';

import { useNavigate } from '@/hooks/useNavigate';
import { ROUTE_AVAILABILITY } from '@/util/constants';

export const useAvailabilityNew = () => {
  const { createAvailabilities } = useAvailabilityCreate();
  const nav = useNavigate();
  const t = useTranslate();
  const initialValues = composeInitialCreateValues(t);

  const handleSubmit = async (formValues: AvailabilityForm) => {
    await createAvailabilities(formValues);

    nav(ROUTE_AVAILABILITY);
  };

  return { initialValues, handleSubmit };
};
