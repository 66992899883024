import { Auth } from 'aws-amplify';

import { ApiForgotPassword } from '../../types/authentication';
import { errorObject } from '../../utils/errorObject';
/**
 *
 * @param body Username, code and new password
 * @returns A string message or appropriate error
 */
export const makeResetPasswordSubmit = async (
  body: ApiForgotPassword,
): Promise<boolean> => {
  try {
    await Auth.forgotPasswordSubmit(
      body.username,
      body.code.toString(),
      body.newPassword,
    );
    return true;
  } catch (error) {
    //@ts-ignore
    return Promise.reject(errorObject[error.code]);
  }
};
