import {
  useJsApiLoader,
  GoogleMap,
  Circle,
  Marker,
} from '@react-google-maps/api';
import { Location } from '@aclito/entities';
import React, { useRef } from 'react';
import { config } from '@aclito/shared/config';
import { useTheme } from 'styled-components';
import { M_TO_KM_CONVERSION } from '@aclito/shared/util/constants';

import { MAP_API_KEY, MAP_BORDER_RADIUS } from '@/util/constants';

interface ViewOnlyMapProps {
  location: Location;
  name: string;
  type: 'marker' | 'circle';
}

const ViewOnlyMap: React.FC<ViewOnlyMapProps> = ({ location, name, type }) => {
  const { isLoaded: isMapLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: MAP_API_KEY,
  });
  const handleOnClick = () => {
    mapRef?.current?.state?.map?.panTo({
      lat: location.lat,
      lng: location.lon,
    });

    const zoom = mapRef?.current?.state?.map?.getZoom();
    if (zoom) {
      mapRef?.current?.state?.map?.setZoom(zoom + 2);
    }
  };

  const circleRadius =
    parseInt(config.distance.availabilitiesDefaultDistance) *
    M_TO_KM_CONVERSION;

  const mapRef = useRef<GoogleMap>(null);
  const theme = useTheme();
  return (
    <>
      {isMapLoaded && (
        <GoogleMap
          ref={mapRef}
          options={{
            streetViewControl: false,
            fullscreenControl: false,
            mapTypeControl: false,
          }}
          mapContainerStyle={{
            borderRadius: MAP_BORDER_RADIUS,
            width: '100%',
            height: '100%',
          }}
          center={{
            lat: location.lat,
            lng: location.lon,
          }}
          zoom={11}
        >
          {type === 'circle' && (
            <Circle
              onClick={handleOnClick}
              radius={circleRadius}
              center={{
                lat: location.lat,
                lng: location.lon,
              }}
              options={{
                fillColor: theme.mapCircle,
                strokeColor: theme.mapCircleStroke,
                strokeWeight: 1,
                fillOpacity: 1,
              }}
            />
          )}
          {type === 'marker' && (
            <Marker
              title={name}
              onClick={handleOnClick}
              position={{
                lat: location.lat,
                lng: location.lon,
              }}
            />
          )}
        </GoogleMap>
      )}
    </>
  );
};

export default ViewOnlyMap;
