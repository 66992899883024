import styled from 'styled-components';
import { deviceMax } from '@aclito/ui-web/utils/mediaQueries';

import { Text, VStack } from '@/components';

export const Content = styled(VStack)`
  width: 33%;
  @media ${deviceMax.tablet} {
    width: 100%;
  }
  height: 100%;
  background-color: ${({ theme }) => theme.white};
  border-radius: 20px;
  padding: 20px;
`;

export const Name = styled(Text)`
  font-weight: bold;
  font-size: 25px;
`;
