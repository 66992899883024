import React from 'react';
import { LoadingOverlay } from '@mantine/core';

import { SuggestionsProps } from '../interface';

import { Text, VStack } from '@/components';
import EventsCard from '@/components/Cards/EventsCards';

const Suggestions: React.FC<SuggestionsProps> = ({ suggestedEvents }) => {
  const suggestedLoading = false;

  return (
    <>
      <LoadingOverlay visible={suggestedLoading} overlayBlur={2} />
      <VStack>
        {suggestedEvents.length ? (
          <EventsCard data={suggestedEvents} />
        ) : (
          <Text tx="availability.info.suggestions.empty" />
        )}
      </VStack>
    </>
  );
};

export default Suggestions;
