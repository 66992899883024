import * as yup from 'yup';
import { Organization } from '@aclito/entities';

import { useSchema, useTranslate } from '../../../hooks';

export const useOrganizationSchema = (org?: Organization) => {
  const t = useTranslate();
  const { newOrgSchema } = useSchema();

  const getMaxMembersValidationSchema = yup.object({
    maxMembers: yup
      .number()
      .when('unlimited', {
        is: true,
        then: yup.number().notRequired(),
        otherwise: yup
          .number()
          .max(100, t('validation.max', { max: 100 }))
          .min(
            org ? org.members.length : 1,
            org ? t('validation.members') : t('validation.min', { min: 1 }),
          )
          .required(t('validation.req'))
          .nullable(),
      })
      .nullable(),
  });

  const newOrganizationSchema = newOrgSchema.concat(
    getMaxMembersValidationSchema,
  );

  return { newOrganizationSchema };
};
