import { useDeleteAvailabilityMutation } from '@aclito/shared/redux/api/availabilityApi';
import { Availability } from '@aclito/entities';

import { useNavigate } from '@/hooks/useNavigate';
import { ROUTE_AVAILABILITY_EDIT } from '@/util/constants';
import { useModal } from '@/hooks/useModal';

export const useAvailabilityInfoWeb = (
  availability: Availability | undefined,
) => {
  const nav = useNavigate();
  const { openConfirmModal } = useModal();
  const [deleteAvailability] = useDeleteAvailabilityMutation();

  const handleEdit = () => {
    if (availability) {
      nav(ROUTE_AVAILABILITY_EDIT, {
        state: { availability },
      });
    }
  };

  const onConfirmDelete = async () => {
    if (availability) {
      await deleteAvailability({ availabilityId: availability.id });
    }
    nav(-1);
  };

  const handleDelete = () => {
    openConfirmModal({
      confirmProps: { color: 'red' },
      closeOnConfirm: true,
      txTitle: 'availability.info.alert.header',
      txLabels: { confirm: 'delete', cancel: 'close' },
      onConfirm: onConfirmDelete,
    });
  };

  return {
    handleEdit,
    handleDelete,
  };
};
