import { feedClient, organizationClient } from '@aclito/client';
import {
  CreateFeedInput,
  Feed,
  UpdateFeedInput,
  UserInfo,
} from '@aclito/entities';

import { aclitoApi, AppState } from '../store';
import { handleError } from '../utils/handleError';

import { selectMyOrganizations } from './organizationApi';

import { findAllAndReplace } from '@aclito/shared/util/findAndReplace';
import { dateSort } from '@aclito/shared/util/sorter';

export const CREATE_FEED = 'createFeed';
export const UPDATE_FEED = 'updateFeed';
export const DELETE_FEED = 'deleteFeed';
export const LIST_FEEDS_BY_MY_ORGS = 'listFeedsByMyOrgs';
export const LIST_FEEDS_BY_ORG = 'listFeedsByOrg';
export const GET_FEED = 'getFeed';

export const feedApi = aclitoApi
  .enhanceEndpoints({
    addTagTypes: ['listFeeds', 'listMyOrgsFeeds', 'getFeed'],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      [LIST_FEEDS_BY_ORG]: builder.query<Feed[], string>({
        queryFn: async (id) => {
          try {
            const { data } = await feedClient.feeds.listFeedsByOrg({
              feedOrgId: id,
            });
            return { data };
          } catch (error) {
            return handleError(error);
          }
        },
        providesTags: ['listFeeds'],
        keepUnusedDataFor: 300,
      }),

      [GET_FEED]: builder.query<Feed, string>({
        queryFn: async (id) => {
          try {
            const { data } = await feedClient.feeds.getFeed(id);
            return { data };
          } catch (error) {
            return handleError(error);
          }
        },
        providesTags: ['getFeed'],
      }),

      [LIST_FEEDS_BY_MY_ORGS]: builder.query<Feed[], string | undefined>({
        queryFn: async (id, app) => {
          const state = app.getState() as AppState;
          const myOrgs = selectMyOrganizations(state);
          const orgsToFilter = id
            ? id
            : myOrgs?.map((org) => org.id).join(' ') ?? '';
          try {
            const { data } = await feedClient.feeds.searchMyFeeds({
              orgs: orgsToFilter,
            });
            return { data };
          } catch (error) {
            return handleError(error);
          }
        },
        keepUnusedDataFor: 300,
        providesTags: ['listMyOrgsFeeds'],
      }),
      [CREATE_FEED]: builder.mutation<
        Feed,
        Omit<CreateFeedInput, 'feedPostedById'> & {
          file: string | undefined;
        }
      >({
        queryFn: async ({ file, ...args }, app) => {
          const state = app.getState() as any;
          const userId: string = state.userInfo.current.id;

          try {
            const { data } = await feedClient.feeds.createFeed({
              feedOrgId: args.feedOrgId,
              feedPostedById: userId,
              text: args.text,
              ...(file && { file }),
            });
            const org = await organizationClient.organizations.getOrganization({
              orgId: args.feedOrgId,
            });

            return {
              data: {
                ...data,
                postedBy: state.userInfo.current as UserInfo,
                org: org.data.org,
              },
            };
          } catch (error) {
            return handleError(error);
          }
        },
        async onQueryStarted({ feedOrgId }, { dispatch, queryFulfilled }) {
          try {
            const { data: createdFeed } = await queryFulfilled;
            dispatch(
              feedApi.util.updateQueryData(
                'listFeedsByOrg',
                feedOrgId,
                (feedCache) => [createdFeed, ...feedCache],
              ),
            );

            dispatch(
              feedApi.util.updateQueryData(
                'listFeedsByMyOrgs',
                feedOrgId,
                (feedCache) => [createdFeed, ...feedCache],
              ),
            );
            dispatch(
              feedApi.util.updateQueryData(
                'listFeedsByMyOrgs',
                '',
                (feedCache) => [createdFeed, ...feedCache],
              ),
            );
          } catch (error) {
            return;
          }
        },
      }),
      [UPDATE_FEED]: builder.mutation<
        Feed,
        Omit<UpdateFeedInput, 'feedPostedById'> & {
          file: string | undefined;
          orgId: string;
        }
      >({
        queryFn: async ({ file, orgId, ...args }, app) => {
          const state = app.getState() as AppState;
          const userId: string = state.userInfo.current.id;

          try {
            const { data } = await feedClient.feeds.updateFeed({
              id: args.id,
              feedPostedById: userId,
              text: args.text,
              ...(file && { file }),
            });

            return { data: data };
          } catch (error) {
            return handleError(error);
          }
        },
        invalidatesTags: ['getFeed'],
        async onQueryStarted(
          { orgId },
          { dispatch, queryFulfilled, getState },
        ) {
          try {
            const { data: updatedFeed } = await queryFulfilled;

            dispatch(
              feedApi.util.updateQueryData(
                'listFeedsByOrg',
                orgId,
                (feedCache) => {
                  return dateSort(
                    findAllAndReplace(
                      feedCache,
                      updatedFeed,
                      (feed) => feed.id === updatedFeed.id,
                    ),
                    'updatedAt',
                    'desc',
                  );
                },
              ),
            );

            for (const {
              endpointName,
              originalArgs,
            } of feedApi.util.selectInvalidatedBy(getState(), [
              'listMyOrgsFeeds',
            ])) {
              if (endpointName !== LIST_FEEDS_BY_MY_ORGS) continue;

              dispatch(
                feedApi.util.updateQueryData(
                  endpointName,
                  originalArgs,
                  (feedCache) => {
                    return dateSort(
                      findAllAndReplace(
                        feedCache,
                        updatedFeed,
                        (feed) => feed.id === updatedFeed.id,
                      ),
                      'updatedAt',
                      'desc',
                    );
                  },
                ),
              );
            }
          } catch (error) {
            return;
          }
        },
      }),
      [DELETE_FEED]: builder.mutation<void, { id: string; feedOrgId: string }>({
        queryFn: async ({ id }) => {
          try {
            await feedClient.feeds.deleteFeed({ id });

            return { data: undefined };
          } catch (error) {
            return handleError(error);
          }
        },
        async onQueryStarted(args, { dispatch, queryFulfilled, getState }) {
          try {
            const deleteFeedOrg = dispatch(
              feedApi.util.updateQueryData(
                'listFeedsByOrg',
                args.feedOrgId,
                (feedCache) => feedCache.filter((feed) => feed.id !== args.id),
              ),
            );

            for (const {
              endpointName,
              originalArgs,
            } of feedApi.util.selectInvalidatedBy(getState(), [
              'listMyOrgsFeeds',
            ])) {
              if (endpointName !== LIST_FEEDS_BY_MY_ORGS) continue;

              dispatch(
                feedApi.util.updateQueryData(
                  endpointName,
                  originalArgs,
                  (feedCache) =>
                    feedCache.filter((feed) => feed.id !== args.id),
                ),
              );
            }

            const deleteFeedMyOrgs = dispatch(
              feedApi.util.updateQueryData(
                'listFeedsByMyOrgs',
                args.feedOrgId,
                (feedCache) => feedCache.filter((feed) => feed.id !== args.id),
              ),
            );
            try {
              await queryFulfilled;
            } catch (error) {
              deleteFeedOrg.undo();
              deleteFeedMyOrgs.undo();
            }
          } catch (error) {
            return;
          }
        },
      }),
    }),
  });

export const selectAllFeeds = (state: AppState) => {
  let res: Feed[] = [];
  for (const { endpointName, originalArgs } of feedApi.util.selectInvalidatedBy(
    state,
    ['listMyOrgsFeeds', 'listFeeds'],
  )) {
    if (
      endpointName === LIST_FEEDS_BY_MY_ORGS ||
      endpointName === LIST_FEEDS_BY_ORG
    ) {
      res = [
        ...res,
        // @ts-expect-error https://github.com/reduxjs/redux-toolkit/issues/2484
        ...(feedApi.endpoints[LIST_FEEDS_BY_MY_ORGS].select(originalArgs)(state)
          ?.data ?? []),
      ];
      res = [
        ...res,
        // @ts-expect-error https://github.com/reduxjs/redux-toolkit/issues/2484
        ...(feedApi.endpoints[LIST_FEEDS_BY_ORG].select(originalArgs)(state)
          ?.data ?? []),
      ];
    }
  }

  return res.filter((value, index, self) => {
    return self.findIndex((v) => v.id === value.id) === index;
  });
};

export const {
  useListFeedsByOrgQuery,
  useCreateFeedMutation,
  useUpdateFeedMutation,
  useDeleteFeedMutation,
  useListFeedsByMyOrgsQuery,
  useGetFeedQuery,
} = feedApi;
