import { useAppSelector, useSchema } from '@aclito/shared/hooks';
import { locationActions, placeActions } from '@aclito/shared/redux/slices';
import { useState } from 'react';
import { EventForm, UserLocation } from '@aclito/shared/types';
import { useJsApiLoader } from '@react-google-maps/api';
import { config } from '@aclito/shared/config';
import { useFormContext, useWatch } from 'react-hook-form';
import { useAppDispatch } from '@aclito/shared/hooks/useDispatch';
import { locationSelectors } from '@aclito/shared/redux/slices/locationSlice';
import { usePlace } from '@aclito/shared/features/event/hooks/usePlace';
import { Place, Event } from '@aclito/entities';
import {
  M_TO_KM_CONVERSION,
  PLACE_PROVIDER,
} from '@aclito/shared/util/constants';

import { PlaceValue } from '../types';

import { MAP_API_KEY } from '@/util/constants';
import { useModal } from '@/hooks/useModal';

export const usePlaceWeb = (eventToEdit?: Event) => {
  const dispatch = useAppDispatch();
  const userLocation = useAppSelector(locationSelectors.userLocation);
  const placesMapLocation = useAppSelector(locationSelectors.placesMapLocation);
  const delta = useAppSelector(locationSelectors.delta);

  const { setValue, getValues, resetField } = useFormContext();
  const { openConfirmModal } = useModal();

  const [activity] = useWatch<EventForm, ['activity']>({
    name: ['activity'],
  });

  const { allList, placeSelectList } = usePlace(activity?.id);

  const [selectedPlace, setSelectedPlace] = useState<Place>();
  const [mapLocation, setMapLocation] =
    useState<UserLocation>(placesMapLocation);
  const [currentCenter, setCurrentCenter] =
    useState<UserLocation>(userLocation);
  const { newPlaceSchema } = useSchema();

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: MAP_API_KEY,
  });

  const circleRadius =
    parseInt(config.distance.placesDefaultDistance) * M_TO_KM_CONVERSION;
  const beenPlayed = selectedPlace?.activities?.includes(
    getValues()?.activity.id,
  );

  const handleUpdatePlaceActivities = async (handledPlace: Place) => {
    const shouldUpdate = !handledPlace.userId.includes(PLACE_PROVIDER);

    if (!handledPlace.activities?.includes(getValues()?.activity.id)) {
      openConfirmModal({
        txTitle: 'events.new.place.alert.header',
        type: 'newPlace',
        confirmProps: { color: 'red' },
        centered: true,
        txLabels: { cancel: 'no', confirm: 'continue' },

        onConfirm: async () => {
          await updatePlaceActivities(handledPlace, shouldUpdate);
        },
      });
    } else {
      await updatePlaceActivities(handledPlace, false);
    }
  };

  const updatePlaceActivities = async (
    placeToUpdate: Place,
    update: boolean,
  ) => {
    const activities = placeToUpdate.activities
      ? [...placeToUpdate.activities, getValues()?.activity.id]
      : [getValues()?.activity.id];

    if (getValues()?.activity && update) {
      await dispatch(
        placeActions.updatePlaceAsync({
          id: placeToUpdate.id,
          activities,
        }),
      );
    }

    placeToUpdate.activities = activities;

    setValue('place', placeToUpdate);
    dispatch(placeActions.addPlace(placeToUpdate));
  };

  const handleCreatePlace = async (values: PlaceValue) => {
    const newPlace = await dispatch(
      placeActions.createPlaceAsync({
        name: values.newPlace,
        location: { lat: mapLocation.lat, lon: mapLocation.lon },
        activities: [getValues()?.activity.id],
        address: values.newPlaceAddress,
      }),
    ).unwrap();
    setValue('place', { ...newPlace, item: newPlace.name });
    resetField('newPlace');
    resetField('newPlaceAddress');
  };
  const onMapClick = (e: google.maps.MapMouseEvent) => {
    if (!e.latLng) return;
    loadPlaces({
      latitude: e.latLng.lat(),
      longitude: e.latLng.lng(),
    });

    setCurrentCenter({
      latitude: e.latLng.lat(),
      longitude: e.latLng.lng(),
      lat: e.latLng.lat(),
      lon: e.latLng.lng(),
      lng: e.latLng.lng(),
    });
    setSelectedPlace(undefined);
  };

  const loadPlaces = (coords: { latitude: number; longitude: number }) => {
    const location = {
      lat: coords.latitude,
      latitude: coords.latitude,
      lng: coords.longitude,
      lon: coords.longitude,
      longitude: coords.longitude,
    };

    dispatch(locationActions.updatePlacesLocation(location));
    setMapLocation(location);

    dispatch(
      placeActions.searchNearbyPlacesAsync({
        location: {
          lat: coords.latitude,
          lon: coords.longitude,
        },
      }),
    );
  };
  const eventPlace = allList.find((p) => p.id === eventToEdit?.place?.id);
  if (!eventPlace && eventToEdit?.place) {
    allList.push({ ...eventToEdit.place, item: eventToEdit.place.name });
  }

  return {
    handleUpdatePlaceActivities,
    selectedPlace,
    setSelectedPlace,
    beenPlayed,
    activity: getValues()?.activity,
    place: getValues()?.place,
    currentCenter,
    setCurrentCenter,
    handleCreatePlace,
    setMapLocation,
    delta,
    mapLocation,
    circleRadius,
    isLoaded,
    newPlaceSchema,
    onMapClick,
    loadPlaces,
    allList,
    placeSelectList,
  };
};
