import { OrganizationTag, OrganizationTags } from '../types/types';

import { getCurentTag } from './getOrgTag';

import { TranslateKey, txFn } from '@aclito/shared/types';

export const orgTypeLabelText = (tags: OrganizationTags, t?: txFn) => {
  const translations: Record<OrganizationTag, TranslateKey> = {
    [OrganizationTag.TEAM]: 'org.new.tab.sublabel.team',
    [OrganizationTag.CLUB]: 'org.new.tab.sublabel.club',
    [OrganizationTag.ORGANIZATION]: 'org.new.tab.sublabel.organization',
  };

  if (!t) {
    return translations[getCurentTag(tags)];
  }

  const [firstWord, ...rest] = t(translations[getCurentTag(tags)]).split(' ');
  return [firstWord + ' ', rest.join(' ')];
};
