import { ShareType, TranslateKey } from '@aclito/shared/types';
import { useTranslate } from '@aclito/shared/hooks';
import { Organization } from '@aclito/entities';

import { SHARE_URL } from '@/util/constants';
import { useModal } from '@/hooks/useModal';

export const useWebShare = (org?: Organization, print?: boolean) => {
  const t = useTranslate();

  const { openModal } = useModal();
  const handleShare = (id: string, shareType: ShareType, name: string) => {
    const type = t(`share.${shareType}` as TranslateKey);

    openModal({
      txTitle: 'share.modal.tile' as TranslateKey,
      txOptions: { type, name },
      type: 'webShare',
      values: {
        msg: getUrl(id, shareType),
        print: print,
        org: org,
      },
    });
  };

  const getUrl = (id: string, shareType: ShareType) =>
    `${SHARE_URL}/${shareType}/${id}`;

  return { handleShare, getUrl };
};
