import { useTranslate } from '@aclito/shared/hooks';
import { Checkbox as MantineCheckbox } from '@mantine/core';
import React from 'react';
import { useTheme } from 'styled-components';

import { CheckboxProps } from './types';

const Checkbox: React.FC<CheckboxProps> = ({
  label,
  placeholder,
  txLabel,
  txPlaceholder,
  ...props
}) => {
  const t = useTranslate();
  const theme = useTheme();
  const i18nLabel = txLabel && t(txLabel);
  const labelContent = i18nLabel || label;

  const i18nPlaceholder = txPlaceholder && t(txPlaceholder);
  const placeholderContent = i18nPlaceholder || placeholder;

  return (
    <MantineCheckbox
      label={labelContent}
      placeholder={placeholderContent}
      {...props}
      styles={{ label: { color: theme.grey80 } }}
      style={{ color: theme.grey10, paddingLeft: 0, ...props.style }}
    />
  );
};

export default Checkbox;
