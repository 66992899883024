import { useAppSelector, useTranslateDate } from '@aclito/shared/hooks';
import { profileSelectors } from '@aclito/shared/redux/slices/profileSlices';
import React from 'react';
import { canEditFeed } from '@aclito/shared/features/organization/utils/canEditFeed';
import styled from 'styled-components';
import { ImageValueShape } from '@aclito/shared/types';
import { Stack } from '@mantine/core';
import {
  selectAllFeeds,
  useGetFeedQuery,
} from '@aclito/shared/redux/api/feedApi';

import FeedMenuOptions from '../../components/FeedMenuOptions';

import {
  SectionGrid,
  Image,
  BackButton,
  HStack,
  Text,
  TitleBar,
  VStack,
  Title,
  OrgAvatar,
} from '@/components';
import { TypedLocation } from '@/hooks/useLocation';
import { StateFeedInfo } from '@/util/types';
import withRouter from '@/navigation/withRouter';

export type OrganizationFeedInfoProps = TypedLocation<StateFeedInfo>;

const OrganizationFeedInfo: React.FC<OrganizationFeedInfoProps> = ({
  state,
}) => {
  const feedId = state?.feedId;
  const userInfo = useAppSelector(profileSelectors.userInfo);
  const tDate = useTranslateDate();

  const selectedFeed = useAppSelector(selectAllFeeds).find(
    (f) => f.id === feedId,
  );
  const { data } = useGetFeedQuery(feedId ?? '', {
    skip: !!selectedFeed || !feedId,
  });
  if (!selectedFeed && !data) return null;

  const feed = selectedFeed ?? data;

  if (!feed) return null;

  const canEdit = canEditFeed(userInfo.id, feed);

  return (
    <SectionGrid $isOpen={false}>
      <TitleBar>
        <HStack>
          <BackButton />
          <Title tx="nav.feed" />
        </HStack>
        {canEdit && <FeedMenuOptions feed={feed} />}
      </TitleBar>
      <VStack>
        <HStack>
          <OrgAvatar org={feed.org} style={{ height: 100, width: 100 }} />
          <VStack spacing={10}>
            <NameText lineClamp={1} text={feed.org.name} />
            <DateText text={tDate(feed.createdAt, 'date.day.month.year')} />
          </VStack>
        </HStack>
        <Stack pl={6}>
          <FeedText text={feed.text} />
          <div style={{ maxWidth: 600 }}>
            {feed.image?.trim().length ? (
              <Image
                image={feed.image as ImageValueShape}
                timeStamp={feed.updatedAt}
              />
            ) : null}
          </div>
        </Stack>
      </VStack>
    </SectionGrid>
  );
};

const DateText = styled(Text)`
  color: ${({ theme }) => theme.grey30};
  font-weight: 400;
  line-height: normal;
  font-size: 16px;
`;

const NameText = styled(Text)`
  line-height: normal;
  font-weight: 500;
  color: ${({ theme }) => theme.grey80};
`;

const FeedText = styled(Text)`
  padding-left: 4px;
  color: ${({ theme }) => theme.grey50};
  font-size: 14px;
  line-height: normal;
  font-weight: 400;
`;

export default withRouter(OrganizationFeedInfo);
