import { createIntl, createIntlCache } from 'react-intl';

import { messages } from '../i18n/I18nProvider';
import { LocaleType, TranslateKey, TranslateValues } from '../types';

const cache = createIntlCache();
export const makeMessage = (
  id: TranslateKey,
  locale: LocaleType,
  values?: TranslateValues,
) => {
  const intl = createIntl({ locale, messages: messages[locale] }, cache);
  return intl.formatMessage({ id }, values);
};
