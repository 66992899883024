import { FlashMessageInterface } from '@aclito/shared/util/flashMessage';
import {
  cleanNotifications,
  showNotification as showMantineNotification,
} from '@mantine/notifications';
import { colors } from '@aclito/shared/theme/colors';
import {
  getBackgroundColor,
  getIcon,
} from '@aclito/shared/util/snackBarConfig';

import { Icon } from '../components/Icons/Icon';

export const showNotification = (
  message: string,
  type: FlashMessageInterface['type'],
  onClick?: () => void,
  options?: FlashMessageInterface,
) => {
  const backgroundColor = getBackgroundColor(type);

  showMantineNotification({
    ...options,
    autoClose: options?.autoHide,
    onClick: () => {
      if (onClick) onClick();
      cleanNotifications();
    },
    message: message,
    icon: <Icon color="whiteText" icon={getIcon(type)} />,
    styles: {
      description: {
        color: colors.whiteText,
      },
      closeButton: {
        color: colors.whiteText,
        backgroundColor,
        ':hover': { backgroundColor },
      },
      icon: {
        backgroundColor,
      },
      root: {
        borderRadius: 10,
        color: colors.whiteText,
        backgroundColor,
      },
    },
  });
};
