import { useSchema } from '@aclito/shared/hooks';
import { useAppDispatch } from '@aclito/shared/hooks/useDispatch';
import { authActions } from '@aclito/shared/redux/slices';
import { PasswordRequestForm } from '@aclito/shared/types';

export const useRequestNewPassword = () => {
  const dispatch = useAppDispatch();
  const initialValues = { username: '' };
  const { passwordRequestSchema } = useSchema();

  const requestNewPassword = (values: PasswordRequestForm) =>
    dispatch(authActions.requestNewPasswordAsync(values.username)).unwrap();

  return { initialValues, passwordRequestSchema, requestNewPassword };
};
