import moment from 'moment';
import { EventRaw, UserInfo } from '@aclito/entities';

import { EVENT_QUEUE_SIZE } from '../../../util/constants';

import { isEventFull } from './isEventFull';
import { getPlayersInQueueCount } from './getParticipantsCount';

export const isJoined = (event: EventRaw, userId: string) => {
  return event.players.some((player) => player.id === userId);
};

export const canJoinEvent = (event: EventRaw, userInfo: UserInfo): boolean => {
  if (moment() > moment(event.date)) {
    return false;
  }

  if (isEventFull(event)) {
    return false;
  }

  if (userInfo.aliases.length) {
    if (
      event.players.filter((player) => player.id === userInfo.id).length >=
      userInfo.aliases.length + 1
    ) {
      return false;
    }
  } else if (event.players.some((p) => p.id === userInfo.id)) {
    return false;
  }

  return true;
};

export const canJoinQueue = (event: EventRaw) => {
  if (!event.enableQueue) {
    return false;
  }
  return !(
    event.enableQueue && EVENT_QUEUE_SIZE <= getPlayersInQueueCount(event)
  );
};
