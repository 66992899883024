import { useEffect, useState } from 'react';
import { Chat, Event } from '@aclito/entities';

import { isJoinedInChat } from '../utils/isJoinedInChat';

import { useAppSelector } from '@aclito/shared/hooks';
import { profileSelectors } from '@aclito/shared/redux/slices/profileSlices';
import { IStorage } from '@aclito/shared/types';
import { shouldShowBadge } from '@aclito/shared/util/shouldShowBadge';

export const useShowCardBadge = (storage: IStorage, event: Event) => {
  const userInfo = useAppSelector(profileSelectors.userInfo);

  const [showBadge, setShowBadge] = useState(false);

  useEffect(() => {
    const showRead = async (chat: Chat) => {
      const show = await shouldShowBadge(storage, {
        chatId: chat.id,
        userId: userInfo.id,
        lastUpdate: chat.updatedAt,
      });
      setShowBadge(show && isJoinedInChat(chat, userInfo));
    };
    if (event.chat) {
      showRead(event.chat);
    }
  }, [event.chat]);

  return { showBadge };
};
