import { LoadingOverlay } from '@mantine/core';
import React from 'react';
import { formatEvents } from '@aclito/shared/features/event/utils/formatEvents';
import { FormattedEvent } from '@aclito/shared/types';
import { useLoadMyEventsQuery } from '@aclito/shared/redux/api/eventApi';
import { dateSort } from '@aclito/shared/util/sorter';

import MyEvents from './components/MyEvents';

import { useNavigate } from '@/hooks/useNavigate';

const Events: React.FC = () => {
  const { data, isLoading } = useLoadMyEventsQuery({ nextToken: null });

  const navigation = useNavigate();

  const mappedMyEvents: FormattedEvent[] = dateSort(
    formatEvents(data?.result ?? []),
    'date',
    'asc',
  );

  return (
    <>
      <LoadingOverlay overlayBlur={2} visible={isLoading} />
      <MyEvents
        {...{
          navigation,
          data: mappedMyEvents,
          nextToken: data?.nextToken ?? null,
        }}
      />
    </>
  );
};

export default Events;
