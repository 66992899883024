import React from 'react';
import { useController } from 'react-hook-form';

import Select from './Select';
import { SelectProps } from './types';

const ValidatedSelect: React.FC<
  SelectProps & {
    name: string;
  }
> = ({ name, ...rest }) => {
  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController({ name });
  return (
    <Select
      value={value ? value?.id : ''}
      name={name}
      searchable={true}
      error={error?.message}
      {...rest}
      onChange={(item) => {
        if (!item) {
          onChange('');
          return;
        }
        onChange(rest.options?.find((o) => o.id === item));
        if (rest.onChange) {
          rest?.onChange(item);
        }
      }}
    />
  );
};

export default ValidatedSelect;
