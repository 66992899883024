import { Center, Loader } from '@mantine/core';
import styled from 'styled-components';
import { EventModel } from '@aclito/shared/classes/EventModel';
import { deviceMax } from '@aclito/ui-web/utils/mediaQueries';

import { InfoLeft, InfoRight } from '../components';

import { Collapsible, HStack, VStack, Title } from '@/components';
import PlayersCards from '@/components/Cards/PlayersCards';

interface SectionInfoProps {
  model: EventModel;
}

const InfoTab = ({ model }: SectionInfoProps) => {
  const { event } = model;

  return (
    <Container spacing={0}>
      <SectionInfo>
        {!event ? (
          <Center style={{ height: '100%' }}>
            <Loader />
          </Center>
        ) : (
          <Collapsible buttonLabelTx="event.info.about" defaultOpen>
            <InfoContent spacing={20}>
              <InfoLeft model={model} />
              <InfoRight model={model} />
            </InfoContent>
          </Collapsible>
        )}
      </SectionInfo>
      <Collapsible buttonLabelTx="tabs.players" defaultOpen>
        <SectionPlayers>
          {event && (
            <PlayersCards
              {...{
                model,
              }}
            />
          )}
        </SectionPlayers>
        {event?.enableQueue && !!event.queuePlayersInfo.length && (
          <QueueHeader>
            <Divider />
            <QueueTitle tx="events.info.queue" />
            <Divider />
          </QueueHeader>
        )}
        <SectionPlayers>
          {event && (
            <PlayersCards
              {...{
                model,
                isQueue: true,
              }}
            />
          )}
        </SectionPlayers>
      </Collapsible>
    </Container>
  );
};

const QueueHeader = styled.div`
  display: flex;
`;

const Divider = styled.div`
  width: 100%;
  border-top: 1px solid ${({ theme }) => theme.grey10};
  margin: 1.65rem 1rem 0;
`;

const QueueTitle = styled(Title)`
  color: ${({ theme }) => theme.grey30};
  text-align: center;
  font-size: 0.875rem;
  font-weight: 500;
  margin-top: 1rem;
`;

const InfoContent = styled(HStack)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  height: auto;
  @media ${deviceMax.desktopL} {
    display: flex;
  }
`;

const Container = styled(VStack)`
  width: 100%;
`;

const SectionInfo = styled(VStack)`
  margin-left: auto;
  margin-right: auto;
  height: auto;
  width: 100%;
  gap: 1rem;
  padding-bottom: 1rem;
`;

const SectionPlayers = styled(VStack)`
  width: 100%;
  height: auto;
`;

export default InfoTab;
