import React from 'react';
import { useController } from 'react-hook-form';
import { DELETED_FILE } from '@aclito/shared/util/constants';

import { ImageDropzoneProps } from '../Dropzone/types';
import { AvatarDropzone } from '../Dropzone';

type ValidatedDropzoneProps = { name: string } & Pick<
  ImageDropzoneProps,
  'imageOnly' | 'circular'
>;

const ValidatedDropzone: React.FC<ValidatedDropzoneProps> = ({
  name,
  imageOnly,
  circular = true,
}) => {
  // NOTE: This component relies on 'file' field inside form values.
  const { field: fileField } = useController({ name: 'file' });
  const { field: imageField } = useController({ name: name });

  const onRemove = () => {
    imageField.onChange('');
    fileField.onChange(DELETED_FILE);
  };

  const onCrop = async (data: Blob | string) => {
    if (typeof data === 'string') {
      fileField.onChange(undefined);
      imageField.onChange('SEED:' + data);
    } else {
      fileField.onChange(await _convertBlobToBase64(data));
      imageField.onChange('FILE:' + URL.createObjectURL(data));
    }
  };

  const _convertBlobToBase64 = (blob: Blob) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = reject;
      reader.onload = () => {
        if (reader.result && typeof reader.result === 'string') {
          resolve(reader.result.split(',')[1]);
        } else {
          resolve(reader.result);
        }
      };
      reader.readAsDataURL(blob);
    });

  return (
    <AvatarDropzone
      onChange={onCrop}
      onRemove={onRemove}
      currentImage={imageField.value}
      imageOnly={imageOnly}
      circular={circular}
    />
  );
};

export default ValidatedDropzone;
