import { Group } from '@mantine/core';

import { Icon } from '../Icons/Icon';

const DropzoneContent = () => {
  return (
    <Group
      style={{
        minWidth: '35vw',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Icon icon={'picture'} size={64} color={'primary'} />
    </Group>
  );
};

export default DropzoneContent;
