import { useTranslate } from '@aclito/shared/hooks/useTranslate';
import { HStack } from '@aclito/ui-web/components/Layout';
import styled from 'styled-components';
import { useAppSelector } from '@aclito/shared/hooks';
import { config } from '@aclito/shared/config';
import { Link } from 'react-router-dom';

const Copyright: React.FC = () => {
  const t = useTranslate();
  const locale = useAppSelector((state) => state.locale);

  // Aclito web has only en|cs
  const urlLocale = locale === 'en' ? 'en' : 'cs';

  return (
    <Container align="center" style={{ textAlign: 'center' }} spacing={8}>
      <div>{t('footer.rights')}</div>
      <DotDivider>•</DotDivider>
      <Link
        to={`${config.URL.aclitoWebURL}/${urlLocale}/terms-and-conditions`}
        style={{
          color: 'inherit',
          textDecoration: 'none',
        }}
      >
        {t('footer.terms')}
      </Link>
      <DotDivider>•</DotDivider>
      <Link
        to={`${config.URL.aclitoWebURL}/${urlLocale}/privacy-policy`}
        style={{ color: 'inherit', textDecoration: 'none' }}
      >
        {t('footer.privacy')}
      </Link>
    </Container>
  );
};

const Container = styled(HStack)`
  border-top: 1px solid ${({ theme }) => theme.grey10};
  padding: 24px 0;
  color: ${({ theme }) => theme.grey40};
  width: 100%;
  font-size: 14px;
  justify-content: center;

  @media (max-width: 576px) {
    flex-direction: column;
  }
`;

const DotDivider = styled.div`
  @media (max-width: 576px) {
    display: none;
  }
`;

export default Copyright;
