import { CreateReportInput, Report } from '@aclito/entities';

import { ContentType, HttpClient } from '../httpClient';

export class ReportClient extends HttpClient {
  reports = {
    createReport: (data: CreateReportInput) =>
      this.request<Report>({
        path: '/report',
        method: 'POST',
        body: data,
        type: ContentType.Json,
      }),
  };
}
