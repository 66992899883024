import { useAppSelector } from '@aclito/shared/hooks';
import { useCallback, useEffect } from 'react';
import {
  noteActions,
  noteSelectors,
} from '@aclito/shared/redux/slices/noteSlices';
import { useAppDispatch } from '@aclito/shared/hooks/useDispatch';

export const useNotesUser = (
  id: string | undefined,
  orgId: string | undefined,
) => {
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(noteSelectors.isLoading);
  const notes = useAppSelector(noteSelectors.notes);
  const loadApiData = useCallback(() => {
    if (id && orgId) {
      dispatch(noteActions.getNoteForUserAsync({ id, orgId }));
    }
  }, [dispatch, id, orgId]);

  useEffect(loadApiData, [loadApiData]);

  if (!id || !orgId) {
    return { notes: undefined, isLoading: false };
  }

  return {
    notes,
    isLoading,
  };
};
