import type { UserInfo } from '@aclito/entities';

import { config } from '../../../config';

import { ActivitiesKey, ProfileInfoForm, txFn } from '@aclito/shared/types';
import {
  getById,
  getByIdTranslate,
  translate,
} from '@aclito/shared/util/selectObject';
import { RESERVED_USER_NAME } from '@aclito/shared/util/constants';

const { age, activities, gender, allLanguages } = config;
export const composeInitialEditValues = (
  t: txFn,
  currentUser: UserInfo,
): ProfileInfoForm => ({
  age:
    currentUser.age === age.unknown.id
      ? getByIdTranslate(currentUser?.age, age, t)
      : getById(currentUser?.age, age),
  gender: getByIdTranslate(currentUser?.gender, gender.signup, t),
  image: currentUser?.image,
  languages: currentUser?.languages.length
    ? currentUser.languages.map((l) => ({
        item: allLanguages[l].name,
        id: l,
      }))
    : [],
  name: currentUser?.name === RESERVED_USER_NAME ? '' : currentUser.name,
  activities: currentUser?.activities.length
    ? translate(
        currentUser.activities.map((s) => ({
          item: activities[s as ActivitiesKey].item,
          id: s,
        })),
        t,
      )
    : [],
  surname:
    currentUser?.surname === RESERVED_USER_NAME ? '' : currentUser.surname,
  description: currentUser?.description || '',
  file: undefined,
});
