import { ImageValueShape } from '../../../types';

export interface PlayerDataType {
  id: string;
  name: string;
  surname: string;
  image: ImageValueShape;
}

export enum EventTag {
  ALL = 'ALL',
  KIDS = 'KIDS',
  ADULTS = 'ADULTS',
}

export type EventTags = { adults: boolean; all: boolean; kids: boolean };
