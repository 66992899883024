import {
  useAppSelector,
  useSchema,
  useSelectObject,
  useTranslate,
} from '@aclito/shared/hooks';
import { useGetActivityAndGenders } from '@aclito/shared/features/event/hooks/useGetActivityAndGender';
import { EventForm } from '@aclito/shared/types';
import { composeInitialCreateValues } from '@aclito/shared/features/event/utils/composeInitialCreateValues';
import { selectMyOrganizations } from '@aclito/shared/redux/api/organizationApi';
import { UserInfo } from '@aclito/entities';
import { useCreateEventMutation } from '@aclito/shared/redux/api/eventApi';

import { ROUTE_EVENTS } from '@/util/constants';
import { useNavigate } from '@/hooks/useNavigate';

export const useNewEvent = (userInfo: UserInfo, orgId?: string) => {
  const myOrgs = useAppSelector(selectMyOrganizations);
  const [createEventTrigger, { isLoading }] = useCreateEventMutation();

  const nav = useNavigate();
  const t = useTranslate();
  const { getById, translatedLevels, translatedActivities } = useSelectObject();
  const { newEventValidationSchema } = useSchema();
  const { allActivities, possibleGenders } = useGetActivityAndGenders(userInfo);
  // const { openConfirmModal } = useModal();
  // const { suggestPlayers } = useSuggestPlayers();

  const levelOptions = translatedLevels.map((level) => ({
    ...level,
    item: level.item,
  }));

  const displayedOrg = myOrgs.find((o) => o.id === orgId);

  const orgOptions = [
    ...myOrgs
      .filter((o) =>
        [...new Set([...o.admins, ...o.powerUsers])].some(
          (member) => member === userInfo.id,
        ),
      )
      .map((org) => ({ ...org, item: org.name })),
    getById('none', { none: { id: 'none', item: 'json.none' } }),
  ];

  const initialValues = composeInitialCreateValues(t, displayedOrg);

  const createNewEvent = async (data: EventForm) => {
    await createEventTrigger(data);
    nav(ROUTE_EVENTS);
  };

  // const suggestPlayersNow = (event: Event) => {
  //   openConfirmModal({
  //     txTitle: 'suggestions.question',
  //     txLabels: {
  //       cancel: 'suggestions.button.decline',
  //       confirm: 'suggestions.button.suggest',
  //     },
  //     onConfirm: () => {
  //       nav(ROUTE_PLAYER_SUGGESTIONS, { state: { event } });
  //     },
  //   });
  // };

  return {
    initialValues,
    createNewEvent,
    newEventValidationSchema,
    translatedActivities,
    levelOptions,
    allActivities,
    possibleGenders,
    isLoading,
    orgOptions,
  };
};
