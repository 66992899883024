import { FieldValues, SubmitErrorHandler } from 'react-hook-form';

import { formErrorMapper } from '@aclito/shared/util/formErrorMapper';
import { FlashMessageInterface } from '@aclito/shared/util/flashMessage';

export const useFormErrorMap = <K extends string, T extends FieldValues>(
  translatedErrorKeys: Record<K, string>,
  notificationCb: (
    message: string,
    type: FlashMessageInterface['type'],
  ) => void,
) => {
  const onError: SubmitErrorHandler<T> = (err) => {
    const errs = formErrorMapper(err, translatedErrorKeys);
    Object.values(errs).forEach((errorMessage) => {
      notificationCb(errorMessage as string, 'danger');
    });
  };

  return { onError };
};
