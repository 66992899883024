import { EventRaw } from '@aclito/entities';

import {
  useJoinProviderEventMutation,
  useLeaveProviderEventMutation,
} from '../../../redux/api/eventApi';

export const useEventInfoProviders = () => {
  const [join] = useJoinProviderEventMutation();
  const [leave] = useLeaveProviderEventMutation();
  const addEventToMyEvents = async (event: EventRaw) => {
    await join({ id: event.id });
  };

  const removeEventFromMyEvents = async (
    event: EventRaw,
    goBack: () => void,
  ) => {
    await leave({ id: event.id }).unwrap();

    goBack();
  };

  return { addEventToMyEvents, removeEventFromMyEvents };
};
