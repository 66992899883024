import dayjs from 'dayjs';

import { SelectObject } from '../types';

export const dateSort = <T, K extends keyof T>(
  data: T[],
  key: K,
  type: 'asc' | 'desc',
) => {
  const desc = (x: T, y: T) => {
    const xValue = x[key];
    const yValue = y[key];

    if (typeof xValue === 'string' && typeof yValue === 'string') {
      return dayjs(yValue).valueOf() - dayjs(xValue).valueOf();
    }

    if (typeof xValue === 'number' && typeof yValue === 'number') {
      return yValue - xValue;
    }

    if (xValue instanceof Date && yValue instanceof Date) {
      return yValue.getTime() - xValue.getTime();
    }

    return 0;
  };

  const asc = (x: T, y: T) => -desc(x, y);
  const func = type === 'asc' ? asc : desc;
  return [...data].sort(func);
};

export const sortByItem = (items: SelectObject[]) =>
  [...items]?.sort((x, y) => (x.item > y.item ? 1 : -1));
