import { IStorage } from '../types';

import { LAST_SEEN_CHAT } from './constants';
import { hashIds } from './getHash';

export const shouldShowBadge = async (
  storage: IStorage,
  data: { chatId: string; userId: string; lastUpdate: string },
) => {
  const { chatId, userId, lastUpdate } = data;
  const lastSeenData = await storage.getParsedItems(LAST_SEEN_CHAT);
  const hash = hashIds(chatId, userId);
  if (Object.keys(lastSeenData).length === 0) return false;
  const hasData = lastSeenData[hash];
  return hasData ? hasData.lastSeen < lastUpdate : false;
};
