import React from 'react';
import styled from 'styled-components';
import { FormattedUserInfo } from '@aclito/shared/types';
import { SectionTitle } from '@aclito/ui-web/components/Layout';
import { useNavigate } from 'react-router-dom';

import InfoBuilder from '../../features/event/screens/EventInfo/components/InfoBuilder';

import { HStack, Avatar, Text, Icon } from '@/components';
import { ROUTE_PROFILE_ALIASES } from '@/util/constants';
export interface AboutUserAttributesProps {
  userInfo: FormattedUserInfo;
  myProfile: boolean;
}

const AboutUserAttributes: React.FC<AboutUserAttributesProps> = ({
  userInfo,
  myProfile,
}) => {
  const nav = useNavigate();

  const onAliasNavigation = () => {
    myProfile
      ? nav(ROUTE_PROFILE_ALIASES)
      : nav(`${ROUTE_PROFILE_ALIASES}/${userInfo.id}`);
  };

  return (
    <Content>
      <InfoBuilder
        data={[
          {
            text: { text: userInfo.formattedActivities.join(', ') },
            label: 'profile.activities',
          },
          {
            text: { text: userInfo.formattedLanguages.join(', ') },
            label: 'profile.languages',
          },
          {
            text: { text: userInfo.formattedAge || '' },
            label: 'profile.age',
          },
          {
            text: { text: userInfo.formattedGender || '' },
            label: 'profile.gender',
          },
          {
            text: {
              text: userInfo.description || '',
            },
            label: 'profile.description',
            vertical: true,
            visible: !!userInfo.description,
          },
        ]}
      />
      {(myProfile || !!userInfo.aliases.length) && (
        <div style={{ paddingTop: 8 }}>
          <SectionTitle
            testID={'user-alias'}
            onClick={onAliasNavigation}
            buttonLabelTx={'profile.alias'}
          />
          {myProfile && !userInfo.aliases.length && (
            <HStack style={{ padding: 16, paddingBottom: 8 }}>
              <Text tx={'profile.no.alias'} />
              <Icon icon={'person'} size={32} />
            </HStack>
          )}
          <HStack style={{ paddingTop: 8 }}>
            {userInfo.aliases.map((alias) => (
              <Avatar key={alias.id} size={60} image={alias.image} />
            ))}
          </HStack>
        </div>
      )}
    </Content>
  );
};

const Content = styled.div`
  flex: 1;
`;

export default AboutUserAttributes;
