import styled from 'styled-components';
import React, { PropsWithChildren } from 'react';

const Box: React.FC<PropsWithChildren<unknown>> = ({ children }) => (
  <StyledBox>{children}</StyledBox>
);

const StyledBox = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export { Box };
