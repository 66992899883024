import React from 'react';
import { Modal as MTModal, ModalProps } from '@mantine/core';
import { useTheme } from 'styled-components';

const Modal: React.FC<ModalProps> = ({ opened, onClose, ...props }) => {
  const theme = useTheme();

  return (
    <MTModal
      styles={{
        header: {
          background: theme.background,
        },
        body: {
          height: 400,
          background: theme.background,
        },
      }}
      radius={10}
      opened={opened}
      onClose={onClose}
      {...props}
    >
      {props.children}
    </MTModal>
  );
};

export default Modal;
