import React from 'react';
import styled from 'styled-components';

import AboutUserAttributes, {
  AboutUserAttributesProps,
} from './AboutUserAttributes';
import { AboutUserMainProps } from './AboutUserMain';

import { Collapsible } from '@/components';

const AboutUserSection: React.FC<
  AboutUserMainProps & AboutUserAttributesProps
> = ({ userInfo, myProfile }) => (
  <AboutUser>
    <Container>
      <Collapsible buttonLabelTx="tabs.aboutme" defaultOpen>
        <AboutUserAttributes {...{ userInfo, myProfile }} />
      </Collapsible>
    </Container>
  </AboutUser>
);

const AboutUser = styled.div`
  min-width: 90%;
  width: 100%;
  margin: auto;
`;

const Container = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: column;
`;

export default AboutUserSection;
