import { unwrapResult } from '@reduxjs/toolkit';
import { useMemo } from 'react';

import { UserAliasForm } from './useProfileAliasesEdit';

import { config } from '@aclito/shared/config';
import { useSelectObject } from '@aclito/shared/hooks';
import { useAppDispatch } from '@aclito/shared/hooks/useDispatch';
import { profileActions } from '@aclito/shared/redux/slices';
import {
  getRandomUserName,
  getRandomUserSurname,
} from '@aclito/shared/util/getRandomUserAttributes';

export const useProfileAliasesCreate = () => {
  const dispatch = useAppDispatch();
  const { getById } = useSelectObject();

  const placeholderName = useMemo(() => getRandomUserName(), []);
  const placeholderSurname = useMemo(
    () => getById(getRandomUserSurname(), config.activities).item,
    [getById],
  );

  const createSecondaryProfile = async (
    aliasData: Omit<UserAliasForm, 'id'>,
  ) => {
    aliasData.image = aliasData.image?.trim() ? aliasData.image : 'BASIC';

    if (!aliasData.file) {
      delete aliasData.file;
    }

    return unwrapResult(
      await dispatch(
        profileActions.createUserAliasAsync({
          aliasData: {
            file: aliasData.file,
            name: aliasData.name.trim() ? aliasData.name : placeholderName,
            surname: aliasData.surname.trim()
              ? aliasData.surname
              : placeholderSurname,
            image: aliasData.image,
          },
        }),
      ),
    );
  };

  const initialValues: UserAliasForm = {
    name: '',
    surname: '',
    image: 'BASIC',
  };

  return {
    placeholderName,
    placeholderSurname,
    createSecondaryProfile,
    initialValues,
  };
};
