import { Auth } from 'aws-amplify';
import { CognitoUser } from '@aws-amplify/auth';

import { errorObject } from '../../utils/errorObject';

/**
 *
 * @returns A current authenticated user
 */
export const makeCurrentUser = async (): Promise<CognitoUser> => {
  try {
    return await Auth.currentAuthenticatedUser();
  } catch (error) {
    //@ts-ignore
    return Promise.reject(errorObject[error.code]);
  }
};
