import { Feed } from '@aclito/entities';
import { useDeleteFeedMutation } from '@aclito/shared/redux/api/feedApi';

import { useLocation } from '@/hooks';
import { useModal } from '@/hooks/useModal';
import { useNavigate } from '@/hooks/useNavigate';
import { ROUTE_FEED_INFO } from '@/util/constants';

export const useFeedDelete = (feed: Feed) => {
  const nav = useNavigate();
  const { openConfirmModal } = useModal();
  const location = useLocation();

  const [deleteFeed] = useDeleteFeedMutation();

  const handleDelete = () => {
    openConfirmModal({
      txTitle: 'org.feed.delete.title',
      txLabels: { cancel: 'close', confirm: 'delete' },
      type: 'deleteFeed',
      onConfirm: async () => {
        await deleteFeed({ id: feed.id, feedOrgId: feed.feedOrgId });
        if (location.pathname === ROUTE_FEED_INFO) {
          nav(-1);
        }
      },
    });
  };

  return { handleDelete };
};
