import React, { useEffect } from 'react';
import { EventTag } from '@aclito/shared/features/event/types/types';
import { useFormContext } from 'react-hook-form';
import { EventForm } from '@aclito/shared/types';
import { generateTagMappings } from '@aclito/shared/features/event/utils/mapEventTags';
import { useAppSelector } from '@aclito/shared/hooks';
import { getCurrentTag } from '@aclito/shared/features/event/utils/getEventTag';
import { useTheme } from 'styled-components';
import { getOrgForTrainer } from '@aclito/shared/util/getOrgForTrainer';
import { useEventInfoSelectTrainer } from '@aclito/shared/features/event/hooks/useEventInfoSelectTrainer';
import { OrganizationModel } from '@aclito/shared/classes/OrganizationModel';
import { profileSelectors } from '@aclito/shared/redux/slices/profileSlices';
import { Divider } from '@mantine/core';
import { colors } from '@aclito/shared/theme/colors';
import { getCommonInputLabelStyle } from '@aclito/ui-web/utils/styles';
import { selectMyOrganizations } from '@aclito/shared/redux/api/organizationApi';
import { useDisplayEvent } from '@aclito/shared/redux/api/eventApi';

import { AdvancedSectionProps } from './interface';

import {
  Collapsible,
  Tabs,
  Text,
  VStack,
  ValidatedInput,
  TagButton,
  ValidatedSelect,
  ValidatedNumberInput,
  ValidatedSwitch,
} from '@/components';

const AdvancedSection: React.FC<AdvancedSectionProps> = ({
  orgOptions,
  isJoined = false,
  eventId,
  orgId,
}) => {
  const myOrgs = useAppSelector(selectMyOrganizations);
  const userInfo = useAppSelector(profileSelectors.userInfo);
  const { model } = useDisplayEvent(eventId);
  const displayedOrg = myOrgs.find((o) => o.id === orgId);
  const { setValue, watch, resetField } = useFormContext<EventForm>();

  const watchRequireConfirm = watch('requireConfirm');
  const watchLinkRequired = watch('linkRequired');
  const tags = watch('tags');
  const watchOrg = watch('org');
  const watchUnlimited = watch('unlimited');
  const watchDontParticipate = watch('dontParticipate');

  const theme = useTheme();

  useEffect(() => {
    if (!watchOrg || watchOrg.id === 'none') {
      setValue('dontParticipate', false);
    }
  }, [watchOrg]);

  const { playersToSelect } = useEventInfoSelectTrainer(
    getOrgForTrainer(watchOrg, displayedOrg, myOrgs),
    model,
  );

  const handleTabIndexChange = (eventTag: EventTag) => {
    setValue('tags', generateTagMappings(eventTag));
  };
  const modelOrg = new OrganizationModel(displayedOrg, userInfo);

  return (
    <>
      <Collapsible buttonLabelTx="events.new.advancedOptions">
        <VStack spacing={20}>
          <VStack spacing={4}>
            <Text
              style={{ ...getCommonInputLabelStyle(theme) }}
              tx="events.new.tab.tags"
            />
            <Text
              style={{
                ...getCommonInputLabelStyle(theme),
                color: theme.grey30,
              }}
              tx="events.new.tab.tags.info"
            />
            <Tabs
              unstyled
              mt={'5px'}
              maw={'max-content'}
              style={{
                flexDirection: 'row',
                flex: 1,
                rowGap: 20,
              }}
              selectedIndex={getCurrentTag(tags)}
              setSelectedIndex={(index) =>
                handleTabIndexChange(index as EventTag)
              }
            >
              <TagButton value={EventTag.ALL} tx="events.new.tab.all" />
              <TagButton value={EventTag.KIDS} tx="events.new.tab.kids" />
              <TagButton value={EventTag.ADULTS} tx="events.new.tab.adults" />
            </Tabs>
          </VStack>
          {!eventId && (
            <ValidatedSelect
              optional
              name="org"
              txLabel="nav.org"
              txPlaceholder="nav.org"
              onChange={() => resetField('trainer')}
              options={orgOptions}
            />
          )}
          <ValidatedSwitch
            name="multisport"
            txLabel="events.new.multisport"
            data-testid="multisport"
          />
          <Divider color={colors.grey10} />
          {(!eventId || isJoined) &&
            modelOrg.isAdmin &&
            watchOrg &&
            watchOrg.id !== 'none' && (
              <ValidatedSwitch
                onChange={() => resetField('trainer', { defaultValue: null })}
                name="dontParticipate"
                txLabel="events.new.dontParticipate"
              />
            )}
          {(!eventId || model?.hasTrainer || orgId) &&
            watchDontParticipate &&
            watchOrg &&
            watchOrg.id !== 'none' && (
              <ValidatedSelect
                name="trainer"
                txLabel="events.new.trainer"
                txPlaceholder={
                  !playersToSelect.length
                    ? 'events.new.no.trainer'
                    : 'events.new.trainer'
                }
                disabled={!playersToSelect.length}
                searchable={false}
                options={playersToSelect.map((p) => ({
                  id: p.id,
                  item: `${p.name} ${p.surname}`,
                }))}
              />
            )}
          {(!eventId || isJoined) &&
            modelOrg.isAdmin &&
            watchOrg &&
            watchOrg.id !== 'none' && <Divider color={colors.grey10} />}
          <ValidatedSwitch
            name="requireConfirm"
            txSubLabel="events.new.confirmation.info"
            txLabel="events.new.confirmation.required"
            data-testid="requireConfirm"
          />
          {watchRequireConfirm && (
            <ValidatedNumberInput
              name="hoursConfirm"
              min={1}
              txLabel="events.new.confirmation.hours"
              txPlaceholder="events.new.confirmation.hours"
            />
          )}
          <Divider color={colors.grey10} />
          <ValidatedSwitch
            name="linkRequired"
            txSubLabel="events.new.external.link.info"
            txLabel="events.new.external.link.required"
            data-testid="linkRequired"
          />
          {watchLinkRequired && (
            <ValidatedInput
              name="externalLink"
              txLabel="events.new.external.link"
              txPlaceholder="events.new.external.link"
              required={watchLinkRequired}
            />
          )}
          {!eventId && (
            <ValidatedSwitch
              disabled={watchUnlimited}
              name="enableQueue"
              txLabel="events.new.enableQueue"
              data-testid="enableQueue"
            />
          )}
        </VStack>
      </Collapsible>
    </>
  );
};

export default AdvancedSection;
