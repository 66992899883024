import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { ShareProps } from './interface';

import withRouter from '@/navigation/withRouter';
import { ROUTE_EVENTS, ROUTE_SHARE_ITEM } from '@/util/constants';

// For backwards compatibility
const ShareItemOld: FC<ShareProps> = ({ query }) => {
  const type = query?.type;
  const id = query?.id;
  const nav = useNavigate();

  useEffect(() => {
    if (type && id) {
      nav(`${ROUTE_SHARE_ITEM}/${type}/${id}`);
    } else {
      nav(ROUTE_EVENTS);
    }
  }, [type, id]);

  return null;
};

export default withRouter(ShareItemOld);
