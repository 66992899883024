import moment from 'moment';
import { useEffect } from 'react';
import type { Message, Chat } from '@aclito/entities';
import { updateLastSeen } from '@aclito/shared/util/updateLastSeen';
import { useAppSelector } from '@aclito/shared/hooks';
import { profileSelectors } from '@aclito/shared/redux/slices/profileSlices';

import { Storage } from '@/util/storage';

export const useChatWeb = (messages: Message[], chat?: Chat) => {
  const userInfo = useAppSelector(profileSelectors.userInfo);

  const isMyMessage = (m: Message) => userInfo.id === m.from;
  const sortedMessages = [...messages].sort((x, y) =>
    moment(x.createdAt).diff(moment(y.createdAt)),
  );

  useEffect(() => {
    if (chat?.id) {
      updateLastSeen(Storage, {
        chatId: chat.id,
        messages,
        userId: userInfo.id,
      });
    }
  }, [chat?.id, messages, userInfo.id]);

  return {
    sortedMessages,
    isMyMessage,
  };
};
