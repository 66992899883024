import { Achievement, UserAchievement } from '@aclito/entities';

import { getLevel } from './getLevel';

export const createDataForAchievementsCards = (
  achievements?: Achievement[],
  userAchievements?: UserAchievement[],
  aliasId?: string,
) => {
  if (!achievements) {
    return [];
  }

  userAchievements = aliasId
    ? userAchievements?.filter((item) => item.aliasId === aliasId)
    : userAchievements?.filter((item) => !item.aliasId);

  return achievements
    .map((achievement, index) => {
      const userAchievement = userAchievements?.find(
        (item) => item.achievementId === achievement.id,
      );

      const achievementProgress = userAchievement?.progress ?? 0;
      let classic = !achievement.config.levelSteps.length;
      let sumOfSteps = 0;
      let level;
      let totalProgress;
      let currentProgress;

      if (achievement.config.levelSteps.length === 1) {
        const total = achievement.config.levelSteps[0];
        level = achievementProgress >= total ? 1 : 0;
        totalProgress = total;
        currentProgress = achievementProgress;
        classic = level === 1;
      } else {
        level = getLevel(achievementProgress, achievement.config.levelSteps);
        for (let i = 0; i < level - 1; i++) {
          sumOfSteps = sumOfSteps + achievement.config.levelSteps[i];
        }
        currentProgress = achievementProgress - sumOfSteps;
        totalProgress =
          level > 0 ? achievement.config.levelSteps[level - 1] : 0;
      }

      if (!classic) {
        level = level - 1;
      }
      const unlocked = classic ? currentProgress !== 0 : level > 0;

      return {
        ...achievement,
        classic,
        unlocked,
        achievementProgress,
        currentProgress,
        totalProgress,
        level,
        key: index,
      };
    })
    .sort((a, b) => {
      if (a.level === 0 && b.level === 0) {
        return b.currentProgress - a.currentProgress;
      }
      return b.level - a.level;
    });
};
