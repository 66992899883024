// https://stackoverflow.com/a/65239086
export const stringHashCode = (str: string) => {
  let hash = 0;
  for (let i = 0; i < str.length; ++i) {
    hash = Math.imul(31, hash) + str.charCodeAt(i);
  }

  let newHash = hash | 0;
  while (newHash.toString().length < 16) {
    // @ts-ignore
    newHash += '0';
  }
  return newHash;
};
