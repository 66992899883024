import { useAppSelector } from '@aclito/shared/hooks';
import { commonSelectors } from '@aclito/shared/redux/slices/commonSlices';
import { FlashMessagesKeys } from '@aclito/shared/util/flashMessage';
import { showTranslatedNotification as stn } from '@aclito/ui-web/utils/showTranslatedNotification';

export const useShowTranslatedNotification = () => {
  const locale = useAppSelector(commonSelectors.locale);
  return (type: FlashMessagesKeys, onClick?: VoidFunction) => {
    stn(locale, type, onClick);
  };
};
