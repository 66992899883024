import styled from 'styled-components';
import { Menu } from '@mantine/core';
import { deviceMax, deviceMin } from '@aclito/ui-web/utils/mediaQueries';

import { HStack, Text, VStack } from '@/components';

export const DisplayMobile = styled(Menu)`
  @media ${deviceMin.tablet} {
    display: none;
  }
`;

export const DisplayFull = styled(HStack)`
  @media ${deviceMax.tablet} {
    display: none;
  }
`;

export const Content = styled(VStack)`
  min-height: 100%;
  width: 100%;
  gap: unset;
`;

export const EventName = styled(Text)`
  color: ${({ theme }) => theme.grey80};
  font-size: 1.25rem;
  font-weight: 700;
`;
export const EventHeader = styled(VStack)`
  padding-bottom: 1.6rem;
`;
