import type { UserAlias } from '@aclito/entities';
import { Card } from '@mantine/core';
import React, { MouseEvent } from 'react';
import styled from 'styled-components';
import { profileActions } from '@aclito/shared/redux/slices';
import { useAppDispatch } from '@aclito/shared/hooks/useDispatch';

import { ROUTE_PROFILE_ALIASES_EDIT } from '@/util/constants';
import { useNavigate } from '@/hooks/useNavigate';
import { HStack, Text, Icon, Avatar, IconHolder } from '@/components';
import { useModal } from '@/hooks/useModal';

interface AliasCardProps {
  alias: UserAlias;
  showButtons: boolean;
  onClick: (alias: UserAlias) => void;
}

const AliasCard: React.FC<AliasCardProps> = ({
  alias,
  onClick,
  showButtons = true,
}) => {
  const navigation = useNavigate();
  const dispatch = useAppDispatch();
  const { openModal, openConfirmModal } = useModal();

  const onEditAliasPress = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    navigation(ROUTE_PROFILE_ALIASES_EDIT, { state: { alias } });
  };

  const onDeleteAliasPress = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    const handleRemove = async () => {
      const result = await dispatch(
        profileActions.removeUserAliasAsync({ aliasData: alias }),
      ).unwrap();

      if (Array.isArray(result)) {
        openModal({
          centered: true,
          size: 'auto',
          type: 'aliasEvents',
          values: {
            events: result,
          },
        });
      }
    };

    openConfirmModal({
      confirmProps: { color: 'red', w: 100 },
      txTitle: 'profile.alias.alert.header',
      closeOnConfirm: true,
      txLabels: { confirm: 'delete', cancel: 'close' },
      onConfirm: handleRemove,
    });
  };

  return (
    <StyledCard onClick={() => onClick(alias)} mb={10} radius={10}>
      <HStack>
        <Avatar image={alias.image} size={48} />
        <Text size={16} text={`${alias.name} ${alias.surname}`} />
      </HStack>
      {showButtons && (
        <IconHolder style={{ cursor: 'pointer' }}>
          <Icon
            icon="edit"
            color={'primary'}
            onClick={onEditAliasPress}
            data-testid={`${alias.name} ${alias.surname}-edit`}
            size={24}
          />
          <Icon
            icon="delete"
            color={'red'}
            onClick={onDeleteAliasPress}
            size={24}
            data-testid={`${alias.name} ${alias.surname}-delete`}
          />
        </IconHolder>
      )}
    </StyledCard>
  );
};

const StyledCard = styled(Card)`
  display: flex;
  background-color: ${({ theme }) => theme.white};
  justify-content: space-between;
  overflow: unset;
` as typeof Card;

export default AliasCard;
