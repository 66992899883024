import React from 'react';
import { useTheme } from 'styled-components';
import { Organization } from '@aclito/entities';
import { Menu } from '@mantine/core';
import { OrganizationModel } from '@aclito/shared/classes/OrganizationModel';
import { useAppSelector } from '@aclito/shared/hooks';
import { profileSelectors } from '@aclito/shared/redux/slices/profileSlices';
import { useMediaQuery } from '@mantine/hooks';
import { deviceMin } from '@aclito/ui-web/utils/mediaQueries';
import {
  useNavigate as useReactRouterNavigate,
  createSearchParams,
} from 'react-router-dom';

import { useOrganizationInfoHandlers } from '../hooks/useOrganizationInfoHandlers';

import { ActionButton, HStack, Text, Icon } from '@/components';
import { useNavigate } from '@/hooks/useNavigate';
import { ROUTE_EDIT_ORGANIZATION, ROUTE_NEW_EVENT } from '@/util/constants';

interface ButtonsProps {
  org: Organization;
  compact?: boolean;
}

const Buttons: React.FC<ButtonsProps> = ({ org, compact }) => {
  const queryTablet = useMediaQuery(deviceMin.tablet);

  const nav = useNavigate();
  const navigate = useReactRouterNavigate();
  const theme = useTheme();
  const { handleLeave, handleShare, handleDelete, handleAddPost } =
    useOrganizationInfoHandlers(org);

  const userInfo = useAppSelector(profileSelectors.userInfo);
  const modelOrg = new OrganizationModel(org, userInfo);

  const handleButton = () => {
    navigate({
      pathname: ROUTE_NEW_EVENT,
      search: createSearchParams({ org: org.id }).toString(),
    });
  };
  const handleEdit = () => {
    nav(ROUTE_EDIT_ORGANIZATION, { state: { org } });
  };

  const shareOrg = () => {
    handleShare(org.id, 'org', org.name);
  };

  return (
    <>
      {compact ? (
        <HStack>
          {queryTablet && (modelOrg.isAdmin || modelOrg.isPowerUser) && (
            <>
              <ActionButton
                primary
                onClick={handleButton}
                iconType={'add'}
                tx={'create.event'}
              />
              <ActionButton
                primary
                iconType={'add'}
                onClick={handleAddPost}
                tx={'org.add.feed'}
              />
            </>
          )}
          <Menu>
            <Menu.Target>
              <ActionButton iconType={'more'} />
            </Menu.Target>
            <Menu.Dropdown
              style={{
                borderRadius: '10px',
                backgroundColor: theme.white,
                color: theme.grey80,
                border: 'none',
              }}
            >
              {!queryTablet && (modelOrg.isAdmin || modelOrg.isPowerUser) && (
                <Menu.Item
                  icon={<Icon icon="add" size={24} />}
                  onClick={handleButton}
                >
                  <Text tx="create.event" />
                </Menu.Item>
              )}
              {!queryTablet && modelOrg.canControl && (
                <Menu.Item
                  icon={<Icon icon="add" size={24} />}
                  onClick={handleAddPost}
                >
                  <Text tx="org.add.feed" />
                </Menu.Item>
              )}
              {modelOrg.canControl && (
                <Menu.Item
                  icon={<Icon icon="edit" size={24} />}
                  onClick={handleEdit}
                >
                  <Text tx="tooltip.edit" />
                </Menu.Item>
              )}
              <Menu.Item
                icon={<Icon icon="share" size={24} />}
                onClick={shareOrg}
              >
                <Text tx="tooltip.share" />
              </Menu.Item>
              {modelOrg.canEdit && (
                <Menu.Item
                  icon={<Icon icon="delete" size={24} />}
                  onClick={handleDelete}
                >
                  <Text tx="tooltip.delete" />
                </Menu.Item>
              )}
              {!modelOrg.isAdmin && modelOrg.isMember && (
                <Menu.Item
                  icon={<Icon icon="leave" size={24} />}
                  onClick={handleLeave}
                >
                  <Text tx="tooltip.leave" />
                </Menu.Item>
              )}
            </Menu.Dropdown>
          </Menu>
        </HStack>
      ) : (
        <HStack>
          {(modelOrg.isAdmin || modelOrg.isPowerUser) && (
            <>
              <ActionButton
                primary
                onClick={handleButton}
                iconType={'add'}
                tx={'create.event'}
              />
              <ActionButton
                primary
                iconType={'add'}
                onClick={handleAddPost}
                tx={'org.add.feed'}
              />
            </>
          )}
          {modelOrg.canControl && (
            <ActionButton
              iconType={'edit'}
              onClick={handleEdit}
              tx={'tooltip.edit'}
            />
          )}
          <ActionButton
            iconType={'share'}
            onClick={shareOrg}
            tx={'tooltip.share'}
          />
          {!modelOrg.isAdmin && modelOrg.isMember && (
            <ActionButton
              bg={'red10'}
              textcolor={'red'}
              iconType={'leave'}
              onClick={handleLeave}
              tx={'tooltip.leave'}
            />
          )}
          {modelOrg.canEdit && (
            <ActionButton
              bg={'red10'}
              textcolor={'red'}
              iconType={'delete'}
              onClick={handleDelete}
              tx={'tooltip.delete'}
            />
          )}
        </HStack>
      )}
    </>
  );
};

export default Buttons;
