import React from 'react';
import styled from 'styled-components';
import { cardColorPicker } from '@aclito/shared/util/cardColorPicker';
import { EventWithDateObject } from '@aclito/shared/types';
import { useMediaQuery } from '@mantine/hooks';
import { deviceMax } from '@aclito/ui-web/utils/mediaQueries';

import { HStack, IconSelect } from '@/components';

interface WeekComponentProps {
  event: EventWithDateObject;
}

const OPACITY = 26;

const WeekComponent: React.FC<WeekComponentProps> = ({ event }) => {
  const sportColor = cardColorPicker(event.activityType);

  const mqLaptop = useMediaQuery(deviceMax.laptop);

  return (
    <StyledContainer sportColor={sportColor} spacing={4}>
      <IconSelect
        iconSize={mqLaptop ? 8 : 16}
        iconColor={cardColorPicker(event.activityType)}
        type={event.activityType}
        style={styleIconSelect}
      ></IconSelect>
      <StyledName>{event.name}</StyledName>
    </StyledContainer>
  );
};

const styleIconSelect: React.CSSProperties = {
  display: 'flex',
  alignItems: 'center',
};

const StyledContainer = styled(HStack)<{ sportColor: string }>`
  color: ${(props) => props.sportColor};
  padding: 8px;
  background-color: ${(props) => `${props.sportColor}${OPACITY}`};
  border-left: ${(props) => `solid 8px ${props.sportColor}`};
  height: 100%;
  flex-wrap: nowrap;
  @media ${deviceMax.desktop} {
    height: 80%;
    border-left: ${(props) => `solid 6px ${props.sportColor}`};
  }
  @media ${deviceMax.laptop} {
    height: 70%;
  }
  @media ${deviceMax.tablet} {
    height: 60%;
    border-left: ${(props) => `solid 4px ${props.sportColor}`};
  }
`;

const StyledName = styled.span`
  font-family: Roboto, sans-serif;
  font-weight: 700;
  font-size: 16px;
  @media ${deviceMax.desktop} {
    font-size: 12px;
  }
  @media ${deviceMax.laptop} {
    font-size: 10px;
  }
  @media ${deviceMax.tablet} {
    font-size: 8px;
  }
`;

export default WeekComponent;
