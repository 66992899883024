import { useState } from 'react';

import { noteActions, noteSelectors } from '../../redux/slices/noteSlices';
import { useAppDispatch } from '../../hooks/useDispatch';
import { useAppSelector, useSchema } from '../../hooks';

export const useNotesShared = () => {
  const notes = useAppSelector(noteSelectors.notes);
  const { emailValidator, phoneValidator, ValidationError } = useSchema();

  const [errorTable, setErrorTable] = useState<{
    email: undefined | string;
    phone: undefined | string;
  }>({
    email: undefined,
    phone: undefined,
  });

  const dispatch = useAppDispatch();
  const handleAddEmail = (email: string) => {
    try {
      emailValidator.validateSync(email);
      dispatch(noteActions.addEmail(email));
      setErrorTable((prev) => ({ ...prev, email: undefined }));
      handleSubmit({ tel: undefined, email: email });
    } catch (e) {
      if (e instanceof ValidationError) {
        const error = e.errors[0];
        setErrorTable((prev) => ({ ...prev, email: error }));
      }
    }
  };

  const handleAddTel = (text: string) => {
    try {
      text.length && phoneValidator.validateSync(text);
      dispatch(noteActions.addTel(text));
      setErrorTable((prev) => ({ ...prev, phone: undefined }));
      handleSubmit({ tel: text, email: undefined });
    } catch (e) {
      if (e instanceof ValidationError) {
        const error = e.errors[0];
        setErrorTable((prev) => ({ ...prev, phone: error }));
      }
    }
  };

  const handleSubmit = async (data: {
    tel: string | undefined;
    email: string | undefined;
  }) => {
    await dispatch(noteActions.saveNoteAsync(data)).unwrap();
  };

  const handleClear = async () => {
    await dispatch(noteActions.clearNoteAsync()).unwrap();
    setErrorTable({ phone: undefined, email: undefined });
  };
  const data = [
    { text: 'Tel:', value: notes?.tel ?? '', error: errorTable.phone },
    { text: 'Email:', value: notes?.email ?? '', error: errorTable.email },
  ];

  const operationTable = {
    0: (text: string) => handleAddTel(text),
    1: (text: string) => handleAddEmail(text),
  } as const;

  const contactFilled = data[0].value && data[1].value;
  return {
    handleSubmit,
    handleAddTel,
    handleClear,
    handleAddEmail,
    data,
    operationTable,
    contactFilled,
  };
};
