import { EmojiByOrgMap } from '@aclito/entities';

import { EmojisKey } from '../types';

export function sortUsersByTopEmoji<T extends { id: string }>(
  users: T[],
  emojiData: EmojiByOrgMap,
  emojiName: EmojisKey,
) {
  return [...users].sort((a, b) => {
    const userAEmojiValue = emojiData[a.id]?.[emojiName] || 0;
    const userBEmojiValue = emojiData[b.id]?.[emojiName] || 0;

    return userBEmojiValue - userAEmojiValue;
  });
}
