import { useEffect } from 'react';
import { WS_URL } from '@aclito/client';
import type { Notification } from '@aclito/entities';

import { useAppDispatch } from '../../../hooks/useDispatch';
import { notificationActions } from '../../../redux/slices';
import { useAppSelector } from '../../../hooks';
import { profileSelectors } from '../../../redux/slices/profileSlices';

export const useNotificationWS = () => {
  const dispatch = useAppDispatch();
  const userInfo = useAppSelector(profileSelectors.userInfo);

  useEffect(() => {
    let ws: WebSocket;

    if (userInfo) {
      ws = new WebSocket(`${WS_URL}?userId=${userInfo.id}`);
      try {
        //MessageEvent - TS error in RN
        const listener = (event: any) => {
          const data = JSON.parse(event.data) as Notification;
          dispatch(notificationActions.addNotification(data));
        };

        ws.addEventListener('message', listener);
      } catch {
        return;
      }
    }

    return () => ws?.close();
  }, [userInfo?.id]);
};
