import React from 'react';
import styled from 'styled-components';

import Result from './Result';

import { CodeType } from '@/features/integration/utils/verificationErrorCodes';
import { Figure1, Figure2 } from '@/images';

type VerificationBodyProps = {
  isLoading: boolean;
  response?: CodeType;
  hasError: boolean;
  resendRequest: (body: object) => void;
  resendCodeBody: {
    ClientId: string | string[] | undefined;
    Username: string | string[] | undefined;
  };
};

const VerificationBody: React.FC<VerificationBodyProps> = ({
  isLoading,
  response,
  hasError,
  resendRequest,
  resendCodeBody,
}) => {
  return (
    <Center>
      <Wrapper>
        <Result
          {...{ isLoading, response }}
          resendRequest={() => resendRequest(resendCodeBody)}
        />
        <Center>
          {response === 'Welcome' && <Figure2 />}
          {hasError && <Figure1 />}
        </Center>
      </Wrapper>
    </Center>
  );
};

const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Wrapper = styled.div`
  height: 100vh;
  z-index: 3;
`;

export default VerificationBody;
