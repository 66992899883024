import { Center, Loader } from '@mantine/core';
import React from 'react';
import { useUserProfile } from '@aclito/shared/features/profile/hooks/useUserProfile';
import { useNavigate } from 'react-router-dom';

import Achievements from '../../../goals/Achievements';

import { Buttons } from './components';
import { UserProfileProps } from './interface';
import DeletedUserProfile from './components/DeletedUserProfile';

import {
  VStack,
  TitleBar,
  Title,
  SectionGrid,
  HStack,
  BackButton,
  SectionTitle,
} from '@/components';
import { AboutUserSection, AboutUserMain } from '@/common/Profile';
import withRouter from '@/navigation/withRouter';
import Reviews from '@/common/Profile/Reviews';
import { ROUTE_GOALS } from '@/util/constants';

const UserProfile: React.FC<UserProfileProps> = ({ state }) => {
  const userId = state?.userId ?? '';
  const navigate = useNavigate();

  const { displayedUser, canBeReported, stats } = useUserProfile(userId);

  const handleNavigationToGoals = (id: string) => {
    navigate(ROUTE_GOALS + `/${id}`);
  };

  return (
    <SectionGrid $isOpen={false}>
      <TitleBar>
        <HStack>
          <BackButton />
          <Title tx={'nav.profile'} />
        </HStack>
        {displayedUser && !displayedUser.deleted && (
          <Buttons
            {...{
              userInfo: displayedUser,
              canBeReported,
            }}
          />
        )}
      </TitleBar>
      {displayedUser?.deleted ? (
        <DeletedUserProfile {...{ userInfo: displayedUser }} />
      ) : (
        <VStack>
          {!displayedUser || !stats ? (
            <Center style={{ height: '100%' }}>
              <Loader />
            </Center>
          ) : (
            <>
              {displayedUser && (
                <AboutUserMain
                  {...{ userInfo: displayedUser, stats, myProfile: false }}
                />
              )}
              <AboutUserSection
                {...{ userInfo: displayedUser, stats, myProfile: false }}
              />
            </>
          )}

          {displayedUser && (
            <>
              <SectionTitle
                onClick={() => handleNavigationToGoals(displayedUser.id)}
                buttonLabelTx="tabs.achievements"
              />
              <Achievements preview id={displayedUser.id} />
              <Reviews id={displayedUser.id} />
            </>
          )}
        </VStack>
      )}
    </SectionGrid>
  );
};

export default withRouter(UserProfile);
