import {
  canJoinEvent,
  canJoinQueue,
} from '@aclito/shared/features/event/utils/canJoinEvent';
import { closeAllModals } from '@mantine/modals';
import { EventModel } from '@aclito/shared/classes/EventModel';
import { useEventInfoJoin as useSharedEventInfoJoin } from '@aclito/shared/features/event/hooks/useEventInfoJoin';
import { LocaleType } from '@aclito/shared/types';
import { FlashMessagesKeys } from '@aclito/shared/util/flashMessage';
import { showTranslatedNotification } from '@aclito/ui-web/utils/showTranslatedNotification';

import { useEventInfoHandlers } from '@/features/event/screens/EventInfo/hooks/useEventInfoHandlers';
import { useModal } from '@/hooks/useModal';
import { openInNewTab } from '@/util/openLinkInNewTab';
import { useNavigate } from '@/hooks/useNavigate';
import { ROUTE_PROFILE } from '@/util/constants';

export const useEventInfoJoin = () => {
  const nav = useNavigate();

  const {
    aliasJoinEvent,
    aliasJoinRepeatingEvent,
    joinEvent,
    joinRepeatingEvents,
    genderValidation,
  } = useSharedEventInfoJoin();
  const { openConfirmModal } = useModal();

  const { handleShare } = useEventInfoHandlers();

  const joinWithInvalidGender = (
    model: EventModel,
    locale: LocaleType,
    type: FlashMessagesKeys,
  ) => {
    const genderNotSet = model.userInfo.gender === 'unknown';

    showTranslatedNotification(
      locale,
      type,
      genderNotSet ? () => nav(ROUTE_PROFILE) : undefined,
    );
  };

  const shareEvent = (model: EventModel) =>
    handleShare(model.event.id, 'event', model.event.name);

  const handleJoin = async (model: EventModel, selectedIds?: string[]) => {
    if (genderValidation(model, joinWithInvalidGender)) {
      return;
    }

    if (model.event.externalLink) {
      openInNewTab(model.event.externalLink);
      await _handleJoinDialog(model, selectedIds);
    } else {
      await _handleJoinDialog(model, selectedIds);
    }
  };

  const _handleJoinDialog = async (
    model: EventModel,
    selectedIds?: string[],
  ) => {
    const { event, userInfo } = model;
    const canJoinPlayers = canJoinEvent(event, userInfo);
    const canJoinPlayersInQueue = canJoinQueue(event);

    const joinRepeating = () => {
      selectedIds
        ? aliasJoinRepeatingEvent(model, selectedIds)
        : joinRepeatingEvents(model);

      closeAllModals();
      model.canShowShare && shareEvent(model);
    };
    const join = () => {
      selectedIds ? aliasJoinEvent(model, selectedIds) : joinEvent(model);

      closeAllModals();
      model.canShowShare && shareEvent(model);
    };

    const continueModal = () => {
      if (
        event.enableQueue
          ? canJoinPlayers || canJoinPlayersInQueue
          : canJoinPlayers
      ) {
        openConfirmModal({
          txTitle: 'modal.confirmation',
          closeOnConfirm: false,
          confirmProps: { color: 'orange' },
          txLabels: {
            cancel: 'close',
            confirm: canJoinPlayers
              ? 'events.info.join'
              : 'events.info.joinQueue',
          },
          onConfirm: async () => {
            if (event.repetitionId) {
              openConfirmModal({
                confirmProps: { color: 'red', w: 100 },
                txTitle: 'join.repeating',
                closeOnConfirm: false,
                type: 'eventRepeatingJoin',
                values: { onConfirm: joinRepeating },
                txLabels: { confirm: 'events.info.join', cancel: 'close' },
                onConfirm: join,
              });
            } else {
              join();
            }
          },
        });
      } else {
        openConfirmModal({
          txTitle: 'events.info.req',
        });
      }
    };

    continueModal();
  };

  return { handleJoin };
};
