import { EventRaw, EventsByOrgParams } from '@aclito/entities';

import { ContentType, HttpClient } from '../httpClient';

export class PublicClient extends HttpClient {
  public = {
    landing: () =>
      this.request<EventRaw[]>({
        path: `/public/landing`,
        method: 'GET',
        type: ContentType.Json,
      }),

    calendar: (id: string, query: EventsByOrgParams) =>
      this.request<EventRaw[]>({
        path: `/public/calendar/${id}`,
        method: 'GET',
        query,
        type: ContentType.Json,
      }),
  };
}
