import React, { useCallback } from 'react';
import moment, { Moment } from 'moment';
import { useController } from 'react-hook-form';
import { DateValue } from '@mantine/dates';

import DateTimePicker from './DateTimePicker';
import { DateTimePickerProps } from './types';

const ValidatedDateTimePicker: React.FC<
  DateTimePickerProps & { name: string }
> = ({ name, ...props }) => {
  const {
    field: { value, onChange, onBlur },
    fieldState: { error },
  } = useController({ name });

  const updateWithDate = (val: DateValue) => {
    if (!val) {
      onChange('');
    } else {
      onChange(val);
    }
  };

  const handleValue = useCallback(
    (val: Date | string | Moment) => {
      let convertedValue;
      if (!val) {
        convertedValue = undefined;
      } else if (value instanceof moment) {
        // @ts-ignore
        convertedValue = val.toDate();
      } else if (typeof val === 'string') {
        convertedValue = moment(value).toDate();
      } else {
        convertedValue = val;
      }
      return convertedValue;
    },
    [value],
  );

  return (
    <DateTimePicker
      {...{
        ...props,
        onBlur: onBlur,
        value: handleValue(value),
        error: error?.message,
        onChange: (d) => updateWithDate(d),
      }}
    />
  );
};

export default ValidatedDateTimePicker;
