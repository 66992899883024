import { IStorage, Item, ItemToReturnType } from '@aclito/shared/types';
import { parseAsyncStorageData } from '@aclito/shared/features/event/utils/normalizeStorageData';

export const Storage: IStorage = {
  setItem: (item: Item, value: string) =>
    new Promise((resolve) => resolve(localStorage.setItem(item, value))),
  getItem: (item: Item) =>
    new Promise((resolve) => resolve(localStorage.getItem(item) ?? '')),
  getParsedItems: async <T extends keyof ItemToReturnType>(item: T) =>
    (await parseAsyncStorageData(Storage.getItem(item))) as ItemToReturnType[T],
  removeItem: (item: Item) =>
    new Promise((resolve) => resolve(localStorage.removeItem(item))),
};
