import React from 'react';
import { VStack } from '@aclito/ui-web/components/Layout';
import styled from 'styled-components';

import Contacts from './components/Contacts';
import Copyright from './components/Copyright';

const Footer: React.FC = () => {
  return (
    <Container px={24} spacing={0}>
      <TopContainer py={32}>
        <Contacts />
      </TopContainer>
      <Copyright />
    </Container>
  );
};

const TopContainer = styled(VStack)``;

const Container = styled(VStack)`
  background-color: ${({ theme }) => theme.white};
  box-shadow: 0px -2px 4px 0px rgba(0, 0, 0, 0.05);
  width: 100%;
`;

export default Footer;
