import React from 'react';
import styled from 'styled-components';

interface ProgressBarProps {
  currentProgressValue: number;
  totalProgressValue: number | undefined;
}

const ProgressBar: React.FC<ProgressBarProps> = ({
  currentProgressValue,
  totalProgressValue,
}) => {
  return (
    <ProgressBarContainer>
      {!!totalProgressValue && (
        <ProgressBarFiller
          current={currentProgressValue}
          total={totalProgressValue}
        />
      )}
    </ProgressBarContainer>
  );
};

const ProgressBarContainer = styled.div`
  width: 70%;
  height: 1rem;
  background-color: ${({ theme }) => theme.grey10};
  border-radius: 8px;
`;

const ProgressBarFiller = styled.div<{ current: number; total: number }>`
  width: ${(props) => `min(${(props.current / props.total) * 100}%, 100%)`};
  height: 100%;
  background-color: ${({ theme }) => theme.primary};
  border-radius: inherit;
`;

export default ProgressBar;
