/* eslint-disable react/display-name */
import { forwardRef } from 'react';
import { Input as MantineInput } from '@mantine/core';
import { useTranslate } from '@aclito/shared/hooks';
import { useTheme } from 'styled-components';

import { getCommonInputLabelStyle } from '../../utils/styles';

import { InputProps } from './types';

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    { label, placeholder, txLabel, txPlaceholder, rightSection, ...props },
    ref,
  ) => {
    const t = useTranslate();
    const theme = useTheme();
    const i18nLabel = txLabel && t(txLabel);
    const labelContent = i18nLabel || label;

    const i18nPlaceholder = txPlaceholder && t(txPlaceholder);
    const placeholderContent = i18nPlaceholder || placeholder;

    return (
      // @ts-ignore
      <MantineInput.Wrapper
        size="md"
        label={labelContent}
        styles={{
          label: { ...getCommonInputLabelStyle(theme) },
        }}
        {...props}
      >
        <MantineInput
          // @ts-ignore
          ref={ref}
          variant="filled"
          styles={{
            input: {
              backgroundColor: theme.white,
              color: theme.grey80,
            },
          }}
          size="md"
          radius={10}
          label={labelContent}
          placeholder={placeholderContent}
          rightSection={rightSection}
          {...props}
        />
      </MantineInput.Wrapper>
    );
  },
);
export default Input;
