import { useTranslate } from '@aclito/shared/hooks';
import { BinType } from '@aclito/shared/features/notification/hooks/useFilterNotifications';
import { TranslateKey } from '@aclito/shared/types';
import { Chip } from '@mantine/core';
import { AnimatePresence, motion, Variants } from 'framer-motion';
import { forwardRef, Ref } from 'react';
import styled from 'styled-components';

import { HStack } from '@/components';

type Props = {
  variant: Variants;
  filter: boolean;
  chipValue: BinType[];
  handleChipFilter: (value: BinType[]) => void;
  options: BinType[];
  translations: Record<BinType, TranslateKey>;
};

const NotificationFilter = (
  {
    variant,
    filter,
    chipValue,
    handleChipFilter,
    options,
    translations,
  }: Props,
  ref: Ref<HTMLDivElement>,
) => {
  const t = useTranslate();

  return (
    <AnimatePresence initial={false}>
      {filter && (
        <motion.div
          ref={ref}
          style={{ position: 'absolute' }}
          variants={variant}
          exit="exit"
          initial="init"
          animate="enter"
        >
          <LabelContainer>
            <Chip.Group multiple value={chipValue} onChange={handleChipFilter}>
              {options.map((type) => (
                <Chip key={type} value={type} color="primary" variant="outline">
                  {t(translations[type])}
                </Chip>
              ))}
            </Chip.Group>
          </LabelContainer>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

const LabelContainer = styled(HStack)``;

export default forwardRef(NotificationFilter);
