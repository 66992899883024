import { EventFilters } from '@aclito/shared/redux/types';

export const getLiteFilters = (
  orgId: string | undefined,
  filters: EventFilters,
) => {
  const orgFilter = { item: '', id: orgId ?? '' };

  return {
    org: orgFilter,
    freeOnly: filters.freeOnly,
    price: filters.price,
    from: filters.from,
    to: filters.to,
    activityType: filters.activityType,
    query: filters.query,
    tags: filters.tags,
  };
};
