import {
  FieldValues,
  SubmitErrorHandler,
  SubmitHandler,
  useFormContext,
} from 'react-hook-form';

import { HStackButtons } from '../../utils/styles';
import { ActionButton, SmallButton } from '../Button';
import { Icon } from '../Icons/Icon';

type StepperButtonsProps<T extends FieldValues> = {
  active: number;
  prevStep: () => void;
  nextStep: () => void;
  onSubmit: SubmitHandler<T>;
  onError: SubmitErrorHandler<T>;
  steps: number;
  create?: boolean;
};

const StepperButtons = <T extends FieldValues>({
  active,
  prevStep,
  nextStep,
  onSubmit,
  onError,
  steps,
  create = true,
}: StepperButtonsProps<T>) => {
  const { handleSubmit } = useFormContext<T>();

  const handleButtonSubmit = () => {
    handleSubmit(onSubmit, onError)();
  };

  return (
    <HStackButtons>
      {active !== 0 && (
        <ActionButton
          iconType={'leftArrow'}
          className="back"
          onClick={prevStep}
          tx="previous"
        />
      )}
      {active !== steps && active !== steps - 1 && (
        <SmallButton
          bg={'primary10'}
          textcolor={'primary'}
          rightIcon={<Icon icon={'rightArrow'} color={'primary'} />}
          className="next"
          onClick={nextStep}
          tx="next"
        />
      )}
      {active === steps - 1 && (
        <SmallButton
          bg={'primary'}
          textcolor={'white'}
          onClick={handleButtonSubmit}
          tx={create ? 'create' : 'json.edit'}
        />
      )}
    </HStackButtons>
  );
};

export default StepperButtons;
