import React from 'react';
import { SimpleGrid } from '@mantine/core';
import { APP_NAME, VERSION } from '@aclito/shared/util/constants';
import { config } from '@aclito/shared/config';
import { useTheme } from 'styled-components';

import { FooterProps } from '../../interface';
import {
  MenuItemContainer,
  menuStyle,
  SmallAnchorText,
  SmallText,
  StyledHStack,
  StyledText,
} from '../DrawerStyles';

import { HStack, VStack, Icon } from '@/components';
import {
  ROUTE_NOTES,
  ROUTE_SETTINGS,
  ROUTE_SOCIAL,
  ROUTE_USER_LOCATION,
} from '@/util/constants';
import { useNavigate } from '@/hooks/useNavigate';
import { openInNewTab } from '@/util/openLinkInNewTab';
import { useDrawer } from '@/components/Drawer/hooks/useDrawer';

const Footer: React.FC<FooterProps> = ({ signOut, closeNavigation }) => {
  const navigate = useNavigate();

  const { matchers, getSxStyle } = useDrawer();
  const theme = useTheme();

  return (
    <SimpleGrid cols={1} verticalSpacing={0}>
      <MenuItemContainer
        onClick={() => {
          navigate(ROUTE_SOCIAL);
          closeNavigation();
        }}
        sx={getSxStyle(matchers.isSocial)}
      >
        <StyledHStack>
          <Icon icon="share" color="grey80" size="24" />
          <StyledText tx="invite" $active={matchers.isSocial} />
        </StyledHStack>
      </MenuItemContainer>
      <MenuItemContainer
        onClick={() => {
          navigate(ROUTE_USER_LOCATION);
          closeNavigation();
        }}
        sx={getSxStyle(matchers.isLocation)}
      >
        <StyledHStack>
          <Icon icon="map" size="24" />
          <StyledText tx="nav.location" $active={matchers.isLocation} />
        </StyledHStack>
      </MenuItemContainer>
      <MenuItemContainer
        onClick={() => {
          navigate(ROUTE_NOTES);
          closeNavigation();
        }}
        sx={getSxStyle(matchers.isNote)}
      >
        <StyledHStack>
          <Icon icon="note" size="24" />
          <StyledText tx="admin.notes.title" $active={matchers.isNote} />
        </StyledHStack>
      </MenuItemContainer>
      <MenuItemContainer
        onClick={() => {
          navigate(ROUTE_SETTINGS);
          closeNavigation();
        }}
        sx={getSxStyle(matchers.isSettings)}
      >
        <StyledHStack>
          <Icon icon="settings" color="grey80" size="24" />
          <StyledText tx="nav.settings" $active={matchers.isSettings} />
        </StyledHStack>
      </MenuItemContainer>
      <MenuItemContainer
        onClick={() => signOut()}
        sx={{
          ...menuStyle(theme),
        }}
      >
        <StyledHStack>
          <Icon icon="logout" color="grey80" size="24" />
          <StyledText tx="auth.signout.button" />
        </StyledHStack>
      </MenuItemContainer>

      <VStack spacing={4} style={{ margin: '16px 24px' }}>
        <SmallAnchorText
          onClick={() => openInNewTab(config.URL.aclitoTacURL)}
          tx={'footer.terms'}
        />
        <SmallAnchorText
          onClick={() => openInNewTab(config.URL.aclitoPpURL)}
          tx={'footer.privacy'}
        />
      </VStack>

      <HStack
        style={{ justifyContent: 'space-between', margin: '0 24px 16px 24px' }}
      >
        <SmallAnchorText
          onClick={() => openInNewTab(config.URL.aclitoManualURL)}
          style={{ fontSize: 12 }}
          tx={'aclito.manual.link'}
        />
        <SmallText>{`${APP_NAME} - ${VERSION}`}</SmallText>
      </HStack>
    </SimpleGrid>
  );
};

export default Footer;
