import { useAppSelector, useTranslate } from '@aclito/shared/hooks';
import { Box, LoadingOverlay } from '@mantine/core';
import { GoogleMap, Marker } from '@react-google-maps/api';
import React from 'react';
import { useMediaQuery } from '@mantine/hooks';
import { locationSelectors } from '@aclito/shared/redux/slices/locationSlice';
import { deviceMax } from '@aclito/ui-web/utils/mediaQueries';

import MapGuide from '../Maps/MapGuide';

import { useDefaultLocation } from './hooks/useDefaultLocation';

import { VStack, HStack, ActionButton, SectionGrid } from '@/components';
import { MAP_BORDER_RADIUS } from '@/util/constants';

const DefaultLocation: React.FC = () => {
  const {
    mapRef,
    isMapLoaded,
    handleCenterChanged,
    handleUpdateLocation,
    getLocation,
    isLoading,
    mapLocation,
  } = useDefaultLocation();

  const userLocation = useAppSelector(locationSelectors.userLocation);
  const t = useTranslate();
  const queryMobile = useMediaQuery(deviceMax.mobile);
  const disabled = !mapLocation.lat || !mapLocation.lon;

  return (
    <SectionGrid $isOpen={false}>
      {isMapLoaded && (
        <>
          <div
            style={{
              display: 'flex',
              justifyContent: queryMobile ? 'center' : 'flex-start',
            }}
          >
            <VStack spacing={16}>
              <VStack align={'center'} justify={'center'}>
                <Box sx={{ position: 'relative', zIndex: 1 }}>
                  <LoadingOverlay visible={isLoading} overlayBlur={2} />
                  <GoogleMap
                    ref={mapRef}
                    options={{
                      streetViewControl: false,
                      fullscreenControl: false,
                    }}
                    onClick={(e) =>
                      handleCenterChanged({
                        lat: e.latLng?.lat(),
                        lon: e.latLng?.lng(),
                      })
                    }
                    mapContainerStyle={mapContainer}
                    center={userLocation}
                    zoom={7}
                  >
                    <>
                      {userLocation && (
                        <Marker
                          title={t('current.location')}
                          position={userLocation}
                        />
                      )}
                      <Marker
                        position={{
                          lat: mapLocation.lat,
                          lng: mapLocation.lon,
                        }}
                      />
                    </>
                  </GoogleMap>
                </Box>
              </VStack>
              <MapGuide />

              <HStack style={{ justifyContent: 'center' }}>
                <ActionButton
                  tx={'center'}
                  iconType={'myLocation'}
                  onClick={getLocation}
                />
                <ActionButton
                  disabled={disabled}
                  primary
                  onClick={handleUpdateLocation}
                  tx="location.set"
                />
              </HStack>
            </VStack>
          </div>
        </>
      )}
    </SectionGrid>
  );
};

const mapContainer: React.CSSProperties = {
  maxWidth: '65rem',
  width: '80vw',
  height: '60vh',
  borderRadius: MAP_BORDER_RADIUS,
};

export default DefaultLocation;
