import styled, { useTheme } from 'styled-components';
import { ThemeColorsType } from '@aclito/shared/theme/colors';
import React from 'react';
import { TranslateKey, ValueOf } from '@aclito/shared/types';

import { HStack, Text } from '@/components';

interface BannerProps {
  icon?: JSX.Element;
  color: ValueOf<ThemeColorsType>;
  tx: TranslateKey;
}

const Banner: React.FC<BannerProps> = ({ icon, color, tx }) => {
  const theme = useTheme();
  return (
    <Container
      spacing={2}
      $color={color}
      $icon={!!icon}
      style={{ flexWrap: 'nowrap' }}
    >
      {icon}
      <StyledText size={12} weight={'bold'} color={theme.whiteText} tx={tx} />
    </Container>
  );
};

const StyledText = styled(Text)`
  white-space: nowrap;
`;

const Container = styled(HStack)<{ $color: string; $icon: boolean }>`
  background: ${({ $color }) => $color};
  border-radius: 25px;
  width: fit-content;
  padding: ${({ $icon }) => ($icon ? '2px 4px 2px 2px' : '2px 4px')};
`;

export default Banner;
