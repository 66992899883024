import styled from 'styled-components';
import React, { PropsWithChildren } from 'react';

const Screen: React.FC<PropsWithChildren<unknown>> = ({ children }) => (
  <StyledScreen>{children}</StyledScreen>
);

const StyledScreen = styled.div`
  width: 100%;
  max-width: 400px;
  width: 400px;
  padding: 48px 16px 64px;
  margin-bottom: auto;
`;

export { Screen };
