import { getParticipantsCount } from '@aclito/shared/features/event/utils/getParticipantsCount';
import styled, { useTheme } from 'styled-components';
import { Organization, Event } from '@aclito/entities';
import moment from 'moment';

import { HStack, Icon, Text } from '@/components';

type PlayersMembersNumberProps = {
  event?: Event;
  organization?: Organization;
  textSize?: number;
  iconSize?: number;
  withIcon?: boolean;
};

const PlayersMembersNumber = ({
  event,
  organization,
  textSize = 16,
  iconSize = 24,
  withIcon = false,
}: PlayersMembersNumberProps) => {
  const theme = useTheme();

  let max = -1;
  let count = 0;
  let isInPast = false;

  if (event) {
    max = event.maxPlayers;
    isInPast = event.date < moment().toISOString();
    count = getParticipantsCount(event);
  } else if (organization) {
    max = organization.maxMembers;
    count = organization.members.length;
  }

  if (isInPast) return null;

  return (
    <HStack spacing={4} noWrap>
      {max === -1 ? (
        <HStack noWrap spacing={0} style={{ justifyContent: 'center' }}>
          <Text
            size={textSize}
            weight="500"
            style={{ color: theme.grey80 }}
          >{`${count}/`}</Text>
          <Icon icon="infinite" size={16} color="grey80" />
        </HStack>
      ) : (
        <Text
          size={textSize}
          weight="500"
          style={{ color: theme.grey80 }}
        >{`${count}/${max}`}</Text>
      )}
      {withIcon && <StyledIcon icon="org" size={iconSize} color="grey80" />}
    </HStack>
  );
};

const StyledIcon = styled(Icon)`
  padding-left: 4px;
`;

export default PlayersMembersNumber;
