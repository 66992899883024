import { MemberType } from '@aclito/shared/types';
import { Organization } from '@aclito/entities';
import { useOrganizationMembers } from '@aclito/shared/features/organization/hooks/useOrganizationMembers';

import { useModal } from '@/hooks/useModal';

export const useOrgMembers = (org: Organization, myRole: MemberType) => {
  const { openConfirmModal, openModal } = useModal();
  const { removeMember } = useOrganizationMembers(org);

  const onRemoveMember = (id: string) => {
    openConfirmModal({
      size: '35%',
      txTitle: 'kick',
      txLabels: { cancel: 'close', confirm: 'kick' },
      type: 'removeMember',
      onConfirm: async () => await removeMember(id),
    });
  };
  const onEditMember = (type: MemberType, id: string) => {
    openModal({
      size: '35%',
      txTitle: 'org.changerole',
      type: 'editMember',
      values: { id, myRole, type, org },
    });
  };
  return {
    onRemoveMember,
    onEditMember,
  };
};
