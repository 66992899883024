import { FC } from 'react';
import { commonSelectors } from '@aclito/shared/redux/slices/commonSlices';
import { useAppSelector, useSelectObject } from '@aclito/shared/hooks';
import { cardColorPicker } from '@aclito/shared/util/cardColorPicker';
import moment from 'moment';
import styled, { useTheme } from 'styled-components';
import { config } from '@aclito/shared/config';
import { formatEventDuration } from '@aclito/shared/features/event/utils/formatEventDuration';

import { EventViewProps } from '../interface';
import { Content, Name } from '../styles';

import { HStack, SmallButton, IconSelect, Text, Icon } from '@/components';
import { useNavigate } from '@/hooks/useNavigate';
import { ROUTE_EVENT_INFO, ROUTE_EVENT_INFO_PROVIDERS } from '@/util/constants';

const EventView: FC<EventViewProps> = (event) => {
  const { getById } = useSelectObject();
  const duration = formatEventDuration(event);
  const theme = useTheme();
  const locale = useAppSelector(commonSelectors.locale);
  moment.locale(locale);
  const date =
    event.formattedDate === event.formattedEndDate
      ? event.formattedDate
      : `${event.formattedDate} - ${event.formattedEndDate}`;

  const nav = useNavigate();
  const handleNavigation = () =>
    nav(event.isAclitoEvent ? ROUTE_EVENT_INFO : ROUTE_EVENT_INFO_PROVIDERS, {
      state: { id: event.id },
    });

  return (
    <Content spacing={10} align={'center'}>
      <Name>{event.name}</Name>
      <HStack spacing={8}>
        <IconSelect iconSize={20} type={event.activityType} />
        <ActivityText color={cardColorPicker(event.activityType)}>
          {getById(event.activityType, config.activities).item}
        </ActivityText>
      </HStack>
      <HStack spacing={10}>
        <Icon icon="calendar" size={20} color="grey80" />
        <StyledText>{date}</StyledText>
      </HStack>
      <HStack spacing={10}>
        <Icon icon="time" size={20} color="grey80" />
        <StyledText>
          {`${event.formattedTime} - ${event.formattedEndTime}`}{' '}
        </StyledText>
        <Text style={{ fontWeight: 'bold' }}>({duration})</Text>
      </HStack>
      <HStack spacing={10}>
        <Icon icon="place" size={20} color="grey80" />
        <StyledText>{event.place?.name}</StyledText>
      </HStack>
      <SmallButton
        onClick={handleNavigation}
        style={{ width: 200, marginTop: 20 }}
        bg="primary10"
      >
        <HStack align={'center'}>
          <Text tx="share.open" color={theme.primary} />
          <Icon icon="forward" size={20} color="primary" />
        </HStack>
      </SmallButton>
    </Content>
  );
};

const ActivityText = styled(Text)`
  margin-bottom: 7px;
` as typeof Text;

const StyledText = styled(Text)`
  color: ${({ theme }) => theme.text};
`;

export default EventView;
