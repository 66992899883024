import {
  useAppSelector,
  useSchema,
  useSelectObject,
  useTranslate,
} from '@aclito/shared/hooks';
import { placeSelectors } from '@aclito/shared/redux/slices/placeSlices';
import { useGetActivityAndGenders } from '@aclito/shared/features/event/hooks/useGetActivityAndGender';
import { useNavigate } from 'react-router-dom';
import { EventWithPlayers, EventForm } from '@aclito/shared/types';
import { closeAllModals } from '@mantine/modals';
import { composeInitialEditValues } from '@aclito/shared/features/event/utils/composeInitialEditValues';
import { useEventInfoUpdate } from '@aclito/shared/features/event/hooks/useEventInfoUpdate';
import { selectMyOrganizations } from '@aclito/shared/redux/api/organizationApi';
import { EventModel } from '@aclito/shared/classes/EventModel';

import { useModal } from '@/hooks/useModal';
import { ROUTE_EVENTS } from '@/util/constants';

export const useEventEdit = (model: EventModel) => {
  const { userInfo, event } = model;

  const t = useTranslate();
  const nav = useNavigate();
  const { editEventValidationSchema } = useSchema();
  const { getById, translatedLevels } = useSelectObject();
  const { allActivities, possibleGenders } = useGetActivityAndGenders(userInfo);
  const { openModal } = useModal();

  const myOrgs = useAppSelector(selectMyOrganizations);
  const isLoadingPlaces = useAppSelector(placeSelectors.placesLoading);

  const levelOptions = translatedLevels.map((level) => ({
    ...level,
    item: level.item,
  }));

  const orgOptions = [
    ...myOrgs
      .filter((o) =>
        [...new Set([...o.admins, ...o.powerUsers])].some(
          (member) => member === userInfo.id,
        ),
      )
      .map((org) => ({ ...org, item: org.name })),
    getById('none', { none: { id: 'none', item: 'json.none' } }),
  ];

  const isJoined = event?.players.some((player) => player.id === userInfo?.id);

  const initialValues = event
    ? composeInitialEditValues(t, userInfo, event, model.event.playersInfo)
    : undefined;
  const { updateEvent, updateRepeatingEvents } = useEventInfoUpdate();

  const handleUpdateEvents = (data: EventForm) => {
    if (event.repetitionId) {
      _updateDialog(data, event);
    } else {
      updateEvent(data, event);
      nav(ROUTE_EVENTS);
    }
  };

  const _updateDialog = (data: EventForm, e: EventWithPlayers) => {
    const onUpdate = () => {
      updateEvent(data, e);
      closeAllModals();
      nav(ROUTE_EVENTS);
    };

    const onUpdateRepeating = () => {
      updateRepeatingEvents(data, event);
      closeAllModals();
      nav(ROUTE_EVENTS);
    };

    openModal({
      txTitle: 'update.repeating',
      type: 'updateEvent',
      values: { onUpdate, onUpdateRepeating },
    });
  };

  return {
    isJoined,
    initialValues,
    levelOptions,
    allActivities,
    possibleGenders,
    isLoadingPlaces,
    editEventValidationSchema,
    handleUpdateEvents,
    orgOptions,
  };
};
