import type { UserAlias } from '@aclito/entities';
import { EventModel } from '@aclito/shared/classes/EventModel';
import { useEventInfoAliasesConfirmation } from '@aclito/shared/features/event/hooks/useEventInfoAliasesConfirmation';
import React from 'react';

import AliasModalConfirmItem from './AliasModalConfirmItem';

import PlayerSelectModal from '@/common/Modals/PlayerSelectModal';

interface AliasModalProps {
  model: EventModel;
  opened: boolean;
  onClose: () => void;
  handleConfirmAliases: (selectedIds: string[]) => Promise<void>;
}

const AliasModalConfirm: React.FC<AliasModalProps> = ({
  model,
  opened,
  onClose,
  handleConfirmAliases,
}) => {
  const {
    selectedIds,
    isSelected,
    handleOnAliasPress,
    joinedAliases,
    isAliasConfirmed,
  } = useEventInfoAliasesConfirmation(model);

  const handleConfirmButtonClick = async () => {
    await handleConfirmAliases(selectedIds);
    onClose();
  };

  const renderItem = (alias: UserAlias) => (
    <AliasModalConfirmItem
      {...{
        event: model.event,
        alias,
        isSelected,
        handleOnAliasPress,
        isAliasConfirmed,
      }}
    />
  );

  return (
    <PlayerSelectModal
      data={joinedAliases}
      renderItem={renderItem}
      topText={'events.info.alias.confirm.title'}
      opened={opened}
      onClose={onClose}
      buttonProps={{
        color: 'yellow',
        disabled: !selectedIds.length,
        onClick: handleConfirmButtonClick,
        tx: 'events.info.alias.confirm.button',
      }}
    />
  );
};

export default AliasModalConfirm;
