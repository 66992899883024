import React from 'react';
import { Menu } from '@mantine/core';
import { useTheme } from 'styled-components';

import { ButtonsProps } from '../interface';

import { Icon, ActionButton, HStack, Text } from '@/components';

const Buttons: React.FC<ButtonsProps> = ({
  handleEdit,
  handleDelete,
  compact,
}) => {
  const theme = useTheme();
  return (
    <>
      {compact ? (
        <Menu>
          <Menu.Target>
            <ActionButton iconType={'more'} />
          </Menu.Target>
          <Menu.Dropdown
            style={{
              borderRadius: '10px',
              backgroundColor: theme.white,
              color: theme.grey80,
              border: 'none',
            }}
          >
            <Menu.Item
              icon={<Icon icon="edit" size={20} />}
              onClick={handleEdit}
            >
              <Text tx="tooltip.edit" />
            </Menu.Item>
            <Menu.Item
              icon={<Icon icon="delete" size={20} />}
              onClick={handleDelete}
            >
              <Text tx="tooltip.delete" />
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      ) : (
        <HStack>
          <ActionButton
            onClick={handleEdit}
            iconType={'edit'}
            tx={'tooltip.edit'}
          />
          <ActionButton
            bg={'red10'}
            onClick={handleDelete}
            textcolor={'red'}
            iconType={'delete'}
            tx={'tooltip.delete'}
          />
        </HStack>
      )}
    </>
  );
};

export default Buttons;
