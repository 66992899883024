import type { UserAlias } from '@aclito/entities';
import { UserAliasForm } from '@aclito/shared/features/profile/hooks/useProfileAliasesEdit';
import { Divider } from '@mantine/core';
import React from 'react';
import { FormProvider, UseFormReturn } from 'react-hook-form';
import styled from 'styled-components';

import {
  HStack,
  SubmitButton,
  VStack,
  ValidatedInput,
  ValidatedDropzone,
} from '@/components';

interface AliasFormProps {
  methods: UseFormReturn<UserAliasForm>;
  handleSubmit: (aliasData: UserAlias) => Promise<void>;
  placeholderName: string;
  placeholderSurname: string;
}

const AliasForm: React.FC<AliasFormProps> = ({
  methods,
  handleSubmit,
  placeholderName,
  placeholderSurname,
}) => {
  return (
    <HStack>
      <FormProvider {...methods}>
        <Content>
          <ValidatedInput
            placeholder={placeholderName}
            txLabel="auth.userinfo.name"
            name="name"
          />
          <ValidatedInput
            placeholder={placeholderSurname}
            txLabel="auth.userinfo.surname"
            name="surname"
          />

          <HStack m={'auto'} style={{ width: '100%' }}>
            <ValidatedDropzone name="image" />
          </HStack>
          <Divider m={'sm'} />
          <div style={{ alignSelf: 'center' }}>
            <SubmitButton bg="primary" tx="continue" onSubmit={handleSubmit} />
          </div>
        </Content>
      </FormProvider>
    </HStack>
  );
};

const Content = styled(VStack)`
  width: 800px;
`;

export default AliasForm;
