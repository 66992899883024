import {
  GoogleMap,
  GoogleMarkerClusterer,
  Marker,
  useJsApiLoader,
} from '@react-google-maps/api';
import React, { MutableRefObject, useRef } from 'react';
import { FormattedEvent } from '@aclito/shared/types';
import { useAppSelector } from '@aclito/shared/hooks';
import { locationSelectors } from '@aclito/shared/redux/slices/locationSlice';
import { Event } from '@aclito/entities';
import { useLoadMyEventsQuery } from '@aclito/shared/redux/api/eventApi';

import { MAP_API_KEY, MAP_BORDER_RADIUS } from '@/util/constants';
import { handleClusterClick } from '@/util/clusterClick';

interface SimpleMapProps {
  data: FormattedEvent[];
  size?: { width: string | number; height: string | number };
  onMarkerClick?: (id: string) => void;
  onMapClusterClick?: (events: Event[]) => void;
}

const SimpleMap: React.FC<SimpleMapProps> = ({
  data,
  size = { width: 800, height: 600 },
  onMapClusterClick,
  onMarkerClick,
}) => {
  const { isLoaded: isMapLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: MAP_API_KEY,
  });
  const userLocation = useAppSelector(locationSelectors.userLocation);
  const mapRef = useRef<GoogleMap>(null) as MutableRefObject<GoogleMap>;
  const myEvents = useLoadMyEventsQuery({
    nextToken: null,
  });
  const events = myEvents.data?.result ?? [];
  const handleMarkerClick = (event: Event) => {
    if (onMarkerClick) {
      onMarkerClick?.(event.id);
      return;
    }

    if (onMapClusterClick) {
      if (event.place) {
        const e = handleClusterClick(
          {
            lat: event.place.location.lat.toFixed(4),
            lon: event.place.location.lon.toFixed(4),
          },
          events,
          mapRef,
        );
        onMapClusterClick?.(e);
      }
    }
  };

  return (
    <>
      {isMapLoaded && (
        <GoogleMap
          ref={mapRef}
          options={{
            streetViewControl: false,
            fullscreenControl: false,
            mapTypeControl: false,
          }}
          mapContainerStyle={{
            ...size,
            borderRadius: MAP_BORDER_RADIUS,
          }}
          center={userLocation}
          zoom={7}
        >
          <GoogleMarkerClusterer options={{}}>
            {(clusterer) => (
              <React.Fragment>
                {data.map((event) => (
                  <>
                    {event.place && (
                      <Marker
                        key={event.id}
                        clickable
                        title={event.name}
                        onClick={() => handleMarkerClick(event)}
                        clusterer={clusterer}
                        position={{
                          lat: event.place.location.lat,
                          lng: event.place.location.lon,
                        }}
                      />
                    )}
                  </>
                ))}
              </React.Fragment>
            )}
          </GoogleMarkerClusterer>
        </GoogleMap>
      )}
    </>
  );
};

export default SimpleMap;
