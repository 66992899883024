import { EventRaw } from '@aclito/entities';

import { EventTag, EventTags } from '../types/types';

export const isForKids = (event: EventRaw) => event.tags.kids;
export const isForAdults = (event: EventRaw) => event.tags.adults;

export const getCurrentTag = (tags: EventTags) => {
  if (tags.adults) return EventTag.ADULTS;
  if (tags.kids) return EventTag.KIDS;
  if (tags.all) return EventTag.ALL;

  return EventTag.ALL;
};
