import { config } from '../config';

export const getRandomUserName = (): string => {
  return config.names[Math.floor(Math.random() * config.names.length)];
};

export const getRandomUserSurname = () => {
  const keys = Object.keys(config.activities);
  const randomKey = keys[Math.floor(Math.random() * keys.length)];
  return config.activities[randomKey as keyof typeof config.activities].id;
};

export const getRandomAvatarSeed = (): string =>
  (Math.random() + 1).toString(36).substring(5);
