import { ThemeColorsKeys } from '@aclito/shared/theme/colors';
import { SnackBarIconType } from '@aclito/shared/types';
import React, { cloneElement, HTMLAttributes } from 'react';
import { IconBaseProps } from 'react-icons';
import { AiOutlineLock, AiOutlineTrophy } from 'react-icons/ai';
import { RiErrorWarningLine } from 'react-icons/ri';
import {
  BsFillCaretDownFill,
  BsFillCaretUpFill,
  BsFillCreditCardFill,
  BsFillShiftFill,
  BsGenderAmbiguous,
  BsTelephone,
} from 'react-icons/bs';
import { FaCheck } from 'react-icons/fa6';
import { FcGoogle } from 'react-icons/fc';
import {
  IoMdLink,
  IoMdPhotos,
  IoMdPodium,
  IoMdTime,
  IoMdPower,
} from 'react-icons/io';
import {
  IoLogoInstagram,
  IoLogoFacebook,
  IoLogoYoutube,
  IoChevronDown,
  IoChevronUp,
  IoChevronDownOutline,
  IoChevronUpOutline,
  IoExit,
  IoHandRight,
  IoInfinite,
  IoLocation,
  IoLogoApple,
  IoMenu,
  IoPersonAdd,
  IoPersonCircle,
  IoPlayCircle,
  IoPricetag,
  IoRefresh,
  IoSendSharp,
  IoTrashBin,
  IoFilter,
  IoChevronBackOutline,
  IoChevronForwardOutline,
  IoLogoLinkedin,
  IoChevronForward,
} from 'react-icons/io5';
import {
  MdOutlineEmojiEmotions,
  MdAdd,
  MdOutlineCalendarMonth,
  MdArrowBack,
  MdArrowForward,
  MdCheck,
  MdChevronLeft,
  MdClose,
  MdContentCopy,
  MdDangerous,
  MdDownload,
  MdEmail,
  MdLanguage,
  MdOutlineLocationOn,
  MdMessage,
  MdMoreHoriz,
  MdNotes,
  MdNotificationsNone,
  MdOutlineMyLocation,
  MdOutlineGroup,
  MdOutlineAccountCircle,
  MdPersonAdd,
  MdPersonSearch,
  MdRemoveCircleOutline,
  MdRepeat,
  MdReport,
  MdSave,
  MdSearch,
  MdOutlineSettings,
  MdOutlineTextsms,
  MdTimer,
  MdWarning,
  MdHelpOutline,
  MdPostAdd,
  MdEdit,
  MdOutlineLightMode,
  MdOutlineDarkMode,
  MdOutlineCancel,
  MdExpandMore,
  MdOutlineEvent,
  MdOutlineAssignment,
  MdOutlineShare,
  MdOutlinePersonRemove,
  MdOutlineCheckCircleOutline,
  MdPrint,
  MdHeartBroken,
  MdOutlineHeartBroken,
  MdOutlineThumbUpOffAlt,
} from 'react-icons/md';
import { ImEmbed } from 'react-icons/im';
import { Loader } from '@mantine/core';
import { useTheme } from 'styled-components';
import {
  BiCalendarCheck,
  BiCommentAdd,
  BiSolidCommentCheck,
} from 'react-icons/bi';

export type IconTypes = keyof typeof iconRegistry | keyof SnackBarIconType;

export interface IconProps extends HTMLAttributes<HTMLDivElement> {
  icon: IconTypes;
  containerStyle?: React.CSSProperties;
  color?: ThemeColorsKeys;
  size?: '8' | '16' | '20' | '24' | '32' | '48' | number;
  originalColor?: string;
  iconProps?: IconBaseProps;
}

export const Icon: React.FC<IconProps> = ({
  icon,
  color,
  size,
  containerStyle,
  originalColor,
  iconProps,
  ...wrapperProps
}) => {
  const theme = useTheme();
  const overrideColor = color ? theme[color] : theme.grey80;

  const overrideSize = size
    ? typeof size === 'string'
      ? parseInt(size, 10)
      : size
    : 24;

  const i = iconRegistry[icon as IconType] as React.ReactElement;

  const el = cloneElement(i ?? <div />, {
    color: originalColor || overrideColor,
    size: overrideSize,
    ...iconProps,
    style: {
      margin: 'auto',
      display: 'block',
    },
  });

  return (
    <div style={{ ...containerStyle }} {...wrapperProps}>
      {el}
    </div>
  );
};

const snackBarIconRegistry: Record<SnackBarIconType, React.ReactElement> = {
  notificationError: <MdOutlineCancel />,
  notificationWarning: <RiErrorWarningLine />,
  notificationSuccess: <FaCheck />,
};

export const iconRegistry = {
  language: <MdLanguage />,
  telephone: <BsTelephone />,
  facebook: <IoLogoFacebook />,
  emoji: <MdOutlineEmojiEmotions />,
  instagram: <IoLogoInstagram />,
  linkedin: <IoLogoLinkedin />,
  youtube: <IoLogoYoutube />,
  lock: <AiOutlineLock />,
  apple: <IoLogoApple />,
  google: <FcGoogle />,
  time: <IoMdTime />,
  timer: <MdTimer />,
  calendar: <MdOutlineCalendarMonth />,
  availability: <BiCalendarCheck />,
  alert: <MdDangerous />,
  remove: <MdRemoveCircleOutline />,
  add: <MdAdd />,
  share: <MdOutlineShare />,
  infinite: <IoInfinite />,
  repeat: <MdRepeat />,
  bannerPerson: <IoPersonCircle />,
  bannerPlay: <IoPlayCircle />,
  place: <IoLocation />,
  myLocation: <MdOutlineMyLocation />,
  chevronUp: <IoChevronUp />,
  chevronRight: <IoChevronForward />,
  chevronDown: <IoChevronDown />,
  chevronUpOutline: <IoChevronUpOutline />,
  chevronDownOutline: <IoChevronDownOutline />,
  level: <IoMdPodium />,
  gender: <BsGenderAmbiguous />,
  price: <IoPricetag />,
  menu: <IoMenu />,
  edit: <MdEdit />,
  personRemove: <MdOutlinePersonRemove />,
  checkmarkCircle: <MdOutlineCheckCircleOutline />,
  checkmark: <MdCheck />,
  copy: <MdContentCopy />,
  questionMarkCircle: <MdHelpOutline />,
  map: <MdOutlineLocationOn />,
  caretDown: <BsFillCaretDownFill />,
  caretUp: <BsFillCaretUpFill />,
  backArrow: <MdArrowBack />,
  notifications: <MdNotificationsNone />,
  settings: <MdOutlineSettings />,
  logout: <IoMdPower />,
  person: <MdOutlineAccountCircle />,
  chat: <MdOutlineTextsms />,
  search: <MdSearch />,
  close: <MdClose />,
  forward: <MdArrowForward />,
  send: <IoSendSharp />,
  refresh: <IoRefresh />,
  delete: <IoTrashBin />,
  more: <MdMoreHoriz />,
  personSearch: <MdPersonSearch />,
  link: <IoMdLink />,
  feedback: <MdOutlineThumbUpOffAlt />,
  commentAdd: <BiCommentAdd />,
  commentCheck: <BiSolidCommentCheck />,
  karmaProvided: <MdHeartBroken />,
  karmaRemove: <MdOutlineHeartBroken />,
  org: <MdOutlineGroup />,
  contactRequest: <IoHandRight />,
  personAdd: <IoPersonAdd />,
  email: <MdEmail />,
  warning: <MdWarning />,
  leave: <IoExit />,
  multisport: <BsFillCreditCardFill />,
  message: <MdMessage />,
  picture: <IoMdPhotos />,
  queueShift: <BsFillShiftFill />,
  download: <MdDownload />,
  addPerson: <MdPersonAdd />,
  suggest: <MdPersonSearch />,
  clear: <MdClose />,
  filter: <IoFilter />,
  report: <MdReport />,
  leftArrow: <IoChevronBackOutline />,
  rightArrow: <IoChevronForwardOutline />,
  leftDrop: <MdChevronLeft />,
  embed: <ImEmbed />,
  loading: <Loader variant="dots" />,
  note: <MdNotes />,
  save: <MdSave />,
  dark: <MdOutlineDarkMode />,
  light: <MdOutlineLightMode />,
  feedAdd: <MdPostAdd />,
  feed: <MdOutlineAssignment />,
  expandMore: <MdExpandMore />,
  datePicker: <MdOutlineEvent />,
  upload: <MdDownload />,
  print: <MdPrint />,
  ...snackBarIconRegistry,
  trophy: <AiOutlineTrophy />,
};

export type IconType = keyof typeof iconRegistry;
