import { useTheme } from 'styled-components';
import { useAppSelector } from '@aclito/shared/hooks';
import { commonSelectors } from '@aclito/shared/redux/slices/commonSlices';
import { AchievementCardProps } from '@aclito/shared/types';

import { BasicCard } from '../components/CardStyles';
import { HStack, VStack } from '../components/Layout';
import { Text } from '../components/Text';

import AchievementIcon from './AchievementIcon';
import ProgressBar from './ProgressBar';

const AchievementCard = ({
  title,
  description,
  currentProgress,
  totalProgress,
  level,
  icons,
  unlocked,
  classic,
}: AchievementCardProps) => {
  const locale = useAppSelector(commonSelectors.locale);

  const theme = useTheme();
  const backgroundColor = classic
    ? theme.primary
    : theme[`achievement${level}`];
  return (
    <BasicCard
      style={{
        height: '100%',
        padding: '0',
        minHeight: '5rem',
      }}
    >
      <HStack noWrap={true} style={{ height: '100%' }}>
        <AchievementIcon
          classic={classic}
          unlocked={unlocked}
          icons={icons}
          backgroundColor={backgroundColor}
          level={level}
        />
        <VStack
          spacing={6}
          style={{
            width: '75%',
            height: '100%',
            padding: '8px 8px 8px 0px',
          }}
        >
          <Text size={16} weight={700}>
            {title[locale]}
          </Text>
          <HStack align="flex-start" style={{ height: '100%' }}>
            <Text size={12} weight={350} lineClamp={4}>
              {description[locale]}
            </Text>
          </HStack>
          {!classic && (
            <HStack noWrap={true} align="flex-end" style={{ height: '100%' }}>
              <ProgressBar
                currentProgressValue={currentProgress}
                totalProgressValue={totalProgress}
              />
              <Text size={12} weight={'bold'} color={theme.primary}>
                {currentProgress}/{totalProgress}
              </Text>
            </HStack>
          )}
        </VStack>
      </HStack>
    </BasicCard>
  );
};

export default AchievementCard;
