import api from '../api/api';
import { ABody, As as AType } from '../api/requests/custom/status/getStatus';

type ImpressionsType = { current: string; body: ABody };
type ImpressionsCountType =
  | { impression: ImpressionsType; count: number }
  | undefined;
const MAX_IMPRESSIONS = 5;
class AManager {
  static instance: AManager | null = null;

  private config: AType | undefined;

  private static createInstance = () => new AManager();

  static start = (): AManager => {
    if (!AManager.instance) {
      AManager.instance = AManager.createInstance();
    }
    return AManager.instance;
  };

  private randomImpression = (): ImpressionsType | null => {
    if (!this.config) {
      return null;
    }
    const keys = Object.keys(this.config);
    const rand = (keys.length * Math.random()) << 0;
    const key = keys[rand];
    const value = this.config[keys[rand]];

    return { current: key, body: value };
  };

  private currentImpression: ImpressionsCountType;

  setCurrentImpression(value: ImpressionsCountType) {
    this.currentImpression = value;
  }

  public async setConfig() {
    this.config = (await api.custom.status.getStatus()).as;
  }

  getAd = (): ABody | null => {
    if (!this.currentImpression) {
      const impression = this.randomImpression();
      if (!impression) return null;

      const randomImpr = this.randomImpression();

      if (randomImpr) {
        this.setCurrentImpression({
          impression: randomImpr,
          count: 0,
        });
      }
    }
    if (
      this.currentImpression &&
      this.currentImpression.count < MAX_IMPRESSIONS
    ) {
      const updated: ImpressionsCountType = {
        impression: this.currentImpression.impression,
        count: this.currentImpression.count + 1,
      };
      this.setCurrentImpression(updated);
      return this.currentImpression.impression.body;
    }

    const randomImpr = this.randomImpression();

    if (randomImpr) {
      this.setCurrentImpression({
        impression: randomImpr,
        count: 0,
      });
    }
    return this.currentImpression?.impression?.body ?? null;
  };
}

export default AManager;
