import { useAppSelector, useTranslate } from '@aclito/shared/hooks';
import { profileSelectors } from '@aclito/shared/redux/slices/profileSlices';
import { useDisclosure } from '@mantine/hooks';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSchema } from '@aclito/shared/hooks/useSchema';
import { composeInitialEditValues } from '@aclito/shared/features/profile/utils/composeInitialEditValues';
import { sizeMax } from '@aclito/ui-web/utils/mediaQueries';

import {
  SectionGrid,
  ActionButton,
  BackButton,
  TitleBar,
  Title,
} from '@/components';
import DefaultLocation from '@/common/DefaultLocation/DefaultLocation';
import ProfileEditForm from '@/common/ProfileEditForm/ProfileEditForm';
import { useUserInformation } from '@/hooks/useUserInformation';
import { useNavigate } from '@/hooks/useNavigate';

const ProfileEdit: React.FC = () => {
  const userInfo = useAppSelector(profileSelectors.userInfo);
  const isLoading = useAppSelector(profileSelectors.isLoadingUpdateMyProfile);
  const { handleUpdateUser } = useUserInformation();
  const t = useTranslate();

  const initialValues = composeInitialEditValues(t, userInfo);
  const [isMap, { toggle }] = useDisclosure(false);

  const { userInfoValidationSchema } = useSchema();

  const methods = useForm({
    defaultValues: initialValues,
    //@ts-ignore
    resolver: yupResolver(userInfoValidationSchema),
    mode: 'onChange',
  });
  const nav = useNavigate();
  const navBack = () => {
    nav(-1);
  };
  return (
    <SectionGrid
      $isOpen={false}
      style={{ alignSelf: 'center', maxWidth: sizeMax.tablet }}
    >
      <TitleBar>
        <Title tx="tooltip.edit" />
        <ActionButton
          onClick={navBack}
          bg={'red10'}
          textcolor={'red'}
          iconType={'close'}
        />
      </TitleBar>
      <FormProvider {...methods}>
        {isMap ? (
          <>
            <BackButton onClick={toggle} />
            <DefaultLocation />
          </>
        ) : (
          <ProfileEditForm
            {...{
              isLoading,
              initialValues,
              submit: handleUpdateUser,
              showTac: false,
              toggle,
            }}
          />
        )}
      </FormProvider>
    </SectionGrid>
  );
};

export default ProfileEdit;
