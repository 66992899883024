import React, { useEffect } from 'react';
import { OrganizationTag } from '@aclito/shared/features/organization/types/types';
import { useFormContext } from 'react-hook-form';
import { OrganizationForm } from '@aclito/shared/types';
import { useTranslate } from '@aclito/shared/hooks';
import { generateTagMappings } from '@aclito/shared/features/organization/utils/mapOrgTags';
import { getCurentTag } from '@aclito/shared/features/organization/utils/getOrgTag';
import { orgTypeLabelText } from '@aclito/shared/features/organization/utils/orgTypeLabelText';
import { useTheme } from 'styled-components';
import { getCommonInputLabelStyle } from '@aclito/ui-web/utils/styles';

import {
  ValidatedInput,
  ValidatedCheckbox,
  Text,
  Tabs,
  HStack,
  VStack,
  ValidatedNumberInput,
  ValidatedTextArea,
  TabButton,
} from '@/components';

interface DescriptionProps {
  isCreating?: boolean;
}

const Description: React.FC<DescriptionProps> = ({ isCreating = false }) => {
  const { setValue, watch } = useFormContext<OrganizationForm>();
  const theme = useTheme();
  const tags = watch('tags');
  const t = useTranslate();

  const handleTabIndexChange = (val: OrganizationTag) =>
    setValue('tags', generateTagMappings(val));

  const watchMaxMembers = watch('maxMembers');
  const watchUnlimited = watch('unlimited');
  useEffect(() => {
    if (!watchMaxMembers) {
      setValue('unlimited', true);
    } else {
      setValue('unlimited', false);
    }
  }, [watchMaxMembers]);

  useEffect(() => {
    if (watchUnlimited) {
      setValue('maxMembers', 0);
    }
  }, [watchUnlimited]);

  return (
    <VStack spacing={20}>
      <ValidatedInput
        name="name"
        txLabel="org.name"
        txPlaceholder="org.name"
        required
      />

      <HStack
        style={{ display: 'flex', alignItems: 'flex-end', flexWrap: 'nowrap' }}
      >
        <ValidatedNumberInput
          name="maxMembers"
          txLabel="org.members"
          txPlaceholder="org.members"
          min={1}
        />
        <ValidatedCheckbox name="unlimited" txLabel="org.unlimited" />
      </HStack>
      {isCreating && <ValidatedCheckbox name="private" txLabel="org.private" />}
      <ValidatedCheckbox name="contact" txLabel="org.contact.text" />

      <ValidatedInput
        name="email"
        txLabel="org.email"
        txPlaceholder="org.email"
      />

      <ValidatedInput
        name="telephone"
        txLabel="org.telephone"
        txPlaceholder="org.telephone"
      />

      <ValidatedInput
        name="address"
        txLabel="org.address"
        txPlaceholder="org.address"
      />

      <ValidatedTextArea
        name="description"
        txLabel="org.description"
        txPlaceholder="org.description"
      />

      <VStack spacing={5}>
        <Text
          style={{ ...getCommonInputLabelStyle(theme) }}
          tx="org.new.tab.label"
        />
        <Text
          style={{ ...getCommonInputLabelStyle(theme), color: theme.grey30 }}
        >
          <Text span weight={700}>
            {orgTypeLabelText(tags, t)[0]}
          </Text>
          {orgTypeLabelText(tags, t)[1]}
        </Text>
        <Tabs
          unstyled
          bg={theme.primary10}
          p={'5px'}
          maw={'max-content'}
          style={{
            border: 1,
            borderRadius: 10,
          }}
          selectedIndex={getCurentTag(tags)}
          setSelectedIndex={(tag) =>
            handleTabIndexChange(tag as OrganizationTag)
          }
        >
          <TabButton value={OrganizationTag.TEAM} tx="org.new.tab.team" />
          <TabButton value={OrganizationTag.CLUB} tx="org.new.tab.club" />
          <TabButton
            value={OrganizationTag.ORGANIZATION}
            tx="org.new.tab.organization"
          />
        </Tabs>
      </VStack>
    </VStack>
  );
};

export default Description;
