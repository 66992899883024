import { useAppSelector } from '@aclito/shared/hooks';
import { profileActions } from '@aclito/shared/redux/slices';
import { profileSelectors } from '@aclito/shared/redux/slices/profileSlices';
import { debounce } from '@aclito/shared/util/debounce';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import { useMemo, useRef, useState } from 'react';
import { useAppDispatch } from '@aclito/shared/hooks/useDispatch';
import { locationSelectors } from '@aclito/shared/redux/slices/locationSlice';
import { LocationInput } from '@aclito/entities';

import { MAP_API_KEY } from '@/util/constants';
import { useModal } from '@/hooks/useModal';

export const useDefaultLocation = () => {
  const isLoading = useAppSelector(profileSelectors.isLoadingLocation);
  const userLocation = useAppSelector(locationSelectors.userLocation);
  const dispatch = useAppDispatch();

  const { openModal } = useModal();

  const [mapLocation, setMapLocation] = useState<LocationInput>(userLocation);
  const mapRef = useRef<GoogleMap>(null);

  const { isLoaded: isMapLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: MAP_API_KEY,
  });

  const confirmMapLocation = () => {
    if (mapLocation) {
      dispatch(profileActions.addLocationAsync({ location: mapLocation }));
    }
  };

  const handleUpdateLocation = () => {
    confirmMapLocation();
  };
  const handleCenterChanged = useMemo(
    () =>
      debounce((region: LocationInput) => {
        setMapLocation(region);
      }, 100),
    [],
  );
  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition, showError);
    } else {
      locationNotAllowed();
    }
  };

  const showPosition = (position: GeolocationPosition) => {
    mapRef.current?.state.map?.panTo({
      lat: position.coords.latitude,
      lng: position.coords.longitude,
    });
  };

  const locationNotAllowed = () => {
    openModal({ txTitle: 'location.notallowed' });
  };

  const showError = (error: GeolocationPositionError) => {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        locationNotAllowed();
        break;
      case error.POSITION_UNAVAILABLE:
        locationNotAllowed();
        break;
      case error.TIMEOUT:
        locationNotAllowed();
        break;

      default:
    }
  };

  return {
    mapRef,
    isMapLoaded,
    handleUpdateLocation,
    mapLocation,
    handleCenterChanged,
    isLoading,
    getLocation,
  };
};
