import { FC } from 'react';
import { useTheme } from 'styled-components';

import { OrgViewProps } from '../interface';
import { Content, Name } from '../styles';

import { OrgAvatar, Icon, HStack, SmallButton, Text } from '@/components';
import { useNavigate } from '@/hooks/useNavigate';
import { ROUTE_ORGANIZATIONS } from '@/util/constants';

const OrgView: FC<OrgViewProps> = (org) => {
  const theme = useTheme();
  const nav = useNavigate();
  const handleNavigation = () =>
    nav(ROUTE_ORGANIZATIONS, { state: { id: org.id } });

  return (
    <Content align={'center'}>
      <Name>{org.name}</Name>
      <OrgAvatar org={org} style={{ height: 50, width: 50 }} />
      <SmallButton
        onClick={handleNavigation}
        style={{ width: 250, marginTop: 20 }}
        bg="primary10"
      >
        <HStack align={'center'}>
          <Text tx="share.open.org" color={theme.primary} />
          <Icon icon="forward" size={20} color="primary" />
        </HStack>
      </SmallButton>
    </Content>
  );
};

export default OrgView;
