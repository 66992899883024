type ItemWithKey<T> = {
  [K in keyof T]: T[K] extends string ? K : never;
}[keyof T];

export function mergeAndFilter<T extends Record<string, any>>(
  a1: T[],
  a2: T[],
  key: ItemWithKey<T>,
): T[] {
  const a2Map = new Map(a2.map((item) => [item[key], item]));

  const merged = a1.reduce((acc, item) => {
    const matchedItem = a2Map.get(item[key]);
    if (matchedItem) {
      acc[item[key]] = Object.assign({}, item, matchedItem);
    }
    return acc;
  }, {} as Record<string, T>);

  // Add items from a2 that were not in a1
  a2.forEach((item) => {
    if (!merged[item[key]]) {
      merged[item[key]] = item;
    }
  });

  return Object.values(merged);
}
