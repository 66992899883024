import moment from 'moment';

type Platform = 'mobile' | 'web' | 'mobile-lite' | undefined;
type Level = 'info' | 'warning' | 'error' | 'debug';
type LogFn = (level: Level, data: object) => void;
interface AxiomData {
  data: object;
  level: Level;
  location: string;
}

interface AxiomLog extends AxiomData {
  createdAt: string;
  userInfoId: string;
  platform: Platform;
  deviceId: string;
}

const BUFFER_SIZE = 10;
const AXIOM_INGEST_URL = 'https://api.axiom.co/v1/datasets/aclito-logs/ingest';
const AXIOM_INGEST_DEV_URL =
  'https://api.axiom.co/v1/datasets/aclito-logs-dev/ingest';
export class AxiomLoggerClass {
  set isDev(value: boolean) {
    this._isDev = value;
  }
  set deviceId(value: string) {
    this._deviceId = value;
  }
  private token = '';
  private userInfoId = '';
  private _deviceId = '';
  private platform: Platform;
  static instance: AxiomLoggerClass | undefined = undefined;
  private buffer: AxiomData[] = [];
  private _isDev = false;

  private intervalId: string | number | NodeJS.Timeout | undefined;

  init(token: string, platform: Platform) {
    this.token = token;
    this.platform = platform;
    this.stopInterval();
    this.startInterval();
  }

  stopInterval() {
    clearInterval(this.intervalId);
  }

  startInterval() {
    this.intervalId = setInterval(this.sendRequest.bind(this), 15000);
  }
  injectUser(userInfoId: string) {
    if (!userInfoId && !userInfoId.length) {
      return;
    }
    this.userInfoId = userInfoId;
  }

  emptyBuffer() {
    this.sendRequest();
  }

  clearBuffer() {
    this.buffer = [];
  }

  getLogger(l: string) {
    const location = l;
    const log: Record<Level, (data: object) => void> = {
      info: (data: object) => constructFn('info', data),
      warning: (data: object) => constructFn('warning', data),
      error: (data: object) => constructFn('error', data),
      debug: (data: object) => constructFn('debug', data),
    };

    const constructFn: LogFn = (level: Level, data: object) => {
      const construct = this.constructData({ location, level, data });
      this.buffer.push(construct);
      if (!(this.buffer.length > BUFFER_SIZE)) {
        return;
      }
      this.sendRequest();
    };

    return log;
  }

  private async sendRequest() {
    const req = this.constructRequest();
    if (!req) {
      return;
    }
    try {
      await fetch(this._isDev ? AXIOM_INGEST_DEV_URL : AXIOM_INGEST_URL, req);
      this.clearBuffer();
    } catch (e) {
      console.warn('Failed to log');
    }
  }

  private constructRequest(): RequestInit | void {
    if (!this.token && !this.token.length) {
      console.warn('Token missing');
      return;
    }
    if (!this.buffer.length) {
      return;
    }
    return {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.token}`,
      },
      body: JSON.stringify(this.buffer),
      method: 'POST',
    };
  }
  private constructData(data: AxiomData): AxiomLog {
    const log: AxiomLog = {
      ...data,
      createdAt: moment().toISOString(),
      userInfoId: this.userInfoId,
      platform: this.platform,
      deviceId: this._deviceId,
    };
    return log;
  }

  private static createInstance = () => new AxiomLoggerClass();

  static getInstance = (): AxiomLoggerClass => {
    if (!AxiomLoggerClass.instance) {
      AxiomLoggerClass.instance = AxiomLoggerClass.createInstance();
    }

    return AxiomLoggerClass.instance;
  };
}

const AxiomLogger = AxiomLoggerClass.getInstance();
export { AxiomLogger };
