import { dateSort } from '@aclito/shared/util/sorter';
import { Center, Loader, Space } from '@mantine/core';
import React from 'react';
import { formatEvents } from '@aclito/shared/features/event/utils/formatEvents';
import { useAppSelector } from '@aclito/shared/hooks';
import { selectMyOrganizations } from '@aclito/shared/redux/api/organizationApi';
import { SelectObject } from '@aclito/shared/types';
import { FiltersInitialState } from '@aclito/shared/redux/initialStates';
import { EventFilters } from '@aclito/shared/redux/types';

import Filter from '../../components/Filter';
import { SearchTabType } from '../../types';

import {
  Overlay,
  LeftSectionLarge,
  LeftSectionSmall,
  LeftSectionTitle,
  SectionGrid,
  HStack,
  TitleBar,
  ShowMoreButton,
  ActionButton,
  Tabs,
  Tab,
  Title,
} from '@/components';
import EventsCards from '@/components/Cards/EventsCards';
import { TypedLocation } from '@/hooks/useLocation';
import withRouter from '@/navigation/withRouter';
import { QuerySearch } from '@/util/types';
import SearchBar from '@/features/search/components/Searchbar';
import SearchMap from '@/features/search/screens/Search/components/SearchMap';
import { useSearch } from '@/features/search/screens/Search/hooks/useSearch';
import { objectifyQuerySearch } from '@/features/search/utils/queryParams';

type SearchProps = TypedLocation<void, QuerySearch>;

const Search: React.FC<SearchProps> = ({ query }) => {
  const organizations = useAppSelector(selectMyOrganizations);

  const orgOptions: SelectObject[] =
    organizations.map((org) => ({
      id: org.id,
      item: org.name,
    })) ?? [];

  let filters: EventFilters;

  if (query && Object.keys(query).length) {
    filters = objectifyQuerySearch(query, orgOptions);
  } else {
    filters = FiltersInitialState;
  }

  const {
    isOpen,
    handler,
    filterProps,
    toggle,
    day,
    events,
    searchValue,
    nextToken,
    isLoading,
    handleSearchBar,
    handleShowMore,
    tabIndex,
    handleTabChange,
  } = useSearch(filters);

  return (
    <SectionGrid $isOpen={isOpen}>
      {isOpen && <Overlay onClick={handler.toggle} />}
      {isOpen && (
        <LeftSectionLarge>
          <LeftSectionTitle>
            <Title tx={'intro.title.filter'} />
            <ActionButton onClick={handler.toggle} iconType="leftDrop" />
          </LeftSectionTitle>
          <Filter {...{ ...filterProps, orgOptions, filters }} />
        </LeftSectionLarge>
      )}
      {isOpen && (
        <LeftSectionSmall>
          <LeftSectionTitle>
            <Title tx={'intro.title.filter'} />
            <ActionButton onClick={handler.toggle} iconType="leftDrop" />
          </LeftSectionTitle>
          <Filter {...{ ...filterProps, orgOptions, filters }} />
        </LeftSectionSmall>
      )}
      <div>
        <TitleBar>
          <HStack>
            <ActionButton
              onClick={handler.toggle}
              iconType={'filter'}
              tx="nav.filters"
            />
            <Title tx={'nav.search'} />
          </HStack>
        </TitleBar>
        <Tabs
          selectedIndex={tabIndex === SearchTabType.LIST ? '0' : '1'}
          setSelectedIndex={handleTabChange}
        >
          <Tab tx="tabs.list" />
          <Tab tx="tabs.map" />
        </Tabs>
        <Space h={16} />
        {tabIndex === SearchTabType.LIST && (
          <HStack>
            <SearchBar value={searchValue} onQueryChange={handleSearchBar} />
            <ActionButton
              tx={day ? 'json.monthly' : 'json.daily'}
              iconType={'chevronUp'}
              onClick={toggle}
            />
          </HStack>
        )}

        {isLoading && tabIndex !== SearchTabType.MAP ? (
          <Center style={{ width: '100%', height: '80%' }}>
            <Loader />
          </Center>
        ) : (
          <>
            {tabIndex === SearchTabType.MAP ? (
              <SearchMap filters={filters} events={events.filter(Boolean)} />
            ) : (
              <>
                <EventsCards
                  showAds
                  showDateType={day ? 'day' : 'month'}
                  fullWidth={false}
                  data={formatEvents(dateSort(events, 'date', 'asc'))}
                />
                <Space h={24} />
                <Center>
                  {nextToken && <ShowMoreButton onClick={handleShowMore} />}
                </Center>
              </>
            )}
          </>
        )}
      </div>
    </SectionGrid>
  );
};

export default withRouter(Search);
