import { EventRaw } from '@aclito/entities';

import { LeaveForm } from '../../../types';
import { EventModel } from '../../../classes/EventModel';
import { useLeaveEventMutation } from '../../../redux/api/eventApi';

export const useEventInfoLeave = () => {
  const [leaveEventTrigger] = useLeaveEventMutation();

  const handleAliasesLeave = async (
    selectedIds: string[],
    messageToLeader: string,
    model: EventModel,
  ) => {
    await leaveEventTrigger({
      id: model.event.id,
      aliasIds: selectedIds,
      message: messageToLeader,
    });
  };

  const handleAliasesLeaveRepeating = async (
    selectedIds: string[],
    messageToLeader: string,
    model: EventModel,
  ) => {
    return await leaveEventTrigger({
      id: model.event.id,
      aliasIds: selectedIds,
      repId: model.event.repetitionId ?? '',
      message: messageToLeader,
    }).unwrap();
  };

  const leaveSingleEvent = async (
    value: LeaveForm,
    model: EventModel,
    selectedIds?: string[],
  ): Promise<EventRaw | EventRaw[]> => {
    return await leaveEventTrigger({
      id: model.event.id,
      aliasIds: selectedIds,
      message: value.area,
    }).unwrap();
  };

  const leaveRepeatingEvents = async (
    value: LeaveForm,
    model: EventModel,
    selectedIds?: string[],
  ): Promise<EventRaw | EventRaw[]> => {
    if (selectedIds) {
      return await handleAliasesLeaveRepeating(selectedIds, value.area, model);
    } else {
      return await leaveEventTrigger({
        id: model.event.id,
        aliasIds: selectedIds,
        repId: model.event.repetitionId ?? '',
        message: value.area,
      }).unwrap();
    }
  };

  return {
    handleAliasesLeave,
    handleAliasesLeaveRepeating,
    leaveSingleEvent,
    leaveRepeatingEvents,
  };
};
