import { useEffect } from 'react';
import { EventModel } from '@aclito/shared/classes/EventModel';

import { useEventInfoConfirmation } from '@/features/event/screens/EventInfo/hooks/useEventInfoConfirmation';

export const useEventInfoInitial = (
  model: EventModel,
  onOpenLeaveModal: () => void,
) => {
  const {
    isOpenedConfirmModal,
    closeConfirmModal,
    confirmAliasesAttendance,
    onConfirmPress,
    onLeavePress,
    close,
    opened,
    open,
  } = useEventInfoConfirmation(model, onOpenLeaveModal);

  useEffect(() => {
    if (model.shouldConfirm) {
      open();
    }
  }, [model?.event?.id]);

  return {
    isOpenedConfirmModal,
    closeConfirmModal,
    confirmAliasesAttendance,
    onConfirmPress,
    onLeavePress,
    opened,
    close,
  };
};
