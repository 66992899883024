import { Organization } from '@aclito/entities';

import { OrganizationTag, OrganizationTags } from '../types/types';

export const isTeam = (org: Organization) => org.tags.team;
export const isClub = (org: Organization) => org.tags.club;
export const isOrganization = (org: Organization) => org.tags.organization;

export const getCurentTag = (tags: OrganizationTags) => {
  if (tags.team) return OrganizationTag.TEAM;
  if (tags.club) return OrganizationTag.CLUB;
  if (tags.organization) return OrganizationTag.ORGANIZATION;

  return OrganizationTag.TEAM;
};
