import React from 'react';
import { Textarea as MTTextarea } from '@mantine/core';
import { useTranslate } from '@aclito/shared/hooks';
import { useTheme } from 'styled-components';

import { getCommonInputLabelStyle } from '../../utils/styles';

import { TextareaProps } from './types';

const Textarea: React.FC<TextareaProps> = ({
  label,
  placeholder,
  txLabel,
  txPlaceholder,
  optional,
  ...props
}) => {
  const t = useTranslate();
  const theme = useTheme();
  const i18nLabel = txLabel && t(txLabel);
  const labelContent = optional
    ? `${i18nLabel || label} ${t('optional')}`
    : i18nLabel || label;

  const i18nPlaceholder = txPlaceholder && t(txPlaceholder);
  const placeholderContent = i18nPlaceholder || placeholder;

  return (
    <MTTextarea
      maxLength={2500}
      label={labelContent}
      placeholder={placeholderContent}
      styles={{
        input: { backgroundColor: theme.white, color: theme.grey80 },
        label: { ...getCommonInputLabelStyle(theme) },
      }}
      {...props}
    />
  );
};

export default Textarea;
