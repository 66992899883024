import styled from 'styled-components';

import { HStack } from '@/components';

export const InviteHStack = styled(HStack)`
  align-items: flex-start;
  & div,
  button {
    transition: transform 250ms ease-in-out;
    &:hover {
      transform: scale(1.1);
    }
    cursor: pointer;
  }
`;

export const CopyLinkButton = styled.div`
  width: 64px;
  height: 64px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.primary};
`;
