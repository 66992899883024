import { LocationInput, Event } from '@aclito/entities';
import { EventFilters } from '@aclito/shared/redux/types';
import { InputProps } from '@aclito/ui-web/components/Form/types';
import { SelectObject } from '@aclito/shared/types';

export type MarkedMapProps = {
  onCenterChange: (region: LocationInput & { radius: number }) => void;
  radius?: number;
  filters: EventFilters;
  panToCenterOnLoad: boolean;
  displayCircle: boolean;
  onMapClusterClick: (events: Event[]) => void;
  events: Event[];
};

export type FilterProps = {
  filters: EventFilters;
  orgOptions: SelectObject[];
  onFilterChange?: (filters: EventFilters) => void;
  onClearFilter?: () => void;
};

export interface SearchBarProps extends InputProps {
  value: string;
  debounce?: number;
  onQueryChange: (query: string) => void;
}

export enum SearchTabType {
  LIST = 'list',
  MAP = 'map',
}
