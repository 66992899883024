import React from 'react';
import { Stepper as MantineStepper } from '@mantine/core';
import { useTheme } from 'styled-components';

interface StepperProps {
  active: number;
  setActive: (value: React.SetStateAction<number>) => void;
  complete?: React.ReactNode;
  steps: {
    component: React.ReactNode;
    label: string;
    description?: string;
  }[];
}

const Stepper: React.FC<StepperProps> = ({
  active,
  setActive,
  steps,
  complete,
}) => {
  const theme = useTheme();
  return (
    <>
      <MantineStepper
        styles={{
          stepIcon: {
            color: theme.black,
            backgroundColor: theme.white,
          },
          separatorActive: {
            backgroundColor: theme.primary,
          },
          separator: {
            backgroundColor: theme.grey20,
          },
        }}
        breakpoint={'xs'}
        active={active}
        onStepClick={setActive}
      >
        {steps.map((step, index) => (
          <MantineStepper.Step
            key={index}
            label={step.label}
            style={{
              color: theme.grey80,
            }}
            description={step.description || ''}
          >
            {step.component}
          </MantineStepper.Step>
        ))}
        <MantineStepper.Completed>{complete}</MantineStepper.Completed>
      </MantineStepper>
    </>
  );
};

export default Stepper;
