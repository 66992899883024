import { Center, Divider, Loader, Space } from '@mantine/core';
import { useTheme } from 'styled-components';
import { FC } from 'react';

import {
  Collapsible,
  SectionGrid,
  BackButton,
  TitleBar,
  VStack,
  HStack,
  Text,
} from '@/components';
import { useNotesUser } from '@/features/notes/screens/NotesUser/hooks/useNotesUser';
import { TypedLocation } from '@/hooks/useLocation';
import { StateNotesUser } from '@/util/types';
import withRouter from '@/navigation/withRouter';
import { useNavigate } from '@/hooks/useNavigate';

type NotesUserProps = TypedLocation<StateNotesUser>;

const NotesUser: FC<NotesUserProps> = ({ state }) => {
  const { notes, isLoading } = useNotesUser(state?.id, state?.orgId);
  const nav = useNavigate();
  const theme = useTheme();

  if (isLoading) {
    return (
      <Center>
        <Loader />
      </Center>
    );
  }

  return (
    <SectionGrid $isOpen={false}>
      <TitleBar>
        <BackButton onClick={() => nav(-1)} />
      </TitleBar>
      <Collapsible buttonLabelTx="nav.notes" defaultOpen>
        <Space h={32} />
        <div>
          <HStack style={{ justifyContent: 'space-between' }}>
            <VStack style={{ alignSelf: 'flex-end' }}>
              <HStack spacing={8}>
                <Text
                  text={'Tel:'}
                  size={'xs'}
                  style={{ color: theme.grey50 }}
                />
              </HStack>
              <HStack spacing={8}>
                <Text
                  text={notes?.tel ?? ''}
                  style={{
                    color: theme.grey80,
                    fontSize: 14,
                  }}
                />
              </HStack>
            </VStack>
          </HStack>

          <Divider style={{ marginBottom: 8 }} />
          <HStack style={{ justifyContent: 'space-between' }}>
            <VStack style={{ alignSelf: 'flex-end' }}>
              <HStack spacing={8}>
                <Text
                  text={'E-mail:'}
                  size={'xs'}
                  style={{ color: theme.grey50 }}
                />
              </HStack>
              <HStack spacing={8}>
                <Text
                  text={notes?.email ?? ''}
                  style={{
                    color: theme.grey80,
                    fontSize: 14,
                  }}
                />
              </HStack>
            </VStack>
          </HStack>
        </div>
      </Collapsible>
    </SectionGrid>
  );
};

export default withRouter(NotesUser);
