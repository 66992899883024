import moment from 'moment';
import { EventRaw } from '@aclito/entities';

import { UserInfoWithAlias } from '../../../types';

export const showConfirmationIcon = (event: EventRaw) =>
  event.requireConfirmation && moment() >= moment(event.confirmationDate);

const _findPlayer = (user: UserInfoWithAlias, event: EventRaw) => {
  if (user.aliasId) {
    return event.players.find((player) => player.aliasId === user.aliasId);
  }

  return event.players.find(
    (player) => player.id === user.id && !player.aliasId,
  );
};

export const isPlayerConfirmed = (user: UserInfoWithAlias, event: EventRaw) =>
  _findPlayer(user, event)?.isConfirmed;

export const isConfirmed = (event: EventRaw) =>
  event.requireConfirmation
    ? moment() >= moment(event.confirmationDate)
    : !event.requireConfirmation;
