import { forwardRef } from 'react';
import { ThemeColorsKeys } from '@aclito/shared/theme/colors';
import styled, { css, useTheme } from 'styled-components';

import Button, { ButtonProps } from './Button';

export interface SmallButtonProps extends ButtonProps {
  bg: ThemeColorsKeys;
  shape?: 'round';
  minWidth?: number;
  textcolor?: ThemeColorsKeys;
}

// eslint-disable-next-line react/display-name
const SmallButton = forwardRef<HTMLButtonElement, SmallButtonProps>(
  ({ bg, ...rest }, ref) => {
    {
      const theme = useTheme();
      return (
        <StyledButton
          ref={ref}
          {...rest}
          // @ts-ignore
          bg={theme[bg]}
          size={'md'}
        />
      );
    }
  },
);

const StyledButton = styled(Button)<SmallButtonProps>`
  background-color: ${(props) => props.bg};
  border-radius: 10px;
  color: ${(props) => props.theme[props.textcolor ?? 'whiteText']};
  ${(p) =>
    p.minWidth &&
    css`
      min-width: ${p.minWidth}rem;
    `};

  ${(p) =>
    p.shape === 'round' &&
    css`
      padding: 0;
      width: 42px;
    `};

  &:hover {
    background-color: ${(props) => props.bg};
    filter: brightness(95%);
  }
`;

export { SmallButton };
