import { FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import {
  Achievement,
  AchievementOrgId,
  UserAchievement,
} from '@aclito/entities';
import { achievementClient } from '@aclito/client';

import { aclitoApi } from '../store';

export const achievementApi = aclitoApi.injectEndpoints({
  endpoints: (builder) => ({
    getAchievements: builder.query<Achievement[], AchievementOrgId>({
      queryFn: async (args) => {
        try {
          const { data } = await achievementClient.achievements.getAll(args);
          return { data: data };
        } catch (error) {
          return {
            error: {
              error: 'fail',
            } as FetchBaseQueryError,
          };
        }
      },
    }),
    getUserAchievementsByUserId: builder.query<
      UserAchievement[],
      { id: string; orgId?: string }
    >({
      queryFn: async ({ id, orgId }) => {
        try {
          const { data } = await achievementClient.user.getAllUserAchievements(
            id,
            orgId,
          );
          return { data: data };
        } catch (error) {
          return {
            error: {
              error: 'fail',
            } as FetchBaseQueryError,
          };
        }
      },
    }),
  }),
});

export const { useGetAchievementsQuery, useGetUserAchievementsByUserIdQuery } =
  achievementApi;
