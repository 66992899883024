import React from 'react';
import {
  isPlayerConfirmed,
  showConfirmationIcon,
} from '@aclito/shared/features/event/utils/confirmation';
import { EventModel } from '@aclito/shared/classes/EventModel';
import styled from 'styled-components';
import { UserInfoWithAlias } from '@aclito/shared/types';
import {
  isUserOrganizer,
  isUserTrainer,
} from '@aclito/shared/features/event/utils/getEventRole';
import { willUserWithAliasParticipate } from '@aclito/shared/features/event/utils/willPlayerParticipate';
import { isPlayerInQueue } from '@aclito/shared/features/event/utils/isPlayerInQueue';
import { canSeeNotes } from '@aclito/shared/features/event/utils/canSeeNotes';
import { getOrgUserRole } from '@aclito/shared/util/getOrgUserRole';

import {
  Icon,
  HStack,
  Text,
  VStack,
  Avatar,
  IconHolder,
  PlainCard,
} from '@/components';
import { useEventInfoKick } from '@/features/event/screens/EventInfo/hooks/useEventInfoKick';
import { useNavigate } from '@/hooks/useNavigate';
import {
  ROUTE_NOTES_USER,
  ROUTE_PROFILE,
  ROUTE_USER_PROFILE,
} from '@/util/constants';
import { useEventInfoConfirmation } from '@/features/event/screens/EventInfo/hooks/useEventInfoConfirmation';
import AliasModalConfirm from '@/features/event/screens/EventInfo/components/AliasModalConfirm';
import { useEventInfoLeave } from '@/features/event/screens/EventInfo/hooks/useEventInfoLeave';
import AliasModalLeave from '@/features/event/screens/EventInfo/components/AliasModalLeave';
import { CardStatusBannerText } from '@/common/CardStatusBanner';
import InitialConfirmModal from '@/features/event/screens/EventInfo/components/InitialConfirmModal';

interface PlayerCardsProps {
  model: EventModel;
  isQueue?: boolean;
}

const PlayerCards: React.FC<PlayerCardsProps> = ({ model, isQueue }) => {
  const { event } = model;
  const cardMapper = (player: UserInfoWithAlias, index: number) =>
    willUserWithAliasParticipate(player, event) && (
      <Card
        key={player.aliasId ?? player.id}
        {...{
          player,
          model,
        }}
        index={index}
      />
    );

  return (
    <PlayerStack>
      {!isQueue
        ? event.playersInfo.map(cardMapper)
        : event.queuePlayersInfo.map(cardMapper)}
    </PlayerStack>
  );
};

const PlayerStack = styled(VStack)`
  padding-top: 1rem;
`;

interface CardProps {
  player: UserInfoWithAlias;
  model: EventModel;
  index: number;
}

const Card: React.FC<CardProps> = ({ player, model, index }) => {
  const nav = useNavigate();
  const { handlePlayerKick, canBePlayerKicked } = useEventInfoKick();

  const {
    isOpenedLeaveModal,
    onCloseLeaveModal,
    handleAliasesLeave,
    onOpenLeaveModal,
  } = useEventInfoLeave(model);

  const {
    open,
    opened,
    close,
    onConfirmPress,
    onLeavePress,
    isOpenedConfirmModal,
    closeConfirmModal,
    confirmAliasesAttendance,
  } = useEventInfoConfirmation(model, onOpenLeaveModal);

  const { canKick, event, userInfo } = model;
  const kickPlayer = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    handlePlayerKick(event, player);
  };

  const handleQuestionMarkClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    eventPlayer: UserInfoWithAlias,
  ) => {
    if (userInfo.id === eventPlayer.id) {
      e.stopPropagation();
      open();
    }
  };

  const handleNotesClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    e.stopPropagation();
    const orgId = model.event.org?.id;
    if (orgId) {
      nav(ROUTE_NOTES_USER, {
        state: { id: player.id, orgId },
      });
    }
  };

  const onPlayerClick = () =>
    userInfo.id === player.id
      ? nav(ROUTE_PROFILE)
      : nav(ROUTE_USER_PROFILE, { state: { userId: player.id } });

  const isCurrentUserAdmin = event.org
    ? getOrgUserRole(event.org, userInfo.id) === 'admin'
    : false;

  return (
    <>
      {event && (
        <>
          <InitialConfirmModal
            {...{ opened, close, onConfirmPress, onLeavePress }}
          />
          <AliasModalConfirm
            model={model}
            opened={isOpenedConfirmModal}
            onClose={closeConfirmModal}
            handleConfirmAliases={confirmAliasesAttendance}
          />
          <AliasModalLeave
            model={model}
            opened={isOpenedLeaveModal}
            onClose={onCloseLeaveModal}
            handleLeaveAliases={handleAliasesLeave}
          />
        </>
      )}
      <PlainCard
        onClick={onPlayerClick}
        data-testid={`${player.name} ${player.surname}`}
      >
        <HStack>
          <Avatar size={50} image={player.image} />
          <VStack spacing={8}>
            <Text weight={500} text={`${player.name} ${player.surname}`} />
            {isUserOrganizer(player, event) && (
              <CardStatusBannerText type="eventOrganizer" />
            )}
            {isUserTrainer(player, event) && (
              <CardStatusBannerText type="eventTrainer" />
            )}
          </VStack>
          <IconHolder style={{ cursor: 'pointer' }}>
            {showConfirmationIcon(event) &&
              !isPlayerInQueue(event, player) &&
              (isPlayerConfirmed(player, event) ? (
                <Icon icon="checkmarkCircle" size={'24'} color="primary" />
              ) : (
                <Icon
                  icon="questionMarkCircle"
                  size={'24'}
                  color="yellow"
                  onClick={(e) => handleQuestionMarkClick(e, player)}
                />
              ))}

            {canSeeNotes(userInfo.id, player.id, event.org ?? undefined) && (
              <Icon
                icon="note"
                size="24"
                color={'yellow'}
                onClick={handleNotesClick}
                data-testid={`notes-user-${index}`}
              />
            )}
            {!model.isInPast &&
              canBePlayerKicked(
                event,
                canKick || isCurrentUserAdmin,
                player,
              ) && (
                <HStack onClick={kickPlayer} data-testid={`kick-user-${index}`}>
                  <Icon icon="personRemove" size={'24'} color={'red'} />
                </HStack>
              )}
          </IconHolder>
        </HStack>
      </PlainCard>
    </>
  );
};

export default PlayerCards;
