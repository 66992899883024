import { EmojiMap } from '@aclito/entities';
import { EmojisKey } from '@aclito/shared/types';
import { Fragment } from 'react';
import { config } from '@aclito/shared/config';

import { HStack, Text } from '@/components';

interface StatsProps {
  emojis: EmojiMap;
  slice?: number;
  compact?: boolean;
}

const Stats: React.FC<StatsProps> = ({ emojis, slice = 8, compact }) => {
  const sortedEmojis = Object.fromEntries(
    Object.entries(emojis).sort(([, valueA], [, valueB]) => valueB - valueA),
  );

  const emojisArray = Object.keys(sortedEmojis)
    .filter((e) => (emojis[e as EmojisKey] ?? 0) > 0)
    .slice(0, slice ?? Number.MAX_VALUE);

  return (
    <HStack spacing={32}>
      {emojisArray.map((stat) => (
        <Fragment key={stat}>
          <HStack spacing={2}>
            <Text size={compact ? 'xs' : 'md'} weight={'bold'}>
              {emojis[stat as EmojisKey]}
            </Text>
            <Text size={compact ? 'md' : 'xl'}>
              {config.emojis[stat as EmojisKey].item}
            </Text>
          </HStack>
        </Fragment>
      ))}
      {!emojisArray.length && <div style={{ padding: 12 }} />}
    </HStack>
  );
};

export default Stats;
