import moment from 'moment';
import { EventRaw } from '@aclito/entities';

import { EventWithDateObject } from '@aclito/shared/types';

export const formatEventDuration = <T extends EventRaw | EventWithDateObject>(
  event: T,
) => {
  const duration = getDuration(event.endDate, event.date);
  return getDurationString(duration);
};

export const getDuration = (endDate: string | Date, date: string | Date) => {
  return moment.duration(moment(endDate).diff(moment(date)));
};

export const getDurationString = (duration: moment.Duration) => {
  // Get Days
  const days = Math.floor(duration.asDays());
  const daysFormatted = days ? `${days}d ` : '';
  // Get Hours
  const hours = duration.hours();
  const hoursFormatted = hours > 0 ? `${hours}h ` : '';

  // Get Minutes
  const minutes = duration.minutes();
  const minutesFormatted = minutes > 0 ? `${minutes}m` : '';

  return [daysFormatted, hoursFormatted, minutesFormatted].join('').trim();
};
