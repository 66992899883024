import { useAppSelector } from '@aclito/shared/hooks';
import { profileSelectors } from '@aclito/shared/redux/slices/profileSlices';
import { EventModel } from '@aclito/shared/classes/EventModel';
import { useDisclosure } from '@mantine/hooks';
import { useConfirmEventMutation } from '@aclito/shared/redux/api/eventApi';

import { useEventInfoLeave } from '@/features/event/screens/EventInfo/hooks/useEventInfoLeave';

export const useEventInfoConfirmation = (
  model: EventModel,
  onOpenLeaveModal: () => void,
) => {
  const userInfo = useAppSelector(profileSelectors.userInfo);
  const [confirmEvent] = useConfirmEventMutation();

  const confirmAliasesAttendance = async (aliasIds: string[]) => {
    await confirmEvent({ id: model.event.id, aliasIds });
  };

  const [
    isOpenedConfirmModal,
    { open: openConfirmModal, close: closeConfirmModal },
  ] = useDisclosure(false);

  const [opened, { open, close }] = useDisclosure(false);

  const { handleLeave } = useEventInfoLeave(model);

  const onConfirmPress = () => {
    close();

    if (model.event) {
      if (userInfo.aliases.length) {
        openConfirmModal();
        return;
      }
      confirmEvent({ id: model.event.id });
    }
  };

  const onLeavePress = () => {
    close();

    if (model.event) {
      if (userInfo.aliases.length) {
        onOpenLeaveModal();
        return;
      }
      handleLeave();
    }
  };

  return {
    isOpenedConfirmModal,
    closeConfirmModal,
    confirmAliasesAttendance,
    onConfirmPress,
    onLeavePress,
    close,
    open,
    opened,
  };
};
