import { Modal } from '@mantine/core';
import { Title } from '@aclito/ui-web/components/Title';
import { config } from '@aclito/shared/config';
import { useAppSelector, useTranslate } from '@aclito/shared/hooks';
import styled, { useTheme } from 'styled-components';
import { VStack } from '@aclito/ui-web/components/Layout';
import { Text } from '@aclito/ui-web/components/Text';
import { commonSelectors } from '@aclito/shared/redux/slices/commonSlices';
import { useEffect, useState } from 'react';
import { TAC } from '@aclito/shared/api/requests/custom/tac/getTac';
import api from '@aclito/shared/api/api';
import { updateAttributes } from '@aclito/shared/util/helpersApi';
import { TERMS_AND_CONDITIONS_SKIP_DATE } from '@aclito/shared/util/constants';
import moment from 'moment/moment';
import { useNavigate } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { FormProvider, useForm } from 'react-hook-form';
import { StatusData } from '@aclito/shared/api/requests/custom/status/getStatus';

import { ActionButton, ValidatedCheckbox } from '@/components';
import { Storage } from '@/util/storage';
import { ROUTE_EVENTS, ROUTE_SIGN_IN } from '@/util/constants';

const TacUpdate = () => {
  const { signOut } = useAuthenticator((context) => [context.signOut]);
  const t = useTranslate();
  const theme = useTheme();
  const navigation = useNavigate();
  const [status, setStatus] = useState<StatusData | undefined>();
  const locale = useAppSelector(commonSelectors.locale);

  const [tac, setTac] = useState<TAC>({
    cs: [],
    en: [],
    sk: [],
  });

  useEffect(() => {
    if (!status) {
      api.custom.status.getStatus().then((data) => setStatus(data));
    }
  }, [status]);

  useEffect(() => {
    api.custom.tac.getTac().then((data) => setTac(data));
  }, []);

  const handleUpdate = async (s: StatusData) => {
    await updateAttributes({
      'custom:termsAndConditions': s.termsAndConditionsVersion.toString(),
    });

    navigation(ROUTE_EVENTS);
  };

  const handleSkip = async () => {
    await Storage.setItem(
      TERMS_AND_CONDITIONS_SKIP_DATE,
      moment().add(1, 'day').toISOString(),
    );

    navigation(ROUTE_EVENTS);
  };

  const methods = useForm({
    defaultValues: {
      pp: false,
      tac: false,
    },
  });

  const values = methods.watch();

  return (
    <Modal
      opened={true}
      onClose={() => {
        signOut();
        navigation(ROUTE_SIGN_IN);
      }}
    >
      <VStack>
        <Title tx={'auth.termsAndConditions.title'} />
        <VStack>
          <Text tx={'auth.termsAndConditionsUpdate'} />
          <VStack spacing={8}>
            <Link
              target="_blank"
              rel="noopener noreferrer"
              href={`${config.URL.aclitoTacURL}`}
            >
              {t('footer.terms')}
            </Link>
            <Link
              target="_blank"
              rel="noopener noreferrer"
              href={`${config.URL.aclitoPpURL}`}
            >
              {t('footer.privacy')}
            </Link>
          </VStack>
        </VStack>
      </VStack>
      <div
        style={{
          height: 300,
          backgroundColor: theme.background,
          borderRadius: 10,
          padding: 8,
          marginTop: 8,
          marginBottom: 8,
          overflowY: 'auto',
        }}
      >
        <VStack>
          {tac[locale].map((data, index) => (
            <div key={index}>
              <Text size={'xl'} style={{ paddingTop: 10 }} weight={'medium'}>
                {data.title}
              </Text>
              <Text size={'sm'}>{data.text}</Text>
            </div>
          ))}
        </VStack>
      </div>
      <VStack spacing={16}>
        <FormProvider {...methods}>
          <VStack style={{ alignItems: 'flex-start' }} spacing={8}>
            <ValidatedCheckbox name="pp" txLabel={'footer.terms'} />
            <ValidatedCheckbox name="tac" txLabel={'footer.privacy'} />
            {/*<ValidatedCheckbox name="gdpr" txLabel={'gdpr'} />*/}
          </VStack>
        </FormProvider>

        {status && (
          <VStack spacing={8}>
            {new Date(status.termsAndConditionsVersionEffectiveDate).getTime() >
              new Date().getTime() && (
              <ActionButton tx="skip" bg="primary10" onClick={handleSkip} />
            )}

            <ActionButton
              tx="termsAndConditions.agree"
              bg="primary"
              textcolor={'whiteText'}
              disabled={!values.pp || !values.tac}
              onClick={() => handleUpdate(status)}
            />
          </VStack>
        )}
      </VStack>
    </Modal>
  );
};

const Link = styled.a`
  color: ${({ theme }) => theme.primary};
`;

export default TacUpdate;
