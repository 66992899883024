import React, { useEffect, useState } from 'react';
import {
  calculateEventMonthSplit,
  calculateEventDaySplit,
} from '@aclito/shared/util/calculateDateSplit';
import moment from 'moment';
import styled, { useTheme } from 'styled-components';
import { FormattedEvent } from '@aclito/shared/types';
import { Grid } from '@mantine/core';

import EventCard from './EventCard';
import GridColumn from './GridColumn';

import { HStack, Text, Icon } from '@/components';

interface MyEventsCardProps {
  data: FormattedEvent[];
  showDateType?: ShowDateType;
  showAds?: boolean;
  fullWidth?: boolean;
  onClick?: (event: FormattedEvent) => void;
}
type ShowDateType = 'day' | 'month';
type FunctionSplitType = Record<
  ShowDateType,
  (index: number, events: FormattedEvent[]) => boolean
>;

const EventsCards: React.FC<MyEventsCardProps> = ({
  data,
  showDateType,
  fullWidth,
  onClick,
}) => {
  const [hiddenDates, setHiddenDates] = useState<{ id: number }[]>([]);
  const theme = useTheme();
  const handleHiddenDate = (id: number) => {
    const date = hiddenDates.findIndex((d) => d.id === id);
    if (date > -1) {
      setHiddenDates((prev) => prev.filter((d) => d.id !== id));
    } else {
      setHiddenDates((prev) => [...prev, { id }]);
    }
  };

  useEffect(() => {
    setHiddenDates([]);
  }, [showDateType]);

  const renderCards = () => (
    <Grid gutter="md">
      {data.map((event) => (
        <GridColumn key={event.id} fullWidth={!!fullWidth}>
          <EventCard event={event} onClick={onClick} />
        </GridColumn>
      ))}
    </Grid>
  );

  const renderCardsWithDate = () => {
    const dataToRender = calculateSplit(showDateType ?? 'day', data);
    return (
      <Box>
        {dataToRender.map((arr, index) => {
          const weekday = moment(arr[0].date).format('DD. dddd, MMMM');
          const month = moment(arr[0].date).format('MMMM');
          const isHidden = !hiddenDates.some((date) => date.id === index);
          return (
            <>
              <DateToggle
                spacing={8}
                key={weekday + month}
                onClick={() => handleHiddenDate(index)}
              >
                <DateText color={theme.grey30}>
                  {showDateType === 'day' ? weekday : month}
                </DateText>
                {isHidden ? (
                  <Icon icon="chevronDown" size={24} color="grey20" />
                ) : (
                  <Icon icon="chevronUp" size={24} color="grey20" />
                )}
              </DateToggle>
              {isHidden && (
                <Grid gutter="sm" key={index}>
                  {arr.map((e) => {
                    // const ad = AManager.start().getAd();
                    // const canShowAd =
                    //   showAds &&
                    //   (i % 10 === 0 || i === arr.length - 1) &&
                    //   i > 0 &&
                    //   showDateType === 'month' &&
                    //   ad;

                    return (
                      <>
                        <GridColumn key={e.id} fullWidth={!!fullWidth}>
                          <EventCard event={e} onClick={onClick} />
                        </GridColumn>
                        {/*{canShowAd && (*/}
                        {/*  <GridColumn key={e.id} fullWidth={!!fullWidth}>*/}
                        {/*    <ABanner ad={ad} />*/}
                        {/*  </GridColumn>*/}
                        {/*)}*/}
                      </>
                    );
                  })}
                </Grid>
              )}
            </>
          );
        })}
      </Box>
    );
  };

  return <>{showDateType ? renderCardsWithDate() : renderCards()}</>;
};
const calculateSplit = (
  type: ShowDateType,
  data: FormattedEvent[],
): FormattedEvent[][] => {
  const functionObject: FunctionSplitType = {
    day: calculateEventDaySplit,
    month: calculateEventMonthSplit,
  };
  const dataToReturn = [];
  let tempData = [];
  for (let index = 0; index < data.length; index++) {
    if (functionObject[type](index, data) && index > 0) {
      dataToReturn.push(tempData);
      tempData = [];
      tempData.push(data[index]);
    } else {
      tempData.push(data[index]);
    }
    if (data.length === index + 1) {
      dataToReturn.push(tempData);
    }
  }
  return dataToReturn;
};

const Box = styled.div`
  width: 100%;
`;
const DateToggle = styled(HStack)`
  cursor: pointer;
  align-items: center;
  padding-top: 16px;
  padding-bottom: 10px;
`;

const DateText = styled(Text)`
  font-size: 14px;
`;

export default EventsCards;
