import { CreateAvailabilityInput } from '@aclito/entities';

import { ActivitiesKey, AvailabilityForm } from '@aclito/shared/types';
import { useCreateAvailabilityMutation } from '@aclito/shared/redux/api/availabilityApi';

export const useAvailabilityCreate = () => {
  const [createAvailability] = useCreateAvailabilityMutation();

  const createAvailabilities = async (data: AvailabilityForm) => {
    const input: Omit<CreateAvailabilityInput, 'userID'> = {
      from: data.date!,
      duration: data.duration!,
      ...(data.numberOfAvailabilities && {
        numberOfAvailabilities: Number(data.numberOfAvailabilities),
      }),
      repeatAfter: data.repeatAfter?.id,
      location: data.location!,
      activities: data.activities?.length
        ? (data.activities.map((activity) => activity.id) as ActivitiesKey[])
        : [],
    };

    await createAvailability(input);
  };

  return {
    createAvailabilities,
  };
};
