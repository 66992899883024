import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';
import type { UserInfo } from '@aclito/entities';

import { REDUX_RESET } from '../../util/constants';
import { UserInfoInitialState } from '../initialStates';
import {
  deleteAccountAsync,
  populateIfFirstTimeAsync,
  updateMyProfileAsync,
  loadUserInfoAsync,
  addLocationAsync,
  updateUserSettingsAsync,
  createUserAliasAsync,
  updateUserAliasAsync,
  removeUserAliasAsync,
} from '../thunks/profileThunks';
import { AppState } from '../store';

export const REDUX_USER_INFO = 'userInfo';

const SliceUserInfo = createSlice({
  name: REDUX_USER_INFO,
  initialState: UserInfoInitialState,
  reducers: {
    updateUserInfo: (state, action: PayloadAction<UserInfo>) => ({
      ...state,
      current: action.payload,
    }),
    updateOrgId: (state, action: PayloadAction<string | undefined>) => ({
      ...state,
      orgId: action.payload,
    }),

    [REDUX_RESET + REDUX_USER_INFO]: () => UserInfoInitialState,
  },
  extraReducers: (builder) => {
    builder.addCase(populateIfFirstTimeAsync.pending, (state) => {
      state.isLoadingPopulate = true;
    });
    builder.addCase(populateIfFirstTimeAsync.fulfilled, (state) => {
      state.isLoadingPopulate = false;
    });
    builder.addCase(populateIfFirstTimeAsync.rejected, (state) => {
      state.isLoadingPopulate = false;
    });
    builder.addCase(updateUserSettingsAsync.pending, (state) => {
      state.isLoadingSettings = true;
    });
    builder.addCase(updateUserSettingsAsync.fulfilled, (state, action) => {
      state.isLoadingSettings = false;
      state.current = action.payload;
    });
    builder.addCase(updateUserSettingsAsync.rejected, (state) => {
      state.isLoadingSettings = false;
    });

    builder.addCase(deleteAccountAsync.pending, (state) => {
      state.isLoadingDeleteAccount = true;
    });
    builder.addCase(deleteAccountAsync.rejected, (state) => {
      state.isLoadingDeleteAccount = false;
    });

    builder.addCase(loadUserInfoAsync.fulfilled, (state, action) => {
      state.current = action.payload.user;
      state.currentStats = action.payload.stats;
    });

    builder.addCase(addLocationAsync.pending, (state) => {
      state.isLoadingLocation = true;
    });
    builder.addCase(addLocationAsync.fulfilled, (state, action) => {
      state.isLoadingLocation = false;
      state.current = action.payload;
    });
    builder.addCase(addLocationAsync.rejected, (state) => {
      state.isLoadingLocation = false;
    });
    builder.addMatcher(
      isAnyOf(
        updateMyProfileAsync.pending,
        updateUserAliasAsync.pending,
        createUserAliasAsync.pending,
        removeUserAliasAsync.pending,
      ),
      (state) => {
        state.isLoadingUpdateMyProfile = true;
      },
    );
    builder.addMatcher(
      isAnyOf(
        updateMyProfileAsync.rejected,
        updateUserAliasAsync.rejected,
        createUserAliasAsync.rejected,
        removeUserAliasAsync.rejected,
      ),
      (state) => {
        state.isLoadingUpdateMyProfile = false;
      },
    );
    builder.addMatcher(
      isAnyOf(
        updateMyProfileAsync.fulfilled,
        updateUserAliasAsync.fulfilled,
        createUserAliasAsync.fulfilled,
        removeUserAliasAsync.fulfilled,
      ),
      (state, action) => {
        state.isLoadingUpdateMyProfile = false;
        if (!Array.isArray(action.payload)) {
          state.current = action.payload;
        }
      },
    );
  },
});

export const profileActions = {
  ...SliceUserInfo.actions,
  deleteAccountAsync,
  populateIfFirstTimeAsync,
  updateMyProfileAsync,
  loadUserInfoAsync,
  addLocationAsync,
  updateUserSettingsAsync,
  createUserAliasAsync,
  updateUserAliasAsync,
  removeUserAliasAsync,
};
export const profileReducers = {
  [REDUX_USER_INFO]: SliceUserInfo.reducer,
};

export const profileSelectors = {
  userInfo: (state: AppState) => state.userInfo.current,
  orgId: (state: AppState) => state.userInfo.orgId,
  stats: (state: AppState) => state.userInfo.currentStats,
  isLoadingPopulate: (state: AppState) => state.userInfo.isLoadingPopulate,
  isLoadingUpdateMyProfile: (state: AppState) =>
    state.userInfo.isLoadingUpdateMyProfile,
  isLoadingDeleteAccount: (state: AppState) =>
    state.userInfo.isLoadingDeleteAccount,
  isLoadingUpdateUserStats: (state: AppState) =>
    state.userInfo.isLoadingUpdateUserStats,
  isLoadingLocation: (state: AppState) => state.userInfo.isLoadingLocation,
  isLoadingSettings: (state: AppState) => state.userInfo.isLoadingSettings,
};
