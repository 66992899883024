import {
  createListenerMiddleware,
  isAnyOf,
  isFulfilled,
  isRejected,
} from '@reduxjs/toolkit';

import { errorObject } from '../../api/utils/errorObject';
import { RejectAuthType, RejectCommonType } from '../types';
import {
  REDUX_LOAD_NOW_OR_NEVER,
  REDUX_UPDATE_EVENT_AS_FINISHED,
  REDUX_LOAD_SEARCH_LOCATION,
  REDUX_LOAD_SEARCH_EVENTS,
  REDUX_FIND_EVENT,
  REDUX_INVITE_TO_EVENT,
  REDUX_JOIN_REPEATING_EVENTS,
  REDUX_UPDATE_REPEATING_EVENTS,
  REDUX_DELETE_REPEATING_EVENTS,
} from '../thunks/eventThunks';
import {
  REDUX_AUTH_SIGN_IN,
  REDUX_AUTH_SIGN_UP,
  REDUX_AUTH_RQ_NEW_PSWD,
  REDUX_AUTH_SUBMIT_NEW_PSWD,
  REDUX_AUTH_CHANGE_PASSWORD,
} from '../thunks/authThunks';
import {
  REDUX_DELETE_ACC,
  REDUX_UPDATE_MY_PROFILE,
  REDUX_ADD_LOCATION,
  REDUX_POPULATE_FIRST_TIME,
} from '../thunks/profileThunks';
import { REDUX_CREATE_PLACE, REDUX_UPDATE_PLACE } from '../thunks/placeThunks';
import { REDUX_DELETE_ALL_NOTIFICATIONS } from '../thunks/notificationThunks';
import { FlashMessagesKeys } from '../../util/flashMessage';
import { LocaleType, ObjectFromList } from '../../types';
import { REDUX_SUGGEST_PLAYERS } from '../thunks/thunks';
import { REDUX_GET_NOTE_USER } from '../thunks/noteThunks';
import type { AppState } from '../store';

import {
  CREATE_EVENT,
  UPDATE_EVENT,
  DELETE_EVENT,
  JOIN_EVENT,
  LEAVE_EVENT,
} from '@aclito/shared/redux/api/eventApi';
import {
  CREATE_FEED,
  DELETE_FEED,
  LIST_FEEDS_BY_MY_ORGS,
  LIST_FEEDS_BY_ORG,
  UPDATE_FEED,
} from '@aclito/shared/redux/api/feedApi';
import {
  CREATE_AVAILABILITY,
  UPDATE_AVAILABILITY,
  DELETE_AVAILABILITY,
  LIST_AVAILABILITIES,
} from '@aclito/shared/redux/api/availabilityApi';
import {
  CREATE_ORGANIZATION,
  UPDATE_ORGANIZATION,
  LEAVE_ORGANIZATION,
  JOIN_ORGANIZATION,
  LIST_MY_ORGANIZATIONS,
  FIND_ORGANIZATION,
  LIST_ALL_ORGANIZATIONS,
} from '@aclito/shared/redux/api/organizationApi';
import { CREATE_REPORT } from '@aclito/shared/redux/api/reportApi';
import { LEAVE_CHAT } from '@aclito/shared/redux/api/messageApi';

type RejectType = RejectCommonType | RejectAuthType;

type PromiseRejected = Record<RejectType, FlashMessagesKeys>;

type PromiseType = {
  rejected: Partial<PromiseRejected> | undefined;
  fulfilled: FlashMessagesKeys | undefined;
};

type MessagesType = ObjectFromList<typeof AllMatches, PromiseType>;
export type AnyMessagesType = Record<string, PromiseType>;

const EventMatches = [
  CREATE_EVENT,
  UPDATE_EVENT,
  DELETE_EVENT,
  JOIN_EVENT,
  LEAVE_EVENT,
  REDUX_LOAD_NOW_OR_NEVER,
  REDUX_UPDATE_EVENT_AS_FINISHED,
  REDUX_LOAD_SEARCH_LOCATION,
  REDUX_LOAD_SEARCH_EVENTS,
  REDUX_FIND_EVENT,
  REDUX_JOIN_REPEATING_EVENTS,
  REDUX_UPDATE_REPEATING_EVENTS,
  REDUX_DELETE_REPEATING_EVENTS,
  REDUX_INVITE_TO_EVENT,
] as const;

const NoteMatches = [REDUX_GET_NOTE_USER] as const;

const ProfileMatches = [
  REDUX_DELETE_ACC,
  REDUX_UPDATE_MY_PROFILE,
  REDUX_ADD_LOCATION,
] as const;

const AvailabilityMatches = [
  CREATE_AVAILABILITY,
  UPDATE_AVAILABILITY,
  DELETE_AVAILABILITY,
  LIST_AVAILABILITIES,
] as const;

const OrganizationMatches = [
  CREATE_ORGANIZATION,
  LEAVE_ORGANIZATION,
  UPDATE_ORGANIZATION,
  JOIN_ORGANIZATION,
  LIST_MY_ORGANIZATIONS,
  FIND_ORGANIZATION,
  LIST_ALL_ORGANIZATIONS,
] as const;

const AuthMatches = [
  REDUX_AUTH_SIGN_IN,
  REDUX_AUTH_SIGN_UP,
  REDUX_AUTH_RQ_NEW_PSWD,
  REDUX_AUTH_SUBMIT_NEW_PSWD,
  REDUX_POPULATE_FIRST_TIME,
  REDUX_AUTH_CHANGE_PASSWORD,
] as const;

const NotificationMatches = [REDUX_DELETE_ALL_NOTIFICATIONS] as const;

const FeedMatches = [
  CREATE_FEED,
  UPDATE_FEED,
  DELETE_FEED,
  LIST_FEEDS_BY_MY_ORGS,
  LIST_FEEDS_BY_ORG,
] as const;

const AllMatches = [
  CREATE_REPORT,
  LEAVE_CHAT,
  REDUX_CREATE_PLACE,
  REDUX_UPDATE_PLACE,
  REDUX_SUGGEST_PLAYERS,
  ...FeedMatches,
  ...NotificationMatches,
  ...AuthMatches,
  ...EventMatches,
  ...ProfileMatches,
  ...OrganizationMatches,
  ...AvailabilityMatches,
  ...NoteMatches,
] as const;
export const setupUiMessageMiddleware = (
  notificationCallBack: (locale: LocaleType, type: FlashMessagesKeys) => void,
  additionalMatchers?: AnyMessagesType,
) => {
  const middleware = createListenerMiddleware();

  middleware.startListening({
    matcher: isAnyOf(isFulfilled, isRejected),
    effect: async (action, listenerApi) => {
      if (action.meta?.arg?.noMessage) {
        return;
      }
      let defaultAction = '',
        promiseResolution = '';

      const isRTKQuery =
        action.type.includes('executeQuery') ||
        action.type.includes('executeMutation');

      if (isRTKQuery) {
        defaultAction = action.meta?.arg?.endpointName;
        promiseResolution = action.meta?.requestStatus;
      } else {
        [defaultAction, promiseResolution] = action.type.split('/');
      }

      if (
        matches(defaultAction, [
          ...AllMatches,
          ...Object.keys(additionalMatchers ?? []),
        ])
      ) {
        const allMessages = { ...messages, ...additionalMatchers };
        const { locale } = listenerApi.getState() as AppState;
        if (promiseResolution.includes('fulfilled')) {
          const type =
            allMessages[defaultAction as keyof typeof allMessages].fulfilled;
          if (type) {
            notificationCallBack(locale, type);
          }
        } else {
          if (isRTKQuery) {
            if (typeof action.payload?.error !== 'string') return;
          } else {
            if (action.payload.type === 'none') return;
            if (typeof action.payload.type !== 'string') return;
          }
          const type =
            allMessages[defaultAction as keyof typeof allMessages].rejected![
              (isRTKQuery
                ? action.payload.error
                : action.payload.type) as RejectType
            ]!;
          notificationCallBack(locale, type);
        }
      }
    },
  });
  return middleware;
};
const messages: MessagesType = {
  [REDUX_POPULATE_FIRST_TIME]: {
    fulfilled: undefined,
    rejected: {
      ...errorObject,
    },
  },
  [REDUX_GET_NOTE_USER]: {
    fulfilled: undefined,
    rejected: {
      permission: 'noteUserPermission',
    },
  },
  [CREATE_FEED]: {
    fulfilled: 'feedCreateSuccess',
    rejected: {
      fail: 'feedCreateError',
    },
  },
  [UPDATE_FEED]: {
    fulfilled: 'feedUpdateSuccess',
    rejected: {
      fail: 'feedUpdateError',
    },
  },
  [LIST_FEEDS_BY_MY_ORGS]: {
    fulfilled: undefined,
    rejected: {
      fail: 'feedListError',
    },
  },
  [DELETE_FEED]: {
    fulfilled: 'feedDeleteSuccess',
    rejected: {
      fail: 'feedDeleteError',
    },
  },
  [LIST_FEEDS_BY_ORG]: {
    fulfilled: undefined,
    rejected: {
      fail: 'feedListError',
    },
  },
  [LEAVE_ORGANIZATION]: {
    fulfilled: 'orgLeaveSuccess',
    rejected: {
      permission: 'orgLeavePermission',
    },
  },
  [REDUX_ADD_LOCATION]: {
    fulfilled: 'locationUpdateSuccess',
    rejected: {
      fail: 'locationUpdateError',
    },
  },
  [REDUX_DELETE_ALL_NOTIFICATIONS]: {
    fulfilled: 'notificationDeleteAllSuccess',
    rejected: {
      fail: 'notificationDeleteAllError',
    },
  },
  [REDUX_CREATE_PLACE]: {
    fulfilled: 'placeCreateSuccess',
    rejected: {
      fail: 'placeCreateError',
    },
  },
  [REDUX_UPDATE_PLACE]: {
    fulfilled: 'placeUpdateSuccess',
    rejected: {
      fail: 'placeUpdateError',
    },
  },
  [DELETE_EVENT]: {
    fulfilled: 'deleteEventSuccess',
    rejected: { fail: 'deleteEventError' },
  },
  [REDUX_DELETE_REPEATING_EVENTS]: {
    fulfilled: 'deleteEventSuccess',
    rejected: { fail: 'deleteEventError' },
  },
  [LEAVE_EVENT]: {
    fulfilled: 'leaveEventSuccess',
    rejected: { fail: 'leaveEventError' },
  },
  [UPDATE_EVENT]: {
    fulfilled: 'updateEventSuccess',
    rejected: {
      fail: 'updateEventError',
      permission: 'updateEventPermissionError',
    },
  },
  [REDUX_UPDATE_REPEATING_EVENTS]: {
    fulfilled: 'updateEventSuccess',
    rejected: {
      fail: 'updateEventError',
    },
  },
  [CREATE_EVENT]: {
    fulfilled: 'createEventSuccess',
    rejected: {
      fail: 'createEventError',
      permission: 'createEventPermissionError',
    },
  },
  [REDUX_UPDATE_MY_PROFILE]: {
    fulfilled: 'updateMyProfileSuccess',
    rejected: { fail: 'updateMyProfileError', ...errorObject },
  },

  [REDUX_DELETE_ACC]: {
    fulfilled: 'accountDeleteSuccess',
    rejected: { fail: 'accountDeleteError' },
  },
  [JOIN_EVENT]: {
    fulfilled: 'joinEventSuccess',
    rejected: {
      fail: 'joinEventError',
      permission: 'joinEventPermissionError',
    },
  },
  [REDUX_JOIN_REPEATING_EVENTS]: {
    fulfilled: 'joinEventSuccess',
    rejected: {
      fail: 'joinEventError',
      permission: 'joinEventPermissionError',
    },
  },
  [REDUX_FIND_EVENT]: {
    fulfilled: undefined,
    rejected: { fail: 'findEventError' },
  },
  [REDUX_INVITE_TO_EVENT]: {
    fulfilled: 'invitePlayerToEventSuccess',
    rejected: undefined,
  },
  [REDUX_LOAD_SEARCH_EVENTS]: {
    fulfilled: undefined,
    rejected: { fail: 'searchEventsListError', empty: 'searchEventsListEmpty' },
  },
  [REDUX_LOAD_SEARCH_LOCATION]: {
    fulfilled: undefined,
    rejected: {
      fail: 'searchEventsLocationError',
      empty: 'searchEventsLocationListEmpty',
    },
  },
  [REDUX_UPDATE_EVENT_AS_FINISHED]: {
    fulfilled: 'eventUpdatedAsFinishedSuccess',
    rejected: { fail: 'eventUpdatedAsFinishedError' },
  },
  [REDUX_LOAD_NOW_OR_NEVER]: {
    fulfilled: undefined,
    rejected: { fail: 'nowOrNeverListError', empty: 'nowOrNeverListEmpty' },
  },
  [JOIN_ORGANIZATION]: {
    fulfilled: 'orgJoinSuccess',
    rejected: { fail: 'orgJoinError', permission: 'orgJoinPermission' },
  },
  [UPDATE_ORGANIZATION]: {
    fulfilled: 'orgUpdateSuccess',
    rejected: { fail: 'orgUpdateError' },
  },
  [CREATE_ORGANIZATION]: {
    fulfilled: 'orgCreateSuccess',
    rejected: { fail: 'orgCreateError', quota: 'orgCreateErrorQuota' },
  },
  [LIST_MY_ORGANIZATIONS]: {
    fulfilled: undefined,
    rejected: { fail: 'orgListError', empty: 'orgListErrorEmpty' },
  },
  [LIST_ALL_ORGANIZATIONS]: {
    fulfilled: undefined,
    rejected: { fail: 'orgListError', empty: 'orgListErrorEmpty' },
  },
  [FIND_ORGANIZATION]: {
    fulfilled: undefined,
    rejected: { fail: 'orgFindError' },
  },
  [REDUX_AUTH_SIGN_IN]: {
    fulfilled: undefined,
    rejected: {
      ...errorObject,
    },
  },
  [REDUX_AUTH_SIGN_UP]: {
    fulfilled: 'signUpSuccess',
    rejected: {
      ...errorObject,
    },
  },
  [REDUX_AUTH_RQ_NEW_PSWD]: {
    fulfilled: 'requestNewPasswordSuccess',
    rejected: {
      ...errorObject,
    },
  },
  [REDUX_AUTH_SUBMIT_NEW_PSWD]: {
    fulfilled: 'submitNewPasswordSuccess',
    rejected: {
      ...errorObject,
    },
  },
  [REDUX_AUTH_CHANGE_PASSWORD]: {
    fulfilled: 'changePasswordSuccess',
    rejected: {
      ...errorObject,
    },
  },
  [CREATE_REPORT]: {
    fulfilled: 'reportCreateSuccess',
    rejected: {
      fail: 'reportCreateError',
      permission: 'reportCreatePermissionError',
    },
  },
  [LEAVE_CHAT]: {
    fulfilled: 'chatLeaveSuccess',
    rejected: {
      fail: 'chatLeaveError',
    },
  },
  [CREATE_AVAILABILITY]: {
    fulfilled: 'availabilityCreateSuccess',
    rejected: {
      fail: 'availabilityCreateError',
    },
  },
  [UPDATE_AVAILABILITY]: {
    fulfilled: 'availabilityUpdateSuccess',
    rejected: {
      fail: 'availabilityUpdaterror',
    },
  },
  [DELETE_AVAILABILITY]: {
    fulfilled: 'availabilityDeleteSuccess',
    rejected: {
      fail: 'availabilityDeleteError',
    },
  },
  [LIST_AVAILABILITIES]: {
    fulfilled: undefined,
    rejected: {
      fail: 'availabilitiesListError',
      empty: 'availabilitiesListErrorEmpty',
    },
  },
  [REDUX_SUGGEST_PLAYERS]: {
    fulfilled: 'playerSuggestionsSuccess',
    rejected: {
      fail: 'playerSuggestionsError',
      empty: 'playerSuggestionsEmpty',
    },
  },
};
const matches = (matcher: string, actions: readonly string[]): boolean =>
  actions.some((action) => action === matcher);
