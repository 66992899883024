import { useAppSelector } from '@aclito/shared/hooks';
import { profileSelectors } from '@aclito/shared/redux/slices/profileSlices';
import { FigureShrugging } from '@aclito/shared/resources';
import styled from 'styled-components';
import { useMediaQuery } from '@mantine/hooks';
import { deviceMax } from '@aclito/ui-web/utils/mediaQueries';
import { useGetUserQuery } from '@aclito/shared/redux/api/userApi';
import { useParams } from 'react-router-dom';
import { UserAlias } from '@aclito/entities';

import AliasCard from './components/AliasCard';

import {
  SectionGrid,
  ActionButton,
  BackButton,
  HStack,
  Text,
  TitleBar,
  VStack,
  Title,
} from '@/components';
import { useNavigate } from '@/hooks/useNavigate';
import {
  ROUTE_ALIAS_INFO,
  ROUTE_PROFILE_ALIASES_CREATE,
} from '@/util/constants';
import { useFlexWrap } from '@/hooks/useFlexWrap';

const ProfileAliases = () => {
  const { userId } = useParams();
  const navigation = useNavigate();
  const userInfo = useAppSelector(profileSelectors.userInfo);

  const id = userId || userInfo.id;
  const isMyProfile = id === userInfo.id;

  const { data } = useGetUserQuery({ id }, { skip: isMyProfile });
  const aliases = isMyProfile ? userInfo.aliases : data?.user?.aliases ?? [];

  const onCreatePress = () => {
    navigation(ROUTE_PROFILE_ALIASES_CREATE);
  };

  const onNavigateInfo = (alias: UserAlias) => {
    navigation(ROUTE_ALIAS_INFO, { state: { alias } });
  };

  const onBackPress = () => navigation(-1);
  const containerId = 'titleFlexContainer';
  const isFlexWrapped = useFlexWrap(containerId);
  const queryMobile = useMediaQuery(deviceMax.mobile);

  return (
    <SectionGrid $isOpen={false}>
      <TitleBar id={containerId}>
        <HStack>
          <BackButton onClick={onBackPress} />
          <Title tx="profile.alias" />
        </HStack>
        {isMyProfile && (
          <>
            {isFlexWrapped || queryMobile ? (
              <ActionButton iconType={'add'} primary onClick={onCreatePress} />
            ) : (
              <ActionButton
                iconType={'add'}
                primary
                onClick={onCreatePress}
                tx={'add'}
              />
            )}
          </>
        )}
      </TitleBar>
      {aliases.map((alias) => (
        <AliasCard
          onClick={onNavigateInfo}
          showButtons={isMyProfile}
          key={alias.id}
          {...{ alias }}
        />
      ))}
      {!aliases.length ? (
        <VStack spacing={24} mt={24}>
          <Text align="center" tx={'profile.no.alias'} />
          <EmptyListingImage src={FigureShrugging} />
        </VStack>
      ) : null}
    </SectionGrid>
  );
};

const EmptyListingImage = styled.img`
  height: 15rem;
`;

export default ProfileAliases;
