import moment from 'moment';
import type { Message } from '@aclito/entities';

import { IStorage, LastSeenData } from '../types';

import { LAST_SEEN_CHAT } from './constants';

import { hashIds } from '@aclito/shared/util/getHash';

export const updateLastSeen = async (
  Storage: IStorage,
  props: {
    messages: Message[];
    chatId: string;
    userId: string;
  },
) => {
  const { messages, chatId, userId } = props;

  if (messages?.length && chatId) {
    const lastMessage = messages[0];
    const hash = hashIds(chatId, userId);
    const lastSeenData: LastSeenData = {
      [hash]: {
        lastMessage,
        lastSeen: moment().add(2, 'seconds').toISOString(),
      },
    };

    const data = await Storage.getParsedItems(LAST_SEEN_CHAT);

    const findData = data[hash];

    if (findData) {
      if (
        lastMessage.id !== findData.lastMessage.id ||
        lastSeenData[hash].lastSeen > findData.lastSeen
      ) {
        data[hash] = lastSeenData[hash];
        Storage.setItem(LAST_SEEN_CHAT, JSON.stringify(data));
      }
    } else {
      data[hash] = lastSeenData[hash];
      Storage.setItem(LAST_SEEN_CHAT, JSON.stringify(data));
    }
  }
};
