import React from 'react';

import { ViewMapProps } from '../types/types';

import { SectionGrid, BackButton, HStack, TitleBar, Title } from '@/components';
import withRouter from '@/navigation/withRouter';
import SimpleMap from '@/common/Maps/SimpleMap';
import { useMarkerClick } from '@/features/event/screens/Events/hooks/useMarkerClick';

const ViewMap: React.FC<ViewMapProps> = ({ state }) => {
  const { handleMarkerClick } = useMarkerClick();

  const event = state?.event;

  return event ? (
    <SectionGrid $isOpen={false}>
      <TitleBar>
        <HStack>
          <BackButton />
          <Title text={event.name} />
        </HStack>
      </TitleBar>
      <SimpleMap
        onMarkerClick={handleMarkerClick}
        size={{ width: '100%', height: '75vh' }}
        data={[event]}
      />
    </SectionGrid>
  ) : (
    <Title tx="error" />
  );
};

export default withRouter(ViewMap);
