import BaseConfig from './config.base';
import { ProdConfig } from './config.prod';
import { DevConfig } from './config.dev';

let extraConfig = ProdConfig;

export const configInit = (dev: boolean) => {
  if (dev) {
    extraConfig = DevConfig;
  }
};
const config = { ...BaseConfig, ...extraConfig };

export { config };
