import React from 'react';
const S3Image = ({
  imageURL,
  ...props
}: { imageURL: string } & React.DetailedHTMLProps<
  React.ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>) => {
  if (!imageURL) return null;

  return (
    <img
      src={imageURL}
      {...props}
      style={{
        borderRadius: '50%',
        maxHeight: '100%',
        maxWidth: '100%',
        ...props.style,
      }}
    />
  );
};

export default S3Image;
