export const formatTimeInput = (input: string) => {
  if (input.length > 2) {
    input = `${input.slice(0, 2)}:${input.slice(2)}`;
  }

  const modifiedRegex = /(\D+)?(\d{2}:)?(\D+)?/g;
  input = input.replace(modifiedRegex, (_, _p1, p2) => p2 || '');

  if (input.length > 5) {
    input = input.slice(0, 5);
  }

  const [hours, minutes] = input.split(':').map(Number);
  if (hours > 23) input = '23:' + input.slice(3);
  if (minutes > 59) input = input.slice(0, 3) + '59';

  return input;
};
