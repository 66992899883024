import type { UserInfo } from '@aclito/entities';

import { config } from '../../../config';
import { FormattedUserInfo, TranslateKey, txFn } from '../../../types';

const { age, activities, gender, allLanguages } = config;
export const composeUser = (t: txFn, userInfo: UserInfo) => {
  const ageItem = age[userInfo.age].item;
  const genderItem = gender.signup[userInfo.gender].item;
  const user: FormattedUserInfo = {
    ...userInfo,
    formattedAge:
      userInfo.age === age.unknown.id ? t(ageItem as TranslateKey) : ageItem,
    formattedGender: t(genderItem as TranslateKey),
    formattedLanguages: userInfo.languages.length
      ? userInfo.languages.map((lang) => allLanguages[lang].name)
      : ['-'],
    formattedActivities: userInfo.activities.length
      ? userInfo.activities.map((act) =>
          t(activities[act].item as TranslateKey),
        )
      : ['-'],
  };
  return user;
};
