import { FeedForm } from '@aclito/shared/features/organization/types/types';
import { useAppSelector, useSchema } from '@aclito/shared/hooks';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useFeedEdit } from '@aclito/shared/features/organization/hooks/useFeedEdit';
import { yupResolver } from '@hookform/resolvers/yup';
import { ImageValueShape } from '@aclito/shared/types';
import { StyledTitle } from '@aclito/ui-web/utils/styles';
import { sizeMax } from '@aclito/ui-web/utils/mediaQueries';
import {
  selectAllFeeds,
  useGetFeedQuery,
} from '@aclito/shared/redux/api/feedApi';
import { Feed } from '@aclito/entities';

import {
  SectionGrid,
  ActionButton,
  SmallButton,
  TitleBar,
  VStack,
  ValidatedSelect,
  ValidatedTextArea,
  ValidatedDropzone,
} from '@/components';
import withRouter from '@/navigation/withRouter';
import { StateFeedEdit } from '@/util/types';
import { TypedLocation } from '@/hooks/useLocation';
import { useNavigate } from '@/hooks/useNavigate';

type OrganizationFeedEditWrapperProps = TypedLocation<StateFeedEdit>;
type OrganizationFeedEditProps = { feed: Feed | undefined };
type WebFeedForm = FeedForm & {
  file: string | undefined;
  image?: ImageValueShape;
};

const OrganizationFeedEdit: React.FC<OrganizationFeedEditProps> = ({
  feed,
}) => {
  const { createFeedSchema, editFeedSchema } = useSchema();
  const {
    orgOptions,
    initialValues,
    createOrUpdateFeed: handleCreateOrUpdateFeed,
  } = useFeedEdit(feed);

  const schema = feed ? editFeedSchema : createFeedSchema;

  const methods = useForm<WebFeedForm>({
    defaultValues: initialValues,
    //@ts-ignore
    resolver: yupResolver(schema),
  });

  const handleButtonSubmit = () => {
    methods.handleSubmit((values) => {
      handleCreateOrUpdateFeed(values);
      nav(-1);
    })();
  };

  const nav = useNavigate();
  const navBack = () => {
    nav(-1);
  };
  return (
    <SectionGrid
      style={{ alignSelf: 'center', maxWidth: sizeMax.tablet }}
      $isOpen={false}
    >
      <TitleBar>
        <StyledTitle tx={feed ? 'nav.feedEdit' : 'org.add.feed'} />
        <ActionButton
          onClick={navBack}
          bg={'red10'}
          textcolor={'red'}
          iconType={'close'}
        />
      </TitleBar>

      <FormProvider {...methods}>
        <VStack>
          {!feed && (
            <ValidatedSelect
              txLabel="org.feed.org"
              txPlaceholder="org.feed.org"
              name="feedOrg"
              options={orgOptions}
            />
          )}
          <ValidatedTextArea
            name="feedText"
            txPlaceholder="org.feed.description"
            txLabel="org.feed.description"
          />
          <ValidatedDropzone name="image" imageOnly circular={false} />
          <SmallButton onClick={handleButtonSubmit} bg="primary" tx="submit" />
        </VStack>
      </FormProvider>
    </SectionGrid>
  );
};

function OrganizationFeedEditWrapper({
  state,
}: OrganizationFeedEditWrapperProps) {
  const feedId = state?.feedId;
  const selectedFeed = useAppSelector(selectAllFeeds).find(
    (f) => f.id === feedId,
  );
  const { data } = useGetFeedQuery(feedId ?? '', {
    skip: !!selectedFeed || !feedId,
  });

  const feed = selectedFeed ?? data;

  return <OrganizationFeedEdit feed={feed} />;
}

export default withRouter(OrganizationFeedEditWrapper);
