import { useSchema, useTranslate } from '@aclito/shared/hooks';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { AvailabilityForm } from '@aclito/shared/types';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAvailabilityCompleteEdit } from '@aclito/shared/features/availability/hooks/useAvailaiblityCompleteEdit';
import { StyledTitle } from '@aclito/ui-web/utils/styles';
import { sizeMax } from '@aclito/ui-web/utils/mediaQueries';

import { useAvailabilityCreateEdit } from '../../hooks/useAvailabilityCreateEdit';

import { useAvailabilityNew } from './hooks/useAvailabilityNew';

import {
  SectionGrid,
  ActionButton,
  Stepper,
  TitleBar,
  useStepper,
  VStack,
  StepperButtons,
} from '@/components';
import { Date, Place, Activities } from '@/common/AvailabilityForm';

const NewAvailability: React.FC = () => {
  const t = useTranslate();

  const { newAvailabilityValidationSchema } = useSchema();

  const { initialValues, handleSubmit } = useAvailabilityNew();
  const { active, setActive, nextStep, prevStep } = useStepper();

  const steps = [
    {
      component: <Date showRepeating={true} />,
      label: t('date'),
    },
    {
      component: <Activities />,
      label: t('availability.new.activities'),
    },
    {
      component: <Place />,
      label: t('events.new.place'),
    },
  ];

  const methods = useForm<AvailabilityForm>({
    //@ts-ignore
    resolver: yupResolver(newAvailabilityValidationSchema),
    defaultValues: initialValues,
    mode: 'onBlur',
  });

  const { alreadyHasAvailability, alreadyHasEvent } =
    useAvailabilityCompleteEdit(methods.getValues, undefined);

  const { onSubmit, onError, navBack } = useAvailabilityCreateEdit(
    handleSubmit,
    alreadyHasEvent(),
    alreadyHasAvailability(),
  );

  return (
    <SectionGrid
      style={{ alignSelf: 'center', maxWidth: sizeMax.tablet }}
      $isOpen={false}
    >
      <TitleBar>
        <StyledTitle tx="nav.availability" />
        <ActionButton
          onClick={navBack}
          bg={'red10'}
          textcolor={'red'}
          iconType={'close'}
        />
      </TitleBar>

      <FormProvider {...methods}>
        <VStack>
          <Stepper
            {...{
              active,
              steps,
              setActive,
              nextStep,
              prevStep,
            }}
          />
          <StepperButtons
            {...{
              onSubmit,
              onError,
              active,
              steps: steps.length,
              nextStep,
              prevStep,
            }}
          />
        </VStack>
      </FormProvider>
    </SectionGrid>
  );
};
export default NewAvailability;
