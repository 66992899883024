import '@total-typescript/ts-reset';
import { createLogger } from '@aclito/shared/util/logger';
import I18nProvider from '@aclito/shared/i18n/I18nProvider';
import { Provider as ReduxProvider } from 'react-redux';
import { store } from '@aclito/shared/redux/store';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import awsConfig from '@aclito/shared/aws/aws-exports';
import { Authenticator } from '@aws-amplify/ui-react';
import { Amplify } from 'aws-amplify';
import { MantineProvider } from '@mantine/core';
import * as Sentry from '@sentry/react';
import { Notifications } from '@mantine/notifications';
import AManager from '@aclito/shared/classes/AManager';
import { useEffect } from 'react';
import { SENTRY_DSN } from '@aclito/shared/util/constants';
import { AxiomLogger } from '@aclito/shared/classes/AxiomLogger';
import { config, configInit } from '@aclito/shared/config';
import { isEnabledConfig } from '@aclito/shared/util/isEnabledConfig';
import { loggerMiddleware } from '@aclito/shared/redux/middleware/loggerMiddleware';
import { setupLocaleMiddleware } from '@aclito/shared/redux/middleware/localeMiddleware';
import { setupUiMessageMiddleware } from '@aclito/shared/redux/middleware/UiMessageMiddleware';
import { Hub } from '@aclito/shared/classes/Hub';
import {
  addMiddleware,
  removeMiddleware,
} from '@aclito/shared/redux/dynamicMiddleware';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import ThemeProvider, {
  ThemeContext,
} from '@aclito/ui-web/providers/ThemeProvider';
import { useAppSelector } from '@aclito/shared/hooks';
import { WebTheme } from '@aclito/ui-web/utils/theme';
import { showTranslatedNotification } from '@aclito/ui-web/utils/showTranslatedNotification';
import { useNotificationWS } from '@aclito/shared/features/notification/hooks/useNotificationWS';

import Routes from './navigation/Routes';
import { Storage } from './util/storage';
import { ROUTE_SIGN_IN } from './util/constants';

import 'moment/locale/cs';
import 'moment/locale/en-gb';
import 'moment/locale/sk';
import 'moment/dist/locale/cs';
import 'moment/dist/locale/en-gb';
import 'moment/dist/locale/sk';

import { ErrorBoundary, MobileBanner } from '@/components';
import LocaleProvider from '@/providers/LocaleProvider';
import { saveRandomIdToBrowser } from '@/util/randomIdBrowser';

const __DEV__ = import.meta.env.MODE === 'development';

const App: React.FC = () => {
  createLogger(__DEV__);
  configInit(__DEV__);

  const url = `${window.location.protocol}//${window.location.host}${ROUTE_SIGN_IN}`;

  awsConfig.oauth.redirectSignIn = url;
  awsConfig.oauth.redirectSignOut = url;
  Amplify.configure({
    ...awsConfig,
    oauth: {
      ...awsConfig.oauth,
    },
  });
  Hub.init(Storage);
  AManager.start().setConfig();
  AxiomLogger.init(config.axiom.token, 'web');
  AxiomLogger.isDev = __DEV__;
  AxiomLogger.deviceId = saveRandomIdToBrowser();

  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [
      new Sentry.BrowserTracing({
        // See docs for support of different versions of variation of react router
        // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        ),
      }),
      new Sentry.Replay(),
    ],
    tracesSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
    enabled: isEnabledConfig(config.catchErrors, __DEV__),
  });

  useEffect(() => {
    addMiddleware(loggerMiddleware);
    addMiddleware(setupLocaleMiddleware(Storage));
    addMiddleware(
      setupUiMessageMiddleware(showTranslatedNotification).middleware,
    );

    return () => {
      removeMiddleware(loggerMiddleware);
      removeMiddleware(setupLocaleMiddleware(Storage));
      removeMiddleware(
        setupUiMessageMiddleware(showTranslatedNotification).middleware,
      );
    };
  }, []);

  return (
    <Authenticator.Provider>
      <ReduxProvider store={store}>
        <MyApp />
      </ReduxProvider>
    </Authenticator.Provider>
  );
};

const MyApp = () => {
  const locale = useAppSelector((state) => state.locale);
  useNotificationWS();

  return (
    <LocaleProvider>
      <I18nProvider locale={locale}>
        <ThemeProvider Storage={Storage} withStorage>
          <ThemeContext.Consumer>
            {({ theme }) => (
              <>
                <MantineProvider
                  withGlobalStyles
                  withNormalizeCSS
                  theme={{
                    fontFamily: 'Roboto, sans-serif',
                    ...WebTheme,
                    colorScheme: theme,
                  }}
                >
                  <ErrorBoundary>
                    <>
                      <Notifications position="top-center" limit={3} />
                      <Routes />
                      <MobileBanner />
                    </>
                  </ErrorBoundary>
                </MantineProvider>
              </>
            )}
          </ThemeContext.Consumer>
        </ThemeProvider>
      </I18nProvider>
    </LocaleProvider>
  );
};

export default App;
