import { Box, Stack } from '@mantine/core';
import { useTranslate } from '@aclito/shared/hooks/useTranslate';
import styled, { useTheme } from 'styled-components';
import React from 'react';
import { GoogleMap, Marker, Circle } from '@react-google-maps/api';
import {
  SubmitHandler,
  useForm,
  FormProvider,
  useFormContext,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ThemeColorsRecord } from '@aclito/shared/theme/colors';
import * as yup from 'yup';
import { closeAllModals } from '@mantine/modals';
import { Event } from '@aclito/entities';

import MapGuide from '../Maps/MapGuide';

import { usePlaceWeb } from './hooks/usePlaceWeb';

import {
  ValidatedSelect,
  HStack,
  VStack,
  SmallButton,
  SubmitButton,
  Text,
  ValidatedInput,
} from '@/components';
import { MAP_BORDER_RADIUS } from '@/util/constants';
import { useModal } from '@/hooks/useModal';

interface PlaceProps {
  eventToEdit?: Event;
}

const Place: React.FC<PlaceProps> = ({ eventToEdit }) => {
  const t = useTranslate();
  const { openModal } = useModal();

  const {
    handleUpdatePlaceActivities,
    selectedPlace,
    setSelectedPlace,
    beenPlayed,
    activity,
    currentCenter,
    handleCreatePlace,
    mapLocation,
    newPlaceSchema,
    isLoaded,
    circleRadius,
    onMapClick,
    loadPlaces,
    allList,
    placeSelectList,
  } = usePlaceWeb(eventToEdit);

  const theme = useTheme();

  type NewPlaceForm = yup.InferType<typeof newPlaceSchema>;
  const initialValues: NewPlaceForm = {
    newPlace: '',
    newPlaceAddress: '',
  };

  const methods = useForm<NewPlaceForm>({
    defaultValues: initialValues,
    resolver: yupResolver(newPlaceSchema),
  });

  const { watch } = useFormContext();

  const watchPlace = watch('place');

  const onSubmit: SubmitHandler<NewPlaceForm> = async (values) => {
    openModal({
      type: 'createPlace',
      values: {
        onCreate: async () => {
          await handleCreatePlace(values);
          closeAllModals();
        },
        onBack: closeAllModals,
      },
    });
  };
  return (
    <VStack spacing={16}>
      <ValidatedSelect
        txLabel={
          activity
            ? 'events.new.place.label'
            : 'events.new.place.label.activity.not.selected'
        }
        txPlaceholder="events.new.place.existing"
        name="place"
        options={placeSelectList}
        disabled={!activity}
      />
      <VStack>
        {isLoaded && activity && !watchPlace && (
          <ContainerPlace>
            <ContainerPlaceBox>
              <FormProvider {...methods}>
                <Stack justify="center" align="center">
                  <NewPlaceTitleWrapper>
                    <Divider />
                    <NewPlaceTitle tx="events.new.place.search.or.create" />
                    <Divider />
                  </NewPlaceTitleWrapper>
                  <Box sx={{ position: 'relative', zIndex: 1, width: '100%' }}>
                    <GoogleMap
                      onClick={onMapClick}
                      onLoad={() => loadPlaces(currentCenter)}
                      options={{
                        streetViewControl: false,
                        fullscreenControl: false,
                      }}
                      mapContainerStyle={mapContainer}
                      center={currentCenter}
                      zoom={7}
                    >
                      <Marker
                        draggable
                        onDragEnd={onMapClick}
                        icon={markerMain(theme)}
                        position={mapLocation}
                      />
                      {allList.map((mapPlace) => (
                        <Marker
                          key={mapPlace.id}
                          position={{
                            lat: mapPlace.location.lat,
                            lng: mapPlace.location.lon,
                          }}
                          onClick={() => setSelectedPlace(mapPlace)}
                        />
                      ))}
                      <Circle
                        onClick={onMapClick}
                        radius={circleRadius}
                        center={currentCenter}
                        options={circleOptions(theme)}
                      />
                    </GoogleMap>
                  </Box>
                  <MapGuide />

                  {!!selectedPlace &&
                  allList.find((p) => p?.id === selectedPlace.id) ? (
                    <InfoBox>
                      <StyledVStack>
                        <VStack spacing={8}>
                          <Title text={selectedPlace.name} />
                          <Text size={'0.9rem'} style={{ textAlign: 'center' }}>
                            {beenPlayed
                              ? `${activity.item} ${t(
                                  'events.new.place.played',
                                )}`
                              : `${activity.item} ${t(
                                  'events.new.place.notplayed',
                                )}`}
                          </Text>
                        </VStack>
                        <SmallButton
                          fullWidth
                          bg="primary"
                          onClick={() =>
                            handleUpdatePlaceActivities(selectedPlace)
                          }
                          tx="events.new.place.select"
                        />
                      </StyledVStack>
                    </InfoBox>
                  ) : (
                    <NewPlaceFormWrapper>
                      <NewPlaceFormInputs>
                        <StyledValidatedInput
                          name="newPlace"
                          txPlaceholder="events.new.place.name"
                          required={true}
                          txLabel="events.new.place.name"
                        />
                        <StyledValidatedInput
                          name="newPlaceAddress"
                          txPlaceholder="events.new.place.address"
                          txLabel="events.new.place.address"
                        />
                      </NewPlaceFormInputs>
                      <StyledSubmitButton
                        onSubmit={onSubmit}
                        shape="round"
                        textcolor="primary"
                        bg="primary10"
                        tx="events.new.place.new.create"
                      />
                    </NewPlaceFormWrapper>
                  )}
                </Stack>
              </FormProvider>
            </ContainerPlaceBox>
          </ContainerPlace>
        )}
      </VStack>
    </VStack>
  );
};
const InfoBox = styled(Box)`
  background: ${({ theme }) => theme.white};
  padding: 0.8rem 1rem;
  border-radius: 10px;
  width: 100%;
`;

const NewPlaceFormWrapper = styled(VStack)`
  flex-wrap: nowrap;
  width: 100%;
`;

const NewPlaceFormInputs = styled(HStack)`
  flex-wrap: nowrap;
  width: 100%;
`;

const StyledValidatedInput = styled(ValidatedInput)`
  width: 100%;
`;

const StyledSubmitButton = styled(SubmitButton)`
  width: 100%;
`;

const Divider = styled.span`
  border-top: 1px solid ${({ theme }) => theme.grey10};
  width: 100%;
  margin: 0.5rem 0;
`;

const NewPlaceTitleWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 1rem;
`;

const NewPlaceTitle = styled(Text)`
  color: ${({ theme }) => theme.grey40};
  text-align: center;
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  white-space: nowrap;
`;

const Title = styled(Text)`
  text-align: center;
  font-weight: bold;
  font-size: 1rem;
`;
const StyledVStack = styled(VStack)`
  justify-content: space-between;
  height: 100%;
`;

const ContainerPlace = styled(HStack)`
  align-items: flex-start;
  justify-content: center;
  width: 100%;
`;

const circleOptions = (theme: ThemeColorsRecord) => ({
  fillColor: theme.mapCircle,
  strokeColor: theme.mapCircleStroke,
  strokeWeight: 1,
  fillOpacity: 1,
});

const mapContainer = {
  height: 400,
  borderRadius: MAP_BORDER_RADIUS,
};

const ContainerPlaceBox = styled.div`
  width: 100%;
`;

const markerMain = (theme: ThemeColorsRecord) => ({
  // eslint-disable-next-line max-len
  path: 'M -1.547 12 z M 0 0 q 2.906 0 4.945 2.039 t 2.039 4.945 q 0 1.453 -0.727 3.328 t -1.758 3.516 t -2.039 3.07 t -1.711 2.273 l -0.75 0.797 q -0.281 -0.328 -0.75 -0.867 t -1.688 -2.156 t -2.133 -3.141 t -1.664 -3.445 t -0.75 -3.375 q 0 -2.906 2.039 -4.945 t 4.945 -2.039 z',
  fillColor: theme.blue,
  fillOpacity: 1,
  strokeWeight: 0.2,
  rotation: 0,
  scale: 1.75,
  anchor: window?.google?.maps && new window.google.maps.Point(0, 20),
});
export default Place;
