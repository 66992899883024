import moment from 'moment';
import { Availability, EventRaw } from '@aclito/entities';

import { FormattedEvent } from '../types';

export const calculateEventDaySplit = (
  index: number,
  events: FormattedEvent[],
) =>
  (index > 0 &&
    events[index - 1].formattedDate !== events[index].formattedDate) ||
  index === 0;

export const calculateEventMonthSplit = (index: number, events: EventRaw[]) =>
  (index > 0 &&
    moment(events[index - 1].date).month() !==
      moment(events[index].date).month()) ||
  index === 0;

export const calculateAvailabilityDaySplit = (
  index: number,
  availabilities: Availability[],
) =>
  (index > 0 &&
    moment(availabilities[index - 1].from).date() !==
      moment(availabilities[index].from).date()) ||
  index === 0;

export const calculateAvailabilityMonthSplit = (
  index: number,
  availabilities: Availability[],
) =>
  (index > 0 &&
    moment(availabilities[index - 1].from).month() !==
      moment(availabilities[index].from).month()) ||
  index === 0;
