import { OrganizationForm } from '../../../types';

import { useCreateOrganizationMutation } from '@aclito/shared/redux/api/organizationApi';

export const useOrganizationCreate = () => {
  const [createOrganization, { isLoading }] = useCreateOrganizationMutation();
  const createOrg = async (formValues: OrganizationForm) => {
    await createOrganization(formValues);
  };

  return { createOrg, isLoading };
};
