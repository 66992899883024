import moment from 'moment';
import { Availability } from '@aclito/entities';

import { config } from '../../../config';
import { AvailabilityForm, txFn } from '../../../types';
import { translate } from '../../../util/selectObject';
import { calculateDateDiff } from '../../../util/calculateDateDiff';

import { translateDateFormat } from '@aclito/shared/util/translateDateFormat';

export const composeInitialEditValues = (
  t: txFn,
  availability: Availability,
): AvailabilityForm => ({
  date: availability.from,
  duration: translateDateFormat(
    moment()
      .startOf('day')
      .add(
        calculateDateDiff(availability.to, availability.from),
        'milliseconds',
      )
      .toString(),
    'time.display',
  ),
  activities: availability?.activities?.length
    ? translate(
        availability.activities.map((s) => ({
          item: config.activities[s].item,
          id: s,
        })),
        t,
      )
    : undefined,
  repeatAfter: undefined,
  repetitionId: availability.repetitionId ?? null,
  numberOfAvailabilities: undefined,
  location: undefined,
  repeating: false,
});
