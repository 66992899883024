import type { UserAlias } from '@aclito/entities';
import React from 'react';
import { ThemeColorsRecord } from '@aclito/shared/theme/colors';
import { useTheme } from 'styled-components';

import { StyledPlayerCard } from '../../../../../common/Modals/ItemStyles';

import { Avatar, HStack, Text, VStack, Icon } from '@/components';
import { CardStatusBannerText } from '@/common/CardStatusBanner';

interface AliasModalJoinItemProps {
  alias: UserAlias;
  handleOnAliasPress: (id: string) => void;
  itemBg: (id: string, theme: ThemeColorsRecord) => string;
  isAliasJoined: (aliasId: string) => boolean;
  isAliasInQueue: (aliasId: string) => boolean;
  isSelected: (id: string) => boolean;
  isSelectedQueue: (id: string) => boolean;
}

const AliasModalJoinItem: React.FC<AliasModalJoinItemProps> = ({
  alias,
  isSelected,
  isSelectedQueue,
  isAliasJoined,
  isAliasInQueue,
  handleOnAliasPress,
  itemBg,
}) => {
  const theme = useTheme();
  return (
    <StyledPlayerCard
      onClick={() => handleOnAliasPress(alias.id)}
      key={alias.id}
      bg={itemBg(alias.id, theme)}
      mb={10}
    >
      <HStack>
        <Avatar image={alias.image} size={48} />
        <VStack spacing={8}>
          <Text size={16} text={`${alias.name} ${alias.surname}`} />
          <HStack>
            {isAliasJoined(alias.id) ? (
              <CardStatusBannerText type="joinedAlias" />
            ) : (
              isAliasInQueue(alias.id) && (
                <CardStatusBannerText type="queuedAlias" />
              )
            )}
          </HStack>
        </VStack>
      </HStack>

      {(isSelected(alias.id) || isSelectedQueue(alias.id)) && (
        <Icon icon={'checkmark'} color="primary" />
      )}
    </StyledPlayerCard>
  );
};

export default AliasModalJoinItem;
