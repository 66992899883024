import { SimpleGrid } from '@mantine/core';
import React, { Fragment, useCallback } from 'react';
import styled from 'styled-components';
import { useSharedSettings } from '@aclito/shared/features/misc/hooks/useSettings';
import {
  SettingRecord,
  SettingsType,
} from '@aclito/shared/features/misc/types';
import { FormProvider, useForm } from 'react-hook-form';
import { useMediaQuery } from '@mantine/hooks';
import { deviceMax } from '@aclito/ui-web/utils/mediaQueries';

import { ActionButton, Text, VStack, ValidatedSwitch } from '@/components';

const NotificationSettings = () => {
  const { handleUpdateSettings, initialValues, translationTable, settings } =
    useSharedSettings();

  const methods = useForm<SettingRecord>({
    defaultValues: initialValues,
  });

  const onSubmit = useCallback(() => {
    methods.handleSubmit(handleUpdateSettings)();
  }, [handleUpdateSettings, methods]);

  const queryMobile = useMediaQuery(deviceMax.mobile);
  const Component = queryMobile ? Fragment : SimpleGrid;
  return (
    <VStack spacing={queryMobile ? 20 : 40}>
      <SubTitle tx="settings.notifications" />
      <FormProvider {...methods}>
        <Component cols={2}>
          {settings &&
            Object.keys(settings).map((setting) => (
              <React.Fragment key={setting}>
                <ValidatedSwitch
                  txLabel={translationTable[setting as SettingsType]}
                  name={setting}
                  data-testid={setting}
                />
              </React.Fragment>
            ))}
        </Component>
      </FormProvider>
      <ActionButton primary onClick={onSubmit} tx="submit" w={200} />
    </VStack>
  );
};

const SubTitle = styled(Text)`
  font-size: 20px;
  font-weight: 600;
`;

export default NotificationSettings;
