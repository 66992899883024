import { useTheme } from 'styled-components';
import { useDispatch } from 'react-redux';
import { commonActions } from '@aclito/shared/redux/slices';
import { useSelectObject } from '@aclito/shared/hooks/useSelectObject';
import { Menu } from '@mantine/core';
import { LocaleType } from '@aclito/shared/types';

import { Icon, Text } from '@/components';

const Language = () => {
  const dispatch = useDispatch();
  const { translatedLocaleOptions } = useSelectObject();
  const theme = useTheme();

  return (
    <>
      <Menu
        trigger="click"
        closeOnClickOutside
        closeOnEscape
        shadow="md"
        width={200}
        data-cy="choose-language"
      >
        <Menu.Target>
          <div>
            <Icon
              style={{
                cursor: 'pointer',
              }}
              icon="language"
              color="primary"
              size={'24'}
            />
          </div>
        </Menu.Target>

        <Menu.Dropdown
          style={{
            borderRadius: '10px',
            backgroundColor: theme.white,
            color: theme.grey80,
            border: 'none',
          }}
        >
          {translatedLocaleOptions.map((lang, index) => (
            <Menu.Item
              onClick={() => {
                dispatch(
                  commonActions.updateLocale({ locale: lang.id as LocaleType }),
                );
              }}
              key={lang.id}
              data-cy={`language-${index}`}
            >
              <Text>{lang.item}</Text>
            </Menu.Item>
          ))}
        </Menu.Dropdown>
      </Menu>
    </>
  );
};

export default Language;
