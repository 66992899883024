import { useState } from 'react';
import { useTranslate } from '@aclito/shared/hooks';
import styled from 'styled-components';
import { Divider, Button as MTButton, Space } from '@mantine/core';
import { closeAllModals } from '@mantine/modals';

import { Checkbox, HStack, Text, VStack } from '@/components';
import { useNotes } from '@/features/notes/screens/Notes/hooks/useNotes';
import NoteItem from '@/features/notes/screens/Notes/components/NoteItem';

export const GrantModal = ({
  handleGrant,
  orgName,
}: {
  handleGrant: () => Promise<void>;
  orgName?: string;
}) => {
  const t = useTranslate();

  const [checked, setChecked] = useState(false);

  const { data, operationTable, contactFilled } = useNotes();

  const handleConfirmClick = (text: string, index: number) => {
    operationTable[index as 0 | 1](text);
  };

  return (
    <GrantModalBody spacing={15}>
      <Text tx="org.contact.modal.text" align="center" weight={'bolder'} />
      <VStack spacing={5}>
        <GrantInfo>{t('grant.modal.about')}</GrantInfo>
      </VStack>
      <InputsWrapper spacing={5}>
        {data.map((item, index) => (
          <>
            <NoteItem
              item={item}
              index={index}
              error={item.error}
              handleConfirmClick={handleConfirmClick}
            />

            {index < data.length - 1 && (
              <>
                <Space h={8} />
                <Divider />
                <Space h={16} />
              </>
            )}
          </>
        ))}
      </InputsWrapper>
      <GDPRWrapper>
        <GDPRText>
          {t('grant.modal.gdpr')} <OrgName>{orgName}</OrgName>
        </GDPRText>
        <Checkbox
          checked={checked}
          onChange={(event) => setChecked(event.currentTarget.checked)}
        />
      </GDPRWrapper>
      <MTButton
        onClick={async () => {
          await handleGrant();
          closeAllModals();
        }}
        disabled={!contactFilled || !checked}
      >
        {t('grant.modal.button')}
      </MTButton>
    </GrantModalBody>
  );
};

const GrantModalBody = styled(VStack)`
  padding: 0 1rem 1rem 1rem;
`;

const GDPRWrapper = styled(HStack)`
  width: 100%;
  margin: 0 auto;
  justify-content: center;
`;

const InputsWrapper = styled(VStack)`
  width: 100%;
  max-width: 30rem;
  margin: 0 auto;
  padding-bottom: 1rem;
`;

const GDPRText = styled(Text)`
  font-size: 0.825rem;
  max-width: 27.5rem;
`;

const GrantInfo = styled(Text)`
  text-align: center;
  font-size: 0.9rem;
  max-width: 30rem;
`;

const OrgName = styled.span`
  font-weight: 700;
`;
