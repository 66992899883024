import { useDisclosure } from '@mantine/hooks';

import FeedList from '../../components/FeedList';

import FeedFilter from './components/FeedFilter';

import {
  LeftSectionLarge,
  LeftSectionSmall,
  LeftSectionTitle,
  SectionGrid,
  TitleButtons,
  ActionButton,
  HStack,
  TitleBar,
  Overlay,
  Title,
} from '@/components';
import { ROUTE_FEED_EDIT } from '@/util/constants';
import { useNavigate } from '@/hooks/useNavigate';
import { useFlexWrap } from '@/hooks/useFlexWrap';

const OrganizationFeed = () => {
  const [isOpen, handler] = useDisclosure(false);
  const nav = useNavigate();

  const id = 'titleFlexContainer';
  const handleAddPost = () => nav(ROUTE_FEED_EDIT);

  const isFlexWrapped = useFlexWrap(id);

  return (
    <SectionGrid $isOpen={isOpen}>
      {isOpen && <Overlay onClick={handler.toggle} />}
      {isOpen && (
        <LeftSectionLarge>
          <LeftSectionTitle>
            <Title tx={'intro.title.filter'} />
            <ActionButton iconType="leftDrop" onClick={handler.toggle} />
          </LeftSectionTitle>
          <FeedFilter />
        </LeftSectionLarge>
      )}
      {isOpen && (
        <LeftSectionSmall>
          <LeftSectionTitle>
            <Title tx={'intro.title.filter'} />
            <ActionButton iconType="leftDrop" onClick={handler.toggle} />
          </LeftSectionTitle>
          <FeedFilter />
        </LeftSectionSmall>
      )}
      <div>
        <TitleBar id={id}>
          <TitleButtons>
            <HStack>
              {!isOpen && (
                <ActionButton
                  onClick={handler.toggle}
                  iconType={'filter'}
                  tx="nav.filters"
                />
              )}
              <Title tx="nav.feed" />
            </HStack>
          </TitleButtons>
          {!isFlexWrapped ? (
            <ActionButton
              iconType={'add'}
              primary
              onClick={handleAddPost}
              tx={'org.add.feed'}
            />
          ) : (
            <ActionButton iconType={'add'} primary onClick={handleAddPost} />
          )}
        </TitleBar>
        <FeedList />
      </div>
    </SectionGrid>
  );
};

export default OrganizationFeed;
