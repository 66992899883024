import { useTranslate } from '@aclito/shared/hooks';
import { TranslateKey, TranslateValues } from '@aclito/shared/types';
import { openConfirmModal as MTCM, openModal as MTOM } from '@mantine/modals';
import { ModalSettings, OpenConfirmModal } from '@mantine/modals/lib/context';
import { useTheme } from 'styled-components';

import {
  modalBodies,
  ModalBodiesProps,
  ModalBodyTypes,
} from '@/components/ModalBodies/ModalBodies';

type OpenModalProps<T extends ModalBodyTypes> = Omit<ModalSettings, 'title'> & {
  txTitle?: TranslateKey;
  txOptions?: TranslateValues;
  title?: string;
  type?: T;
  values?: ModalBodiesProps[T];
};

type OpenConfirmModalProps<T extends ModalBodyTypes> = Omit<
  OpenConfirmModal,
  'title' | 'labels'
> & {
  txTitle?: TranslateKey;
  txOptions?: TranslateValues;
  txLabels?: { cancel: TranslateKey; confirm: TranslateKey };
  type?: T;
  values?: ModalBodiesProps[T];
};

export const useModal = () => {
  const t = useTranslate();
  const theme = useTheme();

  const styles = {
    content: {
      background: theme.background,
    },
    header: {
      background: theme.background,
    },
    title: {
      color: theme.text,
    },
  };

  const openConfirmModal = <T extends ModalBodyTypes>(
    payload: OpenConfirmModalProps<T>,
  ) => {
    MTCM({
      ...payload,
      ...(payload.txTitle && { title: t(payload.txTitle, payload.txOptions) }),
      ...(payload.txLabels && {
        labels: {
          cancel: t(payload.txLabels.cancel),
          confirm: t(payload.txLabels.confirm),
        },
      }),
      styles,
      radius: 10,
      overlayProps: { color: theme.modalOverlay },
      ...(payload.type && {
        children: modalBodies(payload.type, payload.values),
      }),
    });
  };

  const openModal = <T extends ModalBodyTypes>(payload: OpenModalProps<T>) => {
    MTOM({
      ...payload,
      ...(payload.txTitle && {
        title: payload.title || t(payload.txTitle, payload.txOptions),
      }),
      radius: 10,
      overlayProps: { color: theme.modalOverlay },
      ...(payload.type && {
        children: modalBodies(payload.type, payload.values),
      }),
      styles,
    });
  };

  return { openModal, openConfirmModal };
};
