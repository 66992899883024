import { notificationActions } from '@aclito/shared/redux/slices';
import { useAppDispatch } from '@aclito/shared/hooks/useDispatch';
import { Notification } from '@aclito/entities';
import { NotificationType } from '@aclito/enums';
import { useNavigate } from 'react-router-dom';

import {
  ROUTE_EVENT_INFO,
  ROUTE_EVENT_INFO_PROVIDERS,
  ROUTE_FEED_INFO,
  ROUTE_FEEDBACK,
  ROUTE_ORGANIZATIONS,
} from '@/util/constants';

export const useLocalNotificationsWeb = () => {
  const dispatch = useAppDispatch();
  const nav = useNavigate();

  const getOnClick = (
    type: NotificationType | 'DELETE',
    item: Notification,
  ) => {
    switch (type) {
      case 'DELETE':
        dispatch(notificationActions.deleteDBNotificationAsync(item.id));
        break;
      case NotificationType.EVENT_END: {
        _navigateToFeedback(item);
        break;
      }
      case NotificationType.EVENT_STARTING:
      case NotificationType.EVENT_QUEUE_SHIFT:
      case NotificationType.EVENT_CONFIRM:
      case NotificationType.EVENT_CHANGE:
      case NotificationType.EVENT_JOIN:
      case NotificationType.EVENT_JOIN_REPEATING:
      case NotificationType.EVENT_MULTIPLE_JOIN:
      case NotificationType.EVENT_QUEUE_MULTIPLE_JOIN:
      case NotificationType.EVENT_QUEUE_REPEATING_JOIN:
      case NotificationType.EVENT_QUEUE_REPEATING_LEFT:
      case NotificationType.EVENT_QUEUE_JOIN:
      case NotificationType.EVENT_PLAYER_CONFIRMED:
      case NotificationType.EVENT_MULTIPLE_PLAYER_CONFIRMED:
      case NotificationType.EVENT_INVITE:
      case NotificationType.EVENT_LEFT:
      case NotificationType.EVENT_LEFT_REPEATING:
      case NotificationType.EVENT_MULTIPLE_LEFT:
      case NotificationType.EVENT_QUEUE_LEFT:
      case NotificationType.EVENT_MULTIPLE_QUEUE_LEFT:
      case NotificationType.EVENT_CHANGE_REPEATING:
      case NotificationType.EVENT_KICK:
      case NotificationType.EVENT_QUEUE_KICK:
        _navigateToEvent(item);
        break;
      case NotificationType.ORG_JOIN:
      case NotificationType.ORG_LEFT:
      case NotificationType.ORG_ROLE_CHANGE:
      case NotificationType.ORG_KICK:
        _navigateToOrg(item);
        break;
      case NotificationType.FEED_CREATE:
      case NotificationType.FEED_UPDATE:
        _navigateToFeed(item);
        break;
      default:
        break;
    }
  };

  const _navigateToFeed = (item: Notification) => {
    if (item.feed) {
      nav(ROUTE_FEED_INFO, {
        state: {
          feedId: item.feed.id,
        },
      });
    }
  };
  const _navigateToFeedback = (item: Notification) => {
    if (item.eventId && item.event?.isAclitoEvent) {
      nav(ROUTE_FEEDBACK);
    }
  };

  const _navigateToEvent = async (item: Notification) => {
    if (item.event) {
      nav(
        item.event.isAclitoEvent
          ? ROUTE_EVENT_INFO
          : ROUTE_EVENT_INFO_PROVIDERS,
        {
          state: {
            id: item.event.id,
          },
        },
      );
    }
  };

  const _navigateToOrg = async (item: Notification) => {
    if (item.org) {
      nav(ROUTE_ORGANIZATIONS, {
        state: {
          id: item.org.id,
        },
      });
    }
  };

  const handleRefresh = () => {
    dispatch(notificationActions.loadNotificationsAsync());
  };

  return { handleRefresh, getOnClick };
};
