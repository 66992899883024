import Icon, { Color } from 'identicon.js';
import { stringHashCode } from '@aclito/shared/util/stringHashCode';
import { IDENTICON_BACKGROUND } from '@aclito/shared/util/constants';
import { Organization } from '@aclito/entities';
import React from 'react';

import S3Image from '../S3Image';

const OrgAvatar = ({
  org,
  background,
  style,
}: {
  org: Organization;
  background?: [number, number, number, number];
  style?: React.CSSProperties;
}) => {
  const data = new Icon(stringHashCode(org.name).toString(), {
    background: background || (IDENTICON_BACKGROUND as Color),
    format: 'png',
  });

  const picType = org.image?.slice(0, 5) ?? '';

  return !org.image || picType === 'BASIC' ? (
    <img src={`data:image/png;base64, ${data}`} style={style} />
  ) : (
    <S3Image
      imageURL={`${org.image.slice(5)}?ts=${org.updatedAt}`}
      style={style}
    />
  );
};

export default OrgAvatar;
