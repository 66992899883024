import React from 'react';
import { cardColorPicker } from '@aclito/shared/util/cardColorPicker';
import { formatEventDuration } from '@aclito/shared/features/event/utils/formatEventDuration';
import { FormattedEvent } from '@aclito/shared/types';
import { useTheme } from 'styled-components';
import { externalColorPicker } from '@aclito/shared/util/externalColorPicker';
import { useTranslateDate } from '@aclito/shared/hooks';
import { Indicator } from '@mantine/core';
import { useShowCardBadge } from '@aclito/shared/features/event/hooks/useShowCardBadge';

import PlayersMembersNumber from '../PlayersMembersNumber';

import EventTags from './EventTags';

import {
  StyledText,
  CardTitle,
  GroupedContent,
  PlainCard,
  HStack,
  VStack,
  Icon,
} from '@/components';
import { ROUTE_EVENT_INFO, ROUTE_EVENT_INFO_PROVIDERS } from '@/util/constants';
import { useNavigate } from '@/hooks/useNavigate';
import { ParamList } from '@/util/types';
import { Storage } from '@/util/storage';

interface CardProps {
  event: FormattedEvent;
  onClick?: (event: FormattedEvent) => void;
}

const EventCard: React.FC<CardProps> = ({ event, onClick }) => {
  const nav = useNavigate();
  const tDate = useTranslateDate();
  const date = tDate(event.date, 'date.card');
  const duration = formatEventDuration(event);
  const theme = useTheme();
  const isAclitoEvent = event.isAclitoEvent ?? true;

  const tintColor: string = event.activityType
    ? cardColorPicker(event.activityType)
    : externalColorPicker(event.provider, theme);

  const navigationKey: keyof ParamList = isAclitoEvent
    ? ROUTE_EVENT_INFO
    : ROUTE_EVENT_INFO_PROVIDERS;

  const navigate = () => {
    nav(navigationKey, { state: { id: event.id } });
  };

  const handleOnClick = onClick ? () => onClick(event) : navigate;

  const { showBadge } = useShowCardBadge(Storage, event);

  return (
    <Indicator disabled={!showBadge} color={theme.red} size={13}>
      <PlainCard
        $borderColor={tintColor}
        onClick={handleOnClick}
        style={{ height: '100%' }}
        data-testid={event.name}
      >
        <VStack
          spacing={16}
          style={{
            height: '100%',
          }}
        >
          <GroupedContent noWrap spacing={8}>
            <HStack noWrap style={{ justifyContent: 'space-between', flex: 1 }}>
              <CardTitle lineClamp={1} text={event.name} />
              {isAclitoEvent && (
                <PlayersMembersNumber
                  textSize={14}
                  withIcon
                  iconSize={16}
                  event={event}
                />
              )}
            </HStack>
          </GroupedContent>

          <VStack spacing={8}>
            <HStack noWrap spacing={10}>
              <Icon icon="time" size={16} color="grey30" />
              <StyledText>{event.formattedTime}</StyledText>
              <StyledText
                style={{
                  fontWeight: 'bold',
                  color: theme.grey50,
                }}
              >
                ({duration})
              </StyledText>
            </HStack>
            <HStack noWrap spacing={10}>
              <Icon icon="calendar" size={16} color="grey30" />
              <StyledText>{date}</StyledText>
            </HStack>
            {event.place && (
              <HStack noWrap spacing={10}>
                {event.place ? (
                  <>
                    <Icon icon="place" size={16} color="grey30" />
                    <StyledText lineClamp={1}>{event.place.name}</StyledText>
                  </>
                ) : (
                  <div>&nbsp;</div>
                )}
              </HStack>
            )}
          </VStack>
          <EventTags event={event} />
        </VStack>
      </PlainCard>
    </Indicator>
  );
};

export default EventCard;
