/* eslint-disable no-restricted-syntax */
import {
  Location,
  useLocation as RRDUseLocation,
  useSearchParams,
} from 'react-router-dom';

export interface TypedLocation<T, K = {}>
  extends Partial<Omit<Location, 'state'>> {
  state?: T;
  query?: K;
}
export const useLocation = <T, K = {}>(): TypedLocation<T, K> => {
  const { state, ...location } = RRDUseLocation();
  const [params] = useSearchParams();
  const paramObject = {} as K;
  params.forEach((value, key) => {
    paramObject[key as keyof K] = value as K[keyof K];
  });
  const typedState = state as T;

  return { state: typedState, query: paramObject, ...location };
};
