import { Organization } from '@aclito/entities';

import { SelectObject } from '../types';

export const getOrgForTrainer = (
  formOrg: SelectObject | undefined,
  displayedOrg: Organization | undefined,
  orgsInState: Organization[],
) => {
  if (formOrg?.id === displayedOrg?.id) {
    return displayedOrg;
  }
  return orgsInState.find((o) => formOrg?.id === o.id);
};
