import { EventRaw } from '@aclito/entities';

import { ActivitiesKey, txFn } from '@aclito/shared/types';
import { config } from '@aclito/shared/config';
import { getByIdTranslate } from '@aclito/shared/util/selectObject';
import { translateDateFormat } from '@aclito/shared/util/translateDateFormat';

export const formatEvents = <T extends EventRaw>(events: T[], t?: txFn) => {
  return events?.map((e) => _formatFn(e, t));
};

export const formatEvent = <T extends EventRaw>(e: T, t?: txFn) => {
  return _formatFn(e, t);
};

const _formatFn = <T extends EventRaw>(e: T, t?: txFn) => {
  return {
    ...e,
    formattedDate: translateDateFormat(e.date, 'date.day.month'),
    formattedTime: translateDateFormat(e.date, 'time.display'),
    formattedEndDate: translateDateFormat(e.endDate, 'date.display'),
    activityType: e.activityType as ActivitiesKey,
    formattedEndTime: translateDateFormat(e.endDate, 'time.display'),
    ...(t && {
      txActivity: getByIdTranslate(e.activityType, config.activities, t).item,
    }),
    ...(t && {
      txLevel: getByIdTranslate(e.minLevel.toString(), config.levels, t).item,
    }),
    ...(t && {
      txGender: getByIdTranslate(e.gender, config.gender.filter, t).item,
    }),
  };
};
