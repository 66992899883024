import { Grid } from '@mantine/core';
import React from 'react';
import { useAppSelector, useTranslateDate } from '@aclito/shared/hooks';
import { commonSelectors } from '@aclito/shared/redux/slices/commonSlices';
import { cardColorPicker } from '@aclito/shared/util/cardColorPicker';
import { ActivitiesKey } from '@aclito/shared/types';
import moment from 'moment';
import { useTheme } from 'styled-components';
import { Availability } from '@aclito/entities';
import { config } from '@aclito/shared/config';

import GridColumn from './GridColumn';
import AvailabilityTags from './AvailabilityTags';

import {
  HStack,
  VStack,
  Icon,
  PlainCard,
  StyledText,
  TimeText,
} from '@/components';
import { ROUTE_AVAILABILITY_INFO } from '@/util/constants';
import { useNavigate } from '@/hooks/useNavigate';

interface AvailabilitiesCardsProps {
  availabilities: Availability[];
}

interface CardProps {
  availability: Availability;
}

const AvailabilitiesCards: React.FC<AvailabilitiesCardsProps> = ({
  availabilities,
}) => (
  <Grid gutter="sm">
    {availabilities?.map((availability) => (
      <GridColumn key={availability.id} fullWidth={false}>
        <Card {...{ availability }} />
      </GridColumn>
    ))}
  </Grid>
);

const Card: React.FC<CardProps> = ({ availability }) => {
  const nav = useNavigate();
  const theme = useTheme();
  const tDate = useTranslateDate();
  const locale = useAppSelector(commonSelectors.locale);
  const timeFrom = tDate(availability.from, 'time.display');
  const timeTo = tDate(availability.to, 'time.display');

  moment.locale(locale);
  const date = tDate(availability.from, 'date.card');

  const color = availability.activities?.length
    ? cardColorPicker(availability.activities[0] as ActivitiesKey)
    : theme.primary;

  const activities = availability.activities?.length
    ? availability.activities
    : ([config.activities.any.id] as ActivitiesKey[]);

  const navigate = () =>
    nav(ROUTE_AVAILABILITY_INFO, {
      state: {
        availability,
      },
    });
  return (
    <PlainCard
      $borderColor={color}
      onClick={navigate}
      style={{ height: '100%' }}
    >
      <VStack spacing={7}>
        <HStack noWrap spacing={10}>
          <TimeText>{`${timeFrom} - ${timeTo}`}</TimeText>
        </HStack>
        <HStack noWrap style={{ alignItems: 'center' }} spacing={10}>
          <Icon icon={'calendar'} size={16} color="grey30" />
          <StyledText text={date} />
        </HStack>
        <AvailabilityTags {...{ activities }} />
      </VStack>
    </PlainCard>
  );
};

export default AvailabilitiesCards;
