import { useAppSelector } from '@aclito/shared/hooks';
import { eventActions } from '@aclito/shared/redux/slices/eventSlices';
import { useState } from 'react';
import { EventFilters } from '@aclito/shared/redux/types';
import { useDisclosure } from '@mantine/hooks';
import { useAppDispatch } from '@aclito/shared/hooks/useDispatch';
import {
  useLazySearchEventsQuery,
  useSearchEventsQuery,
} from '@aclito/shared/redux/api/eventApi';
import {
  commonActions,
  commonSelectors,
} from '@aclito/shared/redux/slices/commonSlices';

import { SearchTabType } from '@/features/search/types';

export const useSearch = (filters: EventFilters) => {
  const isSearchOpen = useAppSelector(commonSelectors.isSearchOpen);
  const [searchValue, setSearchValue] = useState(filters.query ?? '');
  const [day, { toggle }] = useDisclosure(true);

  const dispatch = useAppDispatch();

  const [trigger] = useLazySearchEventsQuery();
  const { data, isLoading } = useSearchEventsQuery({
    ...filters,
    nextToken: null,
  });

  const events = data?.data ?? [];

  const handleShowMore = () => {
    trigger({
      ...filters,
      nextToken: data?.nextToken ?? null,
    });
  };

  const handleSearchBar = (q: string) => {
    setSearchValue(q);
    dispatch(eventActions.applyFilters({ ...filters, query: q }));
  };

  const onFilterChange = (f: EventFilters) => {
    dispatch(eventActions.applyFilters({ ...f, query: searchValue }));
  };

  const onClearFilter = () => {
    dispatch(eventActions.clearFilter());
    dispatch(commonActions.setIsSearchOpen(false));
  };

  const filterProps = {
    onFilterChange,
    onClearFilter,
  };

  const [tabIndex, setTabIndex] = useState(SearchTabType.LIST);

  const handleTabChange = (val: string) =>
    val === '0'
      ? setTabIndex(SearchTabType.LIST)
      : setTabIndex(SearchTabType.MAP);

  const [isOpen, handler] = useDisclosure(filters.isDirty || isSearchOpen, {
    onOpen() {
      dispatch(commonActions.setIsSearchOpen(true));
    },
    onClose() {
      dispatch(commonActions.setIsSearchOpen(false));
    },
  });

  return {
    filterProps,
    isOpen,
    handler,
    toggle,
    day,
    events,
    searchValue,
    handleShowMore,
    nextToken: data?.nextToken,
    isLoading,
    handleSearchBar,
    tabIndex,
    handleTabChange,
  };
};
