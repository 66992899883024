import { Card } from '@mantine/core';
import styled from 'styled-components';

export const StyledPlayerCard = styled(Card)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.grey10};
  margin: 0;
  padding: 20px 20px;
` as typeof Card;
