import styled from 'styled-components';
import { Center, Loader, Space } from '@mantine/core';
import React from 'react';
import { useAppSelector } from '@aclito/shared/hooks';
import { profileSelectors } from '@aclito/shared/redux/slices/profileSlices';
import { deviceMax, deviceMin } from '@aclito/ui-web/utils/mediaQueries';

import { OrganizationListingProps, OrgsTabType } from '../interface';

import {
  HStack,
  Tab,
  Tabs,
  TitleBar,
  ActionButton,
  SearchBar,
  Title,
  ShowMoreButton,
} from '@/components';
import { ROUTE_NEW_ORGANIZATION } from '@/util/constants';
import OrganizationCard from '@/components/Cards/OrganizationCard';

const OrganizationListing: React.FC<OrganizationListingProps> = ({
  selected,
  setSelected,
  navigation,
  tabIndex,
  search,
  setTabIndex,
  handleSearch,
  resetSearch,
  handleShowMore,
  nextToken,
  getSelectedOrgs,
  loading,
  toggle,
}) => {
  const userInfo = useAppSelector(profileSelectors.userInfo);

  const handleNavigation = () => {
    navigation(ROUTE_NEW_ORGANIZATION);
  };

  const handleTabChange = (val: string) =>
    val === '0'
      ? setTabIndex(OrgsTabType.MYORGS)
      : setTabIndex(OrgsTabType.ALLORGS);

  return (
    <ListingSection>
      <TitleBar>
        <HStackTop>
          <HStack>
            <Title tx={'org.title'} />
          </HStack>
          <HStack>
            <ActionButton
              iconType={'add'}
              data-testid="add-organnization"
              primary
              onClick={handleNavigation}
            />
            <SlideMenu iconType="leftDrop" onClick={toggle} />
          </HStack>
        </HStackTop>
      </TitleBar>
      <Tabs
        selectedIndex={tabIndex === OrgsTabType.MYORGS ? '0' : '1'}
        setSelectedIndex={handleTabChange}
      >
        <Tab tx="tabs.myOrg" />
        <Tab tx="tabs.all" />
      </Tabs>

      <Orgs>
        <SearchBar
          mt={8}
          onClear={resetSearch}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleSearch(e.target.value)
          }
          value={search}
        />
        {loading ? (
          <Center>
            <Loader />
          </Center>
        ) : (
          <>
            {getSelectedOrgs.map((o) => (
              <OrganizationCard
                key={o.id}
                organization={o}
                userInfo={userInfo}
                selected={selected}
                setSelected={setSelected}
              />
            ))}
            <Space h={24} />
            <Center>
              {nextToken && <ShowMoreButton onClick={handleShowMore} />}
            </Center>
          </>
        )}
      </Orgs>
    </ListingSection>
  );
};

const ListingSection = styled.div`
  height: 100%;
  width: 100%;
`;

// Custom media queries to handle element wrapping movements
const Orgs = styled.div`
  display: flex;
  flex-direction: column;

  & > :not(:last-child) {
    margin-bottom: 10px;
  }
  height: calc(100vh - 13rem);
  @media (${deviceMax.laptop}) {
    height: calc(100vh - 13rem);
  }
  @media (${deviceMax.tablet}) {
    height: calc(100vh - 16rem);
  }
  @media (max-width: 308px) {
    height: calc(100vh - 21rem);
  }
  overflow-y: auto;
`;

const HStackTop = styled(HStack)`
  width: 100%;
  justify-content: space-between;
`;

const SlideMenu = styled(ActionButton)`
  @media ${deviceMin.laptop} {
    display: none;
  }
`;

export default OrganizationListing;
