import { useAppSelector } from '@aclito/shared/hooks';
import { notificationSelectors } from '@aclito/shared/redux/slices/notificationsSlices';
import { useTheme } from 'styled-components';

import { useLocation } from '@/hooks';
import {
  ROUTE_AVAILABILITY,
  ROUTE_EVENTS,
  ROUTE_SOCIAL,
  ROUTE_NOTES,
  ROUTE_NOTIFICATIONS,
  ROUTE_ORGANIZATIONS,
  ROUTE_PROFILE,
  ROUTE_SEARCH,
  ROUTE_SETTINGS,
  ROUTE_USER_LOCATION,
  ROUTE_FEEDS,
  ROUTE_GOALS,
  ROUTE_FEEDBACK,
} from '@/util/constants';
import {
  activeMenuStyle,
  menuStyle,
} from '@/components/Drawer/components/DrawerStyles';

export const useDrawer = () => {
  const notifications = useAppSelector(notificationSelectors.getNotification);

  const count = notifications.length;
  const label = count > 9 ? '9+' : count;

  const location = useLocation();
  const theme = useTheme();

  const matchers = {
    isNotifications: location.pathname === ROUTE_NOTIFICATIONS,
    isEvents: location.pathname === ROUTE_EVENTS,
    isFeeds: location.pathname === ROUTE_FEEDS,
    isSearch: location.pathname === ROUTE_SEARCH,
    isOrganizations: location.pathname === ROUTE_ORGANIZATIONS,
    isProfile: location.pathname === ROUTE_PROFILE,
    isAvailability: location.pathname === ROUTE_AVAILABILITY,
    isLocation: location.pathname === ROUTE_USER_LOCATION,
    isSettings: location.pathname === ROUTE_SETTINGS,
    isSocial: location.pathname === ROUTE_SOCIAL,
    isNote: location.pathname === ROUTE_NOTES,
    isGoals: location.pathname === ROUTE_GOALS,
    isFeedback: location.pathname === ROUTE_FEEDBACK,
  };

  const getSxStyle = (isActive: boolean) => [
    menuStyle(theme),
    ...(isActive ? [activeMenuStyle(theme)] : []),
  ];

  return { count, label, getSxStyle, matchers };
};
