import styled from 'styled-components';

const Background = () => (
  <StyledBackground>
    <BackgroundShape />
  </StyledBackground>
);
const StyledBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  clip-path: inset(0 0 0 0 round 5px);
`;

const BackgroundShape = styled.div`
  transform: rotate(45deg);
  position: absolute;
  height: 520px;
  width: 520px;
  background: ${({ theme }) => theme.background};
  top: -50px;
  right: 120px;
  border-radius: 0 72px 72px 0;
`;
export default Background;
