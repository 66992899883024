import { Auth } from '@aws-amplify/auth';
import { ISignUpResult } from 'amazon-cognito-identity-js';

import { ApiSignUp } from '../../types/authentication';
import { errorObject } from '../../utils/errorObject';

/**
 *
 * @param body The user attributes and credentials
 * @returns A promise of created user if success
 */
export const makeSignUp = async (body: ApiSignUp): Promise<ISignUpResult> => {
  try {
    return await Auth.signUp({
      username: body.username,
      password: body.password,
    });
  } catch (error) {
    //@ts-ignore
    return Promise.reject(errorObject[error.code]);
  }
};
