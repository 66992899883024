import { useForm } from 'react-hook-form';

import { useAppSelector } from '@aclito/shared/hooks/useSelector';
import {
  organizationsActions,
  organizationsSelectors,
} from '@aclito/shared/redux/slices/organizationSlices';
import { profileSelectors } from '@aclito/shared/redux/slices/profileSlices';
import { FeedFilters } from '@aclito/shared/redux/types';
import { SelectObject } from '@aclito/shared/types';
import { getById } from '@aclito/shared/util/selectObject';
import { config } from '@aclito/shared/config';
import { useListFeedsByMyOrgsQuery } from '@aclito/shared/redux/api/feedApi';
import { useAppDispatch } from '@aclito/shared/hooks/useDispatch';
import { selectMyOrganizations } from '@aclito/shared/redux/api/organizationApi';

export const useFeedFilters = () => {
  const dispatch = useAppDispatch();
  const myOrgs = useAppSelector(selectMyOrganizations);
  const userInfo = useAppSelector(profileSelectors.userInfo);
  const feedFilter = useAppSelector(organizationsSelectors.getFeedFilter);

  useListFeedsByMyOrgsQuery(feedFilter.feedOrg.id, { skip: !myOrgs.length });

  const orgOptions: SelectObject[] = myOrgs.map((org) => ({
    id: org.id,
    item: org.name,
  }));

  const filterInitialValue: FeedFilters = {
    feedOrg: feedFilter.feedOrg
      ? feedFilter.feedOrg
      : getById('any', config.activities),
  };

  const methods = useForm<FeedFilters>({ defaultValues: filterInitialValue });

  const clearFilter = () => {
    dispatch(organizationsActions.clearFeedFilters());
    methods.reset();
  };

  const submitFilters = async (values: FeedFilters) => {
    if (!values.feedOrg || typeof values.feedOrg === 'string') {
      dispatch(
        organizationsActions.setFeedFilters({ feedOrg: { id: '', item: '' } }),
      );
    } else {
      dispatch(organizationsActions.setFeedFilters(values));
    }
  };

  return {
    orgOptions,
    clearFilter,
    userInfo,
    submitFilters,
    methods,
  };
};
