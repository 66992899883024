import { closeAllModals } from '@mantine/modals';
import type { UserInfo } from '@aclito/entities';
import { useSchema, useSelectObject } from '@aclito/shared/hooks';
import {
  EventWithPlayers,
  LocaleType,
  TranslateKey,
} from '@aclito/shared/types';
import { makeMessage } from '@aclito/shared/util/makeTranslatedMessage';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTheme } from 'styled-components';
import { useEventInfoKick } from '@aclito/shared/features/event/hooks/useEventInfoKick';
import { config } from '@aclito/shared/config';
import { getById } from '@aclito/shared/util/selectObject';

import {
  SmallButton,
  VStack,
  ValidatedSelect,
  ValidatedTextArea,
} from '@/components';

type KickFormType = {
  area: { id: string; item: string };
  otherText: string;
};

interface KickFormProps {
  event: EventWithPlayers;
  user: UserInfo;
}

export const KickForm = ({ event, user }: KickFormProps) => {
  const { kickUserValidationSchema } = useSchema();
  const { translatedKickReasonsTypes } = useSelectObject();
  const { kickUserFromEvent: kickShared } = useEventInfoKick();

  const methods = useForm<KickFormType>({
    defaultValues: { otherText: '', area: { item: '', id: '' } },
    //@ts-ignore
    resolver: yupResolver(kickUserValidationSchema),
  });

  const watchArea = methods.watch('area');

  const kickUserFromEvent = async (otherReason: string, reason: string) => {
    const { kick } = config;
    let value;
    if (reason === 'other') {
      value = otherReason.length
        ? otherReason
        : makeMessage(
            'json.notstated' as TranslateKey,
            user.preferredLanguage as LocaleType,
          );
    } else {
      value = makeMessage(
        getById(reason, kick).item as TranslateKey,
        user.preferredLanguage as LocaleType,
      );
    }
    kickShared(event, user, value);
    closeAllModals();
  };

  const onSubmit = () => {
    methods.handleSubmit((values) =>
      kickUserFromEvent(values.otherText, values.area.id),
    )();
  };

  const theme = useTheme();

  return (
    <FormProvider {...methods}>
      <VStack style={{ height: 300, justifyContent: 'space-between' }}>
        <VStack>
          <ValidatedSelect
            styles={{
              input: {
                backgroundColor: theme.background,
                color: theme.text,
              },
            }}
            options={translatedKickReasonsTypes}
            name="area"
            txPlaceholder="reason.message"
          />
          {watchArea?.id === 'other' && (
            <ValidatedTextArea
              styles={{
                input: { backgroundColor: theme.background },
              }}
              txPlaceholder="reason.text"
              name="otherText"
            />
          )}
        </VStack>

        <SmallButton onClick={onSubmit} bg="primary" tx="kick" />
      </VStack>
    </FormProvider>
  );
};
