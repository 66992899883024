import { EventRaw } from '@aclito/entities';

// Use virtual on BE - there might be some inconsistencies when shifting players around which results in inconsistent flags for certain scenarios
// Use by flag on FE - Assuming the flags come correct from BE it should result for fewer inconsistencies on FE

export const getPlayersVirtual = (event: EventRaw) =>
  event.players
    .filter((p) => p.willParticipate)
    .slice(
      0,
      event.maxPlayers === -1 ? event.players.length : event.maxPlayers,
    );

export const getPlayersByFlag = (event: EventRaw) =>
  event.players.filter((p) => p.willParticipate).filter((p) => !p.queueDate);

export const getQueuePlayersVirtual = (event: EventRaw) =>
  event.enableQueue
    ? event.players
        .filter((p) => p.willParticipate)
        .slice(event.maxPlayers, event.players.length)
    : [];

export const getQueuePlayersByFlag = (event: EventRaw) =>
  event.enableQueue
    ? event.players.filter((p) => p.willParticipate).filter((p) => p.queueDate)
    : [];
