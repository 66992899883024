import React from 'react';
import { useController } from 'react-hook-form';

import NumberInput from './NumberInput';
import { InputProps } from './types';

const ValidatedNumberInput: React.FC<InputProps & { name: string }> = ({
  placeholder,
  name,
  ...rest
}) => {
  const {
    field: { value, onChange, onBlur },
    fieldState: { error },
  } = useController({ name });
  return (
    <NumberInput
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      placeholder={placeholder}
      error={error?.message}
      // @ts-ignore
      invalid={error}
      autoComplete={'on'}
      {...rest}
    />
  );
};

export default ValidatedNumberInput;
