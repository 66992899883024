import { IconUrl } from '@aclito/shared/resources';
import styled from 'styled-components';
import { deviceMax } from '@aclito/ui-web/utils/mediaQueries';

import { Text } from '@/components';

interface LogoProps {
  text?: string;
  logoSize: string | number;
}

const Logo: React.FC<LogoProps> = (props) => (
  <>
    <StyledLogo logoSize={props.logoSize} src={IconUrl} />
    <BottomText text={props.text} />
  </>
);

const StyledLogo = styled.img<LogoProps>`
  width: ${(props) => props.logoSize}px;
  height: ${(props) => props.logoSize}px;
`;

const BottomText = styled(Text)`
  font-weight: 500;
  color: ${({ theme }) => theme.white};
  padding-bottom: 0;
  padding-left: 10px;
  @media ${deviceMax.tablet} {
    display: none;
  }
`;

export default Logo;
