import React from 'react';
import { FormattedEventWithPlayers } from '@aclito/shared/types';
import { useTranslate, useTranslateDate } from '@aclito/shared/hooks';
import { formatEventDuration } from '@aclito/shared/features/event/utils/formatEventDuration';
import { formatEvent } from '@aclito/shared/features/event/utils/formatEvents';

import InfoBuilder from './InfoBuilder';

import { Content } from '@/features/event/styles/eventInfoStyles';
import { openInNewTab } from '@/util/openLinkInNewTab';

interface InfoMidProps {
  event: FormattedEventWithPlayers;
  handleRedirectToOrg: (event: FormattedEventWithPlayers) => void;
  handleRedirectToLeader: (event: FormattedEventWithPlayers) => void;
  handleRedirectToMap: (event: FormattedEventWithPlayers) => void;
}

const Info: React.FC<InfoMidProps> = ({
  handleRedirectToLeader,
  handleRedirectToOrg,
  handleRedirectToMap,
  ...rest
}) => {
  const t = useTranslate();
  const tDate = useTranslateDate();
  const event = formatEvent(rest.event, t);
  const date = `${event.formattedDate} ${event.formattedTime} - ${event.formattedEndDate}`;
  const duration = formatEventDuration(event);

  const handleOrg = () => {
    handleRedirectToOrg(event);
  };

  const handleMap = () => {
    handleRedirectToMap(event);
  };

  const handleLeader = () => {
    handleRedirectToLeader(event);
  };

  return (
    <Content>
      <InfoBuilder
        data={[
          { text: { text: date }, icon: 'calendar', label: 'events.info.date' },
          {
            text: { text: `${duration}` },
            icon: 'time',
            label: 'events.info.duration',
          },
          {
            text: {
              text: `${event.leaderInfo.name} ${event.leaderInfo.surname}`,
              style: { textDecorationLine: 'underline' },
            },
            icon: 'person',
            label: 'events.info.leader',
            onPress: handleLeader,
          },
          {
            text: {
              text: event.place?.name || '',
              style: { textDecorationLine: 'underline' },
            },
            icon: 'map',
            label: 'events.info.place',
            onPress: handleMap,
          },
          {
            text: { text: event.txGender || '' },
            icon: 'gender',
            label: 'events.info.gender',
          },
          {
            text: { text: event.txLevel || '' },
            icon: 'level',
            label: 'events.info.level',
          },
          {
            text:
              event.price === 0
                ? { tx: 'events.info.forFree' }
                : { text: event.price.toString() },
            icon: 'price',
            label: 'events.info.price',
          },
          {
            text: { tx: event.canPayWithMultiSport ? 'yes' : 'no' },
            icon: 'multisport',
            label: 'events.info.multisport',
            visible: !!event.canPayWithMultiSport,
          },
          {
            text: {
              tx: 'events.info.external.link',
              style: { textDecorationLine: 'underline' },
            },
            icon: 'link',
            label: 'events.info.external.link',
            onPress: () => openInNewTab(event.externalLink ?? ''),
            visible: !!event.externalLink,
          },
          {
            text: {
              text: event?.org?.name || '',
              style: { textDecorationLine: 'underline' },
            },
            icon: 'person',
            label: 'events.info.org',
            onPress: handleOrg,
            visible: !!event.org,
          },
          {
            text: {
              text: tDate(event.confirmationDate ?? '', 'date.display'),
            },
            icon: 'time',
            label: 'events.info.confirm',
            visible: !!event.requireConfirmation,
          },
          {
            text: {
              text: event.info,
            },
            icon: 'edit',
            label: 'events.info.description',
            vertical: true,
            visible: !!event.info,
          },
        ]}
      />
    </Content>
  );
};

export default Info;
