import { useState } from 'react';
import { LocationInput } from '@aclito/entities';
import { useAppSelector } from '@aclito/shared/hooks';
import {
  locationActions,
  locationSelectors,
} from '@aclito/shared/redux/slices/locationSlice';
import { useAppDispatch } from '@aclito/shared/hooks/useDispatch';
import { UserLocation } from '@aclito/shared/types';

export const useCenterChange = (locationFilter: LocationInput | undefined) => {
  const dispatch = useAppDispatch();

  const searchMapLocation = useAppSelector(locationSelectors.searchMapLocation);
  const [center, setCenter] = useState(
    locationFilter
      ? {
          lat: locationFilter.lat,
          lon: locationFilter.lon,
          lng: locationFilter.lon,
          latitude: locationFilter.lat,
          longitude: locationFilter.lon,
        }
      : searchMapLocation,
  );

  const centerChanged = (region: LocationInput) => {
    if (region && region.lat) {
      setCenter(() => {
        const location: UserLocation = {
          lat: region.lat,
          lon: region.lon,
          lng: region.lon,
          latitude: region.lat,
          longitude: region.lon,
        };
        dispatch(locationActions.updateSearchMapLocations({ value: location }));
        return location;
      });
      return region;
    }
  };

  return { center, centerChanged };
};
