import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Center, Loader, Space } from '@mantine/core';
import {
  isClub,
  isOrganization,
  isTeam,
} from '@aclito/shared/features/organization/utils/getOrgTag';
import { useMediaQuery } from '@mantine/hooks';
import { deviceMax, deviceMin } from '@aclito/ui-web/utils/mediaQueries';
import { useOrganizationModel } from '@aclito/shared/hooks/useOrganizationModel';
import { closeAllModals } from '@mantine/modals';

import { OrganizationInfoProps } from '../Organizations/interface';
import FeedList from '../../components/FeedList';

import { useOrganizationInfoSection } from './hooks/useOrganizationInfoSection';
import { Buttons, OrgEvents } from './components';
import Description from './components/Description';
import JoinOrganizationButton from './components/JoinOrganizationButton';

import {
  TitleButtons,
  Collapsible,
  ActionButton,
  HStack,
  Tab,
  Tabs,
  Text,
  TitleBar,
  VStack,
  PlayersMembersNumber,
  Title,
  OrgAvatar,
} from '@/components';
import RoleBanner from '@/features/organization/screens/OrganizationInfo/components/RoleBanner';
import Members from '@/features/organization/screens/OrganizationInfo/components/Members';
import { CardStatusBannerText } from '@/common/CardStatusBanner';
import { useFlexWrap } from '@/hooks/useFlexWrap';
import { useModal } from '@/hooks/useModal';
import { useNotes } from '@/features/notes/screens/Notes/hooks/useNotes';

const OrganizationInfo: React.FC<OrganizationInfoProps> = ({
  selected,
  toggle,
}) => {
  const { data, isFetching, model } = useOrganizationModel(selected);
  const displayedOrg = data?.org;

  const { myRole } = useOrganizationInfoSection(displayedOrg);

  const [tabIndex, setTabIndex] = useState({
    index: '0',
  });

  const handleTabIndexChange = (index: string) => {
    setTabIndex({
      index,
    });
  };

  const { openModal } = useModal();
  const { contactFilled } = useNotes();

  useEffect(() => {
    if (
      model.organization &&
      model.organization.contact &&
      model.isMember &&
      !contactFilled
    ) {
      openModal({
        withCloseButton: true,
        size: 'auto',
        padding: 10,
        type: 'grantNotes',
        values: {
          handleGrant: () => Promise.resolve(closeAllModals()),
          orgName: displayedOrg.name,
        },
      });
    }
  }, []);

  const id = 'titleFlexContainer';
  const isFlexWrapped = useFlexWrap(id, [displayedOrg]);
  const queryMobile = useMediaQuery(deviceMax.mobile);

  return !isFetching ? (
    <>
      {displayedOrg && (
        <>
          <TitleBar id={id}>
            <TitleButtons>
              <HStack>
                <OrganizationsButton
                  onClick={toggle}
                  iconType={'leftDrop'}
                  tx="org.title"
                />
                <Title tx="org.info" />
              </HStack>
            </TitleButtons>
            <Buttons
              org={displayedOrg}
              compact={isFlexWrapped || queryMobile}
            />
          </TitleBar>

          <VStack spacing={16}>
            <HStack spacing={16} align={'center'}>
              <OrgAvatar org={displayedOrg} style={{ height: 64, width: 64 }} />
              <VStack spacing={4} style={{ flex: 1 }}>
                <HStack
                  spacing={8}
                  style={{
                    justifyContent: 'space-between',
                  }}
                >
                  <Text style={{ fontWeight: 500 }} size={20}>
                    {displayedOrg.name}
                  </Text>
                  <PlayersMembersNumber
                    textSize={20}
                    withIcon
                    organization={displayedOrg}
                  />
                </HStack>
                <HStack spacing={4}>
                  <RoleBanner myRole={myRole} />
                  {isClub(displayedOrg) && (
                    <CardStatusBannerText type={'orgTypeClub'} />
                  )}
                  {isTeam(displayedOrg) && (
                    <CardStatusBannerText type={'orgTypeTeam'} />
                  )}
                  {isOrganization(displayedOrg) && (
                    <CardStatusBannerText type={'orgTypeOrganization'} />
                  )}
                </HStack>
              </VStack>
            </HStack>
            <JoinOrganizationButton
              org={displayedOrg}
              canJoin={model.canJoin}
              canControl={model.canControl}
              canShareOrg={model.canShareOrg}
            />
          </VStack>
          <Space h={24} />
          <Tabs
            selectedIndex={tabIndex.index}
            setSelectedIndex={handleTabIndexChange}
            data-testid="org-list"
          >
            <Tab tx="org.info" data-testid="org-info" />
            <Tab tx="org.events" data-testid="org-events" />
            <Tab tx="nav.feed" data-testid="org-comments" />
          </Tabs>
          <Space h={16} />
          {tabIndex.index === '0' && (
            <>
              <Collapsible buttonLabelTx="org.description" defaultOpen>
                <Description
                  displayedOrg={displayedOrg}
                  data-testid="org-info"
                />
              </Collapsible>
              <Space h={8} />
              <Collapsible buttonLabelTx="org.members" defaultOpen>
                <Members displayedOrg={displayedOrg} />
              </Collapsible>
            </>
          )}

          {tabIndex.index === '1' && (
            <OrgEvents data-testid="org-events" displayedOrg={displayedOrg} />
          )}
          {tabIndex.index === '2' && (
            <FeedList data-testid="org-comments" orgId={displayedOrg.id} />
          )}
        </>
      )}
    </>
  ) : (
    <CenterLoader>
      <Loader />
    </CenterLoader>
  );
};

const CenterLoader = styled(Center)`
  width: 100%;
`;

const OrganizationsButton = styled(ActionButton)`
  @media ${deviceMin.laptop} {
    display: none;
  }
`;

export default OrganizationInfo;
