import styled from 'styled-components';
import { isMobile, isAndroid, isIOS } from 'react-device-detect';
import { config } from '@aclito/shared/config';
import { useDisclosure } from '@mantine/hooks';

import { SmallButton, Icon } from '@/components';
import { openInNewTab } from '@/util/openLinkInNewTab';

const MobileBanner = () => {
  const [bannerVisible, { toggle }] = useDisclosure(true);

  const handleDownload = () => {
    if (isIOS) {
      openInNewTab(config.URL.appleStoreURL);
    } else if (isAndroid) {
      openInNewTab(config.URL.googleStoreURL);
    }
  };

  return (
    <>
      {isMobile && bannerVisible && (
        <Banner>
          <Container>
            <SmallButton
              bg={'primary'}
              tx={'aclito.share.title'}
              leftIcon={<Icon icon={'download'} color={'whiteText'} />}
              onClick={handleDownload}
            />
            <Icon icon={'close'} color={'grey80'} onClick={toggle} />
          </Container>
        </Banner>
      )}
    </>
  );
};

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Banner = styled.div`
  padding: 16px;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  background: ${({ theme }) => theme.white};
  color: ${({ theme }) => theme.white};
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
`;

export default MobileBanner;
