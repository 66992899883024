import { eventActions } from '@aclito/shared/redux/slices/eventSlices';
import { M_TO_KM_CONVERSION } from '@aclito/shared/util/constants';
import { useAppDispatch } from '@aclito/shared/hooks/useDispatch';
import { EventFilters } from '@aclito/shared/redux/types';
import { useSearchParams } from 'react-router-dom';

import { MarkedMapProps } from '@/features/search/types';

export const useSearchMap = (filters: EventFilters) => {
  const dispatch = useAppDispatch();
  const [, setQueryParams] = useSearchParams();
  const findEventsOnMap: MarkedMapProps['onCenterChange'] = ({
    radius,
    ...region
  }) => {
    if (Object.keys(region).length) {
      setQueryParams((prev) => {
        prev.set('lon', region.lon.toString());
        prev.set('lat', region.lat.toString());
        return prev.toString();
      });

      dispatch(
        eventActions.applyFilters({
          ...filters,
          distance: (radius / M_TO_KM_CONVERSION).toString(),
          location: region,
        }),
      );
    }
  };

  return { findEventsOnMap, showCircle: !!filters.distance };
};
