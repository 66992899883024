/* eslint-disable react/display-name */
import { forwardRef } from 'react';
import { NumberInput as MantineInput } from '@mantine/core';
import { useTranslate } from '@aclito/shared/hooks';
import { useTheme } from 'styled-components';

import { getCommonInputLabelStyle } from '../../utils/styles';

import { InputProps } from './types';

const NumberInput = forwardRef<HTMLInputElement, InputProps>(
  ({ label, placeholder, txLabel, txPlaceholder, ...props }, ref) => {
    const t = useTranslate();
    const theme = useTheme();
    const i18nLabel = txLabel && t(txLabel);
    const labelContent = i18nLabel || label;

    const i18nPlaceholder = txPlaceholder && t(txPlaceholder);
    const placeholderContent = i18nPlaceholder || placeholder;

    const computeValue = (value: InputProps['value']) => {
      if (!value) {
        return '';
      }
      if (typeof props.value === 'string') {
        return parseInt(props.value);
      }
      return value as number | '' | undefined;
    };

    return (
      // @ts-ignore
      <MantineInput
        // @ts-ignore
        ref={ref}
        variant="filled"
        rightSection={<></>}
        styles={{
          input: { backgroundColor: theme.white, color: theme.grey80 },
          label: { ...getCommonInputLabelStyle(theme) },
        }}
        size="md"
        radius={10}
        label={labelContent}
        placeholder={placeholderContent}
        {...props}
        value={computeValue(props.value)}
      />
    );
  },
);
export default NumberInput;
