import { useEffect, useMemo, useState } from 'react';
import { eventClient, organizationClient } from '@aclito/client';
import { Organization } from '@aclito/entities';

import { FormattedEvent, ShareType, TranslateKey } from '../../../types';

import { formatEvent } from '@aclito/shared/features/event/utils/formatEvents';
import { useTranslate } from '@aclito/shared/hooks';

export interface useShareProps {
  id: string | undefined;
  type: ShareType | undefined;
}

export const useShare = (data: useShareProps) => {
  const { id, type } = data;
  const [org, setOrg] = useState<Organization | null>();
  const [event, setEvent] = useState<FormattedEvent | null>();
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState('');
  const t = useTranslate();

  const displayEvent = (eventId: string) => {
    setIsLoading(true);
    eventClient.events
      .getEvent(eventId)
      .then((e) => {
        setEvent(formatEvent(e.data));
        setName(e.data.name);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
        setError(
          t('share.error' as TranslateKey, {
            type: t(`share.${type}` as TranslateKey),
          }),
        );
      });
  };
  const displayOrg = (orgId: string) => {
    setIsLoading(true);
    organizationClient.organizations
      .getOrganization({ orgId: orgId })
      .then((o) => {
        setIsLoading(false);
        setOrg(o.data.org);
        setName(o?.data.org.name ?? '');
      })
      .catch(() => {
        setIsLoading(false);
        setError(
          t('share.error' as TranslateKey, {
            type: t(`share.${type}` as TranslateKey),
          }),
        );
      });
  };

  useEffect(() => {
    if (org || event) {
      setOrg(undefined);
      setEvent(undefined);
    }
    if (type && id) shareActions[type].display(id);
  }, [type, id]);

  const shareActions = useMemo(
    () => ({
      org: {
        display: displayOrg,
      },
      event: {
        display: displayEvent,
      },
    }),
    [org, event],
  );

  return {
    name,
    org,
    event,
    error,
    isLoading,
  };
};
