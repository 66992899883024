import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';

import { REDUX_RESET } from '../../util/constants';
import { LocationInitialState } from '../initialStates';
import { AppState } from '../store';
import { LocationServices, UserLocation } from '../../types';
import { addLocationAsync, loadUserInfoAsync } from '../thunks/profileThunks';

export const REDUX_LOCATION = 'location';

type Key = 'searchMapLocation' | 'placesMapLocation';
const SliceLocation = createSlice({
  name: REDUX_LOCATION,
  initialState: LocationInitialState,
  reducers: {
    [REDUX_RESET + REDUX_LOCATION]: () => LocationInitialState,
    reset: (state, action: PayloadAction<Key[]>) => {
      action.payload.forEach((e) => {
        state[e] = undefined;
      });
    },

    setLocationService: (state, action: PayloadAction<LocationServices>) => {
      state.locationService = action.payload;
    },
    setLiveLocation: (
      state,
      action: PayloadAction<UserLocation | undefined>,
    ) => {
      state.liveLocation = action.payload;
    },

    updateUserLocation: (
      state,
      action: PayloadAction<UserLocation | undefined>,
    ) => {
      state.userLocation = action.payload;
    },

    updatePlacesLocation: (state, action: PayloadAction<UserLocation>) => {
      const coords = action.payload;
      state.placesMapLocation = {
        lat: coords.latitude,
        latitude: coords.latitude,
        lng: coords.longitude,
        lon: coords.longitude,
        longitude: coords.longitude,
      };
    },

    updateSearchMapLocations: (
      state,
      action: PayloadAction<{
        value: { lat: number; lon: number } | undefined;
      }>,
    ) => {
      const value = action.payload.value;
      if (!value) {
        state.searchMapLocation = undefined;
      } else {
        const { lat, lon } = value;
        state.searchMapLocation = {
          lat: lat,
          lng: lon,
          latitude: lat,
          lon: lon,
          longitude: lon,
        };
      }
    },
  },

  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(loadUserInfoAsync.fulfilled),
      (state, payload) => {
        if (payload.payload.user.location) {
          state.userLocation = {
            lon: payload.payload.user.location.lon,
            latitude: payload.payload.user.location.lat,
            lng: payload.payload.user.location.lon,
            longitude: payload.payload.user.location.lon,
            lat: payload.payload.user.location.lat,
          };
        }
      },
    );
    builder.addMatcher(
      isAnyOf(addLocationAsync.fulfilled),
      (state, payload) => {
        if (payload.payload.location) {
          state.userLocation = {
            lon: payload.payload.location.lon,
            latitude: payload.payload.location.lat,
            lng: payload.payload.location.lon,
            longitude: payload.payload.location.lon,
            lat: payload.payload.location.lat,
          };
        }
      },
    );
  },
});

export const locationActions = {
  ...SliceLocation.actions,
};
export const locationReducers = {
  [REDUX_LOCATION]: SliceLocation.reducer,
};

export const locationSelectors = {
  defaultLocation: (state: AppState) => state.location.defaultLocation,
  delta: (state: AppState) => state.location.delta,
  locationService: (state: AppState) => state.location.locationService,
  liveLocation: (state: AppState) => state.location.liveLocation,
  userLocation: (state: AppState) =>
    state.location.userLocation || state.location.defaultLocation,
  searchMapLocation: (state: AppState) =>
    state.location.searchMapLocation ||
    state.location.liveLocation ||
    state.location.userLocation ||
    state.location.defaultLocation,
  placesMapLocation: (state: AppState) =>
    state.location.placesMapLocation ||
    state.location.liveLocation ||
    state.location.userLocation ||
    state.location.defaultLocation,
};
