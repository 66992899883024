import React, { cloneElement, HTMLAttributes } from 'react';
import { ThemeColorsKeys } from '@aclito/shared/theme/colors';
import { useTheme } from 'styled-components';
import type { AllIcons } from '@aclito/shared/types';
import { IconBaseProps } from 'react-icons';

import { iconRegistry } from './Icon';
import { iconSelectRegistry } from './IconSelect';

export interface AllIconsProps extends HTMLAttributes<HTMLDivElement> {
  icon: AllIcons;
  containerStyle?: React.CSSProperties;
  color?: ThemeColorsKeys;
  size?: '8' | '16' | '20' | '24' | '32' | '48' | number;
  originalColor?: string;
  iconProps?: IconBaseProps;
}

const AllIcons: React.FC<AllIconsProps> = ({
  icon,
  color,
  size,
  containerStyle,
  originalColor,
  iconProps,
  ...wrapperProps
}) => {
  const theme = useTheme();
  const overrideColor = color ? theme[color] : theme.grey80;

  const overrideSize = size
    ? typeof size === 'string'
      ? parseInt(size, 10)
      : size
    : 24;

  const allIconsRegistry = {
    ...iconRegistry,
    ...iconSelectRegistry,
  };

  const i = allIconsRegistry[icon] as React.ReactElement;

  const el = cloneElement(i ?? <div />, {
    color: originalColor || overrideColor,
    size: overrideSize,
    ...iconProps,
    style: {
      margin: 'auto',
      display: 'block',
    },
  });

  return (
    <div style={{ ...containerStyle }} {...wrapperProps}>
      {el}
    </div>
  );
};

export default AllIcons;
