import { EventModel } from '@aclito/shared/classes/EventModel';
import React from 'react';
import styled from 'styled-components';
import { FigureFollow } from '@aclito/shared/resources';
import { useChatHandlers } from '@aclito/shared/features/event/hooks/useChatHandlers';

import { Text, VStack } from '@/components';

interface ChatPlaceholderrProps {
  model: EventModel;
}

const ChatPlaceholder: React.FC<ChatPlaceholderrProps> = ({ model }) => {
  const { txChatPlaceholderText } = useChatHandlers(model);

  return !model.isJoinedInChat ? (
    <Container align="center">
      <Text tx={txChatPlaceholderText} />
      <EmptyListingImage src={FigureFollow} />
    </Container>
  ) : null;
};

const Container = styled(VStack)`
  margin-top: 60px;
`;

const EmptyListingImage = styled.img`
  height: 35%;
`;

export default ChatPlaceholder;
