import { useDisclosure } from '@mantine/hooks';
import { useEffect } from 'react';
import styled from 'styled-components';
import { Organization } from '@aclito/entities';
import { FigureShrugging } from '@aclito/shared/resources';
import { useSearchParams } from 'react-router-dom';
import { deviceMin } from '@aclito/ui-web/utils/mediaQueries';

import OrganizationInfo from '../OrganizationInfo/OrganizationInfo';

import OrganizationListing from './components/OrganizationListing';
import { useOrganizations } from './hooks/useOrganizations';
import { OrganizationListingProps, OrgsTabType } from './interface';

import {
  Overlay,
  LeftSectionLarge,
  LeftSectionSmall,
  SectionGrid,
  ActionButton,
  Text,
  VStack,
} from '@/components';
import { useLocation } from '@/hooks/useLocation';
import { useNavigate } from '@/hooks/useNavigate';
import { StateOrganization } from '@/util/types';

const Organizations = () => {
  const navigation = useNavigate();
  const { state, query } = useLocation<StateOrganization, { org: string }>();
  const {
    search,
    tabIndex,
    getSelectedOrgs,
    setTabIndex,
    handleSearch,
    resetSearch,
    myOrgsLoading,
    allOrgsLoading,
    myOrgsCreateLoading,
    myOrgsUpdateLoading,
    handleShowMore,
    nextToken,
  } = useOrganizations();

  const [open, { toggle }] = useDisclosure(false);

  const [, setQueryParams] = useSearchParams();

  useEffect(() => {
    if (state?.id) {
      const params = new URLSearchParams();
      params.append('org', state.id);
      setQueryParams(() => params.toString());
    }
  }, []);

  const handleSelectedOrg = (org: Organization) => {
    const params = new URLSearchParams();

    params.append('org', org.id);
    setQueryParams(() => params.toString());

    toggle();
  };
  const props: OrganizationListingProps = {
    navigation,
    handleShowMore,
    nextToken,
    selected: query?.org,
    setSelected: handleSelectedOrg,
    resetSearch,
    handleSearch,
    setTabIndex,
    search,
    tabIndex,
    getSelectedOrgs,
    loading:
      tabIndex === OrgsTabType.MYORGS
        ? myOrgsLoading || myOrgsCreateLoading || myOrgsUpdateLoading
        : allOrgsLoading,
    open,
    toggle,
  };
  return (
    <SectionGrid $isOpen={true}>
      {open && <Overlay />}
      <LeftSectionLarge>
        <OrganizationListing {...props} />
      </LeftSectionLarge>
      {open && (
        <LeftSectionSmall>
          <OrganizationListing {...props} />
        </LeftSectionSmall>
      )}
      <div>
        {!query?.org && (
          <EmptySelectWrapper spacing={0}>
            {!open && (
              <ToggleBack iconType="rightArrow" onClick={toggle} tx="nav.org" />
            )}
            <Text
              style={{
                textAlign: 'center',
                margin: '20% 20% 2% 20%',
              }}
              tx="org.about"
            />
            <EmptyListingImage src={FigureShrugging} />
          </EmptySelectWrapper>
        )}
        {query?.org && (
          <OrganizationInfo selected={query.org} toggle={toggle} />
        )}
      </div>
    </SectionGrid>
  );
};

const EmptyListingImage = styled.img`
  height: 15%;
`;

const EmptySelectWrapper = styled(VStack)`
  height: 100%;
`;

const ToggleBack = styled(ActionButton)`
  @media ${deviceMin.laptop} {
    display: none;
  }
`;

export default Organizations;
