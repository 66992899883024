import { EventRaw } from '@aclito/entities';

import { isUserTrainer } from '../utils/getEventRole';
import { useKickFromEventMutation } from '../../../redux/api/eventApi';

import { EventWithPlayers, UserInfoWithAlias } from '@aclito/shared/types';
import { useAppSelector } from '@aclito/shared/hooks';
import { profileSelectors } from '@aclito/shared/redux/slices/profileSlices';

export const useEventInfoKick = () => {
  const userInfo = useAppSelector(profileSelectors.userInfo);
  const [kickPlayer] = useKickFromEventMutation();

  const canBePlayerKicked = (
    event: EventRaw,
    canKick: boolean,
    player: UserInfoWithAlias,
  ) =>
    userInfo.id !== player.id &&
    !isUserTrainer(player, event) &&
    player.id !== event.leader &&
    canKick;

  const kickUserFromEvent = async (
    event: EventWithPlayers,
    user: UserInfoWithAlias,
    value: string,
  ) => {
    const id = user.aliasId ? user.aliasId : user.id;

    await kickPlayer({ eventId: event.id, idOrAliasId: id, message: value });
  };

  return { kickUserFromEvent, canBePlayerKicked };
};
