import { AllIcons } from '@aclito/shared/types';
import { BIG_ICON_SIZE_CLASSIC_ACHIEVEMENT } from '@aclito/shared/util/constants';

import { IconType } from '../../components/Icons/Icon';

import IconAll from '@aclito/ui-web/components/Icons/IconAll';

const checkIconNameType = (iconName: string): iconName is IconType => {
  return (iconName as AllIcons) !== undefined;
};

const createIcons = (icons: string[], size: number) => {
  if (!icons || icons.length === 0) {
    return (
      <IconAll
        size={BIG_ICON_SIZE_CLASSIC_ACHIEVEMENT}
        color={'white'}
        icon={'trophy'}
      />
    );
  }

  return icons.map((iconName, index) => (
    <IconAll
      key={index}
      size={size}
      color={'white'}
      icon={checkIconNameType(iconName) ? iconName : 'trophy'}
    />
  ));
};

export default createIcons;
