import { useTranslate } from '@aclito/shared/hooks';
import { useAvailabilityInfo } from '@aclito/shared/features/availability/hooks/useAvailabilityInfo';
import React from 'react';
import styled from 'styled-components';
import { useMediaQuery } from '@mantine/hooks';
import { useListAvailabilitiesQuery } from '@aclito/shared/redux/api/availabilityApi';
import { deviceMax } from '@aclito/ui-web/utils/mediaQueries';

import { AvailabilityInfoProps } from './interface';
import { Buttons, Info, Suggestions } from './components';
import { useAvailabilityInfoWeb } from './hooks/useAvailabilityInfoWeb';

import {
  Collapsible,
  SectionGrid,
  TitleBar,
  HStack,
  VStack,
  Title,
  BackButton,
} from '@/components';
import withRouter from '@/navigation/withRouter';
import ViewOnlyMap from '@/common/Maps/ViewOnlyMap';
import { useFlexWrap } from '@/hooks/useFlexWrap';

const AvailabilityInfo: React.FC<AvailabilityInfoProps> = ({ state }) => {
  const { data } = useListAvailabilitiesQuery({
    nextToken: null,
  });

  const displayedAvailability = data?.result.find(
    (item) => item.id === state?.availability.id,
  );

  const { suggestedEvents } = useAvailabilityInfo(displayedAvailability);

  const { handleDelete, handleEdit } = useAvailabilityInfoWeb(
    displayedAvailability,
  );

  const t = useTranslate();

  const id = 'titleFlexContainer';
  const isFlexWrapped = useFlexWrap(id);
  const queryMobile = useMediaQuery(deviceMax.mobile);

  return (
    <SectionGrid $isOpen={false}>
      <TitleBar id={id}>
        <HStack>
          <BackButton />
          <Title tx="nav.availability.info" />
        </HStack>
        <Buttons
          {...{
            handleEdit,
            handleDelete,
            compact: isFlexWrapped || queryMobile,
          }}
        />
      </TitleBar>
      <VStack>
        <TopSection spacing={0} align={'center'}>
          <InfoSection>
            {displayedAvailability && (
              <Info {...{ availability: displayedAvailability }} />
            )}
          </InfoSection>
        </TopSection>
        {displayedAvailability && (
          <Collapsible buttonLabelTx="nav.map" defaultOpen>
            <MapSection>
              <ViewOnlyMap
                type="circle"
                location={displayedAvailability?.location}
                name={t('nav.availability')}
              />
            </MapSection>
          </Collapsible>
        )}
        {displayedAvailability && (
          <Collapsible buttonLabelTx="nav.suggestions" defaultOpen>
            <Suggestions {...{ suggestedEvents }} />
          </Collapsible>
        )}
      </VStack>
    </SectionGrid>
  );
};

const TopSection = styled(HStack)`
  height: 100%;
`;

const InfoSection = styled(VStack)``;

const MapSection = styled.div`
  height: 406px;
  width: 50%;
`;

export default withRouter(AvailabilityInfo);
