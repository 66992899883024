import { TranslateKey } from '@aclito/shared/types';

export type CodeType =
  | 'AliasExistsException'
  | 'CodeMismatchException'
  | 'ExpiredCodeException'
  | 'InternalErrorException'
  | 'InvalidLambdaResponseException'
  | 'InvalidParameterException'
  | 'LimitExceededException'
  | 'NotAuthorizedException'
  | 'ResourceNotFoundException'
  | 'TooManyFailedAttemptsException'
  | 'TooManyRequestsException'
  | 'UnexpectedLambdaException'
  | 'UserLambdaValidationException'
  | 'UserNotFoundException'
  | 'OK'
  | 'Welcome'
  | 'Unknown';

export const codes: Record<CodeType, TranslateKey> = {
  AliasExistsException: 'error.couldntverify',
  CodeMismatchException: 'error.codemismatch',
  ExpiredCodeException: 'error.codeexpiry',
  InternalErrorException: 'error.internal',
  InvalidLambdaResponseException: 'error.couldntverify',
  InvalidParameterException: 'error.invalidparam',
  LimitExceededException: 'error.limit',
  NotAuthorizedException: 'error.notauth',
  ResourceNotFoundException: 'error.couldntverify',
  TooManyFailedAttemptsException: 'error.toomanyattempts',
  TooManyRequestsException: 'error.toomanyrequests',
  UnexpectedLambdaException: 'error.couldntverify',
  UserLambdaValidationException: 'error.couldntverify',
  UserNotFoundException: 'error.usernotfound',
  OK: 'confirmed',
  Welcome: 'welcome',
  Unknown: 'error.unknown',
};

export const getCode = (code: CodeType) => codes[code] || codes.Unknown;
