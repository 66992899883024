import {
  CreateFeedInput,
  DeleteFeedInput,
  FeedRaw,
  UpdateFeedInput,
  GetFeedByFeedOrgIdParams,
  GetMyFeedQueryParams,
  Feed,
} from '@aclito/entities';

import { ContentType, HttpClient } from '../httpClient';

export class FeedClient extends HttpClient {
  feeds = {
    createFeed: (data: CreateFeedInput) =>
      this.request<FeedRaw>({
        path: '/feed',
        method: 'POST',
        body: data,
        type: ContentType.Json,
      }),
    deleteFeed: (data: DeleteFeedInput) =>
      this.request<void>({
        path: '/feed',
        method: 'DELETE',
        body: data,
        type: ContentType.Json,
      }),
    updateFeed: (data: UpdateFeedInput) =>
      this.request<Feed>({
        path: '/feed',
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
      }),
    listFeedsByOrg: (query: GetFeedByFeedOrgIdParams) =>
      this.request<Feed[]>({
        path: '/feed',
        method: 'GET',
        query,
        type: ContentType.Json,
      }),
    getFeed: (id: string) =>
      this.request<Feed>({
        path: `/feed/single/${id}`,
        method: 'GET',
        type: ContentType.Json,
      }),
    searchMyFeeds: (query: GetMyFeedQueryParams) =>
      this.request<Feed[]>({
        path: '/feed/my-feeds',
        method: 'GET',
        query,
        type: ContentType.Json,
      }),
  };
}
