import React from 'react';
import styled from 'styled-components';
import { config } from '@aclito/shared/config';

import logo from '../images/aclito.svg';

const Header: React.FC = () => {
  const navToLanding = () => {
    document.location = config.URL.aclitoWebURL;
  };

  const logoURL = new URL(logo, import.meta.url).href;

  return (
    <Container>
      <img style={{ cursor: 'pointer' }} onClick={navToLanding} src={logoURL} />
    </Container>
  );
};

const Container = styled.div`
  position: sticky;
  top: 0;
  display: flex;
  z-index: 1;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  background-color: ${({ theme }) => theme.white};
  padding: 16px 24px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.05);
`;

export default Header;
