import { EventRaw } from '@aclito/entities';

import { UserInfoWithAlias } from '@aclito/shared/types';

export const isUserOrganizer = (
  userInfo: UserInfoWithAlias,
  event: EventRaw,
) => {
  return event.leader === userInfo.id && !userInfo.aliasId;
};
export const isUserTrainer = (userInfo: UserInfoWithAlias, event: EventRaw) => {
  return event.players.some((p) => {
    return p.isTrainer && p.id === userInfo.id && !userInfo.aliasId;
  });
};
