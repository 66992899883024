import { Middleware, isPending } from '@reduxjs/toolkit';

import { AppState } from '../store';
interface PaginationArg {
  nextToken: string | null;
}
interface PaginationData {
  result: any[];
  nextToken: string | null;
}
//https://github.com/reduxjs/redux-toolkit/discussions/1163#discussioncomment-5168974
export const customMiddleware: Middleware = (api) => (next) => (action) => {
  // Exclude unrelated actions
  if (!isPending(action) || !action.meta || !action.meta.arg) {
    return next(action);
  }

  const { type, endpointName, originalArgs } = action.meta.arg;

  const state = api.getState() as AppState;

  const latestQuery = state.aclitoApi.queries[endpointName];

  // Exclude unrelated actions
  if (type !== 'query' || !originalArgs || !latestQuery) {
    return next(action);
  }

  const queryArg = originalArgs as PaginationArg;
  const latestData = latestQuery.data as PaginationData;
  const { result: latestItems, nextToken: latestCursor } = latestData;
  // Exclude first query runs
  if (!latestItems || !latestItems.length || latestCursor === undefined) {
    return next(action);
  }

  // Exclude normal subsequent queries
  if (queryArg.nextToken === latestCursor && latestCursor !== null) {
    return next(action);
  }

  action.meta.arg.originalArgs = {
    ...originalArgs,
    pageSize: latestItems.length,
    nextToken: null,
    refetch: true,
  } as PaginationArg;

  return next(action);
};
