import { Auth } from 'aws-amplify';

import { errorObject } from '../../utils/errorObject';
/**
 *
 * @param username Users username
 * @returns A string message or appropriate error
 */
export const makeResetPasswordRequest = async (
  username: string,
): Promise<boolean> => {
  try {
    await Auth.forgotPassword(username);
    return true;
  } catch (error) {
    //@ts-ignore
    return Promise.reject(errorObject[error.code]);
  }
};
