import { TextProps as MTTextProps, Text as MTText } from '@mantine/core';
import { useTranslate } from '@aclito/shared/hooks';
import { TranslateKey, TranslateValues } from '@aclito/shared/types';
import { useTheme } from 'styled-components';

export interface TextProps extends MTTextProps {
  tx?: TranslateKey;
  text?: string | number;
  txOptions?: TranslateValues;
}

export const Text = (props: TextProps) => {
  const { children, style, tx, text, txOptions, ...rest } = props;

  const t = useTranslate();
  const theme = useTheme();

  const i18nText = tx && t(tx, { bold: (str) => <b>{str}</b>, ...txOptions });
  const content = i18nText || text || children;

  const styles: React.CSSProperties = {
    ...style,
    lineHeight: 'normal',
  };

  return (
    <MTText color={theme.grey80} {...rest} style={styles}>
      {content}
    </MTText>
  );
};
