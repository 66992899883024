import {
  useNavigate as RRDUseNavigate,
  NavigateOptions,
} from 'react-router-dom';

import { ParamList } from '../util/types';

type Keyof<T extends {}> = Extract<keyof T, string>;

interface DefaultOptions<T> extends Omit<NavigateOptions, 'state'> {
  state: T;
}

declare function DefaultFunction<
  RouteName extends keyof ParamList = Keyof<ParamList>,
>(to: RouteName | number, options?: DefaultOptions<ParamList[RouteName]>): void;

export type UseNavigateType = ReturnType<typeof useNavigate>;
export const useNavigate = (): typeof DefaultFunction => RRDUseNavigate();
