import { ThemeColorsType } from '@aclito/shared/theme/colors';
import React from 'react';
import { BannersKey, ValueOf } from '@aclito/shared/types';
import { useTheme } from 'styled-components';

import { banners } from './BannersConfig';
import Banner from './Banner';

interface CardStatusBannerTextProps {
  type: BannersKey;
  bannerColor?: ValueOf<ThemeColorsType>;
}

const CardStatusBannerText: React.FC<CardStatusBannerTextProps> = ({
  type,
  bannerColor,
}) => {
  const { color, icon, text } = banners[type];
  const theme = useTheme();

  const c = bannerColor ?? theme[color];
  return <Banner color={c} icon={icon} tx={text} />;
};

export default CardStatusBannerText;
