import { Center, LoadingOverlay, Space } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import {
  useLazyListAvailabilitiesQuery,
  useListAvailabilitiesQuery,
} from '@aclito/shared/redux/api/availabilityApi';
import { deviceMax } from '@aclito/ui-web/utils/mediaQueries';
import { dateSort } from '@aclito/shared/util/sorter';

import { useAvailabilities } from './hooks/useAvailabilities';

import {
  TitleBar,
  ActionButton,
  ShowMoreButton,
  Text,
  Title,
  SectionGrid,
} from '@/components';
import { useNavigate } from '@/hooks/useNavigate';
import { ROUTE_NEW_AVAILABILITY } from '@/util/constants';
import AvailabilitiesCards from '@/components/Cards/AvailabilitiesCards';
import { useFlexWrap } from '@/hooks/useFlexWrap';

const Availabilities = () => {
  const navigation = useNavigate();

  const { data, isFetching } = useListAvailabilitiesQuery({
    nextToken: null,
  });

  const availabilities = data?.result || [];

  const [trigger] = useLazyListAvailabilitiesQuery();

  const loadNextAvailabilities = async () => {
    trigger({ nextToken: data?.nextToken ?? null });
  };

  useAvailabilities();

  const id = 'titleFlexContainer';
  const isFlexWrapped = useFlexWrap(id);
  const queryMobile = useMediaQuery(deviceMax.mobile);
  const handleNav = () => navigation(ROUTE_NEW_AVAILABILITY);
  return (
    <>
      <LoadingOverlay overlayBlur={2} visible={isFetching} />
      <SectionGrid $isOpen={false}>
        <TitleBar id={id}>
          <Title tx="nav.availability" />
          {isFlexWrapped || queryMobile ? (
            <ActionButton iconType={'add'} primary onClick={handleNav} />
          ) : (
            <ActionButton
              iconType={'add'}
              primary
              onClick={handleNav}
              tx={'create'}
            />
          )}
        </TitleBar>

        <Text
          align="center"
          style={{
            margin: '1% 24% 2% 24%',
          }}
          tx="availability.about"
        />
        <Space h={30} />
        {availabilities?.length ? (
          <>
            {!isFetching && (
              <AvailabilitiesCards
                availabilities={
                  availabilities ? dateSort(availabilities, 'from', 'asc') : []
                }
              />
            )}
            {data?.nextToken && (
              <Center mt="xl">
                <ShowMoreButton onClick={loadNextAvailabilities} />
              </Center>
            )}
          </>
        ) : null}
      </SectionGrid>
    </>
  );
};

export default Availabilities;
