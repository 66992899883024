import { useState } from 'react';

import { EventModel } from '@aclito/shared/classes/EventModel';
import { ThemeColorsRecord } from '@aclito/shared/theme/colors';
import { TranslateKey } from '@aclito/shared/types';
import { ALIAS_PREFIX, EVENT_QUEUE_SIZE } from '@aclito/shared/util/constants';
import {
  getParticipantsCount,
  getPlayersInQueueCount,
} from '@aclito/shared/features/event/utils/getParticipantsCount';

export const useEventInfoAliasJoin = (model: EventModel) => {
  const { event } = model;
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [selectedQueueIds, setSelectedQueueIds] = useState<string[]>([]);

  const willEventBeFull =
    event.maxPlayers !== -1 &&
    getParticipantsCount(event) + selectedIds.length >= event.maxPlayers;

  const willQueueBeFull =
    getPlayersInQueueCount(event) + selectedQueueIds.length > EVENT_QUEUE_SIZE;

  const handleOnAliasPress = (id: string) => {
    const toggleSelected = (selected: string[]) =>
      selected.includes(id)
        ? selected.filter((val) => val !== id)
        : [...selected, id];

    if (isAliasJoined(id)) {
      return;
    }

    if (
      (!willEventBeFull || selectedIds.includes(id)) &&
      !selectedQueueIds.includes(id)
    ) {
      setSelectedIds(toggleSelected);
    } else if (
      (event.enableQueue && !willQueueBeFull) ||
      selectedQueueIds.includes(id)
    ) {
      setSelectedQueueIds(toggleSelected);
    }
  };

  const isSelected = (id: string) => selectedIds.includes(id);
  const isSelectedQueue = (id: string) => selectedQueueIds.includes(id);

  const isAliasJoined = (aliasId: string) => {
    if (aliasId.startsWith(ALIAS_PREFIX)) {
      return event.players.some((player) => player?.aliasId === aliasId);
    } else {
      return event.players.some(
        (player) => player.id === aliasId && !player.aliasId,
      );
    }
  };

  const isAliasInQueue = (aliasId: string) => {
    if (aliasId.startsWith(ALIAS_PREFIX)) {
      return event.queuePlayersInfo.some(
        (player) => player?.aliasId === aliasId,
      );
    } else {
      return event.queuePlayersInfo.some(
        (player) => player.id === aliasId && !player.aliasId,
      );
    }
  };

  const topText: TranslateKey =
    willEventBeFull && !event.enableQueue
      ? 'events.info.alias.full.title'
      : willEventBeFull && event.enableQueue
      ? 'events.info.alias.queue.title'
      : willQueueBeFull
      ? 'events.info.alias.queue.full.title'
      : 'events.info.alias.join.title';

  const itemBg = (id: string, theme: ThemeColorsRecord) =>
    isSelectedQueue(id)
      ? `${theme.yellow}4D`
      : isSelected(id)
      ? `${theme.primary}1A`
      : willEventBeFull && !event.enableQueue
      ? `${theme.black}1A`
      : willEventBeFull && willQueueBeFull
      ? `${theme.black}1A`
      : willEventBeFull && event.enableQueue
      ? `${theme.yellow}1A`
      : theme.white;

  return {
    isSelected,
    isSelectedQueue,
    isAliasJoined,
    isAliasInQueue,
    handleOnAliasPress,
    selectedIds,
    selectedQueueIds,
    topText,
    itemBg,
    setSelectedIds,
    setSelectedQueueIds,
  };
};
