import { useState } from 'react';
import { useFormContext } from 'react-hook-form';

import {
  AvailabilityActivities,
  AvailabilityForm,
  SelectObject,
} from '@aclito/shared/types';
import { config } from '@aclito/shared/config';
import { useAppSelector, useSelectObject } from '@aclito/shared/hooks';
import { profileSelectors } from '@aclito/shared/redux/slices/profileSlices';

export const useAvailabilityEdit = () => {
  const { setValue, getValues } = useFormContext<AvailabilityForm>();
  const userInfo = useAppSelector(profileSelectors.userInfo);

  const [radioValue, setRadioValue] = useState<AvailabilityActivities>(
    getValues().activities?.length
      ? AvailabilityActivities.CHOOSE_ACTIVITY
      : AvailabilityActivities.ANY_ACTIVITY,
  );

  const { translate, translatedActivities } = useSelectObject();

  const handleRadioChange = (
    value: AvailabilityActivities,
    initialActivities: SelectObject[],
  ) => {
    setRadioValue(value);
    if (value === AvailabilityActivities.MY_ACTIVITIES) {
      setValue(
        'activities',
        translate(
          userInfo.activities.map((s) => ({
            item: config.activities[s].item,
            id: s,
          })),
        ),
      );
    }
    if (value === AvailabilityActivities.CHOOSE_ACTIVITY) {
      setValue('activities', initialActivities);
    }

    if (value === AvailabilityActivities.ANY_ACTIVITY) {
      setValue('activities', undefined);
    }
  };

  return {
    handleRadioChange,
    radioValue,
    setRadioValue,
    translatedActivities,
  };
};
