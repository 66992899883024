import { useTranslate } from '@aclito/shared/hooks/useTranslate';
import { AlertErrorEventKeys, EventForm } from '@aclito/shared/types';
import { SubmitHandler } from 'react-hook-form';
import { useFormErrorMap } from '@aclito/shared/hooks/useFormErrorMap';
import { showNotification } from '@aclito/ui-web/utils/showNotification';

import { useNavigate } from '@/hooks/useNavigate';

export const useEventCreateEdit = (handleSubmit: (data: EventForm) => void) => {
  const t = useTranslate();

  const nav = useNavigate();
  const navBack = () => {
    nav(-1);
  };

  const tx: Record<AlertErrorEventKeys, string> = {
    requireConfirm: t('events.new.confirmation.required'),
    hoursConfirm: t('events.new.confirmation.hours'),
    date: t('events.new.date'),
    gender: t('events.new.gender'),
    info: t('events.new.description'),
    level: t('events.new.level'),
    name: t('events.new.name'),
    players: t('events.new.players'),
    price: t('events.new.price'),
    activity: t('events.new.activity'),
    place: t('events.new.place'),
    newPlace: t('events.new.place'),
    externalLink: t('events.new.external.link'),
    linkRequired: t('events.new.external.link.required'),
    unlimited: t('events.new.unlimited'),
    repeatAfter: t('events.new.repetitionInterval'),
    duration: t('events.new.duration'),
    numberOfEvents: t('events.new.numberOfEvents'),
    trainer: t('events.new.trainer'),
  };

  const { onError } = useFormErrorMap(tx, showNotification);

  const onSubmit: SubmitHandler<EventForm> = async (values) => {
    handleSubmit(values);
  };

  return { onSubmit, onError, navBack };
};
