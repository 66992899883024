import { StatusData } from '../api/requests/custom/status/getStatus';

import { BREAKING_CHANGES_VERSION } from './constants';

export const isLoginDisabled = (status: StatusData): boolean => {
  if (status.status === 'offline') return true;
  return status.breakingChangesVersion > BREAKING_CHANGES_VERSION;
};

export const isTACUpdateRequired = (
  currentVersion = 0,
  status: StatusData,
  skippedUntil: string | null,
): boolean => {
  const isRequired = currentVersion < status.termsAndConditionsVersion;

  if (
    isRequired &&
    skippedUntil &&
    new Date().getTime() < new Date(skippedUntil).getTime()
  ) {
    return false;
  }

  return isRequired;
};
