import { useTranslate } from '@aclito/shared/hooks';
import { Select as MantineSelect } from '@mantine/core';
import React, { useRef } from 'react';
import { useTheme } from 'styled-components';

import { Icon } from '../Icons/Icon';
import { getCommonInputLabelStyle } from '../../utils/styles';

import { SelectProps } from './types';

const Select: React.FC<SelectProps> = ({
  options,
  label,
  placeholder,
  txLabel,
  txPlaceholder,
  optional,
  ...props
}) => {
  const t = useTranslate();
  const theme = useTheme();
  const i18nLabel = txLabel && t(txLabel);
  const labelContent = optional
    ? `${i18nLabel || label} ${t('optional')}`
    : i18nLabel || label;

  const i18nPlaceholder = txPlaceholder && t(txPlaceholder);
  const placeholderContent = i18nPlaceholder || placeholder;

  const selectRef = useRef<HTMLInputElement>(null);

  return (
    <MantineSelect
      variant="filled"
      size="md"
      ref={selectRef}
      rightSection={
        props.value ? (
          <Icon
            onClick={() => props.onChange && props.onChange('')}
            icon={'close'}
            color="grey30"
          />
        ) : (
          <Icon
            onClick={() => selectRef.current && selectRef.current.focus()}
            icon={'expandMore'}
            color="grey30"
          />
        )
      }
      radius={10}
      clearable
      styles={{
        input: {
          backgroundColor: theme.white,
          color: theme.grey80,
        },
        label: { ...getCommonInputLabelStyle(theme) },
      }}
      data={options.map((o) => ({ value: o.id, label: o.item }))}
      label={labelContent}
      placeholder={placeholderContent}
      {...props}
    />
  );
};

export default Select;
