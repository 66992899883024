import React, { useEffect } from 'react';
import styled from 'styled-components';
import { EventForm } from '@aclito/shared/types';
import { useFormContext } from 'react-hook-form';

import { DescriptionProps } from './interface';
import AdvancedSection from './AdvancedSection';

import {
  HStack,
  ValidatedInput,
  ValidatedNumberInput,
  ValidatedSelect,
  ValidatedSwitch,
  VStack,
  ValidatedTextArea,
} from '@/components';

const Description: React.FC<DescriptionProps> = ({
  allActivities,
  possibleGenders,
  levelOptions,
  orgOptions,
  isJoined = false,
  eventId,
  orgId,
}) => {
  const { setValue, watch, resetField } = useFormContext<EventForm>();

  const watchPrice = watch('price');
  const watchPlayers = watch('players');
  const watchUnlimited = watch('unlimited');
  const watchForFree = watch('forFree');
  const watchEnableQueue = watch('enableQueue');

  const allActivitiesNoAny = allActivities.filter(
    (activity) => activity.id !== 'any',
  );

  useEffect(() => {
    setValue('forFree', !watchPrice || watchPrice === 0);

    if (!watchPlayers) {
      setValue('unlimited', true);
    } else {
      setValue('unlimited', false);
    }
  }, [watchPrice, watchPlayers, setValue]);

  useEffect(() => {
    if (watchUnlimited) {
      resetField('players');
      setValue('players', '');
    }
  }, [setValue, watchUnlimited]);

  useEffect(() => {
    if (watchForFree) {
      resetField('price');
      setValue('price', '');
    }
  }, [setValue, watchForFree]);

  return (
    <VStack spacing={20}>
      <ValidatedInput
        txLabel="events.new.name"
        txPlaceholder="events.new.name"
        name="name"
        required
      />

      <VStack justify="space-between">
        <ValidatedSelect
          name="activity"
          options={allActivitiesNoAny}
          txLabel="events.new.activity"
          txPlaceholder="events.new.activity"
          required
        />

        <StyledHStack>
          <ValidatedNumberInput
            txLabel="events.new.players"
            txPlaceholder="events.new.players"
            name="players"
            required={!watchUnlimited || watchEnableQueue}
            min={2}
            max={99}
            style={inputStyle}
          />
          <ValidatedSwitch
            name="unlimited"
            txLabel="events.new.unlimited"
            required={!watchPlayers}
            style={{ marginBottom: '0.7rem', ...inputStyle }}
            data-testid="unlimited"
          />
        </StyledHStack>

        <StyledHStack>
          <ValidatedNumberInput
            style={inputStyle}
            name="price"
            txLabel="events.new.price"
            txPlaceholder="events.new.price"
            required={!watchForFree}
            min={0}
            max={1000}
          />
          <ValidatedSwitch
            name="forFree"
            txLabel="events.new.forFree"
            required={!watchPrice || watchPrice === 0}
            style={{ marginBottom: '0.7rem', ...inputStyle }}
            data-testid="forFree"
          />
        </StyledHStack>

        <HStack>
          <ValidatedSelect
            style={inputStyle}
            name="gender"
            options={possibleGenders}
            txLabel="events.new.gender"
            txPlaceholder="events.new.gender"
            required
          />
          <ValidatedSelect
            style={inputStyle}
            txLabel="events.new.level"
            txPlaceholder="events.new.level"
            name="level"
            options={levelOptions}
            required
          />
        </HStack>
      </VStack>

      <ValidatedTextArea
        optional
        txLabel="events.new.description"
        txPlaceholder="events.new.description"
        name="info"
      />
      <AdvancedSection
        {...{
          orgOptions,
          eventId,
          isJoined,
          orgId,
        }}
      />
    </VStack>
  );
};

const inputStyle = {
  flexGrow: 1,
  flexBasis: 0,
};

const StyledHStack = styled(HStack)`
  display: flex;
  align-items: flex-end;
  flex-wrap: nowrap;
`;

export default Description;
