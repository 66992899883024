import React, { useRef, useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { useTranslate, useTranslateDate } from '@aclito/shared/hooks';
import type { Chat, Message } from '@aclito/entities';
import {
  useFindAndDisplayChatQuery,
  useLazyFindAndDisplayChatQuery,
} from '@aclito/shared/redux/api/messageApi';

import { Avatar, Icon, Text } from '@/components';
import { useNavigate } from '@/hooks/useNavigate';
import { ROUTE_USER_PROFILE } from '@/util/constants';

type Props = {
  items: Message[];
  isLoading: boolean;
  hasMore: boolean;
  isMyMessage: (m: Message) => boolean;
  chat: Chat;
};

const MessagesList: React.FC<Props> = ({
  items,
  isLoading,
  hasMore,
  isMyMessage,
  chat,
}) => {
  const t = useTranslate();
  const [observerLoading, setObserverLoading] = useState<boolean>(false);

  const { data } = useFindAndDisplayChatQuery({
    nextToken: null,
    id: chat.id,
  });

  const [trigger] = useLazyFindAndDisplayChatQuery();

  const loadNext = async () => {
    await trigger({ id: chat.id, nextToken: data?.nextToken ?? null });
    setObserverLoading(false);
  };

  const observer = useRef<IntersectionObserver>();
  const refM = useRef<HTMLDivElement | null>(null);
  const refPrevLastItem = useRef<HTMLDivElement | null>(null);
  const tDate = useTranslateDate();
  const nav = useNavigate();

  const lastElementRef = (node: HTMLDivElement | null) => {
    if (isLoading) return;
    if (observer.current) {
      observer.current.disconnect();
    }
    observer.current = new IntersectionObserver(async (entries) => {
      if (entries[0].isIntersecting && hasMore && !observerLoading && chat) {
        setObserverLoading((prev) => !prev);
        await loadNext();
        if (refPrevLastItem.current) {
          refPrevLastItem.current.scrollIntoView();
        }
      }
    });
    if (node) observer.current.observe(node);
  };

  useEffect(() => {
    if (refM.current && items.length > 0) {
      refM.current.scrollIntoView({ block: 'end' });
    }
  }, [items.length]);

  useEffect(() => {
    if (!observerLoading && refM.current) {
      refM.current.scrollIntoView({ block: 'end' });
    }
  }, [items.length]);

  const getRef = (i: number) => {
    if (i === 0) {
      // date is always first:
      return lastElementRef;
    }
    if (i === 1) {
      // first message:
      return refPrevLastItem;
    }
    return null;
  };
  const navigateToUserProfile = (id: string) => () =>
    nav(ROUTE_USER_PROFILE, {
      state: { userId: id },
    });

  const getMessage = (m: Message & { dateOnly?: boolean }, i: number) => (
    <MessageCard
      key={m.id}
      ref={getRef(i)}
      alignment={isMyMessage(m) ? 'right' : 'left'}
    >
      {m.dateOnly ? (
        <SystemDate>{m.message}</SystemDate>
      ) : (
        <>
          {m.system ? (
            <SystemMessage>{m.message}</SystemMessage>
          ) : (
            <>
              {!isMyMessage(m) && (
                <Avatar
                  onClick={navigateToUserProfile(m.user.id)}
                  size={48}
                  image={m.user.image}
                />
              )}
              <Cloud sending={m.sent}>
                {!m.user.deleted ? (
                  <MessageText
                    $isMyMessage={isMyMessage(m)}
                    text={m.user.name}
                    weight={'bold'}
                  />
                ) : (
                  <MessageText
                    $isMyMessage={isMyMessage(m)}
                    tx={'userprofile.unknown'}
                    weight={'bold'}
                  />
                )}
                <MessageText
                  $isMyMessage={isMyMessage(m)}
                  text={m.message}
                  weight={400}
                />
                <div className="last-row">
                  <MessageText
                    $isMyMessage={isMyMessage(m)}
                    text={tDate(m.createdAt.toString(), 'time.message')}
                    weight={400}
                  />
                  {isMyMessage(m) && (
                    <Icon color="white" icon="checkmark" size={15} />
                  )}
                </div>
              </Cloud>
            </>
          )}
        </>
      )}
    </MessageCard>
  );

  return (
    <Wrapper>
      <Scrollbar ref={refM}>
        <h4 style={{ margin: '0' }}>
          {observerLoading && t('flatlist.loading')}
        </h4>
        {items.map((item, i) => getMessage(item, i))}
      </Scrollbar>
    </Wrapper>
  );
};

const MessageText = styled(Text)<{ $isMyMessage?: boolean }>`
  color: ${({ theme, $isMyMessage }) =>
    $isMyMessage ? theme.grey10 : theme.text};
  font-size: 12px;
`;

const Wrapper = styled.div`
  height: 100%;
  overflow-y: auto;
`;

const Scrollbar = styled.div`
  display: flex;
  flex-direction: column;

  & > :not(:last-child) {
    margin-bottom: 2rem;
  }

  & > :last-child {
    margin-bottom: 53px;
  }
`;

const SystemDate = styled(Text)`
  font-size: 14px;
  font-weight: 400;
  margin-left: auto;
  margin-right: auto;
`;

const SystemMessage = styled(Text)`
  font-size: 12px;
  font-weight: 400;
  margin-left: auto;
  margin-right: auto;
`;

const Cloud = styled.div<{ sending?: boolean }>`
  min-height: 78px;
  min-width: 228px;
  border-radius: 5px 25px 25px 25px;
  background-color: ${({ theme }) => theme.white};

  padding: 10px 16px;
  opacity: ${(p) => (p.sending === false ? 0.3 : 1)};

  .last-row {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
  }
`;

const MessageCard = styled.div<{ alignment: 'left' | 'right' }>`
  display: flex;
  align-items: center;
  justify-content: ${(p) => p.alignment};
  gap: 16px;

  ${(p) =>
    p.alignment === 'right' &&
    css`
      flex-direction: row-reverse;
    `};

  ${Cloud} {
    ${(p) =>
      p.alignment === 'right' &&
      css`
        border-radius: 25px 5px 25px 25px;
        background-color: ${({ theme }) => theme.blue};
        color: ${({ theme }) => theme.white};
      `};
  }
`;

export default MessagesList;
