import { createAsyncThunk } from '@reduxjs/toolkit';
import { notificationClient } from '@aclito/client';
import { Notification } from '@aclito/entities';

import { ThunkType } from '../types';

export const REDUX_LOAD_NOTIFICATIONS = 'loadNotifications';
export const REDUX_DELETE_DB_NOTIFICATION = 'deleteDNotification';
export const REDUX_DELETE_ALL_NOTIFICATIONS = 'deleteAllNotifications';

export const loadNotificationsAsync = createAsyncThunk<
  Notification[],
  void,
  ThunkType
>(REDUX_LOAD_NOTIFICATIONS, async (_, thunkAPI) => {
  try {
    const res = await notificationClient.notifications.listNotifications();
    return res.data;
  } catch (error) {
    return thunkAPI.rejectWithValue({ type: 'fail', payload: error });
  }
});

export const deleteAllNotificationsAsync = createAsyncThunk<
  void,
  void,
  ThunkType
>(REDUX_DELETE_ALL_NOTIFICATIONS, async (_, thunkAPI) => {
  try {
    await notificationClient.notifications.deleteAll();
  } catch (error) {
    return thunkAPI.rejectWithValue({ type: 'fail', payload: error });
  }
});

export const deleteDBNotificationAsync = createAsyncThunk<
  string,
  string,
  ThunkType
>(REDUX_DELETE_DB_NOTIFICATION, async (data, thunkAPI) => {
  try {
    await notificationClient.notifications.deleteOne({ id: data });
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue({ type: 'fail', payload: error });
  }
});
