const contextKey = 'context';

export type WebContext = {
  pathname: string;
  search: string;
  hash: string;
  state: object | undefined;
};

const contextInitialState: WebContext = {
  pathname: '',
  search: '',
  hash: '',
  state: undefined,
};

export const getContextFromLocalStorage = () => {
  const context = localStorage.getItem(contextKey);
  return context ? (JSON.parse(context) as WebContext) : contextInitialState;
};

export const saveContextToLocalStorage = (context: WebContext) => {
  localStorage.setItem(contextKey, JSON.stringify(context));
};

export const removeContextFromLocalStorage = () => {
  localStorage.removeItem(contextKey);
};
