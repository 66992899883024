import React from 'react';
import { useTranslate } from '@aclito/shared/hooks';
import { Loader } from '@mantine/core';
import styled from 'styled-components';

import {
  CodeType,
  getCode,
} from '@/features/integration/utils/verificationErrorCodes';
import { Text } from '@/components';

type ResultProps = {
  isLoading: boolean;
  response: CodeType | undefined;
  resendRequest: (body: object) => void;
};

const Result: React.FC<ResultProps> = ({
  isLoading,
  response,
  resendRequest,
}) => {
  const t = useTranslate();
  const canShowLink =
    response === 'ExpiredCodeException' || response === 'CodeMismatchException';

  return (
    <ResultTextContainer>
      {!isLoading ? (
        <Text size={'lg'} text={t(getCode(response as CodeType))} />
      ) : (
        <Loader />
      )}
      {canShowLink && (
        <NewLink onClick={resendRequest}>{t('verification.request')}</NewLink>
      )}
    </ResultTextContainer>
  );
};

const NewLink = styled.div`
  background: ${({ theme }) => theme.primary};
  border-radius: 50px;
  padding: 12px 30px;
  margin-top: 30px;
  font-size: 22px;
  color: ${({ theme }) => theme.white};
  &:hover {
    cursor: pointer;
  }
`;

const ResultTextContainer = styled.div`
  display: flex;
  margin-top: 25vh;
  justify-content: center;
  align-items: center;
  margin-bottom: 3vh;
  flex-direction: column;
`;

export default Result;
