import type { UserAlias } from '@aclito/entities';
import { EventModel } from '@aclito/shared/classes/EventModel';
import { useEventInfoAliasesLeave } from '@aclito/shared/features/event/hooks/useEventInfoAliasesLeave';
import React from 'react';

import AliasModalLeaveItem from './AliasModalLeaveItem';

import PlayerSelectModal from '@/common/Modals/PlayerSelectModal';

interface AliasModalProps {
  model: EventModel;
  opened: boolean;
  onClose: () => void;
  handleLeaveAliases: (selectedIds: string[]) => void;
}

const AliasModalLeave: React.FC<AliasModalProps> = ({
  model,
  opened,
  onClose,
  handleLeaveAliases,
}) => {
  const { selectedIds, isSelected, handleOnAliasPress, joinedAliases } =
    useEventInfoAliasesLeave(model);

  const handleLeaveButtonClick = () => {
    handleLeaveAliases(selectedIds);
    onClose();
  };

  const noAliases = joinedAliases ? joinedAliases.length > 0 : true;

  const renderItem = (alias: UserAlias) => (
    <AliasModalLeaveItem
      {...{
        isSelected,
        alias,
        handleOnAliasPress,
      }}
    />
  );

  return (
    <PlayerSelectModal
      data={joinedAliases}
      renderItem={renderItem}
      topText={
        noAliases
          ? 'events.info.alias.leave.title'
          : 'events.info.alias.leave.noAliases'
      }
      opened={opened}
      onClose={onClose}
      buttonProps={{
        color: 'red',
        disabled: !selectedIds.length,
        onClick: handleLeaveButtonClick,
        tx: 'events.info.alias.leave.button',
      }}
    />
  );
};

export default AliasModalLeave;
