import { getUserData } from '@aclito/shared/util/cognitoUser';
import { FIRST_TIME } from '@aclito/shared/util/constants';
import { useAboutUser } from '@aclito/shared/features/profile/hooks/useAboutUser';
import { ProfileInfoForm } from '@aclito/shared/types';

import { useNavigate } from './useNavigate';

import { ROUTE_EVENTS } from '@/util/constants';

export const useUserInformation = () => {
  const nav = useNavigate();
  const { updateUser, placeholderName, placeholderSurname, prefill } =
    useAboutUser();

  const handleUpdateUser = async (attributes: ProfileInfoForm) => {
    const { login } = await getUserData();
    const updated = await updateUser(attributes);

    if (updated && login === FIRST_TIME) {
      nav(0);
      nav(ROUTE_EVENTS);
    }
  };
  return { handleUpdateUser, placeholderName, placeholderSurname, prefill };
};
