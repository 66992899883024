import styled from 'styled-components';
import { closeAllModals } from '@mantine/modals';
import { cardColorPicker } from '@aclito/shared/util/cardColorPicker';
import { CloseButton, Loader } from '@mantine/core';
import { config } from '@aclito/shared/config';
import { getById } from '@aclito/shared/util/selectObject';
import { EventWithDateObject, TranslateKey } from '@aclito/shared/types';

import { HStack, VStack, Text, Title, PlainCard } from '@/components';
import EmbeddedInfo from '@/features/event/screens/EventInfo/components/EmbeddedInfo';
import { openInNewTab } from '@/util/openLinkInNewTab';
import { useWebShare } from '@/hooks/useWebShare';
import JoinLeaveButton from '@/features/event/screens/EventInfo/components/JoinLeaveButton';

interface LoadedEventModalProps {
  event: EventWithDateObject;
  navigation: (id: string) => void;
}

const LoadedEventModal: React.FC<LoadedEventModalProps> = ({ event }) => {
  const { getUrl } = useWebShare();
  const eventUrl = getUrl(event.id, 'event');
  return (
    <>
      {event ? (
        <PlainCard $borderColor={cardColorPicker(event.activityType)}>
          <VStack spacing={15}>
            <HStack
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <VStack spacing={0}>
                <Title text={event.name} />
                <SportTitle
                  tx={
                    getById(event.activityType, config.activities)
                      .item as TranslateKey
                  }
                  color={cardColorPicker(event.activityType)}
                />
              </VStack>
              <CloseButton
                style={{
                  position: 'absolute',
                  top: '24px',
                  right: '24px',
                }}
                onClick={() => {
                  closeAllModals();
                }}
              />
            </HStack>
            <EmbeddedInfo event={event} />
            <HStack
              style={{
                justifyContent: 'flex-end',
              }}
            >
              <JoinLeaveButton
                onClick={() => {
                  openInNewTab(eventUrl);
                }}
                tx="modal.goToEvent"
              />
            </HStack>
          </VStack>
        </PlainCard>
      ) : (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      )}
    </>
  );
};
const LoaderContainer = styled.div`
  width: 500px;
  height: 400px;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 10px;
`;

const SportTitle = styled(Text)<{ color: string }>`
  font-weight: bold;
  display: flex;
  justify-content: center;
  color: ${(props) => props.color};
` as typeof Text;

export default LoadedEventModal;
