import React from 'react';
import styled, { useTheme } from 'styled-components';
import { splitIconArrayIntoPairs } from '@aclito/shared/features/goals/utils';
import {
  BIG_ICON_SIZE_CLASSIC_ACHIEVEMENT,
  BIG_ICON_SIZE_LEVEL_ACHIEVEMENT,
  SMALL_ICON_SIZE_CLASSIC_ACHIEVEMENT,
  SMALL_ICON_SIZE_LEVEL_ACHIEVEMENT,
} from '@aclito/shared/util/constants';

import { Text } from '../components/Text';
import { Icon } from '../components/Icons/Icon';

import createIcons from './utils/createIcons';

interface IconProps {
  unlocked: boolean;
  classic: boolean;
  icons: string[] | undefined;
  backgroundColor: string;
  level: number;
  preview?: boolean;
}

const AchievementIcon: React.FC<IconProps> = ({
  unlocked,
  classic,
  icons = [],
  level,
  preview,
  backgroundColor,
}) => {
  const theme = useTheme();

  const CircleComponent = preview ? NoCircle : Circle;

  const sizeClassic =
    icons.length > 1
      ? SMALL_ICON_SIZE_CLASSIC_ACHIEVEMENT
      : BIG_ICON_SIZE_CLASSIC_ACHIEVEMENT;

  const sizeNonClassic =
    icons.length > 1
      ? SMALL_ICON_SIZE_LEVEL_ACHIEVEMENT
      : BIG_ICON_SIZE_LEVEL_ACHIEVEMENT;

  const size = classic ? sizeClassic : sizeNonClassic;

  return (
    <Container
      preview={preview}
      style={{
        backgroundColor: !unlocked ? theme.grey20 : backgroundColor,
      }}
    >
      {classic ? (
        <>
          {unlocked ? (
            <CircleComponent>
              {splitIconArrayIntoPairs(icons ?? []).map((icon, index) => (
                <IconContainer key={index}>
                  {createIcons(icon, size)}
                </IconContainer>
              ))}
            </CircleComponent>
          ) : (
            <CircleComponent>
              <Icon icon={'lock'} color={'white'} size={32} />
            </CircleComponent>
          )}
        </>
      ) : (
        <>
          {unlocked ? (
            <CircleComponent>
              {splitIconArrayIntoPairs(icons ?? []).map((icon, index) => (
                <IconContainer key={index}>
                  {createIcons(icon, size)}
                </IconContainer>
              ))}
              <Text
                size={10}
                weight={350}
                style={{ color: 'white', paddingTop: 2 }}
              >
                Level {level}
              </Text>
            </CircleComponent>
          ) : (
            <CircleComponent>
              <Icon icon={'lock'} color={'white'} size={32} />
            </CircleComponent>
          )}
        </>
      )}
    </Container>
  );
};

const NoCircle = styled.div``;

const Container = styled.div<{ preview?: boolean }>`
  height: 100%;
  border-radius: ${({ preview }) => (preview ? '10px' : '10px 0 0 10px')};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
`;

const Circle = styled.div`
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  border: solid 2px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const IconContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

export default AchievementIcon;
