import moment from 'moment';
import { EventPlayer, EventRaw, UserInfo } from '@aclito/entities';

const resolveConfirm = (player: EventPlayer | undefined, event: EventRaw) => {
  return (
    event.requireConfirmation &&
    player &&
    !player.isConfirmed &&
    !player.queueDate &&
    moment() >= moment(event.confirmationDate) &&
    moment() < moment(event.date) &&
    event.players.some((p) => p.id === player.id)
  );
};

export const shouldConfirmEvent = (event: EventRaw, userInfo: UserInfo) => {
  if (userInfo.aliases.length) {
    const filteredPlayers = event.players.filter(
      (player) => player.id === userInfo.id,
    );

    return filteredPlayers.some((player) => resolveConfirm(player, event));
  }

  const currentPlayer = event.players.find(
    (player) => player.id === userInfo.id,
  );

  return resolveConfirm(currentPlayer, event);
};
