import { PlayerDataType } from '@aclito/shared/features/event/types/types';
import { TranslateKey } from '@aclito/shared/types';
import React from 'react';

import { Button, VStack, Modal, ButtonProps, Title } from '@/components';

interface PlayerSelectModalProps {
  topText: TranslateKey;
  renderItem: (item: PlayerDataType) => JSX.Element;
  renderEmpty?: JSX.Element;
  opened: boolean;
  onClose: () => void;
  buttonProps: ButtonProps;
  data: PlayerDataType[];
}

const PlayerSelectModal: React.FC<PlayerSelectModalProps> = ({
  opened,
  onClose,
  buttonProps,
  renderItem,
  data,
  topText,
  renderEmpty,
}) => {
  return (
    <Modal size={'30%'} opened={opened} onClose={onClose}>
      <Title mb={10} align="center" tx={topText} />
      {data.length
        ? data.map((item, index) => (
            <React.Fragment key={item.id ? item.id : index}>
              {renderItem(item)}
            </React.Fragment>
          ))
        : renderEmpty}
      <VStack mt={20} pb={20}>
        <Button {...buttonProps} />
      </VStack>
    </Modal>
  );
};

export default PlayerSelectModal;
