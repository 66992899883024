import { composeUser } from '../utils/composeUser';
import { profileSelectors } from '../../../redux/slices/profileSlices';
import { composeEvents } from '../utils/composeEvents';
import { FormattedUserInfo } from '../../../types';
import { useEventHistoryQuery } from '../../../redux/api/eventApi';

import { useAppSelector, useTranslate } from '@aclito/shared/hooks';

export const useProfile = () => {
  const { data } = useEventHistoryQuery();
  const t = useTranslate();
  const userInfo = useAppSelector(profileSelectors.userInfo);
  const stats = useAppSelector(profileSelectors.stats);

  const pastEvents = composeEvents(data ?? []);

  const user: FormattedUserInfo = composeUser(t, userInfo);

  return { user, pastEvents, stats };
};
