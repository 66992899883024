import { createAsyncThunk } from '@reduxjs/toolkit';
import { notesClient } from '@aclito/client';
import { Note, NoteGrant } from '@aclito/entities';
import axios from 'axios';

import { ThunkType } from '../types';
import { AppState } from '../store';

export const REDUX_SAVE_NOTE = 'noteSave';
export const REDUX_GET_NOTE = 'noteGet';
export const REDUX_GET_NOTE_USER = 'noteGetUser';
export const REDUX_GET_MY_GRANTS = 'myNoteGrants';
export const REDUX_CLEAR_NOTE = 'clearNotes';

export const saveNoteAsync = createAsyncThunk<
  void,
  { tel: string | undefined; email: string | undefined },
  ThunkType
>(REDUX_SAVE_NOTE, async (args, thunkAPI) => {
  const { note } = thunkAPI.getState() as AppState;
  const { tel, email } = args;
  try {
    await notesClient.notes.patchNote({
      tel: tel ?? note.current?.tel ?? '',
      email: email ?? note.current?.email ?? '',
    });
  } catch (error) {
    return thunkAPI.rejectWithValue({ type: 'fail', payload: error });
  }
});

export const clearNoteAsync = createAsyncThunk<void, void, ThunkType>(
  REDUX_CLEAR_NOTE,
  async (_, thunkAPI) => {
    try {
      await notesClient.notes.patchNote({
        tel: '',
        email: '',
      });
    } catch (error) {
      return thunkAPI.rejectWithValue({ type: 'fail', payload: error });
    }
  },
);

export const getNoteAsync = createAsyncThunk<Note, void, ThunkType>(
  REDUX_GET_NOTE,
  async (_, thunkAPI) => {
    const { userInfo } = thunkAPI.getState() as AppState;

    try {
      const res = await notesClient.notes.getNote(userInfo.current.id);

      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ type: 'fail', payload: error });
    }
  },
);

export const getNoteForUserAsync = createAsyncThunk<
  Note | undefined,
  { id: string; orgId: string },
  ThunkType
>(REDUX_GET_NOTE_USER, async ({ id, orgId }, thunkAPI) => {
  try {
    const data = await notesClient.notes.getNote(id, { orgId });
    return data.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response?.status === 403) {
        return thunkAPI.rejectWithValue({
          type: 'permission',
          payload: 'forbidden',
        });
      }
      return thunkAPI.rejectWithValue({
        type: 'fail',
      });
    }

    return thunkAPI.rejectWithValue({ type: 'fail', payload: error });
  }
});

export const getMyNoteGrants = createAsyncThunk<NoteGrant[], void, ThunkType>(
  REDUX_GET_MY_GRANTS,
  async (_, thunkAPI) => {
    try {
      const data = await notesClient.grants.getGrants();
      return data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ type: 'fail', payload: error });
    }
  },
);
