import { Menu } from '@mantine/core';
import { useDisclosure, useMediaQuery } from '@mantine/hooks';
import React, { useState } from 'react';
import { useTheme } from 'styled-components';
import { deviceMax } from '@aclito/ui-web/utils/mediaQueries';
import { useLazyLoadMyEventsQuery } from '@aclito/shared/redux/api/eventApi';

import { MyEventsProps } from '../interface';

import EventCards from './EventCards';

import {
  SectionGrid,
  ActionButton,
  HStack,
  Tab,
  Tabs,
  Text,
  TitleBar,
  Icon,
  Title,
} from '@/components';
import { ROUTE_NEW_EVENT } from '@/util/constants';
import { useFlexWrap } from '@/hooks/useFlexWrap';
import { MyEventsTabType } from '@/features/event/screens/Events/types';
import EventsMap from '@/features/event/screens/Events/components/EventsMap';

const MyEvents: React.FC<MyEventsProps> = ({ navigation, data, nextToken }) => {
  const [tabIndex, setTabIndex] = useState(MyEventsTabType.LIST);
  const [day, { toggle }] = useDisclosure(true);

  const handleTabChange = (val: string) =>
    val === '0'
      ? setTabIndex(MyEventsTabType.LIST)
      : setTabIndex(MyEventsTabType.MAP);

  const [trigger] = useLazyLoadMyEventsQuery();

  const handleShowMore = () => {
    trigger({ nextToken: nextToken });
  };

  const id = 'titleFlexContainer';
  const isFlexWrapped = useFlexWrap(id);
  const queryMobile = useMediaQuery(deviceMax.mobile);

  const theme = useTheme();

  const handleNav = () => navigation(ROUTE_NEW_EVENT);

  return (
    <SectionGrid $isOpen={false}>
      <TitleBar>
        <HStack>
          <Title tx={'tabs.myevents'} />
        </HStack>
        {isFlexWrapped || queryMobile ? (
          <Menu>
            <Menu.Target>
              <ActionButton iconType={'more'} />
            </Menu.Target>
            <Menu.Dropdown
              style={{
                borderRadius: '10px',
                backgroundColor: theme.white,
                color: theme.grey80,
                border: 'none',
              }}
            >
              <Menu.Item
                icon={<Icon icon="add" size={20} />}
                onClick={handleNav}
              >
                <Text tx="create" />
              </Menu.Item>
              {tabIndex === MyEventsTabType.LIST && (
                <Menu.Item
                  icon={<Icon icon="chevronDown" size={20} />}
                  onClick={toggle}
                >
                  <Text tx={day ? 'json.monthly' : 'json.daily'} />
                </Menu.Item>
              )}
            </Menu.Dropdown>
          </Menu>
        ) : (
          <HStack>
            <ActionButton
              tx="create"
              primary
              iconType="add"
              onClick={handleNav}
            />
            {tabIndex === MyEventsTabType.LIST && (
              <ActionButton
                tx={day ? 'json.monthly' : 'json.daily'}
                iconType={'chevronDown'}
                onClick={toggle}
              />
            )}
          </HStack>
        )}
      </TitleBar>

      <Tabs
        selectedIndex={tabIndex === MyEventsTabType.LIST ? '0' : '1'}
        setSelectedIndex={handleTabChange}
      >
        <Tab tx="tabs.list" />
        <Tab tx="tabs.map" />
      </Tabs>

      {data.length ? (
        <>
          {tabIndex === MyEventsTabType.LIST && (
            <EventCards
              {...{
                data,
                nextToken,
                onClick: handleShowMore,
                day: day,
              }}
            />
          )}

          {tabIndex === MyEventsTabType.MAP && <EventsMap {...{ data }} />}
        </>
      ) : (
        <Text style={{ paddingTop: 16 }} tx="flatlist.noeventyet" />
      )}
    </SectionGrid>
  );
};

export default MyEvents;
