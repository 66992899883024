import { UserInfo } from '@aclito/entities';

import { profileSelectors } from '../../../redux/slices/profileSlices';

import { useCreateReportMutation } from '@aclito/shared/redux/api/reportApi';
import { useAppSelector, useSchema } from '@aclito/shared/hooks';

export const useReportUser = (userToReport: UserInfo | undefined) => {
  const userInfo = useAppSelector(profileSelectors.userInfo);
  const { reportUserValidationSchema } = useSchema();
  const initialValues = {
    message: '',
  };
  const [createReport, { isLoading }] = useCreateReportMutation();
  const handleReport = async (data: typeof initialValues) => {
    if (!userToReport) return undefined;

    return await createReport({
      message: `User with id: ${userToReport.id} was reported with message: ${data.message}`,
      reportUserId: userToReport.id,
      reporter: userInfo.id,
    }).unwrap();
  };

  return { reportUserValidationSchema, initialValues, handleReport, isLoading };
};
