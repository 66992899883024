import { FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
export const GET_USER = 'getUser';
export const GET_USER_REVIEWS = 'getUserReviews';
export const GET_USER_STATS = 'getUserStats';
import { userClient } from '@aclito/client';
import { Review, Stats, UserInfo } from '@aclito/entities';

import { aclitoApi } from '../store';

export const userApi = aclitoApi.injectEndpoints({
  endpoints: (builder) => ({
    [GET_USER]: builder.query<{ user: UserInfo; stats: Stats }, { id: string }>(
      {
        queryFn: async (args) => {
          try {
            const data = await userClient.user.getUserProfile(args.id);
            return { data: data.data };
          } catch (error) {
            return { error: { error: 'fail' } as FetchBaseQueryError };
          }
        },
      },
    ),
    [GET_USER_REVIEWS]: builder.query<Review[], { id: string }>({
      queryFn: async (args) => {
        try {
          const data = await userClient.user.getUserReviews(args.id);
          return { data: data.data };
        } catch (error) {
          return { error: { error: 'fail' } as FetchBaseQueryError };
        }
      },
    }),
    [GET_USER_STATS]: builder.query<Stats, { id: string }>({
      queryFn: async (args) => {
        try {
          const data = await userClient.user.getUserStats(args.id);
          return { data: data.data };
        } catch (error) {
          return { error: { error: 'fail' } as FetchBaseQueryError };
        }
      },
    }),
  }),
});

export const { useGetUserQuery, useGetUserReviewsQuery, useGetUserStatsQuery } =
  userApi;
