import { useTranslate } from '@aclito/shared/hooks';
import { TranslateKey } from '@aclito/shared/types';
import { Tabs } from '@mantine/core';
import styled from 'styled-components';

import { Text } from '../Text';

export type ITab = {
  children?: React.ReactNode;
  title?: string;
  tx?: TranslateKey;
  setSelectedIndex?: (val: string) => void;
  value?: string;
  selectedIndex?: string;
};

const Tab: React.FC<ITab> = ({ setSelectedIndex, title, tx, value }) => {
  const t = useTranslate();
  const i18nText = tx && t(tx);
  const text = i18nText || title;

  const handleOnTabClick = () => {
    if (value && setSelectedIndex) setSelectedIndex(value);
  };

  const getOnlyHeader = () => (
    <StyledTab value={value ?? ''} onClick={handleOnTabClick}>
      <Text color="grey40" style={{ textTransform: 'uppercase' }}>
        {text}
      </Text>
    </StyledTab>
  );

  return <>{getOnlyHeader()}</>;
};

const StyledTab = styled(Tabs.Tab)`
  &:hover {
    background-color: transparent;
  }
`;

export default Tab;
