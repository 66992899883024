import { useAppSelector } from '@aclito/shared/hooks';
import {
  useListFeedsByMyOrgsQuery,
  useListFeedsByOrgQuery,
} from '@aclito/shared/redux/api/feedApi';
import { organizationsSelectors } from '@aclito/shared/redux/slices/organizationSlices';
import { Center, Grid, Loader } from '@mantine/core';
import React from 'react';
import { FigureShrugging } from '@aclito/shared/resources';
import styled from 'styled-components';
import { dateSort } from '@aclito/shared/util/sorter';
import { selectMyOrganizations } from '@aclito/shared/redux/api/organizationApi';

import FeedCard from './FeedCard';

import { Text, VStack } from '@/components';

interface FeedListProps {
  orgId?: string;
}

const FeedList: React.FC<FeedListProps> = ({ orgId }) => {
  const feedFilters = useAppSelector(organizationsSelectors.getFeedFilter);
  const myOrgs = useAppSelector(selectMyOrganizations);

  const { data, isFetching } = useListFeedsByOrgQuery(orgId ?? '', {
    skip: !orgId,
  });
  const { data: allData, isFetching: isAllFetching } =
    useListFeedsByMyOrgsQuery(feedFilters.feedOrg.id, {
      skip: !!orgId?.length || !myOrgs.length,
    });

  const feeds = (orgId ? data : allData) ?? [];

  return (
    <>
      {isFetching || isAllFetching ? (
        <Center>
          <Loader />
        </Center>
      ) : (
        <>
          {feeds?.length ? (
            <Grid columns={1} gutter="md">
              {dateSort(feeds, 'updatedAt', 'desc').map((feed) => (
                <Grid.Col key={feed.id}>
                  <FeedCard feed={feed} />
                </Grid.Col>
              ))}
            </Grid>
          ) : (
            <VStack spacing={10}>
              <Text align="center" tx="error.empty.listFeeds" />
              <EmptyListingImage src={FigureShrugging} />
            </VStack>
          )}
        </>
      )}
    </>
  );
};

const EmptyListingImage = styled.img`
  height: 10rem;
`;

export default FeedList;
