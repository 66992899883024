import React, { useRef, useState } from 'react';
import { useDisclosure } from '@mantine/hooks';
import styled, { useTheme } from 'styled-components';

import { NoteItemProps } from '@/features/notes/screens/Notes/types';
import { Button, HStack, Text, VStack, Icon, Input } from '@/components';

const NoteItem: React.FC<NoteItemProps> = ({
  item,
  index,
  handleConfirmClick,
  error,
}) => {
  const [opened, { toggle }] = useDisclosure(false);
  const ref = useRef<HTMLInputElement>(null);

  const [text, setText] = useState(item.value);
  const theme = useTheme();
  return (
    <>
      <HStack style={{ justifyContent: 'space-between' }}>
        <VStack spacing={8}>
          <Text text={item.text} size={'xs'} style={{ color: theme.grey50 }} />
          <HStack>
            {!opened ? (
              <>
                {error ? (
                  <Text style={{ fontSize: 12, color: theme.error }}>
                    {error}
                  </Text>
                ) : (
                  <Text
                    text={text}
                    style={{
                      color: theme.grey80,
                      fontSize: 14,
                    }}
                  />
                )}
              </>
            ) : (
              <Input
                ref={ref}
                onBlur={() => {
                  toggle();
                  handleConfirmClick(text, index);
                }}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setText(e.target.value)
                }
                value={text}
                //@ts-ignore
                size={'xs'}
                data-testid={`${item.text}input`}
              />
            )}
          </HStack>
        </VStack>
        <StyledHStack>
          {!opened ? (
            <InteractiveIcon
              onClick={() => {
                toggle();
                setTimeout(() => {
                  ref?.current?.focus();
                }, 100);
              }}
              icon={'edit'}
              color={'primary'}
              data-testid={`${item.text}edit`}
            />
          ) : (
            <Button
              tx="save"
              onClick={() => {
                toggle();
                handleConfirmClick(text, index);
              }}
              leftIcon={<Icon icon="save" color="whiteText" />}
              data-testid={`${item.text}save`}
            />
          )}
        </StyledHStack>
      </HStack>
    </>
  );
};

const StyledHStack = styled(HStack)`
  margin-top: auto;
`;

const InteractiveIcon = styled(Icon)`
  cursor: pointer;
`;
export default NoteItem;
