import React from 'react';
import { useController } from 'react-hook-form';
import { SelectObject } from '@aclito/shared/types';

import MultiSelect from './MultiSelect';
import { MultiSelectProps } from './types';

const ValidatedMultiSelect: React.FC<MultiSelectProps & { name: string }> = ({
  name,
  ...rest
}) => {
  const {
    field: { value, onChange, onBlur },
    fieldState: { error },
  } = useController({ name });
  return (
    <MultiSelect
      value={value ? value?.map((v: SelectObject) => v.id) : []}
      onChange={(item) => {
        onChange(rest.options.filter((o) => item.some((i) => i === o.id)));
      }}
      name={name}
      onBlur={onBlur}
      error={error?.message}
      {...rest}
    />
  );
};

export default ValidatedMultiSelect;
