import React from 'react';
import { EventWithDateObject } from '@aclito/shared/types';
import { useTranslate } from '@aclito/shared/hooks';
import { formatEventDuration } from '@aclito/shared/features/event/utils/formatEventDuration';

import InfoBuilder from './InfoBuilder';

import { Content } from '@/features/event/styles/eventInfoStyles';
import { openInNewTab } from '@/util/openLinkInNewTab';
import { formatEventWithDate } from '@/util/formatEventWithDateObject';

interface EmbeddedInfoProps {
  event: EventWithDateObject;
}

const EmbeddedInfo: React.FC<EmbeddedInfoProps> = ({ event }) => {
  const t = useTranslate();
  const date = `${event.formattedDate} ${event.formattedTime} - ${event.formattedEndDate}`;
  const formattedEventWithDate = formatEventWithDate(event, t);

  const duration = formatEventDuration(formattedEventWithDate);

  return (
    <Content>
      <InfoBuilder
        data={[
          {
            text: { text: date },
            icon: 'calendar',
            label: 'events.info.date',
          },
          {
            text: { text: duration },
            icon: 'time',
            label: 'events.info.duration',
          },
          {
            text: { text: event.txGender || '' },
            icon: 'gender',
            label: 'events.info.gender',
          },
          {
            text: { text: event.txLevel || '' },
            icon: 'level',
            label: 'events.info.level',
          },
          {
            text:
              event.price === 0
                ? { tx: 'events.info.forFree' }
                : { text: event.price.toString() },
            icon: 'price',
            label: 'events.info.price',
          },
          {
            text: { tx: 'yes' },
            icon: 'multisport',
            label: 'events.info.multisport',
            visible: event.canPayWithMultiSport,
          },
          {
            text: { tx: 'events.info.external.link' },
            icon: 'link',
            label: 'events.info.external.link',
            visible: !!event.externalLink,
            onPress: () => openInNewTab(event.externalLink ?? ''),
          },
          {
            text: { text: event.org?.name || '' },
            icon: 'org',
            label: 'events.info.org',
            visible: !!event.org,
          },
          {
            text: { text: event.info },
            icon: 'edit',
            label: 'events.info.description',
            visible: !!event.info,
          },
        ]}
      />
    </Content>
  );
};

export default EmbeddedInfo;
