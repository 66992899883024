import { Feed } from '@aclito/entities';

import { FeedForm } from '../types/types';

import { useAppSelector } from '@aclito/shared/hooks';
import {
  useCreateFeedMutation,
  useUpdateFeedMutation,
} from '@aclito/shared/redux/api/feedApi';
import { profileSelectors } from '@aclito/shared/redux/slices/profileSlices';
import { getMyOrgsSelectOptions } from '@aclito/shared/util/getMyOrgsSelectOptions';
import { ImageValueShape } from '@aclito/shared/types';
import { selectMyOrganizations } from '@aclito/shared/redux/api/organizationApi';

export const useFeedEdit = (feed: Feed | undefined) => {
  const myOrgs = useAppSelector(selectMyOrganizations);
  const userInfo = useAppSelector(profileSelectors.userInfo);
  const displayedOrg = myOrgs.find((o) => o.id === feed?.feedOrgId);

  const [createFeed] = useCreateFeedMutation();

  const [updateFeed] = useUpdateFeedMutation();

  const orgOptions = getMyOrgsSelectOptions(myOrgs, userInfo.id);

  const createOrUpdateFeed = async (data: FeedForm) => {
    if (feed) {
      await updateFeed({
        id: feed.id,
        text: data.feedText,
        file: data.file,
        orgId: feed.feedOrgId,
      }).unwrap();
    } else {
      if (data.feedOrg?.id.length) {
        await createFeed({
          text: data.feedText,
          feedOrgId: data.feedOrg.id,
          file: data.file,
        }).unwrap();
      }
    }
  };

  const initialValues: FeedForm = feed
    ? {
        feedText: feed.text,
        feedOrg: { item: '', id: '' },
        image: feed.image as ImageValueShape,
        file: undefined,
      }
    : {
        feedText: '',
        feedOrg: displayedOrg
          ? { item: displayedOrg.name, id: displayedOrg.id }
          : undefined,
        image: undefined,
        file: undefined,
      };

  return {
    orgOptions,
    initialValues,
    createOrUpdateFeed,
  };
};
