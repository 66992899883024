import {
  Chat,
  GetChatByEventIdInput,
  GetChatByIdInput,
  ChatIdInput,
} from '@aclito/entities';

import { ContentType, HttpClient } from '../httpClient';

export class ChatClient extends HttpClient {
  chats = {
    getChatByEventId: (data: GetChatByEventIdInput) =>
      this.request<Chat>({
        path: `/chat/hash/${data.eventId}`,
        method: 'GET',
        type: ContentType.Json,
      }),
    getChatById: (data: GetChatByIdInput) =>
      this.request<Chat>({
        path: `/chat/single/${data.id}`,
        method: 'GET',
        type: ContentType.Json,
      }),
    leaveChat: (data: ChatIdInput) =>
      this.request<Chat>({
        path: `/chat/leave/${data.id}`,
        method: 'PATCH',
        type: ContentType.Json,
      }),
    joinChat: (data: ChatIdInput) =>
      this.request<Chat>({
        path: `/chat/join/${data.id}`,
        method: 'PATCH',
        type: ContentType.Json,
      }),
  };
}
