import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { REDUX_RESET } from '../../util/constants';
import { AvailabilityDatesInitialState } from '../initialStates';
import { AvailabilityDates } from '../types';
import { AppState } from '../store';

export const REDUX_AVAILABILITY_DATES = 'availabilityDates';

const SliceAvailabilityDates = createSlice({
  name: REDUX_AVAILABILITY_DATES,
  initialState: AvailabilityDatesInitialState,
  reducers: {
    updateAvailabilityDates: (_, action: PayloadAction<AvailabilityDates>) =>
      action.payload,
    removeAvailabilityDate: (state, action: PayloadAction<number>) => {
      state.date = [...state.date].filter((_, i) => action.payload !== i);
    },
    [REDUX_RESET + REDUX_AVAILABILITY_DATES]: () =>
      AvailabilityDatesInitialState,
  },
});

export const availabilityActions = {
  ...SliceAvailabilityDates.actions,
};
export const availabilityReducers = {
  [REDUX_AVAILABILITY_DATES]: SliceAvailabilityDates.reducer,
};

export const availabilitySelectors = {
  availabilityDates: (state: AppState) => state.availabilityDates,
};
