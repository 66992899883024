import {
  Chat,
  ConfirmEventParams,
  CreateAdminEventInput,
  CreateEventInput,
  Event,
  EventRaw,
  EventsByOrgParams,
  JoinLeaveEventParams,
  JoinLeaveEventRepeatingParams,
  KickFromEventParams,
  Place,
  SearchEventInput,
  UpdateEventInput,
} from '@aclito/entities';
import { EventWithPlayers } from '@aclito/shared/types';

import { ContentType, HttpClient } from '../httpClient';

export class EventClient extends HttpClient {
  admin = {
    upload: (body: CreateAdminEventInput[]) =>
      this.request<void>({
        path: `/events/uploader`,
        method: 'POST',
        body,
        type: ContentType.Json,
      }),
  };

  events = {
    getEvent: (id: string) =>
      this.request<EventWithPlayers>({
        path: `/events/single/${id}`,
        method: 'GET',
        type: ContentType.Json,
      }),

    leaveProviderEvent: (id: string) =>
      this.request<EventRaw>({
        path: `/events/provider/leave/${id}`,
        method: 'PATCH',
        type: ContentType.Json,
      }),

    joinProviderEvent: (id: string) =>
      this.request<EventRaw>({
        path: `/events/provider/join/${id}`,
        method: 'PATCH',
        type: ContentType.Json,
      }),

    deleteEvent: (id: string) =>
      this.request<EventRaw>({
        path: `/events/single/${id}`,
        method: 'DELETE',
        type: ContentType.Json,
      }),

    deleteRepeatingEvent: (repId: string, id: string) =>
      this.request<{ id: string }[]>({
        path: `/events/repeating/${repId}`,
        method: 'DELETE',
        body: { id },
        type: ContentType.Json,
      }),

    getMyEvents: (nextToken: string | null, limit: number, orgId?: string) =>
      this.request<{ data: Event[]; nextToken: string | null }>({
        path: `/events/list`,
        method: 'GET',
        query: { nextToken, limit, orgId },
        type: ContentType.Json,
      }),

    getEventsByOrg: (id: string, query: EventsByOrgParams) =>
      this.request<Event[]>({
        path: `/events/org/${id}`,
        method: 'GET',
        query,
        type: ContentType.Json,
      }),

    searchEvents: (input: SearchEventInput) =>
      this.request<{
        data: EventRaw[];
        total: number;
        nextToken: string | null;
      }>({
        path: `/events/search`,
        method: 'GET',
        query: input,
        type: ContentType.Json,
      }),

    createEvent: (data: CreateEventInput) =>
      this.request<Array<Omit<Event, 'leaderInfo' | 'playerInfo'>>>({
        path: '/events',
        method: 'POST',
        body: data,
        type: ContentType.Json,
      }),

    joinEvent: (id: string, data: JoinLeaveEventParams) =>
      this.request<EventRaw & { chat: Chat; place: Place }>({
        path: `/events/join/${id}`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
      }),

    joinEventRepeating: (repId: string, data: JoinLeaveEventRepeatingParams) =>
      this.request<{ events: EventRaw[]; place: Place }>({
        path: `/events/join/repeating/${repId}`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
      }),

    leaveEvent: (id: string, data: JoinLeaveEventParams) =>
      this.request<EventRaw & { chat: Chat }>({
        path: `/events/leave/${id}`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
      }),

    leaveRepeatingEvent: (repId: string, data: JoinLeaveEventRepeatingParams) =>
      this.request<EventRaw[]>({
        path: `/events/leave/repeating/${repId}`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
      }),

    confirmEvent: (id: string, data: ConfirmEventParams) =>
      this.request<EventRaw>({
        path: `/events/confirm/${id}`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
      }),

    kickFromEvent: (id: string, data: KickFromEventParams) =>
      this.request<EventRaw & { chat: Chat }>({
        path: `/events/kick/${id}`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
      }),

    updateEvent: (id: string, data: UpdateEventInput) =>
      this.request<EventRaw & { place: Place }>({
        path: `/events/single/${id}`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
      }),

    eventHistory: () =>
      this.request<Event[]>({
        path: `/events/history`,
        method: 'GET',
        type: ContentType.Json,
      }),

    updateRepeatingEvent: (id: string, data: UpdateEventInput) =>
      this.request<{ events: EventRaw[]; place: Place }>({
        path: `/events/repeating/${id}`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
      }),
  };
}
