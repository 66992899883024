import React from 'react';
import { useAvailabilityInfo } from '@aclito/shared/features/availability/hooks/useAvailabilityInfo';
import styled from 'styled-components';

import { InfoProps } from '../interface';

import { Text, VStack } from '@/components';
import AvailabilityTags from '@/components/Cards/AvailabilityTags';

const Info: React.FC<InfoProps> = ({ availability }) => {
  const { activities, date } = useAvailabilityInfo(availability);

  return (
    <InfoContainer spacing={8}>
      <Data text={date} />
      <AvailabilityTags {...{ activities }} />
    </InfoContainer>
  );
};

const InfoContainer = styled(VStack)`
  height: 100%;
  width: 100%;
`;

const Data = styled(Text)`
  color: ${({ theme }) => theme.grey80};
  font-family: Roboto;
  font-size: 1.25rem;
  font-weight: 500;
`;

export default Info;
