import { Grid } from '@mantine/core';
import {
  columnsFullWidth,
  columnsThreeFourths,
} from '@aclito/ui-web/utils/mediaQueries';

interface GridColumnProps {
  fullWidth: boolean;
  children?: React.ReactNode;
}
const GridColumn: React.FC<GridColumnProps> = ({ children, fullWidth }) => {
  const columns = fullWidth ? columnsFullWidth : columnsThreeFourths;
  return (
    <Grid.Col
      xl={columns.xl}
      lg={columns.lg}
      md={columns.md}
      sm={columns.sm}
      xs={columns.xs}
    >
      {children}
    </Grid.Col>
  );
};

export default GridColumn;
