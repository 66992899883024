import { useTranslate } from '@aclito/shared/hooks';
import { ActionIcon, Center, Loader, Menu } from '@mantine/core';
import { useEventInfoProviders } from '@aclito/shared/features/event/hooks/useEventInfoProviders';
import { formatEvent } from '@aclito/shared/features/event/utils/formatEvents';
import { formatEventDuration } from '@aclito/shared/features/event/utils/formatEventDuration';
import styled, { useTheme } from 'styled-components';
import { deviceMax } from '@aclito/ui-web/utils/mediaQueries';
import { useDisplayEvent } from '@aclito/shared/redux/api/eventApi';
import React from 'react';

import { InfoRight } from '../EventInfo/components';
import JoinLeaveButton from '../EventInfo/components/JoinLeaveButton';
import InfoBuilder from '../EventInfo/components/InfoBuilder';

import {
  SectionGrid,
  HStack,
  Text,
  TitleBar,
  Icon,
  Title,
  BackButton,
} from '@/components';
import { TypedLocation } from '@/hooks/useLocation';
import { StateEventInfoProviders } from '@/util/types';
import withRouter from '@/navigation/withRouter';
import { providerImage } from '@/util/providerData';
import { useNavigate } from '@/hooks/useNavigate';
import {
  Content,
  DisplayFull,
  DisplayMobile,
  EventHeader,
  EventName,
} from '@/features/event/styles/eventInfoStyles';
import { openInNewTab } from '@/util/openLinkInNewTab';

type EventInfoProps = TypedLocation<StateEventInfoProviders, { id: string }>;
const EventInfoProviders: React.FC<EventInfoProps> = ({ state, query }) => {
  const id = query?.id ? query.id : state?.id;

  const { model, isFetching } = useDisplayEvent(id);

  const nav = useNavigate();
  const t = useTranslate();
  const theme = useTheme();

  const { addEventToMyEvents, removeEventFromMyEvents } =
    useEventInfoProviders();

  if (!model?.event || isFetching) {
    return (
      <Center>
        <Loader />
      </Center>
    );
  }
  const goBack = () => nav(-1);

  const event = formatEvent(model.event);
  const duration = formatEventDuration(event);
  const date = `${event.formattedDate} ${event.formattedTime} - ${event.formattedEndDate}`;

  if (!model.event) return null;

  const removeEvent = () => {
    removeEventFromMyEvents(model.event, goBack);
  };
  const addEvent = () => {
    addEventToMyEvents(model.event);
  };

  return (
    <SectionGrid $isOpen={false}>
      <TitleBar>
        <HStack>
          <BackButton />
          <Title tx="nav.eventInfo" />
        </HStack>
      </TitleBar>

      <EventHeader>
        <EventName>{model.event.name}</EventName>

        <DisplayMobile position="bottom-end">
          <Menu.Target>
            <ActionIcon
              variant="filled"
              size="xl"
              style={{
                backgroundColor: `${theme.primary}`,
                borderRadius: '10px',
              }}
            >
              <Icon icon="more" color={'whiteText'} size={24} />
            </ActionIcon>
          </Menu.Target>
          <Menu.Dropdown
            style={{
              borderRadius: '10px',
              backgroundColor: theme.white,
              color: theme.grey80,
              border: 'none',
            }}
          >
            {!model.isJoined && model.canJoin && (
              <Menu.Item
                icon={<Icon icon="add" size={20} />}
                onClick={addEvent}
              >
                <Text>{t('events.info.join.provider')}</Text>
              </Menu.Item>
            )}
            {model.canLeave && (
              <Menu.Item
                icon={<Icon icon="remove" size={20} />}
                onClick={removeEvent}
              >
                <Text>{t('events.info.leave.provider')}</Text>
              </Menu.Item>
            )}
          </Menu.Dropdown>
        </DisplayMobile>
        <DisplayFull>
          {!model.isJoined && model.canJoin && (
            <JoinLeaveButton onClick={addEvent}>
              <Icon icon="add" size={30} color="whiteText" />
              <Text tx={'events.info.join.provider'} color="white" />
            </JoinLeaveButton>
          )}
          {model.canLeave && (
            <JoinLeaveButton onClick={removeEvent}>
              <Icon icon="remove" size={30} color="whiteText" />
              <Text tx={'events.info.leave.provider'} color="white" />
            </JoinLeaveButton>
          )}
        </DisplayFull>
      </EventHeader>
      <InfoContent>
        <Content>
          <InfoBuilder
            data={[
              {
                text: { text: date },
                icon: 'calendar',
                label: 'events.info.date',
              },
              {
                text: { text: duration },
                icon: 'time',
                label: 'events.info.duration',
              },
              {
                text: { text: event?.place?.address || '' },
                icon: 'place',
                label: 'events.info.place',
                visible: !!event?.place?.address,
              },
              {
                text: { text: event.externalLink ?? '' },
                icon: 'place',
                label: 'events.info.external.link.provider',
                visible: !!event.externalLink,
                onPress: () => openInNewTab(event.externalLink ?? ''),
              },
              {
                text: { text: event.info || '' },
                icon: 'edit',
                label: 'events.info.description',
                visible: !!event.info,
              },
            ]}
          />
        </Content>
        {event.place && <InfoRight model={model} />}
      </InfoContent>
      <img
        src={providerImage[model.event.provider]}
        style={{ width: 'auto', height: 100, marginTop: 5 }}
        alt={'Logo of event provider'}
      />
    </SectionGrid>
  );
};

const InfoContent = styled(HStack)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  height: auto;
  @media ${deviceMax.desktopL} {
    display: flex;
  }
`;

export default withRouter(EventInfoProviders);
