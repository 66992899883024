import { EventModel } from '@aclito/shared/classes/EventModel';
import { useEventInfoAliasJoin } from '@aclito/shared/features/event/hooks/useEventInfoAliasesJoin';
import React from 'react';

import AliasModalJoinItem from './AliasModalJoinItem';

import PlayerSelectModal from '@/common/Modals/PlayerSelectModal';

interface AliasModalProps {
  model: EventModel;
  opened: boolean;
  onClose: () => void;
  handleJoinAliases: (model: EventModel, selectedIds: string[]) => void;
}

const AliasModalJoin: React.FC<AliasModalProps> = ({
  model,
  opened,
  onClose,
  handleJoinAliases,
}) => {
  const {
    isSelected,
    isSelectedQueue,
    isAliasJoined,
    isAliasInQueue,
    handleOnAliasPress,
    selectedIds,
    selectedQueueIds,
    topText,
    itemBg,
    setSelectedIds,
    setSelectedQueueIds,
  } = useEventInfoAliasJoin(model);
  const { userInfo } = model;

  const handleJoinButtonClick = () => {
    handleJoinAliases(model, [...selectedIds, ...selectedQueueIds]);
    setSelectedIds([]);
    setSelectedQueueIds([]);
    onClose();
  };

  return (
    <PlayerSelectModal
      data={[...userInfo.aliases].concat(
        model.event.leader !== userInfo.id ? [userInfo] : [],
      )}
      renderItem={(alias) => (
        <AliasModalJoinItem
          {...{
            alias,
            isSelected,
            isSelectedQueue,
            isAliasJoined,
            isAliasInQueue,
            handleOnAliasPress,
            itemBg,
          }}
        />
      )}
      topText={topText}
      opened={opened}
      onClose={onClose}
      buttonProps={{
        disabled: !selectedIds.length && !selectedQueueIds.length,
        onClick: handleJoinButtonClick,
        tx: 'events.info.alias.join.button',
      }}
    />
  );
};

export default AliasModalJoin;
