import { Group, GroupProps } from '@mantine/core';
import React from 'react';

type HStackProps = GroupProps;

const HStack: React.FC<HStackProps> = ({ children, ...rest }) => (
  <Group {...rest}>{children}</Group>
);

export default HStack;
