import { GoogleMap, useJsApiLoader, Circle } from '@react-google-maps/api';
import { Box } from '@mantine/core';
import React, { useEffect, useRef, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { AvailabilityForm, UserLocation } from '@aclito/shared/types';
import { calculateCircleRadius } from '@aclito/shared/features/availability/utils/calculateCircleRadius';
import { useFormContext } from 'react-hook-form';
import { useAppSelector } from '@aclito/shared/hooks';
import { locationSelectors } from '@aclito/shared/redux/slices/locationSlice';

import MapGuide from '../Maps/MapGuide';

import { HStack, VStack } from '@/components';
import { MAP_API_KEY, MAP_BORDER_RADIUS } from '@/util/constants';

const Place: React.FC = () => {
  const { setValue } = useFormContext<AvailabilityForm>();
  const { isLoaded: isMapLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: MAP_API_KEY,
  });
  const mapRef = useRef(null);
  const circleRadius = calculateCircleRadius();

  const userLocation = useAppSelector(locationSelectors.userLocation);

  const [currentCenter] = useState<UserLocation>(userLocation);

  const [circleCenter, setCircleCenter] = useState({
    lat: userLocation.latitude,
    lng: userLocation.longitude,
  });

  const onMapClick = (event: google.maps.MapMouseEvent) => {
    const latitude = event?.latLng?.lat();
    const longitude = event?.latLng?.lng();

    if (latitude && longitude) {
      setCircleCenter({
        lat: latitude,
        lng: longitude,
      });

      setValue('location', {
        lat: latitude,
        lon: longitude,
      });
    }
  };

  useEffect(() => {
    setValue('location', {
      lat: userLocation.latitude,
      lon: userLocation.longitude,
    });
  }, [setValue, userLocation.latitude, userLocation.longitude]);

  const theme = useTheme();

  return (
    <>
      {isMapLoaded && (
        <VStack>
          <ContainerPlace>
            <Box sx={{ position: 'relative', zIndex: 1, width: '100%' }}>
              <GoogleMap
                onClick={onMapClick}
                ref={mapRef}
                options={{
                  streetViewControl: false,
                  fullscreenControl: false,
                }}
                mapContainerStyle={mapContainer}
                center={currentCenter}
                zoom={7}
              >
                <Circle
                  radius={circleRadius}
                  center={circleCenter}
                  options={{
                    fillColor: theme.mapCircle,
                    strokeColor: theme.mapCircleStroke,
                    strokeWeight: 1,
                    fillOpacity: 1,
                  }}
                  onClick={onMapClick}
                />
              </GoogleMap>
            </Box>
            <MapGuide />
          </ContainerPlace>
        </VStack>
      )}
    </>
  );
};

const mapContainer = {
  height: 400,
  borderRadius: MAP_BORDER_RADIUS,
};

const ContainerPlace = styled(HStack)`
  align-items: flex-start;
  justify-content: center;
  width: 100%;
`;

export default Place;
