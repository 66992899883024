import { FormattedUserInfo } from '@aclito/shared/types';
import React from 'react';

import { AboutUserMain } from '@/common/Profile';

interface DeletedUserProfileProps {
  userInfo: FormattedUserInfo;
}

const DeletedUserProfile: React.FC<DeletedUserProfileProps> = ({
  userInfo,
}) => {
  return <AboutUserMain userInfo={userInfo} deleted />;
};

export default DeletedUserProfile;
