import { EventPlayer, UserInfo } from '@aclito/entities';

export const filterJoinedAliases = (
  players: EventPlayer[],
  leader: string,
  userInfo: UserInfo,
) =>
  players
    .filter((p) => p.id === userInfo.id && !p.isTrainer)
    .map((player) =>
      player.aliasId
        ? userInfo.aliases.find((alias) => alias.id === player.aliasId)
        : userInfo,
    )
    .filter(Boolean)
    .filter((p) => p.id !== leader);
