import { EventPlayer, UserInfo, Event } from '@aclito/entities';

import { EventWithPlayers } from '@aclito/shared/types';

export const mapFeedbackEvent = (
  event: Event,
  pool: UserInfo[],
): EventWithPlayers => {
  const _mapAlias = (player: EventPlayer) => {
    const foundUser = pool.find((user) => user.id === player.id);
    const foundAlias = foundUser?.aliases.find(
      (alias) => alias.id === player.aliasId,
    );

    if (foundAlias && foundUser) {
      return {
        ...foundUser,
        name: foundAlias.name,
        image: foundAlias.image,
        surname: foundAlias.surname,
        aliasId: foundAlias.id,
      };
    }
  };

  const playersInfo = event.players
    .map((player) => {
      if (player.aliasId) {
        return _mapAlias(player);
      }

      return pool.find((user) => user.id === player.id);
    })
    .filter(Boolean);

  return {
    ...event,
    leaderInfo: null as any,
    playersInfo,
    queuePlayersInfo: [],
  };
};
