import React from 'react';
import styled from 'styled-components';
import { EventModel } from '@aclito/shared/classes/EventModel';
import { deviceMax } from '@aclito/ui-web/utils/mediaQueries';

import ViewOnlyMap from '@/common/Maps/ViewOnlyMap';

type InfoRightProps = { model: EventModel };

const InfoRight: React.FC<InfoRightProps> = ({ model }) => {
  const { event } = model;
  return (
    <Content>
      {event && (
        <ViewOnlyMap
          type="marker"
          location={event.place?.location ?? { lat: 0, lon: 0 }}
          name={event.name}
        />
      )}
    </Content>
  );
};

const Content = styled.div`
  height: 100%;
  @media ${deviceMax.desktopL} {
    width: 100%;
    height: 300px;
  }
`;

export default InfoRight;
