import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';
import { Notification } from '@aclito/entities';

import { NotificationsInitialState } from '../initialStates';
import { REDUX_RESET } from '../../util/constants';
import {
  deleteAllNotificationsAsync,
  deleteDBNotificationAsync,
  loadNotificationsAsync,
} from '../thunks/notificationThunks';
import { AppState } from '../store';

export const REDUX_NOTIFICATIONS = 'notifications';

const SliceNotifications = createSlice({
  name: REDUX_NOTIFICATIONS,
  initialState: NotificationsInitialState,
  reducers: {
    addNotification: (state, action: PayloadAction<Notification>) => {
      if (!state?.current?.items) {
        return;
      }

      state.current.items.push(action.payload);
    },

    [REDUX_RESET + REDUX_NOTIFICATIONS]: () => NotificationsInitialState,
  },
  extraReducers: (builder) => {
    builder.addCase(loadNotificationsAsync.fulfilled, (state, action) => {
      state.current.items = action.payload;
      state.isLoading = false;
    });
    builder.addCase(loadNotificationsAsync.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(deleteDBNotificationAsync.fulfilled, (state, action) => {
      state.isLoading = false;

      state.current.items = state.current.items
        .filter(Boolean)
        .filter((n) => n.id !== action.meta.arg);
    });

    builder.addCase(deleteDBNotificationAsync.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(deleteAllNotificationsAsync.fulfilled, (state) => {
      state.current.items = [];
      state.isLoading = false;
    });
    builder.addCase(deleteAllNotificationsAsync.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addMatcher(
      isAnyOf(
        deleteDBNotificationAsync.pending,
        loadNotificationsAsync.pending,
        deleteAllNotificationsAsync.pending,
      ),
      (state) => {
        state.isLoading = true;
      },
    );
  },
});

export const notificationActions = {
  ...SliceNotifications.actions,
  deleteDBNotificationAsync,
  loadNotificationsAsync,
  deleteAllNotificationsAsync,
};

export const notificationReducers = {
  [REDUX_NOTIFICATIONS]: SliceNotifications.reducer,
};

export const notificationSelectors = {
  isLoading: (state: AppState) => state.notifications.isLoading,
  notifications: (state: AppState) => state.notifications.current ?? [],
  getNotification: (state: AppState): Notification[] =>
    state.notifications.current.items.filter(Boolean) ?? [],
};
