import {
  Achievement,
  AchievementOrgId,
  CreateAchievementInput,
  UpdateAchievementInput,
  UserAchievement,
} from '@aclito/entities';

import { ContentType, HttpClient } from '../httpClient';

export class AchievementClient extends HttpClient {
  user = {
    getAllUserAchievements: (id: string, orgId?: string) =>
      this.request<UserAchievement[]>({
        path: `/achievements/user/${id}`,
        method: 'GET',
        query: { orgId },
        type: ContentType.Json,
      }),
  };
  achievements = {
    getAll: (orgId: AchievementOrgId) =>
      this.request<Achievement[]>({
        path: '/achievements',
        method: 'GET',
        query: { orgId },
        type: ContentType.Json,
      }),
    getOne: (id: string) =>
      this.request<Achievement>({
        path: `/achievements/single/${id}`,
        method: 'GET',
        type: ContentType.Json,
      }),
    deleteOne: (id: string) =>
      this.request<Achievement[]>({
        path: `/achievements/single/${id}`,
        method: 'DELETE',
        type: ContentType.Json,
      }),

    create: (body: CreateAchievementInput) =>
      this.request<Achievement>({
        path: '/achievements',
        method: 'POST',
        body,
      }),
    updateOne: (body: UpdateAchievementInput, id: string) =>
      this.request<Achievement>({
        path: `/achievements/single/${id}`,
        method: 'PATCH',
        body,
      }),
  };
}
