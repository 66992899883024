import { EventRaw } from '@aclito/entities';

import { UserInfoWithAlias } from '@aclito/shared/types';

export const willUserWithAliasParticipate = (
  player: UserInfoWithAlias,
  event: EventRaw,
) =>
  event.players.find((p) => {
    if (player.aliasId) {
      return player.aliasId === p.aliasId;
    }

    return p.id === player.id;
  })?.willParticipate;
