import styled from 'styled-components';

import { deviceMax, deviceMin, sizeMax } from '../../utils/mediaQueries';

import HStack from './HStack';

export const LeftSectionTitle = styled(HStack)`
  justify-content: space-between;
`;
export const SectionGrid = styled.div<{ $isOpen: boolean }>`
  margin: auto;
  max-width: ${sizeMax.desktopXL}px;
  display: grid;
  ${(props) =>
    !props.$isOpen
      ? 'grid-template-columns: 1fr'
      : 'grid-template-columns: 400px 1fr '};
  column-gap: 2rem;
  @media ${deviceMax.laptop} {
    grid-template-columns: 1fr;
  }
`;
export const TitleButtons = styled.div`
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
`;
export const LeftSectionLarge = styled.div`
  @media ${deviceMax.laptop} {
    display: none;
  }
  padding-right: 2rem;
  border-right: 1px solid ${({ theme }) => theme.grey10};
`;
export const LeftSectionSmall = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  z-index: 3;
  top: 0;
  bottom: 0;
  left: 70px;
  background-color: ${({ theme }) => theme.background};
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  padding: 2rem;
  @media ${deviceMin.laptop} {
    display: none;
  }
  @media ${deviceMax.tablet} {
    left: 0;
    top: 3.5rem;
  }
  @media ${deviceMax.mobile} {
    width: 100%;
  }
`;
