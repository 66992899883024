import styled from 'styled-components';

const AliasFormScreen = styled.div`
  background-color: ${({ theme }) => theme.background};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export { AliasFormScreen };
