import SVG from '@aclito/shared/util/SVG';
import styled from 'styled-components';
import React from 'react';
import DefaultAvatar from '@aclito/shared/resources/default-person.svg';
import { ImageValueShape } from '@aclito/shared/types';

import S3Image from '../S3Image';

interface AvatarProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  size: number | string;
  image: ImageValueShape;
  timeStamp?: string;
}

const Avatar: React.FC<AvatarProps> = ({ size, image, timeStamp, ...rest }) => {
  if (!image) {
    return <AvatarPlaceholder {...{ size }} />;
  }
  const picType = image.slice(0, 5) ?? '';
  const picValue = image.slice(5) ?? '';

  return (
    <div {...rest}>
      {picType === 'BASIC' && (
        <AvatarView {...{ size }}>
          <DefaultImage src={DefaultAvatar} {...{ size }} />
        </AvatarView>
      )}
      {picType === 'FILE:' && (
        <AvatarView {...{ size }}>
          <S3Image
            imageURL={timeStamp ? picValue + `?ts=${timeStamp}` : picValue}
          />
        </AvatarView>
      )}
      {picType === 'SEED:' && (
        <AvatarView
          {...{ size }}
          dangerouslySetInnerHTML={{
            __html: `${SVG(picValue)}`,
          }}
        />
      )}
    </div>
  );
};

const AvatarView = styled.div<{ size: number | string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  :hover {
    cursor: pointer;
  }

  min-height: ${(props) => props.size}px;
  max-height: ${(props) => props.size}px;
  min-width: ${(props) => props.size}px;
  max-width: ${(props) => props.size}px;
`;

const DefaultImage = styled.img<{ size: number | string }>`
  min-height: ${(props) => props.size}px;
  max-height: ${(props) => props.size}px;
  min-width: ${(props) => props.size}px;
  max-width: ${(props) => props.size}px;
`;

const AvatarPlaceholder = styled.div<{ size: number | string }>`
  min-height: ${(props) => props.size}px;
  max-height: ${(props) => props.size}px;
  min-width: ${(props) => props.size}px;
  max-width: ${(props) => props.size}px;
  border-radius: 100%;
  background-color: ${({ theme }) => theme.grey20};
`;

export default Avatar;
