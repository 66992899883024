import { FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import {
  Availability,
  Event,
  CreateAvailabilityInput,
  DeleteAvailabilityInput,
  UpdateAvailabilityInput,
} from '@aclito/entities';
import { availabilityClient } from '@aclito/client';

import { aclitoApi, AppState } from '../store';
import { assertObjectExists } from '../../util/assertions';

import { findAllAndReplace } from '@aclito/shared/util/findAndReplace';

export const CREATE_AVAILABILITY = 'createAvailability';
export const UPDATE_AVAILABILITY = 'updateAvailability';
export const DELETE_AVAILABILITY = 'deleteAvailability';
export const LIST_AVAILABILITIES = 'listAvailabilities';
export const SUGGEST_EVENTS = 'suggestEvents';

export const availabilityApi = aclitoApi
  .enhanceEndpoints({ addTagTypes: ['listAvailabilities'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      [SUGGEST_EVENTS]: builder.query<Event[], string>({
        queryFn: async (args) => {
          try {
            const { data } =
              await availabilityClient.availabilities.suggestEvents(args);
            return { data: data };
          } catch (error) {
            return { error: { error: 'fail' } as FetchBaseQueryError };
          }
        },
      }),
      [CREATE_AVAILABILITY]: builder.mutation<
        Availability[],
        Omit<CreateAvailabilityInput, 'userID'>
      >({
        queryFn: async (inputData) => {
          try {
            const { data } =
              await availabilityClient.availabilities.createAvailability(
                inputData,
              );
            assertObjectExists(data);
            return { data: data };
          } catch (error) {
            return { error: { error: 'fail' } as FetchBaseQueryError };
          }
        },
        async onQueryStarted(_, { dispatch, queryFulfilled }) {
          try {
            const { data: createdAvailabilities } = await queryFulfilled;
            dispatch(
              availabilityApi.util.updateQueryData(
                'listAvailabilities',
                { nextToken: null, refetch: undefined },
                (availabilityCache) => ({
                  result: [
                    ...createdAvailabilities,
                    ...availabilityCache.result,
                  ],
                  nextToken: availabilityCache.nextToken,
                }),
              ),
            );
          } catch (error) {
            return;
          }
        },
      }),
      [UPDATE_AVAILABILITY]: builder.mutation<
        Availability,
        UpdateAvailabilityInput
      >({
        queryFn: async (args) => {
          try {
            const { data } =
              await availabilityClient.availabilities.updateAvailability(args);
            assertObjectExists(data);
            return { data: data };
          } catch (error) {
            return { error: { error: 'fail' } as FetchBaseQueryError };
          }
        },
        async onQueryStarted(_, { dispatch, queryFulfilled }) {
          try {
            const { data: updatedAvailability } = await queryFulfilled;
            dispatch(
              availabilityApi.util.updateQueryData(
                'listAvailabilities',
                { nextToken: null, refetch: undefined },
                (availabilityCache) => ({
                  result: findAllAndReplace(
                    availabilityCache.result,
                    updatedAvailability,
                    (a) => a.id === updatedAvailability.id,
                  ),
                  nextToken: availabilityCache.nextToken,
                }),
              ),
            );
          } catch (error) {
            return;
          }
        },
      }),
      [DELETE_AVAILABILITY]: builder.mutation<void, DeleteAvailabilityInput>({
        queryFn: async (args) => {
          try {
            await availabilityClient.availabilities.deleteAvailability({
              availabilityId: args.availabilityId,
            });
            return { data: undefined };
          } catch (error) {
            return { error: { error: 'fail' } as FetchBaseQueryError };
          }
        },
        async onQueryStarted(args, { dispatch, queryFulfilled }) {
          const deleteResult = dispatch(
            availabilityApi.util.updateQueryData(
              'listAvailabilities',
              {
                nextToken: null,
                refetch: undefined,
              },
              (availabilityCache) => ({
                result: availabilityCache.result.filter(
                  (a) => a.id !== args.availabilityId,
                ),
                nextToken: availabilityCache.nextToken,
              }),
            ),
          );
          try {
            await queryFulfilled;
          } catch (error) {
            deleteResult.undo();
          }
        },
      }),
      [LIST_AVAILABILITIES]: builder.query<
        { result: Availability[]; nextToken: string | null },
        { nextToken: string | null; refetch?: boolean }
      >({
        queryFn: async (args) => {
          try {
            const { data } =
              await availabilityClient.availabilities.listAvailabilities({
                nextToken: args.nextToken,
                limit: 50,
              });
            assertObjectExists(data);
            const result = data.data;
            const nextToken = data.nextToken;
            return { data: { result, nextToken } };
          } catch (error) {
            return { error: { error: 'fail' } as FetchBaseQueryError };
          }
        },
        serializeQueryArgs: ({ queryArgs }) => {
          const { nextToken, refetch, ...rest } = queryArgs;
          return rest;
        },
        merge: (currentData, { result, nextToken }, { arg }) => {
          if (arg.refetch) {
            currentData.result = result;
          } else {
            currentData.result.push(...result);
          }
          currentData.nextToken = nextToken;
        },
        forceRefetch({ currentArg, previousArg }) {
          return !!currentArg?.nextToken && currentArg !== previousArg;
        },
        providesTags: ['listAvailabilities'],
      }),
    }),
  });

export const selectAvailabilities = () => (state: AppState) =>
  availabilityApi.endpoints.listAvailabilities.select({
    nextToken: null,
    refetch: undefined,
    // @ts-expect-error https://github.com/reduxjs/redux-toolkit/issues/2484
  })(state)?.data?.result;

export const {
  useCreateAvailabilityMutation,
  useUpdateAvailabilityMutation,
  useSuggestEventsQuery,
  useDeleteAvailabilityMutation,
  useListAvailabilitiesQuery,
  useLazyListAvailabilitiesQuery,
} = availabilityApi;
