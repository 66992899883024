import React from 'react';
import { FormattedUserInfo } from '@aclito/shared/types';

import { HStack, ActionButton } from '@/components';
import { ROUTE_REPORT_USER } from '@/util/constants';
import { useNavigate } from '@/hooks/useNavigate';

interface ButtonsProps {
  userInfo: FormattedUserInfo;
  canBeReported: boolean;
}

const Buttons: React.FC<ButtonsProps> = ({ userInfo, canBeReported }) => {
  const nav = useNavigate();

  return (
    <HStack>
      {canBeReported && (
        <ActionButton
          onClick={() => {
            nav(ROUTE_REPORT_USER, { state: { userToReport: userInfo } });
          }}
          iconType={'report'}
        />
      )}
    </HStack>
  );
};

export default Buttons;
