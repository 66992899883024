import { Text } from '@aclito/ui-web/components/Text';
import styled from 'styled-components';

const Status = () => {
  return (
    <Container>
      <Text tx={'maintenance'} />
    </Container>
  );
};

const Container = styled.div`
  height: 100vh;
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
`;

export default Status;
