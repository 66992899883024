import { useState } from 'react';

import { CodeType } from '@/features/integration/utils/verificationErrorCodes';
import { resendCodeHeaders } from '@/features/integration/utils/verifyHeaders';

const url = 'https://cognito-idp.eu-central-1.amazonaws.com/';

export const useVerify = () => {
  const [response, setResponse] = useState<CodeType>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState(false);

  const sendRequest = async (
    headers: HeadersInit,
    body: string,
    welcome: boolean,
  ) => {
    try {
      let result: CodeType;
      setHasError(false);
      const resp = await fetch(url, {
        method: 'POST',
        body,
        headers,
      });

      if (resp.ok) {
        if (welcome) {
          setResponse('Welcome'), (result = 'Welcome');
        } else {
          setResponse('OK'), (result = 'OK');
        }
      } else {
        const data = (await resp.json()) as { __type: CodeType };
        const error = data.__type;
        result = error;
        setHasError(true);
        setResponse(error);
      }

      setIsLoading(false);
      return result;
    } catch (error) {
      setIsLoading(false);
      setHasError(true);
      setResponse('Unknown');
    }
  };

  const resendRequest = (body: object) => {
    setResponse(undefined);
    setIsLoading(true);
    sendRequest(resendCodeHeaders, JSON.stringify(body), false);
  };

  return {
    sendRequest,
    isLoading,
    hasError,
    response,
    setIsLoading,
    resendRequest,
  };
};
