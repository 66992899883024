import * as React from 'react';
import {
  AppShell,
  Burger,
  Header,
  MediaQuery,
  Navbar,
  Text,
} from '@mantine/core';
import styled, { useTheme } from 'styled-components';
import { Link } from 'react-router-dom';
import { profileSelectors } from '@aclito/shared/redux/slices/profileSlices';
import { useAppSelector } from '@aclito/shared/hooks';
import { getUserData } from '@aclito/shared/util/cognitoUser';
import AclitoLogo from '@aclito/shared/resources/icons/aclito.svg';
import { useEffect, useState } from 'react';
import { useMediaQuery } from '@mantine/hooks';
import { deviceMax, deviceMin } from '@aclito/ui-web/utils/mediaQueries';

import withData from '../../navigation/withData';

import { DrawerProps } from './interface';
import { Footer as BottomDrawer } from './components/Footer';
import { Header as TopDrawer } from './components/Header';

import { Avatar, VStack, HStack } from '@/components';
import { ROUTE_PROFILE, ROUTE_EVENTS } from '@/util/constants';
import { useOnClickOutside } from '@/hooks/useOnClickOutside';

const PermanentDrawer: React.FC<DrawerProps> = ({ signOut, children }) => {
  const [opened, setOpened] = React.useState(false);
  const userInfo = useAppSelector(profileSelectors.userInfo);
  const [email, setEmail] = useState('');

  const navRef = React.useRef(null);
  const toggleRef = React.useRef(null);

  const matches = useMediaQuery(deviceMin.tablet);

  const theme = useTheme();

  useEffect(() => {
    getUserData().then((data) => setEmail(data.email));
  }, []);

  useOnClickOutside([navRef, toggleRef], () => {
    setOpened(false);
  });

  return (
    <AppShell
      styles={{
        main: {
          background: theme.background,
          height: '100%',
          width: '100%',
        },
        body: {
          width: '100%',
        },
        root: {
          width: '100%',
        },
      }}
      padding="xl"
      navbarOffsetBreakpoint="sm"
      navbar={
        <Navbar
          style={{
            overflowY: 'auto',
            borderRight: `0.0625rem solid ${theme.grey10}`,
          }}
          hiddenBreakpoint="sm"
          hidden={!opened}
          bg={theme.white}
          width={{ sm: 72, lg: 280, xs: 250 }}
          ref={navRef}
        >
          <HStack style={{ justifyContent: 'space-between', padding: '16px' }}>
            {matches && (
              <AppHeader to={ROUTE_EVENTS} onClick={() => setOpened(false)}>
                <img src={AclitoLogo} style={{ width: '40px' }} />
                <AppHeaderText>ACLITO</AppHeaderText>
              </AppHeader>
            )}
          </HStack>

          <>
            {userInfo && (
              <StyledLink to={ROUTE_PROFILE} onClick={() => setOpened(false)}>
                <AvatarView>
                  <Avatar
                    size={40}
                    image={userInfo.image}
                    timeStamp={userInfo.updatedAt}
                  />
                </AvatarView>
                <VStack spacing={0}>
                  <StyledText>{`${userInfo.name} ${userInfo.surname}`}</StyledText>
                  <StyledTextEmail color={theme.white}>{email}</StyledTextEmail>
                </VStack>
              </StyledLink>
            )}
          </>

          <Navbar.Section grow>
            <TopDrawer closeNavigation={() => setOpened(false)} />
          </Navbar.Section>

          <Navbar.Section>
            <BottomDrawer
              signOut={signOut}
              closeNavigation={() => setOpened(false)}
            />
          </Navbar.Section>
        </Navbar>
      }
      header={
        <>
          {!matches && (
            <HeaderWrapper height={60} p="md">
              <MediaQuery largerThan="sm" styles={{ display: 'none' }}>
                <>
                  <AppHeader to={ROUTE_EVENTS} onClick={() => setOpened(false)}>
                    <img src={AclitoLogo} style={{ width: '40px' }} />
                    <AppHeaderText>ACLITO</AppHeaderText>
                  </AppHeader>
                  <Burger
                    opened={opened}
                    onClick={() => setOpened((o) => !o)}
                    size="sm"
                    color={theme.yellow}
                    mr="xl"
                    ref={toggleRef}
                  />
                </>
              </MediaQuery>
            </HeaderWrapper>
          )}
        </>
      }
    >
      {children}
    </AppShell>
  );
};

const AppHeader = styled(Link)`
  display: flex;
  gap: 8px;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  @media (${deviceMax.desktop}) {
    justify-content: center;
  }
`;

const AppHeaderText = styled.span`
  font-family: 'Acme', sans-serif;
  color: ${({ theme }) => theme.orange};
  font-size: 24px;
  @media (${deviceMax.desktop}) and (${deviceMin.tablet}) {
    display: none;
  }
`;

const AvatarView = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const HeaderWrapper = styled(Header)`
  background-color: ${({ theme }) => theme.white};
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  border-bottom: 0.0625rem solid ${({ theme }) => theme.grey10};
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  display: flex;
  padding: 0 16px;
  gap: 16px;
`;

const StyledText = styled(Text)`
  color: ${({ theme }) => theme.grey80};
  @media (${deviceMax.desktop}) and (${deviceMin.tablet}) {
    display: none;
  }
`;

const StyledTextEmail = styled(Text)`
  font-size: 10px;
  color: ${({ theme }) => theme.grey60};
  @media (${deviceMax.desktop}) and (${deviceMin.tablet}) {
    display: none;
  }
` as typeof Text;

export default withData(PermanentDrawer);
