import { EventProviderType } from '@aclito/enums';

const providerImage: Record<EventProviderType, string> = {
  [EventProviderType.CHYTRA_REZERVACE]: new URL(
    '../images/logos/chytra-rezervace-logo.png',
    import.meta.url,
  ).href,
  [EventProviderType.RESERVANTO]: new URL(
    '../images/logos/reservanto.png',
    import.meta.url,
  ).href,
  [EventProviderType.ACLITO]: new URL('', import.meta.url).href,
};

const providerStyle: Record<EventProviderType, React.CSSProperties> = {
  [EventProviderType.CHYTRA_REZERVACE]: {
    width: 60,
    height: 30,
  },
  [EventProviderType.RESERVANTO]: {
    width: 90,
    height: 30,
  },
  [EventProviderType.ACLITO]: {},
};

export { providerImage, providerStyle };
