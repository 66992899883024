import { createAvatar } from '@dicebear/avatars';
import * as styles from '@dicebear/pixel-art';

const SVG = (seed?: string) =>
  createAvatar(styles, {
    seed: seed ?? '',
    radius: 50,
    // ... and other options
  });

export default SVG;
