import styled from 'styled-components';

export const CalendarStyleWrapper = styled.div`
  height: 800px;
  & .rbc-header,
  & .rbc-label,
  & .rbc-date-cell {
    color: ${({ theme }) => theme.black};
  }

  & .rbc-header.rbc-today {
    color: white;
    background: ${({ theme }) => theme.primary};
  }

  & .rbc-today.rbc-day-bg {
    background: ${({ theme }) => theme.primary10};
  }

  & .rbc-day-bg,
  & .rbc-time-slot {
    background: ${({ theme }) => theme.white};
  }

  & .rbc-time-content > * + * > *,
  & .rbc-day-bg + .rbc-day-bg {
    border-left: 1px solid ${({ theme }) => theme.grey20};
  }

  & .rbc-timeslot-group {
    border-bottom: 1px solid ${({ theme }) => theme.grey20};
  }

  & .rbc-time-content {
    border-top: 2px solid ${({ theme }) => theme.grey20};
  }
  & .rbc-day-slot .rbc-time-slot {
    border-top: 1px solid ${({ theme }) => theme.grey10};
  }
  & .rbc-day-slot .rbc-time-slot:first-child {
    border-top: unset;
  }
  & .rbc-event {
    background: unset;
    padding: unset;
    border-radius: unset;
    min-height: unset !important;
    border: unset;
  }
  & .rbc-event:focus {
    outline: 1px solid ${({ theme }) => theme.primary};
  }
`;
