export const debounce = <K>(
  callback: (...args: any[]) => void,
  wait: number,
) => {
  let timeoutId: any;
  return (...args: Array<K>) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      // eslint-disable-next-line prefer-spread
      callback.apply(null, args);
    }, wait);
  };
};
