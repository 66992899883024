import { config } from '@aclito/shared/config';
import { useAppSelector } from '@aclito/shared/hooks';
import { profileSelectors } from '@aclito/shared/redux/slices/profileSlices';
import { RESERVED_GENDER } from '@aclito/shared/util/constants';
import { useEffect } from 'react';

import { ROUTE_PROFILE_EDIT } from '@/util/constants';
import { useNavigate } from '@/hooks/useNavigate';
import { useShowTranslatedNotification } from '@/hooks';

export const useAvailabilities = () => {
  const userInfo = useAppSelector(profileSelectors.userInfo);
  const nav = useNavigate();
  const showMessage = useShowTranslatedNotification();
  const onProfileClick = () => {
    nav(ROUTE_PROFILE_EDIT);
  };
  useEffect(() => {
    if (config.gender.signup[userInfo.gender].id === RESERVED_GENDER) {
      showMessage('availabilityGender', onProfileClick);
    }
  }, [userInfo.gender]);
};
