import { LocaleType } from '@aclito/shared/types';
import { makeMessage } from '@aclito/shared/util/makeTranslatedMessage';
import {
  flashMessages,
  FlashMessagesKeys,
} from '@aclito/shared/util/flashMessage';

import { showNotification } from './showNotification';

export const showTranslatedNotification = (
  locale: LocaleType,
  type: FlashMessagesKeys,
  onClick?: () => void,
) => {
  const options = flashMessages[type as FlashMessagesKeys] ?? {
    type: 'danger',
    translation: 'error.undefined',
  };

  showNotification(
    makeMessage(options?.translation, locale as LocaleType, options?.values),
    options.type,
    onClick,
    options,
  );
};
