import { Center, Divider, Loader } from '@mantine/core';
import { useListPastEventsQuery } from '@aclito/shared/redux/api/feedbackApi';
import { dateSort } from '@aclito/shared/util/sorter';
import { formatEvents } from '@aclito/shared/features/event/utils/formatEvents';
import { useNavigate } from 'react-router-dom';

import {
  BackButton,
  HStack,
  SectionGrid,
  Title,
  TitleBar,
  Text,
  VStack,
} from '@/components';
import EventsCards from '@/components/Cards/EventsCards';
import { ROUTE_FEEDBACK } from '@/util/constants';

function Feedback() {
  const { data, isLoading } = useListPastEventsQuery(undefined);

  const events = dateSort(formatEvents(data ?? []), 'date', 'asc');
  const nav = useNavigate();

  return (
    <SectionGrid $isOpen={false}>
      <TitleBar>
        <HStack>
          <BackButton />
          <Title tx={'nav.feedback'} />
        </HStack>
      </TitleBar>

      {
        <VStack spacing={16}>
          <Text tx={'feedback.info'} />
          <Divider />
          {isLoading ? (
            <Center>
              <Loader />
            </Center>
          ) : (
            <EventsCards
              {...{
                onClick: (event) => nav(ROUTE_FEEDBACK + `/${event.id}`),
                data: events,
                showDateType: 'day',
              }}
            />
          )}
        </VStack>
      }
    </SectionGrid>
  );
}

export default Feedback;
