import { FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { Event } from '@aclito/entities';
import { eventClient } from '@aclito/client';
import publicClient from '@aclito/client/src/public-client';

import { aclitoApi } from '../store';

export const SEARCH_CALENDAR_EVENTS = 'searchCalendarEvents';
export const SEARCH_EMBEDDED_CALENDAR_EVENTS = 'searchEmbeddedCalendarEvents';

export const calendarEventsApi = aclitoApi.injectEndpoints({
  endpoints: (builder) => ({
    [SEARCH_CALENDAR_EVENTS]: builder.query<
      Event[],
      { viewStartDate?: string; viewEndDate?: string; orgId: string }
    >({
      queryFn: async ({ viewStartDate, viewEndDate, orgId }) => {
        try {
          const data = await eventClient.events.getEventsByOrg(orgId, {
            startDate: viewStartDate,
            endDate: viewEndDate,
          });

          return { data: data.data };
        } catch (error) {
          return { error: { error: 'fail' } as FetchBaseQueryError };
        }
      },
    }),
    [SEARCH_EMBEDDED_CALENDAR_EVENTS]: builder.query<
      Event[],
      { viewStartDate?: string; viewEndDate?: string; orgId: string }
    >({
      queryFn: async ({ viewStartDate, viewEndDate, orgId }) => {
        try {
          const data = await publicClient.public.calendar(orgId, {
            startDate: viewStartDate,
            endDate: viewEndDate,
          });

          return { data: data.data };
        } catch (error) {
          return { error: { error: 'fail' } as FetchBaseQueryError };
        }
      },
    }),
  }),
});

export const {
  useSearchCalendarEventsQuery,
  useSearchEmbeddedCalendarEventsQuery,
} = calendarEventsApi;
