import { ContentType, HttpClient } from '../httpClient';

export class ReservantoClient extends HttpClient {
  reservanto = {
    createReservantoToken: (token: string) =>
      this.request({
        path: `/reservanto/${token}`,
        method: 'POST',
        type: ContentType.Json,
      }),
  };
}
