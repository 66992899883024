import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NoteGrant } from '@aclito/entities';

import { REDUX_RESET } from '../../util/constants';
import { MyNoteGrantsInitialState, NoteInitialState } from '../initialStates';
import {
  clearNoteAsync,
  getMyNoteGrants,
  getNoteAsync,
  getNoteForUserAsync,
  saveNoteAsync,
} from '../thunks/noteThunks';
import { AppState } from '../store';

export const REDUX_NOTE = 'note';
export const REDUX_MY_GRANTS = 'myGrants';
const SliceNote = createSlice({
  name: REDUX_NOTE,
  initialState: NoteInitialState,
  reducers: {
    [REDUX_RESET + REDUX_NOTE]: () => NoteInitialState,

    addTel: (state, payload: PayloadAction<string>) => {
      if (state.current) {
        state.current.tel = payload.payload;
      }
    },
    addEmail: (state, payload: PayloadAction<string>) => {
      if (state.current) {
        state.current.email = payload.payload;
      }
    },
  },

  extraReducers: (builder) => {
    builder.addCase(clearNoteAsync.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(clearNoteAsync.fulfilled, (state) => {
      state.isLoading = false;
      if (state.current) {
        state.current.tel = '';
        state.current.email = '';
      }
    });
    builder.addCase(clearNoteAsync.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(getNoteAsync.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getNoteAsync.fulfilled, (state, action) => {
      state.isLoading = false;
      state.current = action.payload;
    });
    builder.addCase(getNoteAsync.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getNoteForUserAsync.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getNoteForUserAsync.fulfilled, (state, action) => {
      state.isLoading = false;
      state.current = action.payload;
    });
    builder.addCase(getNoteForUserAsync.rejected, (state) => {
      state.isLoading = false;
      state.current = undefined;
    });
  },
});

const SliceMyNoteGrants = createSlice({
  name: REDUX_MY_GRANTS,
  initialState: MyNoteGrantsInitialState,
  reducers: {
    [REDUX_RESET + REDUX_MY_GRANTS]: () => MyNoteGrantsInitialState,
    addGrant: (state, payload: PayloadAction<NoteGrant>) => {
      state.current.push(payload.payload);
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getMyNoteGrants.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getMyNoteGrants.fulfilled, (state, payload) => {
      state.current = payload.payload;
      state.isLoading = false;
    });
    builder.addCase(getMyNoteGrants.rejected, (state) => {
      state.isLoading = false;
      state.current = [];
    });
  },
});

export const noteActions = {
  ...SliceNote.actions,
  ...SliceMyNoteGrants.actions,
  saveNoteAsync,
  clearNoteAsync,
  getMyNoteGrants,
  getNoteAsync,
  getNoteForUserAsync,
};
export const noteReducers = {
  [REDUX_NOTE]: SliceNote.reducer,
  [REDUX_MY_GRANTS]: SliceMyNoteGrants.reducer,
};

export const noteSelectors = {
  isLoading: (state: AppState) => state.note.isLoading,
  notes: (state: AppState) => state.note.current,
  myGrants: (state: AppState) => state.myGrants.current,
};
