import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth/lib/types';
import styled, { css } from 'styled-components';
import { useTranslate } from '@aclito/shared/hooks/useTranslate';
import { ThemeColorsType } from '@aclito/shared/theme/colors';
import { ValueOf } from '@aclito/shared/types';

import { Icon } from '../components/Icons/Icon';
import { VStack } from '../components/Layout';
import { SmallButton } from '../components/Button';
import { Text } from '../components/Text';

const Social = ({
  onSocialClick,
}: {
  onSocialClick: (type: CognitoHostedUIIdentityProvider) => void;
}) => {
  const t = useTranslate();

  const socialLogin = (type: CognitoHostedUIIdentityProvider) => {
    onSocialClick(type);
  };
  return (
    <VStack spacing={12}>
      <LinkButton
        bg={'facebookBlue'}
        textcolor={'white'}
        onClick={() => socialLogin(CognitoHostedUIIdentityProvider.Facebook)}
      >
        <LinkButtonContent>
          <StyledIcon icon={'facebook'} color="whiteText" size={24} />
          {t('auth.signin.facebook')}
        </LinkButtonContent>
      </LinkButton>
      <LinkButton
        bg={'black'}
        textcolor={'white'}
        onClick={() => socialLogin(CognitoHostedUIIdentityProvider.Apple)}
      >
        <LinkButtonContent>
          <StyledIcon icon={'apple'} color="whiteText" size={24} />
          {t('auth.signin.apple')}
        </LinkButtonContent>
      </LinkButton>
      <LinkButton
        bg="white"
        textcolor={'black'}
        onClick={() => socialLogin(CognitoHostedUIIdentityProvider.Google)}
      >
        <LinkButtonContent>
          <StyledIcon icon={'google'} size={24} />
          <Text color="googleText">{t('auth.signin.google')}</Text>
        </LinkButtonContent>
      </LinkButton>
    </VStack>
  );
};

const StyledIcon = styled(Icon)`
  margin-right: 8px;
  display: flex;
`;

const LinkButton = styled(SmallButton)<{
  textcolor: ValueOf<ThemeColorsType>;
}>`
  width: 100%;
  background-color: ${(props) => props.bg};
  color: ${(props) => props.textcolor};
  border-radius: 10px;
  height: 100%;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
  cursor: pointer;
  &:hover {
    background-color: ${(props) => props.bg};
    filter: brightness(95%);
  }
  ${(p) =>
    p.bg === p.theme.black &&
    css`
      &:hover {
        opacity: 0.9;
      }
    `};
`;

const LinkButtonContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  font-size: 16px;
  font-weight: 400;
`;

export default Social;
