import { Divider, Space } from '@mantine/core';
import { useAppSelector } from '@aclito/shared/hooks';
import { profileSelectors } from '@aclito/shared/redux/slices/profileSlices';
import { useParams } from 'react-router-dom';
import { selectMyOrganizations } from '@aclito/shared/redux/api/organizationApi';
import { SelectObject } from '@aclito/shared/types';
import { useState } from 'react';

import Achievements from './Achievements';

import {
  TitleBar,
  SectionGrid,
  Title,
  BackButton,
  HStack,
  Tabs,
  TagButton,
  VStack,
  Select,
} from '@/components';

const Goals = () => {
  const { userId } = useParams();

  const userInfo = useAppSelector(profileSelectors.userInfo);
  const id = userId || userInfo.id;

  const myOrgs = useAppSelector(selectMyOrganizations);
  const orgOptions: SelectObject[] = myOrgs.map((org) => ({
    id: org.id,
    item: org.name,
  }));

  const [orgId, setOrgId] = useState<string | undefined>();
  const [orgVisible, setOrgVisible] = useState(false);
  const [tabId, setTabId] = useState('global');

  const isTabLayout = userInfo.id === id;

  return (
    <SectionGrid $isOpen={false}>
      <TitleBar>
        <HStack>
          <BackButton />
          <Title tx={'goals.title'} />
        </HStack>
      </TitleBar>
      <Space h={16} />

      {isTabLayout && (
        <>
          <VStack spacing={8}>
            <Tabs
              unstyled
              maw={'max-content'}
              style={{
                flexDirection: 'row',
                flex: 1,
                rowGap: 20,
              }}
              selectedIndex={tabId}
              setSelectedIndex={(newTabId) => {
                setTabId(newTabId);
                newTabId === 'org' ? setOrgVisible(true) : setOrgVisible(false);
              }}
            >
              <TagButton value={'global'} tx="tabs.all" />
              <TagButton value={'org'} tx="org.new.tab.organization" />
            </Tabs>
            {orgVisible && (
              <Select
                options={orgOptions}
                txLabel="org.new.tab.organization"
                txPlaceholder="org.new.tab.organization"
                onChange={(value) => setOrgId(value ?? undefined)}
              />
            )}
          </VStack>
          <Divider style={{ marginTop: 16, marginBottom: 16 }} />
        </>
      )}
      {orgVisible ? (
        orgId ? (
          <Achievements orgId={orgId} id={id} />
        ) : null
      ) : (
        <Achievements orgId={undefined} id={id} />
      )}
    </SectionGrid>
  );
};

export default Goals;
