import { MessagePayload, onMessage } from 'firebase/messaging';
import { useEffect } from 'react';
import { showNotification as showMantineNotification } from '@mantine/notifications';
import { useAppSelector } from '@aclito/shared/hooks';
import { profileSelectors } from '@aclito/shared/redux/slices/profileSlices';
import { userClient } from '@aclito/client';
import { NotificationDataType } from '@aclito/enums';

import { getFirebaseToken, messaging } from '@/util/firebase';
import { Icon } from '@/components';

const FirebaseProvider = ({ children }: { children: React.ReactNode }) => {
  const userInfo = useAppSelector(profileSelectors.userInfo);
  useEffect(() => {
    if (userInfo) {
      getToken();
    }
    const sub = onMessage(messaging, showNotification);

    return () => sub();
  }, [userInfo]);

  const getToken = async () => {
    const token = await getFirebaseToken();
    if (token && token !== userInfo.webToken) {
      await userClient.user.updateUser({
        webToken: token,
      });
    }
  };

  const showNotification = (payload: MessagePayload) => {
    if (payload.notification && payload.data) {
      showMantineNotification({
        title: payload.notification.title,
        message: payload.notification.body,
        color: 'blue',
        icon: getIcon(payload.data.type as NotificationDataType),
      });
    }
  };

  const getIcon = (type: NotificationDataType) => {
    const icons: Record<NotificationDataType, React.ReactNode> = {
      GroupMessage: <Icon icon="message" />,
      Event: <Icon icon="timer" />,
      Org: <Icon icon="org" />,
    };

    return icons[type] ?? null;
  };

  return <>{children}</>;
};

export default FirebaseProvider;
