import React from 'react';
import styled from 'styled-components';
import { Review } from '@aclito/entities';
import moment from 'moment';

import { Avatar, HStack, Text, VStack, PlainCard } from '@/components';

interface CommentCardsProps {
  reviews: Review[];
}

const ReviewCards: React.FC<CommentCardsProps> = ({ reviews }) => (
  <>
    {(reviews ?? []).map((review) => (
      <PlainCard key={review.id}>
        <VStack>
          <HStack>
            <Avatar size={50} image={review.user.image} />
            <VStack spacing={4}>
              <Title>
                {review.user.name} {review.user.surname}
              </Title>
              <TimeText>{moment(review.updatedAt).fromNow()}</TimeText>
            </VStack>
          </HStack>
          <SecondaryTitle>{review.title}</SecondaryTitle>
          <Body>{review.text}</Body>
        </VStack>
      </PlainCard>
    ))}
  </>
);

const SecondaryTitle = styled(Text)`
  font-size: 24px;
  color: ${({ theme }) => theme.grey50};
  font-weight: 400;
`;

const Title = styled(Text)`
  font-size: 24px;
  color: ${({ theme }) => theme.grey80};
  font-weight: 500;
  margin-right: 16px;
`;

const TimeText = styled(Text)`
  font-size: 10px;
  color: ${({ theme }) => theme.grey30};
`;
const Body = styled(Text)`
  font-size: 12px;
`;

export default ReviewCards;
