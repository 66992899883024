import { EventProviderType } from '@aclito/enums';

import { ThemeColorsRecord } from '@aclito/shared/theme/colors';

export const externalColorPicker = (
  eventProvider: EventProviderType,
  theme: ThemeColorsRecord,
) => {
  let externalEventColor: string;
  switch (eventProvider) {
    case EventProviderType.CHYTRA_REZERVACE:
      externalEventColor = theme.chytrarezervace;
      break;
    case EventProviderType.RESERVANTO:
      externalEventColor = theme.reservanto;
      break;
    default:
      externalEventColor = theme.primary;
  }

  return externalEventColor;
};
