import { ThemeColorsKeys } from '@aclito/shared/theme/colors';
import { TranslateKey } from '@aclito/shared/types';
import React, { ReactNode } from 'react';
import styled, { useTheme } from 'styled-components';

import { HStack, Text } from '@/components';

interface JoinLeaveButtonProps {
  tx?: TranslateKey;
  bg?: ThemeColorsKeys;
  children?: ReactNode;
  onClick?: () => void;
}

const JoinLeaveButton: React.FC<JoinLeaveButtonProps> = ({
  tx,
  bg,
  onClick,
  children,
}) => {
  const theme = useTheme();

  return (
    <StyledButton
      style={{ backgroundColor: bg ? theme[bg] : theme.primary }}
      onClick={onClick}
      data-testid={`JoinAndLeaveButton-${tx}`}
    >
      <HStack spacing={8}>
        {children}
        {tx && <ButtonText tx={tx} />}
      </HStack>
    </StyledButton>
  );
};

const StyledButton = styled.button`
  display: flex;
  height: 2.5rem;
  padding: 0.75rem 1rem;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: unset;
  color: ${({ theme }) => theme.whiteText};
  &:hover {
    filter: brightness(95%);
  }
  cursor: pointer;
`;

const ButtonText = styled(Text)`
  font-size: 1rem;
  font-weight: 500;
  color: ${({ theme }) => theme.whiteText};
`;

export default JoinLeaveButton;
