import React, { useState } from 'react';
import { Space } from '@mantine/core';
import { FormattedEvent } from '@aclito/shared/types';
import { formatEvents } from '@aclito/shared/features/event/utils/formatEvents';

import { EventsMapProps } from '../interface';

import SimpleMap from '@/common/Maps/SimpleMap';
import EventsCards from '@/components/Cards/EventsCards';

const EventsMap: React.FC<EventsMapProps> = ({ data }) => {
  const [selectedEvents, setSelectedEvents] = useState<FormattedEvent[]>([]);

  return (
    <>
      <Space h={16} />
      <SimpleMap
        data={data}
        size={{ width: '100%', height: '75vh' }}
        onMapClusterClick={(e) => {
          setSelectedEvents(formatEvents(e));
        }}
      />
      <Space h={24} />
      <EventsCards data={selectedEvents} />
    </>
  );
};

export default EventsMap;
