import {
  FieldValues,
  SubmitErrorHandler,
  SubmitHandler,
  useFormContext,
} from 'react-hook-form';

import { SmallButton, SmallButtonProps } from './SmallButton';

// @ts-ignore
type SubmitProps<T extends FieldValues> = {
  onSubmit: SubmitHandler<any>;
  onError?: SubmitErrorHandler<any>;
};

const SubmitButton = <T extends FieldValues>({
  children,
  onSubmit,
  onError,
  bg,
  ...props
}: Omit<SmallButtonProps, 'onSubmit'> & SubmitProps<T>) => {
  const { handleSubmit } = useFormContext();

  const handleSubmitButton = () => {
    handleSubmit(onSubmit, onError)();
  };

  return (
    <SmallButton
      data-testid="testID"
      onClick={handleSubmitButton}
      bg={bg}
      {...props}
    >
      {children}
    </SmallButton>
  );
};

export default SubmitButton;
