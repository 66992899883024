import { createAsyncThunk } from '@reduxjs/toolkit';
import { userClient } from '@aclito/client';
import {
  UserInfo,
  UserLocation,
  UserSettings,
  UserAlias,
  UpdateUserInfoInput,
  EventRaw,
  Stats,
  CreateAliasInput,
  UpdateAliasInput,
} from '@aclito/entities';

import { FIRST_TIME, NOT_FIRST_TIME, REDUX_RESET } from '../../util/constants';
import api from '../../api/api';
import { currentUser, updateAttributes } from '../../util/helpersApi';
import {
  authActions,
  commonActions,
  profileActions,
  locationActions,
} from '../slices';
import { ThunkAuthType, ThunkType, WithMessageProp } from '../types';
import { flushState } from '../utils/flushState';
import { Hub } from '../../classes/Hub';
import { REDUX_HAS_SEARCHED } from '../slices/commonSlices';
import { AppState } from '../store';
import { errorObject } from '../../api/utils/errorObject';

export const REDUX_DELETE_ACC = 'deleteAccount';
export const REDUX_POPULATE_FIRST_TIME = 'populateFirstTime';
export const REDUX_UPDATE_MY_PROFILE = 'updateMyProfile';
export const REDUX_LOAD_USER = 'loadUser';
export const REDUX_ADD_LOCATION = 'addLocation';
export const REDUX_UPDATE_SETTINGS = 'settingsUpdate';
export const REDUX_CREATE_USER_ALIAS = 'createUserAlias';
export const REDUX_UPDATE_USER_ALIAS = 'updateUserAlias';
export const REDUX_REMOVE_USER_ALIAS = 'removeUserAlias';

export const addLocationAsync = createAsyncThunk<
  UserInfo,
  WithMessageProp<{ location: UserLocation }>,
  ThunkType
>(REDUX_ADD_LOCATION, async (data, { dispatch, ...thunkAPI }) => {
  try {
    const { data: user } = await userClient.user.updateUser({
      location: data.location,
    });

    dispatch(locationActions.reset(['searchMapLocation', 'placesMapLocation']));
    dispatch(commonActions.updateHasSearch(['search', false]));
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    dispatch(commonActions[REDUX_RESET + REDUX_HAS_SEARCHED]());
    return user;
  } catch (error) {
    return thunkAPI.rejectWithValue({ type: 'fail', payload: error });
  }
});

export const loadUserInfoAsync = createAsyncThunk<
  { user: UserInfo; stats: Stats },
  void,
  ThunkType
>(REDUX_LOAD_USER, async (_, thunkAPI) => {
  try {
    const { data } = await userClient.user.getUser();
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue({ type: 'fail', payload: error });
  }
});

export const updateUserSettingsAsync = createAsyncThunk<
  UserInfo,
  UserSettings,
  ThunkType
>(REDUX_UPDATE_SETTINGS, async (data, thunkAPI) => {
  try {
    const { data: user } = await userClient.user.updateUser({
      settings: data,
    });
    return user;
  } catch (error) {
    return thunkAPI.rejectWithValue({ type: 'fail', payload: error });
  }
});

export const deleteAccountAsync = createAsyncThunk<void, void, ThunkType>(
  REDUX_DELETE_ACC,
  async (_, { dispatch, ...thunkAPI }) => {
    try {
      const user = await currentUser();
      const data = await userClient.user.deleteAccount();

      if (data.data) {
        user.deleteUser(async (error, d) => {
          if (error) {
            return thunkAPI.rejectWithValue({ type: 'fail', payload: error });
          }
          if (d) {
            await api.authentication.signOut();
            Hub.cognitoUser = undefined;
            Hub.user = undefined;
            flushState(dispatch);
          }
          return;
        });
      }
      return;
    } catch (error) {
      return thunkAPI.rejectWithValue({ type: 'fail', payload: error });
    }
  },
);

export const populateIfFirstTimeAsync = createAsyncThunk<
  void,
  void,
  ThunkAuthType
>(REDUX_POPULATE_FIRST_TIME, async (_, { dispatch, ...thunkAPI }) => {
  try {
    const user = await currentUser();
    user.getUserAttributes(async (__, res) => {
      if (res?.length) {
        const attr = res.find((attribute) => attribute.Name === 'custom:Login');
        if (attr?.Value === FIRST_TIME) {
          const { data: userInfo } = await userClient.user.initial();
          const status = await api.custom.status.getStatus();
          await api.authentication.updateAttributes({
            'custom:userInfo': userInfo.id,
            'custom:termsAndConditions':
              status.termsAndConditionsVersion.toString(),
          });
          dispatch(profileActions.updateUserInfo(userInfo));
          return;
        }
      }
    });
    return;
  } catch (error) {
    await api.authentication.signOut();
    return thunkAPI.rejectWithValue({
      type: errorObject.UserNotFoundException,
    });
  }
});

export const updateMyProfileAsync = createAsyncThunk<
  UserInfo,
  UpdateUserInfoInput,
  ThunkAuthType | ThunkType
>(REDUX_UPDATE_MY_PROFILE, async (data, thunkAPI) => {
  const { locale, pushToken } = thunkAPI.getState() as AppState & {
    pushToken: string;
  };
  try {
    const { data: updated } = await userClient.user.updateUser({
      ...data,
      preferredLanguage: locale,
      pushToken,
    });
    try {
      await updateAttributes({ 'custom:Login': NOT_FIRST_TIME });
      thunkAPI.dispatch(authActions.updateIsFirstTime(false));
    } catch (error) {
      return thunkAPI.rejectWithValue({ type: 'fail', payload: error });
    }
    return updated;
  } catch (error) {
    return thunkAPI.rejectWithValue({ type: 'fail', payload: error });
  }
});

export const createUserAliasAsync = createAsyncThunk<
  UserInfo,
  { aliasData: CreateAliasInput },
  ThunkType
>(REDUX_CREATE_USER_ALIAS, async ({ aliasData }, thunkAPI) => {
  try {
    const { data: updated } = await userClient.alias.createAlias(aliasData);

    return updated;
  } catch (error) {
    return thunkAPI.rejectWithValue({ type: 'fail', payload: error });
  }
});

export const updateUserAliasAsync = createAsyncThunk<
  UserInfo,
  { aliasData: UpdateAliasInput },
  ThunkType
>(REDUX_UPDATE_USER_ALIAS, async ({ aliasData }, thunkAPI) => {
  try {
    const { data: updated } = await userClient.alias.updateAlias(aliasData);

    return updated;
  } catch (error) {
    return thunkAPI.rejectWithValue({ type: 'fail', payload: error });
  }
});

export const removeUserAliasAsync = createAsyncThunk<
  UserInfo | EventRaw[],
  { aliasData: UserAlias },
  ThunkType
>(REDUX_REMOVE_USER_ALIAS, async ({ aliasData }, thunkAPI) => {
  try {
    const data = await userClient.alias.deleteAlias(aliasData.id);
    return data.data;
  } catch (error) {
    return thunkAPI.rejectWithValue({ type: 'fail', payload: error });
  }
});
