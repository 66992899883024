import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { NotificationBody } from '@aclito/shared/types';
import { Notification } from '@aclito/entities';
import { NotificationType } from '@aclito/enums';

import {
  HStack,
  VStack,
  LocalNotificationIcons,
  Text,
  Icon,
  PlainCard,
} from '@/components';

interface NotificationCardProps {
  getOnClick: (type: NotificationType | 'DELETE', item: Notification) => void;
  notification: Notification;
  notificationBody: NotificationBody;
}

const NotificationCard: React.FC<NotificationCardProps> = ({
  notification,
  notificationBody,
  getOnClick,
}) => {
  const icon = LocalNotificationIcons(notification.type);
  const {
    secondaryTitle,
    titleTranslations,
    bodyTranslations,
    action,
    message,
  } = notificationBody;

  const handleAction = () => getOnClick(notification.type, notification);

  return (
    <PlainCard onClick={handleAction}>
      <Content spacing={16}>
        <TopContent>
          {icon}
          <VStack spacing={8}>
            {!!titleTranslations && <Title>{titleTranslations}</Title>}
            {!!secondaryTitle && (
              <SecondaryTitle>{secondaryTitle}</SecondaryTitle>
            )}
            {!!bodyTranslations && <Body>{bodyTranslations}</Body>}
            {!!message && <Message lineClamp={2}>{message}</Message>}
          </VStack>
        </TopContent>
        <div
          onClick={(e) => {
            e.stopPropagation();
            getOnClick('DELETE', notification);
          }}
        >
          <Icon
            icon="close"
            size={24}
            color="red"
            data-testid="closeNotification"
          />
        </div>
      </Content>

      <HStack style={{ justifyContent: 'space-between', marginTop: 30 }}>
        <div>{!!action && <BottomText>{action}</BottomText>}</div>
        <div>
          <BottomText>{moment(notification.createdAt).fromNow()}</BottomText>
        </div>
      </HStack>
    </PlainCard>
  );
};

export default NotificationCard;

const TopContent = styled(HStack)`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: nowrap;
`;

const Content = styled(HStack)`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: nowrap;
`;
const Title = styled(Text)`
  font-size: 16px;
  color: ${({ theme }) => theme.grey80};
  font-weight: bold;
  margin-right: 24px;
`;

const BottomText = styled(Text)`
  font-size: 14px;
  color: ${({ theme }) => theme.grey30};
`;
const Body = styled(Text)`
  font-size: 14px;
  color: ${({ theme }) => theme.grey50};
`;

const SecondaryTitle = styled(Text)`
  font-size: 14px;
  color: ${({ theme }) => theme.primary};
`;

const Message = styled(Text)`
  font-size: 14px;
  color: ${({ theme }) => theme.grey50};
` as typeof Text;
