import { useState } from 'react';

import { filterJoinedAliases } from '../utils/filterJoinedAliases';
import { getPlayersByFlag } from '../utils/getPlayers';
import { ALIAS_PREFIX } from '../../../util/constants';

import { EventModel } from '@aclito/shared/classes/EventModel';

export const useEventInfoAliasesConfirmation = (model: EventModel) => {
  const { event, userInfo } = model;

  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const isAliasConfirmed = (aliasId: string) => {
    if (aliasId.startsWith(ALIAS_PREFIX)) {
      return event.players.some(
        (player) => player?.aliasId === aliasId && player.isConfirmed,
      );
    } else {
      return event.players.some(
        (player) =>
          player.id === aliasId && !player.aliasId && player.isConfirmed,
      );
    }
  };

  const handleOnAliasPress = (id: string) => {
    const toggleSelected = (selected: string[]) =>
      selected.includes(id)
        ? selected.filter((val) => val !== id)
        : [...selected, id];

    if (!isAliasConfirmed(id)) {
      setSelectedIds(toggleSelected);
    }
  };

  const isSelected = (id: string) => selectedIds.includes(id);

  return {
    handleOnAliasPress,
    selectedIds,
    isSelected,
    joinedAliases: filterJoinedAliases(
      getPlayersByFlag(event),
      event.leader,
      userInfo,
    ),
    isAliasConfirmed,
  };
};
