import React from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';
import styled from 'styled-components';

import { Text, TextProps } from '@/components';

interface LinkProps extends NavLinkProps {
  textProps?: TextProps;
}

const Link: React.FC<
  Omit<LinkProps, 'children'> & { children?: React.ReactNode }
> = ({ children, textProps, to, ...rest }) => (
  <StyledLink to={to} {...rest}>
    {children}
    <Text {...textProps} />
  </StyledLink>
);
const StyledLink = styled(NavLink)`
  z-index: 2;
  padding: 16px;
`;
export default Link;
