import { BannersKey, TranslateKey } from '@aclito/shared/types';
import { ThemeColorsKeys } from '@aclito/shared/theme/colors';

import { Icon } from '@/components';

export interface BannersProps {
  icon?: JSX.Element;
  color: ThemeColorsKeys;
  text: TranslateKey;
}

export const banners: Record<BannersKey, BannersProps> = {
  eventRunning: {
    icon: <Icon icon="bannerPlay" size={14} color={'whiteText'} />,
    color: 'bannerRunning',
    text: 'card.banner.running',
  },
  orgAdmin: {
    icon: <Icon icon="person" size={14} color={'whiteText'} />,
    color: 'bannerAdmin',
    text: 'card.banner.admin',
  },
  orgPowerUser: {
    icon: <Icon icon="person" size={14} color={'whiteText'} />,
    color: 'bannerPowerUser',
    text: 'card.banner.powerUser',
  },
  orgMember: {
    icon: <Icon icon="person" size={14} color="whiteText" />,
    color: 'primary',
    text: 'member',
  },
  alias: {
    icon: <Icon icon="person" size={14} color="whiteText" />,
    color: 'primary',
    text: 'alias',
  },
  joinedAlias: {
    color: 'bannerJoinedAlias',
    text: 'card.banner.joined',
  },
  queuedAlias: {
    color: 'bannerQueued',
    text: 'card.banner.queued',
  },
  confirmedAlias: {
    color: 'bannerConfirmed',
    text: 'card.banner.confirmed',
  },
  eventOrganizer: {
    icon: <Icon icon="person" size={14} color={'whiteText'} />,
    color: 'bannerEventOrganizer',
    text: 'card.banner.eventOrganizer',
  },
  eventTrainer: {
    icon: <Icon icon="person" size={14} color={'whiteText'} />,
    color: 'primary',
    text: 'card.banner.eventTrainer',
  },
  eventForAdults: {
    color: 'yellow',
    text: 'events.new.tab.adults',
  },
  eventForKids: {
    color: 'yellow',
    text: 'events.new.tab.kids',
  },
  orgTypeClub: {
    color: 'orange',
    text: 'org.new.tab.club',
  },
  orgTypeOrganization: {
    color: 'orange',
    text: 'org.new.tab.organization',
  },
  orgTypeTeam: {
    color: 'orange',
    text: 'org.new.tab.team',
  },
  eventRepeating: {
    color: 'blue',
    text: 'card.banner.eventRepeating',
  },
  CHYTRA_REZERVACE: {
    color: 'chytrarezervace',
    text: 'card.banner.external',
  },
  RESERVANTO: {
    color: 'reservanto',
    text: 'card.banner.external',
  },
};
