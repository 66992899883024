import { useTheme } from 'styled-components';

import { Avatar } from '../Avatar';
import Image from '../Image';

import { CurrentPictureProps } from './types';

const CurrentPicture = ({ currentImage, circular }: CurrentPictureProps) => {
  const theme = useTheme();
  return (
    <div
      style={{
        alignItems: 'center',
        borderRadius: 10,
        height: 200,
        width: 'auto',
        display: 'flex',
        position: 'relative',
        justifyContent: 'center',
        backgroundColor: theme.white,
        padding: 24,
      }}
    >
      <div>
        {currentImage &&
          (circular ? (
            <Avatar size={180} image={currentImage} />
          ) : (
            <Image width={200} height={200} image={currentImage} />
          ))}
      </div>
    </div>
  );
};

export default CurrentPicture;
