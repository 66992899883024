import React, { useState } from 'react';
import styled from 'styled-components';
import { Center, Loader } from '@mantine/core';
import { useTranslate, useTranslateDate } from '@aclito/shared/hooks';
import { EventModel } from '@aclito/shared/classes/EventModel';
import { deviceMax, deviceMin } from '@aclito/ui-web/utils/mediaQueries';
import {
  useFindAndDisplayChatQuery,
  useSendMessageMutation,
} from '@aclito/shared/redux/api/messageApi';
import { Message } from '@aclito/entities';
import moment from 'moment';
import { txDateFn, txFn } from '@aclito/shared/types';
import { updateLastSeen } from '@aclito/shared/util/updateLastSeen';
import { ChatMessageMetaData } from '@aclito/enums';

import { useChatWeb } from '../../hooks/useChatWeb';

import MessagesList from './MessagesList';
import ChatPlaceholder from './ChatPlaceholder';

import { Storage } from '@/util/storage';
import { SmallButton, Textarea, Icon } from '@/components';

interface ChatProps {
  model: EventModel;
}

const getMessages = (m: Message[], tDate: txDateFn, t: txFn) => {
  const result: Message[] = [];
  let prevDay: moment.Moment | undefined = undefined;
  structuredClone(m).forEach((message) => {
    const currentDay = moment(message.createdAt).startOf('day');
    const dateString = tDate(message.createdAt.toString(), 'date.chat');
    const item: Message & { dateOnly: boolean } = {
      ...message,
      message: dateString,
      id: dateString,
      dateOnly: true,
    };

    if (message.system) {
      switch (message.meta) {
        case ChatMessageMetaData.Joined: {
          message.message = t('chat.join', {
            name: `${message.user.name} ${message.user.surname}`,
          });
          break;
        }
        case ChatMessageMetaData.Left: {
          message.message = t('chat.left', {
            name: `${message.user.name} ${message.user.surname}`,
          });
          break;
        }
      }
    }

    if (!prevDay) {
      result.push(item);
    }

    if (prevDay && !currentDay.isSame(prevDay)) {
      result.push(item);
    }

    result.push(message);

    prevDay = currentDay;
  });
  return result;
};

const Chat: React.FC<ChatProps> = ({ model }) => {
  const chatId = model.event.eventChatId ?? '';

  const { data, isLoading } = useFindAndDisplayChatQuery(
    { id: chatId, nextToken: null },
    { skip: !model.event.eventChatId },
  );

  const [onSend] = useSendMessageMutation();

  const chat = model.event.chat;
  const messages = data?.messages ?? [];

  const [text, setText] = useState<string>('');

  const handleSendMessage = async () => {
    setText('');
    const message = await onSend({ chatId, message: text }).unwrap();
    await updateLastSeen(Storage, {
      chatId,
      messages: [message],
      userId: model.userInfo.id,
    });
  };

  const handleOnMessageChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setText(e.target.value);
  };
  const tDate = useTranslateDate();

  const hasMore = !!data?.nextToken;

  const handleKeyPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (!e.shiftKey && e.key === 'Enter') {
      e.preventDefault();
      handleSendMessage();
    }
  };

  const { sortedMessages, isMyMessage } = useChatWeb(messages, chat);
  const t = useTranslate();
  return (
    <Wrapper>
      {isLoading ? (
        <Center style={{ height: '100%' }}>
          <Loader />
        </Center>
      ) : (
        <>
          {chat && model.isJoinedInChat ? (
            <>
              <MessagesList
                items={getMessages(sortedMessages, tDate, t)}
                isLoading={isLoading}
                hasMore={hasMore}
                isMyMessage={isMyMessage}
                chat={chat}
              />
              <>
                {chat && (
                  <WritingBox>
                    <Textarea
                      txPlaceholder="chat.writingPlaceholder"
                      autosize
                      minRows={1}
                      maxRows={3}
                      onChange={handleOnMessageChange}
                      value={text}
                      onKeyDown={handleKeyPress}
                      rightSection={
                        <SmallButton
                          shape="round"
                          bg={'transparent'}
                          onClick={handleSendMessage}
                        >
                          <Icon icon="send" size={18} color="primary" />
                        </SmallButton>
                      }
                    />
                  </WritingBox>
                )}
              </>
            </>
          ) : (
            <ChatPlaceholder model={model} />
          )}
        </>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height: 70vh;
  @media ${deviceMax.laptop} {
    width: 100%;
  }
  @media ${deviceMin.desktopL} {
    width: 75%;
  }

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const WritingBox = styled.div`
  border-top: 2px solid ${({ theme }) => theme.grey30};
  display: flex;
  & > * {
    width: 100%;
  }

  textarea {
    border-radius: unset;
    border: unset;
  }

  button {
    align-self: flex-start;
    margin-right: 20px;
  }
`;

export default Chat;
