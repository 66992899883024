import React from 'react';
import { useController } from 'react-hook-form';

import Checkbox from './Checkbox';
import { CheckboxProps } from './types';

const ValidatedCheckbox: React.FC<CheckboxProps & { name: string }> = ({
  name,
  ...rest
}) => {
  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController({ name });
  return (
    <Checkbox
      checked={value}
      value={value}
      error={error?.message}
      onChange={(val) => onChange(val.target.checked)}
      data-testid={name}
      {...rest}
    />
  );
};

export default ValidatedCheckbox;
