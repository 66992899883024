import React from 'react';
import {
  Slider as MantineSlider,
  RangeSlider as MantineRangeSlider,
  Input,
} from '@mantine/core';
import { useTranslate } from '@aclito/shared/hooks';
import { useTheme } from 'styled-components';
import { colors } from '@aclito/shared/theme/colors';

import { getCommonInputLabelStyle } from '../../utils/styles';

import { SliderProps, RangeSliderProps } from './types';

export const Slider: React.FC<SliderProps> = ({ label, txLabel, ...rest }) => {
  const t = useTranslate();
  const theme = useTheme();
  const i18nLabel = txLabel && t(txLabel);
  const labelContent = i18nLabel || label;

  return (
    <Input.Wrapper
      styles={{
        label: { ...getCommonInputLabelStyle(theme) },
      }}
      size="md"
      label={labelContent}
    >
      <MantineSlider
        styles={{ track: { backgroundColor: 'white' } }}
        {...rest}
      />
    </Input.Wrapper>
  );
};

export const RangeSlider: React.FC<RangeSliderProps> = ({
  label,
  txLabel,
  ...rest
}) => {
  const theme = useTheme();
  const t = useTranslate();
  const i18nLabel = txLabel && t(txLabel);
  const labelContent = i18nLabel || label;
  return (
    <Input.Wrapper
      size="md"
      label={labelContent}
      styles={{
        label: { ...getCommonInputLabelStyle(theme), marginBottom: 16 },
      }}
    >
      <MantineRangeSlider
        thumbSize={20}
        styles={{
          mark: {
            display: 'none',
          },
          markLabel: {
            paddingRight: 11,
            marginTop: 20,
            color: colors.grey80,
          },
          track: { backgroundColor: 'white', height: 4 },
          thumb: {
            backgroundColor: colors.primary,
          },
        }}
        {...rest}
      />
    </Input.Wrapper>
  );
};
