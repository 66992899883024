import { formatEvent } from '@aclito/shared/features/event/utils/formatEvents';
import { EventWithDateObject, txFn } from '@aclito/shared/types';
import moment from 'moment';

export const formatEventWithDate = (event: EventWithDateObject, t: txFn) => ({
  ...formatEvent(
    {
      ...event,
      date: moment(event.date).toISOString(),
      endDate: moment(event.endDate).toISOString(),
    },

    t,
  ),
});
