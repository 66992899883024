import { useEffect } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import type { UpdateUserInfoInput } from '@aclito/entities';

import {
  ActivitiesKey,
  AgeKey,
  GenderUserKey,
  LanguagesKey,
  ProfileInfoForm,
} from '../../../types';

import { useProfilePrefill } from './useProfilePrefill';

import { useAppDispatch } from '@aclito/shared/hooks/useDispatch';
import { getUserData } from '@aclito/shared/util/cognitoUser';
import { profileActions } from '@aclito/shared/redux/slices';
import { RESERVED_AGE, RESERVED_GENDER } from '@aclito/shared/util/constants';

export const useAboutUser = () => {
  const dispatch = useAppDispatch();

  const { placeholderName, placeholderSurname, prefill, setPrefill } =
    useProfilePrefill();

  useEffect(() => {
    populateFirstTime();
  }, []);

  const populateFirstTime = async () => {
    const { name, surname } = await getUserData();
    await dispatch(profileActions.populateIfFirstTimeAsync());
    if (name && surname) {
      setPrefill({ ...prefill, name, surname });
    }
  };

  const updateUser = async (attributes: ProfileInfoForm) => {
    attributes.image = attributes.image?.trim() ? attributes.image : 'BASIC';

    if (!attributes.file) {
      delete attributes.file;
    }

    const user: UpdateUserInfoInput = {
      ...attributes,
      name: attributes.name.trim() ? attributes.name : placeholderName,
      surname: attributes.surname.trim()
        ? attributes.surname
        : placeholderSurname,
      age: (attributes.age ? attributes.age.id : RESERVED_AGE) as AgeKey,
      description: attributes.description ?? '',
      languages: (attributes.languages?.length
        ? attributes.languages.map((l) => l.id)
        : []) as LanguagesKey[],
      activities: (attributes.activities?.length
        ? attributes.activities.map((s) => s.id)
        : []) as ActivitiesKey[],
      gender: (attributes.gender
        ? attributes.gender.id
        : RESERVED_GENDER) as GenderUserKey,
      image: attributes.image,
    };
    return unwrapResult(
      await dispatch(profileActions.updateMyProfileAsync(user)),
    );
  };

  return { placeholderName, placeholderSurname, prefill, updateUser };
};
