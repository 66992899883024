import React from 'react';

import JoinLeaveButton from './JoinLeaveButton';

import { Icon } from '@/components';

interface JoinButtonProps {
  onPress: () => void;
}

const JoinButton: React.FC<JoinButtonProps> = ({ onPress }) => {
  return (
    <JoinLeaveButton onClick={onPress} tx={'events.info.join'} bg={'primary'}>
      <Icon icon="forward" color="whiteText" />
    </JoinLeaveButton>
  );
};

export default JoinButton;
