import { Auth } from 'aws-amplify';

import { errorObject } from '../../utils/errorObject';
/**
 *
 * @returns Returns a message about sign out
 */
export const makeSignOut = async (): Promise<boolean> => {
  try {
    await Auth.signOut();
    return true;
  } catch (error) {
    //@ts-ignore
    return Promise.reject(errorObject[error.code]);
  }
};
