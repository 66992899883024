import type { DeleteNotificationInput, Notification } from '@aclito/entities';

import { ContentType, HttpClient } from '../httpClient';

export class NotificationClient extends HttpClient {
  notifications = {
    deleteOne: (data: DeleteNotificationInput) =>
      this.request<void>({
        path: `/notifications/single/${data.id}`,
        method: 'DELETE',
        type: ContentType.Json,
      }),

    listNotifications: () =>
      this.request<Notification[]>({
        path: `/notifications/list`,
        method: 'GET',
        type: ContentType.Json,
      }),

    deleteAll: () =>
      this.request<void>({
        path: `/notifications`,
        method: 'DELETE',
        type: ContentType.Json,
      }),
  };
}
