import React from 'react';
import { getOrgUserRole } from '@aclito/shared/util/getOrgUserRole';
import {
  isClub,
  isOrganization,
  isTeam,
} from '@aclito/shared/features/organization/utils/getOrgTag';
import { Organization, UserInfo } from '@aclito/entities';

import PlayersMembersNumber from '../PlayersMembersNumber';

import { PlainCard, HStack, Text, VStack, OrgAvatar } from '@/components';
import {
  CardStatusBannerIcon,
  CardStatusBannerText,
} from '@/common/CardStatusBanner';

type OrganizationCardProps = {
  organization: Organization;
  userInfo: UserInfo;
  selected: string | undefined;
  setSelected: (val: Organization) => void;
};

const OrganizationCard: React.FC<OrganizationCardProps> = ({
  organization,
  userInfo,
  setSelected,
  selected,
}) => {
  return (
    <PlainCard
      className={selected === organization.id ? 'selected' : ''}
      onClick={() => setSelected(organization)}
    >
      <HStack style={{ flexWrap: 'nowrap' }}>
        <OrgAvatar org={organization} style={{ width: 48 }} />

        <VStack spacing={8} style={{ width: '100%' }}>
          <HStack
            style={{ justifyContent: 'space-between', flexWrap: 'nowrap' }}
          >
            <Text size={16} lineClamp={1} weight={700}>
              {organization.name}
            </Text>
            {organization.maxMembers === -1 && (
              <PlayersMembersNumber
                textSize={14}
                withIcon
                iconSize={16}
                organization={organization}
              />
            )}
          </HStack>

          <HStack spacing={4}>
            {getOrgUserRole(organization, userInfo.id) === 'admin' && (
              <CardStatusBannerIcon type="orgAdmin" />
            )}
            {getOrgUserRole(organization, userInfo.id) === 'powerUser' && (
              <CardStatusBannerIcon type="orgPowerUser" />
            )}
            {isClub(organization) && (
              <CardStatusBannerText type="orgTypeClub" />
            )}
            {isTeam(organization) && (
              <CardStatusBannerText type="orgTypeTeam" />
            )}
            {isOrganization(organization) && (
              <CardStatusBannerText type="orgTypeOrganization" />
            )}
          </HStack>
        </VStack>
      </HStack>
    </PlainCard>
  );
};

export default OrganizationCard;
