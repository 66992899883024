import React from 'react';
import styled from 'styled-components';

import { Button } from '../Button';
import { ButtonProps } from '../Button/Button';

import { ITab } from './Tab';

interface TabButtonProps extends ButtonProps, ITab {
  value: string;
}

const TabButton: React.FC<TabButtonProps> = ({
  value,
  setSelectedIndex,
  selectedIndex,
  ...rest
}) => {
  const handleTabIndexChange = () => setSelectedIndex?.(value);

  return (
    <StyledButton
      variant={selectedIndex === value ? 'filled' : 'subtle'}
      {...rest}
      onClick={handleTabIndexChange}
    />
  );
};

const StyledButton = styled(Button)`
  &:hover {
    color: ${({ theme }) => theme.white};
  }
  width: 120px;
  height: 40px;
  border-radius: 10px;
` as typeof Button;

export default TabButton;
