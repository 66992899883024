import { useSchema, useTranslate } from '@aclito/shared/hooks';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { AvailabilityForm } from '@aclito/shared/types';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAvailabilityCompleteEdit } from '@aclito/shared/features/availability/hooks/useAvailaiblityCompleteEdit';
import { StyledTitle } from '@aclito/ui-web/utils/styles';
import { sizeMax } from '@aclito/ui-web/utils/mediaQueries';

import { useAvailabilityCreateEdit } from '../../hooks/useAvailabilityCreateEdit';

import { useAvailabilityEdit } from './hooks/useAvailabilityEdit';
import { AvailabilityEditProps } from './interface';

import {
  SectionGrid,
  ActionButton,
  Stepper,
  TitleBar,
  useStepper,
  VStack,
  StepperButtons,
} from '@/components';
import { Activities, Place, Date } from '@/common/AvailabilityForm';
import withRouter from '@/navigation/withRouter';

const AvailabilityEdit: React.FC<AvailabilityEditProps> = ({ state }) => {
  const availability = state?.availability;

  const { initialValues, handleSubmit } = useAvailabilityEdit(availability);
  const t = useTranslate();
  const { newAvailabilityValidationSchema } = useSchema();
  const { active, setActive, nextStep, prevStep } = useStepper();

  const steps = [
    {
      component: <Date showRepeating={false} />,
      label: t('date'),
    },
    {
      component: (
        <Activities initialActivities={initialValues?.activities ?? []} />
      ),
      label: t('availability.new.activities'),
    },
    {
      component: <Place />,
      label: t('events.new.place'),
    },
  ];

  const methods = useForm<AvailabilityForm>({
    //@ts-ignore
    resolver: yupResolver(newAvailabilityValidationSchema),
    defaultValues: initialValues,
  });

  const { alreadyHasAvailability, alreadyHasEvent } =
    useAvailabilityCompleteEdit(methods.getValues, availability?.id);

  const { onSubmit, onError, navBack } = useAvailabilityCreateEdit(
    handleSubmit,
    alreadyHasEvent(),
    alreadyHasAvailability(),
  );

  return (
    <>
      {initialValues && availability && (
        <SectionGrid
          style={{ alignSelf: 'center', maxWidth: sizeMax.tablet }}
          $isOpen={false}
        >
          <TitleBar>
            <StyledTitle tx="nav.availability.edit" />
            <ActionButton
              onClick={navBack}
              bg={'red10'}
              textcolor={'red'}
              iconType={'close'}
            />
          </TitleBar>

          <FormProvider {...methods}>
            <VStack>
              <Stepper
                {...{
                  active,
                  steps,
                  setActive,
                  nextStep,
                  prevStep,
                }}
              />
              <StepperButtons
                {...{
                  active,
                  steps: steps.length,
                  nextStep,
                  prevStep,
                  onError,
                  onSubmit,
                  create: false,
                }}
              />
            </VStack>
          </FormProvider>
        </SectionGrid>
      )}
    </>
  );
};

export default withRouter(AvailabilityEdit);
