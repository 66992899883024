import { isMobile } from 'react-device-detect';
export const useRedirect = (webURL: string | undefined, deepLink: string) => {
  const redirect = () => {
    if (isMobile && webURL) {
      document.location = deepLink;
      setTimeout(() => (document.location = webURL), 2000);
    } else {
      if (webURL) {
        document.location = webURL;
      }
    }
  };

  return redirect;
};
