import { useFeedFilters } from '@aclito/shared/features/organization/hooks/useFeedFilters';
import { FormProvider } from 'react-hook-form';
import styled from 'styled-components';

import { ValidatedSelect, ActionButton, HStack, VStack } from '@/components';

const FeedFilter = () => {
  const { orgOptions, clearFilter, methods, submitFilters } = useFeedFilters();

  return (
    <FormProvider {...methods}>
      <VStack spacing={16}>
        <ValidatedSelect
          options={orgOptions}
          name="feedOrg"
          txLabel="org.feed.org"
          txPlaceholder="org.feed.org"
        />
        <ButtonSection spacing={16}>
          <ActionButton fullWidth tx="clear" onClick={clearFilter} />
          <ActionButton
            fullWidth
            primary
            tx="filter"
            onClick={methods.handleSubmit(submitFilters)}
          />
        </ButtonSection>
      </VStack>
    </FormProvider>
  );
};

const ButtonSection = styled(HStack)`
  flex-wrap: nowrap;
`;

export default FeedFilter;
