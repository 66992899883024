import React from 'react';
import { Organization } from '@aclito/entities';
import { useTheme } from 'styled-components';
import moment from 'moment';

import { Button } from '@/components';
import { useOrganizationInfoHandlers } from '@/features/organization/screens/OrganizationInfo/hooks/useOrganizationInfoHandlers';
import { MobileCalendar } from '@/components/MobileCalendar';
import { useCalendar } from '@/components/Calendar/hooks/useCalendar';

import 'aclito-calendar/dist/styles.css';

interface TabContentProps {
  displayedOrg: Organization;
}

const OrgEvents: React.FC<TabContentProps> = ({ displayedOrg }) => {
  const theme = useTheme();

  const { mappedEvents } = useCalendar(displayedOrg.id);

  const { handleCalendar } = useOrganizationInfoHandlers(displayedOrg);

  return (
    <MobileCalendar
      maxDate={moment().endOf('month').toDate()}
      minDate={moment().startOf('month').toDate()}
      hideOutsideDates
      nextIcon={<></>}
      previousIcon={<></>}
      maxLevel="month"
      topBarElement={
        <Button
          style={{
            backgroundColor: 'transparent',
            color: theme.primary,
          }}
          onClick={handleCalendar}
          tx="nav.calendar"
        />
      }
      events={mappedEvents}
    />
  );
};

export default OrgEvents;
