import { useFormContext } from 'react-hook-form';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { EventForm } from '../../../types';

import { generateDates } from '@aclito/shared/util/generateDates';
import { useSelectObject } from '@aclito/shared/hooks';
import { eventActions } from '@aclito/shared/redux/slices';

export const useRepeatingForm = () => {
  const dispatch = useDispatch();

  const { getValues, watch } = useFormContext<EventForm>();

  const { translatedRepeatChoices } = useSelectObject();

  const watchDate = watch('date');
  const watchSwitch = watch('switchRepeating');
  const watchNumberOfEvents = watch('numberOfEvents');
  const watchRepeatAfter = watch('repeatAfter');
  const watchDuration = watch('duration');

  useEffect(() => {
    if (watchSwitch) {
      if (
        watchDate &&
        watchRepeatAfter?.item &&
        watchRepeatAfter.id !== 'none'
      ) {
        if (watchRepeatAfter && watchNumberOfEvents) {
          dispatch(
            eventActions.updateEventDates({
              date: generateDates({
                repeatAfter: watchRepeatAfter,
                date: watchDate,
                numberToGenerate: watchNumberOfEvents,
              }),
            }),
          );
        } else {
          dispatch(
            eventActions.updateEventDates({
              date: watchDate ? [watchDate] : [],
            }),
          );
        }
      }
    } else {
      dispatch(
        eventActions.updateEventDates({
          date: watchDate ? [watchDate] : [],
        }),
      );
    }
  }, [watchSwitch, watchNumberOfEvents, watchDate, watchRepeatAfter]);
  return {
    ...getValues(),
    watchDuration,
    generateDates,
    translatedRepeatChoices,
  };
};
