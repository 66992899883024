import styled, { useTheme } from 'styled-components';
import { useTranslate } from '@aclito/shared/hooks';
import { TranslateKey } from '@aclito/shared/types';

import { HStack } from '../Layout';
import { Text } from '../Text';
import { getCommonInputLabelStyle } from '../../utils/styles';

interface LabeledButonProps {
  onClick: any;
  labelTx: TranslateKey;
  buttonTx: TranslateKey;
}

export const LabeledButton = ({
  onClick,
  labelTx,
  buttonTx,
}: LabeledButonProps) => {
  const t = useTranslate();
  const theme = useTheme();
  const buttonText = t(buttonTx) || buttonTx;
  return (
    <HStack spacing={4} style={{ width: '100%' }}>
      <Text style={{ ...getCommonInputLabelStyle(theme) }} tx={labelTx} />
      <StyledButton onClick={onClick}>{buttonText}</StyledButton>
    </HStack>
  );
};

const StyledButton = styled.button`
  display: flex;
  width: 100%;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.primary10};
  padding: 10px 0;
  border: 0;
  color: ${({ theme }) => theme.primary};
  &:hover {
    filter: brightness(95%);
    cursor: pointer;
  }
`;

export default LabeledButton;
