import { Organization } from '@aclito/entities';
import { useOrganizationEdit as useOrgEdit } from '@aclito/shared/features/organization/hooks/useOrganizationEdit';
import { OrganizationForm } from '@aclito/shared/types';
import { composeInitialEditValues } from '@aclito/shared/features/organization/utils/composeInitialEditValues';

import { useNavigate } from '@/hooks/useNavigate';

export const useOrganizationEdit = (org: Organization | undefined) => {
  const nav = useNavigate();
  const { editOrg, isLoading } = useOrgEdit(org);
  const initialValues = org ? composeInitialEditValues(org) : undefined;

  const handleSubmit = async (data: OrganizationForm) => {
    nav(-1);
    await editOrg(data);
  };

  return { initialValues, handleSubmit, isLoading };
};
