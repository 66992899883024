import {
  CognitoAccessToken,
  CognitoIdToken,
  CognitoUserSession,
} from 'amazon-cognito-identity-js';
import { Auth } from 'aws-amplify';

const GROUPS = 'cognito:groups';
const TAC = 'custom:termsAndConditions';
const LOGIN = 'custom:Login';
const USER_INFO = 'custom:userInfo';
const NAME = 'name';
const EMAIL = 'email';

export interface CognitoData {
  groups: Array<string>;
  login: string;
  userInfo: string;
  email: string;
  name: string;
  surname: string;
  termsAndConditionsVersion: number;
  idToken: CognitoIdToken;
  accessToken: CognitoAccessToken;
}

const getPayload = (session: CognitoUserSession) => {
  const p = session.getIdToken().payload;

  let name: string | undefined;
  let surname: string | undefined;

  if (p[NAME]) {
    const [n, s] = p[NAME].split(' ');
    name = n;
    surname = s;
  }
  return {
    groups: p[GROUPS],
    login: p[LOGIN],
    userInfo: p[USER_INFO],
    email: p[EMAIL],
    termsAndConditionsVersion: p[TAC] ? Number(p[TAC]) : 0,
    name,
    surname,
    idToken: session.getIdToken(),
    accessToken: session.getAccessToken(),
  } as CognitoData;
};

const getUserDataBySession = (session: CognitoUserSession) =>
  getPayload(session);

const getUserData = () => Auth.currentSession().then((s) => getPayload(s));

export { getUserData, getUserDataBySession };
