import React, { useEffect } from 'react';

import { useVerify } from './hooks/useVerify';

import { TypedLocation } from '@/hooks/useLocation';
import { QueryVerification } from '@/util/types';
import { confirmSignUpHeaders } from '@/features/integration/utils/verifyHeaders';
import { useRedirect } from '@/features/integration/hooks/useRedirect';
import VerificationBody from '@/features/integration/screens/Verification/components/VerificationBody';
import withRouter from '@/navigation/withRouter';

type VerificationProps = TypedLocation<void, QueryVerification>;

const Verification: React.FC<VerificationProps> = ({ query }) => {
  const clientId = query?.clientId;
  const code = query?.code;
  const username = query?.username;
  const email = query?.email;

  const redirect = useRedirect(undefined, 'aclito://');
  const {
    hasError,
    sendRequest,
    setIsLoading,
    response,
    isLoading,
    resendRequest,
  } = useVerify();

  const handleVerification = async () => {
    if (clientId && code && username && !response) {
      const c = await sendRequest(
        confirmSignUpHeaders,
        JSON.stringify(confirmSignUpBody),
        true,
      );
      if (c === 'Welcome') {
        setTimeout(() => redirect(), 1000);
      }
    }
  };

  useEffect(() => {
    setIsLoading(true);
    handleVerification();
  }, [clientId, code, username]);

  const confirmSignUpBody = {
    ClientId: clientId,
    ConfirmationCode: code,
    Username: username,
  };

  const resendCodeBody = {
    ClientId: clientId,
    Username: email,
  };

  return (
    <div>
      <VerificationBody
        isLoading={isLoading}
        response={response}
        hasError={hasError}
        resendRequest={resendRequest}
        resendCodeBody={resendCodeBody}
      />
    </div>
  );
};

export default withRouter(Verification);
