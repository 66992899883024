import { Organization } from '@aclito/entities';

import { config } from '../../../config';
import { getByIdTranslate } from '../../../util/selectObject';
import { EventForm, txFn } from '../../../types';

export const composeInitialCreateValues = (
  t: txFn,
  org: Organization | undefined,
): EventForm => ({
  switchRepeating: false,
  repeatAfter: undefined,
  numberOfEvents: undefined,
  date: null,
  duration: null,
  gender: getByIdTranslate('any', config.gender.filter, t),
  info: '',
  level: getByIdTranslate('0', config.levels, t),
  place: undefined,
  players: undefined,
  price: undefined,
  activity: undefined,
  name: '',
  multisport: false,
  newPlace: '',
  externalLink: '',
  linkRequired: false,
  org: org ? { item: org.name, id: org.id } : undefined,
  forFree: true,
  unlimited: true,
  requireConfirm: false,
  hoursConfirm: undefined,
  dontParticipate: false,
  enableQueue: false,
  trainer: undefined,
  tags: {
    all: true,
    kids: false,
    adults: false,
  },
});
