import { useGetUserStatsQuery } from '@aclito/shared/redux/api/userApi';
import { createSearchParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { deviceMax } from '@aclito/ui-web/utils/mediaQueries';
import { Center } from '@mantine/core';

import {
  Avatar,
  BackButton,
  HStack,
  SectionGrid,
  SectionTitle,
  Text,
  Title,
  TitleBar,
  VStack,
} from '@/components';
import { TypedLocation } from '@/hooks/useLocation';
import { StateAliasInfo } from '@/util/types';
import withRouter from '@/navigation/withRouter';
import Karma from '@/common/Profile/Karma';
import Stats from '@/common/Profile/Stats';
import Reviews from '@/common/Profile/Reviews';
import Achievements from '@/features/goals/Achievements';
import { ROUTE_GOALS } from '@/util/constants';

type AliasInfoProps = TypedLocation<StateAliasInfo>;
const AliasInfo = (props: AliasInfoProps) => {
  const alias = props.state?.alias;

  const { data: stats } = useGetUserStatsQuery(
    { id: alias?.id ?? '' },
    { skip: !alias },
  );

  const nav = useNavigate();

  if (!alias) return null;

  const userId = alias.id.split('__')[1];

  const handleNavigationToGoals = (id: string) => {
    nav({
      pathname: ROUTE_GOALS + `/${id}`,
      search: createSearchParams({ aliasId: alias.id }).toString(),
    });
  };

  return (
    <SectionGrid $isOpen={false}>
      <TitleBar>
        <HStack>
          <BackButton />
          <Title tx="alias" />
        </HStack>
      </TitleBar>
      <VStack spacing={16}>
        <GroupedContent>
          <Avatar image={alias.image} size={64} />
          <VStack spacing={4}>
            <Text text={`${alias.name} ${alias.surname}`} />
            {stats && <Karma points={stats.karma.value} />}
          </VStack>
        </GroupedContent>

        {stats && (
          <Center style={{ paddingBottom: 16 }}>
            <Stats emojis={stats?.emoji ?? {}} />
          </Center>
        )}
      </VStack>

      <VStack>
        <SectionTitle
          onClick={() => handleNavigationToGoals(userId)}
          buttonLabelTx="tabs.achievements"
        />
        <Achievements preview aliasId={alias.id} id={userId} />
        <Reviews id={alias.id} />
      </VStack>
    </SectionGrid>
  );
};
const GroupedContent = styled(HStack)`
  width: 100%;
  @media ${deviceMax.mobileS} {
    flex-direction: column;
  }
`;

export default withRouter(AliasInfo);
