import { Organization } from '@aclito/entities';

import { OrganizationForm } from '../../../types';

export const composeInitialEditValues = (
  org: Organization,
): OrganizationForm => ({
  name: org.name,
  maxMembers: org.maxMembers === -1 ? undefined : org.maxMembers,
  description: org.description,
  private: org.private,
  address: org.address,
  unlimited: org.maxMembers === -1,
  image: org.image || 'BASIC',
  file: undefined,
  contact: org.contact,
  tags: org.tags,
  telephone: org.telephone || '',
  email: org.email || '',
});
