import { Auth } from 'aws-amplify';

import { ApiChangePassword } from '../../types/authentication';
import { errorObject } from '../../utils/errorObject';
/**
 *
 * @param body Cognito User and passwords
 * @returns A message or appropriate error
 */
export const makeChangePassword = async (
  body: ApiChangePassword,
): Promise<boolean> => {
  try {
    await Auth.changePassword(body.user, body.old, body.new);
    return true;
  } catch (error) {
    //@ts-ignore
    return Promise.reject(errorObject[error.code]);
  }
};
