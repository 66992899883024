import {
  DeleteAvailabilityInput,
  CreateAvailabilityInput,
  UpdateAvailabilityInput,
  GetAvailabilitiesByUserIdParams,
  Availability,
  Event,
} from '@aclito/entities';

import { ContentType, HttpClient } from '../httpClient';

export class AvailabilityClient extends HttpClient {
  availabilities = {
    deleteAvailability: (data: DeleteAvailabilityInput) =>
      this.request<void>({
        path: `/availabilities/single/${data.availabilityId}`,
        method: 'DELETE',
        type: ContentType.Json,
      }),
    createAvailability: (data: CreateAvailabilityInput) =>
      this.request<Availability[]>({
        path: '/availabilities',
        method: 'POST',
        body: data,
        type: ContentType.Json,
      }),
    updateAvailability: (data: UpdateAvailabilityInput) =>
      this.request<Availability>({
        path: '/availabilities',
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
      }),
    listAvailabilities: (query: GetAvailabilitiesByUserIdParams) =>
      this.request<{ data: Availability[]; nextToken: string | null }>({
        path: '/availabilities',
        method: 'GET',
        query,
        type: ContentType.Json,
      }),
    suggestEvents: (id: string) =>
      this.request<Event[]>({
        path: `/availabilities/suggest/${id}`,
        method: 'GET',
        type: ContentType.Json,
      }),
  };
}
