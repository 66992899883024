import { Loader, Center } from '@mantine/core';

import withRouter from '@/navigation/withRouter';
import { TypedLocation } from '@/hooks/useLocation';
import { QueryReservanto } from '@/util/types';
import { useReservanto } from '@/features/misc/screens/Reservanto/hooks/useReservanto';
import { Text, VStack } from '@/components';
import { Logo } from '@/common/Logo';
type ReservantoProps = TypedLocation<void, QueryReservanto>;

const Reservanto = ({ query }: ReservantoProps) => {
  const accepted = query?.accepted;
  const token = query?.token;

  const { isLoading, message } = useReservanto(token, accepted);

  if (!token || !accepted) {
    return <Text tx={'reservanto.badurl'} />;
  }

  return (
    <Center style={{ height: '100vh' }}>
      <VStack style={{ alignItems: 'center' }}>
        <Logo logoSize={150} />

        {!isLoading ? <Text tx={message} /> : <Loader />}
      </VStack>
    </Center>
  );
};

export default withRouter(Reservanto);
