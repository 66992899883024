import React, { cloneElement } from 'react';
import { BannersKey } from '@aclito/shared/types';
import styled, { useTheme } from 'styled-components';

import { banners } from './BannersConfig';

import { HStack } from '@/components';

interface CardStatusBannerIconProps {
  type: BannersKey;
}

const CardStatusBannerIcon: React.FC<CardStatusBannerIconProps> = ({
  type,
}) => {
  const { icon, color } = banners[type];
  const Icon = cloneElement(icon ?? <></>);
  const theme = useTheme();
  return <Container color={theme[color]}>{Icon}</Container>;
};

const Container = styled(HStack)<{ color: string }>`
  background: ${({ theme, color }) => color ?? theme.whiteText};
  border-radius: 25px;
  padding: 2px 2px;
`;

export default CardStatusBannerIcon;
