import styled from 'styled-components';

import { deviceMin } from '../../utils/mediaQueries';

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.65);
  border-radius: 0;
  z-index: 3;
  @media ${deviceMin.laptop} {
    display: none;
  }
`;

export default Overlay;
