import moment from 'moment';

export const compareByMonth = (fstDate: Date, sndDate: Date) =>
  moment(fstDate).month() === moment(sndDate).month();

export const compareByDay = (fstDate: Date, sndDate: Date) =>
  moment(fstDate).date() === moment(sndDate).date();

export const compareByYear = (fstDate: Date, sndDate: Date) =>
  moment(fstDate).year() === moment(sndDate).year();

export const compareByMonthAndYear = (fstDate: Date, sndDate: Date) =>
  compareByMonth(fstDate, sndDate) && compareByYear(fstDate, sndDate);

export const compareByMonthAndDayAndYear = (fstDate: Date, sndDate: Date) =>
  compareByMonthAndYear(fstDate, sndDate) && compareByDay(fstDate, sndDate);
