import { Grid } from '@mantine/core';
import {
  useGetAchievementsQuery,
  useGetUserAchievementsByUserIdQuery,
} from '@aclito/shared/redux/api/achievementApi';
import { createDataForAchievementsCards } from '@aclito/shared/features/goals/utils';
import AchievementIcon from '@aclito/ui-web/achievements/AchievementIcon';
import { useLocation } from 'react-router-dom';
import HStack from '@aclito/ui-web/components/Layout/HStack';
import { useTheme } from 'styled-components';
import { Text } from '@aclito/ui-web/components/Text';
import { Icon } from '@aclito/ui-web/components/Icons/Icon';
import AchievementCard from '@aclito/ui-web/achievements/AchievementCard';

import GridColumn from '@/components/Cards/GridColumn';

type AchievementProps = {
  id: string;
  aliasId?: string;
  orgId?: string;
  preview?: boolean;
};

const Achievements = ({ id, aliasId, preview, orgId }: AchievementProps) => {
  const { search } = useLocation();
  const query = new URLSearchParams(search);

  const myAliasId = query.get('aliasId') || aliasId;

  const {
    data: achievements,
    isFetching: isFetchingAchievements,
    isUninitialized,
    isSuccess,
  } = useGetAchievementsQuery(orgId ?? 'none');

  const { data: userAchievements, isFetching: isFetchingUserAchievements } =
    useGetUserAchievementsByUserIdQuery({ id, orgId });

  const theme = useTheme();

  return (
    <>
      {preview ? (
        <HStack spacing={8}>
          {!isFetchingAchievements &&
            !isFetchingUserAchievements &&
            createDataForAchievementsCards(
              achievements,
              userAchievements,
              myAliasId,
            ).map((achievement, index) => (
              <div key={index} style={{ width: 60, height: 60 }}>
                <AchievementIcon
                  preview
                  classic={achievement.classic}
                  unlocked={achievement.unlocked}
                  icons={achievement.config.icons}
                  backgroundColor={
                    achievement.classic
                      ? theme.primary
                      : theme[`achievement${achievement.level}`]
                  }
                  level={achievement.level}
                />
              </div>
            ))}
        </HStack>
      ) : (
        <>
          <Grid gutter="md">
            {!isFetchingAchievements &&
              !isFetchingUserAchievements &&
              createDataForAchievementsCards(
                achievements,
                userAchievements,
                myAliasId,
              ).map((achievement, index) => (
                <GridColumn key={index} fullWidth={false}>
                  <AchievementCard
                    {...achievement}
                    icons={achievement.config.icons}
                  />
                </GridColumn>
              ))}
          </Grid>
          {!achievements?.length && !isUninitialized && isSuccess && orgId && (
            <HStack>
              <Icon icon={'trophy'} /> <Text tx={'flatlist.nobadge'} />
            </HStack>
          )}
        </>
      )}
    </>
  );
};

export default Achievements;
