import { composeUser } from '../utils/composeUser';
import { profileSelectors } from '../../../redux/slices/profileSlices';
import { useGetUserQuery } from '../../../redux/api/userApi';

import { useAppSelector, useTranslate } from '@aclito/shared/hooks';

export const useUserProfile = (id: string) => {
  const userInfo = useAppSelector(profileSelectors.userInfo);

  const t = useTranslate();

  const { data, refetch } = useGetUserQuery({ id });

  const canBeReported = userInfo?.id !== id;

  return {
    displayedUser: data && composeUser(t, data.user),
    stats: data?.stats,
    canBeReported,
    refetch,
  };
};
