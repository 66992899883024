import { useAppSelector } from '@aclito/shared/hooks';
import { useRepeatingForm } from '@aclito/shared/features/availability/hooks/useRepeatingForm';
import React from 'react';
import { availabilitySelectors } from '@aclito/shared/redux/slices/availabilitySlices';
import moment from 'moment';
import styled from 'styled-components';
import { formatDate } from '@aclito/shared/util/formatRepeatingDate';
import { Space } from '@mantine/core';

import {
  HStack,
  VStack,
  Text,
  ValidatedSelect,
  ValidatedSwitch,
  ValidatedDateTimePicker,
  ValidatedTimeInput,
  ValidatedNumberInput,
} from '@/components';

interface DateFormProps {
  showRepeating: boolean;
}

const DateForm: React.FC<DateFormProps> = ({ showRepeating }) => {
  const availabilityDates = useAppSelector(
    availabilitySelectors.availabilityDates,
  );

  const { repeating, translatedRepeatChoices, watchDuration } =
    useRepeatingForm();

  return (
    <VStack spacing={20}>
      <VStack spacing={20}>
        <ValidatedDateTimePicker
          name="date"
          {...{
            required: true,
            txLabel: 'availability.new.datefrom',
            txPlaceholder: 'availability.new.datefrom',
            minDate: moment().toDate(),
            maxDate: moment().add(1, 'year').toDate(),
          }}
        />

        <ValidatedTimeInput
          required
          txLabel="events.new.duration"
          name="duration"
          txPlaceholder="time.display"
        />

        {showRepeating && (
          <ValidatedSwitch
            txLabel="availability.new.repeating"
            name="repeating"
          />
        )}
      </VStack>

      {repeating && (
        <VStack spacing={20}>
          <ValidatedSelect
            txLabel="availability.new.repetitionInterval"
            txPlaceholder="availability.new.repetitionInterval"
            name="repeatAfter"
            options={translatedRepeatChoices}
            required
          />
          <ValidatedNumberInput
            txLabel="availability.new.numberOfAvailabilities"
            txPlaceholder="availability.new.numberOfAvailabilities"
            name="numberOfAvailabilities"
            min={1}
            max={180}
          />
        </VStack>
      )}
      <Space h={20} />
      {watchDuration &&
        availabilityDates.date?.map((startDate: string, index: number) => {
          const [hour, minute] = watchDuration.split(':');
          return (
            <Items key={index}>
              <Text>
                {formatDate(
                  startDate,
                  moment(availabilityDates.date[index])
                    .add(hour, 'hour')
                    .add(minute, 'minute')
                    .toISOString(),
                )}
              </Text>
              {/*TODO: update BE payload #940*/}
              {/*{availabilityDates.date.length > 1 && (*/}
              {/*  <>*/}
              {/*    <StyledIcon*/}
              {/*      icon={'remove'}*/}
              {/*      size={20}*/}
              {/*      color="red"*/}
              {/*      onClick={() => {*/}
              {/*        dispatch(*/}
              {/*          availabilityActions.removeAvailabilityDate(index),*/}
              {/*        );*/}
              {/*      }}*/}
              {/*    />*/}
              {/*  </>*/}
              {/*)}*/}
            </Items>
          );
        })}
    </VStack>
  );
};

const Items = styled(HStack)`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid;
  border-bottom-color: ${({ theme }) => theme.grey50};
`;

// const StyledIcon = styled(Icon)`
//   transition: transform 200ms ease-in-out;
//   &:hover {
//     cursor: pointer;
//     transform: scale(1.3);
//   }
// `;

export default DateForm;
