import { MemberType } from '@aclito/shared/types';
import React from 'react';

import { CardStatusBannerText } from '@/common/CardStatusBanner';

interface RoleBannerProps {
  myRole: MemberType;
}

const RoleBanner: React.FC<RoleBannerProps> = ({ myRole }) => {
  const canDisplayRole = myRole && myRole !== 'member';
  return (
    <>
      {canDisplayRole && (
        <CardStatusBannerText
          type={myRole === 'admin' ? 'orgAdmin' : 'orgPowerUser'}
        />
      )}
    </>
  );
};

export default RoleBanner;
