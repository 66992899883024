import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Event } from '@aclito/entities';

import { applyFilters } from '../thunks/eventThunks';
import {
  EventDatesInitialState,
  FiltersInitialState,
  SearchEventsInitialState,
} from '../initialStates';
import { EventDates, EventFilters } from '../types';
import { REDUX_RESET } from '../../util/constants';
import { AppState } from '../store';

export const REDUX_FILTERED_EVENTS = 'filteredEvents';
export const REDUX_EVENT_DATES = 'eventDates';
export const REDUX_FILTERS = 'searchFilters';

const SliceFilters = createSlice({
  name: REDUX_FILTERS,
  initialState: FiltersInitialState,
  reducers: {
    updateFilter: (_, action: PayloadAction<EventFilters>) => {
      return action.payload;
    },
    clearFilter: () => FiltersInitialState,
    [REDUX_RESET + REDUX_FILTERS]: () => FiltersInitialState,
  },
});

const SliceSearchEvents = createSlice({
  name: REDUX_FILTERED_EVENTS,
  initialState: SearchEventsInitialState,
  reducers: {
    updateSearchEvents: (state, action: PayloadAction<Event[]>) => ({
      ...state,
      current: { ...state.current, items: action.payload },
    }),
    resetToken: (state) => {
      if (state.current) {
        state.current.nextToken = null;
      }
    },
  },
  [REDUX_RESET + REDUX_FILTERED_EVENTS]: () => SearchEventsInitialState,
});

const SliceEventDates = createSlice({
  name: REDUX_EVENT_DATES,
  initialState: EventDatesInitialState,
  reducers: {
    updateEventDates: (_, action: PayloadAction<EventDates>) => action.payload,
    removeEventDates: (state, action: PayloadAction<number>) => {
      state.date = [...state.date].filter((_, i) => action.payload !== i);
    },
    [REDUX_RESET + REDUX_EVENT_DATES]: () => EventDatesInitialState,
  },
});

export const eventActions = {
  ...SliceEventDates.actions,
  ...SliceFilters.actions,
  ...SliceSearchEvents.actions,
  applyFilters,
};

export const eventReducers = {
  [REDUX_FILTERED_EVENTS]: SliceSearchEvents.reducer,
  [REDUX_FILTERS]: SliceFilters.reducer,
  [REDUX_EVENT_DATES]: SliceEventDates.reducer,
};

export const eventSelectors = {
  searchFilters: (state: AppState) => state.searchFilters,
  eventDates: (state: AppState) => state.eventDates,
};
