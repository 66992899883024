export const getLevel = (current: number, levelSteps: number[]) => {
  if (!levelSteps) {
    return 0;
  }
  let cumulativeSum = 0;

  for (let i = 0; i < levelSteps.length; i++) {
    cumulativeSum += levelSteps[i];
    if (cumulativeSum > current) {
      return i + 1;
    }
  }

  return levelSteps.length;
};
