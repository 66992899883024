import React from 'react';
import styled from 'styled-components';
import { useGetUserReviewsQuery } from '@aclito/shared/redux/api/userApi';
import { dateSort } from '@aclito/shared/util/sorter';

import ReviewCards from './ReviewCards';

import { Collapsible } from '@/components';

interface AboutUserCommentsProps {
  id: string;
}

const Reviews: React.FC<AboutUserCommentsProps> = ({ id }) => {
  const { data } = useGetUserReviewsQuery({ id });

  const sortedData = dateSort(data ?? [], 'updatedAt', 'desc');

  return (
    <Collapsible buttonLabelTx="feedback.reviews" defaultOpen={false}>
      <Comments>
        <ReviewCards {...{ reviews: sortedData }} />
      </Comments>
    </Collapsible>
  );
};

const Comments = styled.div`
  & > div:not(:last-child) {
    margin-bottom: 10px;
  }
`;

export default Reviews;
