import { ApiSignIn } from '@aclito/shared/api/types/authentication';
import { useAppDispatch } from '@aclito/shared/hooks/useDispatch';
import { authActions } from '@aclito/shared/redux/slices';

export const useLogin = () => {
  const dispatch = useAppDispatch();

  const login = async (values: ApiSignIn) =>
    dispatch(authActions.signInAsync(values));

  return { login };
};
