import { useTranslate } from '@aclito/shared/hooks';
import { TranslateKey } from '@aclito/shared/types';
import React from 'react';
import styled from 'styled-components';
import { useDisclosure } from '@mantine/hooks';
import { Collapse } from '@mantine/core';
import { motion } from 'framer-motion';

import { Text } from '../Text';
import { Icon } from '../Icons/Icon';

import HStack from './HStack';

interface CollapsibleProps {
  children: React.ReactNode;
  defaultOpen?: boolean;
  buttonLabelTx?: TranslateKey;
  text?: string;
}

export function Collapsible({
  children,
  buttonLabelTx,
  defaultOpen,
  text,
}: CollapsibleProps) {
  const [opened, { toggle }] = useDisclosure(defaultOpen ?? false);
  const t = useTranslate();

  const textValue = buttonLabelTx ? t(buttonLabelTx) : text;

  return (
    <>
      <CollapseButton onClick={toggle}>
        <HStack spacing={0}>
          <Text>{textValue}</Text>
        </HStack>
        <motion.div
          transition={{ type: 'spring', stiffness: 90 }}
          animate={{
            rotate: !opened ? 180 : 0,
          }}
        >
          <Icon icon="chevronDown" />
        </motion.div>
      </CollapseButton>
      {children && (
        <Collapse
          transitionDuration={500}
          animateOpacity={false}
          transitionTimingFunction="ease"
          in={opened}
        >
          {children}
        </Collapse>
      )}
    </>
  );
}

const CollapseButton = styled.button`
  display: flex;
  width: 100%;
  padding: 8px 16px;
  align-items: center;
  justify-content: space-between;
  background: ${({ theme }) => theme.grey10};
  color: ${({ theme }) => theme.grey80};
  font-weight: bold;
  border: unset;
  cursor: pointer;
`;

export default Collapsible;
