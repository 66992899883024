import styled from 'styled-components';

import { HStack } from './Layout';
import { Text } from './Text';

const BasicCard = styled.div<{
  color?: string;
  $borderColor?: string;
}>`
  background-color: ${(props) =>
    props.color ? props.color : props.theme.white};
  border-radius: 10px;
  justify-content: center;
  padding: 12px;
  border-left: 2px solid;
  border-left-color: ${(props) =>
    props.$borderColor
      ? props.$borderColor
      : props.color
      ? props.color
      : 'red'};
  border-left-width: ${(props) => (props.$borderColor ? '8px' : '0px')};
`;
const PlainCard = styled(BasicCard)`
  cursor: pointer;
  transition: transform 250ms ease-in-out;

  &:hover {
    background: ${({ theme }) => `${theme.black}03`};
  }

  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12);

  &.selected {
    background: ${({ theme }) => theme.primary10};
  }
`;

const CardTitle = styled(Text)`
  font-weight: 700;
  color: ${({ theme }) => theme.grey80};
  font-size: 14px;
`;

const TimeText = styled(Text)`
  font-weight: 700;
  font-size: 14px;
  overflow: hidden;
  white-space: nowrap;
`;

const StyledText = styled(Text)`
  color: ${({ theme }) => theme.grey80};
  font-weight: normal;
  overflow: hidden;
  white-space: nowrap;
` as typeof Text;

const GroupedContent = styled(HStack)`
  align-items: center;
  overflow: hidden;
`;

const IconHolder = styled(HStack)`
  z-index: 1;
  margin-left: auto;
  margin-right: 24px;
  margin-top: 4px;
`;

export {
  PlainCard,
  BasicCard,
  CardTitle,
  StyledText,
  GroupedContent,
  TimeText,
  IconHolder,
};
