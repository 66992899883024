import { LeaveForm } from '@aclito/shared/types';
import { closeAllModals } from '@mantine/modals';
import { useForm } from 'react-hook-form';
import { EventModel } from '@aclito/shared/classes/EventModel';
import { useEventInfoLeave as useEventInfoLeaveShared } from '@aclito/shared/features/event/hooks/useEventInfoLeave';
import { useDisclosure } from '@mantine/hooks';

import { useNavigate } from '@/hooks/useNavigate';
import { useModal } from '@/hooks/useModal';

export const useEventInfoLeave = (model: EventModel) => {
  const nav = useNavigate();
  const { openConfirmModal } = useModal();

  const [
    isOpenedLeaveModal,
    { open: onOpenLeaveModal, close: onCloseLeaveModal },
  ] = useDisclosure(false);

  const { leaveRepeatingEvents, leaveSingleEvent } = useEventInfoLeaveShared();

  const handleAliasesLeave = (selectedIds: string[]) => {
    handleLeave(selectedIds);
  };

  const methods = useForm<LeaveForm>({
    defaultValues: { area: '' },
  });

  const handleLeave = async (selectedIds?: string[]) => {
    const { event } = model;

    const leaveSingle = async (value: LeaveForm) => {
      await leaveSingleEvent(value, model, selectedIds);
      closeAllModals();
      nav(-1);
    };

    const leaveRepeating = async (value: LeaveForm) => {
      await leaveRepeatingEvents(value, model, selectedIds);
      closeAllModals();
      nav(-1);
    };

    openConfirmModal({
      txTitle: 'events.info.leave.header',
      closeOnConfirm: false,
      txLabels: { cancel: 'close', confirm: 'leave' },
      type: 'leaveEvent',
      values: { methods },
      onConfirm: () => {
        methods.handleSubmit(async (value) => {
          const handleLeaveRepeating = async () => {
            await leaveRepeating(value);
          };

          if (event.repetitionId) {
            openConfirmModal({
              confirmProps: { color: 'red', w: 100 },
              txTitle: 'leave.repeating',
              type: 'leaveEventConfirm',
              values: { onConfirm: handleLeaveRepeating },
              closeOnConfirm: false,
              txLabels: { confirm: 'leave', cancel: 'close' },
              onConfirm: async () => await leaveSingle(value),
            });
          } else {
            leaveSingle(value);
          }
        })();
      },
    });
  };

  return {
    handleLeave,
    handleAliasesLeave,
    isOpenedLeaveModal,
    onOpenLeaveModal,
    onCloseLeaveModal,
  };
};
