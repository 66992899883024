const primary = '#4CAF50';
const primary10 = `${primary}1A`;
const red = '#FF5252';
const red10 = `${red}1A`;
export const internalPaletteLight: InternalPaletteRecord = {
  primary,
  primary10,
  background: '#F5F5F5',
  red,
  red10,
  yellow: '#FFD600',
  orange: '#FFC011',
  grey10: '#E6E6E6',
  grey20: '#CCCCCC',
  grey30: '#B3B3B3',
  grey40: '#999999',
  grey50: '#808080',
  grey60: '#666666',
  grey70: '#4D4D4D',
  grey80: '#333333',
  grey90: '#1A1A1A',
  blue: '#1877F2',
  black: '#000000',
  white: '#FFFFFF',
  whiteText: '#FFFFFF',
  reservanto: '#07B7B5',
  chytrarezervace: '#02B6E4',
  transparent: 'rgba(0, 0, 0, 0)',
};

export const internalPaletteDark: InternalPaletteRecord = {
  primary,
  primary10,
  background: '#1A1A1A',
  red,
  red10,
  yellow: '#FFD600',
  orange: '#FFC011',
  grey10: '#4D4D4D',
  grey20: '#666666',
  grey30: '#808080',
  grey40: '#999999',
  grey50: '#B3B3B3',
  grey60: '#CCCCCC',
  grey70: '#E6E6E6',
  grey80: '#FFFFFF',
  grey90: '#1A1A1A',
  blue: '#1877F2',
  black: '#FFFFFF',
  white: '#333333',
  whiteText: '#FFFFFF',
  reservanto: '#07B7B5',
  chytrarezervace: '#02B6E4',
  transparent: 'rgba(0, 0, 0, 0)',
};

export const themeColors = (internalPalette: InternalPaletteRecord) => ({
  ...internalPalette,
  success: internalPalette.primary,
  error: internalPalette.red,

  bannerRunning: internalPalette.primary,
  bannerAdmin: internalPalette.primary,
  bannerPowerUser: internalPalette.blue,
  bannerJoinedAlias: internalPalette.primary,
  bannerQueued: internalPalette.orange,
  bannerConfirmed: internalPalette.yellow,
  bannerEventOrganizer: internalPalette.primary,

  achievement0: internalPalette.primary,
  achievement1: '#CD7F32',
  achievement2: '#A0CAE9',
  achievement3: '#FFD300',
  achievement4: '#4BE74A',
  achievement5: '#12AC40',
  achievement6: '#43D9E7',
  achievement7: '#35ADF9',
  achievement8: '#E0ABFF',
  achievement9: '#B64EF4',
  achievement10: '#EC2D2D',

  text: internalPalette.grey80,
  placeholder: internalPalette.grey50,
  border: internalPalette.grey60,
  mapCircle: internalPalette.primary10,
  mapCircleStroke: internalPalette.primary,
  notificationInfo: '#3576FA',
  copy: 'blue',
  copied: 'teal',
  reservanto: '#01b7b6',
  facebookBlue: '#1877F2',
  googleText: '#777777',

  lowKarma: internalPalette.red,
  karma: internalPalette.primary,

  shadow: internalPalette.grey30,
  modalOverlay: internalPalette.grey80,
});
export const activityColors = {
  icehockey: '#0fb7ff',
  tennis: '#d2eb1a',
  running: '#a600bf',
  badminton: '#a35007',
  volleyball: '#ffbe19',
  football: '#28a100',
  golf: '#ffe8d6',
  tabletennis: '#a00744',
  handball: '#8e70ff',
  box: '#882166',
  swimming: '#bbad86',
  iceskating: '#dd8479',
  archery: '#204fcc',
  baseball: '#433DAF',
  bowling: '#9932CC',
  personaltraining: '#525722',
  cricket: '#997609',
  curling: '#f46488',
  cycling: '#cf792d',
  darts: '#719c72',
  billiard: '#1b8b52',
  wallclimbing: '#dfc646',
  rugby: '#684120',
  skateboarding: '#A3514F',
  skiing: '#685cf1',
  snowboarding: '#7ee8f6',
  squash: '#2372c8',
  fencing: '#2f5e5b',
  karate: '#bc0223',
  taekwondo: '#df869d',
  beachvolleyball: '#9B70C8',
  chess: '#BDE9A5',
  wrestling: '#D6E96A',
  horseriding: '#b2b6ca',
  fitness: '#eff147',
  pilates: '#3c7fa3',
  gym: '#8aa5e8',
  yoga: '#82a49a',
  hiking: '#3CE5B2',
  basketball: '#FF4500',
  circletraining: '#32CD32',
  aerobic: '#FFD700',
  spinning: '#1E90FF',
  tabata: '#FF6347',
  trx: '#8B008B',
  bosu: '#FFA500',
  heat: '#FF69B4',
  hiit: '#FF1493',
  zumba: '#FF0000',
  trampoline: '#00CED1',
  ballet: '#FF8C00',
  poledance: '#800080',
  hathayoga: '#2E8B57',
  poweryoga: '#228B22',
  dance: '#20B2AA',
  bachata: '#E57373',
  crossfit: '#FF5733',
  bodyform: '#81C784',
  any: '#4CAF50',
  default: '#FFFFFF',
} as const;

export type ActivitiesColorsType = typeof activityColors;
export type ThemeColorsType = ReturnType<typeof themeColors>;
export type ThemeColorsKeys = keyof ReturnType<typeof themeColors>;
export type ThemeColorsRecord = Record<ThemeColorsKeys, string>;
export const colors = {
  palette: { ...themeColors(internalPaletteLight), activityColors },
  activitiesPalette: activityColors,
  ...themeColors(internalPaletteLight),
};
type InternalPaletteRecord = Record<InternalPalette, string>;
type InternalPalette =
  | 'primary'
  | 'primary10'
  | 'background'
  | 'red'
  | 'red10'
  | 'yellow'
  | 'orange'
  | 'grey10'
  | 'grey20'
  | 'grey30'
  | 'grey40'
  | 'grey50'
  | 'grey60'
  | 'grey70'
  | 'grey80'
  | 'grey90'
  | 'blue'
  | 'black'
  | 'white'
  | 'whiteText'
  | 'transparent'
  | 'reservanto'
  | 'chytrarezervace';
