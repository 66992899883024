import { EventWithPlayers } from '@aclito/shared/types';
import {
  EmittedFeedbackEvent,
  EmojiByOrgMap,
  Event,
  FeedbackEvent,
} from '@aclito/entities';

import { ContentType, HttpClient } from '../httpClient';

export class FeedbackClient extends HttpClient {
  org = {
    byOrg: (orgId: string) =>
      this.request<EmojiByOrgMap>({
        path: `/feedback/byOrgId/${orgId}`,
        method: 'GET',
        type: ContentType.Json,
      }),
  };

  feedback = {
    getEvents: (orgId?: string) =>
      this.request<Event[]>({
        path: '/feedback/list',
        method: 'GET',
        query: { orgId },
        type: ContentType.Json,
      }),

    getEvent: (id: string) =>
      this.request<EventWithPlayers & { ratings: FeedbackEvent[] }>({
        path: `/feedback/single/${id}`,
        method: 'GET',
        type: ContentType.Json,
      }),

    emit: (data: EmittedFeedbackEvent) =>
      this.request<FeedbackEvent[]>({
        path: `/feedback/emit`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
      }),

    finish: (id: string) =>
      this.request<FeedbackEvent[]>({
        path: `/feedback/finish/${id}`,
        method: 'POST',
        type: ContentType.Json,
      }),
  };
}
