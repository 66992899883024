import { StackProps, Stack } from '@mantine/core';
import React from 'react';

type VStackProps = StackProps;

const VStack: React.FC<VStackProps> = ({ children, ...rest }) => (
  <Stack {...rest}>{children}</Stack>
);

export default VStack;
