import { Indicator, MediaQuery, SimpleGrid } from '@mantine/core';
import React from 'react';
import { ThemeContext } from '@aclito/ui-web/providers/ThemeProvider';

import { HeaderProps } from '../../interface';
import { MenuItemContainer, StyledHStack, StyledText } from '../DrawerStyles';

import {
  ROUTE_AVAILABILITY,
  ROUTE_EVENTS,
  ROUTE_FEEDS,
  ROUTE_NOTIFICATIONS,
  ROUTE_ORGANIZATIONS,
  ROUTE_PROFILE,
  ROUTE_SEARCH,
  ROUTE_GOALS,
  ROUTE_FEEDBACK,
} from '@/util/constants';
import { Icon } from '@/components';
import { useNavigate } from '@/hooks/useNavigate';
import DrawerDivider from '@/components/Drawer/components/DrawerDivider';
import { useDrawer } from '@/components/Drawer/hooks/useDrawer';

const Header: React.FC<HeaderProps> = ({ closeNavigation }) => {
  const navigate = useNavigate();

  const { count, label, matchers, getSxStyle } = useDrawer();

  return (
    <SimpleGrid cols={1} verticalSpacing={0} style={{ marginTop: '16px' }}>
      <MenuItemContainer sx={getSxStyle(false)}>
        <ThemeContext.Consumer>
          {({ toggleTheme, theme: themeType }) => (
            <>
              {themeType === 'light' ? (
                <StyledHStack onClick={toggleTheme}>
                  <Icon icon={'dark'} />
                  <StyledText tx="darkmode" $active={false} />
                </StyledHStack>
              ) : (
                <StyledHStack onClick={toggleTheme}>
                  <Icon icon={'light'} />
                  <StyledText tx="lightmode" $active={false} />
                </StyledHStack>
              )}
            </>
          )}
        </ThemeContext.Consumer>
      </MenuItemContainer>

      <DrawerDivider />

      <MenuItemContainer
        sx={getSxStyle(matchers.isNotifications)}
        onClick={() => {
          navigate(ROUTE_NOTIFICATIONS);
          closeNavigation();
        }}
      >
        <StyledHStack>
          <Icon icon="notifications" size="24" />
          <StyledText
            tx="nav.notifications"
            $active={matchers.isNotifications}
          />
          <MediaQuery smallerThan="lg" styles={{ display: 'none' }}>
            {count ? (
              <Indicator
                label={label}
                color={'red'}
                style={{ marginLeft: 16 }}
                size={18}
              >
                <></>
              </Indicator>
            ) : (
              <></>
            )}
          </MediaQuery>
        </StyledHStack>
      </MenuItemContainer>

      <DrawerDivider />

      <MenuItemContainer
        sx={getSxStyle(matchers.isEvents)}
        onClick={() => {
          navigate(ROUTE_EVENTS);
          closeNavigation();
        }}
      >
        <StyledHStack>
          <Icon icon="calendar" size="24" />
          <StyledText tx="nav.event" $active={matchers.isEvents} />
        </StyledHStack>
      </MenuItemContainer>
      <MenuItemContainer
        onClick={() => {
          navigate(ROUTE_SEARCH);
          closeNavigation();
        }}
        sx={getSxStyle(matchers.isSearch)}
      >
        <StyledHStack>
          <Icon icon="search" size="24" />
          <StyledText tx="nav.search" $active={matchers.isSearch} />
        </StyledHStack>
      </MenuItemContainer>
      <MenuItemContainer
        onClick={() => {
          navigate(ROUTE_FEEDS);
          closeNavigation();
        }}
        sx={getSxStyle(matchers.isFeeds)}
      >
        <StyledHStack>
          <Icon icon="feed" size="24" />
          <StyledText tx="nav.feed" $active={matchers.isFeeds} />
        </StyledHStack>
      </MenuItemContainer>

      <MenuItemContainer
        onClick={() => {
          navigate(ROUTE_PROFILE);
          closeNavigation();
        }}
        sx={getSxStyle(matchers.isProfile)}
      >
        <StyledHStack>
          <Icon icon="person" size="24" />
          <StyledText tx="nav.profile" $active={matchers.isProfile} />
        </StyledHStack>
      </MenuItemContainer>

      <DrawerDivider />

      <MenuItemContainer
        onClick={() => {
          navigate(ROUTE_ORGANIZATIONS);
          closeNavigation();
        }}
        sx={getSxStyle(matchers.isOrganizations)}
      >
        <StyledHStack>
          <Icon icon="org" size="24" />
          <StyledText tx="nav.org" $active={matchers.isOrganizations} />
        </StyledHStack>
      </MenuItemContainer>
      <MenuItemContainer
        onClick={() => {
          navigate(ROUTE_AVAILABILITY);
          closeNavigation();
        }}
        sx={getSxStyle(matchers.isAvailability)}
      >
        <StyledHStack>
          <Icon icon="availability" size="24" />
          <StyledText tx="nav.availability" $active={matchers.isAvailability} />
        </StyledHStack>
      </MenuItemContainer>

      <MenuItemContainer
        onClick={() => {
          navigate(ROUTE_GOALS);
          closeNavigation();
        }}
        sx={getSxStyle(matchers.isGoals)}
      >
        <StyledHStack>
          <Icon icon="trophy" size="24" />
          <StyledText tx="nav.goals" $active={matchers.isGoals} />
        </StyledHStack>
      </MenuItemContainer>

      <MenuItemContainer
        onClick={() => {
          navigate(ROUTE_FEEDBACK);
          closeNavigation();
        }}
        sx={getSxStyle(matchers.isFeedback)}
      >
        <StyledHStack>
          <Icon icon="feedback" size="24" />
          <StyledText tx="nav.feedback" $active={matchers.isFeedback} />
        </StyledHStack>
      </MenuItemContainer>
    </SimpleGrid>
  );
};

export default Header;
