import { Organization, UserInfo } from '@aclito/entities';

import { MemberType, UserWithMembershipType } from '../types';

export const sectionMembers = (
  org: Organization | undefined,
  membersInfo: UserInfo[],
  me: UserInfo,
) => {
  const adminInfo: UserWithMembershipType[] =
    org?.admins?.map((admin) => ({
      ...membersInfo.find((member) => member.id === admin)!,
      type: 'admin',
    })) ?? [];
  const powerUsersInfo: UserWithMembershipType[] =
    org?.powerUsers
      ?.filter((member) => member !== org.admins.find((p) => p === member))
      .map((admin) => ({
        ...membersInfo.find((member) => member.id === admin)!,
        type: 'powerUser',
      })) ?? [];
  const memberInfo: UserWithMembershipType[] =
    org?.members
      ?.filter((member) => member !== org.admins.find((ad) => ad === member))
      ?.filter((member) => member !== org.powerUsers.find((p) => p === member))
      .map((admin) => ({
        ...membersInfo.find((member) => member.id === admin)!,
        type: 'member',
      })) ?? [];
  const adminNames: string[] =
    adminInfo?.map((a) => `${a.name} ${a.surname}`) ?? [];
  const memberNames: string[] =
    memberInfo?.map((m) => `${m.name} ${m.surname}`) ?? [];
  const myRole: MemberType = [
    ...(adminInfo ?? []),
    ...(memberInfo ?? []),
    ...(powerUsersInfo ?? []),
  ].find((m) => m.id === me.id)?.type!;

  return {
    adminInfo,
    memberInfo,
    powerUsersInfo,
    adminNames,
    memberNames,
    myRole,
  };
};
