import React from 'react';
import { useTranslate } from '@aclito/shared/hooks/useTranslate';
import { Divider, LoadingOverlay } from '@mantine/core';
import { yupResolver } from '@hookform/resolvers/yup';
import { EventForm } from '@aclito/shared/types';
import { useForm, FormProvider } from 'react-hook-form';
import { getParticipantsCount } from '@aclito/shared/features/event/utils/getParticipantsCount';
import { useTheme } from 'styled-components';
import { StyledTitle } from '@aclito/ui-web/utils/styles';
import { sizeMax } from '@aclito/ui-web/utils/mediaQueries';
import { useDisplayEvent } from '@aclito/shared/redux/api/eventApi';
import { EventModel } from '@aclito/shared/classes/EventModel';

import { useEventCreateEdit } from '../../hooks/useEventCreateEdit';

import { useEventEdit } from './hooks/useEventEdit';

import {
  SectionGrid,
  ActionButton,
  Stepper,
  TitleBar,
  useStepper,
  VStack,
  StepperButtons,
} from '@/components';
import Description from '@/common/EventForm/Description';
import Place from '@/common/EventForm/Place';
import DateForm from '@/common/EventForm/DateForm';
import withRouter from '@/navigation/withRouter';
import { StateEventEdit } from '@/util/types';
import { TypedLocation } from '@/hooks/useLocation';

type EventEditProps = TypedLocation<StateEventEdit>;

const EventEdit: React.FC<EventEditProps> = ({ state }) => {
  const event = state?.event;
  const orgId = state?.orgId;

  const { model } = useDisplayEvent(event?.id);

  if (!model) return null;

  return <EventEditComponent model={model} orgId={orgId} />;
};

const EventEditComponent = ({
  model,
  orgId,
}: {
  model: EventModel;
  orgId: string | undefined;
}) => {
  const t = useTranslate();
  const {
    initialValues,
    editEventValidationSchema,
    allActivities,
    possibleGenders,
    levelOptions,
    isLoadingPlaces,
    handleUpdateEvents,
    orgOptions,
  } = useEventEdit(model);

  const { onSubmit, onError, navBack } = useEventCreateEdit(handleUpdateEvents);

  const { active, setActive, nextStep, prevStep } = useStepper();

  const methods = useForm<EventForm>({
    defaultValues: initialValues,
    // @ts-ignore
    resolver: yupResolver(
      editEventValidationSchema(
        getParticipantsCount(model.event),
        model.hasTrainer,
      ),
    ),
  });

  const steps = [
    {
      component: (
        <Description
          isJoined={model.isJoined}
          eventId={model.event.id}
          orgId={orgId}
          {...{ allActivities, possibleGenders, levelOptions, orgOptions }}
        />
      ),
      label: t('org.info.description'),
    },
    {
      component: <Place eventToEdit={model.event} />,
      label: t('events.new.place'),
    },
    {
      component: <DateForm showRepeating={false} />,
      label: t('events.new.date'),
    },
  ];

  const theme = useTheme();

  return (
    <SectionGrid
      style={{ alignSelf: 'center', maxWidth: sizeMax.tablet }}
      $isOpen={false}
    >
      <TitleBar>
        <StyledTitle tx="nav.eventInfoEdit" />
        <ActionButton
          onClick={navBack}
          bg={'red10'}
          textcolor={'red'}
          iconType={'close'}
        />
      </TitleBar>

      <FormProvider {...methods}>
        <VStack>
          <LoadingOverlay visible={isLoadingPlaces} />
          <Stepper
            {...{
              active,
              steps,
              setActive,
              nextStep,
              prevStep,
            }}
          />
          <Divider color={theme.grey10} />
          <StepperButtons
            {...{
              active,
              steps: steps.length,
              nextStep,
              prevStep,
              onError,
              onSubmit,
              create: false,
            }}
          />
        </VStack>
      </FormProvider>
    </SectionGrid>
  );
};

export default withRouter(EventEdit);
