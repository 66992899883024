import { useTranslate } from '@aclito/shared/hooks';
import { TranslateKey, TranslateValues } from '@aclito/shared/types';
import React from 'react';
import styled from 'styled-components';

type AnchorLinkProps = React.DetailedHTMLProps<
  React.AnchorHTMLAttributes<HTMLAnchorElement>,
  HTMLAnchorElement
> & {
  tx?: TranslateKey;
  text?: string;
  txOptions?: TranslateValues;
};
const AnchorLink: React.FC<AnchorLinkProps> = ({
  tx,
  text,
  children,
  txOptions,
  ...props
}) => {
  const t = useTranslate();

  const i18nText = tx && t(tx, txOptions);
  const content = i18nText || text || children;

  return (
    // @ts-ignore
    <StyledAnchorTag {...props}>{content}</StyledAnchorTag>
  );
};

const StyledAnchorTag = styled.a`
  font-size: 16px;
`;

export default AnchorLink;
