import { CognitoUser } from '@aws-amplify/auth';
import { Hub as AmplifyHub } from 'aws-amplify';
import moment from 'moment';

import { IStorage, LocaleType } from '../types';
import { LOCALE } from '../util/constants';
import { currentUser } from '../util/helpersApi';
import { AppState, store } from '../redux/store';

import { CognitoData, getUserData } from '@aclito/shared/util/cognitoUser';

class HubClass {
  storage: IStorage | undefined;

  user: CognitoData | undefined;

  cognitoUser: CognitoUser | undefined;

  init = (storage: IStorage) => {
    this.storage = storage;
    this.authListen();
    this.momentListen();
    this.localeListen();
  };

  getLocale = async () =>
    ((await this.storage!.getItem(LOCALE)) || 'cs') as LocaleType;

  authListen = () => {
    AmplifyHub.listen('auth', async () => {
      try {
        this.user = await getUserData();
        this.cognitoUser = await currentUser();
      } catch (error) {
        /* empty */
      }
    });
  };

  momentListen = () => {
    store.subscribe(() => {
      const state: AppState = store.getState();
      moment.locale(state.locale);
    });
  };

  localeListen = () => {
    store.subscribe(() => {
      const state = store.getState().locale;
      this.storage!.setItem(LOCALE, state || 'cs');
    });
  };
}

export const Hub = new HubClass();
