import axios from 'axios';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react';

export const handleError = (error: unknown) => {
  if (axios.isAxiosError(error)) {
    if (error.response?.status === 409) {
      return { error: { error: 'permission' } as FetchBaseQueryError };
    }
    if (error.response?.status === 400) {
      return { error: { error: 'fail' } as FetchBaseQueryError };
    }
    if (error.response?.status === 401) {
      return { error: { error: 'permission' } as FetchBaseQueryError };
    }
    if (error.response?.status === 403) {
      return { error: { error: 'permission' } as FetchBaseQueryError };
    }
    return { error: { error: 'fail' } as FetchBaseQueryError };
  }
  return { error: { error: 'fail' } as FetchBaseQueryError };
};
