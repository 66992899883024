import { Organization, UserInfo } from '@aclito/entities';
export type OrganizationModelDetermined = Omit<
  OrganizationModel,
  'organization'
> & {
  organization: Organization;
};
export class OrganizationModel {
  private _organization: Organization | undefined;
  private _userInfo: UserInfo;
  private _isAdmin = false;
  private _isPowerUser = false;
  private _isMember = false;
  private _canJoin = false;
  private _canShareOrg = false;
  private _canControl = false;
  private _canEdit = false;
  private _showLeave = false;
  private _showSharing = false;
  private _showDelete = false;
  private _showEdit = false;
  private _showPersonAdd = false;
  private _showAdd = false;

  constructor(organization: Organization | undefined, userInfo: UserInfo) {
    this._userInfo = userInfo;
    this._organization = organization;
    this.init();
  }

  init() {
    this._isAdmin = !!this._organization?.admins?.some(
      (admin) => admin === this._userInfo.id,
    );
    this._isPowerUser = !!this._organization?.powerUsers?.some(
      (member) => member === this._userInfo.id,
    );
    this._isMember = !!this._organization?.members?.some(
      (member) => member === this._userInfo.id,
    );
    this._canControl = this._isAdmin || this._isPowerUser;

    this._canJoin =
      !!this._organization &&
      (this._isMember === undefined ? false : !this._isMember) &&
      (this._organization.maxMembers === -1 ||
        this._organization.maxMembers > this._organization.members.length);

    this._canShareOrg = this._canControl && this._userInfo.settings.orgSharing;

    this._canEdit = this._isAdmin;

    this._showLeave = this._isMember || this._isAdmin || this._isPowerUser;

    this._showSharing = true;

    this._showDelete = this._isAdmin;

    this._showEdit = this._canControl;

    this._showPersonAdd = this._canControl;

    this._showAdd = this._canControl;
  }

  get organization(): Organization | undefined {
    return this._organization;
  }

  set organization(value: Organization) {
    this._organization = value;
  }

  get userInfo(): UserInfo {
    return this._userInfo;
  }

  set userInfo(value: UserInfo) {
    this._userInfo = value;
  }

  get isAdmin(): boolean {
    return this._isAdmin;
  }

  set isAdmin(value: boolean) {
    this._isAdmin = value;
  }

  get showLeave(): boolean {
    return this._showLeave;
  }

  set showLeave(value: boolean) {
    this._showLeave = value;
  }

  get isPowerUser(): boolean {
    return this._isPowerUser;
  }

  set isPowerUser(value: boolean) {
    this._isPowerUser = value;
  }

  get isMember(): boolean {
    return this._isMember;
  }

  set isMember(value: boolean) {
    this._isMember = value;
  }

  get canJoin(): boolean {
    return this._canJoin;
  }

  set canJoin(value: boolean) {
    this._canJoin = value;
  }

  get canShareOrg(): boolean {
    return this._canShareOrg;
  }

  set canShareOrg(value: boolean) {
    this._canShareOrg = value;
  }

  get canControl(): boolean {
    return this._canControl;
  }

  set canControl(value: boolean) {
    this._canControl = value;
  }

  get canEdit(): boolean {
    return this._canEdit;
  }

  set canEdit(value: boolean) {
    this._canEdit = value;
  }

  get showSharing(): boolean {
    return this._showSharing;
  }

  set showSharing(value: boolean) {
    this._showSharing = value;
  }

  get showDelete(): boolean {
    return this._showDelete;
  }

  set showDelete(value: boolean) {
    this._showDelete = value;
  }

  get showEdit(): boolean {
    return this._showEdit;
  }

  set showEdit(value: boolean) {
    this._showEdit = value;
  }

  get showPersonAdd(): boolean {
    return this._showPersonAdd;
  }

  set showPersonAdd(value: boolean) {
    this._showPersonAdd = value;
  }

  get showAdd(): boolean {
    return this._showAdd;
  }

  set showAdd(value: boolean) {
    this._showAdd = value;
  }
}
