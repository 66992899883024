import { useEventInfoHandlers } from '../../EventInfo/hooks/useEventInfoHandlers';

import { useModal } from '@/hooks/useModal';

export const useMarkerClick = () => {
  const { openModal } = useModal();

  const {
    handleRedirectToEventInfo,
    handleRedirectToLeader,
    handleRedirectToMap,
    handleRedirectToOrg,
  } = useEventInfoHandlers();

  const handleMarkerClick = (id: string) => {
    openModal({
      withCloseButton: false,
      centered: true,
      size: 'auto',
      padding: 0,
      type: 'calModal',
      values: {
        id,
        navigation: handleRedirectToEventInfo,
        handleRedirectToLeader,
        handleRedirectToOrg,
        handleRedirectToMap,
      },
    });
  };
  return { handleMarkerClick };
};
