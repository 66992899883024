import React from 'react';
import styled from 'styled-components';
import { EventModel } from '@aclito/shared/classes/EventModel';
import { deviceMax } from '@aclito/ui-web/utils/mediaQueries';

import { useEventInfoHandlers } from '../hooks/useEventInfoHandlers';

import Info from './Info';

import { HStack, VStack, BasicCard } from '@/components';

type InfoLeftProps = { model: EventModel };

const InfoLeft: React.FC<InfoLeftProps> = ({ model }) => {
  const { event } = model;
  const { handleRedirectToOrg, handleRedirectToLeader, handleRedirectToMap } =
    useEventInfoHandlers();

  return (
    <CardBehindStyled>
      <Content spacing={10} align={'center'}>
        <HStackStyled>
          <Info
            {...{
              event,
              handleRedirectToLeader,
              handleRedirectToOrg,
              handleRedirectToMap,
            }}
          />
        </HStackStyled>
      </Content>
    </CardBehindStyled>
  );
};

const Content = styled(VStack)`
  width: 100%;
  height: auto;
  @media ${deviceMax.desktopL} {
    width: 100%;
  }
`;

const CardBehindStyled = styled(BasicCard)`
  height: 100%;
  width: auto;
  background: unset;
  padding: unset;
  @media ${deviceMax.desktopL} {
    width: 100%;
  }
`;

const HStackStyled = styled(HStack)`
  width: 100%;
  display: flex;
  align-items: center;
  @media ${deviceMax.tabletS} {
    display: flex;
    flex-direction: row;
  }
`;

export default InfoLeft;
