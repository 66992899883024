import { EventWithPlayers, UserInfoWithAlias } from '@aclito/shared/types';
import { useEventInfoKick as useEventInfoKickShared } from '@aclito/shared/features/event/hooks/useEventInfoKick';

import { useModal } from '@/hooks/useModal';

export const useEventInfoKick = () => {
  const { openModal } = useModal();

  const { canBePlayerKicked } = useEventInfoKickShared();

  const handlePlayerKick = (
    event: EventWithPlayers,
    user: UserInfoWithAlias,
  ) => {
    openModal({
      txTitle: 'events.info.kick.header',
      type: 'kickPlayer',
      values: { event, user },
    });
  };

  return { handlePlayerKick, canBePlayerKicked };
};
