import {
  CreatePlaceInput,
  ListPlaceInput,
  Place,
  UpdatePlaceInput,
  AdminListPlaceInput,
} from '@aclito/entities';

import { ContentType, HttpClient } from '../httpClient';

export class PlaceClient extends HttpClient {
  admin = {
    list: (query: AdminListPlaceInput) =>
      this.request<Place[]>({
        path: '/places/admin/list',
        method: 'GET',
        query,
        type: ContentType.Json,
      }),
  };

  places = {
    list: (query: ListPlaceInput) =>
      this.request<Place[]>({
        path: '/places/list',
        method: 'GET',
        query,
        type: ContentType.Json,
      }),
    create: (body: CreatePlaceInput) =>
      this.request<Place>({
        path: '/places',
        method: 'POST',
        body,
        type: ContentType.Json,
      }),
    update: (id: string, body: UpdatePlaceInput) =>
      this.request<Place>({
        path: `/places/single/${id}`,
        method: 'PATCH',
        body,
        type: ContentType.Json,
      }),
  };
}
