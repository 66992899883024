import { colors } from '@aclito/shared/theme/colors';

import { Text } from '@/components';

const MapGuide = () => {
  return (
    <Text
      align="center"
      style={{
        color: colors.grey50,
      }}
      tx="tooltip.map.guide"
    />
  );
};

export default MapGuide;
