import { useTranslate } from '@aclito/shared/hooks';
import { OrganizationForm } from '@aclito/shared/types';
import { useFormErrorMap } from '@aclito/shared/hooks/useFormErrorMap';
import { showNotification } from '@aclito/ui-web/utils/showNotification';

import { useNavigate } from '@/hooks/useNavigate';

type AlertErrorKeys = keyof Pick<OrganizationForm, 'name' | 'telephone'>;

export const useOrgCreateEdit = () => {
  const t = useTranslate();

  const nav = useNavigate();
  const navBack = () => {
    nav(-1);
  };

  const tx: Record<AlertErrorKeys, string> = {
    name: t('org.name'),
    telephone: t('org.telephone'),
  };

  const { onError } = useFormErrorMap(tx, showNotification);

  return { onError, navBack };
};
