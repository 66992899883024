import React from 'react';
import styled from 'styled-components';
import { FormattedUserInfo } from '@aclito/shared/types';
import { deviceMax } from '@aclito/ui-web/utils/mediaQueries';
import { Stats as StatsType } from '@aclito/entities';

import { Avatar, HStack, VStack, Text } from '@/components';
import Karma from '@/common/Profile/Karma';
import Stats from '@/common/Profile/Stats';

export interface AboutUserMainProps {
  userInfo: FormattedUserInfo;
  stats?: StatsType;
  deleted?: boolean;
}

const AboutUserMain: React.FC<AboutUserMainProps> = ({
  userInfo,
  stats,
  deleted,
}) => (
  <Content spacing={20} align={'center'} justify={'center'}>
    <GroupedContent>
      <Avatar image={userInfo.image} size={64} />
      <StyledVStack align="center">
        {!deleted ? (
          <>
            <NameText text={`${userInfo.name} ${userInfo.surname}`} />
            {stats && <Karma points={stats.karma.value} />}
          </>
        ) : (
          <UserDeletedText
            size={'lg'}
            weight={'bold'}
            tx={'userprofile.deleted'}
          />
        )}
      </StyledVStack>
    </GroupedContent>
    {stats && <Stats {...{ emojis: stats.emoji }} />}
  </Content>
);

const UserDeletedText = styled(Text)`
  color: ${({ theme }) => theme.red};
`;

const GroupedContent = styled(HStack)`
  width: 100%;
  @media ${deviceMax.mobileS} {
    flex-direction: column;
  }
`;

const StyledVStack = styled(VStack)`
  gap: 0.3rem;
  align-items: unset;
`;

const Content = styled(VStack)`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NameText = styled(Text)`
  font-family: Roboto;
  font-size: 1.25rem;
  font-weight: 500;
`;

export default AboutUserMain;
