import { useJsApiLoader, GoogleMap } from '@react-google-maps/api';
import { ForwardedRef, MutableRefObject, createRef } from 'react';
import { config } from '@aclito/shared/config';
import { debounce } from '@aclito/shared/util/debounce';
import { M_TO_KM_CONVERSION } from '@aclito/shared/util/constants';

import { handleClusterClick } from '@/util/clusterClick';
import { MAP_API_KEY } from '@/util/constants';
import { useCenterChange } from '@/features/search/hooks/useCenterChange';
import { MarkedMapProps } from '@/features/search/types';

export const useSearchMap = (
  {
    radius,
    panToCenterOnLoad,
    onCenterChange,
    displayCircle,
    onMapClusterClick,
    events,
    filters,
  }: MarkedMapProps,
  ref: ForwardedRef<GoogleMap>,
) => {
  const mapRef = (ref || createRef<GoogleMap>()) as MutableRefObject<GoogleMap>;
  const { isLoaded: isMapLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: MAP_API_KEY,
  });

  const circleRadius =
    radius ||
    parseInt(filters?.distance || config.distance.eventsDefaultDistance) *
      M_TO_KM_CONVERSION;

  const { center, centerChanged } = useCenterChange(filters.location);

  const handleMapClusterClick = (location: { lat: string; lon: string }) => {
    const e = handleClusterClick(location, events, mapRef);
    onMapClusterClick?.(e);
  };

  const handleOnCenterChange = (e: google.maps.MapMouseEvent) => {
    const lng = e.latLng?.lng();
    const lat = e.latLng?.lat();
    if (displayCircle && center.lat !== lat && center.lng !== lng) {
      _debouncedSearch(lat, lng);
    }
  };

  const _debouncedSearch = debounce((lat: number, lon: number) => {
    const changed = centerChanged({
      lat,
      lon,
    });
    if (changed) onCenterChange?.({ ...changed, radius: circleRadius });
  }, 200);

  const handleOnLoad = (map: google.maps.Map) => {
    if (panToCenterOnLoad) {
      setTimeout(() => {
        map.panTo({ ...center });
      }, 500);
    }
  };

  return {
    handleOnCenterChange,
    center,
    isMapLoaded,
    mapRef,
    circleRadius,
    handleMapClusterClick,
    handleOnLoad,
  };
};
