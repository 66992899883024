import { Components, EventProps } from 'aclito-calendar';
import { EventWithDateObject } from '@aclito/shared/types';
import moment from 'moment';

import CustomToolBar from './components/CustomToolBar';
import EventComponent from './components/EventComponent';

import SlotComponent from '@/common/Calendar/components/SlotComponent';

export const components: Components<EventWithDateObject> = {
  toolbar: CustomToolBar,
  day: {
    event: (props: EventProps<EventWithDateObject>) => (
      <SlotComponent {...props} />
    ),
  },
  week: {
    header: (p) => <span>{moment(p.date).format('dddd').substring(0, 3)}</span>,
    event: (props: EventProps<EventWithDateObject>) => (
      <SlotComponent {...props} />
    ),
  },
  month: {
    header: (p) => <span>{moment(p.date).format('dddd').substring(0, 3)}</span>,
    event: EventComponent,
  },
};
