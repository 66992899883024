import { createStyles, Tabs as MantineTabs, TabsProps } from '@mantine/core';
import React, { Children, cloneElement } from 'react';
import styled, { useTheme } from 'styled-components';

import { ITab } from './Tab';

type ITabs = {
  children: React.ReactElement<ITab>[];
  selectedIndex: string;
  setSelectedIndex: (val: string) => void;
  growList?: boolean;
  tabWidth?: number;
} & TabsProps;

const useStyles = () =>
  createStyles(() => ({
    tab: {
      fontSize: 14,
      fontWeight: 500,
    },
  }));

const Tabs: React.FC<ITabs> = ({
  children,
  selectedIndex,
  setSelectedIndex,
  growList = true,
  tabWidth,
  ...rest
}) => {
  const theme = useTheme();
  const { classes } = useStyles()();

  return (
    <MantineTabs
      classNames={{ tabLabel: classes.tab }}
      color={theme.primary}
      styles={{
        tab: {
          color: theme.grey40,
          '&[data-active]': {
            borderBottomWidth: 4,
            color: theme.primary,
            height: '40px',
          },
          borderBottomWidth: 1,
          width: tabWidth,
        },
      }}
      value={selectedIndex}
      {...rest}
    >
      <StyledList grow={growList}>
        {Children.map(children, (child: React.ReactElement<ITab>, i) =>
          cloneElement(child, {
            selectedIndex,
            key: `tab-header-${child.key}`,
            setSelectedIndex,
            value: child.props.value ?? i.toString(),
          }),
        )}
      </StyledList>

      {Children.map(children, (child: React.ReactElement<ITab>, i) => (
        <MantineTabs.Panel key={`tab-panel-${child.key}`} value={i.toString()}>
          {child.props.children}
        </MantineTabs.Panel>
      ))}
    </MantineTabs>
  );
};

const StyledList = styled(MantineTabs.List)`
  display: flex;
  flex-wrap: nowrap;
`;

export default Tabs;
