import { EventRaw } from '@aclito/entities';

import { getPlayersVirtual, getQueuePlayersVirtual } from './getPlayers';

export const getParticipantsCount = (event: EventRaw) =>
  getPlayersVirtual(_filterParticipants(event)).length;

export const getPlayersInQueueCount = (event: EventRaw) =>
  getQueuePlayersVirtual(_filterParticipants(event)).length;

const _filterParticipants = (event: EventRaw) => ({
  ...event,
  players: event.players?.filter((player) => player.willParticipate),
});
