import moment from 'moment';
import { UserInfo } from '@aclito/entities';

import { FormattedEventWithPlayers } from '../types';
import {
  canJoinEvent,
  canJoinQueue,
  isJoined,
} from '../features/event/utils/canJoinEvent';
import { eventIsRunning } from '../features/event/utils/eventIsRunning';
import { shouldConfirmEvent } from '../features/event/utils/shouldConfirmEvent';
import { isJoinedInChat } from '../features/event/utils/isJoinedInChat';
import { canLeaveEvent } from '../features/event/utils/canLeaveEvent';

export class EventModel {
  constructor(event: FormattedEventWithPlayers, userInfo: UserInfo) {
    this._event = event;
    this._userInfo = userInfo;
    this.init();
  }

  _hasTrainer = false;

  get hasTrainer(): boolean {
    return this._hasTrainer;
  }

  _event: FormattedEventWithPlayers;

  get event(): FormattedEventWithPlayers {
    return this._event;
  }

  set event(value: FormattedEventWithPlayers) {
    this._event = value;
  }

  _userInfo: UserInfo;

  get userInfo(): UserInfo {
    return this._userInfo;
  }

  set userInfo(value: UserInfo) {
    this._userInfo = value;
  }

  _displayOnly = false;

  get displayOnly(): boolean {
    return this._displayOnly;
  }

  set displayOnly(value: boolean) {
    this._displayOnly = value;
  }

  _canEdit = false;

  get canEdit(): boolean {
    return this._canEdit;
  }

  set canEdit(value: boolean) {
    this._canEdit = value;
  }

  _isJoined = false;

  get isJoined(): boolean {
    return this._isJoined;
  }

  set isJoined(value: boolean) {
    this._isJoined = value;
  }

  _isJoinedFully = false;

  get isJoinedFully(): boolean {
    return this._isJoinedFully;
  }

  set isJoinedFully(value: boolean) {
    this._isJoinedFully = value;
  }

  _isInQueue = false;

  get isInQueue(): boolean {
    return this._isInQueue;
  }

  set isInQueue(value: boolean) {
    this._isInQueue = value;
  }

  _hasRepetitionId = false;

  get hasRepetitionId(): boolean {
    return this._hasRepetitionId;
  }

  set hasRepetitionId(value: boolean) {
    this._hasRepetitionId = value;
  }

  _isInPast = false;

  get isInPast(): boolean {
    return this._isInPast;
  }

  set isInPast(value: boolean) {
    this._isInPast = value;
  }

  _canLeave = false;

  get canLeave(): boolean {
    return this._canLeave;
  }

  set canLeave(value: boolean) {
    this._canLeave = value;
  }

  _isJoinedInChat = false;

  get isJoinedInChat(): boolean {
    return this._isJoinedInChat;
  }

  set isJoinedInChat(value: boolean) {
    this._isJoinedInChat = value;
  }

  _canJoin = false;

  get canJoin(): boolean {
    return this._canJoin;
  }

  set canJoin(value: boolean) {
    this._canJoin = value;
  }

  _canJoinQueue = false;

  get canJoinQueue(): boolean {
    return this._canJoinQueue;
  }

  set canJoinQueue(value: boolean) {
    this._canJoinQueue = value;
  }

  _isLeader = false;

  get isLeader(): boolean {
    return this._isLeader;
  }

  set isLeader(value: boolean) {
    this._isLeader = value;
  }

  _running = false;

  get running(): boolean {
    return this._running;
  }

  set running(value: boolean) {
    this._running = value;
  }

  _canShowShare = false;

  get canShowShare(): boolean {
    return this._canShowShare;
  }

  set canShowShare(value: boolean) {
    this._canShowShare = value;
  }

  _canKick = false;

  get canKick(): boolean {
    return this._canKick;
  }

  set canKick(value: boolean) {
    this._canKick = value;
  }

  _shouldConfirm = false;

  get shouldConfirm() {
    return this._shouldConfirm;
  }

  set shouldConfirm(value: boolean) {
    this._shouldConfirm = value;
  }

  _canDisplayChat = false;

  get canDisplayChat() {
    return this._canDisplayChat;
  }

  set canDisplayChat(value: boolean) {
    this._canDisplayChat = value;
  }

  private init() {
    if (this._event && this._userInfo) {
      this._displayOnly = moment(this._event.date) < moment();
      this._isInPast = moment(this._event.date) < moment();

      this._canEdit =
        !this._isInPast &&
        !!(
          this._event.leader === this._userInfo.id ||
          this._event.players.some(
            (p) => p.id === this.userInfo.id && p.isTrainer,
          ) ||
          this._event.org?.admins.some((admin) => admin === this._userInfo.id)
        );

      this._isJoined = isJoined(this._event, this._userInfo.id);

      this._isJoinedFully = this._userInfo.aliases.length
        ? this._event.players.filter(
            (player) => player.id === this._userInfo.id,
          ).length >=
          this._userInfo.aliases.length + 1
        : this._isJoined;

      this._isInQueue = this._event.queuePlayersInfo?.some(
        (player) => player.id === this._userInfo.id,
      );

      this._hasTrainer = this._event.players.some((p) => p.isTrainer);

      this._hasRepetitionId = !!this._event.repetitionId;

      this._canLeave = canLeaveEvent(this._event, this._userInfo);

      this._canJoin = canJoinEvent(this._event, this._userInfo);

      this._canJoinQueue = canJoinQueue(this._event);

      this._isLeader = this._event.leader === this._userInfo.id;

      this._running = eventIsRunning(this.event);

      this._canShowShare = this._userInfo.settings.eventSharing;

      this._isJoinedInChat = this._event.chat
        ? isJoinedInChat(this._event.chat, this.userInfo)
        : false;

      this._canDisplayChat = this._isJoined;

      this._canKick = this._event.leader
        ? this._userInfo.id === this._event.leader ||
          (!!this._event.players.find(
            (player) => player.isTrainer && player.id === this.userInfo.id,
          ) &&
            !this._displayOnly)
        : false;

      this._shouldConfirm = !!(
        this._event && shouldConfirmEvent(this._event, this._userInfo)
      );
    }
  }
}
