import { useMemo, useState } from 'react';

import {
  getRandomUserName,
  getRandomUserSurname,
} from '../../../util/getRandomUserAttributes';
import { getByIdTranslate } from '../../../util/selectObject';
import { config } from '../../../config';
import { composeInitialCreateValues } from '../utils/composeInitialCreateValues';
import { useTranslate } from '../../../hooks';

export const useProfilePrefill = () => {
  const t = useTranslate();

  const [prefill, setPrefill] = useState(composeInitialCreateValues(t));

  const placeholderName = useMemo(
    () => prefill.name || getRandomUserName(),
    [prefill],
  );
  const placeholderSurname = useMemo(
    () =>
      prefill.surname ||
      getByIdTranslate(getRandomUserSurname(), config.activities, t).item,
    [prefill],
  );

  return { prefill, setPrefill, placeholderSurname, placeholderName };
};
