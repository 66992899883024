import React from 'react';
import { useTheme } from 'styled-components';

import { HStack } from '@/components';

interface KarmaProps {
  points: number;
}

const Karma: React.FC<KarmaProps> = ({ points }) => {
  const theme = useTheme();

  return (
    <HStack spacing={3}>
      {[9, 8, 7, 6, 5, 4, 3, 2, 1].map((item, i) => (
        <div
          key={i}
          style={{
            width: 12,
            height: 12,
            borderRadius: 20,
            backgroundColor: item > points ? theme.lowKarma : theme.karma,
            marginTop: 1.5,
          }}
        />
      ))}
    </HStack>
  );
};

export default Karma;
