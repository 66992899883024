import { FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import feedbackClient from '@aclito/client/src/feedback-client';
import {
  EmittedFeedbackEvent,
  EmojiByOrgMap,
  Event,
  FeedbackEvent,
} from '@aclito/entities';

import { aclitoApi, AppState } from '../store';
import { EventWithPlayers } from '../../types';
import { useAppSelector } from '../../hooks';
import { profileSelectors } from '../slices/profileSlices';
import { EventModel } from '../../classes/EventModel';
import { formatEvent } from '../../features/event/utils/formatEvents';
import { mergeAndFilter } from '../../features/event/utils/mergeAndFilter';
import { mapFeedbackEvent } from '../../features/event/utils/mapFeedbackEvent';

export const LIST_PAST_EVENTS = 'listPastEvents';
export const GET_PAST_EVENT = 'getPastEvent';
export const EMIT_FEEDBACK_EVENT = 'emitFeedbackEvent';
export const FINISH_EVENT = 'finishEvent';
export const EMOJIS_BY_ORG = 'emojisByOrg';

export const feedbackApi = aclitoApi.injectEndpoints({
  endpoints: (builder) => ({
    [EMIT_FEEDBACK_EVENT]: builder.mutation<
      FeedbackEvent[],
      EmittedFeedbackEvent
    >({
      queryFn: async (args) => {
        try {
          const { data } = await feedbackClient.feedback.emit(args);
          return { data: data };
        } catch (error) {
          return {
            error: {
              error: 'fail',
            } as FetchBaseQueryError,
          };
        }
      },
      async onQueryStarted({ eventId }, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            feedbackApi.util.updateQueryData(
              'getPastEvent',
              eventId,
              (draft) => {
                return {
                  ...draft,
                  ratings: mergeAndFilter(draft.ratings, data, 'id'),
                };
              },
            ),
          );
        } catch (error) {
          return;
        }
      },
    }),
    [FINISH_EVENT]: builder.mutation<string, string>({
      queryFn: async (args) => {
        try {
          await feedbackClient.feedback.finish(args);
          return { data: args };
        } catch (error) {
          return {
            error: {
              error: 'fail',
            } as FetchBaseQueryError,
          };
        }
      },
      async onQueryStarted(id, { dispatch, queryFulfilled, getState }) {
        try {
          await queryFulfilled;
          const orgId = (getState() as AppState).userInfo.orgId;
          dispatch(
            feedbackApi.util.updateQueryData(
              'listPastEvents',
              undefined,
              (draft) => draft.filter((e) => e.id !== id),
            ),
          );
          dispatch(
            feedbackApi.util.updateQueryData('listPastEvents', orgId, (draft) =>
              draft.filter((e) => e.id !== id),
            ),
          );
        } catch (error) {
          return;
        }
      },
    }),
    [LIST_PAST_EVENTS]: builder.query<Event[], undefined | string>({
      queryFn: async (args) => {
        try {
          const { data } = await feedbackClient.feedback.getEvents(args);
          return { data: data };
        } catch (error) {
          return {
            error: {
              error: 'fail',
            } as FetchBaseQueryError,
          };
        }
      },
    }),
    [GET_PAST_EVENT]: builder.query<
      EventWithPlayers & { ratings: FeedbackEvent[] },
      string
    >({
      queryFn: async (id) => {
        try {
          const { data } = await feedbackClient.feedback.getEvent(id);
          return { data: data };
        } catch (error) {
          return {
            error: {
              error: 'fail',
            } as FetchBaseQueryError,
          };
        }
      },
    }),
    [EMOJIS_BY_ORG]: builder.query<EmojiByOrgMap, string>({
      queryFn: async (id) => {
        try {
          const { data } = await feedbackClient.org.byOrg(id);
          return { data: data };
        } catch (error) {
          return {
            error: {
              error: 'fail',
            } as FetchBaseQueryError,
          };
        }
      },
    }),
  }),
});

export const useFeedbackEvent = <T extends string | undefined>(id: T) => {
  const userInfo = useAppSelector(profileSelectors.userInfo);

  const { data, ...rest } = useGetPastEventQuery(id ?? '', { skip: !id });

  if (!data) {
    return {
      model: undefined,
      ratings: [],
      ...rest,
    };
  }
  const mappedEvent = mapFeedbackEvent(data, data.playersInfo);
  return {
    model: new EventModel(formatEvent(mappedEvent), userInfo),
    ratings: data.ratings,
    ...rest,
  };
};

export const {
  useGetPastEventQuery,
  useListPastEventsQuery,
  useEmitFeedbackEventMutation,
  useFinishEventMutation,
  useEmojisByOrgQuery,
} = feedbackApi;
