import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useFormContext } from 'react-hook-form';

import { AvailabilityForm } from '../../../types';

import { generateDates } from '@aclito/shared/util/generateDates';
import { useSelectObject } from '@aclito/shared/hooks';
import { availabilityActions } from '@aclito/shared/redux/slices';

export const useRepeatingForm = () => {
  const dispatch = useDispatch();

  const { getValues, watch } = useFormContext<AvailabilityForm>();

  const { translatedRepeatChoices } = useSelectObject();

  const watchDate = watch('date');
  const watchRepeat = watch('repeating');
  const watchNumberOfAvailabilities = watch('numberOfAvailabilities');
  const watchRepeatAfter = watch('repeatAfter');
  const watchDuration = watch('duration');

  useEffect(() => {
    if (watchRepeat) {
      if (watchDate && watchRepeatAfter?.item) {
        if (watchRepeatAfter && watchNumberOfAvailabilities) {
          dispatch(
            availabilityActions.updateAvailabilityDates({
              date: generateDates({
                repeatAfter: watchRepeatAfter,
                date: watchDate,
                numberToGenerate: watchNumberOfAvailabilities,
              }),
            }),
          );
        } else {
          dispatch(
            availabilityActions.updateAvailabilityDates({
              date: watchDate ? [watchDate] : [],
            }),
          );
        }
      }
    } else {
      dispatch(
        availabilityActions.updateAvailabilityDates({
          date: watchDate ? [watchDate] : [],
        }),
      );
    }
  }, [watchRepeat, watchRepeatAfter, watchDate, watchNumberOfAvailabilities]);

  return {
    ...getValues(),
    translatedRepeatChoices,
    watchDuration,
    watchDate,
  };
};
