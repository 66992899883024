import { config } from '../../../config';

import { ProfileInfoForm, txFn } from '@aclito/shared/types';
import { getByIdTranslate } from '@aclito/shared/util/selectObject';
import { RESERVED_AGE, RESERVED_GENDER } from '@aclito/shared/util/constants';

export const composeInitialCreateValues = (t: txFn): ProfileInfoForm => ({
  age: getByIdTranslate(RESERVED_AGE, config.age, t),
  gender: getByIdTranslate(RESERVED_GENDER, config.gender.signup, t),
  image: 'BASIC',
  languages: [],
  name: '',
  activities: [],
  surname: '',
  description: '',
  file: undefined,
});
