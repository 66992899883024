import moment from 'moment';

export const formattedWeekDate = (monthDate: Date) => {
  const formatStart = moment(monthDate).startOf('isoWeek').format(`MMM DD/MM`);
  const formatEndSameMonth = `${moment(monthDate)
    .endOf('isoWeek')
    .format(`DD/MM`)}`;
  const formatEndDifferentMonth = `${moment(monthDate)
    .endOf('isoWeek')
    .format(`MMM DD/MM`)}`;
  const formatYear = `${moment(monthDate).endOf('isoWeek').format('yyyy')}`;

  return `${
    moment(monthDate).startOf('isoWeek').get('month') ===
    moment(monthDate).endOf('isoWeek').get('month')
      ? `${formatStart} - ${formatEndSameMonth} ${formatYear}`
      : `${formatStart} - ${formatEndDifferentMonth} ${formatYear}`
  }`;
};
