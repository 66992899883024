import { MemberType, UserWithMembershipType } from '../types';

export const memberControls = (
  member: UserWithMembershipType,
  myRole: MemberType,
  withControls: boolean | undefined,
  myId: string,
) => {
  if (withControls) {
    if (myId === member.id || myRole === 'member') {
      return false;
    }
    if (
      myRole === 'admin' ||
      (myRole === 'powerUser' &&
        (member.type === 'member' || member.type === 'powerUser'))
    ) {
      return true;
    }

    return false;
  }
  return false;
};
