import { AnyAction } from 'redux';
import { Dispatch } from 'react';

import { REDUX_RESET } from '../../util/constants';
import {
  authActions,
  commonActions,
  eventActions,
  profileActions,
  notificationActions,
  availabilityActions,
  organizationsActions,
  locationActions,
} from '../slices';
import { aclitoApi } from '../store';
import { noteActions } from '../slices/noteSlices';

export const flushState = (dispatch: Dispatch<AnyAction>) => {
  Object.keys(authActions)
    .filter((key) => key.startsWith(REDUX_RESET))
    //@ts-ignore
    .forEach((fn) => dispatch(authActions[fn]()));
  Object.keys(commonActions)
    .filter((key) => key.startsWith(REDUX_RESET))
    //@ts-ignore
    .forEach((fn) => dispatch(commonActions[fn]()));
  Object.keys(eventActions)
    .filter((key) => key.startsWith(REDUX_RESET))
    //@ts-ignore
    .forEach((fn) => dispatch(eventActions[fn]()));
  Object.keys(profileActions)
    .filter((key) => key.startsWith(REDUX_RESET))
    //@ts-ignore
    .forEach((fn) => dispatch(profileActions[fn]()));
  Object.keys(notificationActions)
    .filter((key) => key.startsWith(REDUX_RESET))
    //@ts-ignore
    .forEach((fn) => dispatch(notificationActions[fn]()));
  Object.keys(availabilityActions)
    .filter((key) => key.startsWith(REDUX_RESET))
    //@ts-ignore
    .forEach((fn) => dispatch(availabilityActions[fn]()));
  Object.keys(organizationsActions)
    .filter((key) => key.startsWith(REDUX_RESET))
    //@ts-ignore
    .forEach((fn) => dispatch(organizationsActions[fn]()));
  Object.keys(locationActions)
    .filter((key) => key.startsWith(REDUX_RESET))
    //@ts-ignore
    .forEach((fn) => dispatch(locationActions[fn]()));
  Object.keys(noteActions)
    .filter((key) => key.startsWith(REDUX_RESET))
    //@ts-ignore
    .forEach((fn) => dispatch(noteActions[fn]()));
  dispatch(aclitoApi.util.resetApiState());
};
