import React, { useEffect } from 'react';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { Outlet, useNavigate } from 'react-router-dom';

import { Drawer } from '../components/Drawer';

import {
  getContextFromLocalStorage,
  removeContextFromLocalStorage,
} from '@/util/context';

const DrawerOutlet: React.FC = () => {
  const { signOut } = useAuthenticator((context) => [context.signOut]);

  const nav = useNavigate();
  useEffect(() => {
    const context = getContextFromLocalStorage();
    if (context.pathname) {
      removeContextFromLocalStorage();
      nav(context);
    }
  }, [nav]);
  return (
    <Drawer signOut={signOut}>
      <Outlet />
    </Drawer>
  );
};

export default DrawerOutlet;
