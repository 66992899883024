import React from 'react';

import { ITab } from '../Tabs/Tab';

import { SmallButton, SmallButtonProps } from './SmallButton';

interface TagButtonProps extends Omit<SmallButtonProps, 'bg'>, ITab {
  value: string;
}

const TagButton: React.FC<TagButtonProps> = ({
  value,
  setSelectedIndex,
  selectedIndex,
  ...rest
}) => {
  const handleTabIndexChange = () => setSelectedIndex?.(value);

  return (
    <SmallButton
      tx="events.new.tab.all"
      
      textcolor={selectedIndex === value ? 'whiteText' : 'grey30'}
      mr={'xs'}
      style={{
        borderRadius: 50,
        fontWeight: 500,
      }}
      onClick={handleTabIndexChange}
      {...rest}
      bg={selectedIndex === value ? 'primary' : 'whiteText'}
    />
  );
};

export default TagButton;
