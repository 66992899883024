/* eslint-disable react/display-name */
import { forwardRef } from 'react';
import {
  PasswordInputProps,
  PasswordInput as MantinePasswordInput,
} from '@mantine/core';
import { useTranslate } from '@aclito/shared/hooks';
import { useTheme } from 'styled-components';

import { getCommonInputLabelStyle } from '../../utils/styles';

import { InputProps } from './types';

const PasswordInput = forwardRef<
  HTMLInputElement,
  InputProps & PasswordInputProps
>(({ label, placeholder, txLabel, txPlaceholder, ...props }, ref) => {
  const t = useTranslate();
  const theme = useTheme();
  const i18nLabel = txLabel && t(txLabel);
  const labelContent = i18nLabel || label;

  const i18nPlaceholder = txPlaceholder && t(txPlaceholder);
  const placeholderContent = i18nPlaceholder || placeholder;

  return (
    <MantinePasswordInput // @ts-ignore
      ref={ref}
      variant="filled"
      styles={{
        input: { backgroundColor: theme.white },
        innerInput: { color: theme.grey80 },
        visibilityToggle: {
          color: theme.primary,
          '&:hover': {
            backgroundColor: theme.grey10,
          },
        },
        label: { ...getCommonInputLabelStyle(theme) },
      }}
      size="md"
      radius={10}
      label={labelContent}
      placeholder={placeholderContent}
      {...props}
    />
  );
});

export default PasswordInput;
