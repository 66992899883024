import { useTranslate } from '@aclito/shared/hooks';
import { Menu } from '@mantine/core';
import React from 'react';
import { useTheme } from 'styled-components';
import { EventModel } from '@aclito/shared/classes/EventModel';
import { useChatHandlers } from '@aclito/shared/features/event/hooks/useChatHandlers';

import { useEventInfoEditDelete } from '../hooks/useEventInfoEditDelete';
import { useEventInfoHandlers } from '../hooks/useEventInfoHandlers';

import AliasModalLeave from './AliasModalLeave';

import { ActionButton, HStack, Text, Icon } from '@/components';
import { useEventInfoLeave } from '@/features/event/screens/EventInfo/hooks/useEventInfoLeave';

interface ButtonsProps {
  model: EventModel;
  compact?: boolean;
}

const Buttons: React.FC<ButtonsProps> = ({ model, compact }) => {
  const t = useTranslate();

  const {
    event,
    userInfo,
    isJoined,
    isInQueue,
    canEdit,
    canLeave,
    isInPast,
    displayOnly,
    isJoinedInChat,
  } = model;
  const { handleShare } = useEventInfoHandlers();

  const {
    handleLeave,
    handleAliasesLeave,
    isOpenedLeaveModal,
    onCloseLeaveModal,
    onOpenLeaveModal,
  } = useEventInfoLeave(model);

  const { leaveChat, joinChat } = useChatHandlers(model);

  const { handleDelete, handleEdit } = useEventInfoEditDelete();

  const shareEvent = () => {
    handleShare(event.id, 'event', event.name);
  };

  const handleLeaveButtonClick = () => {
    if (userInfo.aliases.length) {
      onOpenLeaveModal();
      return;
    }

    handleLeave();
  };

  const handleChats = async () =>
    isJoinedInChat ? await leaveChat() : await joinChat();

  const theme = useTheme();

  return (
    <>
      <AliasModalLeave
        handleLeaveAliases={handleAliasesLeave}
        opened={isOpenedLeaveModal}
        onClose={onCloseLeaveModal}
        model={model}
      />
      {compact ? (
        <Menu>
          <Menu.Target>
            <ActionButton iconType={'more'} />
          </Menu.Target>
          <Menu.Dropdown
            style={{
              borderRadius: '10px',
              backgroundColor: theme.white,
              color: theme.grey80,
              border: 'none',
            }}
          >
            {!isInPast ? (
              <>
                {canEdit && (
                  <Menu.Item
                    icon={<Icon icon="edit" size={24} />}
                    onClick={() => handleEdit(event)}
                  >
                    <Text>{t('json.edit')}</Text>
                  </Menu.Item>
                )}
                <Menu.Item
                  onClick={shareEvent}
                  icon={<Icon icon="share" size={24} />}
                >
                  <Text>{t('nav.share')}</Text>
                </Menu.Item>
                {/*{canEdit && !displayOnly && (*/}
                {/*  <Menu.Item*/}
                {/*    icon={<Icon icon="personSearch" size={24} />}*/}
                {/*    onClick={() => handlePlayerSearch(event)}*/}
                {/*  >*/}
                {/*    <Text>{t('availability.info.suggestions')}</Text>*/}
                {/*  </Menu.Item>*/}
                {/*)}*/}

                {isJoined && !isInQueue && (
                  <Menu.Item
                    icon={<Icon icon={'chat'} size={24} />}
                    onClick={handleChats}
                  >
                    <Text tx={isJoinedInChat ? 'leave.chat' : 'join.chat'} />
                  </Menu.Item>
                )}

                {canLeave && (
                  <Menu.Item
                    icon={<Icon icon="leave" size={24} />}
                    onClick={handleLeaveButtonClick}
                  >
                    <Text>{t('leave')}</Text>
                  </Menu.Item>
                )}
                {canEdit && !displayOnly && (
                  <Menu.Item
                    icon={<Icon icon="delete" size={24} />}
                    onClick={() => handleDelete(event)}
                  >
                    <Text>{t('delete')}</Text>
                  </Menu.Item>
                )}
              </>
            ) : null}
          </Menu.Dropdown>
        </Menu>
      ) : (
        <HStack>
          {canEdit && (
            <ActionButton
              tx={'json.edit'}
              iconType={'edit'}
              onClick={() => handleEdit(event)}
            />
          )}
          <ActionButton
            onClick={shareEvent}
            iconType={'share'}
            tx={'nav.share'}
          />

          {/*{canEdit && !displayOnly && (*/}
          {/*  <ActionButton*/}
          {/*    iconType={'personSearch'}*/}
          {/*    onClick={() => handlePlayerSearch(event)}*/}
          {/*    tx={'availability.info.suggestions'}*/}
          {/*  />*/}
          {/*)}*/}

          {isJoined && (
            <ActionButton
              iconType={'chat'}
              onClick={handleChats}
              tx={isJoinedInChat ? 'leave.chat' : 'join.chat'}
            />
          )}
          {canEdit && !displayOnly && (
            <ActionButton
              bg={'red10'}
              textcolor={'red'}
              iconType="delete"
              tx={'delete'}
              onClick={() => handleDelete(event)}
            />
          )}
        </HStack>
      )}
    </>
  );
};

export default Buttons;
