import * as React from 'react';
import { GenderKey, RepeatingKey, ActivitiesKey } from '@aclito/shared/types';
import {
  FaHockeyPuck,
  FaRunning,
  FaGolfBall,
  FaTableTennis,
  FaSkating,
  FaBowlingBall,
  FaBicycle,
  FaSkiing,
  FaSnowboarding,
  FaChess,
  FaHorse,
  FaDumbbell,
  FaHiking,
  FaBasketballBall,
} from 'react-icons/fa';
import {
  IoFootball,
  IoTennisball,
  IoBaseball,
  IoFitness,
  IoMaleFemale,
  IoMale,
  IoFemale,
  IoBody,
} from 'react-icons/io5';
import {
  GiBoxingGlove,
  GiTennisRacket,
  GiPoolTriangle,
  GiMountainClimbing,
  GiFencer,
  GiBallerinaShoes,
} from 'react-icons/gi';
import {
  MdSportsVolleyball,
  MdSportsHandball,
  MdPool,
  MdSportsCricket,
  MdSportsRugby,
  MdSkateboarding,
  MdSportsKabaddi,
  MdRepeat,
  MdStar,
} from 'react-icons/md';
import { FiTarget } from 'react-icons/fi';
import { TbCurling, TbKarate, TbYoga } from 'react-icons/tb';
import { useTheme } from 'styled-components';

export const iconSelectRegistry = {
  icehockey: <FaHockeyPuck />,
  football: <IoFootball />,
  volleyball: <MdSportsVolleyball />,
  badminton: <GiTennisRacket />,
  running: <FaRunning />,
  tennis: <IoTennisball />,
  golf: <FaGolfBall />,
  tabletennis: <FaTableTennis />,
  handball: <MdSportsHandball />,
  box: <GiBoxingGlove />,
  swimming: <MdPool />,
  iceskating: <FaSkating />,
  archery: <FiTarget />,
  baseball: <IoBaseball />,
  bowling: <FaBowlingBall />,
  cricket: <MdSportsCricket />,
  curling: <TbCurling />,
  cycling: <FaBicycle />,
  darts: <FiTarget />,
  billiard: <GiPoolTriangle />,
  wallclimbing: <GiMountainClimbing />,
  rugby: <MdSportsRugby />,
  skateboarding: <MdSkateboarding />,
  skiing: <FaSkiing />,
  snowboarding: <FaSnowboarding />,
  squash: <GiTennisRacket />,
  fencing: <GiFencer />,
  karate: <TbKarate />,
  taekwondo: <MdSportsKabaddi />,
  beachvolleyball: <MdSportsVolleyball />,
  chess: <FaChess />,
  wrestling: <MdSportsKabaddi />,
  horseriding: <FaHorse />,
  fitness: <IoFitness />,
  pilates: <TbYoga />,
  gym: <FaDumbbell />,
  yoga: <TbYoga />,
  hiking: <FaHiking />,
  basketball: <FaBasketballBall />,
  circletraining: <FaDumbbell />,
  personaltraining: <FaDumbbell />,
  aerobic: <IoFitness />,
  spinning: <FaBicycle />,
  tabata: <IoFitness />,
  trx: <FaDumbbell />,
  bosu: <FaDumbbell />,
  heat: <IoFitness />,
  hiit: <IoFitness />,
  zumba: <GiBallerinaShoes />,
  trampoline: <IoFitness />,
  ballet: <GiBallerinaShoes />,
  poledance: <GiBallerinaShoes />,
  hathayoga: <TbYoga />,
  poweryoga: <TbYoga />,
  dance: <GiBallerinaShoes />,
  bachata: <TbYoga />,
  crossfit: <IoFitness />,
  bodyform: <IoBody />,
  male: <IoMale />,
  female: <IoFemale />,
  any: <IoMaleFemale />,
  repeating: <MdRepeat />,
  default: <MdStar />,
};

const IconSelect: React.FC<{
  type: ActivitiesKey | GenderKey | RepeatingKey;
  iconColor?: string;
  iconSize?: number;
  style?: React.CSSProperties;
}> = ({ type, iconColor, iconSize, style }) => {
  const icons: Record<
    ActivitiesKey | GenderKey | RepeatingKey,
    React.ReactElement
  > = iconSelectRegistry;
  const theme = useTheme();

  const Icon = () =>
    icons[type] &&
    React.cloneElement(icons[type], {
      size: iconSize ?? 24,
      color: iconColor ?? theme.grey80,
    });

  return (
    <>
      {style ? (
        <div style={style}>
          <Icon />
        </div>
      ) : (
        <>
          <Icon />
        </>
      )}
    </>
  );
};

export default IconSelect;
