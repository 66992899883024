import { Middleware } from 'redux';

import { REDUX_LOCALE } from '../slices/commonSlices';
import { STARTING_LANGUAGE } from '../../util/constants';
import { IStorage } from '../../types';
import { AppDispatch, AppState } from '../store';

export const setupLocaleMiddleware = (storage: IStorage) => {
  const localeMiddleware: Middleware<
    NonNullable<unknown>,
    AppState,
    AppDispatch
  > = () => (next) => async (action) => {
    switch (action.type) {
      case `${REDUX_LOCALE}/updateLocale`:
        await storage.setItem(STARTING_LANGUAGE, action.payload.locale);
        break;
      default:
    }
    return next(action);
  };
  return localeMiddleware;
};
