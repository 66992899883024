import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Place } from '@aclito/entities';

import { REDUX_RESET } from '../../util/constants';
import { PlaceInitialState } from '../initialStates';
import {
  createPlaceAsync,
  updatePlaceAsync,
  searchNearbyPlacesAsync,
} from '../thunks/placeThunks';
import { AppState } from '../store';
import { RejectCommonType } from '../types';

export const REDUX_PLACE = 'places';

const SlicePlace = createSlice({
  name: REDUX_PLACE,
  initialState: PlaceInitialState,
  reducers: {
    [REDUX_RESET + REDUX_PLACE]: () => PlaceInitialState,
    addPlace: (state, payload: PayloadAction<Place>) => {
      state.current.push(payload.payload);
    },
  },
  extraReducers: (builder) => {
    // List

    builder.addCase(searchNearbyPlacesAsync.pending, (state) => {
      state.error = undefined;
      state.isLoading = true;
    });

    builder.addCase(searchNearbyPlacesAsync.fulfilled, (state, action) => {
      state.isLoading = false;
      state.current = action.payload;
    });

    builder.addCase(searchNearbyPlacesAsync.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as RejectCommonType;
    });

    // Create
    builder.addCase(createPlaceAsync.pending, (state) => {
      state.error = undefined;
      state.isLoading = true;
    });

    builder.addCase(createPlaceAsync.fulfilled, (state, action) => {
      state.isLoading = false;
      if (!state.current) {
        return;
      }
      state.current.push(action.payload);
    });

    builder.addCase(createPlaceAsync.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as RejectCommonType;
    });

    // Update
    builder.addCase(updatePlaceAsync.pending, (state) => {
      state.error = undefined;
      state.isLoading = true;
    });

    builder.addCase(updatePlaceAsync.fulfilled, (state, action) => {
      state.isLoading = false;
      const all = state.current;
      const filtered = all
        .filter(Boolean)
        .filter((item) => item.id !== action.payload.id);
      filtered.push(action.payload);
      state.current = filtered;
    });

    builder.addCase(updatePlaceAsync.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as RejectCommonType;
    });
  },
});

export const placeActions = {
  ...SlicePlace.actions,
  createPlaceAsync,
  updatePlaceAsync,
  searchNearbyPlacesAsync,
};
export const placeReducers = {
  [REDUX_PLACE]: SlicePlace.reducer,
};

export const placeSelectors = {
  places: (state: AppState) => state.places.current,
  placesLoading: (state: AppState) => state.places.isLoading,
};
