import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { REDUX_RESET } from '../../util/constants';
import {
  FeedFiltersInitialState,
  SearchOrganizationsInitialState,
} from '../initialStates';
import { OrgFilter } from '../../types';
import { AppState } from '../store';

import { FeedFilters } from '@aclito/shared/redux/types';

export const REDUX_ORGANIZATIONS = 'searchOrganizations';
export const REDUX_FEED_FILTERS = 'feedFilters';

const SliceSearchOrganizations = createSlice({
  name: REDUX_ORGANIZATIONS,
  initialState: SearchOrganizationsInitialState,
  reducers: {
    [REDUX_RESET + REDUX_ORGANIZATIONS]: () => SearchOrganizationsInitialState,
    setOrganizationFilter: (state, action: PayloadAction<OrgFilter>) => ({
      ...state,
      filter: action.payload,
    }),
    clearOrganizationFilter: (state) => ({ ...state, filter: { name: '' } }),
  },
});

const SliceFeedFilters = createSlice({
  name: REDUX_FEED_FILTERS,
  initialState: FeedFiltersInitialState,
  reducers: {
    [REDUX_RESET + REDUX_FEED_FILTERS]: () => FeedFiltersInitialState,
    setFeedFilters: (_, action: PayloadAction<FeedFilters>) => ({
      feedOrg: action.payload.feedOrg,
    }),
    clearFeedFilters: () => FeedFiltersInitialState,
  },
});

export const organizationsActions = {
  ...SliceSearchOrganizations.actions,
  ...SliceFeedFilters.actions,
};
export const organizationsReducers = {
  [REDUX_ORGANIZATIONS]: SliceSearchOrganizations.reducer,
  [REDUX_FEED_FILTERS]: SliceFeedFilters.reducer,
};

export const organizationsSelectors = {
  getOrgFilter: (state: AppState) => state.searchOrganizations.filter,
  getFeedFilter: (state: AppState) => state.feedFilters,
};
