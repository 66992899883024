import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { cardColorPicker } from '@aclito/shared/util/cardColorPicker';
import { EventProps } from 'aclito-calendar';
import { EventWithDateObject } from '@aclito/shared/types';
import { useMediaQuery } from '@mantine/hooks';
import { deviceMax } from '@aclito/ui-web/utils/mediaQueries';

import { IconSelect } from '@/components';

const EventComponent = ({ event }: EventProps<EventWithDateObject>) => {
  const sportColor = cardColorPicker(event.activityType);

  const mqLaptop = useMediaQuery(deviceMax.laptop);

  return (
    <StyledLine sportColor={sportColor}>
      <StyledBox>
        <StyledTime>{moment(event.date).format('H:mm')} </StyledTime>
        <StyledText>{event.name}</StyledText>
      </StyledBox>
      <IconSelect
        iconSize={mqLaptop ? 12 : 16}
        iconColor={cardColorPicker(event.activityType)}
        type={event.activityType}
        style={styleIconSelect}
      />
    </StyledLine>
  );
};

const styleIconSelect: React.CSSProperties = {
  display: 'flex',
  alignItems: 'center',
};

const StyledLine = styled.span<{ sportColor: string }>`
  display: flex;
  justify-content: space-between;
  color: ${(props) => props.sportColor};
  background: ${(props) => `${props.sportColor}26`};
  transition: transform 0.3s ease-in-out;
  border-left: ${(props) => `solid 8px ${props.sportColor}`};
  @media ${deviceMax.desktop} {
    border-left: ${(props) => `solid 6px ${props.sportColor}`};
  }
  @media ${deviceMax.laptop} {
    border-left: ${(props) => `solid 4px ${props.sportColor}`};
  }
`;

const StyledBox = styled.span`
  display: flex;
  gap: 4px;
  @media ${deviceMax.tablet} {
    gap: 2px;
  }
`;

const StyledText = styled.span`
  font-weight: 700;
  width: 50%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media ${deviceMax.desktop} {
    font-size: 12px;
  }
`;
const StyledTime = styled.span`
  @media ${deviceMax.desktop} {
    font-size: 12px;
  }
`;

export default EventComponent;
