import type { UserInfo } from '@aclito/entities';
import { Link } from 'react-router-dom';
import React from 'react';
import styled from 'styled-components';
import { useSelectObject, useTranslate } from '@aclito/shared/hooks';
import { config } from '@aclito/shared/config';

import {
  Avatar,
  HStack,
  VStack,
  SmallButton,
  Text,
  PlainCard,
} from '@/components';
import { ROUTE_USER_PROFILE } from '@/util/constants';

interface PlayerCardProps {
  player: UserInfo;
  invitePlayer: (player: UserInfo) => Promise<void>;
}

const SuggestionCard: React.FC<PlayerCardProps> = ({
  player,
  invitePlayer,
}) => {
  const t = useTranslate();

  const handleInvite = () => invitePlayer(player);
  const { getById } = useSelectObject();

  return (
    <Items>
      <PlainCard>
        <HStack>
          <ProfileLink to={ROUTE_USER_PROFILE} state={{ userInfo: player }}>
            <HStack>
              <Avatar size={50} image={player.image} />
              <VStack spacing={0}>
                <Text text={`${player.name} ${player.surname}`} weight={500} />
                <Text
                  text={`${t('profile.age')}: ${
                    getById(player.age, config.age).item
                  }`}
                  weight={300}
                />
              </VStack>
            </HStack>
          </ProfileLink>
          <ButtonContainer>
            <SmallButton
              onClick={handleInvite}
              bg="primary"
              tx="suggestions.invite"
            />
          </ButtonContainer>
        </HStack>
      </PlainCard>
    </Items>
  );
};

const ProfileLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: ${({ theme }) => theme.black};
`;

const ButtonContainer = styled.div`
  margin-left: auto;
  margin-right: 20px;
`;
const Items = styled.div`
  margin: 16px;
`;

export default SuggestionCard;
