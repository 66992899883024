import { EventRaw } from '@aclito/entities';

import { formatEvent } from '@aclito/shared/features/event/utils/formatEvents';
import { dateSort } from '@aclito/shared/util/sorter';

export const composeEvents = (events: EventRaw[]) => {
  return dateSort(
    events.map((event) => formatEvent(event)),
    'date',
    'desc',
  );
};
