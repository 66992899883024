import { AppThunk, EventFilters } from '../types';
import { eventActions } from '../slices';

export const REDUX_LOAD_NOW_OR_NEVER = 'loadNowOrNever';
export const REDUX_FIND_EVENT = 'findEvent';
export const REDUX_JOIN_REPEATING_EVENTS = 'joinRepeatingEvents';
export const REDUX_DELETE_REPEATING_EVENTS = 'deleteRepeatingEvents';
export const REDUX_UPDATE_REPEATING_EVENTS = 'updateRepeatingEvents';
export const REDUX_INVITE_TO_EVENT = 'inviteToEvent';
export const REDUX_UPDATE_EVENT_AS_FINISHED = 'updateEventAsFinished';
export const REDUX_LOAD_SEARCH_EVENTS = 'loadSearchEvents';
export const REDUX_LOAD_SEARCH_LOCATION = 'loadSearchEventsLocation';

export const applyFilters =
  (values: EventFilters): AppThunk =>
  (dispatch) => {
    dispatch(eventActions.updateFilter(values));
  };
