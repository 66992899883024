import { useSchema } from '@aclito/shared/hooks';

import { useUserInformation } from '@/hooks/useUserInformation';

export const useAboutUser = () => {
  const { userInfoValidationSchema } = useSchema();

  const { handleUpdateUser, prefill, placeholderName, placeholderSurname } =
    useUserInformation();

  const initialValues = { ...prefill };

  return {
    initialValues,
    userInfoValidationSchema,
    handleUpdateUser,
    placeholderName,
    placeholderSurname,
  };
};
