import { createAsyncThunk, unwrapResult } from '@reduxjs/toolkit';
import { getLiteFilters } from '@aclito/ui-mobile/utils/getLiteFilters';

import { ThunkType } from '../types';
import {
  profileActions,
  commonActions,
  notificationActions,
  authActions,
} from '../slices';
import { AppState } from '../store';
import { AxiomLogger } from '../../classes/AxiomLogger';
import { eventApi } from '../api/eventApi';
import { noteActions } from '../slices/noteSlices';
import { feedApi } from '../api/feedApi';
import { achievementApi } from '../api/achievementApi';

import { organizationApi } from '@aclito/shared/redux/api/organizationApi';

export const REDUX_LOAD_ALL = 'loadAll';
export const REDUX_SUGGEST_PLAYERS = 'suggestPlayers';

export const loadAllAsync = createAsyncThunk<boolean, void, ThunkType>(
  REDUX_LOAD_ALL,
  async (_data, { dispatch, ...thunkAPI }) => {
    try {
      const { canLoadAgain } = (thunkAPI.getState() as AppState).isFirstLoaded;

      if (canLoadAgain) {
        dispatch(
          commonActions.updateFirstLoad({
            isLoading: true,
            canLoadAgain: true,
          }),
        );
      }

      const { user } = unwrapResult(
        await dispatch(profileActions.loadUserInfoAsync()),
      );

      AxiomLogger.injectUser(user.id);

      dispatch(achievementApi.endpoints.getAchievements.initiate('none'));
      dispatch(
        achievementApi.endpoints.getUserAchievementsByUserId.initiate({
          id: user.id,
        }),
      );
      dispatch(notificationActions.loadNotificationsAsync());
      dispatch(noteActions.getNoteAsync());
      dispatch(
        organizationApi.endpoints.listMyOrganizations.initiate({
          nextToken: null,
        }),
      );

      dispatch(eventApi.endpoints.loadMyEvents.initiate({ nextToken: null }));

      dispatch(
        commonActions.updateFirstLoad({
          isLoading: false,
          canLoadAgain: false,
        }),
      );

      return true;
    } catch (error: any) {
      if (error.type === 'fail') {
        dispatch(authActions.signOutAsync());
        return thunkAPI.rejectWithValue({ type: 'fail' });
      } else {
        return thunkAPI.rejectWithValue({ type: 'fail', payload: error });
      }
    }
  },
);

export const loadAllLiteAsync = createAsyncThunk<
  boolean,
  { orgId?: string },
  ThunkType
>(REDUX_LOAD_ALL, async ({ orgId }, { dispatch, ...thunkAPI }) => {
  try {
    const { user } = unwrapResult(
      await dispatch(profileActions.loadUserInfoAsync()),
    );
    dispatch(noteActions.getNoteAsync());

    const filters = (thunkAPI.getState() as AppState).searchFilters;
    const liteFilters = getLiteFilters(orgId, filters);
    AxiomLogger.injectUser(user.id);

    if (orgId) {
      dispatch(notificationActions.loadNotificationsAsync());
      dispatch(
        eventApi.endpoints.loadMyOrgEvents.initiate({
          nextToken: null,
          orgId: orgId,
        }),
      );
      dispatch(
        eventApi.endpoints.searchEvents.initiate({
          nextToken: null,
          ...liteFilters,
        }),
      );
      dispatch(feedApi.endpoints.listFeedsByOrg.initiate(orgId));
    }

    return true;
  } catch (error: any) {
    if (error.type === 'fail') {
      dispatch(authActions.signOutAsync());
      return thunkAPI.rejectWithValue({ type: 'fail' });
    } else {
      return thunkAPI.rejectWithValue({ type: 'fail', payload: error });
    }
  }
});
