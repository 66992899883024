import moment from 'moment';
import { UseFormGetValues } from 'react-hook-form';

import { useLoadMyEventsQuery } from '../../../redux/api/eventApi';

import { useAppSelector } from '@aclito/shared/hooks';
import { AvailabilityForm } from '@aclito/shared/types';
import { profileSelectors } from '@aclito/shared/redux/slices/profileSlices';
import { selectAvailabilities } from '@aclito/shared/redux/api/availabilityApi';

export const useAvailabilityCompleteEdit = (
  getValues: UseFormGetValues<AvailabilityForm>,
  availabilityId: string | undefined,
) => {
  const { data } = useLoadMyEventsQuery({
    nextToken: null,
  });
  const myEvents = data?.result ?? [];

  const cachedAvailabilities = useAppSelector(selectAvailabilities());

  const userInfo = useAppSelector(profileSelectors.userInfo);

  const alreadyHasEvent = () => {
    if (userInfo.aliases.length) return false;

    const values = getValues();
    const date = moment(values.date);
    const dateTo = moment(values.date).add(values.duration, 'hours');

    return myEvents.some(
      (event) =>
        (date <= moment(event.date) && dateTo >= moment(event.endDate)) ||
        (date >= moment(event.date) && dateTo <= moment(event.endDate)),
    );
  };

  const alreadyHasAvailability = () => {
    if (userInfo.aliases.length || !cachedAvailabilities) return false;

    const values = getValues();
    const date = moment(values.date);
    const dateTo = moment(values.date).add(values.duration, 'hours');
    return cachedAvailabilities.some(
      (availability) =>
        availability.id !== availabilityId &&
        ((date <= moment(availability.from) &&
          dateTo >= moment(availability.to)) ||
          (date >= moment(availability.from) &&
            dateTo <= moment(availability.to))),
    );
  };

  return { alreadyHasAvailability, alreadyHasEvent };
};
