import { Divider } from '@mantine/core';
import { useTheme } from 'styled-components';

const DrawerDivider = () => {
  const theme = useTheme();

  return <Divider h={1} style={{ margin: '8px 24px' }} color={theme.grey10} />;
};

export default DrawerDivider;
