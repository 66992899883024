import { UserSettings } from '@aclito/entities';

import { useAppSelector } from '../../../hooks';
import { profileSelectors } from '../../../redux/slices/profileSlices';
import { LocaleType, TranslateKey } from '../../../types';
import { SettingsType } from '../types';

import { useAppDispatch } from '@aclito/shared/hooks/useDispatch';
import { commonActions, profileActions } from '@aclito/shared/redux/slices';

export const useSharedSettings = () => {
  const dispatch = useAppDispatch();

  const settings = useAppSelector(profileSelectors.userInfo)?.settings as
    | UserSettings
    | undefined;

  const handleLocaleChange = (locale: LocaleType) =>
    dispatch(commonActions.updateLocale({ locale }));

  const handleUpdateSettings = (newSettings: UserSettings) => {
    dispatch(profileActions.updateUserSettingsAsync(newSettings));
  };

  const deleteUserAccount = async () => {
    await dispatch(profileActions.deleteAccountAsync());
  };

  const translationTable: Record<SettingsType, TranslateKey> = {
    chatNotifications: 'settings.chatNotifications',
    eventNotifications: 'settings.eventNotifications',
    eventSharing: 'settings.eventSharing',
    orgNotifications: 'settings.orgNotifications',
    orgSharing: 'settings.orgSharing',
    mailEventNotifications: 'settings.mailEventNotifications',
  };

  return {
    handleLocaleChange,
    handleUpdateSettings,
    initialValues: settings,
    translationTable,
    settings,
    deleteUserAccount,
  };
};
