import { useAppSelector } from '@aclito/shared/hooks/useSelector';
import {
  authActions,
  authSelectors,
} from '@aclito/shared/redux/slices/authSlices';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import styled, { useTheme } from 'styled-components';
import { useRegister } from '@aclito/shared/features/auth/hooks/useRegister';
import { Divider } from '@mantine/core';
import * as yup from 'yup';
import AuthLogo from '@aclito/ui-web/auth/AuthLogo';
import { Social, Screen, Header, Box } from '@aclito/ui-web/auth';
import { useAppDispatch } from '@aclito/shared/hooks/useDispatch';

import Footer from '../../components/Footer/Footer';

import RegisterForm from './components/Form/Form';
import { useRegisterWeb } from './hooks';

import { Container, VStack } from '@/components';
import WithAuth from '@/navigation/withAuth';
import Link from '@/common/Text/Link';
import withRouter from '@/navigation/withRouter';
import { ROUTE_SIGN_IN } from '@/util/constants';
import { submitOnKeyPress } from '@/util/submitOnKeyPress';

const Register: React.FC = () => {
  const isLoading = useAppSelector(authSelectors.isLoadingSignUp);
  const theme = useTheme();
  const { initialValues, registerValidationSchema } = useRegister();
  const { signUp } = useRegisterWeb();

  type RegisterSchemaType = yup.InferType<typeof registerValidationSchema>;

  const methods = useForm<RegisterSchemaType>({
    defaultValues: initialValues,
    resolver: yupResolver(registerValidationSchema),
  });

  const dispatch = useAppDispatch();

  return (
    <Box>
      <Header />
      <Container>
        <Screen>
          <VStack spacing={16}>
            <AuthLogo />
            <FormProvider {...methods}>
              <div
                onKeyDown={(e) =>
                  submitOnKeyPress(e, methods.handleSubmit(signUp))
                }
              >
                <Social
                  onSocialClick={(type) => {
                    dispatch(authActions.federatedSignInAsync(type));
                  }}
                />
                <Divider
                  style={{ marginTop: 20, marginBottom: 20 }}
                  h={1}
                  bg={theme.grey10}
                />
                <RegisterForm
                  isLoading={isLoading}
                  onSubmit={methods.handleSubmit(signUp)}
                />
              </div>
            </FormProvider>

            <AlreadyHaveAccLink
              textProps={{ tx: 'auth.signin.acc', color: 'primary' }}
              to={ROUTE_SIGN_IN}
            />
          </VStack>
        </Screen>
      </Container>
      <Footer />
    </Box>
  );
};

const AlreadyHaveAccLink = styled(Link)`
  padding: 0;
  margin: 16px 0px 32px 0px;
  color: ${({ theme }) => theme.primary};
  display: flex;
  justify-content: center;
`;

export default WithAuth(withRouter(Register));
