import { Auth } from 'aws-amplify';

import { errorObject } from '../../utils/errorObject';
/**
 *
 * @param username resends sign up code
 * @returns An appropriate error
 */
export const makeResendSignUpCode = async (
  username: string,
): Promise<boolean> => {
  try {
    await Auth.resendSignUp(username);
    return true;
  } catch (error) {
    //@ts-ignore
    return Promise.reject(errorObject[error.code]);
  }
};
