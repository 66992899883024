import React, { Fragment, PropsWithChildren } from 'react';
import { IntlProvider } from 'react-intl';

import { LocaleType } from '../types';

import text_cs from './messages/cs.json';
import text_en from './messages/en.json';
import text_sk from './messages/sk.json';
import text_fr from './messages/fr.json';

export const messages: Record<string, Record<string, string>> = {
  en: text_en,
  cs: text_cs,
  sk: text_sk,
  fr: text_fr,
};

type ProviderProps = { locale: LocaleType };

const Provider: React.FC<PropsWithChildren<ProviderProps>> = ({
  children,
  locale,
}) => {
  return (
    <IntlProvider
      textComponent={Fragment}
      locale={locale || 'cs'}
      messages={messages[locale || 'cs']}
    >
      {children}
    </IntlProvider>
  );
};
export default Provider;
