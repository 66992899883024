import {
  useEmitFeedbackEventMutation,
  useFinishEventMutation,
} from '@aclito/shared/redux/api/feedbackApi';
import { EmojisKey } from '@aclito/shared/types';
import { FeedbackType } from '@aclito/enums';
import { closeAllModals } from '@mantine/modals';

import { useNavigate } from '@/hooks/useNavigate';
import { useModal } from '@/hooks/useModal';

export const useFeedbackHandlers = (eventId: string) => {
  const { openModal, openConfirmModal } = useModal();
  const nav = useNavigate();

  const handleFinish = () => {
    openConfirmModal({
      confirmProps: { color: 'primary', w: 100 },
      txTitle: 'nav.feedback',
      type: 'feedbackFinish',
      closeOnConfirm: true,
      txLabels: { confirm: 'yes', cancel: 'close' },
      onConfirm: async () => {
        await finishEvent(eventId);
        nav(-1);
      },
    });
  };

  const handleCommentClick = (
    userOrAliasId: string,
    name: string,
    text: string | undefined,
  ) => {
    openModal({
      type: 'feedbackComment',
      title: name,
      values: {
        text,
        onConfirm: async (res) => {
          closeAllModals();
          await emitFeedback({
            type: FeedbackType.REVIEW,
            eventId,
            text: res,
            userOrAliasId,
          });
        },
      },
    });
  };

  const handleKarmaClick = (
    userOrAliasId: string,
    name: string,
    karmaProvided: boolean,
  ) => {
    openModal({
      type: 'feedbackKarma',
      title: name,
      values: {
        karmaProvided,
        onConfirm: async () => {
          closeAllModals();
          await emitFeedback({
            type: FeedbackType.KARMA,
            eventId,
            userOrAliasId,
          });
        },
      },
    });
  };

  const handleEmojiSubmit = async (
    userOrAliasId: string,
    emoji: EmojisKey | null,
  ) => {
    openModal({
      type: 'feedbackEmoji',
      values: {
        emoji,
        onConfirm: async (e) => {
          closeAllModals();
          await emitFeedback({
            type: FeedbackType.EMOJI,
            emoji: e,
            eventId,
            userOrAliasId,
          });
        },
      },
    });
  };

  const [finishEvent] = useFinishEventMutation();
  const [emitFeedback] = useEmitFeedbackEventMutation();

  return {
    handleFinish,
    handleEmojiSubmit,
    handleCommentClick,
    handleKarmaClick,
  };
};
