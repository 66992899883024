import { DependencyList, useLayoutEffect, useState } from 'react';
import { debounce } from '@aclito/shared/util/debounce';

//WARNING: This will cause issues when changing window size frequently. Once the component behind isFlexWrapped is removed from the tree, it has nothing to calculate with.
export const useFlexWrap = (id: string, deps?: DependencyList) => {
  const [isFlexWrapped, setIsFlexWrapped] = useState(false);

  useLayoutEffect(() => {
    const checkFlexWrap = () => {
      const flexContainer = document.getElementById(id);
      const containerWidth = flexContainer?.clientWidth ?? 0;
      const totalItemsWidth = Array.from(flexContainer?.children ?? []).reduce(
        (total, item) => total + item.clientWidth,
        0,
      );
      setIsFlexWrapped(totalItemsWidth > containerWidth);
    };

    // Check initially
    checkFlexWrap();
    const debouncedCheckFlexWrap = debounce(checkFlexWrap, 200);
    // Add an event listener for window resize using the debounced function
    window.addEventListener('resize', debouncedCheckFlexWrap);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', debouncedCheckFlexWrap);
    };
  }, deps ?? []);

  return isFlexWrapped;
};
