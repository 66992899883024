import { Organization, UserInfo } from '@aclito/entities';

import { useAppSelector } from '@aclito/shared/hooks';
import { profileSelectors } from '@aclito/shared/redux/slices/profileSlices';
import {
  OrganizationModel,
  OrganizationModelDetermined,
} from '@aclito/shared/classes/OrganizationModel';
import { useFindOrganizationQuery } from '@aclito/shared/redux/api/organizationApi';

type Extra = { isFetching: boolean };

export const useOrganizationModel = <T extends string | Organization>(
  arg: T | undefined,
): T extends string
  ? { model: OrganizationModel } & Extra & {
        data: { org: Organization; members: UserInfo[] };
      }
  : T extends Organization
  ? {
      model: OrganizationModelDetermined;
    } & Extra
  : unknown => {
  const userInfo = useAppSelector(profileSelectors.userInfo);
  const { data, ...rest } = useFindOrganizationQuery(
    { id: arg as string },
    { skip: !arg || typeof arg !== 'string' },
  );
  if (typeof arg !== 'string') {
    // @ts-ignore
    return {
      model: new OrganizationModel(arg, userInfo) as Omit<
        OrganizationModel,
        'organization'
      > & { organization: Organization },
      ...rest,
    };
  }
  // @ts-ignore
  return {
    data,
    model: new OrganizationModel(data?.org, userInfo),
    ...rest,
  };
};
