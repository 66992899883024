import { useState } from 'react';

export const useStepper = (def?: number) => {
  const [active, setActive] = useState(def ?? 0);
  const nextStep = () =>
    setActive((current) => (current < 3 ? current + 1 : current));
  const prevStep = () =>
    setActive((current) => (current > 0 ? current - 1 : current));

  return { active, nextStep, prevStep, setActive };
};
