import { FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { chatClient, messageClient, WS_URL } from '@aclito/client';
import moment from 'moment';
import type { Chat, CreateMessageInput, Message } from '@aclito/entities';
import { ChatMessageMetaData } from '@aclito/enums';

import { aclitoApi, AppState } from '../store';
import { assertObjectExists } from '../../util/assertions';
import { randomString } from '../../util/randomizers';
import { findAllAndReplace } from '../../util/findAndReplace';

import { eventApi } from './eventApi';

export const JOIN_CHAT = 'joinChat';
export const LEAVE_CHAT = 'leaveChat';
export const SEND_MESSAGE = 'sendMessage';
export const FIND_AND_DISPLAY_CHAT = 'findAndDisplayChat';

export const messageApi = aclitoApi.injectEndpoints({
  endpoints: (builder) => ({
    [FIND_AND_DISPLAY_CHAT]: builder.query<
      { messages: Message[]; nextToken: string | null },
      { id: string; nextToken: string | null; refetch?: boolean }
    >({
      queryFn: async ({ id, nextToken }) => {
        try {
          const { data: messages } =
            await messageClient.messages.getMessagesByChatId({
              id,
              limit: 20,
              nextToken: nextToken,
            });

          return {
            data: { messages: messages.data, nextToken: messages.nextToken },
          };
        } catch (error) {
          return { error: { error: 'fail' } as FetchBaseQueryError };
        }
      },
      serializeQueryArgs: ({ queryArgs }) => {
        const { nextToken, refetch, ...rest } = queryArgs;
        return rest;
      },
      merge: (currentData, { messages, nextToken }, { arg }) => {
        if (arg.refetch) {
          currentData.messages = messages;
        } else {
          currentData.messages.push(...messages);
        }
        currentData.nextToken = nextToken;
      },
      forceRefetch({ currentArg, previousArg }) {
        return !!currentArg?.nextToken && currentArg !== previousArg;
      },
      async onCacheEntryAdded(
        { id },
        { updateCachedData, cacheDataLoaded, cacheEntryRemoved, getState },
      ) {
        const ws = new WebSocket(`${WS_URL}?chatId=${id}`);
        try {
          await cacheDataLoaded;
          const state = getState() as AppState;
          const user = state.userInfo.current;
          // MessageEvent throws TS error in RN repo
          const listener = (event: any) => {
            const data = JSON.parse(event.data) as Message;
            updateCachedData((draft) => {
              if (user.id !== data.from) {
                draft.messages.push(data);
              }
            });
          };

          ws.addEventListener('message', listener);
        } catch {
          return;
        }
        await cacheEntryRemoved;
        ws.close();
      },
    }),
    [SEND_MESSAGE]: builder.mutation<Message, CreateMessageInput>({
      queryFn: async (input) => {
        try {
          const { data: message } = await messageClient.messages.postMessage(
            input,
          );
          assertObjectExists(message);
          return { data: message };
        } catch (error) {
          return { error: { error: 'fail' } as FetchBaseQueryError };
        }
      },
      async onQueryStarted(data, { dispatch, queryFulfilled, getState }) {
        const state = getState() as AppState;
        const updateIdentifier = randomString();
        const user = state.userInfo.current;
        const patchResult = dispatch(
          messageApi.util.updateQueryData(
            'findAndDisplayChat',
            { id: data.chatId, nextToken: null },
            (draft) => {
              draft.messages.push({
                id: updateIdentifier,
                message: data.message,
                chatId: data.chatId,
                updatedAt: moment().toISOString(),
                createdAt: moment().toISOString(),
                date: moment().toISOString(),
                from: user.id,
                user,
                sent: false,
                system: false,
                meta: ChatMessageMetaData.Message,
              });
              return draft;
            },
          ),
        );
        try {
          const result = await queryFulfilled;
          dispatch(
            messageApi.util.updateQueryData(
              'findAndDisplayChat',
              { id: data.chatId, nextToken: null },
              (draft) => {
                const replaced = findAllAndReplace(
                  draft.messages,
                  result.data,
                  (i) => i.id === updateIdentifier,
                );
                return { ...draft, messages: replaced };
              },
            ),
          );
        } catch {
          patchResult.undo();
        }
      },
    }),
    [JOIN_CHAT]: builder.mutation<Chat, { id: string; eventId: string }>({
      queryFn: async ({ id }) => {
        try {
          const { data } = await chatClient.chats.joinChat({ id });
          assertObjectExists(data);
          return { data: data };
        } catch (error) {
          return { error: { error: 'fail' } as FetchBaseQueryError };
        }
      },
      async onQueryStarted(data, { dispatch, queryFulfilled }) {
        const res = await queryFulfilled;
        dispatch(
          eventApi.util.updateQueryData(
            'displayEvent',
            { id: data.eventId },
            (draft) => {
              draft.chat = res.data;
              return draft;
            },
          ),
        );
      },
    }),
    [LEAVE_CHAT]: builder.mutation<Chat, { id: string; eventId: string }>({
      queryFn: async ({ id }) => {
        try {
          const { data } = await chatClient.chats.leaveChat({ id });
          assertObjectExists(data);
          return { data: data };
        } catch (error) {
          return { error: { error: 'fail' } as FetchBaseQueryError };
        }
      },
      async onQueryStarted(data, { dispatch, queryFulfilled, getState }) {
        const state = getState() as AppState;
        const user = state.userInfo.current;
        const patchResult = dispatch(
          eventApi.util.updateQueryData(
            'displayEvent',
            { id: data.eventId },
            (draft) => {
              if (!draft.chat) return draft;

              draft.chat.participants = draft.chat.participants.filter(
                (p) => p !== user.id,
              );
              return draft;
            },
          ),
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
  }),
});

export const {
  useJoinChatMutation,
  useLeaveChatMutation,
  useSendMessageMutation,
  useFindAndDisplayChatQuery,
  useLazyFindAndDisplayChatQuery,
} = messageApi;
