import { useAppSelector, useSchema, useTranslate } from '@aclito/shared/hooks';
import { profileSelectors } from '@aclito/shared/redux/slices/profileSlices';
import {
  UserAliasForm,
  useProfileAliasesEdit,
} from '@aclito/shared/features/profile/hooks/useProfileAliasesEdit';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingOverlay } from '@mantine/core';
import React from 'react';
import { useForm } from 'react-hook-form';
import type { UserAlias } from '@aclito/entities';
import { ActionButton } from '@aclito/ui-web/components/Button';
import { TitleBar } from '@aclito/ui-web/components/TitleBar';
import { SectionGrid } from '@aclito/ui-web/components/Layout/Sections';
import { sizeMax } from '@aclito/ui-web/utils/mediaQueries';
import { StyledTitle } from '@aclito/ui-web/utils/styles';

import AliasForm from '../../components/AliasForm';

import withRouter from '@/navigation/withRouter';
import { TypedLocation } from '@/hooks/useLocation';
import { StateAliasEdit } from '@/util/types';
import { useNavigate } from '@/hooks/useNavigate';

type ProfileAliasesEditProps = TypedLocation<StateAliasEdit>;

const ProfileAliasesEdit: React.FC<ProfileAliasesEditProps> = ({ state }) => {
  const alias = state?.alias;
  const nav = useNavigate();
  const t = useTranslate();

  const { aliasesEditValidationSchema } = useSchema();
  const isLoadingUpdateMyProfile = useAppSelector(
    profileSelectors.isLoadingUpdateMyProfile,
  );

  const { updateSecondaryProfile, initialValues } = useProfileAliasesEdit(
    alias?.id ?? '',
  );

  const handleSubmit = async (aliasData: UserAlias) => {
    if (await updateSecondaryProfile(aliasData)) {
      nav(-1);
    }
  };

  const methods = useForm<UserAliasForm>({
    defaultValues: initialValues,
    //@ts-ignore
    resolver: yupResolver(aliasesEditValidationSchema),
  });

  const navBack = () => nav(-1);

  return (
    <SectionGrid
      style={{ alignSelf: 'center', maxWidth: sizeMax.tablet }}
      $isOpen={false}
    >
      <TitleBar>
        <StyledTitle tx="profile.alias" />
        <ActionButton
          onClick={navBack}
          bg={'red10'}
          textcolor={'red'}
          iconType={'close'}
        />
      </TitleBar>
      <LoadingOverlay visible={isLoadingUpdateMyProfile} overlayBlur={2} />

      <AliasForm
        {...{
          methods,
          handleSubmit,
          placeholderName: t('auth.userinfo.name'),
          placeholderSurname: t('auth.userinfo.surname'),
        }}
      />
    </SectionGrid>
  );
};

export default withRouter(ProfileAliasesEdit);
