import { logger } from 'react-native-logs';

const config = {
  transportOptions: {
    colors: {
      redux: 'magenta',
      render: 'green',
      debug: 'cyan',
      info: 'blueBright',
      warn: 'yellowBright',
      error: 'redBright',
    },
  },
  levels: {
    redux: -2,
    render: -1,
    debug: 0,
    info: 1,
    warn: 2,
    error: 2,
  },
};
type Severity = 'trace' | 'info' | 'error' | 'debug' | 'redux' | 'render';
let Logger: ReturnType<typeof logger.createLogger<Severity>>;

export const createLogger = (enabled: boolean, severity?: Severity) => {
  Logger = logger.createLogger<Severity>({
    ...config,
    enabled,
    severity: severity || undefined,
  });
};
export { Logger };
