import { IconBaseProps } from 'react-icons';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { CSSProperties } from 'react';

import { Icon } from '../Icons/Icon';

interface BackButtonProps extends IconBaseProps {
  style?: CSSProperties;
}

const BackButton = ({ onClick, style, ...rest }: BackButtonProps) => {
  const nav = useNavigate();

  const handleClick = (e: any) => {
    if (onClick) {
      onClick?.(e);
    } else {
      nav(-1);
    }
  };
  return (
    <StyledButton style={style}>
      <StyledIcon
        icon="backArrow"
        color={'primary'}
        onClick={handleClick}
        size="24"
        iconProps={rest}
      />
    </StyledButton>
  );
};

const StyledButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 8px;
  max-width: 40px;
  background-color: ${({ theme }) => theme.primary10};
  border-radius: 10px;
`;

const StyledIcon = styled(Icon)`
  transition: transform 200ms ease-in-out;
  &:hover {
    cursor: pointer;
    transform: scale(1.3);
  }
` as typeof Icon;

export default BackButton;
