//import { devToolsEnhancer } from '@redux-devtools/remote'; //Only use for development, otherwise leave commented

import { configureStore } from '@reduxjs/toolkit';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import {
  authReducers,
  availabilityReducers,
  commonReducers,
  eventReducers,
  notificationReducers,
  organizationsReducers,
  placeReducers,
  profileReducers,
} from './slices';
import { noteReducers } from './slices/noteSlices';
import dynamicMiddleware from './dynamicMiddleware';
import { mobileReducers } from './slices/mobileSlices';
import { locationReducers } from './slices/locationSlice';
import { customMiddleware } from './middleware/paginationMiddleware';

export const aclitoApi = createApi({
  reducerPath: 'aclitoApi',
  baseQuery: fetchBaseQuery({ baseUrl: '/' }),
  endpoints: () => ({}),
  tagTypes: [],
});

export const store = configureStore({
  reducer: {
    ...eventReducers,
    ...commonReducers,
    ...authReducers,
    ...profileReducers,
    ...placeReducers,
    ...notificationReducers,
    ...organizationsReducers,
    ...availabilityReducers,
    ...noteReducers,
    ...mobileReducers,
    ...locationReducers,
    [aclitoApi.reducerPath]: aclitoApi.reducer,
  },
  devTools: true,
  // enhancers: [
  //   devToolsEnhancer({
  //     //Only use for development, otherwise leave commented
  //     name: 'aclito',
  //     port: 8000,
  //     secure: false,
  //     realtime: true,
  //   }),
  // ],
  middleware: (getDefaultMiddleware) => {
    //@ts-ignore
    return getDefaultMiddleware().concat([
      aclitoApi.middleware,
      customMiddleware,
      dynamicMiddleware,
    ]);
  },
});
export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
