import { Organization } from '@aclito/entities';

import { OrganizationForm } from '../../../types';

import { useUpdateOrganizationMutation } from '@aclito/shared/redux/api/organizationApi';

export const useOrganizationEdit = (org: Organization | undefined) => {
  const [updateOrg, { isLoading }] = useUpdateOrganizationMutation();

  const editOrg = async (data: OrganizationForm) => {
    data.image = data.image?.trim() ? data.image : 'BASIC';
    if (org) {
      await updateOrg({
        ...data,
        id: org.id,
      }).unwrap();
    }
  };

  return { editOrg, isLoading };
};
