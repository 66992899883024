import { useAppSelector, useSchema } from '@aclito/shared/hooks';
import { useAppDispatch } from '@aclito/shared/hooks/useDispatch';
import { ForgotPasswordForm } from '@aclito/shared/types';
import { authActions } from '@aclito/shared/redux/slices';
import { authSelectors } from '@aclito/shared/redux/slices/authSlices';

export const useSubmitNewPassword = () => {
  const isLoading = useAppSelector(authSelectors.isLoadingSubmitPassword);
  const initialValues = { username: '', code: '', newPassword: '' };
  const { passwordSubmitSchema } = useSchema();

  const dispatch = useAppDispatch();

  const submitNewPassword = (values: ForgotPasswordForm) =>
    dispatch(authActions.submitNewPasswordAsync(values)).unwrap();

  return {
    initialValues,
    passwordSubmitSchema,
    submitNewPassword,
    isLoading,
  };
};
