import { Center } from '@mantine/core';
import React from 'react';

import { EventCardsProps } from '../interface';

import { ShowMoreButton } from '@/components';
import EventsCards from '@/components/Cards/EventsCards';

const EventCards: React.FC<EventCardsProps> = ({
  onClick,
  data,
  nextToken,
  day,
}) => {
  return (
    <>
      <EventsCards
        {...{
          data,
          showDateType: day ? 'day' : 'month',
        }}
      />
      {data.length && nextToken ? (
        <Center mt="xl">
          <ShowMoreButton onClick={onClick} />
        </Center>
      ) : null}
    </>
  );
};

export default EventCards;
