import { useAppSelector } from '@aclito/shared/hooks';
import { debounce } from '@aclito/shared/util/debounce';
import { useMemo, useState } from 'react';
import {
  useListMyOrganizationsQuery,
  useListAllOrganizationsQuery,
  selectMyOrganizations,
  useLazyListAllOrganizationsQuery,
  useCreateOrganizationMutation,
  useUpdateOrganizationMutation,
} from '@aclito/shared/redux/api/organizationApi';

import { OrgsTabType } from '../interface';

export const useOrganizations = () => {
  const [search, setSearch] = useState<string>('');
  const [debouncedSearch, setDebouncedSearch] = useState<string>('');
  const { isLoading: myOrgsLoading } = useListMyOrganizationsQuery({
    nextToken: null,
  });
  const { data, isLoading: allOrgsLoading } = useListAllOrganizationsQuery({
    nextToken: null,
    ...(debouncedSearch.length && { name: debouncedSearch }),
  });
  const myOrgs = useAppSelector(selectMyOrganizations);
  const allOrgs = data?.orgs ?? [];

  const [, { isLoading: myOrgsCreateLoading }] =
    useCreateOrganizationMutation();

  const [, { isLoading: myOrgsUpdateLoading }] =
    useUpdateOrganizationMutation();

  const [tabIndex, setTabIndex] = useState<OrgsTabType>(OrgsTabType.MYORGS);
  const [trigger] = useLazyListAllOrganizationsQuery();
  const handleShowMore = () => {
    trigger({
      ...(debouncedSearch.length && { name: debouncedSearch }),
      nextToken: data?.nextToken ?? null,
    });
  };

  const handleFilters = useMemo(
    () =>
      debounce((name: string) => {
        setDebouncedSearch(name);
      }, 200),
    [setDebouncedSearch],
  );

  const handleSearch = (val: string) => {
    setSearch(val);
    if (!myOrgsSelected && (val.length === 0 || val.length > 2)) {
      handleFilters(val);
    }
  };
  const resetSearch = () => {
    setSearch('');
  };

  const myOrgsSelected = tabIndex === OrgsTabType.MYORGS;
  const getSelectedOrgs = myOrgsSelected
    ? myOrgs?.filter((o) =>
        o.name.toLowerCase().includes(search.toLowerCase()),
      ) ?? []
    : allOrgs;
  return {
    handleFilters,
    search,
    tabIndex,
    myOrgsSelected,
    getSelectedOrgs,
    setTabIndex,
    handleSearch,
    resetSearch,
    myOrgsLoading,
    allOrgsLoading,
    myOrgsCreateLoading,
    myOrgsUpdateLoading,
    handleShowMore,
    nextToken: data?.nextToken,
  };
};
