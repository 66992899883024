import { cloneElement, forwardRef } from 'react';
import { IconBaseProps } from 'react-icons';
import { TranslateKey } from '@aclito/shared/types';
import { useTranslate } from '@aclito/shared/hooks';
import { useTheme } from 'styled-components';
import { ThemeColorsKeys } from '@aclito/shared/theme/colors';

import { iconRegistry, IconType } from '../Icons/Icon';
import { HStack } from '../Layout';

import { SmallButton, SmallButtonProps } from './SmallButton';

/* eslint-disable react/display-name */
interface ActionButtonsProps extends Omit<SmallButtonProps, 'bg'> {
  iconType?: IconType;
  primary?: boolean;
  tx?: TranslateKey;
  bg?: ThemeColorsKeys;
}
const ActionButton = forwardRef<HTMLButtonElement, ActionButtonsProps>(
  ({ iconType, primary, tx, bg, textcolor, ...rest }, ref) => {
    {
      const t = useTranslate();
      const icons: Record<IconType, React.ReactElement> = { ...iconRegistry };
      const theme = useTheme();

      return (
        <SmallButton
          // @ts-ignore
          ref={ref}
          bg={bg ? bg : primary ? 'primary' : 'primary10'}
          shape={tx ? undefined : 'round'}
          {...rest}
        >
          <HStack spacing={8}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {iconType &&
                icons[iconType] &&
                cloneElement<IconBaseProps>(icons[iconType], {
                  size: 24,
                  color: textcolor
                    ? theme[textcolor]
                    : !primary
                    ? theme.primary
                    : theme.whiteText,
                })}
            </div>
            {tx && (
              <div
                style={{
                  color: textcolor
                    ? theme[textcolor]
                    : !primary
                    ? theme.primary
                    : theme.whiteText,
                }}
              >
                {t(tx)}
              </div>
            )}
          </HStack>
        </SmallButton>
      );
    }
  },
);

export default ActionButton;
