import { Auth } from 'aws-amplify';
import { CognitoUser } from '@aws-amplify/auth';

import { ApiRequiredAttributes } from '../../types/authentication';
import { errorObject } from '../../utils/errorObject';
/**
 *
 * @param user Cognito User
 * @param password New password
 * @param attributes Required attributes
 * @returns A message if successful, appropriate error otherwise
 */
export const makeCompleteNewPassword = async (
  user: CognitoUser,
  password: string,
  attributes: ApiRequiredAttributes,
): Promise<boolean> => {
  try {
    await Auth.completeNewPassword(user, password, {
      name: attributes.name,
      family_name: attributes.surname,
      gender: attributes.gender,
    });
    return true;
  } catch (error) {
    //@ts-ignore
    return Promise.reject(errorObject[error.code]);
  }
};
