import {
  EmojisKey,
  MemberCardProps,
  MemberType,
  OnEditMemberFunction,
  OnRemoveMemberFunction,
  UserWithMembershipType,
} from '@aclito/shared/types';
import { useAppSelector } from '@aclito/shared/hooks';
import { profileSelectors } from '@aclito/shared/redux/slices/profileSlices';
import React, { useState } from 'react';
import { memberControls } from '@aclito/shared/util/memberControls';
import { canSeeNotes } from '@aclito/shared/features/event/utils/canSeeNotes';
import { Organization } from '@aclito/entities';
import { useEmojisByOrgQuery } from '@aclito/shared/redux/api/feedbackApi';
import { Divider } from '@mantine/core';
import { useTheme } from 'styled-components';
import { sortUsersByTopEmoji } from '@aclito/shared/util/sortByEmojis';
import { closeAllModals } from '@mantine/modals';
import { config } from '@aclito/shared/config';

import { useNavigate } from '@/hooks/useNavigate';
import {
  Avatar,
  HStack,
  Text,
  VStack,
  Icon,
  IconHolder,
  PlainCard,
} from '@/components';
import {
  ROUTE_NOTES_USER,
  ROUTE_PROFILE,
  ROUTE_USER_PROFILE,
} from '@/util/constants';
import RoleBanner from '@/features/organization/screens/OrganizationInfo/components/RoleBanner';
import Stats from '@/common/Profile/Stats';
import { useModal } from '@/hooks/useModal';

interface OrgMemberCardsProps {
  members: UserWithMembershipType[];
  withControls: boolean;
  myRole: MemberType;
  onRemoveMember?: OnRemoveMemberFunction;
  onEditMember?: OnEditMemberFunction;
  org: Organization;
}

const OrgMemberCards: React.FC<OrgMemberCardsProps> = ({
  members,
  onRemoveMember,
  onEditMember,
  withControls,
  myRole,
  org,
}) => {
  const userInfo = useAppSelector(profileSelectors.userInfo);
  const { data: emojiMap } = useEmojisByOrgQuery(org.id, {
    skip: !org.members.includes(userInfo.id),
  });

  const [emoji, setEmoji] = useState<EmojisKey | null>(null);
  const { openModal } = useModal();
  const theme = useTheme();

  const sortedMembers = emoji
    ? sortUsersByTopEmoji(members, emojiMap ?? {}, emoji)
    : members;

  const handleEmoji = async () => {
    openModal({
      type: 'feedbackEmoji',
      values: {
        emoji,
        onConfirm: async (e) => {
          closeAllModals();
          setEmoji(e);
        },
      },
    });
  };

  return (
    <>
      <HStack
        onClick={handleEmoji}
        style={{ alignSelf: 'end', alignItems: 'center', cursor: 'pointer' }}
        spacing={8}
      >
        <Text color={theme.primary} tx={'sortBy'} />
        {emoji ? (
          <Text
            style={{ fontSize: 18, width: 24, height: 24, lineHeight: 'unset' }}
            text={config.emojis[emoji].item}
          />
        ) : (
          <Icon color={'primary'} icon={'chevronDown'} />
        )}
      </HStack>
      {sortedMembers.map((member, index: number) => {
        const emojis = emojiMap?.[member.id];
        return (
          <Card
            key={member.id}
            {...{
              member,
              emojis,
              onEditMember,
              onRemoveMember,
              myId: userInfo.id,
              myRole,
              withControls,
              org,
              index,
            }}
            index={index}
          />
        );
      })}
    </>
  );
};

const Card: React.FC<MemberCardProps> = ({
  member,
  withControls,
  onEditMember,
  onRemoveMember,
  myRole,
  myId,
  org,
  index,
  emojis,
}) => {
  const nav = useNavigate();
  const theme = useTheme();
  const hasControl = memberControls(member, myRole, withControls, myId);

  const handleRemoveMember = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    event.stopPropagation();
    onRemoveMember?.(member.id);
  };

  const handleEditMember = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    event.stopPropagation();
    onEditMember?.(member.type, member.id);
  };

  const handleClickUserNotes = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    event.stopPropagation();
    nav(ROUTE_NOTES_USER, { state: { id: member.id, orgId: org.id } });
  };

  const navigate = () =>
    myId === member.id
      ? nav(ROUTE_PROFILE)
      : nav(ROUTE_USER_PROFILE, { state: { userId: member.id } });

  return (
    <PlainCard
      onClick={navigate}
      data-testid={`${member.name} ${member.surname}`}
    >
      <VStack spacing={10}>
        <HStack>
          <Avatar size={48} image={member.image} />
          <VStack spacing={8}>
            <Text weight={700}>{`${member.name} ${member.surname}`}</Text>
            <RoleBanner myRole={member.type} />
          </VStack>

          {hasControl && myRole === 'admin' && (
            <IconHolder style={{ cursor: 'pointer' }}>
              <>
                {canSeeNotes(myId, member.id, org) && (
                  <Icon
                    icon="note"
                    size="24"
                    color={'yellow'}
                    onClick={handleClickUserNotes}
                    data-testid={`notes-user-${index}`}
                  />
                )}
              </>
              <Icon
                onClick={handleRemoveMember}
                icon="personRemove"
                size={'24'}
                color="red"
                data-testid={`kick-user-${index}`}
              />
              <Icon
                color="primary"
                onClick={handleEditMember}
                icon="edit"
                size={'24'}
                data-testid={`edit-user-${index}`}
              />
            </IconHolder>
          )}
        </HStack>
        {!!Object.keys(emojis ?? {}).length && emojis && (
          <>
            <Divider color={theme.grey10} />
            <Stats compact slice={5} emojis={emojis} />
          </>
        )}
      </VStack>
    </PlainCard>
  );
};

export default OrgMemberCards;
