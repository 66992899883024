import React, { useState } from 'react';
import styled from 'styled-components';
import { useProfile } from '@aclito/shared/features/profile/hooks/useProfile';
import { useMediaQuery } from '@mantine/hooks';
import { deviceMax } from '@aclito/ui-web/utils/mediaQueries';
import { useNavigate } from 'react-router-dom';

import { MyProfileTabType } from './types';

import {
  SectionGrid,
  VStack,
  Text,
  TitleBar,
  ActionButton,
  Title,
  Tab,
  Tabs,
  SectionTitle,
} from '@/components';
import { ROUTE_GOALS, ROUTE_PROFILE_EDIT } from '@/util/constants';
import EventsCards from '@/components/Cards/EventsCards';
import AboutUserSection from '@/common/Profile/AboutUserSection';
import Reviews from '@/common/Profile/Reviews';
import { useFlexWrap } from '@/hooks/useFlexWrap';
import { AboutUserMain } from '@/common/Profile';
import Achievements from '@/features/goals/Achievements';

const Profile: React.FC = () => {
  const navigation = useNavigate();

  const { user, stats, pastEvents } = useProfile();
  const [tabIndex, setTabIndex] = useState(MyProfileTabType.INFO);
  const id = 'titleFlexContainer';
  const isFlexWrapped = useFlexWrap(id);
  const queryMobile = useMediaQuery(deviceMax.mobile);

  const nav = useNavigate();

  const handleNavigation = () => {
    navigation(ROUTE_PROFILE_EDIT);
  };

  const handleNavigationToGoals = (userId: string) => {
    nav(ROUTE_GOALS + `/${userId}`);
  };

  const handleTabChange = (val: string) =>
    val === '0'
      ? setTabIndex(MyProfileTabType.INFO)
      : setTabIndex(MyProfileTabType.HISTORY);

  return (
    <SectionGrid $isOpen={false}>
      <TitleBar>
        <Title tx="profile.about" />
        {isFlexWrapped || queryMobile ? (
          <ActionButton iconType={'edit'} />
        ) : (
          <ActionButton
            iconType={'edit'}
            onClick={handleNavigation}
            tx={'tooltip.edit'}
          />
        )}
      </TitleBar>

      {user && stats && (
        <AboutUserMain {...{ userInfo: user, stats, myProfile: true }} />
      )}

      <Tabs
        style={{ paddingBottom: 16 }}
        selectedIndex={tabIndex === MyProfileTabType.INFO ? '0' : '1'}
        setSelectedIndex={handleTabChange}
      >
        <Tab tx="tabs.info" />
        <Tab tx="tabs.history" />
      </Tabs>

      <>
        {tabIndex === MyProfileTabType.INFO && (
          <Container>
            {user && (
              <AboutUserSection
                {...{ userInfo: user, stats, myProfile: true }}
              />
            )}
            <SectionTitle
              onClick={() => handleNavigationToGoals(user.id)}
              buttonLabelTx="tabs.achievements"
            />
            <Achievements preview id={user.id} />
            <Reviews id={user.id} />
          </Container>
        )}

        {tabIndex === MyProfileTabType.HISTORY && (
          <Container>
            <MyActivities>
              {pastEvents.length ? (
                <EventsCards data={pastEvents} />
              ) : (
                <Text tx="flashlist.no.pastevents" size={16} />
              )}
            </MyActivities>
          </Container>
        )}
      </>
    </SectionGrid>
  );
};

const MyActivities = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  .body {
    display: grid;
    grid-template-columns: repeat(2, min-content);
    gap: 24px;
  }
`;

const Container = styled(VStack)`
  display: flex;
`;

export default Profile;
