import { Feed } from '@aclito/entities';
import { Menu } from '@mantine/core';
import React from 'react';
import styled, { useTheme } from 'styled-components';

import { useFeedDelete } from '../hooks/useFeedDelete';

import { Icon, ActionButton, HStack, Text } from '@/components';
import { ROUTE_FEED_EDIT } from '@/util/constants';
import { useNavigate } from '@/hooks/useNavigate';

interface FeedMenuOptionsProps {
  feed: Feed;
  compact?: boolean;
}

const FeedMenuOptions: React.FC<FeedMenuOptionsProps> = ({ feed, compact }) => {
  const nav = useNavigate();
  const theme = useTheme();
  const { handleDelete } = useFeedDelete(feed);

  return (
    <>
      {compact ? (
        <Menu>
          <Menu.Target>
            <StyledMoreButton>
              <Icon icon="more" color={'primary'} size={24} />
            </StyledMoreButton>
          </Menu.Target>
          <Menu.Dropdown
            style={{
              borderRadius: '10px',
              backgroundColor: theme.white,
              color: theme.grey80,
              border: 'none',
            }}
          >
            <Menu.Item
              onClick={() =>
                nav(ROUTE_FEED_EDIT, { state: { feedId: feed.id } })
              }
              icon={<Icon icon="edit" size={20} />}
            >
              <Text tx="json.edit" />
            </Menu.Item>
            <Menu.Item
              onClick={handleDelete}
              icon={<Icon icon="delete" size={20} />}
            >
              <Text tx="delete" />
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      ) : (
        <HStack>
          <ActionButton
            tx="json.edit"
            iconType="edit"
            onClick={() => nav(ROUTE_FEED_EDIT, { state: { feedId: feed.id } })}
          />
          <ActionButton
            tx="delete"
            bg={'red10'}
            textcolor={'red'}
            iconType="delete"
            onClick={handleDelete}
          />
        </HStack>
      )}
    </>
  );
};

const StyledMoreButton = styled.button`
  background: unset;
  border: unset;
  cursor: pointer;
  margin-top: 0.5rem;
`;

export default FeedMenuOptions;
