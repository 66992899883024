import { FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { CreateReportInput, Report } from '@aclito/entities';
import { reportClient } from '@aclito/client';
import axios from 'axios';

import { assertObjectExists } from '../../util/assertions';
import { aclitoApi } from '../store';

export const CREATE_REPORT = 'createReport';

export const reportApi = aclitoApi.injectEndpoints({
  endpoints: (builder) => ({
    [CREATE_REPORT]: builder.mutation<Report, CreateReportInput>({
      queryFn: async (input, _) => {
        try {
          const data = await reportClient.reports.createReport(input);

          assertObjectExists(data.data);
          return { data: data.data };
        } catch (error) {
          if (axios.isAxiosError(error)) {
            if (error.response?.status === 409) {
              return { error: { error: 'permission' } as FetchBaseQueryError };
            }
          }
          return { error: { error: 'fail' } as FetchBaseQueryError };
        }
      },
    }),
  }),
});

export const { useCreateReportMutation } = reportApi;
