import { EventWithDateObject } from '@aclito/shared/types';
import { cardColorPicker } from '@aclito/shared/util/cardColorPicker';
import { Indicator } from '@mantine/core';
import moment from 'moment';
import React from 'react';
import styled from 'styled-components';

import { HStack, VStack } from '@/components';

interface CalendarDayProps {
  date: Date;
  eventsByDay: EventWithDateObject[];
  selectedDay: Date | undefined;
  onDayClick: (date: Date) => void;
}

const CalendarDay: React.FC<CalendarDayProps> = ({
  date,
  selectedDay,
  eventsByDay,
  onDayClick,
}) => {
  const day = date.getDate();
  const handleClick = () => {
    onDayClick(date);
  };

  return (
    <SelectedCalendarDay
      isCurrent={moment().startOf('D').diff(moment(date)) === 0}
      isSelected={moment(selectedDay).diff(moment(date)) === 0}
      onClick={handleClick}
      spacing={6}
      align="center"
    >
      <div>{day}</div>
      <HStack spacing={8}>
        {eventsByDay.length > 0 &&
          (eventsByDay.length > 3 ? (
            <Indicator size={6} color="red" children={null} />
          ) : (
            eventsByDay.map((e) => (
              <Indicator
                key={e.id}
                size={6}
                color={cardColorPicker(e.activityType)}
                children={null}
              />
            ))
          ))}
      </HStack>
    </SelectedCalendarDay>
  );
};

const SelectedCalendarDay = styled(VStack)<{
  isSelected: boolean;
  isCurrent: boolean;
}>`
  background-color: ${({ isSelected, isCurrent, theme }) =>
    isSelected ? theme.primary : isCurrent ? theme.primary10 : 'transparent'};
  border-radius: 6px;
  padding: 10px;
  color: ${({ isSelected, theme }) => isSelected && theme.whiteText};
`;

export default CalendarDay;
