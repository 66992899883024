import dayjs, { Dayjs, ManipulateType } from 'dayjs';

import { SelectObject } from '../types';

interface GenerateDatesData {
  repeatAfter: SelectObject;
  numberToGenerate: string;
  date: string;
}

export const generateDates = (data: GenerateDatesData): string[] => {
  const startDate = dayjs(data.date);
  const minutes = dayjs(data.date).minute();
  const hours = dayjs(data.date).hour();

  const frequency: ManipulateType =
    data.repeatAfter.id === 'daily'
      ? 'day'
      : data.repeatAfter.id === 'weekly'
      ? 'week'
      : data.repeatAfter.id === 'monthly'
      ? 'month'
      : 'day';

  const arrayDates: Dayjs[] = [];

  for (let i = 0; i < Number(data.numberToGenerate); ++i) {
    arrayDates.push(
      dayjs(
        startDate
          .add(i === 0 ? 0 : i, frequency)
          .minute(minutes)
          .hour(hours),
      ),
    );
  }

  return arrayDates.map((generatedDate) =>
    generatedDate.set('seconds', 0).set('milliseconds', 0).toISOString(),
  );
};
