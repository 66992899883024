import React from 'react';
import { useController } from 'react-hook-form';

import { TextareaProps } from './types';
import Textarea from './Textarea';

const ValidatedTextArea: React.FC<TextareaProps & { name: string }> = ({
  name,
  ...rest
}) => {
  const {
    field: { value, onChange, onBlur },
    fieldState: { error },
  } = useController({ name });
  return (
    <Textarea
      value={value}
      onChange={(val) => onChange(val.target.value)}
      onBlur={onBlur}
      error={error?.message}
      autoComplete={'on'}
      variant="filled"
      size="md"
      autosize
      minRows={4}
      radius={10}
      {...rest}
    />
  );
};

export default ValidatedTextArea;
