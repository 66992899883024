import React, { useState } from 'react';
import { useMediaQuery } from '@mantine/hooks';
import { Space } from '@mantine/core';
import { deviceMax } from '@aclito/ui-web/utils/mediaQueries';
import { useDisplayEvent } from '@aclito/shared/redux/api/eventApi';
import { EventModel } from '@aclito/shared/classes/EventModel';

import { Buttons, JoinLeave } from './components';
import AliasModalConfirm from './components/AliasModalConfirm';
import AliasModalLeave from './components/AliasModalLeave';
import { useEventInfoLeave } from './hooks/useEventInfoLeave';
import InitialConfirmModal from './components/InitialConfirmModal';
import InfoTab from './components/InfoTab';
import Chat from './components/Chat/Chat';

import {
  SectionGrid,
  HStack,
  Tab,
  Tabs,
  TitleBar,
  VStack,
  PlayersMembersNumber,
  Title,
  BackButton,
} from '@/components';
import { StateEventInfo } from '@/util/types';
import withRouter from '@/navigation/withRouter';
import { TypedLocation } from '@/hooks/useLocation';
import { useEventInfoInitial } from '@/features/event/screens/EventInfo/hooks/useEventInfoInitial';
import {
  EventHeader,
  EventName,
} from '@/features/event/styles/eventInfoStyles';
import EventTags from '@/components/Cards/EventTags';
import { useFlexWrap } from '@/hooks/useFlexWrap';

type EventInfoProps = TypedLocation<StateEventInfo, { id: string }>;

const EventInfo: React.FC<EventInfoProps> = ({ state, query }) => {
  const id = query?.id ? query.id : state?.id ?? '';
  const { model } = useDisplayEvent(id);

  if (!model) return null;

  return <EventInfoComponent model={model} />;
};

const EventInfoComponent = ({ model }: { model: EventModel }) => {
  const {
    isOpenedLeaveModal,
    onCloseLeaveModal,
    handleAliasesLeave,
    onOpenLeaveModal,
  } = useEventInfoLeave(model);

  const {
    isOpenedConfirmModal,
    closeConfirmModal,
    confirmAliasesAttendance,
    onConfirmPress,
    onLeavePress,
    opened,
    close,
  } = useEventInfoInitial(model, onOpenLeaveModal);

  const [tabIndex, setTabIndex] = useState('0');

  const handleTabIndexChange = (index: string) => {
    setTabIndex(index);
  };

  const { event } = model;

  const idContainer = 'titleFlexContainer';
  const isFlexWrapped = useFlexWrap(idContainer, [event]);
  const queryMobile = useMediaQuery(deviceMax.mobile);

  return (
    <>
      {event && event.isAclitoEvent && (
        <SectionGrid $isOpen={false}>
          {event && (
            <>
              <InitialConfirmModal
                {...{ opened, close, onConfirmPress, onLeavePress }}
              />
              <AliasModalConfirm
                model={model}
                opened={isOpenedConfirmModal}
                onClose={closeConfirmModal}
                handleConfirmAliases={confirmAliasesAttendance}
              />
              <AliasModalLeave
                model={model}
                opened={isOpenedLeaveModal}
                onClose={onCloseLeaveModal}
                handleLeaveAliases={handleAliasesLeave}
              />
            </>
          )}
          <TitleBar id={idContainer}>
            <HStack>
              <BackButton />
              <Title tx="nav.eventInfo" />
            </HStack>

            {model.event && (
              <Buttons model={model} compact={isFlexWrapped || queryMobile} />
            )}
          </TitleBar>
          <EventHeader spacing={16}>
            <VStack spacing={8}>
              <HStack
                spacing={8}
                style={{
                  justifyContent: 'space-between',
                }}
              >
                <EventName>{event?.name}</EventName>
                <PlayersMembersNumber
                  textSize={20}
                  withIcon
                  event={model.event}
                />
              </HStack>
              {event && <EventTags fullText event={event} />}
            </VStack>
            {model.event && <JoinLeave model={model} />}
          </EventHeader>

          {model.canDisplayChat ? (
            <Tabs
              selectedIndex={tabIndex}
              setSelectedIndex={handleTabIndexChange}
            >
              <Tab tx="tabs.info" />
              <Tab tx="tabs.chat" />
            </Tabs>
          ) : (
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            <Tabs
              selectedIndex={tabIndex}
              setSelectedIndex={handleTabIndexChange}
            >
              <Tab tx="tabs.info" />
            </Tabs>
          )}
          <Space h={16} />
          {tabIndex === '0' ? <InfoTab model={model} /> : null}
          {tabIndex === '1' ? <Chat model={model} /> : null}
        </SectionGrid>
      )}
    </>
  );
};

export default withRouter(EventInfo);
