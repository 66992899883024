import moment from 'moment';

import { config } from '../config';
import { LocaleType } from '../types';
import { INITIAL_LOCATION } from '../util/constants';

import {
  EventFilters,
  FirstLoad,
  SearchOrganizationStateProps,
  AuthInitialStateProps,
  SearchEventsInitialStateProps,
  NotificationsInitialStateProps,
  UserInfoInitialStateProps,
  AvailabilityDates,
  EventDates,
  HasSearchedTypes,
  NoteInitialStateProps,
  MyNoteGrantsInitialStateProps,
  FeedFilters,
  LocationInitialStateProps,
  PlaceStateProps,
} from './types';
const { distance } = config;
export const InitialHasSearchedState = {
  search: false,
  orgs: false,
  nowOrNever: false,
  chats: false,
} as HasSearchedTypes;

export const FiltersInitialState = {
  activityType: { id: '', item: '' },
  from: moment().startOf('minutes').toISOString(),
  to: moment().add(3, 'months').startOf('day').toISOString(),
  gender: { id: '', item: '' },
  org: { id: '', item: '' },
  level: [{ id: '0', item: 'json.any' }],
  price: [0, 1000],
  distance: distance.eventsDefaultDistance,
  location: undefined,
  query: undefined,
  multisport: false,
  freeOnly: true,
  tags: { adults: false, all: true, kids: false },
  isDirty: false,
} as EventFilters;

export const LocaleInitialState: LocaleType = 'cs';
export const EventDatesInitialState = { date: [], endDate: [] } as EventDates;

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const UserInfoInitialState = {
  current: undefined,
  currentStats: undefined,
  orgId: undefined,
  isLoadingUpdateMyProfile: false,
  isLoadingDeleteAccount: false,
  isLoadingUpdateUserStats: false,
  isLoadingLocation: false,
  isLoadingSettings: false,
  isLoadingPopulate: false,
} as UserInfoInitialStateProps;

export const FirstLoadInitialState = {
  isLoading: false,
  canLoadAgain: true,
} as FirstLoad;

export const PlaceInitialState = {
  current: [],
  isLoading: false,
  error: undefined,
} as PlaceStateProps;

export const NotificationsInitialState = {
  current: {
    items: [],
  },
  error: undefined,
  isLoading: false,
} as NotificationsInitialStateProps;

export const SearchEventsInitialState = {
  current: {
    items: [],
    total: null,
    nextToken: null,
    aggregateItems: [],
  },
  error: undefined,
  isLoading: false,
} as SearchEventsInitialStateProps;

export const AvailabilityDatesInitialState = {
  date: [],
} as AvailabilityDates;
export const SearchOrganizationsInitialState = {
  filter: { name: '' },
} as SearchOrganizationStateProps;

export const FeedFiltersInitialState = {
  feedOrg: { id: '', item: '' },
} as FeedFilters;

export const AuthInitialState = {
  isLoadingSignIn: false,
  isLoadingRequestPassword: false,
  isLoadingSignUp: false,
  isLoadingSubmitPassword: false,
  isLoadingChangePassword: false,
  error: '',
} as AuthInitialStateProps;

const { latitudeDelta, longitudeDelta, ...initialLocation } = INITIAL_LOCATION;
export const LocationInitialState: LocationInitialStateProps = {
  locationService: 'unset',
  liveLocation: undefined,
  userLocation: undefined,
  searchMapLocation: undefined,
  placesMapLocation: undefined,
  defaultLocation: initialLocation,
  delta: {
    latitudeDelta,
    longitudeDelta,
  },
};

export const NoteInitialState: NoteInitialStateProps = {
  current: undefined,
  isLoading: false,
  error: undefined,
};

export const MyNoteGrantsInitialState: MyNoteGrantsInitialStateProps = {
  current: [],
  isLoading: false,
  error: undefined,
};
