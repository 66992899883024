import { Organization } from '@aclito/entities';

import { getOrgUserRole } from '@aclito/shared/util/getOrgUserRole';

export const canSeeNotes = (
  userInfoId: string,
  playerId: string,
  org?: Organization,
) => {
  const isCurrentUserAdmin = org
    ? getOrgUserRole(org, userInfoId) === 'admin'
    : false;

  return playerId !== userInfoId && isCurrentUserAdmin && org?.contact;
};
