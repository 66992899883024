import { useTheme } from 'styled-components';

import { VStack } from '../components/Layout';
import { Text } from '../components/Text';
const AuthLogo = () => {
  const theme = useTheme();

  return (
    <VStack
      spacing={0}
      style={{ justifyContent: 'center', alignItems: 'center' }}
    >
      <img
        style={{ width: 144, height: 144 }}
        src={new URL('../images/aclito.png', import.meta.url).href}
      />
      <Text
        style={{ fontFamily: 'Acme' }}
        weight={500}
        color={theme.orange}
        size={40}
      >
        ACLITO
      </Text>
    </VStack>
  );
};

export default AuthLogo;
