import { useSelectObject, useTranslate } from '@aclito/shared/hooks';
import { Divider, LoadingOverlay } from '@mantine/core';
import React, { useState } from 'react';
import styled from 'styled-components';
import { ProfileInfoForm } from '@aclito/shared/types';
import { config } from '@aclito/shared/config';

import {
  ValidatedMultiSelect,
  ValidatedSelect,
  ValidatedInput,
  SubmitButton,
  HStack,
  VStack,
  ValidatedTextArea,
  ValidatedDropzone,
  LabeledButton,
  Text,
  Checkbox,
  SmallButton,
} from '@/components';

interface ProfileEditFormProps {
  submit: (values: ProfileInfoForm) => void;
  isLoading: boolean;
  showTac: boolean;
  placeholderName?: string;
  placeholderSurname?: string;
  toggle: () => void;
}

const ProfileEditForm: React.FC<ProfileEditFormProps> = ({
  submit,
  isLoading,
  showTac = false,
  placeholderName,
  placeholderSurname,
  toggle,
}) => {
  const {
    ageOptions,
    translatedGender,
    translatedActivities,
    translatedLanguages,
  } = useSelectObject();

  const t = useTranslate();

  const [tac, setTac] = useState(false);
  const [tacVisible, setTacVisible] = useState(showTac);
  const [pp, setPP] = useState(false);
  const handleCheckboxChangeTac = () => {
    setTac((prev) => !prev);
  };
  const handleCheckboxChangePP = () => {
    setPP((prev) => !prev);
  };

  return (
    <Screen>
      <LoadingOverlay visible={isLoading} overlayBlur={2} />
      <FormWrapper>
        <ValidatedInput
          placeholder={placeholderName}
          txLabel="auth.userinfo.name"
          name="name"
        />
        <ValidatedInput
          placeholder={placeholderSurname}
          txLabel="auth.userinfo.surname"
          name="surname"
        />
        <ValidatedSelect
          txLabel="auth.userinfo.age"
          txPlaceholder="auth.userinfo.age"
          name="age"
          options={ageOptions}
        />
        <ValidatedSelect
          name="gender"
          options={translatedGender}
          txLabel="auth.userinfo.gender"
          txPlaceholder="auth.userinfo.gender"
        />
        <ValidatedMultiSelect
          name="activities"
          options={translatedActivities}
          txLabel="auth.userinfo.activities"
          txPlaceholder="auth.userinfo.activities"
        />
        <ValidatedMultiSelect
          name="languages"
          options={translatedLanguages}
          txLabel="auth.userinfo.languages"
          txPlaceholder="auth.userinfo.languages"
        />
        <ValidatedTextArea
          name="description"
          txLabel="auth.userinfo.description"
          txPlaceholder="auth.userinfo.description"
        />

        <HStack m={'auto'} style={{ width: '100%' }}>
          <ValidatedDropzone name="image" />
          <LabeledButton
            onClick={toggle}
            labelTx={'auth.userinfo.location'}
            buttonTx={'auth.userinfo.setLocation'}
          />
        </HStack>

        <Divider m={'sm'} />
        {!tacVisible && (
          <div>
            <SubmitButton
              fullWidth
              bg="primary"
              tx="continue"
              onSubmit={submit}
            />
          </div>
        )}

        {tacVisible && (
          <>
            <Text
              weight={'500'}
              italic
              align={'center'}
              tx={'auth.termsAndConditions'}
            />
            <VStack style={{ paddingTop: 16 }}>
              <VStack>
                <HStack style={{ flexWrap: 'nowrap' }}>
                  <Checkbox
                    onChange={handleCheckboxChangeTac}
                    checked={tac}
                    size={'md'}
                    required
                  />
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`${config.URL.aclitoTacURL}`}
                  >
                    {t('footer.terms')}
                  </a>
                </HStack>
                <HStack style={{ flexWrap: 'nowrap' }}>
                  <Checkbox
                    onChange={handleCheckboxChangePP}
                    checked={pp}
                    size={'md'}
                    required
                  />
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`${config.URL.aclitoPpURL}`}
                  >
                    {t('footer.privacy')}
                  </a>
                </HStack>
              </VStack>
            </VStack>
            <SmallButton
              fullWidth
              disabled={!pp || !tac}
              bg="primary"
              tx="termsAndConditions.agree"
              onClick={() => setTacVisible(false)}
            />
            <Divider m={'sm'} />
          </>
        )}
      </FormWrapper>
    </Screen>
  );
};

const FormWrapper = styled(VStack)`
  width: 100%;
`;

const Screen = styled.div`
  background-color: ${({ theme }) => theme.background};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;
export default ProfileEditForm;
