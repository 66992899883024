import React from 'react';
import { Divider, LoadingOverlay } from '@mantine/core';
import { useOrganizationSchema } from '@aclito/shared/features/organization/hooks/useOrganizationSchema';
import { useTranslate } from '@aclito/shared/hooks';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTheme } from 'styled-components';
import { StyledTitle } from '@aclito/ui-web/utils/styles';
import { sizeMax } from '@aclito/ui-web/utils/mediaQueries';

import { useOrgCreateEdit } from '../../hooks/useOrgCreateEdit';

import { useOrganizationEdit } from './hooks/useOrganizationEdit';

import {
  SectionGrid,
  ActionButton,
  Stepper,
  TitleBar,
  useStepper,
  VStack,
  ValidatedDropzone,
  StepperButtons,
} from '@/components';
import Description from '@/common/OrganizationForm/Description';
import { StateOrganizationEdit } from '@/util/types';
import { TypedLocation } from '@/hooks/useLocation';
import withRouter from '@/navigation/withRouter';

// type AlertErrorKeys = keyof Pick<OrganizationForm, 'name' | 'telephone'>;

type OrganizationEditProps = TypedLocation<StateOrganizationEdit>;
const OrganizationEdit: React.FC<OrganizationEditProps> = ({ state }) => {
  const org = state?.org;

  const { handleSubmit, initialValues, isLoading } = useOrganizationEdit(org);
  const { newOrganizationSchema } = useOrganizationSchema(org);

  const { active, setActive, nextStep, prevStep } = useStepper();
  const t = useTranslate();
  const steps = [
    {
      component: <Description />,
      label: t('org.info.description'),
    },
    {
      component: <ValidatedDropzone name="image" imageOnly />,
      label: t('org.info.avatar'),
    },
  ];

  const methods = useForm({
    defaultValues: initialValues,
    //@ts-ignore
    resolver: yupResolver(newOrganizationSchema),
  });

  const theme = useTheme();

  const { onError, navBack } = useOrgCreateEdit();

  return (
    <SectionGrid
      style={{ alignSelf: 'center', maxWidth: sizeMax.tablet }}
      $isOpen={false}
    >
      <TitleBar>
        <StyledTitle tx="org.title" />
        <ActionButton
          onClick={navBack}
          bg={'red10'}
          textcolor={'red'}
          iconType={'close'}
        />
      </TitleBar>

      <FormProvider {...methods}>
        <VStack>
          <LoadingOverlay visible={isLoading} />
          <Stepper
            {...{
              active,
              steps,
              setActive,
              nextStep,
              prevStep,
            }}
          />
          <Divider color={theme.grey10} />
          <StepperButtons
            {...{
              active,
              steps: steps.length,
              nextStep,
              onError,
              onSubmit: handleSubmit,
              prevStep,
              create: false,
            }}
          />
        </VStack>
      </FormProvider>
    </SectionGrid>
  );
};

export default withRouter(OrganizationEdit);
