import React from 'react';
import styled from 'styled-components';
import { Organization } from '@aclito/entities';

import { useOrganizationInfoHandlers } from '../hooks/useOrganizationInfoHandlers';

import { Icon, HStack, Text } from '@/components';

interface JoinOrganizationButtonProps {
  org: Organization;
  canControl: boolean;
  canShareOrg: boolean;
  canJoin: boolean;
}

const JoinOrganizationButton: React.FC<JoinOrganizationButtonProps> = ({
  org,
  canShareOrg,
  canControl,
  canJoin,
}) => {
  const { handleJoin, handleShare } = useOrganizationInfoHandlers(org);

  const handleButton = () => {
    const cb = canControl && canShareOrg ? shareOrg : undefined;
    handleJoin(cb);
  };

  const shareOrg = () => {
    handleShare(org.id, 'org', org.name);
  };

  return (
    <>
      {canJoin && (
        <StyledButton onClick={handleButton}>
          <HStack spacing={8}>
            <Icon icon={'forward'} color="whiteText"></Icon>
            <Text weight="500" tx={'org.join'} color="whiteText"></Text>
          </HStack>
        </StyledButton>
      )}
    </>
  );
};

const StyledButton = styled.button`
  display: flex;
  height: 2.5rem;
  padding: 0.75rem 1rem;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  border-radius: 10px;
  border: unset;
  background: ${({ theme }) => theme.primary};
  color: ${({ theme }) => theme.whiteText};
  &:hover {
    filter: brightness(95%);
  }
  cursor: pointer;
`;

export default JoinOrganizationButton;
