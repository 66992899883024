import moment from 'moment';
import { UserInfo, Event } from '@aclito/entities';

import { config } from '../../../config';
import { calculateDateDiff } from '../../../util/calculateDateDiff';
import { EventForm, SelectObject, txFn } from '../../../types';
import { getByIdTranslate } from '../../../util/selectObject';

import { translateDateFormat } from '@aclito/shared/util/translateDateFormat';

export const composeInitialEditValues = (
  t: txFn,
  userInfo: UserInfo,
  event: Event,
  userPool: UserInfo[],
): EventForm => {
  const place = event.place
    ? ({ ...event.place, item: event.place.name } as SelectObject)
    : undefined;

  const trainer = event.players.find((player) => player.isTrainer);
  const trainerInfo = userPool.find((user) => user.id === trainer?.id);

  return {
    name: event.name,
    date: event.date,
    duration: translateDateFormat(
      moment()
        .startOf('day')
        .add(calculateDateDiff(event.endDate, event.date), 'milliseconds')
        .toString(),
      'time.display',
    ),
    gender: getByIdTranslate(event.gender, config.gender.filter, t),
    info: event.info,
    level: getByIdTranslate(event.minLevel.toString(), config.levels, t),
    place,
    players: event.maxPlayers === -1 ? undefined : event.maxPlayers,
    price: event.price === 0 ? undefined : event.price,
    activity: getByIdTranslate(event.activityType, config.activities, t),
    multisport: event.canPayWithMultiSport,
    externalLink: event.externalLink ?? '',
    linkRequired: !!event.externalLink && event.externalLink.length > 0,
    forFree: event.price === 0,
    unlimited: event.maxPlayers === -1,
    org: event.org ? { item: event.org.name, id: event.org.id } : undefined,
    requireConfirm: event.requireConfirmation,
    hoursConfirm:
      event.requireConfirmation && event.confirmationDate
        ? moment(calculateDateDiff(event.date, event.confirmationDate))
            .utc()
            .hour()
            .toString()
        : undefined,
    dontParticipate: !event.players.find((player) => player.id === userInfo.id)
      ?.willParticipate,
    enableQueue: event.enableQueue,
    newPlace: '',
    numberOfEvents: undefined,
    repeatAfter: undefined,
    switchRepeating: false,
    tags: event.tags,
    trainer: trainerInfo
      ? ({
          id: trainerInfo.id,
          item: `${trainerInfo.name} ${trainerInfo.surname}`,
        } as SelectObject)
      : null,
  };
};
