import React from 'react';
import { AvailabilityActivities, SelectObject } from '@aclito/shared/types';
import { useAvailabilityEdit } from '@aclito/shared/features/availability/hooks/useAvailabilityEdit';

import {
  VStack,
  ValidatedMultiSelect,
  RadioGroup,
  RadioButton,
} from '@/components';

interface ActivitiesProps {
  initialActivities?: SelectObject[];
}

const Activities: React.FC<ActivitiesProps> = ({ initialActivities = [] }) => {
  const { handleRadioChange, radioValue, translatedActivities } =
    useAvailabilityEdit();

  const onRadioChange = (value: AvailabilityActivities) => {
    handleRadioChange(value, initialActivities);
  };

  return (
    <VStack>
      <RadioGroup value={radioValue} onChange={onRadioChange}>
        <VStack>
          <RadioButton
            value={AvailabilityActivities.ANY_ACTIVITY}
            txLabel="availability.new.anyactivity"
          />
          <RadioButton
            value={AvailabilityActivities.MY_ACTIVITIES}
            txLabel="availability.new.myactivities"
          />
          <RadioButton
            value={AvailabilityActivities.CHOOSE_ACTIVITY}
            txLabel="availability.new.chooseactivities"
          />
        </VStack>
      </RadioGroup>
      {radioValue === AvailabilityActivities.CHOOSE_ACTIVITY && (
        <ValidatedMultiSelect
          options={translatedActivities}
          name="activities"
          txLabel="availability.new.activities"
          txPlaceholder="availability.new.activities"
        />
      )}
    </VStack>
  );
};

export default Activities;
