import { useEffect } from 'react';
import { isAndroid, isMobile } from 'react-device-detect';
import { config } from '@aclito/shared/config';
import { useSearchParams } from 'react-router-dom';

const aclitoWebURL = `${window.location.protocol}//${window.location.host}`;

const Invite = () => {
  const [searchParams] = useSearchParams();
  const type = searchParams.get('type') === 'lite' ? 'lite' : 'full';

  const aclitoURL = isAndroid
    ? type === 'full'
      ? config.URL.googleStoreURL
      : config.URL.googleStoreLiteURL
    : type === 'full'
    ? config.URL.appleStoreURL
    : config.URL.appleStoreLiteURL;

  useEffect(() => {
    if (isMobile) {
      document.location = aclitoURL;
    } else {
      document.location = aclitoWebURL;
    }
  }, [aclitoURL]);

  return <div></div>;
};

export default Invite;
