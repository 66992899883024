import { EventTag, EventTags } from '../types/types';

export const generateTagMappings = (selectedTag: EventTag): EventTags => {
  const tagMappings: Record<EventTag, EventTags> = {
    [EventTag.ADULTS]: { adults: true, all: false, kids: false },
    [EventTag.KIDS]: { adults: false, all: false, kids: true },
    [EventTag.ALL]: { adults: false, all: true, kids: false },
  };

  return tagMappings[selectedTag];
};
