import { unwrapResult } from '@reduxjs/toolkit';
import type { UserAlias } from '@aclito/entities';

import { ImageValueShape } from '../../../types';

import { useAppSelector } from '@aclito/shared/hooks';
import { useAppDispatch } from '@aclito/shared/hooks/useDispatch';
import { profileActions } from '@aclito/shared/redux/slices';
import { profileSelectors } from '@aclito/shared/redux/slices/profileSlices';

export interface UserAliasForm extends Omit<UserAlias, 'id'> {
  file?: string;
}

export const useProfileAliasesEdit = (id: string) => {
  const dispatch = useAppDispatch();
  const userInfo = useAppSelector(profileSelectors.userInfo);
  const currentAlias = userInfo.aliases.find((alias) => alias.id === id)!;

  const updateSecondaryProfile = async (aliasData: UserAliasForm) => {
    aliasData.image = aliasData.image?.trim() ? aliasData.image : 'BASIC';
    if (!aliasData.file) {
      delete aliasData.file;
    }
    return unwrapResult(
      await dispatch(
        profileActions.updateUserAliasAsync({
          aliasData: {
            id,
            ...aliasData,
          },
        }),
      ),
    );
  };

  const initialValues: UserAliasForm = {
    name: currentAlias.name,
    surname: currentAlias.surname,
    image: currentAlias.image as ImageValueShape,
  };

  return {
    updateSecondaryProfile,
    initialValues,
  };
};
