import { OrganizationTag, OrganizationTags } from '../types/types';

export const generateTagMappings = (
  selectedTag: OrganizationTag,
): OrganizationTags => {
  const tagMappings: Record<OrganizationTag, OrganizationTags> = {
    [OrganizationTag.ORGANIZATION]: {
      organization: true,
      team: false,
      club: false,
    },
    [OrganizationTag.CLUB]: { organization: false, team: false, club: true },
    [OrganizationTag.TEAM]: { organization: false, team: true, club: false },
  };

  return tagMappings[selectedTag];
};
