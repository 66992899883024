import { FormProvider, useForm } from 'react-hook-form';
import { useAppSelector, useSchema, useTranslate } from '@aclito/shared/hooks';
import {
  authActions,
  authSelectors,
} from '@aclito/shared/redux/slices/authSlices';
import { useAppDispatch } from '@aclito/shared/hooks/useDispatch';
import z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button } from '@aclito/ui-web/auth';
import { useNavigate } from 'react-router-dom';
import { Loader } from '@mantine/core';
import { sizeMax } from '@aclito/ui-web/utils/mediaQueries';

import {
  BackButton,
  HStack,
  Icon,
  SectionGrid,
  Title,
  TitleBar,
  ValidatedPasswordInput,
  VStack,
} from '@/components';

const ChangePassword = () => {
  const isLoading = useAppSelector(authSelectors.isLoadingChangePassword);
  const navigation = useNavigate();
  const { passwordChangeSchema } = useSchema();
  const dispatch = useAppDispatch();

  type PasswordChange = z.infer<typeof passwordChangeSchema>;

  const submitNewPassword = async (values: PasswordChange) => {
    await dispatch(
      authActions.changePasswordAsync({
        new: values.newPassword,
        old: values.oldPassword,
      }),
    ).unwrap();
  };

  const handleSubmit = async (values: PasswordChange) => {
    await submitNewPassword(values);
    navigation(-1);
  };

  const { ...methods } = useForm<PasswordChange>({
    resolver: zodResolver(passwordChangeSchema),
    defaultValues: { newPassword: '', oldPassword: '' },
  });

  const t = useTranslate();

  return (
    <SectionGrid
      style={{ alignSelf: 'center', maxWidth: sizeMax.tablet }}
      $isOpen={false}
    >
      <TitleBar>
        <HStack>
          <BackButton />
          <Title tx="auth.changePassword" />
        </HStack>
      </TitleBar>
      <FormProvider {...methods}>
        <VStack>
          <ValidatedPasswordInput
            name="oldPassword"
            txLabel="auth.password"
            autoComplete={'current-password'}
            txPlaceholder="auth.password"
          />

          <ValidatedPasswordInput
            name="newPassword"
            txLabel="auth.new.password"
            autoComplete={'new-password'}
            txPlaceholder="auth.password"
          />
        </VStack>
        <Button onClick={() => methods.handleSubmit(handleSubmit)()}>
          {t('auth.confirm.button')}
          {!isLoading ? (
            <Icon icon="forward" size={24} color="whiteText" />
          ) : (
            <Loader size={24} color="white" />
          )}
        </Button>
      </FormProvider>
    </SectionGrid>
  );
};

export default ChangePassword;
