import { colors } from '@aclito/shared/theme/colors';
import { MantineThemeOverride } from '@mantine/core';

import { sizeMax } from './mediaQueries';

const convertPxToRem = (sizeInPx: number): string => {
  const oneRemInPx = 16;
  return `${sizeInPx / oneRemInPx}rem`;
};

export const WebTheme: MantineThemeOverride = {
  colors: {
    // @ts-ignore
    primary: [...Array<string>(10)].fill(colors.primary),
    dark: [
      colors.white,
      colors.grey50,
      colors.grey30,
      colors.grey50,
      colors.grey70,
      colors.grey70,
      colors.grey80,
      colors.grey80,
      colors.grey90,
      colors.grey90,
    ],
  },
  primaryColor: 'primary',
  breakpoints: {
    xs: convertPxToRem(sizeMax.mobile),
    sm: convertPxToRem(sizeMax.tablet),
    md: convertPxToRem(sizeMax.laptop),
    lg: convertPxToRem(sizeMax.desktop),
    xl: convertPxToRem(sizeMax.desktopL),
  },
};
