import { Organization } from '@aclito/entities';

export const getMyOrgsSelectOptions = (
  orgs: Organization[],
  userId: string,
) => [
  ...orgs
    .filter((o) =>
      [...new Set([...o.admins, ...o.powerUsers])].some(
        (member) => member === userId,
      ),
    )
    .map((org) => ({ ...org, item: org.name })),
];
