import { useTranslate } from '@aclito/shared/hooks';
import { Center, Loader } from '@mantine/core';
import React from 'react';
import { useReportUser } from '@aclito/shared/features/misc/hooks/useReportUser';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { sizeMax } from '@aclito/ui-web/utils/mediaQueries';

import { ReportUserProps } from './interface';

import {
  SectionGrid,
  VStack,
  SubmitButton,
  Text,
  ValidatedTextArea,
  Title,
} from '@/components';
import { useNavigate } from '@/hooks/useNavigate';
import withRouter from '@/navigation/withRouter';

const ReportUser: React.FC<ReportUserProps> = ({ state }) => {
  const userToReport = state?.userToReport;

  const nav = useNavigate();
  const t = useTranslate();

  const { reportUserValidationSchema, initialValues, handleReport, isLoading } =
    useReportUser(userToReport);

  const handleSubmit = async (data: typeof initialValues) => {
    try {
      if (await handleReport(data)) nav(-1);
    } catch (e) {
      /* empty */
    }
  };

  const methods = useForm<typeof initialValues>({
    defaultValues: initialValues,
    resolver: yupResolver(reportUserValidationSchema),
  });

  return (
    <SectionGrid
      style={{ alignSelf: 'center', maxWidth: sizeMax.tablet }}
      $isOpen={false}
    >
      {isLoading ? (
        <Center style={{ height: '100%' }}>
          <Loader />
        </Center>
      ) : (
        <VStack>
          <Title>{`${t('nav.report')} - ${userToReport?.name} ${
            userToReport?.surname
          }`}</Title>
          <FormProvider {...methods}>
            <VStack>
              <Text tx="report.title" />
              <ValidatedTextArea
                variant="default"
                name="message"
                txLabel="messages.message"
                txPlaceholder="messages.message"
              />
              <SubmitButton
                onSubmit={handleSubmit}
                tx={'submit.button'}
                bg="primary"
              />
            </VStack>
          </FormProvider>
        </VStack>
      )}
    </SectionGrid>
  );
};

export default withRouter(ReportUser);
