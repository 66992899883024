import styled from 'styled-components';

import { HStack } from '../components/Layout';

export const TitleBar = styled(HStack)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 24px;
`;
