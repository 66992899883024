import { useAppSelector } from '@aclito/shared/hooks/useSelector';
import { useSchema } from '@aclito/shared/hooks/useSchema';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import styled, { useTheme } from 'styled-components';
import {
  authActions,
  authSelectors,
} from '@aclito/shared/redux/slices/authSlices';
import { SignInForm } from '@aclito/shared/types';
import { useLogin } from '@aclito/shared/features/auth/hooks/useLogin';
import { ApiSignIn } from '@aclito/shared/api/types/authentication';
import { Divider } from '@mantine/core';
import { useSearchParams } from 'react-router-dom';
import { PRE_SIGN_UP_DUPLICATE_ERROR } from '@aclito/shared/util/constants';
import { commonSelectors } from '@aclito/shared/redux/slices/commonSlices';
import AuthLogo from '@aclito/ui-web/auth/AuthLogo';
import { useEffect } from 'react';
import { Social, Header, Screen, Box } from '@aclito/ui-web/auth';
import { useAppDispatch } from '@aclito/shared/hooks/useDispatch';
import { showTranslatedNotification } from '@aclito/ui-web/utils/showTranslatedNotification';

import Footer from '../../components/Footer/Footer';

import { LoginForm } from './components';

import { Container, VStack } from '@/components';
import WithAuth from '@/navigation/withAuth';
import { useNavigate } from '@/hooks/useNavigate';
import Link from '@/common/Text/Link';
import { ROUTE_SIGN_UP } from '@/util/constants';
import { submitOnKeyPress } from '@/util/submitOnKeyPress';

const Login = () => {
  const isLoading = useAppSelector(authSelectors.isLoadingSignIn);
  const navigate = useNavigate();
  const { login } = useLogin();
  const theme = useTheme();
  const { signInValidationSchema } = useSchema();
  const locale = useAppSelector(commonSelectors.locale);

  const handleSubmit = async (values: ApiSignIn) => {
    const res = await login(values);
    if (!res.payload) {
      navigate(0);
    }
  };

  const [searchParams] = useSearchParams();

  useEffect(() => {
    const error = searchParams.get('error_description');

    if (error?.includes(PRE_SIGN_UP_DUPLICATE_ERROR)) {
      showTranslatedNotification(locale, 'UsernameExistsException');
    }
  }, []);

  const methods = useForm<SignInForm>({
    defaultValues: { username: '', password: '' } as SignInForm,
    resolver: yupResolver(signInValidationSchema),
    mode: 'onChange',
  });

  const dispatch = useAppDispatch();

  return (
    <Box>
      <Header />
      <Container>
        <Screen>
          <VStack>
            <VStack spacing={16}>
              <AuthLogo />
              <FormProvider {...methods}>
                <div
                  onKeyDown={(e) =>
                    submitOnKeyPress(e, methods.handleSubmit(handleSubmit))
                  }
                >
                  <Social
                    onSocialClick={(type) => {
                      dispatch(authActions.federatedSignInAsync(type));
                    }}
                  />
                  <Divider
                    style={{ marginTop: 20, marginBottom: 20 }}
                    h={1}
                    bg={theme.grey10}
                  />
                  <LoginForm
                    isLoading={isLoading}
                    onSubmit={methods.handleSubmit(handleSubmit)}
                  />
                </div>
              </FormProvider>
              <NoAccountLink
                to={ROUTE_SIGN_UP}
                textProps={{ tx: 'auth.signin.noacc', color: 'primary' }}
              />
            </VStack>
          </VStack>
        </Screen>
      </Container>
      <Footer />
    </Box>
  );
};

const NoAccountLink = styled(Link)`
  padding: 0;
  margin-top: 16px;
  color: ${({ theme }) => theme.primary};
  display: flex;
  text-decoration: none;
  justify-content: center;
`;

export default WithAuth(Login);
