import { useState } from 'react';
import { Space } from '@mantine/core';
import { useTranslate } from '@aclito/shared/hooks';

import {
  SectionGrid,
  Tab,
  Tabs,
  Text,
  VStack,
  Title,
  TitleBar,
  ActionButton,
} from '@/components';
import NotesListing from '@/features/notes/screens/Notes/components/NotesListing';
import NoteGrantListing from '@/features/notes/screens/Notes/components/NoteGrantListing';
import { useNotes } from '@/features/notes/screens/Notes/hooks/useNotes';

const Notes = () => {
  const t = useTranslate();
  const [tabIndex, setTabIndex] = useState({
    index: '0',
  });

  const { handleClear } = useNotes();
  const handleTabIndexChange = (index: string) => {
    setTabIndex({
      index,
    });
  };

  return (
    <SectionGrid $isOpen={false}>
      <VStack>
        <TitleBar>
          <Title>{t('admin.notes.title')}</Title>
          <ActionButton
            tx={'admin.notes.remove'}
            bg={'red10'}
            textcolor={'red'}
            iconType={'delete'}
            onClick={handleClear}
          />
        </TitleBar>
        <Text tx="grant.modal.about" />
        <Tabs
          selectedIndex={tabIndex.index}
          setSelectedIndex={handleTabIndexChange}
        >
          <Tab tx="tabs.notes" />
          <Tab tx="tabs.permissions" />
        </Tabs>
      </VStack>
      <Space h={32} />
      <>
        {tabIndex.index === '0' && <NotesListing />}
        {tabIndex.index === '1' && <NoteGrantListing />}
      </>
    </SectionGrid>
  );
};

export default Notes;
