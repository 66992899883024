import { Auth } from 'aws-amplify';

import { ApiConfirmSignUp } from '../../types/authentication';
import { errorObject } from '../../utils/errorObject';
/**
 *
 * @param body Username and code
 * @returns A message ir appropriate error
 */
export const makeConfirmSignUp = async (
  body: ApiConfirmSignUp,
): Promise<boolean> => {
  try {
    await Auth.confirmSignUp(body.username, body.code);
    return true;
  } catch (error) {
    //@ts-ignore
    return Promise.reject(errorObject[error.code]);
  }
};
