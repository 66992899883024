import { config } from '../../../../config';

export type ABody = { picture: string; url: string };
export type As = Record<string, ABody>;
export interface StatusData {
  title?: string;
  status: 'online' | 'offline';
  text?: string;
  breakingChangesVersion: number;
  termsAndConditionsVersion: number;
  termsAndConditionsVersionEffectiveDate: string;
  as: As;
}

export const getStatus = async (): Promise<StatusData> => {
  // @ts-ignore
  const res = await fetch(config.URL.aclitoStatusURL, { cache: 'no-store' });
  const data = await res.json();
  return data as StatusData;
};
