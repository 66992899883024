import { useJoinEventMutation } from '../../../redux/api/eventApi';

import { EventModel } from '@aclito/shared/classes/EventModel';
import { LocaleType } from '@aclito/shared/types';
import { FlashMessagesKeys } from '@aclito/shared/util/flashMessage';
import { useAppSelector } from '@aclito/shared/hooks';
import { commonSelectors } from '@aclito/shared/redux/slices/commonSlices';

export const useEventInfoJoin = () => {
  const locale = useAppSelector(commonSelectors.locale);

  const [joinEventTrigger] = useJoinEventMutation();

  const joinEvent = async (model: EventModel) => {
    await joinEventTrigger({ id: model.event.id });
  };

  const joinRepeatingEvents = async (model: EventModel) => {
    await joinEventTrigger({
      id: model.event.id,
      repId: model.event.repetitionId ?? '',
    });
  };

  const genderValidation = (
    model: EventModel,
    genderValidationCallback: (
      model: EventModel,
      locale: LocaleType,
      type: FlashMessagesKeys,
    ) => void,
  ) => {
    const { event, userInfo } = model;
    const genderNotSet = userInfo.gender === 'unknown';

    if (event.gender !== 'any' && event.gender !== userInfo.gender) {
      genderValidationCallback(
        model,
        locale,
        genderNotSet ? 'joinEventUnknownGender' : 'joinEventInvalidGender',
      );

      return true;
    }

    return false;
  };

  const aliasJoinEvent = async (model: EventModel, selectedIds: string[]) => {
    await joinEventTrigger({ id: model.event.id, aliasIds: selectedIds });
  };

  const aliasJoinRepeatingEvent = async (
    model: EventModel,
    selectedIds: string[],
  ) => {
    await joinEventTrigger({
      id: model.event.id,
      repId: model.event.repetitionId ?? '',
      aliasIds: selectedIds,
    });
  };

  return {
    aliasJoinEvent,
    aliasJoinRepeatingEvent,
    joinEvent,
    joinRepeatingEvents,
    genderValidation,
  };
};
