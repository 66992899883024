import { useAuthenticator } from '@aws-amplify/ui-react';
import { FC, useEffect, useState } from 'react';

import { useNavigate } from '@/hooks/useNavigate';
import { ROUTE_EVENTS } from '@/util/constants';

const WithAuth = <T extends object>(Component: FC<T>): FC<T> => {
  const Auth: FC<T> = (props) => {
    const { route } = useAuthenticator((context) => [context.route]);
    const navigate = useNavigate();
    const [afterRun, setAfterRun] = useState(false);

    useEffect(() => {
      setAfterRun(true);
      if (route === 'authenticated') {
        navigate(ROUTE_EVENTS);
      }
    }, [navigate, route]);

    return afterRun ? <Component {...props} /> : null;
  };

  return Auth;
};

export default WithAuth;
