import { Organization } from '@aclito/entities';

import { MemberType } from '../types';

export const getOrgUserRole = (
  org: Organization,
  userID: string,
): MemberType => {
  if (org.admins?.some((memberId) => memberId === userID)) {
    return 'admin';
  }
  if (org.powerUsers?.some((memberId) => memberId === userID)) {
    return 'powerUser';
  }

  return 'member';
};
