import { AvailabilityForm, txFn } from '../../../types';

export const composeInitialCreateValues = (_t: txFn): AvailabilityForm => ({
  date: undefined,
  duration: null,
  activities: undefined,
  location: undefined,
  numberOfAvailabilities: undefined,
  repeating: false,
  repeatAfter: undefined,
  repetitionId: null,
});
