import {
  CreateAliasInput,
  UpdateUserInfoInput,
  UserInfo,
  UpdateAliasInput,
  EventRaw,
  Review,
  Stats,
} from '@aclito/entities';

import { ContentType, HttpClient } from '../httpClient';
export class UserClient extends HttpClient {
  user = {
    getUser: () =>
      this.request<{ user: UserInfo; stats: Stats }>({
        path: '/users',
        method: 'GET',
        type: ContentType.Json,
      }),

    getUserReviews: (id: string) =>
      this.request<Review[]>({
        path: `/users/review/${id}`,
        method: 'GET',
        type: ContentType.Json,
      }),

    getUserStats: (id: string) =>
      this.request<Stats>({
        path: `/users/stats/${id}`,
        method: 'GET',
        type: ContentType.Json,
      }),

    getUserProfile: (id: string) =>
      this.request<{ user: UserInfo; stats: Stats }>({
        path: `/users/profile/${id}`,
        method: 'GET',
        type: ContentType.Json,
      }),

    updateUser: (data: UpdateUserInfoInput) =>
      this.request<UserInfo>({
        path: '/users',
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
      }),

    initial: () =>
      this.request<UserInfo>({
        path: '/users/initial',
        method: 'POST',
        type: ContentType.Json,
      }),

    deleteAccount: () =>
      this.request<UserInfo>({
        path: '/users/account',
        method: 'DELETE',
        type: ContentType.Json,
      }),
  };
  alias = {
    deleteAlias: (id: string) =>
      this.request<UserInfo | EventRaw[]>({
        path: `/users/alias/${id}`,
        method: 'DELETE',
        type: ContentType.Json,
      }),

    createAlias: (body: CreateAliasInput) =>
      this.request<UserInfo>({
        path: `/users/alias`,
        method: 'POST',
        body,
        type: ContentType.Json,
      }),

    updateAlias: (body: UpdateAliasInput) =>
      this.request<UserInfo>({
        path: `/users/alias`,
        method: 'PATCH',
        body,
        type: ContentType.Json,
      }),
  };
}
