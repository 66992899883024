import { Button as MTButton, CopyButton, Divider } from '@mantine/core';
import styled, { useTheme } from 'styled-components';
import { useTranslate } from '@aclito/shared/hooks';
import {
  EmojisKey,
  EventWithDateObject,
  EventWithPlayers,
  FormattedEventWithPlayers,
  LeaveForm,
  MemberType,
  UserInfoWithAlias,
} from '@aclito/shared/types';
import { FormProvider, useForm, UseFormReturn } from 'react-hook-form';
import { EventRaw, Organization } from '@aclito/entities';
import { closeAllModals } from '@mantine/modals';
import { config } from '@aclito/shared/config';

import {
  Icon,
  ActionButton,
  Button,
  HStack,
  Input,
  Text,
  VStack,
  ValidatedTextArea,
  Title,
} from '@/components';
import EventModal from '@/common/Modals/EventModal';
import EditModal from '@/features/organization/components/EditModal';
import { KickForm } from '@/features/event/components/KickForm';
import ShareModal from '@/common/InviteLayouts/ShareModal';
import LoadedEventModal from '@/common/Modals/LoadedEventModal';
import { GrantModal } from '@/common/Modals/GrantModal';
import { ROUTE_EVENT_INFO } from '@/util/constants';
import { useNavigate } from '@/hooks/useNavigate';

type VoidFn = () => void;

type EmbedCalBody = { url: string };
type EmbedCalModalBody = {
  event: EventWithDateObject;
  navigation: (id: string) => void;
};
type ShareUrlBody = { msg: string };
type CalModalBody = {
  id: string;
  navigation: (id: string) => void;
  handleRedirectToOrg: (event: FormattedEventWithPlayers) => void;
  handleRedirectToLeader: (event: FormattedEventWithPlayers) => void;
  handleRedirectToMap: (event: FormattedEventWithPlayers) => void;
};
type UpdateEventBody = { onUpdate: VoidFn; onUpdateRepeating: VoidFn };
type NewPlaceBody = void;
type DeleteAvailabilityBody = void;
type DeleteAccountBody = void;
type DeleteAliasBody = void;
type DeleteFeedBody = void;
type RemoveMemberBody = void;
type GrantNotesBody = { handleGrant: () => Promise<void>; orgName?: string };
type EditMemberBody = {
  id: string;
  myRole: MemberType;
  type: MemberType;
  org: Organization;
};
type DeleteOrgPermissionBody = void;
type DeleteOrgConfirmBody = void;
type LeaveOrgBody = { methods: UseFormReturn<LeaveForm, any> };
type LeaveEventBody = { methods: UseFormReturn<LeaveForm, any> };
type LeaveEventConfirmBody = { onConfirm: VoidFn };
type EventDeleteBody = void;
type EventDeleteConfirmBody = { onConfirm: VoidFn };
type EventJoinRepeatingBody = { onConfirm: VoidFn };
type KickPlayerBody = { event: EventWithPlayers; user: UserInfoWithAlias };
type PlayerAttendanceBody = { onConfirm: VoidFn; onLeave: VoidFn };
type WebShareBody = {
  msg: string;
  print?: boolean;
  org?: Organization;
};
type CreatePlaceBody = { onCreate: VoidFn; onBack: VoidFn };
type AliasEventsBody = { events: EventRaw[] };
type KarmaFeedbackBody = {
  karmaProvided: boolean;
  onConfirm: VoidFn;
};
type CommentFeedbackBody = {
  text: string | undefined;
  onConfirm: (text: string | null) => void;
};
type EmojiFeedbackBody = {
  emoji: EmojisKey | null;
  onConfirm: (emoji: EmojisKey | null) => void;
};
type FinishFeedbackBody = void;

export type ModalBodiesProps = {
  feedbackKarma: KarmaFeedbackBody;
  feedbackComment: CommentFeedbackBody;
  feedbackEmoji: EmojiFeedbackBody;
  feedbackFinish: FinishFeedbackBody;
  embedCal: EmbedCalBody;
  aliasEvents: AliasEventsBody;
  embedCalModal: EmbedCalModalBody;
  calModal: CalModalBody;
  shareUrl: ShareUrlBody;
  updateEvent: UpdateEventBody;
  newPlace: NewPlaceBody;
  deleteAvailability: DeleteAvailabilityBody;
  deleteAccount: DeleteAccountBody;
  deleteAlias: DeleteAliasBody;
  deleteFeed: DeleteFeedBody;
  removeMember: RemoveMemberBody;
  editMember: EditMemberBody;
  deleteOrgPermission: DeleteOrgPermissionBody;
  deleteOrgConfirm: DeleteOrgConfirmBody;
  leaveOrg: LeaveOrgBody;
  eventDelete: EventDeleteBody;
  eventDeleteConfirm: EventDeleteConfirmBody;
  leaveEvent: LeaveEventBody;
  leaveEventConfirm: LeaveEventConfirmBody;
  eventRepeatingJoin: EventJoinRepeatingBody;
  kickPlayer: KickPlayerBody;
  playerAttendance: PlayerAttendanceBody;
  webShare: WebShareBody;
  grantNotes: GrantNotesBody;
  createPlace: CreatePlaceBody;
};

export type ModalBodyTypes = keyof ModalBodiesProps;

export const modalBodies = <T extends ModalBodyTypes>(
  type: T,
  values: ModalBodiesProps[T] | undefined,
) => {
  const modalRecord: Record<ModalBodyTypes, (props: any) => JSX.Element> = {
    feedbackKarma: (props: KarmaFeedbackBody) => <FeedbackKarma {...props} />,
    feedbackComment: (props: CommentFeedbackBody) => (
      <FeedbackComment {...props} />
    ),
    feedbackEmoji: (props: EmojiFeedbackBody) => <FeedbackEmoji {...props} />,
    feedbackFinish: () => <FeedbackFinish />,
    aliasEvents: (props: AliasEventsBody) => <AliasEvents {...props} />,
    embedCal: (props: EmbedCalBody) => <EmbedCalModal url={props.url} />,
    embedCalModal: (props: EmbedCalModalBody) => <EmbeddedModal {...props} />,
    calModal: (props: CalModalBody) => <CalModal {...props} />,
    shareUrl: (props: ShareUrlBody) => <ShareUrlModal msg={props.msg} />,
    updateEvent: (props: UpdateEventBody) => <UpdateEvent {...props} />,
    newPlace: () => <NewPlace />,
    deleteAvailability: () => <DeleteAvailability />,
    deleteAccount: () => <DeleteAccount />,
    deleteAlias: () => <DeleteAlias />,
    deleteFeed: () => <DeleteFeed />,
    removeMember: () => <RemoveMember />,
    editMember: (props: EditMemberBody) => <EditMember {...props} />,
    deleteOrgConfirm: () => <DeleteOrgConfirm />,
    deleteOrgPermission: () => <DeleteOrgPermission />,
    leaveOrg: (props: LeaveOrgBody) => <LeaveOrg methods={props.methods} />,
    eventDelete: () => <EventDelete />,
    eventDeleteConfirm: (props: EventDeleteConfirmBody) => (
      <EventDeleteConfirm onConfirm={props.onConfirm} />
    ),
    leaveEvent: (props: LeaveEventBody) => (
      <LeaveEvent methods={props.methods} />
    ),
    leaveEventConfirm: (props: LeaveEventConfirmBody) => (
      <LeaveEventConfirm onConfirm={props.onConfirm} />
    ),
    eventRepeatingJoin: (props: EventJoinRepeatingBody) => (
      <EventRepeatingJoin onConfirm={props.onConfirm} />
    ),
    kickPlayer: (props: KickPlayerBody) => <KickPlayer {...props} />,
    playerAttendance: (props: PlayerAttendanceBody) => (
      <PlayerAttendance {...props} />
    ),
    webShare: (props: WebShareBody) => <WebShare {...props} />,
    grantNotes: (props: GrantNotesBody) => (
      <GrantNotes handleGrant={props.handleGrant} orgName={props.orgName} />
    ),
    createPlace: (props: CreatePlaceBody) => <CreatePlace {...props} />,
  };

  return modalRecord[type](values);
};

const FeedbackEmoji = (props: EmojiFeedbackBody) => {
  const theme = useTheme();
  return (
    <GridContainer>
      {Object.keys(config.emojis).map((item) => (
        <GridItem
          style={{
            backgroundColor:
              props.emoji === item ? theme.primary10 : 'transparent',
          }}
          onClick={() =>
            props.onConfirm(item === props.emoji ? null : (item as EmojisKey))
          }
          key={item}
        >
          {config.emojis[item as EmojisKey].item}
        </GridItem>
      ))}
    </GridContainer>
  );
};

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 10px;
  padding: 20px;
`;

const GridItem = styled.div`
  padding: 8px;
  width: 48px;
  height: 48px;
  transition: transform 150ms ease-in-out;
  cursor: pointer;
  &:hover {
    transform: scale(1.3);
  }
  text-align: center;
  font-size: 18px;
  border-radius: 8px;
`;

const FeedbackKarma = (props: KarmaFeedbackBody) => {
  return (
    <VStack spacing={16}>
      <Text
        tx={
          props.karmaProvided
            ? 'feedback.karma.undo.title'
            : 'feedback.karma.title'
        }
      />
      <ActionButton onClick={props.onConfirm} tx={'yes'} />
    </VStack>
  );
};
const FeedbackComment = (props: CommentFeedbackBody) => {
  const methods = useForm<{ review: string }>({
    defaultValues: { review: props.text ?? '' },
  });

  return (
    <VStack spacing={16}>
      <FormProvider {...methods}>
        <ValidatedTextArea
          txPlaceholder="feedback.review"
          name="review"
          maxLength={80}
        />
      </FormProvider>
      <ActionButton
        onClick={methods.handleSubmit((values) =>
          props.onConfirm(values.review.length ? values.review : null),
        )}
        tx={'submit.button'}
        primary
      />
    </VStack>
  );
};
const FeedbackFinish = () => {
  return <Text tx="feedback.finish" size="sm" />;
};

const AliasEvents = (props: AliasEventsBody) => {
  const nav = useNavigate();
  const theme = useTheme();
  return (
    <VStack
      spacing={16}
      style={{ minWidth: 400, marginTop: 4, maxHeight: 600 }}
    >
      <Text
        style={{ marginBottom: 8 }}
        size={'lg'}
        color={theme.red}
        tx={'alias.leave.title'}
      />
      {props.events.map((item, index) => (
        <>
          <VStack spacing={1}>
            <HStack
              style={{
                justifyContent: 'space-between',
              }}
            >
              <HStack spacing={8}>
                <Icon icon={'calendar'} />
                <Text text={item.name} />
              </HStack>
              <ActionButton
                iconType={'rightArrow'}
                onClick={() => {
                  nav(ROUTE_EVENT_INFO, { state: { id: item.id } });
                  closeAllModals();
                }}
              />
            </HStack>
          </VStack>
          {index < props.events.length - 1 && (
            <Divider style={{ marginBottom: 8, marginTop: 8 }} />
          )}
        </>
      ))}
    </VStack>
  );
};

const CreatePlace = (props: CreatePlaceBody) => {
  const theme = useTheme();

  return (
    <VStack align="center" justify={'center'}>
      <Text
        align="center"
        size={18}
        weight={500}
        color={theme.primary}
        tx="events.new.place.create.alert.header"
      />
      <Text align="center" size={16} tx="events.new.place.create.alert.body" />
      <HStack
        style={{
          justifyContent: 'center',
        }}
      >
        <ActionButton miw={140} w={'auto'} tx="back" onClick={props.onBack} />
        <ActionButton
          miw={140}
          w={'auto'}
          primary
          onClick={props.onCreate}
          tx="create"
        />
      </HStack>
    </VStack>
  );
};

const PlayerAttendance = (props: PlayerAttendanceBody) => {
  return (
    <VStack justify={'center'}>
      <Text tx="events.info.attendance.confirm.text" />
      <HStack spacing={15} style={{ justifyContent: 'space-between' }}>
        <Button
          w={195}
          color={'yellow'}
          onClick={props.onConfirm}
          tx="events.info.attendance.confirm.button"
        />
        <Button w={195} color={'gray'} onClick={props.onLeave} tx="leave" />
      </HStack>
    </VStack>
  );
};

const KickPlayer = ({ event, user }: KickPlayerBody) => {
  return (
    <VStack spacing={10}>
      <Title tx="events.info.alert.body" />
      <KickForm event={event} user={user} />
    </VStack>
  );
};

const EventRepeatingJoin = (props: EventJoinRepeatingBody) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
        marginBottom: 10,
      }}
    >
      <Button
        w={'auto'}
        onClick={props.onConfirm}
        color={'red'}
        tx="join.all"
      />
    </div>
  );
};

const LeaveEventConfirm = (props: LeaveEventConfirmBody) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
        marginBottom: 10,
      }}
    >
      <Button
        w={'auto'}
        onClick={props.onConfirm}
        color={'red'}
        tx="leave.all"
      />
    </div>
  );
};

const LeaveEvent = ({ methods }: LeaveEventBody) => {
  return (
    <>
      <Text tx="events.info.alert.body" />
      <FormProvider {...methods}>
        <ValidatedTextArea
          txPlaceholder="alert.message"
          name="area"
          maxLength={80}
        />
      </FormProvider>
    </>
  );
};

const LeaveOrg = ({ methods }: LeaveOrgBody) => {
  return (
    <>
      <Text tx="org.info.leave.body" />
      <FormProvider {...methods}>
        <ValidatedTextArea
          txPlaceholder="alert.message.org"
          name="area"
          maxLength={80}
        />
      </FormProvider>
    </>
  );
};

const EventDeleteConfirm = (props: EventDeleteConfirmBody) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
        marginBottom: 10,
      }}
    >
      <Button
        w={'auto'}
        onClick={props.onConfirm}
        color={'red'}
        tx="delete.all"
      />
    </div>
  );
};

const EventDelete = () => {
  return <Text tx="events.info.alert.body" size="sm" />;
};

const RemoveMember = () => {
  return <Text tx="org.kick.member" />;
};

const EditMember = (props: EditMemberBody) => {
  return <EditModal {...props} />;
};

const UpdateEvent = ({ onUpdateRepeating, onUpdate }: UpdateEventBody) => {
  return (
    <HStack>
      <Button miw={220} color={'gray'} onClick={onUpdate} tx="update.one" />
      <Button
        miw={220}
        color={'gray'}
        onClick={onUpdateRepeating}
        tx="update.all"
      />
    </HStack>
  );
};

const DeleteAlias = () => {
  return <Text tx={'profile.alert.body'} />;
};
const DeleteFeed = () => {
  return <Text tx="org.info.delete.body" />;
};

const DeleteOrgPermission = () => {
  return <Text tx="org.info.delete.permissions.body" />;
};

const DeleteOrgConfirm = () => {
  return <Text tx="org.info.delete.body" />;
};

const DeleteAccount = () => {
  return <Text tx={'profile.deleteaccount.alert.body'} />;
};

const NewPlace = () => {
  return <Text align="center" tx="events.new.place.alert.body" />;
};

const DeleteAvailability = () => {
  return <Text tx="availability.info.alert.body" size="sm" />;
};

const CalModal = ({
  id,
  navigation,
  handleRedirectToLeader,
  handleRedirectToOrg,
  handleRedirectToMap,
}: CalModalBody) => {
  return (
    <EventModal
      id={id}
      navigation={navigation}
      handleRedirectToLeader={handleRedirectToLeader}
      handleRedirectToOrg={handleRedirectToOrg}
      handleRedirectToMap={handleRedirectToMap}
    />
  );
};

const EmbeddedModal = ({ event, navigation }: EmbedCalModalBody) => {
  return <LoadedEventModal event={event} navigation={navigation} />;
};

const EmbedCalModal = ({ url }: EmbedCalBody) => {
  const t = useTranslate();
  return (
    <VStack spacing={10}>
      <Input
        styles={{ input: { backgroundColor: 'whitesmoke' } }}
        value={url}
      />
      <AboutModal>
        <Text tx="calendar.share" />
      </AboutModal>
      <CopyButton value={url}>
        {({ copied, copy }) => (
          <MTButton onClick={copy}>
            <HStack>
              {copied ? t('modal.copied') : t('modal.copy')}
              {copied ? (
                <Icon icon="checkmark" color="whiteText" />
              ) : (
                <Icon icon="copy" color="whiteText" />
              )}
            </HStack>
          </MTButton>
        )}
      </CopyButton>
    </VStack>
  );
};

const WebShare = ({ msg, print, org }: WebShareBody) => {
  return <ShareModal msg={msg} print={print} org={org} />;
};

const GrantNotes = ({ handleGrant, orgName }: GrantNotesBody) => {
  return <GrantModal handleGrant={handleGrant} orgName={orgName} />;
};

const ShareUrlModal = ({ msg }: ShareUrlBody) => {
  const theme = useTheme();
  const t = useTranslate();
  return (
    <VStack spacing={10}>
      <Input value={msg} />
      <CopyButton value={msg}>
        {({ copied, copy }) => (
          <MTButton color={copied ? theme.copied : theme.copy} onClick={copy}>
            {copied ? t('modal.copied') : t('modal.copy')}
          </MTButton>
        )}
      </CopyButton>
    </VStack>
  );
};

const AboutModal = styled.div`
  padding: 1rem 0;
  text-align: center;
`;
