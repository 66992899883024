import { useEffect, useState } from 'react';
import { TranslateKey } from '@aclito/shared/types';
import { reservantoClient } from '@aclito/client';

export const useReservanto = (
  token: string | undefined,
  accepted: number | undefined,
) => {
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState<TranslateKey>();
  const saveToken = async () => {
    if (token) {
      setIsLoading(true);
      try {
        const res = await reservantoClient.reservanto.createReservantoToken(
          token,
        );
        if (res.status === 400) {
          setMessage('reservanto.invalidtoken');
        }
        if (res.status === 201) {
          setMessage('reservanto.newtoken');
        }
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (token && accepted) {
      saveToken();
    }
  }, [token, accepted]);

  return { isLoading, message };
};
