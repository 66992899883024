import { createContext, ReactNode, useEffect, useState } from 'react';
import { ThemeProvider as ThemeProviderSC } from 'styled-components';
import {
  activityColors,
  internalPaletteDark,
  internalPaletteLight,
  themeColors,
} from '@aclito/shared/theme/colors';
import { IStorage } from '@aclito/shared/types';

type ThemeProviderProps = {
  children: ReactNode;
  defaultTheme?: Theme;
  withStorage?: boolean;
  Storage: IStorage;
};
export type Theme = 'light' | 'dark';
export const ThemeContext = createContext<{
  theme: Theme;
  toggleTheme: () => void;
}>({
  theme: 'light',
  toggleTheme: () => {
    return;
  },
});

const ThemeProvider = ({
  children,
  defaultTheme,
  withStorage,
  Storage,
}: ThemeProviderProps) => {
  const [theme, setTheme] = useState<Theme>(defaultTheme ?? 'light');

  const colors = {
    activitiesPalette: activityColors,
    transparent: 'rgba(0, 0, 0, 0)',
  };

  const setMode = async (mode: Theme) => {
    withStorage && (await Storage.setItem('theme', mode));
    setTheme(mode);
  };

  const toggleTheme = () => {
    theme === 'light' ? setMode('dark') : setMode('light');
  };

  const getTheme = async () => {
    const localTheme = (await Storage.getItem('theme')) as Theme;
    localTheme && setTheme(localTheme);
  };

  useEffect(() => {
    withStorage && getTheme();
  }, []);

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      <ThemeProviderSC
        theme={
          theme === 'light'
            ? { ...colors, ...themeColors(internalPaletteLight) }
            : { ...colors, ...themeColors(internalPaletteDark) }
        }
      >
        <AvatarsMask />
        {children}
      </ThemeProviderSC>
    </ThemeContext.Provider>
  );
};
const AvatarsMask = () => (
  <svg width="0" height="0" style={{ float: 'left' }}>
    <mask id="avatarsRadiusMask">
      <rect
        width="20"
        height="20"
        rx="10"
        ry="10"
        x="0"
        y="0"
        fill="#fff"
      ></rect>
    </mask>
  </svg>
);
export default ThemeProvider;
