import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth/lib/types';
import { createAsyncThunk } from '@reduxjs/toolkit';

import api from '../../api/api';
import {
  ApiChangePassword,
  ApiForgotPassword,
  ApiSignIn,
  ApiSignUp,
} from '../../api/types/authentication';
import { Logger } from '../../util/logger';
import { ThunkAuthType } from '../types';
import { flushState } from '../utils/flushState';
import { ApiAuthErrors } from '../../api/types/types';
import { currentUser } from '../../util/helpersApi';

export const REDUX_AUTH_SIGN_IN = 'signIn';
export const REDUX_AUTH_SIGN_UP = 'signUp';
export const REDUX_AUTH_RQ_NEW_PSWD = 'requestNewPassword';
export const REDUX_AUTH_SUBMIT_NEW_PSWD = 'submitNewPassword';
export const REDUX_AUTH_CHANGE_PASSWORD = 'changePassword';
export const REDUX_AUTH_SIGN_OUT = 'signOut';
export const REDUX_AUTH_FEDERATED = 'federatedSignIn';

export const signInAsync = createAsyncThunk<void, ApiSignIn, ThunkAuthType>(
  REDUX_AUTH_SIGN_IN,
  async (data, thunkAPI) => {
    try {
      await api.authentication.signIn(data);
    } catch (error) {
      return thunkAPI.rejectWithValue({ type: error as ApiAuthErrors });
    }
  },
);

export const singUpAsync = createAsyncThunk<void, ApiSignUp, ThunkAuthType>(
  REDUX_AUTH_SIGN_UP,
  async (data, thunkAPI) => {
    try {
      await api.authentication.signUp(data);
    } catch (error) {
      return thunkAPI.rejectWithValue({ type: error as ApiAuthErrors });
    }
  },
);

export const requestNewPasswordAsync = createAsyncThunk<
  void,
  string,
  ThunkAuthType
>(REDUX_AUTH_RQ_NEW_PSWD, async (data, thunkAPI) => {
  try {
    await api.authentication.resetPasswordRequest(data);
  } catch (error) {
    return thunkAPI.rejectWithValue({ type: error as ApiAuthErrors });
  }
});

export const submitNewPasswordAsync = createAsyncThunk<
  void,
  ApiForgotPassword,
  ThunkAuthType
>(REDUX_AUTH_SUBMIT_NEW_PSWD, async (data, thunkAPI) => {
  try {
    await api.authentication.resetPasswordSubmit(data);
  } catch (error) {
    return thunkAPI.rejectWithValue({ type: error as ApiAuthErrors });
  }
});

export const changePasswordAsync = createAsyncThunk<
  void,
  Omit<ApiChangePassword, 'user'>,
  ThunkAuthType
>(REDUX_AUTH_CHANGE_PASSWORD, async (data, thunkAPI) => {
  try {
    const user = await currentUser();
    await api.authentication.changePassword({ user, ...data });
  } catch (error) {
    return thunkAPI.rejectWithValue({ type: error as ApiAuthErrors });
  }
});

export const signOutAsync = createAsyncThunk<void, void, ThunkAuthType>(
  REDUX_AUTH_SIGN_OUT,
  async (_, thunkAPI) => {
    try {
      Logger.debug('Flushing');
      await api.authentication.signOut();

      flushState(thunkAPI.dispatch);
    } catch (error) {
      return thunkAPI.rejectWithValue({ type: error as ApiAuthErrors });
    }
  },
);

export const federatedSignInAsync = createAsyncThunk<
  void,
  CognitoHostedUIIdentityProvider,
  ThunkAuthType
>(REDUX_AUTH_FEDERATED, async (data, thunkAPI) => {
  try {
    await api.authentication.federatedSignIn(data);
  } catch (error) {
    return thunkAPI.rejectWithValue({ type: error as ApiAuthErrors });
  }
});
