import { useShare } from '@aclito/shared/features/misc/hooks/useShare';
import { ShareType } from '@aclito/shared/types';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { Loader } from '@mantine/core';

import { EventView, OrgView } from './components';

import { VStack } from '@/components';

const ShareItem = () => {
  const location = useParams();
  const type = location?.type as ShareType | undefined;
  const id = location?.id;

  const { event, org, isLoading } = useShare({
    id,
    type,
  });

  return (
    <Container justify={'center'} align={'center'}>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {event && <EventView {...{ ...event }} />}
          {org && <OrgView {...{ ...org }} />}
        </>
      )}
    </Container>
  );
};

const Container = styled(VStack)`
  margin-top: 80px;
`;

export default ShareItem;
