import React from 'react';
import * as Sentry from '@sentry/react';
import { config } from '@aclito/shared/config';
import { isEnabledConfig } from '@aclito/shared/util/isEnabledConfig';

import { Fallback } from './components';

import { reportCrash } from '@/components/ErrorBoundary/components/crashReporting';
const __DEV__ = import.meta.env.MODE === 'development';

interface ErrorBoundaryProps {
  children: React.ReactElement;
}

const ErrorBoundary = ({ children }: ErrorBoundaryProps) => {
  if (isEnabledConfig(config.catchErrors, __DEV__)) {
    return (
      <Sentry.ErrorBoundary
        onError={reportCrash}
        fallback={({ resetError, error, componentStack }) => (
          <Fallback
            resetError={resetError}
            error={error}
            errorInfo={componentStack ?? ''}
          />
        )}
      >
        {children}
      </Sentry.ErrorBoundary>
    );
  }

  return children;
};

export default ErrorBoundary;
