import React from 'react';
import { PasswordInputProps } from '@mantine/core';
import { useController } from 'react-hook-form';

import { InputProps } from './types';
import PasswordInput from './PasswordInput';

const ValidatedPasswordInput: React.FC<
  InputProps & PasswordInputProps & { name: string }
> = ({ placeholder, name, ...rest }) => {
  const {
    field: { value, onChange, onBlur },
    fieldState: { error },
  } = useController({ name });

  return (
    <PasswordInput
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      placeholder={placeholder}
      error={error?.message}
      // @ts-ignore
      invalid={error}
      {...rest}
    />
  );
};

export default ValidatedPasswordInput;
