import { useCallback } from 'react';
import { useAppSelector } from '@aclito/shared/hooks';
import { profileSelectors } from '@aclito/shared/redux/slices/profileSlices';
import { FormattedEventWithPlayers } from '@aclito/shared/types';
import { Event } from '@aclito/entities';

import { useNavigate } from '@/hooks/useNavigate';
import {
  ROUTE_EVENT_INFO,
  ROUTE_MAP,
  ROUTE_ORGANIZATIONS,
  ROUTE_PLAYER_SUGGESTIONS,
  ROUTE_PROFILE,
  ROUTE_USER_PROFILE,
} from '@/util/constants';
import { useWebShare } from '@/hooks/useWebShare';

export const useEventInfoHandlers = () => {
  const nav = useNavigate();
  const { handleShare } = useWebShare();
  const userInfo = useAppSelector(profileSelectors.userInfo);

  const handlePlayerSearch = useCallback((event: Event) => {
    nav(ROUTE_PLAYER_SUGGESTIONS, { state: { event } });
  }, []);

  const handleRedirectToOrg = useCallback((event: Event) => {
    if (event.org) {
      nav(ROUTE_ORGANIZATIONS, { state: { id: event.org.id } });
    }
  }, []);

  const handleRedirectToEventInfo = useCallback((id: string) => {
    nav(ROUTE_EVENT_INFO, {
      state: { id },
    });
  }, []);

  const handleRedirectToMap = useCallback(
    (event: FormattedEventWithPlayers) => {
      nav(ROUTE_MAP, {
        state: { event },
      });
    },
    [],
  );

  const handleRedirectToLeader = useCallback(
    (event: FormattedEventWithPlayers) => {
      const linkTo =
        event.leaderInfo.id === userInfo.id
          ? ROUTE_PROFILE
          : ROUTE_USER_PROFILE;
      if (event.leader) {
        nav(linkTo, { state: { userId: event.leaderInfo.id } });
      }
    },
    [],
  );

  return {
    handleRedirectToOrg,
    handleRedirectToEventInfo,
    handlePlayerSearch,
    handleRedirectToLeader,
    handleShare,
    handleRedirectToMap,
  };
};
