import { EventRaw } from '@aclito/entities';

import { UserInfoWithAlias } from '@aclito/shared/types';

export const isPlayerInQueue = (event: EventRaw, player: UserInfoWithAlias) => {
  if (player.aliasId) {
    return event.players.some(
      (p) => p.aliasId === player.aliasId && p.queueDate,
    );
  }

  return event.players.some(
    (p) => p.id === player.id && p.queueDate && !p.aliasId,
  );
};

export const isAliasInQueue = (event: EventRaw, aliasId: string) =>
  event.players.some((p) => p.aliasId === aliasId && p.queueDate);
