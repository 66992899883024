import React from 'react';
import styled from 'styled-components';
import { EventModel } from '@aclito/shared/classes/EventModel';
import { useDisclosure } from '@mantine/hooks';

import AliasModalJoin from './AliasModalJoin';
import JoinLeaveButton from './JoinLeaveButton';
import AliasModalLeave from './AliasModalLeave';
import JoinButton from './JoinButton';

import { useEventInfoJoin } from '@/features/event/screens/EventInfo/hooks/useEventInfoJoin';
import { HStack, Icon } from '@/components';
import { useEventInfoLeave } from '@/features/event/screens/EventInfo/hooks/useEventInfoLeave';

interface JoinLeaveProps {
  model: EventModel;
}

const JoinLeave: React.FC<JoinLeaveProps> = ({ model }) => {
  const [
    isOpenedJoinModal,
    { open: onOpenJoinModal, close: onCloseJoinModal },
  ] = useDisclosure(false);

  const { handleJoin } = useEventInfoJoin();

  const disabledJoin = model.isLeader && !model.userInfo.aliases.length;

  const handleJoinButtonClick = () => {
    if (model.userInfo.aliases.length) {
      onOpenJoinModal();
      return;
    }

    handleJoin(model);
  };

  const {
    handleLeave,
    handleAliasesLeave,
    isOpenedLeaveModal,
    onCloseLeaveModal,
    onOpenLeaveModal,
  } = useEventInfoLeave(model);

  const handleLeaveButtonClick = () => {
    if (model.userInfo.aliases.length) {
      onOpenLeaveModal();
      return;
    }

    handleLeave();
  };

  return (
    <Container>
      <AliasModalJoin
        handleJoinAliases={handleJoin}
        opened={isOpenedJoinModal}
        onClose={onCloseJoinModal}
        model={model}
      />
      <AliasModalLeave
        handleLeaveAliases={handleAliasesLeave}
        opened={isOpenedLeaveModal}
        onClose={onCloseLeaveModal}
        model={model}
      />
      {!model.isInPast && !disabledJoin && (
        <HStack spacing={16}>
          {!model.isJoinedFully && (
            <>
              {model.canJoin ? (
                <JoinButton onPress={handleJoinButtonClick} />
              ) : model.canJoinQueue ? (
                <JoinLeaveButton
                  onClick={handleJoinButtonClick}
                  tx={'events.info.joinQueue'}
                  bg={'yellow'}
                >
                  <Icon icon="forward" color="whiteText" />
                </JoinLeaveButton>
              ) : (
                <JoinLeaveButton tx="events.info.isFull" bg="red">
                  <Icon icon="forward" color="whiteText" />
                </JoinLeaveButton>
              )}
            </>
          )}
          {model.canLeave && (
            <JoinLeaveButton
              onClick={handleLeaveButtonClick}
              tx="leave"
              bg="red"
            >
              <Icon icon="leave" color="whiteText" />
            </JoinLeaveButton>
          )}
        </HStack>
      )}
    </Container>
  );
};

const Container = styled.div``;

export default JoinLeave;
