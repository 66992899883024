import type { Availability } from '@aclito/entities';
import { useTranslate } from '@aclito/shared/hooks';
import { ActivitiesKey, AvailabilityForm } from '@aclito/shared/types';
import { composeInitialEditValues } from '@aclito/shared/features/availability/utils/composeInitialEditValues';
import { useUpdateAvailabilityMutation } from '@aclito/shared/redux/api/availabilityApi';

import { useNavigate } from '@/hooks/useNavigate';

export const useAvailabilityEdit = (availability: Availability | undefined) => {
  const t = useTranslate();
  const nav = useNavigate();
  const [updateAvailability] = useUpdateAvailabilityMutation();

  const initialValues =
    availability && composeInitialEditValues(t, availability);

  const handleSubmit = async (formValues: AvailabilityForm) => {
    if (availability) {
      nav(-1);

      await updateAvailability({
        id: availability.id,
        from: formValues.date,
        duration: formValues.duration ?? undefined,
        location: formValues.location,
        activities: formValues.activities?.length
          ? (formValues.activities.map(
              (activity) => activity.id,
            ) as ActivitiesKey[])
          : [],
      }).unwrap();
    }
  };

  return { initialValues, handleSubmit };
};
