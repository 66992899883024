import { config } from '@aclito/shared/config';
import { AxiomLogger } from '@aclito/shared/classes/AxiomLogger';
import * as Sentry from '@sentry/react';
import { isEnabledConfig } from '@aclito/shared/util/isEnabledConfig';

const __DEV__ = import.meta.env.MODE === 'development';
export const reportCrash = (error: Error, componentStack: string) => {
  if (!isEnabledConfig(config.catchErrors, __DEV__)) return;
  AxiomLogger.getLogger('errorBoundary').error({
    error: { payload: error.message, componentStack },
  });
  AxiomLogger.emptyBuffer();
  Sentry.captureException(error);
};
