import { useParams } from 'react-router-dom';
import { TitleBar } from '@aclito/ui-web/components/TitleBar';
import { HStack } from '@aclito/ui-web/components/Layout';
import { ActionButton, BackButton } from '@aclito/ui-web/components/Button';
import { Title } from '@aclito/ui-web/components/Title';
import { useFeedbackEvent } from '@aclito/shared/redux/api/feedbackApi';
import { formatEventDuration } from '@aclito/shared/features/event/utils/formatEventDuration';
import { Center, Divider, Loader } from '@mantine/core';

import { useFeedbackHandlers } from './hooks/useFeedbackHandlers';

import InfoBuilder from '@/features/event/screens/EventInfo/components/InfoBuilder';
import { SectionGrid, VStack } from '@/components';
import FeedbackCards from '@/components/Cards/FeedbackCards';
const FeedbackInfo = () => {
  const { eventId } = useParams();

  const { model, ratings } = useFeedbackEvent(eventId);
  const {
    handleFinish,
    handleEmojiSubmit,
    handleCommentClick,
    handleKarmaClick,
  } = useFeedbackHandlers(eventId ?? '');

  const players = model?.event?.playersInfo ?? [];

  return (
    <SectionGrid $isOpen={false}>
      <TitleBar>
        <HStack>
          <BackButton />
          <Title tx={'nav.feedback'} />
        </HStack>
        {model && (
          <ActionButton
            onClick={handleFinish}
            tx={'finish'}
            iconType={'checkmark'}
          />
        )}
      </TitleBar>

      {model ? (
        <>
          <InfoBuilder
            data={[
              {
                text: {
                  text: `${model.event.formattedDate} ${model.event.formattedTime}`,
                },
                icon: 'calendar',
                label: 'events.info.date',
              },
              {
                text: { text: `${formatEventDuration(model.event)}` },
                icon: 'time',
                label: 'events.info.duration',
              },
              {
                text: {
                  text: model.event.place?.name || '',
                  style: { textDecorationLine: 'underline' },
                },
                icon: 'map',
                label: 'events.info.place',
              },
            ]}
          />
          <VStack spacing={16}>
            <Divider style={{ marginTop: 16 }} />
            <FeedbackCards
              event={model.event}
              onKarmaClick={handleKarmaClick}
              onCommentClick={handleCommentClick}
              onEmojiClickSubmit={handleEmojiSubmit}
              data={players}
              ratings={ratings}
            />
          </VStack>
        </>
      ) : (
        <Center>
          <Loader />
        </Center>
      )}
    </SectionGrid>
  );
};

export default FeedbackInfo;
