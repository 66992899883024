import type { UserAlias } from '@aclito/entities';
import React from 'react';
import { useTheme } from 'styled-components';

import { StyledPlayerCard } from '../../../../../common/Modals/ItemStyles';

import { HStack, Text, VStack, Icon, Avatar } from '@/components';

interface AliasModalLeaveItemProps {
  alias: UserAlias;
  handleOnAliasPress: (id: string) => void;
  isSelected: (id: string) => boolean;
}

const AliasModalLeaveItem: React.FC<AliasModalLeaveItemProps> = ({
  alias,
  isSelected,
  handleOnAliasPress,
}) => {
  const theme = useTheme();
  return (
    <StyledPlayerCard
      onClick={() => handleOnAliasPress(alias.id)}
      key={alias.id}
      bg={isSelected(alias.id) ? `${theme.red}1A` : `${theme.white}`}
      mb={10}
    >
      <HStack>
        <Avatar image={alias.image} size={48} />
        <VStack>
          <Text size={16} text={`${alias.name} ${alias.surname}`} />
        </VStack>
      </HStack>

      {isSelected(alias.id) && <Icon icon={'close'} color="red" />}
    </StyledPlayerCard>
  );
};

export default AliasModalLeaveItem;
