import { Culture, dayjsLocalizer, Formats } from 'react-big-calendar';
import { useAppSelector } from '@aclito/shared/hooks';
import { commonSelectors } from '@aclito/shared/redux/slices/commonSlices';
import dayjs from 'dayjs';
import sk from 'dayjs/locale/sk';
import en from 'dayjs/locale/en';
import cs from 'dayjs/locale/cs';

export const useLocalizer = () => {
  const locale = useAppSelector(commonSelectors.locale);
  const localizer = dayjsLocalizer(dayjs);

  const formatFunction = (format: string) => (date: Date, culture?: Culture) =>
    localizer?.format(date, format, culture) ?? '';

  const formats: Formats = {
    dateFormat: formatFunction('D.'),
    dayFormat: formatFunction('ddd D.M.'),
    weekdayFormat: formatFunction('ddd D.M.'),
    timeGutterFormat: formatFunction('H:mm'),
  };

  const localeRecord = {
    sk,
    cs,
    en,
  };

  dayjs.locale({
    // @ts-ignore
    ...localeRecord[locale],
    weekStart: 1,
  });

  return { formats, localizer };
};
