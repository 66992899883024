import { Organization } from '@aclito/entities';

import { useUpdateMyOrganizationsMembersMutation } from '@aclito/shared/redux/api/organizationApi';

export const useOrganizationMembers = (org: Organization) => {
  const [updateOrgMembers] = useUpdateMyOrganizationsMembersMutation();

  const removeMember = async (id: string) => {
    await updateOrgMembers({
      orgId: org.id,
      data: {
        memberId: id,
      },
    }).unwrap();
  };

  return {
    removeMember,
  };
};
