import { PLACE_PROVIDER } from '../../../util/constants';

import { useAppSelector, useTranslate } from '@aclito/shared/hooks';
import { placeSelectors } from '@aclito/shared/redux/slices/placeSlices';

export const usePlace = (activity?: string) => {
  const places = useAppSelector(placeSelectors.places);
  const t = useTranslate();

  const placeSelectList = places.map((place) => ({
    ...place,
    item: place.userId.includes(PLACE_PROVIDER)
      ? `(${t('provider.aclito')}) ${place.name}`
      : place.name,
  }));

  return {
    placeSelectList: placeSelectList.filter((p) =>
      p.activities.includes(activity ?? ''),
    ),
    allList: placeSelectList,
  };
};
