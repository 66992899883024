import React from 'react';
import { ActivitiesKey } from '@aclito/shared/types';

import { HStack } from '@/components';
import ActivityBannerText from '@/common/CardStatusBanner/ActivityBannerText';

interface AvailabilityTagsProps {
  activities: ActivitiesKey[];
}

const AvailabilityTags: React.FC<AvailabilityTagsProps> = ({ activities }) => {
  return (
    <HStack spacing={4}>
      {activities?.map((activity) => (
        <ActivityBannerText key={activity} activityType={activity} />
      ))}
    </HStack>
  );
};

export default AvailabilityTags;
