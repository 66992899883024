import React, { ChangeEvent } from 'react';
import { useController } from 'react-hook-form';
import { formatTimeInput } from '@aclito/shared/util/formatTimeInput';

import { InputProps } from './types';
import Input from './Input';
const ValidatedTimeInput: React.FC<InputProps & { name: string }> = ({
  placeholder,
  name,
  ...rest
}) => {
  const {
    field: { value, onChange, onBlur },
    fieldState: { error },
  } = useController({ name });

  const handleDurationChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(formatTimeInput(e.target.value));
  };

  return (
    // @ts-ignore
    <Input
      type="text"
      value={value}
      onChange={handleDurationChange}
      name={name}
      error={error?.message}
      onBlur={onBlur}
      placeholder={placeholder}
      {...rest}
    ></Input>
  );
};

export default ValidatedTimeInput;
