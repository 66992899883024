import { ImageValueShape } from '@aclito/shared/types';
import React from 'react';
import styled from 'styled-components';

interface ImageProps {
  image: ImageValueShape;
  height?: number | string;
  width?: number | string;
  timeStamp?: string;
}

const Image: React.FC<ImageProps> = ({ image, height, width, timeStamp }) => {
  let pic = image.slice(5);

  if (!pic) return null;

  if (pic.includes('FILE:') || (pic.includes('https://') && timeStamp)) {
    pic = pic + `?ts=${timeStamp}`;
  }

  return pic.trim().length ? (
    <StyledImage width={width} height={height} src={pic} />
  ) : null;
};

const StyledImage = styled.img<{
  height?: number | string;
  width?: number | string;
}>`
  height: ${({ height }) => height ?? 'auto'};
  width: ${({ width }) => width ?? '100%'};
  object-fit: cover;
`;

export default Image;
