import React from 'react';
import { useTheme } from 'styled-components';

import { Icon, SmallButton, Text } from '@/components';

interface ShowMoreButtonProps {
  onClick: () => void;
}

const ShowMoreButton: React.FC<ShowMoreButtonProps> = ({ onClick }) => {
  const theme = useTheme();
  return (
    <SmallButton bg="primary10" onClick={onClick}>
      <Icon size={16} icon="caretDown" color="primary" />
      <Text tx="web.show.more" ml={10} size={16} color={theme.primary} />
    </SmallButton>
  );
};

export default ShowMoreButton;
