import { OrganizationForm } from '@aclito/shared/types';
import { composeInitialCreateValues } from '@aclito/shared/features/organization/utils/composeInitialCreateValues';
import { useOrganizationSchema } from '@aclito/shared/features/organization/hooks/useOrganizationSchema';
import { useOrganizationCreate } from '@aclito/shared/features/organization/hooks/useOrganizationCreate';

import { ROUTE_ORGANIZATIONS } from '@/util/constants';
import { useNavigate } from '@/hooks/useNavigate';

export const useNewOrganization = () => {
  const nav = useNavigate();

  const initialValues = composeInitialCreateValues();
  const { createOrg, isLoading } = useOrganizationCreate();

  const { newOrganizationSchema } = useOrganizationSchema();

  const createNewOrganization = async (data: OrganizationForm) => {
    nav(ROUTE_ORGANIZATIONS);

    await createOrg(data);
  };

  return {
    initialValues,
    createNewOrganization,
    newOrganizationSchema,
    isLoading,
  };
};
