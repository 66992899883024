import { commonActions } from '@aclito/shared/redux/slices';
import { Hub } from '@aclito/shared/classes/Hub';
import { useEffect } from 'react';
import { useAppDispatch } from '@aclito/shared/hooks/useDispatch';

const LocaleProvider = ({ children }: { children: React.ReactNode }) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    Hub.getLocale().then((locale) => {
      dispatch(commonActions.updateLocale({ locale }));
    });
  }, []);
  return <>{children}</>;
};

export default LocaleProvider;
