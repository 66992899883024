import { FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { organizationClient } from '@aclito/client';
import {
  Organization,
  UpdateOrganizationMemberParams,
  UserInfo,
} from '@aclito/entities';

import { OrganizationForm } from '../../types';
import { aclitoApi, AppState } from '../store';
import { assertObjectExists } from '../../util/assertions';
import { profileActions, REDUX_USER_INFO } from '../slices/profileSlices';

import { findAllAndReplace } from '@aclito/shared/util/findAndReplace';

export const CREATE_ORGANIZATION = 'createOrganization';
export const DELETE_ORGANIZATION = 'deleteOrganization';
export const UPDATE_ORGANIZATION = 'updateOrganization';
export const UPDATE_MY_ORGANIZATIONS_MEMBERS = 'updateMyOrganizationsMembers';
export const JOIN_ORGANIZATION = 'joinOrganization';
export const JOIN_ORGANIZATION_LITE = 'joinOrganizationLite';
export const LEAVE_ORGANIZATION_LITE = 'leaveOrganizationLite';
export const LEAVE_ORGANIZATION = 'leaveOrganization';
export const FIND_ORGANIZATION = 'findOrganization';
export const LIST_MY_ORGANIZATIONS = 'listMyOrganizations';
export const LIST_ALL_ORGANIZATIONS = 'listAllOrganizations';

type UpdateMember = {
  orgId: string;
  data: UpdateOrganizationMemberParams;
};

export const organizationApi = aclitoApi
  .enhanceEndpoints({
    addTagTypes: [
      'listMyOrganizations',
      'listAllOrganizations',
      'findOrganization',
    ],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      [CREATE_ORGANIZATION]: builder.mutation<Organization, OrganizationForm>({
        queryFn: async (args, app) => {
          const { unlimited, ...input } = args;
          const { userInfo } = app.getState() as AppState;
          const maxMembers = unlimited ? -1 : Number(args.maxMembers);

          if (!input.file) {
            delete input.file;
          }

          try {
            const { data: org } =
              await organizationClient.organizations.createOrganization({
                ...input,
                maxMembers,
                members: [userInfo.current.id],
                admins: [userInfo.current.id],
                powerUsers: [userInfo.current.id],
                telephone:
                  input.telephone === null ? undefined : input.telephone,
                email: input.email === null ? undefined : input.email,
              });

            return { data: org };
          } catch (error) {
            return { error: { error: 'fail' } as FetchBaseQueryError };
          }
        },
        async onQueryStarted(_, { dispatch, queryFulfilled }) {
          try {
            const { data: createdOrg } = await queryFulfilled;
            dispatch(
              organizationApi.util.updateQueryData(
                'listMyOrganizations',
                { nextToken: null },
                (organizationCache) => ({
                  orgs: [createdOrg, ...organizationCache.orgs],
                  nextToken: organizationCache.nextToken,
                }),
              ),
            );
            dispatch(
              organizationApi.util.updateQueryData(
                'listAllOrganizations',
                { nextToken: null },
                (organizationCache) => ({
                  orgs: [createdOrg, ...organizationCache.orgs],
                  nextToken: organizationCache.nextToken,
                }),
              ),
            );
          } catch (error) {
            return;
          }
        },
      }),
      [DELETE_ORGANIZATION]: builder.mutation<
        { orgId: string },
        { orgId: string }
      >({
        queryFn: async (args) => {
          try {
            const { data: deleted } =
              await organizationClient.organizations.deleteOrganization({
                orgId: args.orgId,
              });

            return { data: { orgId: deleted.id } };
          } catch (error) {
            return { error: { error: 'fail' } as FetchBaseQueryError };
          }
        },
        async onQueryStarted(args, { dispatch, queryFulfilled }) {
          await queryFulfilled;
          const deleteResultMyOrgs = dispatch(
            organizationApi.util.updateQueryData(
              'listMyOrganizations',
              { nextToken: null },
              (organizationCache) => ({
                orgs: organizationCache.orgs.filter(
                  (org) => org.id !== args.orgId,
                ),
                nextToken: organizationCache.nextToken,
              }),
            ),
          );
          const deleteResultAllOrgs = dispatch(
            organizationApi.util.updateQueryData(
              'listAllOrganizations',
              { nextToken: null },
              (organizationCache) => ({
                orgs: organizationCache.orgs.filter(
                  (org) => org.id !== args.orgId,
                ),
                nextToken: organizationCache.nextToken,
              }),
            ),
          );

          try {
            /* empty */
          } catch (error) {
            deleteResultMyOrgs.undo();
            deleteResultAllOrgs.undo();
          }
        },
      }),
      [UPDATE_ORGANIZATION]: builder.mutation<
        Organization,
        OrganizationForm & { id: string }
      >({
        queryFn: async (args) => {
          try {
            const { unlimited, ...input } = args;
            const maxMembers = unlimited ? -1 : args.maxMembers;

            if (!input.file) {
              delete input.file;
            }

            const { data: updated } =
              await organizationClient.organizations.updateOrganization({
                ...input,
                maxMembers,
                telephone:
                  input.telephone === null ? undefined : input.telephone,
                email: input.email === null ? undefined : input.email,
              });
            return { data: updated };
          } catch (error) {
            return { error: { error: 'fail' } as FetchBaseQueryError };
          }
        },
        async onQueryStarted(_, { dispatch, queryFulfilled }) {
          try {
            const { data: updatedOrg } = await queryFulfilled;
            dispatch(
              organizationApi.util.updateQueryData(
                'listMyOrganizations',
                { nextToken: null },
                (organizationCache) => ({
                  orgs: findAllAndReplace(
                    organizationCache.orgs,
                    updatedOrg,
                    (org) => org.id === updatedOrg.id,
                  ),
                  nextToken: organizationCache.nextToken,
                }),
              ),
            );

            dispatch(
              organizationApi.util.updateQueryData(
                'findOrganization',
                { id: updatedOrg.id },
                (organizationCache) => {
                  organizationCache.org = updatedOrg;
                  return organizationCache;
                },
              ),
            );

            dispatch(
              organizationApi.util.updateQueryData(
                'listAllOrganizations',
                { nextToken: null },
                (organizationCache) => ({
                  orgs: findAllAndReplace(
                    organizationCache.orgs,
                    updatedOrg,
                    (org) => org.id === updatedOrg.id,
                  ),
                  nextToken: organizationCache.nextToken,
                }),
              ),
            );
          } catch (error) {
            return;
          }
        },
      }),
      [UPDATE_MY_ORGANIZATIONS_MEMBERS]: builder.mutation<
        Organization,
        UpdateMember
      >({
        queryFn: async (args, _) => {
          try {
            const { data: updated } =
              await organizationClient.organizations.updateOrganizationMember(
                args.orgId,
                { ...args.data },
              );
            assertObjectExists(updated);
            return { data: updated };
          } catch (error) {
            return { error: { error: 'fail' } as FetchBaseQueryError };
          }
        },
        async onQueryStarted(args, { dispatch, queryFulfilled }) {
          try {
            const { data: updatedOrg } = await queryFulfilled;
            dispatch(
              organizationApi.util.updateQueryData(
                'listMyOrganizations',
                { nextToken: null },
                (organizationCache) => ({
                  orgs: findAllAndReplace(
                    organizationCache.orgs,
                    updatedOrg,
                    (org) => org.id === updatedOrg.id,
                  ),
                  nextToken: organizationCache.nextToken,
                }),
              ),
            );
            dispatch(
              organizationApi.util.updateQueryData(
                'findOrganization',
                { id: args.orgId },
                (draft) => {
                  let members = draft.members;

                  if (!args.data.newRole) {
                    members = members.filter(
                      (m) => m.id !== args.data.memberId,
                    );
                  }

                  return {
                    ...draft,
                    org: updatedOrg,
                    members,
                  };
                },
              ),
            );
            dispatch(
              organizationApi.util.updateQueryData(
                'listAllOrganizations',
                { nextToken: null },
                (organizationCache) => ({
                  orgs: findAllAndReplace(
                    organizationCache.orgs,
                    updatedOrg,
                    (org) => org.id === updatedOrg.id,
                  ),
                  nextToken: organizationCache.nextToken,
                }),
              ),
            );
          } catch (error) {
            return;
          }
        },
      }),
      [JOIN_ORGANIZATION]: builder.mutation<Organization, { orgId: string }>({
        queryFn: async (args, _) => {
          try {
            const { data: updated } =
              await organizationClient.organizations.joinOrganization(
                args.orgId,
              );
            assertObjectExists(updated);
            return { data: updated };
          } catch (error) {
            return { error: { error: 'fail' } as FetchBaseQueryError };
          }
        },
        async onQueryStarted(
          { orgId },
          { dispatch, queryFulfilled, getState },
        ) {
          try {
            const state = getState() as AppState;
            const userInfo = state[REDUX_USER_INFO].current;
            const { data: updatedOrg } = await queryFulfilled;

            for (const {
              endpointName,
              originalArgs,
            } of organizationApi.util.selectInvalidatedBy(getState(), [
              'listAllOrganizations',
            ])) {
              if (endpointName !== LIST_ALL_ORGANIZATIONS) continue;
              dispatch(
                organizationApi.util.updateQueryData(
                  endpointName,
                  originalArgs,
                  (draft) => {
                    return {
                      nextToken: draft.nextToken,
                      orgs: draft.orgs.filter((o) => o.id !== orgId),
                    };
                  },
                ),
              );
            }

            dispatch(
              organizationApi.util.updateQueryData(
                'listMyOrganizations',
                { nextToken: null }, // might be an issue with pagination
                (draft) => {
                  return {
                    nextToken: draft.nextToken,
                    orgs: [...draft.orgs, updatedOrg],
                  };
                },
              ),
            );

            dispatch(
              organizationApi.util.updateQueryData(
                'findOrganization',
                { id: orgId },
                (organizationCache) => {
                  return {
                    ...organizationCache,
                    org: updatedOrg,
                    members: [...organizationCache.members, userInfo],
                  };
                },
              ),
            );
          } catch (error) {
            return;
          }
        },
      }),
      [JOIN_ORGANIZATION_LITE]: builder.mutation<
        Organization,
        { orgId: string }
      >({
        queryFn: async (args, _) => {
          try {
            const { data: updated } =
              await organizationClient.organizations.joinOrganization(
                args.orgId,
              );
            assertObjectExists(updated);
            return { data: updated };
          } catch (error) {
            return { error: { error: 'fail' } as FetchBaseQueryError };
          }
        },
        async onQueryStarted(
          { orgId },
          { dispatch, queryFulfilled, getState },
        ) {
          try {
            const state = getState() as AppState;
            const userInfo = state[REDUX_USER_INFO].current;

            const { data: updatedOrg } = await queryFulfilled;
            dispatch(profileActions.updateOrgId(orgId));
            dispatch(
              organizationApi.util.updateQueryData(
                'findOrganization',
                { id: orgId },
                (organizationCache) => {
                  return {
                    ...organizationCache,
                    org: updatedOrg,
                    members: [...organizationCache.members, userInfo],
                  };
                },
              ),
            );
          } catch (error) {
            return;
          }
        },
      }),
      [LEAVE_ORGANIZATION_LITE]: builder.mutation<
        Organization,
        { orgId: string }
      >({
        queryFn: async (args, _) => {
          try {
            const { data: updated } =
              await organizationClient.organizations.leaveOrganization(
                args.orgId,
                { message: '' },
              );
            assertObjectExists(updated);
            return { data: updated };
          } catch (error) {
            return { error: { error: 'fail' } as FetchBaseQueryError };
          }
        },
        async onQueryStarted(
          { orgId },
          { dispatch, queryFulfilled, getState },
        ) {
          try {
            const state = getState() as AppState;
            const userInfo = state[REDUX_USER_INFO].current;
            const { data: updatedOrg } = await queryFulfilled;
            dispatch(profileActions.updateOrgId(undefined));
            dispatch(
              organizationApi.util.updateQueryData(
                'findOrganization',
                { id: orgId },
                (organizationCache) => {
                  return {
                    ...organizationCache,
                    org: updatedOrg,
                    members: organizationCache.members.filter(
                      (member) => member.id !== userInfo.id,
                    ),
                  };
                },
              ),
            );
          } catch (error) {
            return;
          }
        },
      }),
      [LEAVE_ORGANIZATION]: builder.mutation<
        Organization,
        { orgId: string; message: string }
      >({
        queryFn: async (args, _) => {
          try {
            const { data: updated } =
              await organizationClient.organizations.leaveOrganization(
                args.orgId,
                { message: args.message },
              );
            assertObjectExists(updated);
            return {
              data: updated,
            };
          } catch (error) {
            return {
              error: { error: 'fail' } as FetchBaseQueryError,
            };
          }
        },
        async onQueryStarted(
          { orgId },
          { dispatch, queryFulfilled, getState },
        ) {
          try {
            const state = getState() as AppState;
            const userId = state[REDUX_USER_INFO].current.id;
            const { data: updatedOrg } = await queryFulfilled;

            for (const {
              endpointName,
              originalArgs,
            } of organizationApi.util.selectInvalidatedBy(getState(), [
              'listMyOrganizations',
            ])) {
              if (endpointName !== LIST_MY_ORGANIZATIONS) continue;
              dispatch(
                organizationApi.util.updateQueryData(
                  endpointName,
                  originalArgs,
                  (draft) => {
                    return {
                      nextToken: draft.nextToken,
                      orgs: draft.orgs.filter((o) => o.id !== orgId),
                    };
                  },
                ),
              );
            }

            dispatch(
              organizationApi.util.updateQueryData(
                'findOrganization',
                { id: orgId },
                (organizationCache) => {
                  return {
                    ...organizationCache,
                    org: updatedOrg,
                    members: organizationCache.members.filter(
                      (member) => member.id !== userId,
                    ),
                  };
                },
              ),
            );
          } catch (error) {
            return;
          }
        },
      }),
      [FIND_ORGANIZATION]: builder.query<
        { org: Organization; members: UserInfo[] },
        { id: string }
      >({
        queryFn: async (args, _) => {
          try {
            const { data } =
              await organizationClient.organizations.getOrganization({
                orgId: args.id,
              });

            return {
              data,
            };
          } catch (error) {
            return {
              error: { error: 'fail' } as FetchBaseQueryError,
            };
          }
        },
        providesTags: ['findOrganization'],
      }),
      [LIST_MY_ORGANIZATIONS]: builder.query<
        {
          orgs: Organization[];
          nextToken: string | null;
        },
        { nextToken: string | null }
      >({
        queryFn: async (args, _) => {
          try {
            const { data: result } =
              await organizationClient.organizations.getMyOrganizations({
                limit: 50,
                nextToken: args.nextToken,
              });
            assertObjectExists(result);
            return { data: { orgs: result.data, nextToken: result.nextToken } };
          } catch (error) {
            return {
              error: { error: 'fail' } as FetchBaseQueryError,
            };
          }
        },
        providesTags: ['listMyOrganizations'],
        keepUnusedDataFor: Number.POSITIVE_INFINITY,
      }),
      [LIST_ALL_ORGANIZATIONS]: builder.query<
        { orgs: Organization[]; nextToken: string | null },
        {
          name?: string;
          nextToken: string | null;
          refetch?: boolean;
        }
      >({
        queryFn: async (args) => {
          try {
            const result =
              await organizationClient.organizations.getAllOrganizations({
                name: args.name,
                limit: 50,
                nextToken: args.nextToken,
              });
            const orgs = result.data.data;
            const nextToken = result.data.nextToken;
            return { data: { orgs, nextToken } };
          } catch (error) {
            return {
              error: { error: 'fail' } as FetchBaseQueryError,
            };
          }
        },
        serializeQueryArgs: ({ queryArgs }) => {
          const { nextToken, refetch, ...rest } = queryArgs;
          return rest;
        },
        merge: (currentData, { orgs, nextToken }, { arg }) => {
          if (arg.refetch) {
            currentData.orgs = orgs;
          } else {
            currentData.orgs.push(...orgs);
          }
          currentData.nextToken = nextToken;
        },
        forceRefetch({ currentArg, previousArg }) {
          return !!currentArg?.nextToken && currentArg !== previousArg;
        },
        providesTags: ['listAllOrganizations'],
      }),
    }),
  });

export const selectMyOrganizations = (state: AppState) => {
  let res: Organization[] = [];
  for (const {
    endpointName,
    originalArgs,
  } of organizationApi.util.selectInvalidatedBy(state, [
    'listMyOrganizations',
  ])) {
    if (endpointName === LIST_MY_ORGANIZATIONS) {
      res = [
        ...res,
        ...(organizationApi.endpoints[LIST_MY_ORGANIZATIONS].select(
          originalArgs,
          // @ts-expect-error https://github.com/reduxjs/redux-toolkit/issues/2484
        )(state)?.data?.orgs ?? []),
      ];
    }
  }
  //TODO filter by updated at

  return res.filter((value, index, self) => {
    return self.findIndex((v) => v.id === value.id) === index;
  });
};

export const {
  useCreateOrganizationMutation,
  useDeleteOrganizationMutation,
  useUpdateOrganizationMutation,
  useUpdateMyOrganizationsMembersMutation,
  useJoinOrganizationMutation,
  useJoinOrganizationLiteMutation,
  useLeaveOrganizationMutation,
  useLeaveOrganizationLiteMutation,
  useFindOrganizationQuery,
  useListMyOrganizationsQuery,
  useLazyListMyOrganizationsQuery,
  useListAllOrganizationsQuery,
  useLazyListAllOrganizationsQuery,
} = organizationApi;
