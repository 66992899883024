import { Note, NoteGrant, UpdateNoteInput } from '@aclito/entities';

import { ContentType, HttpClient } from '../httpClient';

export class NotesClient extends HttpClient {
  notes = {
    getNote: (id: string, query?: { orgId: string }) =>
      this.request<Note>({
        path: `/notes/${id}`,
        method: 'GET',
        query,
        type: ContentType.Json,
      }),

    patchNote: (body: UpdateNoteInput) =>
      this.request<Note>({
        path: `/notes`,
        method: 'PATCH',
        body,
        type: ContentType.Json,
      }),
  };
  grants = {
    getGrants: () =>
      this.request<NoteGrant[]>({
        path: `/notes/grants`,
        method: 'GET',
        type: ContentType.Json,
      }),
  };
}
