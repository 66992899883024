import { Divider } from '@mantine/core';

import { useNoteGrants } from '@/features/notes/screens/Notes/hooks/useNoteGrants';
import { ActionButton, HStack, Icon, Text, VStack } from '@/components';
import { useNavigate } from '@/hooks/useNavigate';

const NoteGrantListing = () => {
  const { noteGrants } = useNoteGrants();
  const nav = useNavigate();
  return (
    <div>
      {noteGrants
        .filter((grant) => grant.org)
        .map((item, index) => (
          <>
            <VStack spacing={1} style={{ alignSelf: 'flex-end' }}>
              <HStack
                style={{
                  justifyContent: 'space-between',
                }}
              >
                <HStack spacing={8}>
                  <Icon icon={'org'} />
                  <Text text={item.org?.name} />
                </HStack>
                <ActionButton
                  iconType={'rightArrow'}
                  onClick={() =>
                    nav('/organizations', { state: { id: item.org.id } })
                  }
                />
              </HStack>
            </VStack>
            {index < noteGrants.length && (
              <Divider style={{ marginBottom: 8, marginTop: 8 }} />
            )}
          </>
        ))}
    </div>
  );
};
export default NoteGrantListing;
