import React, { ForwardedRef, forwardRef } from 'react';
import { Stack } from '@mantine/core';
import {
  Circle,
  GoogleMap,
  GoogleMarkerClusterer,
  Marker,
} from '@react-google-maps/api';
import styled, { useTheme } from 'styled-components';
import { ThemeColorsRecord } from '@aclito/shared/theme/colors';

import { VStack } from '@/components';
import { MarkedMapProps } from '@/features/search/types';
import { useSearchMap } from '@/features/search/hooks/useSearchMap';
import { MAP_BORDER_RADIUS } from '@/util/constants';
import MapGuide from '@/common/Maps/MapGuide';
// eslint-disable-next-line react/display-name
const MarkedMap = forwardRef(
  (props: MarkedMapProps, ref: ForwardedRef<GoogleMap>) => {
    const {
      circleRadius,
      isMapLoaded,
      center,
      handleMapClusterClick,
      handleOnCenterChange,
      handleOnLoad,
      mapRef,
    } = useSearchMap(props, ref);

    const theme = useTheme();

    return (
      <>
        {isMapLoaded && (
          <Content spacing={3} align="center">
            <Stack w={'100%'} justify="center">
              <GoogleMap
                ref={mapRef}
                options={{
                  streetViewControl: false,
                  fullscreenControl: false,
                }}
                onLoad={handleOnLoad}
                mapContainerStyle={mapContainer}
                center={center}
                zoom={7}
                onClick={handleOnCenterChange}
              >
                {props.displayCircle && (
                  <Circle
                    radius={circleRadius}
                    {...(center && {
                      center,
                    })}
                    options={circleOptions(theme)}
                  />
                )}
                <GoogleMarkerClusterer options={{}}>
                  {(clusterer) => (
                    <React.Fragment>
                      {props.events.map(
                        (event) =>
                          event.place && (
                            <Marker
                              key={event.id}
                              clickable
                              clusterer={clusterer}
                              onClick={() => {
                                if (event.place) {
                                  handleMapClusterClick({
                                    lat: event.place.location.lat.toFixed(4),
                                    lon: event.place.location.lon.toFixed(4),
                                  });
                                }
                              }}
                              position={{
                                lat: event.place.location.lat,
                                lng: event.place.location.lon,
                              }}
                            />
                          ),
                      )}
                    </React.Fragment>
                  )}
                </GoogleMarkerClusterer>
              </GoogleMap>
              <MapGuide />
            </Stack>
          </Content>
        )}
      </>
    );
  },
);

const mapContainer = {
  width: '100%',
  height: 600,
  borderRadius: MAP_BORDER_RADIUS,
};

const circleOptions = (theme: ThemeColorsRecord) => ({
  fillColor: theme.mapCircle,
  strokeColor: theme.mapCircleStroke,
  strokeWeight: 1,
  fillOpacity: 1,
});

const Content = styled(VStack)`
  width: 100%;
  flex: 1;
`;

export default MarkedMap;
