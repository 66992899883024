import { useAppSelector } from '@aclito/shared/hooks';
import moment from 'moment';
import React from 'react';
import { useRepeatingForm } from '@aclito/shared/features/event/hooks/useRepeatingForm';
import { eventSelectors } from '@aclito/shared/redux/slices/eventSlices';
import { formatDate } from '@aclito/shared/util/formatRepeatingDate';
import styled, { useTheme } from 'styled-components';

import {
  VStack,
  HStack,
  Text,
  ValidatedNumberInput,
  Collapsible,
  ValidatedSwitch,
  ValidatedTimeInput,
  ValidatedSelect,
  ValidatedDateTimePicker,
} from '@/components';

interface DateFormProps {
  showRepeating: boolean;
}

const DateForm: React.FC<DateFormProps> = ({ showRepeating }) => {
  const eventDates = useAppSelector(eventSelectors.eventDates);

  const { translatedRepeatChoices, switchRepeating, date, watchDuration } =
    useRepeatingForm();

  const theme = useTheme();
  return (
    <VStack spacing={16}>
      <VStack spacing={16}>
        <ValidatedDateTimePicker
          name="date"
          {...{
            required: true,
            txLabel: 'events.new.date',
            txPlaceholder: 'events.new.date',
            minDate: moment().toDate(),
            maxDate: moment().add(6, 'months').toDate(),
          }}
        />
        <ValidatedTimeInput
          required
          txLabel="events.new.duration"
          name="duration"
          txPlaceholder="time.display"
        />
        {showRepeating && (
          <ValidatedSwitch
            txLabel="events.new.switchRepeating"
            name="switchRepeating"
          />
        )}
      </VStack>
      {switchRepeating && date && (
        <FormRow spacing={20}>
          <ValidatedSelect
            txLabel="events.new.repetitionInterval"
            txPlaceholder="events.new.repetitionInterval"
            name="repeatAfter"
            options={translatedRepeatChoices}
            required
          />

          <ValidatedNumberInput
            txLabel="events.new.numberOfEvents"
            txPlaceholder="events.new.numberOfEvents"
            name="numberOfEvents"
            min={1}
            max={180}
          />
        </FormRow>
      )}
      <Collapsible defaultOpen buttonLabelTx="events.new.plannedEvents">
        {watchDuration && (
          <VStack spacing={4}>
            {eventDates.date?.map((startDate: string, index: number) => {
              const [hour, minute] = watchDuration.split(':');

              return (
                <Items key={index}>
                  <Text color={theme.grey80}>
                    {formatDate(
                      startDate,
                      moment(eventDates.date[index])
                        .add(hour, 'hour')
                        .add(minute, 'minute')
                        .toISOString(),
                    )}
                  </Text>
                  {/*{eventDates.date.length > 1 && (*/}
                  {/*  <>*/}
                  {/*    <StyledIcon*/}
                  {/*      icon="remove"*/}
                  {/*      size={16}*/}
                  {/*      color="grey80"*/}
                  {/*      onClick={() => {*/}
                  {/*        dispatch(eventActions.removeEventDates(index));*/}
                  {/*      }}*/}
                  {/*    />*/}
                  {/*  </>*/}
                  {/*)}*/}
                </Items>
              );
            })}
          </VStack>
        )}
      </Collapsible>
    </VStack>
  );
};

const Items = styled(HStack)`
  display: flex;
  justify-content: space-between;
  border-radius: 0.625rem;
  padding: 0.75rem;
  background-color: ${({ theme }) => theme.primary10};
`;

const FormRow = styled(HStack)`
  & > * {
    flex-grow: 1;
  }
`;

// const StyledIcon = styled(Icon)`
//   transition: transform 200ms ease-in-out;
//   &:hover {
//     cursor: pointer;
//     transform: scale(1.3);
//   }
// `;

export default DateForm;
