import * as React from 'react';
import { Switch as MantineSwitch } from '@mantine/core';
import { useTranslate } from '@aclito/shared/hooks';
import styled, { useTheme } from 'styled-components';

import { Text } from '../Text';
import { VStack } from '../Layout';

import { SwitchProps } from './types';

const Switch: React.FC<SwitchProps> = ({
  label,
  placeholder,
  txLabel,
  txPlaceholder,
  txSubLabel,
  ...props
}) => {
  const t = useTranslate();
  const theme = useTheme();
  const i18nLabel = txLabel && t(txLabel);
  const labelContent = i18nLabel || label;

  const i18nPlaceholder = txPlaceholder && t(txPlaceholder);
  const placeholderContent = i18nPlaceholder || placeholder;

  const switchHeight = 28;

  return (
    <StyledInput
      styles={{
        track: {
          backgroundColor: theme.grey20,
          border: 'none',
          width: 44,
          height: switchHeight,
        },
        thumb: {
          background: theme.whiteText,
          border: 'none',
          width: 20,
          height: 20,
        },
        label: { color: theme.grey80 },
      }}
      size="md"
      label={
        <VStack h={switchHeight} justify="center" spacing={0}>
          <Text size={14}>{labelContent}</Text>
          {txSubLabel ? (
            <Text size={14} color={theme.grey30} tx={txSubLabel} />
          ) : null}
        </VStack>
      }
      placeholder={placeholderContent}
      {...props}
    />
  );
};

const StyledInput = styled(MantineSwitch)`
  input:checked + * > * {
    left: calc(100% - 1.125rem - 0.25rem);
  }
`;

export default Switch;
