import { FC } from 'react';

import { useLocation } from '../hooks';

const withRouter = <T extends object>(Component: FC<T>): FC<T> => {
  const Router: FC<T> = (props) => {
    const location = useLocation<T>();

    if (location.state === undefined) {
      location.state = {} as T;
    }

    return <Component {...(props as T)} {...location} />;
  };
  return Router;
};
export default withRouter;
