import { makeFederatedSignIn } from './federatedSignIn';
import { makeUpdateAttributesCurrentUser } from './updateUserAttributes';
import { makeCompleteNewPassword } from './completeNewPassword';
import { makeCurrentUser } from './currentUser';
import { makeSignIn } from './signIn';
import { makeSignOut } from './signOut';
import { makeSignUp } from './signUp';
import { makeResetPasswordRequest } from './resetPasswordRequest';
import { makeConfirmSignUp } from './confirmSignUp';
import { makeResetPasswordSubmit } from './resetPasswordSubmit';
import { makeChangePassword } from './changePassword';
import { makeResendSignUpCode } from './resendSignUpCode';

export const authenticationClient = {
  completeNewPassword: makeCompleteNewPassword,
  currentUser: makeCurrentUser,
  signIn: makeSignIn,
  signOut: makeSignOut,
  signUp: makeSignUp,
  resetPasswordRequest: makeResetPasswordRequest,
  confirmSignUp: makeConfirmSignUp,
  resetPasswordSubmit: makeResetPasswordSubmit,
  changePassword: makeChangePassword,
  resendSignUpCode: makeResendSignUpCode,
  updateAttributes: makeUpdateAttributesCurrentUser,
  federatedSignIn: makeFederatedSignIn,
};
