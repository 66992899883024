import React from 'react';
import { MemberType } from '@aclito/shared/types';
import { Box } from '@mantine/core';
import { closeAllModals } from '@mantine/modals';
import { useUpdateMyOrganizationsMembersMutation } from '@aclito/shared/redux/api/organizationApi';
import { Organization } from '@aclito/entities';

import { VStack, Button, Text } from '@/components';

interface EditModalProps {
  type: MemberType;
  myRole: MemberType;
  id: string;
  org: Organization;
}
const EditModal: React.FC<EditModalProps> = ({ myRole, type, id, org }) => {
  const isAdmin = myRole === 'admin';
  const isPowerUser = myRole === 'powerUser';

  const [updateOrgMembers] = useUpdateMyOrganizationsMembersMutation();

  const update = async (newRole: MemberType) => {
    closeAllModals();
    if (org && id) {
      await updateOrgMembers({
        orgId: org.id,
        data: {
          memberId: id,
          newRole,
        },
      }).unwrap();
    }
  };

  return (
    <Box
      sx={{
        width: 200,
        marginLeft: 'auto',
        marginRight: 'auto',
      }}
    >
      {isAdmin && org && (
        <VStack spacing={10}>
          {type === 'member' ? (
            <Button
              onClick={() => {
                update('powerUser');
              }}
            >
              <Text color="white" tx="powerUser" />
            </Button>
          ) : (
            <Button
              onClick={() => {
                update('member');
              }}
            >
              <Text color="white" tx="member" />
            </Button>
          )}

          {type === 'admin' ? (
            <Button
              onClick={() => {
                update('powerUser');
              }}
            >
              <Text color="white" tx="powerUser" />
            </Button>
          ) : (
            <Button
              onClick={() => {
                update('admin');
              }}
            >
              <Text color="white" tx="admin" />
            </Button>
          )}
        </VStack>
      )}
      {isPowerUser && (
        <VStack spacing={10}>
          {type === 'member' ? (
            <Button onClick={() => update('powerUser')}>
              <Text color="white" tx="powerUser" />
            </Button>
          ) : (
            <Button onClick={() => update('member')}>
              <Text color="white" tx="member" />
            </Button>
          )}
        </VStack>
      )}
    </Box>
  );
};
export default EditModal;
