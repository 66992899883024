import { CognitoHostedUIIdentityProvider, Auth } from '@aws-amplify/auth';
import { ICredentials } from '@aws-amplify/core';
/**
 *
 * @param provider sign in type
 * @returns A promise of User if success, appropriate error message otherwise
 */
export const makeFederatedSignIn = async (
  provider: CognitoHostedUIIdentityProvider,
): Promise<ICredentials> => {
  try {
    return await Auth.federatedSignIn({ provider });
  } catch (error) {
    return Promise.reject(error);
  }
};
